<template>
  <div id="sync-container">
    <DataTableAlternate
      id="sync-table"
      ref="pricingSyncIndex"
      table-name="pricingSyncIndexOne"
      :api="api"
      :data-table-options="dataTableOptions"
      @selection-change="handleSelectionChange"
      @select-all="handleSelectionChange"
    >
      <template #pageActions>
        <el-col>
          <el-button
            :disabled="checkedItems.filter((i) => i.sync_enabled).length === 0"
            flat
            size="small"
            style="margin: 2px 2px 2px 0px"
            type="primary"
            @click="confirmBulkSync"
          >
            Sync
          </el-button>

          <el-button size="small" type="primary" @click.prevent="$refs.pricingSyncIndex.refresh()">Refresh</el-button>
        </el-col>
      </template>
      <template #actions="{ row }">
        <el-button
          :disabled="!row.sync_enabled"
          size="mini"
          type="secondary"
          @click="sync(row)"
        >Sync</el-button>
      </template>
      <template #default="{ row, column }">
        <template v-if="column === 'sync_enabled'">
          <el-switch
            :disabled="!row.parent_template_id"
            :value="row.sync_enabled"
            active-text=""
            inactive-text=""
            @change="toggleSyncEnabled(row)"
          />
        </template>
        <template v-else-if="column === 'status'">
          <el-tag
              v-if="row.status.length"
              :type="getStatusTagType(row.status)"
              :hit="true"
              size="mini"
              class="status-tag"
          >
            {{row.status}}
          </el-tag>
        </template>
        <template v-else-if="column === 'parent_template_name'">
          <el-button
            class="inline-action-button template-edit"
            icon="el-icon el-icon-edit"
            size="mini"
            @click="selectParentTemplate(row)"
          />
          <span>{{$refs.pricingSyncIndex.getColumnValue(column, row)}}</span>
        </template>
        <div v-else>
          {{$refs.pricingSyncIndex.getColumnValue(column, row)}}
        </div>
      </template>
    </DataTableAlternate>
  </div>
</template>

<script>
import camelCaseKeys from 'camelcase-keys';
import pricingSyncApi from 'api/v2/pricingSync'
import {logErrorMessage} from '@/js/common/util';
import DataTableAlternate from '@/js/components/Common/DataTableAlternate.vue';

export default {
  name: 'PricingSyncTable',
  components: {DataTableAlternate},
  data() {
    return {
      items: [],
      checkAll: false,
      checkedItems: [],
      isIndeterminate: false,
      loading: true,
      dataTableOptions: {
        showRefresh: true,
        allowSelection: true,
        tableHeightOffset: 165,
        pageSizes: [100, 200, 500],
        columnWidth: {
          _rowActions: 60,
          template_id: 110,
          project_name: 250,
          template_name: 210,
          parent_template_name: 210,
          template_items_count: 80,
          parent_template_items_count: 100,
          linked_template_items_count: 100,
          sync_enabled: 100,
          synced_at: 130,
          parent_template_updated_at: 130,
          status: 100,
        }
      },
    }
  },
  computed: {
    api() {
      return pricingSyncApi
    }
  },
  mounted() {
    this.fetchItems()
  },
  methods: {
    fetchItems() {
      this.loading = true
      pricingSyncApi.index(
        (response) => {
          this.items = response
          this.loading = false
        },
        (error) => {
          logErrorMessage(error)
          this.loading = false
        }
      )
    },
    selectParentTemplate(item) {
      this.$emit('select-template', camelCaseKeys(item))
    },
    toggleSyncEnabled(item) {
      pricingSyncApi.toggleSync(
        (response) => {
          item.sync_enabled = response.syncEnabled
        },
        (error) => {
          this.$message.error({
            message: error.message,
          })
        },
        {
          projectId: item.project_id,
          pricingTemplateId: item.template_id,
        }
      )
    },
    sync(item) {
      pricingSyncApi.store(
        (response) => {
          if (response.pricingSyncItem) {
            Object.assign(item, response.pricingSyncItem)
          }
          if (response.changes) {
            // eslint-disable-next-line
            console.info('@todo changes', response.changes)
          }
        },
        (error) => {
          this.$message.error({
            message: error.message,
          })
        },
        {
          projectId: item.project_id,
          pricingTemplateId: item.template_id,
        }
      )
    },
    confirmBulkSync() {
      const syncableTemplates = this.checkedItems.filter((i) => i.sync_enabled)
      const items = syncableTemplates.slice(0, 10)
      let messageText = items
        .map((t) => t.project_name + ' - ' + t.template_name)
        .join('\r\n')

      if (syncableTemplates.length > 10) {
        messageText += '\r\n\r\n'
        messageText += `... and ${syncableTemplates.length - 10} more templates`
      }

      this.$confirm(messageText, 'The following templates will be synced', {
        confirmButtonText: 'Yes please',
        cancelButtonText: 'No thanks',
        type: 'warning',
        customClass: 'template-sync-dialog',
      }).then(() => {
        this.syncAll(syncableTemplates.map((p) => p.template_id))
      })
    },
    syncAll(pricingTemplatesIds) {
      this.loading = true
      pricingSyncApi.bulkSync(
        (response) => {
          response
            .filter((r) => r.error)
            .map((r) => {
              this.$message.error({
                message: r.error,
              })
              return r.error
            })

          this.$refs.pricingSyncIndex.refresh(false)
          this.loading = false
        },
        (error) => {
          this.$message.error({
            message: error.message,
          })
        },
        {
          pricingTemplateIds: pricingTemplatesIds,
        }
      )
    },
    handleSelectionChange(val) {
      this.checkedItems = val
    },
    getStatusTagType(status) {
      if(status === 'Never synced') {
        return 'warning';
      }
      if(status === 'Outdated') {
        return 'danger';
      }
      if(status === 'Local changes') {
        return 'primary';
      }
      if(status === 'Up-to-date') {
        return 'success';
      }
      if(status === 'unknown') {
        return 'grey';
      }
    },

  },
}
</script>

<style lang="scss" scoped>
#sync-container {
  #sync-table {
    .template-edit {
      display: none;
      position: absolute;
      right: 0;
      top: 5px;
    }

    tr:hover {
      .template-edit {
        display: inline-block;
      }
    }
  }
  .status-tag {
    color:white;
    display: block;

    &.el-tag--success {
      background-color: #b3e19d;
      color: #606266;
    }
    &.el-tag--danger {
      background-color: #F56C6C;
    }
    &.el-tag--primary {
      background-color: #409EFF;
    }
    &.el-tag--warning {
      background-color: #fd8200;
    }
  }
}
</style>
