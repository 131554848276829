<template>
  <div>
    <el-form ref="form" label-position="top">
      <el-form-item label="Update status">
        <el-select
          ref="status"
          v-model="lerfForm.status"
          placeholder="please select status"
          @change="onChange"
        >
          <el-option
            v-for="(status) in statuses"
            :key="status.id"
            :label="
              status.value === currentStatus
                ? `${status.label} (current)`
                : status.label
            "
            :value="status.value"
            :disabled="status.value === currentStatus"
          />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" :disabled="!updated" @click="update">
          Update
        </el-button>
      </el-form-item>
    </el-form>

    <StatusHistory :items="lerf.statuses" />
  </div>
</template>

<script>
import lerfsApi from 'api/v2/lerfs'
import StatusHistory from './StatusHistory'
import { formatErrorMessage } from 'common/util'

import { mapGetters } from 'vuex'

export default {
  name: 'LerfFormStatus',
  components: {
    StatusHistory,
  },
  props: {
    lerf: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      lerfForm: {
        status: null
      },
      form: {},
      updated: false,
      currentStatus: null,
      statusHistory: [],
    }
  },

  computed: {
    ...mapGetters('global', ['lerfUpdateRules']),

    statuses() {
      return this.$store.getters['global/getEnumById']('lerf_status', true)
    },

    filteredStatuses() {
      return Object.values(this.statuses).filter((status) => {
        return (
          this.rules[this.currentStatus].includes(status.value) ||
          status.value === this.currentStatus
        )
      })
    },
  },
  created() {
    this.lerfForm = this.lerf
  },

  mounted() {
    this.currentStatus = this.lerf.status
    this.statusHistory = this.lerf.statusHistory
    if (
      !Object.keys(this.lerfUpdateRules).includes(this.currentStatus) &&
      this.statusHistory.length >= 2
    ) {
      this.currentStatus = this.statusHistory[1].statusNew
    }
  },
  methods: {
    onChange() {
      this.updated = true
    },
    update() {
      lerfsApi.update(
        () => {
          this.currentStatus = this.lerf.status
          this.updated = false

          this.$emit('update')

          this.$message({
            type: 'success',
            message: 'Updated',
            duration: 2000,
          })
        },
        (error) => {
          this.loading = false
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.lerf.id,
        {
          status: this.lerfForm.status,
        }
      )
    },
  },
}
</script>
