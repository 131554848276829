<template>
  <div>
    <el-form label-position="top" label-width="120px">
      <el-row :gutter="10" class="row-bg">
        <el-form-item>
          <el-col>
            <el-row :gutter="10" class="row-bg" type="flex">
              <el-col :span="12">Subtotal</el-col>
              <el-col :span="12" style="text-align:right;">{{formatNumber(purchaseInvoiceForm.net)}}</el-col>
            </el-row>
            <el-row :gutter="10" type="flex">
              <el-col :span="12">Discount</el-col>
              <el-col :span="12" style="text-align:right;">{{formatNumber(discountAmount)}}</el-col>
            </el-row>
            <el-row :gutter="10" type="flex">
              <el-col :span="12">Total</el-col>
              <el-col :span="12" style="text-align:right;">{{formatNumber(grandTotal)}}</el-col>
            </el-row>
          </el-col>
        </el-form-item>
      </el-row>
      <el-row
        v-if="discountAdded"
        :gutter="10"
        class="row-bg"
      >
        <el-col>
          <el-form-item label="Discount percentage">
            <el-input
              v-model="discountPercentage"
              placeholder="Percentagee"
              :disabled="updating"
              style="max-width:100px;"
              @change="onChange"
              @keyup="onChange"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="row-bg">
        <el-col>
          <el-form-item v-if="discountAdded">
            <el-button type="primary" :disabled="updating || !changed" @click="update">
              Update
            </el-button>
          </el-form-item>
          <el-form-item v-if="!discountAdded">
            <el-button type="primary" :disabled="updating" @click="addDiscount">
              Add discount
            </el-button>
          </el-form-item>
          <el-form-item v-if="discountAdded">
            <el-button type="primary" :disabled="updating" @click="removeDiscount">
              Remove discount
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import purchaseInvoicesApi from 'api/v2/purchaseInvoices'
import { formatErrorMessage } from '../../common/util'
import numeral from 'numeral'

export default {
  name: 'DiscountRulesForm',
  components: {},
  props: {
    purchaseInvoice: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      purchaseInvoiceForm: {
        status: null
      },
      changed: false,
      currentRule: null,
      discountAdded: false,
      discountAmount: null,
      discountPercentage: null,
      discountPercentageOriginal: null,
      form: {},
      grandTotal: 0,
      updated: false,
      updating: false,
    }
  },
  computed: {
    console: () => console, // eslint-disable-next-line
    window: () => window, // eslint-disable-next-line
  },
  created() {
    this.purchaseInvoiceForm = this.purchaseInvoice
    if (typeof this.purchaseInvoiceForm.discountPercentage !== 'undefined') {
      if (this.purchaseInvoiceForm.discountPercentage !== null && !isNaN(this.purchaseInvoiceForm.discountPercentage)) {
        this.discountPercentage = parseFloat(this.purchaseInvoiceForm.discountPercentage)
      }
    }
    this.discountPercentageOriginal = this.discountPercentage
    this.updateDiscountAmount()
  },
  methods: {
    onChange() {
      this.setChanged()
      this.updateDiscountAmount()
    },
    setChanged() {
      this.changed = this.discountPercentageOriginal !== this.discountPercentage
    },
    addDiscount() {
      this.discountAdded = true
      this.discountPercentage = 0
      this.updateDiscountAmount()
    },
    removeDiscount() {
      this.discountAdded = false
      this.discountPercentage = 0
      this.updateDiscountAmount()

      // Update the stored value
      if (this.discountPercentageOriginal !== this.discountPercentage) {
        this.update()
      }
    },
    updateDiscountAmount() {
      this.setChanged()

      // Replace comma with dot
      this.discountPercentage = this.discountPercentage.toString().replace(',', '.')

      // Some validations
      if (isNaN(this.discountPercentage)) this.discountPercentage = 0
      this.discountPercentage = parseFloat(this.discountPercentage)
      if (this.discountPercentage < 0) this.discountPercentage = 0
      if (this.discountPercentage > 100) this.discountPercentage = 100

      // Calculate
      this.discountAmount = 0
      if (this.discountPercentage > 0) {
        this.discountAmount = this.purchaseInvoiceForm.net * this.discountPercentage / 100
        this.discountAdded = true
      }
      this.grandTotal = this.purchaseInvoiceForm.net - this.discountAmount
    },
    formatNumber(number) {
      return numeral(number).format('0,0.00')
    },
    update() {
      this.updating = true
      purchaseInvoicesApi.update(
        (response) => {
          this.updating = false
          this.updated = true
          this.$emit('update', response)
          this.discountPercentageOriginal = this.discountPercentage

          this.$message({
            type: 'success',
            message: 'Updated',
            duration: 3000,
          })
        },
        (error) => {
          this.loading = false
          this.$message({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.purchaseInvoice.id,
        {
          discount_percentage: this.discountPercentage,
        }
      )
    }
  }
}
</script>
