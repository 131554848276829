<template>
  <weekly-report-table />
</template>

<script>
import WeeklyReportTable from './WeeklyReportTable'

export default {
  name: 'WeeklyReport',
  components: {
    WeeklyReportTable,
  },
}
</script>
