import ApiResource from '../ApiResource'

class ProjectAreaLocationResource extends ApiResource {
  constructor() {
    super({
      name: 'projects.areas.locations',
    })
  }

  moveToArea(resolve, reject, projectId, areaId, locationId, payload) {
    this.ids = [projectId, areaId, locationId]
    this.post(`${this.getUrl()}/move`, payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const projectAreaLocationsApi = new ProjectAreaLocationResource()

export default projectAreaLocationsApi
