export class AuthorizationError extends Error {
  constructor(
    message = 'User is not authorized to perform action',
    fileName = '',
    lineNumber = ''
  ) {
    super(message, fileName, lineNumber)

    this.name = this.constructor.name
  }
}

export class AuthenticationError extends Error {
  constructor(
    message = 'User is not logged in',
    fileName = '',
    lineNumber = ''
  ) {
    super(message, fileName, lineNumber)

    this.name = this.constructor.name
  }
}

export class NotFoundError extends Error {
  constructor(
    message = 'Frontend and backend mismatch. Please refresh the application.',
    fileName = '',
    lineNumber = ''
  ) {
    super(message, fileName, lineNumber)

    this.name = this.constructor.name
  }
}
