<template>
  <div>
    <input ref="fileInput" type="file" @input="uploadDocument"/>
    <el-button icon="plus" type="primary" @click="selectDocument">
      Upload a document
    </el-button>

    <div class="documents">
      <a
        v-for="document in documents"
        :key="document.id"
        :download="document.name"
        :href="document.url"
        class="document"
        target="_blank"
      >
        <div class="document-icon">
          <i class="el-icon-document"/>
        </div>
        <div>
          {{ document.name }}
        </div>
        <small>
          {{ formatDate(document.createdAt) }}
        </small>

        <i class="el-icon-delete" @click.prevent="removeDocument(document)"/>
      </a>
    </div>

    <h6 style="margin: 1em 0px 5px 0px">Approvals</h6>
    <div class="documents">
      <template v-for="approval in approvals">
        <a
          v-if="approval.status === 'Approved'"
          :key="approval.id"
          class="document"
          @click="downloadApproval(approval)"
        >
          <div class="document-icon">
            <i class="el-icon-document"/>
          </div>
          <div>
            {{ approval.reference }}
          </div>
          <small>
            {{ formatDate(approval.approved_at) }}
          </small>

          <!-- <i class="el-icon-delete" @click.prevent="removeDocument(document)" /> -->
        </a>
      </template>
    </div>
  </div>
  <!-- APPROVALS -->
</template>

<script>
import moment from 'moment'

import projectMediaApi from 'api/v2/projectMedia'
import projectApprovalsApi from 'api/v2/projectApprovals'
import approvalsApi from 'api/v2/approvals'

import {formatErrorMessage} from 'common/util'
import {logErrorMessage} from '../../../../common/util';

export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      documents: null,
      approvals: [],
    }
  },

  mounted() {
    this.fetchProjectDocuments()
    this.fetchApprovals(1)
  },

  methods: {
    fetchProjectDocuments() {
      projectMediaApi.index(
        (response) => {
          this.documents = response
        },
        (error) => {
          this.$message.error(formatErrorMessage(error))
        },
        this.project.id
      )
    },
    fetchApprovals() {
      projectApprovalsApi.index(
        (response) => {
          this.approvals = response
        },
        (error) => {
          logErrorMessage(error)
          this.$message.error({
            showClose: true,
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.project.id
      )
    },
    downloadApproval(approval) {
      return approvalsApi.download(
        () => {

        },
        (error) => {
          logErrorMessage(error)
          this.$message.error({
            showClose: true,
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        approval.id
      )
    },
    removeDocument(document) {
      this.$confirm(
        `Are you sure that you want to remove the document "${document.name}"?`,
        'Remove document?',
        {
          cancelButtonText: 'No, cancel',
          confirmButtonText: 'Yes, remove',
        }
      )
        .then(() => {
          projectMediaApi.delete(
            () => {
              this.documents = this.documents.filter(
                (d) => d.id !== document.id
              )
            },
            (error) => {
              this.$message.error(formatErrorMessage(error))
            },
            this.project.id,
            document.id
          )
        })
        .catch(() => {
        })
    },

    formatDate(date) {
      return moment(date).format('DD-MM-YYYY HH:mm')
    },

    selectDocument() {
      this.$refs.fileInput.click()
    },

    uploadDocument(event) {
      const file = event.target.files[0]

      projectMediaApi.upload(
        () => {
          this.fetchProjectDocuments()
        },
        (error) => {
          this.$message.error(formatErrorMessage(error))
        },
        this.project.id,
        file
      )
    },
  },
}
</script>

<style lang="scss" scoped>
input {
  display: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.documents {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
  padding-top: 16px;
}

.document {
  width: 150px;
  height: 150px;
  margin: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:hover {
    border-radius: 8px;
    background-color: #0001;
  }

  small {
    margin-top: 4px;
  }

  .el-icon-delete {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
    border-radius: 8px;
    color: red;

    &:hover {
      background-color: #0001;
    }
  }
}

.document-icon {
  font-size: 80px;
  flex: 1;
  display: flex;
  align-items: center;
}
</style>
