import ApiResource from '../ApiResource'

class ProjectPositionItemResource extends ApiResource {
  constructor() {
    super({
      name: 'projects.positions.items',
    })

    this.getSingleItem = this.show
    this.deleteItem = this.delete
  }
}

const projectPositionItemsApi = new ProjectPositionItemResource()

export default projectPositionItemsApi
