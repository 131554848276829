<template>
  <div>
    <template v-if="isEditing || isEditingOverwrite">
      <div v-if="projectFields !== null">
        <div
          v-for="(groupItems, groupName) in editableGroupedFields"
          :key="groupName"
        >
          <div
            class="fields_group"
            :class="'fields_group--count_' + groupItems.length"
          >
            <h3>{{groupName}}</h3>
            <div class="group_items">
              <div
                v-for="(projectField, index) in groupItems"
                :key="index"
                class="project_field project_field--show"
              >
                <div class="name">
                  <template v-if="projectField.description">
                    <h3>{{ projectField.description }}</h3>
                    <p>{{ projectField.name }}</p>
                  </template>
                  <template v-else>
                    <h3>{{ projectField.name }}</h3>
                    <p>{{ projectField.description }}</p>
                  </template>
                </div>
                <div class="value">

                  <template v-if="projectField.type === 'date'">
                    <el-date-picker
                      v-model="projectField.value"
                      :disabled="!projectField.updateable"
                      format="dd.MM.yyyy"
                      placeholder="Select date"
                      type="date"
                    />
                  </template>
                  <template v-else-if="projectField.type === 'datetime'">
                    <el-date-picker
                      v-model="projectField.value"
                      :disabled="!projectField.updateable"
                      format="dd.MM.yyyy HH:mm:ss"
                      placeholder="Select date/time"
                      type="datetime"
                    />
                  </template>
                  <template v-else-if="projectField.type === 'string'">
                    <el-input
                      v-model="projectField.value"
                      :disabled="!projectField.updateable"
                    />
                  </template>
                  <template v-else-if="projectField.type === 'text'">
                    <el-input
                      v-model="projectField.value"
                      :autosize="{ minRows: 2 }"
                      :disabled="!projectField.updateable"
                      type="textarea"
                    />
                  </template>
                  <template v-else-if="projectField.type === 'number'">
                    <el-input
                      v-model="projectField.value"
                      :disabled="!projectField.updateable"
                      type="number"
                    />
                  </template>
                  <template v-else-if="projectField.type === 'decimal'">
                    <el-input
                      v-model="projectField.value"
                      :disabled="!projectField.updateable"
                      type="number"
                    />
                  </template>
                  <template v-else-if="projectField.type === 'dropdown'">
                    <el-select
                      v-model="projectField.value"
                      :disabled="!projectField.updateable"
                      placeholder="Select..."
                    >
                      <el-option
                        v-for="item in projectField.options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </template>
                  <p v-else>Unknown field type: {{ projectField.type }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p v-if="projectFieldsToEdit.length !== 0 && editableGroupedFields.length > 0">
          <strong>Other fields</strong>
        </p>
        <div
          v-for="(projectField, index) in projectFieldsToEdit"
          :key="index"
          class="project_field"
        >
          <template v-if="projectField.description">
            <h3>{{ projectField.description }}</h3>
            <p>{{ projectField.name }}</p>
          </template>
          <template v-else>
            <h3>{{ projectField.name }}</h3>
            <p>{{ projectField.description }}</p>
          </template>
          <template v-if="projectField.type === 'date'">
            <el-date-picker
              v-model="projectField.value"
              :disabled="!projectField.updateable"
              format="dd.MM.yyyy"
              placeholder="Select date"
              type="date"
            />
          </template>
          <template v-else-if="projectField.type === 'datetime'">
            <el-date-picker
              v-model="projectField.value"
              :disabled="!projectField.updateable"
              format="dd.MM.yyyy HH:mm:ss"
              placeholder="Select date/time"
              type="datetime"
            />
          </template>
          <template v-else-if="projectField.type === 'string'">
            <el-input
              v-model="projectField.value"
              :disabled="!projectField.updateable"
            />
          </template>
          <template v-else-if="projectField.type === 'text'">
            <el-input
              v-model="projectField.value"
              :autosize="{ minRows: 2 }"
              :disabled="!projectField.updateable"
              type="textarea"
            />
          </template>
          <template v-else-if="projectField.type === 'number'">
            <el-input
              v-model="projectField.value"
              :disabled="!projectField.updateable"
              type="number"
            />
          </template>
          <template v-else-if="projectField.type === 'decimal'">
            <el-input
              v-model="projectField.value"
              :disabled="!projectField.updateable"
              type="number"
            />
          </template>
          <template v-else-if="projectField.type === 'dropdown'">
            <el-select
              v-model="projectField.value"
              :disabled="!projectField.updateable"
              placeholder="Select..."
            >
              <el-option
                v-for="item in projectField.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
          <p v-else>Unknown field type: {{ projectField.type }}</p>
        </div>
      </div>
      <el-row v-if="isEditingOverwrite === null">
        <el-col :span="24" class="align-right">
          <el-button :disabled="isLoading" type="cancel" @click="onCancel">
            Cancel
          </el-button>
          <el-button :disabled="isLoading" type="primary" @click="onSubmit">
            Save
          </el-button>
        </el-col>
      </el-row>
    </template>
    <template v-else>
      <div v-if="projectFields !== null">
        <p v-if="projectFieldsToView.length === 0 && groupedFieldIds.length === 0" class="info-small">
          Nothing to display
        </p>
        <div
          v-for="(groupItems, groupName) in groupedFields"
          :key="groupName"
        >
          <div
            class="fields_group"
            :class="'fields_group--count_' + groupItems.length"
          >
            <h3>{{groupName}}</h3>
            <div class="group_items">
              <div
                v-for="(projectField, index) in groupItems"
                :key="index"
                class="project_field project_field--show"
              >
                <div class="name">
                  <template v-if="projectField.description">
                    <h3>{{ projectField.description }}</h3>
                    <p>{{ projectField.name }}</p>
                  </template>
                  <template v-else>
                    <h3>{{ projectField.name }}</h3>
                    <p>{{ projectField.description }}</p>
                  </template>
                </div>
                <div class="value">
                  <template v-if="projectField.type === 'date'">
                    {{ formatDate(projectField.value) }}<br/>
                  </template>

                  <template v-else-if="projectField.type === 'datetime'">
                    {{ formatDateTime(projectField.value) }}
                  </template>

                  <template v-else-if="projectField.type === 'dropdown'">
                    {{
                      getOptionLabelFromValue(
                        projectField.value,
                        projectField.options
                      )
                    }}
                  </template>

                  <template v-else>
                    {{ projectField.value }}
                  </template>
                </div>
              </div>
            </div>

          </div>
        </div>

        <p v-if="projectFieldsToView.length !== 0 && groupedFieldIds.length !== 0">
          <strong>Other fields</strong>
        </p>
        <div
          v-for="(projectField, index) in projectFieldsToView"
          :key="index"
          class="project_field project_field--show"
        >
          <div class="name">
            <template v-if="projectField.description">
              <h3>{{ projectField.description }}</h3>
              <p>{{ projectField.name }}</p>
            </template>
            <template v-else>
              <h3>{{ projectField.name }}</h3>
              <p>{{ projectField.description }}</p>
            </template>
          </div>
          <div class="value">
            <template v-if="projectField.type === 'date'">
              {{ formatDate(projectField.value) }}<br/>
            </template>

            <template v-else-if="projectField.type === 'datetime'">
              {{ formatDateTime(projectField.value) }}
            </template>

            <template v-else-if="projectField.type === 'dropdown'">
              {{
                getOptionLabelFromValue(
                  projectField.value,
                  projectField.options
                )
              }}
            </template>

            <template v-else>
              {{ projectField.value }}
            </template>
          </div>
        </div>

        <el-row v-if="projectFieldsToEdit.length || Object.keys(editableGroupedFields).length">
          <el-col :span="24" class="align-right">
            <el-button v-if="canEdit" size="small" @click="onEdit">
              Edit
            </el-button>
          </el-col>
        </el-row>
      </div>
    </template>
    <div v-if="isLoading" v-loading="isLoading" style="padding: 50px 20px"/>
  </div>
</template>

<script>
import moment from 'moment'
import projectMetaFieldsApi from 'api/v2/projectMetaFields'
import {AuthorizationError} from 'api/errors'
import {formatDate, formatDateTime, formatErrorMessage} from 'common/util'
import {cloneDeep} from 'lodash-es'
import {startCase} from 'lodash'
import projectPurchaseInvoiceMetaFieldsApi from '@/js/api/v2/projectPurchaseInvoiceMetaFields';
import projectSalesInvoiceMetaFieldsApi from '@/js/api/v2/projectSalesInvoiceMetaFields';

export default {
  name: 'ProjectMetaFields',

  props: {
    project: {
      type: Object,
      required: true,
    },
    invoice: {
      type: Object,
      required: false,
      default: () => {}
    },
    projectFields: {
      type: Array,
      required: true,
    },
    isEditingOverwrite: {
      type: Boolean,
      default: null,
    },
    onSuccess: {
      type: Function,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    areaId: {
      type: Number,
      default: null,
    },
    category: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      isLoading: false,
      isEditing: false,
      clonedProjectFields: null,
      groupedFields: {},
      groupedFieldIds: [],
      editableGroupedFields: {},
      editableGroupedFieldIds: [],
    }
  },
  computed: {
    projectFieldsToEdit() {
      if (!this.clonedProjectFields) {
        return []
      }
      return this.clonedProjectFields.filter((pf) => {
        if (!pf.canEdit) {
          return false
        }
        if (this.editableGroupedFieldIds.includes(pf.projectFieldId)) {
          return false
        }
        return true
      })
    },
    projectFieldsToView() {
      if (!this.projectFields) {
        return []
      }
      return this.projectFields.filter((pf) => {
        if (!pf.value) {
          return false
        }
        if (this.groupedFieldIds.includes(pf.projectFieldId)) {
          return false
        }
        return true
      })
    },
  },

  watch: {
    projectFields: {
      immediate: true,
      handler(newValue) {
        this.clonedProjectFields = cloneDeep(newValue)
        this.splitGrouped()
        this.splitEditableGrouped()
      },
    },
  },

  mounted() {},
  methods: {
    splitEditableGrouped() {
      this.editableGroupedFields = {}
      this.editableGroupedFieldIds = []

      for (const index in this.clonedProjectFields) {
        if(!this.clonedProjectFields[index].canEdit) {
          continue
        }
        if (
          this.clonedProjectFields[index].group === true &&
          this.clonedProjectFields[index].groupName !== ''
        ) {
          if (this.editableGroupedFields[this.clonedProjectFields[index].groupName] === undefined) {
            this.editableGroupedFields[this.clonedProjectFields[index].groupName] = [];
          }
          if(this.editableGroupedFields[this.clonedProjectFields[index].groupName].length <= 2) {
            this.editableGroupedFields[this.clonedProjectFields[index].groupName].push(this.clonedProjectFields[index])
            this.editableGroupedFieldIds.push(this.clonedProjectFields[index].projectFieldId)
          }
        }
      }

    },
    splitGrouped() {
      this.groupedFields = {}
      this.groupedFieldIds = []

      for (const index in this.projectFields) {
        if(!this.projectFields[index].value) {
          continue
        }
        if (
          this.projectFields[index].group === true &&
          this.projectFields[index].groupName !== ''
        ) {
          if (this.groupedFields[this.projectFields[index].groupName] === undefined) {
            this.groupedFields[this.projectFields[index].groupName] = [];
          }
          if(this.groupedFields[this.projectFields[index].groupName].length <= 2) {
            this.groupedFields[this.projectFields[index].groupName].push(this.projectFields[index])
            this.groupedFieldIds.push(this.projectFields[index].projectFieldId)
          }
        }
      }

    },
    formatDate(value) {
      return formatDate(value)
    },
    formatDateTime(value) {
      return formatDateTime(value)
    },
    getOptionLabelFromValue(value, options) {
      const option = options.find((o) => o.value === value)
      return option ? option.label : value
    },
    onEdit() {
      this.isEditing = true
    },
    onCancel() {
      this.isEditing = false
    },

    onSubmit() {
      if(this.isLoading) {
        return
      }
      this.isLoading = true

      const fields = this.clonedProjectFields.map((field) => {
        let value = field.value
        if (value && field.type === 'date') {
          value = moment(value).format('YYYY-MM-DD')
        } else if (value && field.type === 'datetime') {
          value = moment(value).format('YYYY-MM-DD HH:mm')
        }

        if(this.invoice) {
          return {
            project_invoice_field_id: field.projectInvoiceFieldId,
            value: value,
          }
        }
        return {
          project_field_id: field.projectFieldId,
          value: value,
        }
      })

      if(this.invoice) {
        let projectInvoiceMetaFieldsApi = null
        if(this.invoice.invoiceType === 'purchase') {
          projectInvoiceMetaFieldsApi = projectPurchaseInvoiceMetaFieldsApi
        } else {
          projectInvoiceMetaFieldsApi = projectSalesInvoiceMetaFieldsApi
        }

        if(!projectInvoiceMetaFieldsApi) {
          throw Error('projectInvoiceMetaFieldsApi is NULL');
        }
        projectInvoiceMetaFieldsApi.store(
          (response) => {
            this.onSuccess(response)

            this.$message({
              type: 'success',
              message: `Saved ${startCase(this.category)}`,
              duration: 4000,
            })
            this.$nextTick(() => {
              this.isEditing = false
              this.isLoading = false
            });
          },
          (error) => {
            if (error instanceof AuthorizationError) {
              this.$router.push('/')
            }

            this.$message.error({
              message: formatErrorMessage(error),
            })
            this.$nextTick(() => {
              this.isLoading = false
            });
          },
          this.project.id,
          this.invoice.id,
          {project_meta: fields}
        )
        return
      }

      projectMetaFieldsApi.store(
        (response) => {
          this.onSuccess(response)

          this.$message({
            type: 'success',
            message: `Saved ${startCase(this.category)}`,
            duration: 4000,
          })
          this.$nextTick(() => {
            this.isEditing = false
            this.isLoading = false
          });
        },
        (error) => {
          if (error instanceof AuthorizationError) {
            this.$router.push('/')
          }

          this.$message.error({
            message: formatErrorMessage(error),
          })
          this.$nextTick(() => {
            this.isLoading = false
          });
        },
        this.project.id,
        {project_meta: fields, area_id: this.areaId}
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.info-small {
  font-size: 14px;
  color: #666;
}

.project_field {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-size: 16px;

  &:last-child {
    border: none;
  }

  h3 {
    margin: 0;
    font-size: 16px;
  }

  p {
    font-size: 12px;
    color: #666;
    margin: 0 0 5px 0;
  }

  &--show {
    display: flex;

    .name {
      // min-width: 150px;
      width: 50%;
      padding-right: 10px;
    }
  }
}
.fields_group {
  border-bottom: 2px solid #eeeeee;
  margin-bottom: 15px;

  > h3 {
    margin: 0;
    font-size: 1.3rem;
  }

  .group_items {
    display: flex;


    .el-date-editor,
    .el-select {
      width: 100%;
    }
  }

  &--count_1 {
    .project_field {
      width: 100%;
    }
  }
  &--count_2 {
    .project_field {
      width: 50%;
    }
  }
  &--count_3 {
    .project_field {
      width: 33.333%;
    }
  }
  .project_field {
    flex-direction: column;
    border: none;

    .name {
      padding:0;

      h3 {
        font-size: 15px;
      }
    }
    .value {
      margin-top: auto;
      padding-top: 5px;
    }

    &--show {
      .name {
        width: auto;
      }
    }
  }
  .project_field:nth-child(odd) {
    border: 1px solid #eee;
  }
}
</style>
