import ApiResource from '../ApiResource'

class PricingTemplateCategoryResource extends ApiResource {
  constructor() {
    super({
      name: 'pricing-templates.categories',
    })
  }
}

const pricingTemplateCategoriesApi = new PricingTemplateCategoryResource()

export default pricingTemplateCategoriesApi
