<!-- This is not implemented correctly and adding a position is currently disabled -->
<template>
  <el-dialog
    :visible.sync="visible"
    title="Resolve Notification"
    :before-close="close"
    class="position-add-dialog"
  >
    <el-form
      ref="form"
      label-width="120px"
      label-position="top"
      :model="item"
      :rules="rules"
    >
      <el-row :gutter="5">
        <el-col :span="24">
          <el-form-item ref="subject" label="Subject" prop="subject">
            {{notification.subject}}
          </el-form-item>
          <el-form-item ref="message" label="Message" prop="message">
            {{notification.message}}
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item ref="response" label="Response" prop="response">
            <el-input
              v-model="item.response"
              type="textarea"
              placeholder="Response"
              rows="5"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button type="primary" :disabled="loading" @click="onSubmit"> Resolve Notification</el-button>
        <el-button type="danger" :disabled="loading" @click="close"> Cancel</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import notificationsApi from '@/js/api/v2/notifications';
import {logErrorMessage} from '@/js/common/util';

export default {
  name: 'NotificationResolveDialog',

  props: {
    notification: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      visible: true,
      loading: false,
      item: {
        response: null,
      }
    }
  },
  computed: {
    rules() {
      return {
        response: [
          {
            required: true,
            message: 'Enter a Response',
          },
        ],
      }
    },
  },
  created() {
    if(this.notification.subject === 'Request Subcontractor Template.') {
      this.item.response = 'The Subcontractor template have been created.'
    }
    notificationsApi.update(
      () => {},
      (error) => {
        alert(error)
        this.errors = error
        logErrorMessage(error)
        this.loading = false
      },
      this.notification.id, {
        markSeen: true
      }
    )
  },
  methods: {
    openNotificationDialog() {
      this.visible = true
    },
    close() {
      if(this.loading) {
        return
      }
      this.$emit('close')
    },
    onSubmit() {
      if(this.loading) {
        return
      }
      this.loading = true
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let payload = Object.assign({}, this.item)

          notificationsApi.update(
            () => {
              this.$message({
                type: 'success',
                message: 'Notification updated',
                duration: 4000,
              })
              this.visible = null
              this.item = {
                response: null,
              }
              this.$emit('sent')
              this.loading = false
            },
            (error) => {
              this.errors = error
              logErrorMessage(error)
              this.loading = false
            },
            this.notification.id, {
              ...{
                markResolved: true,
              }, ...payload
            }
          )
        }
      })
    },
  },
}
</script>

<style lang="scss">
.position-add-dialog {
  .el-select {
    width: 100%;
  }
}
</style>
