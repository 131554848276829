<template>
  <div class="table-claim-items">
    <el-table
      v-loading.body="loading"
      :data="sortedItems"
      border
      :summary-method="getSummaries"
      show-summary
      sum-text="Sum"
      :default-sort="{ prop: 'position_item_id', order: 'ascending' }"
      size="mini"
      :highlight-current-row="true"
      stripe
      @sort-change="(val) => $emit('sort-change', val)"
      @filter-change="(val) => $emit('filter-change', val)"
    >
      <!-- <el-table-column width="80" label="Import">
        <template slot-scope="scope">
          {{ scope.row.isImport ? 'Yes' : 'No' }}
        </template>
      </el-table-column> -->
      <!-- <el-table-column prop="executed_at" label="Execution Date" width="150" /> -->

      <el-table-column
        prop="section"
        label="Section"
        width="250"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="reference"
        label="Reference"
        width="120"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="description"
        label="Description"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="comment"
        label="Comment"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="mass"
        label="Mass"
        width="70"
        align="right"
        :formatter="(r, c, val) => formatNumber(val)"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="factor"
        label="Factor"
        width="70"
        align="right"
        :formatter="(r, c, val) => formatNumber(val)"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="price"
        label="Price"
        width="100"
        align="right"
        :formatter="(r, c, val) => formatNumber(val)"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="net"
        label="Net"
        width="100"
        align="right"
        :formatter="(r, c, val) => formatNumber(val)"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="gross"
        label="Gross"
        width="100"
        align="right"
        :formatter="(r, c, val) => formatNumber(val)"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        fixed="right"
        label="Operations"
        width="140"
        :show-overflow-tooltip="true"
        align="right"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            icon="el-icon el-icon-edit"
            @click.native.prevent="editItem(scope.$index)"
          >
          </el-button>
          <el-button
            size="mini"
            icon="el-icon el-icon-delete"
            type="danger"
            @click.native.prevent="removeItem(scope.$index)"
          >
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import numeral from 'numeral'

export default {
  name: 'ClaimItemsTable',

  props: {
    items: {
      type: Array,
      default: () => []
    },
    itemsSummary:  {
      type: Array,
      default: () => []
    },
    loading:  {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    // tries to sort the items similar as to the sort of the project report
    sortedItems() {
      let sortedItems = this.items
      return sortedItems
        .sort((a, b) => {
          // sort by id
          if (a.positionItemId > b.positionItemId) return 1
          if (a.positionItemId < b.positionItemId) return -1
          return 0
        })
        .sort((a, b) => {
          // sort by creation date of original item
          // console.log(a)
          if (!a.positionItem || !b.positionItem) return 0
          if (a.positionItem.created_at > b.positionItem.created_at) return -1
          if (a.positionItem.created_at < b.positionItem.created_at) return 1
          // console.log('not sorting a.id')
          return 0
        })
    },
  },

  methods: {
    editItem(index) {
      this.$emit('editItem', index)
    },

    removeItem(index) {
      this.$emit('removeItem', index)
    },

    getSummaries() {
      return this.itemsSummary
    },

    formatNumber(number) {
      return numeral(number).format()
    },
  },
}
</script>

<style lang="scss">
.table-claim-items {
  .el-table {
    width: 100%;
  }

  .el-table td,
  .el-table th {
    height: 30px;

    div.cell {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>
