<template>
  <div v-if="renderComponent" v-loading="loading" class="bid-values-table">

    <el-row
      v-if="tab === 'open-bids'"
    >
      <div class="bulk-actions">
        <el-select
          v-model="bulkOptionSelected"
          placeholder="Bulk actions"
          size="medium"
          :disabled="bulkOptionsDisabled"
        >
          <el-option
            v-for="(bulkOption, bulkOptionIndex) in bulkOptions"
            :key="bulkOptionIndex"
            :value="bulkOptionIndex"
            :label="bulkOption.label"
          />
        </el-select>
        <el-button
          :disabled="bulkActionDisabled"
          size="medium"
          @click="bulkActionApply()"
        >
          Apply
        </el-button>
      </div>
      <el-button
        type="primary"
        class="bid-add-button"
        size="medium"
        @click.prevent="makeAddDialogVisible()"
      >
        Add Bid
      </el-button>
    </el-row>
    <div ref="main" :class="containerCssClass">
      <el-row class="native-table-outer-row">
        <el-col class="native-table-outer">
          <el-table
            :data="data"
            :highlight-current-row="true"
            size="mini"
            stripe
            :height="tableHeight"
            @sort-change="changeSort"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              v-if="tab === 'open-bids'"
              type="selection"
              width="20"
            />
            <el-table-column :fixed="true" width="125">
              <template v-if="hasFiltersApplied()" slot="header">
                <div class="filters-clear">
                  <el-button type="primary" size="mini" @click="clearFilters">
                    Clear
                  </el-button>
                </div>
              </template>
              <template slot-scope="scope">
                <el-button
                  v-if="tab === 'open-bids'"
                  icon="el-icon-edit"
                  size="mini"
                  title="Edit/Finalize Bid"
                  type="primary"
                  @click.native.prevent="editItem(scope.row.id)"
                >
                </el-button>
                <el-button
                  v-if="tab === 'open-bids'"
                  icon="el-icon-document-copy"
                  size="mini"
                  title="Clone Bid"
                  type="primary"
                  @click.native.prevent="cloneItem(scope.row.id)"
                >
                </el-button>
                <el-button
                  v-if="tab === 'open-bids'"
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  title="Close Bid"
                  @click.native.prevent="closeItem(scope.row.id)"
                >
                </el-button>
                <el-button
                  v-if="tab === 'finalized-bids'"
                  size="mini"
                  icon="el-icon-warning"
                  title="Reopen Bid"
                  @click.native.prevent="reopenItem(scope.row.id)"
                >
                </el-button>
                <el-button
                  v-if="tab === 'closed-bids'"
                  icon="el-icon-warning"
                  size="mini"
                  title="Reopen Bid"
                  @click.native.prevent="restoreItem(scope.row.id)"
                >
                </el-button>
                <el-button
                  v-if="tab === 'closed-bids' && canDeleteBid()"
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  title="Hard Delete Bid"
                  @click.native.prevent="hardDelete(scope.row.id)"
                >
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              v-for="(column, indexNumber) in columnsToRender"
              :key="column"
              :column-key="column"
              :fixed="isFixedLeft(column, indexNumber)"
              :label="column"
              :prop="column"
              :show-overflow-tooltip="true"
              :width="getColumnWidth(column)"
              :label-class-name="getColumnClass(column)"
              :class-name="getColumnClass(column)"
              sortable="custom"
            >
              <!-- eslint-disable vue/no-unused-vars -->
              <template slot="header" slot-scope="scope">
                <div :class="loading ? 'loading' : ''" class="header-search">
                  <el-select
                    v-model="searchKeywords[column]"
                    clearable
                    filterable
                    multiple
                    popper-class="header-search-with-groups"
                    @change="searchFilterChange($event, column)"
                  >
                    <el-option-group
                      v-if="getColumnFilters(column, 'F').length"
                    >
                      <el-option
                        v-for="(value, option_index) in getColumnFilters(column, 'F')"
                        :key="'f'+option_index"
                        :label="value"
                        :value="value"
                      />
                    </el-option-group>
                    <el-option-group
                      v-if="getColumnFilters(column, 'O').length"
                      class="other-filters"
                    >
                      <el-option
                        v-for="(value, option_index) in getColumnFilters(column, 'O')"
                        :key="'o'+option_index"
                        :label="value"
                        :value="value"
                        :class="filters[column] && filters[column].includes(value) ? 'hidden' : ''"
                      >
                        {{value}}
                      </el-option>
                    </el-option-group>
                  </el-select>
                </div>

                {{ visibleTableColumnsNames[column] }}
              </template>
              <template slot-scope="scope">
                <div
                  :class="cellContentClass(column)"
                  @click="toggleEdit(scope.row, column)"
                >
                  <template
                    v-if="getFieldType(column) === 'date' && scope.row[column]"
                  >
                    {{ formatDisplayDate(scope.row[column]) }}
                  </template>
                  <template v-else>
                    {{ scope.row[column] || '&nbsp;' }}
                  </template>
                  <span v-if="cellIsUpdateable(column)" class="editable-icon">
                    <i class="el-icon-edit" title="Click to edit"></i>
                  </span>
                </div>
                <el-input
                  v-if="
                    cellIsUpdateable(column) &&
                    editInputShow &&
                    editColName === column &&
                    editRowID === scope.row.id &&
                    editInputType === 'text'
                  "
                  v-model="editInputValue"
                  :name="editColName"
                  class="cell-input"
                  size="small"
                  type="text"
                  @blur="updateField($event, scope.row)"
                  @keyup.enter.native="handleEnter($event, scope.row)"
                />
                <el-input
                  v-if="
                    cellIsUpdateable(column) &&
                    editInputShow &&
                    editColName === column &&
                    editRowID === scope.row.id &&
                    editInputType === 'number'
                  "
                  v-model="editInputValue"
                  :name="editColName"
                  :step="editNumberInputStep"
                  class="cell-input"
                  size="small"
                  type="number"
                  @blur="updateField($event, scope.row)"
                  @keyup.enter.native="handleEnter($event, scope.row)"
                />
                <el-date-picker
                  v-if="
                    cellIsUpdateable(column) &&
                    editInputShow &&
                    editColName === column &&
                    editRowID === scope.row.id &&
                    editInputType === 'date'
                  "
                  v-model="editInputValue"
                  class="cell-input"
                  format="dd.MM.yyyy"
                  placeholder="Pick a date"
                  size="small"
                  style="width: 100%"
                  type="date"
                  @change="updateDateField($event, scope.row, column)"
                />
                <el-select
                  v-if="
                    cellIsUpdateable(column) &&
                    editInputShow &&
                    editColName === column &&
                    editRowID === scope.row.id &&
                    editInputType === 'dropdown'
                  "
                  v-model="editInputValue"
                  :name="editColName"
                  class="cell-input"
                  @change="updateSelectField($event, scope.row, column)"
                >
                  <el-option
                    v-for="item in loadedConfiguration.metaFields[column]
                      .options"
                    :key="item.value"
                    :label="item.label"
                    :selected="editInputValue == item.value"
                    :value="item.value"
                  />
                </el-select>
              </template>
              <!-- eslint-enable -->
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row class="pagination-row">
        <el-button
          class="setting"
          type="primary"
          @click="dialogConfigVisible = true"
          ><i class="el-icon-setting"></i
        ></el-button>

        <template v-if="data.length">
          <el-button
            type="primary"
            class="export"
            :loading="generatingExcelFile"
            @click="generateExcelFile('xlsx')"
            >Download excel file</el-button
          >
          <el-button
            type="primary"
            class="export"
            :loading="generatingExcelFile"
            @click="generateExcelFile('csv')"
            >Download csv file</el-button
          >
        </template>
        <Pagination
          :per-page="perPage"
          :total="total"
          :current-page="currentPage"
          :page-sizes="[15, 30, 50, 100]"
          @pageChange="pageChange"
          @changePageSize="changePageSize"
        />
      </el-row>
      <el-row
        v-if="allRevisions && allRevisions.length"
        class="revision-card-wrapper"
      >
        <el-row class="toggle">
          Bid value revisions

          <el-button
            class="toggle-button"
            type="primary"
            @click="revisionsVisible = !revisionsVisible"
          >
            <template v-if="revisionsVisible === true">
              Hide Revisions
            </template>
            <template v-if="revisionsVisible === false">
              Show Revisions
            </template>
          </el-button>
        </el-row>
        <div v-if="allRevisions && allRevisions.length">
          <revision-table
            :revisions="allRevisions"
            :height="revisionTableHeight"
            card-title="Project meta Fields revisions"
            type="bidValue"
            :hide-columns="hideColumnsInRevision"
          >
          </revision-table>
        </div>
      </el-row>
    </div>
    <el-dialog
      title="Are you sure you want to reopen this bid?"
      top="0"
      :visible.sync="dialogReopenVisible"
      class="question-dialog"
      @close="stopReopenItem"
    >
      <label>Do you want to clear bid management values from the area?</label>
      <el-form ref="form" label-width="120px" label-position="top">
        <el-form-item>
          <el-checkbox v-model="reopenClearMeta">
            Yes, clear area meta
          </el-checkbox>
        </el-form-item>
      </el-form>
      <el-row class="text-right">
        <el-col>
          <el-button type="default" @click.native.prevent="stopReopenItem()">
            Cancel
          </el-button>
          <el-button
            type="primary"
            @click.native.prevent="reopenItem(itemToReopen)"
          >
            Reopen
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog
      title="Are you sure you want to hard delete this bid?"
      top="0"
      :visible.sync="dialogDeleteVisible"
      class="question-dialog"
      @close="cancelDeleteBid"
    >
      <el-alert
        title="This is a final change and cannot be undone."
        type="warning"
        description="All Bid Values for this bid will be deleted."
        effect="dark"
        :closable="false"
        show-icon
      >
      </el-alert>

      <el-form ref="form" label-width="120px" label-position="top">
        <el-form-item>
          <el-checkbox v-model="dialogDeleteAgree">
            Yes, hard delete this bid and all its bid values.
          </el-checkbox>
        </el-form-item>
      </el-form>
      <el-row class="text-right">
        <el-col>
          <el-button type="default" @click.native.prevent="cancelDeleteBid()">
            Cancel
          </el-button>
          <el-button
            type="danger"
            @click.native.prevent="hardDelete(bidIdToHardDelete)"
          >
            Hard Delete
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogConfigVisible"
      title="Configuration"
      top="0"
      @close="onDialogConfigClose"
    >
      <el-row class="top-row" type="flex">
        <el-col :span="4">
          <h4>Categories</h4>
          <div>
            <el-checkbox-group v-model="filteredCategories">
              <el-checkbox
                v-for="(element, index) in loadedConfiguration.categories"
                :key="element"
                :label="element"
              >
                {{ categoryLabels[element] }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </el-col>
        <el-col :offset="1" :span="8">
          <h4>Available Fields</h4>
          <span class="add-all" @click="addAllFieldsToVisible">Add All</span>
          <draggable
            :list="availableTableColumnsList"
            class="list-group"
            group="visibleFields"
          >
            <div
              v-for="(element, index) in availableTableColumnsList"
              :key="element.name"
              class="list-group-item"
            >
              {{ element.name }}
              <i
                v-if="
                  searchKeywords['meta_' + element.id] &&
                  searchKeywords['meta_' + element.id].length
                "
                class="el-icon-warning"
                title="There is a search filter applied to this field"
              ></i>
              <span :class="'cat-' + element.category">
                {{ categoryLabels[element.category] }}
              </span>
            </div>
          </draggable>
        </el-col>
        <el-col :offset="1" :span="8">
          <h4>Selected Fields</h4>
          <span class="remove-all" @click="removeAllFieldsFromVisible"
            >Remove All</span
          >
          <draggable
            :list="loadedConfiguration.visibleColumns"
            class="list-group"
            group="visibleFields"
          >
            <div
              v-for="(element, index) in loadedConfiguration.visibleColumns"
              :key="element.name"
              class="list-group-item"
            >
              {{ element.name }}
              <i
                v-if="
                  searchKeywords['meta_' + element.id] &&
                  searchKeywords['meta_' + element.id].length
                "
                class="el-icon-warning"
                title="There is a search filter applied to this field"
              ></i>
              <span :class="'cat-' + element.category">
                {{ categoryLabels[element.category] }}
              </span>
            </div>
          </draggable>
        </el-col>
      </el-row>
      <el-row class="bottom-row" type="flex">
        <el-col :span="22" justify="right">
          <el-button
            class="setting"
            type="primary"
            @click="dialogConfigVisible = false"
          >
            Close
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { can, formatErrorMessage } from '../../common/util'
import draggable from 'vuedraggable'
import RevisionTable from 'components/Common/RevisionTable'
import moment from 'moment'
import BidsApi from 'api/v2/bids'
import Pagination from '@/js/components/Common/DefaultPagination.vue'

export default {
  name: 'BidTable',
  components: {
    draggable,
    RevisionTable,
    Pagination,
  },
  props: {
    tab: {
      type: String,
      required: true,
    },
    api: {
      type: Object,
      required: true,
    },
    apiUpdate: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      checkAll: false,
      checkedItems: [],
      isIndeterminate: false,
      windowHeight: window.innerHeight,
      currentPage: 1,
      total: null,
      // Overridden by response payload
      perPage: 15,
      loading: true,
      spinner: true,
      data: [],
      filters: {},
      filtersAllPossible: {},
      filtersLoaded: false,
      loadedConfiguration: [],
      loadedRevisions: [],
      revisionsVisible: false,
      visibleTableColumns: [],
      fixedTableColumns: [],
      maxFixedColumns: 3,
      visibleTableColumnsNames: {},
      editableColumns: {},
      availableTableColumnsList: [],
      dialogConfigVisible: false,
      dialogDiscardVisible: false,
      dialogReopenVisible: false,
      itemToReopen: false,
      reopenClearMeta: false,
      filteredCategories: [],
      searchKeywords: [],
      renderComponent: true,
      categoryLabels: {
        general: 'General',
        customer: 'Customer',
        bid_management: 'Bid Management',
        controlling: 'Controlling',
        bauleiters: 'Bauleiters',
        engineering: 'Engineering',
        project_fields: 'Project Fields',
        invoicing: 'Invoicing',
        finance: 'Finance',
        closing: 'Closing',
        other: 'Other',
      },
      editRowID: null,
      editColName: null,
      editInputShow: false,
      editInputType: 'text',
      editNumberInputStep: '1',
      editInputValue: '',
      editInputName: '',
      generatingExcelFile: false,
      dialogDeleteVisible: false,
      dialogDeleteShown: false,
      bidIdToHardDelete: null,
      dialogDeleteAgree: false,
      order: null,
      orderBy: null,
      bulkOptions: [
        {
          method: 'closeItems', // This must be a callable method in this module
          label: 'Close Bids', // The label of the Bulk Actions select option
          confirmationMessage: 'Are you sure you want to close selected bids?', // The confirmation question that will be asked
        }
      ],
      // null or the index key of the 'Bulk Action' selected from bulkOptions
      bulkOptionSelected:null
    }
  },
  computed: {
    hideColumnsInRevision() {
      if (this.tab === 'finalized-bids') {
        return []
      }
      return ['bidValue.projectname', 'bidValue.area']
    },
    bulkOptionsDisabled() {
      return !this.checkedItems['length']
    },
    revisionTableHeight() {
      // The revisions table must be small enough to fit without the page getting a scrollbar
      return `${this.windowHeight - 575}`
    },
    tableHeight() {
      // When the revisions are visible the table need to be small
      if(this.revisionsVisible) {
        return '250'
      }
      // Else the table must be small enough to fit without the page getting a scrollbar
      return `${this.windowHeight - 325}`
    },
    bulkActionDisabled() {
      if (!this.bulkOptionsDisabled && this.bulkOptionSelected !== null) {
        return false
      }
      return true
    },
    columnsToRender() {
      return this.fixedTableColumns.concat(this.visibleTableColumns)
    },
    containerCssClass() {
      let classes = []
      classes.push(this.revisionsVisible ? 'show-revisions' : 'hide-revisions')
      classes.push(this.tab)
      return classes.join(' ')
    },
    dateFormat() {
      return 'dd.MM.yyyy'
    },
    allRevisions() {
      let result = this.loadedRevisions.map(function (currentObject) {
        currentObject.username =
          currentObject.user.firstname + ' ' + currentObject.user.lastname
        currentObject.updated_at = currentObject.updatedAt
        return currentObject
      })
      return result
    },
  },
  watch: {
    filteredCategories() {
      this.filterAvailableTableColumnsList()
    },
    revisionsVisible() {},
    data() {
      this.leftTableOffset()
    },
  },
  mounted() {
    this.leftTableOffset()
  },
  created() {
    this.loadConfiguration()
    this.loadPage(1)
    this.loadRevisions()
    let self = this
    document.addEventListener('keyup', function (evt) {
      if (self.editInputShow !== undefined) {
        if (self.editInputShow === true) {
          if (evt.key === 'Escape') {
            self.editInputShow = false
          }
        }
      }
    })
  },
  methods: {
    makeAddDialogVisible() {
      //eslint-disable-next-line
      console.log('makeAddDialogVisible - before')
      this.$emit('addnew');
    },
    canDeleteBid() {
      return can('delete', 'bid')
    },
    leftTableOffset() {
      let column = null
      let fixed = null
      let width = 0
      for (const indexNumber in this.columnsToRender) {
        column = this.columnsToRender[indexNumber]
        fixed = this.isFixedLeft(column, indexNumber)
        if (!fixed) {
          continue
        }
        width += this.getColumnWidth(column)
      }

      width += 125

      if (this.tab === 'open-bids') {
        // This is to make space for the select column
        width += 20
      }

      let tables_bodies = document.querySelectorAll(
        '.native-table-outer-row  .el-table--scrollable-x .el-table__body-wrapper > table'
      )
      for (let i = 0; i < tables_bodies.length; i++) {
        tables_bodies[i].style['margin-left'] = '-' + width + 'px'
      }
      let tables_headers = document.querySelectorAll(
        '.native-table-outer-row  .el-table--scrollable-x .el-table__header-wrapper > table'
      )
      for (let i = 0; i < tables_headers.length; i++) {
        tables_headers[i].style['margin-left'] = '-' + width + 'px'
      }

      let tables_bodies_wrap = document.querySelectorAll(
        '.native-table-outer-row  .el-table--scrollable-x .el-table__body-wrapper'
      )
      for (let i = 0; i < tables_bodies_wrap.length; i++) {
        tables_bodies_wrap[i].style['margin-left'] = width + 'px'
        tables_bodies_wrap[i].style['width'] = 'calc(100% - ' + width + 'px)'
      }
      let tables_headers_wrap = document.querySelectorAll(
        '.native-table-outer-row  .el-table--scrollable-x .el-table__header-wrapper'
      )
      for (let i = 0; i < tables_headers_wrap.length; i++) {
        tables_headers_wrap[i].style['margin-left'] = width + 'px'
        tables_headers_wrap[i].style['width'] = 'calc(100% - ' + width + 'px)'
      }
    },
    getColumnWidth(column) {
      if (['cost_object_code', 'project_name'].includes(column)) {
        return 200
      }
      return this.visibleTableColumnsNames[column].length * 10 + 40
    },
    filtersAppliedLabel(column) {
      if (!this.hasFilterApplied(column)) {
        return ''
      }

      if (this.searchKeywords[column].length === 1) {
        return '1 filter'
      }
      return this.searchKeywords[column].length + ' filters'
    },
    hasFilterApplied(column) {
      if (
        this.searchKeywords[column] &&
        this.searchKeywords[column].length > 0
      ) {
        return true
      }
      return false
    },
    getColumnClass(column) {
      if (this.hasFilterApplied(column)) {
        return 'has-applied-filter'
      }
      return ''
    },
    hasFiltersApplied() {
      let filters = this.filterSearchKeywords()
      if (Object.keys(filters).length > 0) {
        return true
      }
      return false
    },
    clearFilters() {
      this.searchKeywords = {}
      this.loadPage(1)
    },
    getColumnFilters(column, group) {
      if(group === 'F') {
        if (!this.filters[column]) {
          return []
        }
        return this.filters[column];
      }
      if (!this.filtersAllPossible[column]) {
        return []
      }
      return this.filtersAllPossible[column];
    },
    filterOptionsColumn(column) {
      if (this.filters[column]) {
        return this.filters[column]
      }
      return []
    },
    getFieldType(column) {
      if (this.loadedConfiguration.metaFields[column]) {
        return this.loadedConfiguration.metaFields[column].type
      }
      return 'text'
    },
    formatDisplayDate(value) {
      return moment(value).format('DD.MM.YYYY')
    },
    editItem(bidId) {
      localStorage.bidFilters = JSON.stringify(this.filterSearchKeywords())
      localStorage.bidorder = this.order
      localStorage.bidorderBy = this.orderBy
      localStorage.bidCurrentPage = this.currentPage
      localStorage.bidPerPage = this.perPage

      this.$router.push('/bids/' + bidId + '/edit')
    },
    cancelDeleteBid() {
      this.dialogDeleteVisible = false
      this.dialogDeleteShown = false
      this.bidIdToHardDelete = null
      this.dialogDeleteAgree = false
    },
    hardDelete(bidId) {
      if (!this.dialogDeleteVisible && !this.dialogDeleteShown) {
        this.dialogDeleteVisible = true
        this.dialogDeleteShown = true
        this.bidIdToHardDelete = bidId
        return
      }

      if (!this.dialogDeleteAgree) {
        this.$message.error({
          showClose: true,
          message:
            'To hard delete this bid you must tick the confirmation box.',
          duration: 5000,
        })
        return
      }

      BidsApi.delete(
        (response) => {
          this.cancelDeleteBid()
          if (response === 1) {
            this.$message({
              type: 'success',
              message: 'Bid hard-deleted',
              duration: 4000,
            })
            this.loadPage(this.currentPage)
          }
        },
        (error) => {
          this.cancelDeleteBid()

          this.$message.error({
            showClose: true,
            message: formatErrorMessage(error),
            duration: 5000,
          })
        },
        bidId
      )
    },
    cloneItem(bidId) {
      let confirm = window.confirm('Are you sure you want to clone this bid?')
      if (!confirm) {
        return
      }
      BidsApi.clone(
        () => {
          this.$message({
            type: 'success',
            message: 'Bid cloned',
            duration: 4000,
          })
          this.loadPage(this.currentPage)
        },
        (error) => {
          this.$message.error({
            showClose: true,
            message: formatErrorMessage(error),
            duration: 5000,
          })
        },
        bidId
      )
    },
    closeItem(bidId) {
      let confirm = window.confirm('Are you sure you want to close this bid?')
      if (!confirm) {
        return
      }
      BidsApi.close(
        () => {
          this.$message({
            type: 'success',
            message: 'Bid closed',
            duration: 4000,
          })
          this.loadPage(this.currentPage)
        },
        (error) => {
          this.$message.error({
            showClose: true,
            message: formatErrorMessage(error),
            duration: 5000,
          })
        },
        bidId
      )
    },
    handleSelectionChange(val) {
      this.checkedItems = val
    },
    bulkActionApply() {
      const bidIds = this.checkedItems.map((b) => b.id)

      // Which action should we use for this bulk action
      const action = this.bulkOptions[this.bulkOptionSelected]

      let confirm = window.confirm(action.confirmationMessage)
      if (!confirm) {
        return
      }

      // Call the method selected
      this[action.method](bidIds)
    },
    closeItems(ids) {
      BidsApi.closeBulk(
        () => {
          this.$message({
            type: 'success',
            message: 'Bids closed',
            duration: 4000,
          })
          this.loadPage(this.currentPage)
        },
        (error) => {
          this.$message.error({
            showClose: true,
            message: formatErrorMessage(error),
            duration: 5000,
          })
        },
        {
          ids: ids,
        }
      )
    },
    restoreItem(bidId) {
      let confirm = window.confirm('Are you sure you want to reopen this bid?')
      if (!confirm) {
        return
      }
      BidsApi.restore(
        (response) => {
          if (response === 1) {
            this.$message({
              type: 'success',
              message: 'Bid reopened',
              duration: 4000,
            })
            this.loadPage(this.currentPage)
          }
        },
        (error) => {
          this.$message.error({
            showClose: true,
            message: formatErrorMessage(error),
            duration: 5000,
          })
        },
        bidId
      )
    },
    stopReopenItem() {
      this.dialogReopenVisible = false
      this.itemToReopen = false
      this.reopenClearMeta = false
    },
    reopenItem(bidId) {
      if (this.itemToReopen === false) {
        this.dialogReopenVisible = true
        this.itemToReopen = bidId
        return
      }

      BidsApi.reopen(
        () => {
          this.$message({
            type: 'success',
            message: 'Bid reopened',
            duration: 4000,
          })

          this.dialogReopenVisible = false
          this.itemToReopen = false
          this.reopenClearMeta = false

          this.loadPage(this.currentPage)
        },
        (error) => {
          this.$message.error({
            showClose: true,
            message: formatErrorMessage(error),
            duration: 5000,
          })
        },
        bidId,
        {
          clear: this.reopenClearMeta,
        }
      )
    },
    isFixedLeft(column, indexNumber) {
      if (indexNumber >= this.maxFixedColumns) {
        return false
      }
      if (this.loadedConfiguration.metaFields[column]) {
        return this.loadedConfiguration.metaFields[column].required_for_bids
      }
      return false
    },
    getFixedLeft(column, indexNumber) {
      if (!this.fixedTableColumns.includes(column)) {
        return 0
      }
      if (indexNumber >= this.maxFixedColumns) {
        return 0
      }
      let left = 60 + indexNumber * 150
      return 'left:' + left + 'px;'
    },
    searchFilterChange(value, field) {
      if (this.loading) {
        return
      }
      if (!this.filtersLoaded) {
        return
      }
      this.searchKeywords[field] = value
      this.loadPage(1)
    },
    updateFiltersLabels() {
      let filter_selects = document.querySelectorAll(
        '.header-search .el-select'
      )
      for (let i = 0; i < filter_selects.length; i++) {
        const number_filters = filter_selects[i].querySelectorAll('.el-tag')
        if (!number_filters || number_filters.length === 0) {
          filter_selects[i].querySelectorAll('input')[0].placeholder = ''
          continue
        }
        let suffix_string = '1 filter'
        if (number_filters.length > 1) {
          suffix_string = number_filters.length + ' filters'
        }
        filter_selects[i].querySelectorAll('input')[0].placeholder =
          suffix_string
      }
    },
    cellIsUpdateable(column) {
      if (this.tab === 'finalized-bids') {
        return false
      }
      if (this.tab === 'closed-bids') {
        return false
      }
      if (this.loadedConfiguration.metaFields[column] === undefined) {
        return false
      }
      return this.loadedConfiguration.metaFields[column].updateable
    },
    cellContentClass(column) {
      return this.cellIsUpdateable(column)
        ? 'cell-content updateable'
        : 'cell-content '
    },
    changePageSize(newPageSize) {
      this.perPage = newPageSize
      this.pageChange(1)
    },
    pageChange(val) {
      this.loading = true
      this.spinner = true
      this.currentPage = val
      this.loadPage(val)
    },
    loadRevisions() {
      this.api.revisions(
        (response) => {
          this.loadedRevisions = response
        },
        (error) => {
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        {
          filterTab: this.tab,
        }
      )
    },
    changeSort({ prop, order }) {
      this.orderBy = prop
      if (order === 'ascending') {
        this.order = 'asc'
      } else if (order === 'descending') {
        this.order = 'desc'
      } else {
        this.order = null
      }

      if (this.order === null || this.orderBy === null) {
        this.order = null
        this.orderBy = null
      }

      this.pageChange(1)
      return false
    },
    loadPage(page) {
      if (
        localStorage.bidorder !== undefined &&
        localStorage.bidorder !== null &&
        localStorage.bidorder !== 'null' &&
        localStorage.bidorderBy !== undefined &&
        localStorage.bidorderBy !== null &&
        localStorage.bidorderBy !== 'null'
      ) {
        this.order = localStorage.bidorder
        this.orderBy = localStorage.bidorderBy
        delete localStorage.bidorder
        delete localStorage.bidorderBy
      }

      if (
        localStorage.bidCurrentPage !== undefined &&
        localStorage.bidCurrentPage !== null &&
        localStorage.bidCurrentPage !== 'null'
      ) {
        this.currentPage = parseInt(localStorage.bidCurrentPage)
        page = this.currentPage
        delete localStorage.bidCurrentPage
      }

      if (
        localStorage.bidPerPage !== undefined &&
        localStorage.bidPerPage !== null &&
        localStorage.bidPerPage !== 'null'
      ) {
        this.perPage = parseInt(localStorage.bidPerPage)
        delete localStorage.bidPerPage
      }

      let keywords = {}
      if (localStorage.bidFilters !== undefined) {
        keywords = localStorage.bidFilters
        this.searchKeywords = JSON.parse(keywords)
        delete localStorage.bidFilters
      } else {
        keywords = JSON.stringify(this.filterSearchKeywords())
      }

      this.loading = true
      this.spinner = true

      this.api.index(
        (response) => {
          this.data = response.data
          this.filters = response.filters
          this.filtersAllPossible = response.filtersAllPossible

          this.loading = false
          this.spinner = false
          this.filtersLoaded = true

          this.total = response.meta.total
          this.currentPage = response.meta.current_page

          this.updateFiltersLabels()
        },
        (error) => {
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        {
          page: page,
          perPage: this.perPage,
          searchKeywords: keywords,
          orderBy: this.orderBy,
          order: this.order,
          filterTab: this.tab,
        }
      )
    },
    filterSearchKeywords() {
      // Ok so 2 things, remove any empty values and also remove non-visible columns
      let keywordsFiltered = {}
      for (const field in this.searchKeywords) {
        let value = this.searchKeywords[field]
        if (value && value.length > 0) {
          if (this.visibleTableColumns.includes(field)) {
            keywordsFiltered[field] = value
          } else if (this.fixedTableColumns.includes(field)) {
            keywordsFiltered[field] = value
          }
        }
      }
      return keywordsFiltered
    },
    loadConfiguration() {
      this.api.configuration(
        (response) => {
          this.loadedConfiguration = response
          this.updateVisibleFields()
        },
        (error) => {
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        }
      )
    },
    addAllFieldsToVisible() {
      for (const index in this.availableTableColumnsList) {
        const column = this.availableTableColumnsList[index]
        this.loadedConfiguration.visibleColumns.push(column)
      }
      this.filterAvailableTableColumnsList()
    },
    removeAllFieldsFromVisible() {
      for (const index in this.loadedConfiguration.visibleColumns) {
        const column = this.loadedConfiguration.visibleColumns[index]
        this.availableTableColumnsList.push(column)
      }
      this.loadedConfiguration.visibleColumns = []
      this.filterAvailableTableColumnsList()
    },
    updateVisibleFields() {
      this.renderComponent = false
      // Set the table fields/columns
      this.visibleTableColumns = []
      this.fixedTableColumns = []
      this.visibleTableColumnsNames = {}

      // set Fixed Columns
      for (const index in this.loadedConfiguration.metaFields) {
        const column = this.loadedConfiguration.metaFields[index]
        const colKey = 'meta_' + column.id.toString()
        if (
          column.required_for_bids &&
          this.fixedTableColumns.length < this.maxFixedColumns
        ) {
          this.fixedTableColumns.push(colKey)
          this.visibleTableColumnsNames[colKey] = column.name
        }
      }

      // and Also other columns
      if (this.loadedConfiguration.visibleColumns.length > 0) {
        for (const index in this.loadedConfiguration.visibleColumns) {
          const column = this.loadedConfiguration.visibleColumns[index]
          const colKey = 'meta_' + column.id.toString()
          const metaColumn = this.loadedConfiguration.metaFields[colKey]

          if (
            !metaColumn.required_for_bids ||
            !this.fixedTableColumns.includes(colKey)
          ) {
            this.visibleTableColumns.push(colKey)
            this.visibleTableColumnsNames[colKey] = column.name
          }
        }
      } else {
        for (const index in this.loadedConfiguration.metaFields) {
          const column = this.loadedConfiguration.metaFields[index]
          const colKey = 'meta_' + column.id.toString()
          const metaColumn = this.loadedConfiguration.metaFields[colKey]

          if (
            !metaColumn.required_for_bids ||
            !this.fixedTableColumns.includes(colKey)
          ) {
            this.visibleTableColumns.push(colKey)
            this.visibleTableColumnsNames[colKey] = column.name
          }
        }
      }

      this.visibleTableColumns.push('created_by')
      this.visibleTableColumnsNames.created_by = 'Created By'

      // Set the category filter to show all by default
      this.filteredCategories = []
      for (const index in this.loadedConfiguration.categories) {
        this.filteredCategories.push(index)
      }
      this.$nextTick(() => {
        this.renderComponent = true
      })

      this.filterAvailableTableColumnsList()
    },
    filterAvailableTableColumnsList() {
      let visible = []
      this.availableTableColumnsList = []

      for (const index in this.loadedConfiguration.visibleColumns) {
        const column = this.loadedConfiguration.visibleColumns[index]
        visible.push(column.id)
      }
      let assignedToFixed = 0
      for (const index in this.loadedConfiguration.metaFields) {
        const column = this.loadedConfiguration.metaFields[index]
        if (!visible.includes(column.id)) {
          if (this.filteredCategories.includes(column.category)) {
            if (
              !column.required_for_bids ||
              assignedToFixed >= this.maxFixedColumns
            ) {
              this.availableTableColumnsList.push(column)
            } else {
              assignedToFixed++
            }
          }
        }
      }
    },
    onDialogConfigClose() {
      this.updateVisibleFields()
      this.loadPage(1)
      this.api.updateConfiguration(
        (response) => {
          if (response.success !== 0) {
            this.$message.error({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(response),
              duration: 0,
            })
          }
        },
        (error) => {
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.loadedConfiguration.visibleColumns
      )
    },
    handleCtrlClick(event, row, column) {
      if(!event.ctrlKey) {
        return false
      }
      if(!this.searchKeywords[column]) {
        this.searchKeywords[column] = [];
      }
      if(!this.searchKeywords[column].includes(row[column])) {
        this.searchKeywords[column].push(row[column])
      } else {
        let index = this.searchKeywords[column].indexOf(row[column]);
        if (index !== -1) {
          this.searchKeywords[column].splice(index, 1);
        }
      }
      this.loadPage(1)
      return true
    },
    toggleEdit(row, column) {
      if(this.handleCtrlClick(event, row, column)) {
        return
      }
      if (!this.cellIsUpdateable(column)) {
        return
      }
      this.editInputShow = true
      this.editRowID = row.id
      this.editColName = column
      this.editInputValue = row[column]
      this.editInputType = this.getFieldType(column)

      if (['text', 'string'].includes(this.editInputType)) {
        this.editInputType = 'text'
      }

      if (['number', 'decimal'].includes(this.editInputType)) {
        if (this.editInputType === 'decimal') {
          this.editNumberInputStep = '0.01'
        } else {
          this.editNumberInputStep = '1'
        }
        this.editInputType = 'number'
      }
    },
    reRender() {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    handleEnter(event, row) {
      this.updateField(event, row)
    },
    updateDateField(value, row, field) {
      let data = {
        field: field,
        value: value,
        bid_id: row.id,
      }
      this.updateToServer(data, row)
    },
    updateSelectField(value, row, field) {
      let data = {
        field: field,
        value: value,
        bid_id: row.id,
      }
      this.updateToServer(data, row)
    },
    updateField(event, row) {
      let data = {
        field: event.target.name,
        value: event.target.value,
        bid_id: row.id,
      }
      this.updateToServer(data, row)
    },
    updateToServer(data, row) {
      this.editInputShow = false

      if (row[data.field] === undefined || row[data.field] === null) {
        row[data.field] = ''
      }
      if (row[data.field] === data.value) {
        return ''
      }
      data.project_field_id = data.field.replace('meta_', '')

      let originalValue = row[data.field]
      if (
        data.value &&
        ['date'].includes(this.getFieldType('meta_' + data.project_field_id))
      ) {
        data.value = moment(data.value, 'DD.MM.YYYY').format(
          'YYYY-MM-DD'
        )
      }
      row[data.field] = data.value
      this.editInputShow = false

      this.apiUpdate.update(
        (response) => {
          if (!response.message) {
            response.message = 'Value Saved'
          }

          if (response.validationErrors) {
            let error_message = [
              '<strong>Value not saved, see error(s):</strong><br />',
            ]

            for (const field_key in response.validationErrors) {
              error_message.push(
                response.validationErrors[field_key].name +
                  ': ' +
                  response.validationErrors[field_key].error
              )
            }
            this.$message.error({
              showClose: true,
              type: 'error',
              dangerouslyUseHTMLString: true,
              message: error_message.join('<br />'),
              duration: 0,
            })
          } else {
            this.$message.success({
              message: response.message,
            })
          }

          this.loadRevisions()
        },
        (error) => {
          row[data.field] = originalValue
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        data.bid_id,
        {
          bid_values: [
            {
              project_field_id: data.project_field_id,
              value: data.value,
            },
          ],
        }
      )
    },
    generateExcelFile(extension) {
      this.generatingExcelFile = true

      const params = {}

      params['extension'] = extension
      params['isExport'] = extension
      params['page'] = 1
      params['perPage'] = 1000000
      params['searchKeywords'] = JSON.stringify(this.filterSearchKeywords())
      params['filterTab'] = this.tab

      BidsApi.exportExcel(
        (response) => {
          this.generatingExcelFile = false
          window.location = response
        },
        (error) => {
          this.generatingExcelFile = false
          if (error) {
            this.$message.error({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 0,
            })
          }
        },
        'export',
        params
      )
    },
  },

}
</script>
<style lang="scss">
.bid-values-table {
  .el-table--mini th.el-table__cell.has-applied-filter {
    background: rgba(64, 158, 255, 0.5);
  }
  .el-table  .el-table-column--selection .cell {
    padding-left: 0;
    padding-right:0;
    text-align: center;
    .el-checkbox__input {

    }
  }
  .filters-clear {
    text-align: center;
  }

  .table-card {
    margin-top: 0 !important;

    .el-card__body {
      padding: 0;
    }
  }

  table .el-select .el-input__inner {
    height: 23px !important;
  }

  .el-select-dropdown__item {
    padding: 0px 10px;
  }

  .cell-content {
    .editable-icon {
      position: absolute;
      right: 5px;
      opacity: 0;
      top: 0;

      el-icon-edit {
        font-size: 12px;
      }
    }

    .el-input--small .el-input__inner {
      height: 23px;
    }
  }

  .cell-content:hover {
    .editable-icon {
      opacity: 1;
    }

    cursor: pointer;
  }
  .finalized-bids .cell-content:hover,
  .closed-bids .cell-content:hover {
    cursor: default;
  }

  .cell-input {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;

    .el-input__inner {
      height: 27px;
    }
  }

  .el-dialog {
    top: 3em;
    bottom: 3em;
    left: 3em;
    right: 3em;
    width: auto;
    overflow: auto;
    position: fixed;
    margin: 0;

    .bottom-row {
      text-align: right;
      padding-top:60px;
    }
  }

  .el-dialog__body {
    padding-bottom: 60px;
  }

  .list-group {
    width: 100%;
    min-height: calc(100%);
    border: 1px solid rgba(206, 206, 206, 0.5);
    list-style-type: none;
    counter-reset: css-counter 0;

    .list-group-item {
      padding: 0.25rem 0.5rem;
      border-bottom: 1px solid rgba(206, 206, 206, 0.5);
      cursor: pointer;
      counter-increment: css-counter 1;

      span {
        background: purple;
        border-radius: 5px;
        display: inline-block;
        float: right;
        color: white;
        font-size: 0.85em;
        padding: 0.25em 0.5em;
      }

      span.cat-bid_management {
        background: green;
      }

      span.cat-controlling {
        background: darkorange;
      }

      span.cat-bauleiters {
        background: darkslateblue;
      }
    }

    .list-group-item:before {
      content: counter(css-counter) '. ';
    }

    .list-group-item:last-child {
      border-bottom: none;
    }

    .sortable-chosen {
      padding: 0.25rem 1rem;
      background: rgba(206, 206, 206, 0.5);
    }
  }

  .add-all,
  .remove-all {
    float: right;
    cursor: pointer;
    margin-top: -2em;
  }

  .pagination-row {
    margin-top: 1em;

    .setting {
      cursor: pointer;
      height: 28px;
      line-height: 28px;
      width: 28px;
      padding: 0;
    }

    .export {
      cursor: pointer;
      height: 28px;
      line-height: 28px;
      padding: 0 15px;
    }
  }

  .el-checkbox {
    display: block;
    margin: 0;
    padding: 0;

    .el-checkbox__input {
      float: right;
    }

    .el-checkbox__label {
      padding: 0;
    }
  }

  .el-checkbox:before,
  .el-checkbox:after {
    clear: both;
    content: '';
    height: 0;
    line-height: 0;
    overflow: hidden;
    display: block;
  }

  h4 {
    padding: 0;
    font-size: 1em;
    margin: 0 0 1em 0;
  }

  .header-search {
    select {
      width: 100%;
      display: block;
      border-radius: 3px;
      border: 1px solid #dde0e5;
    }
    .el-select__input input {
      margin-left: 5px;
      cursor: pointer;
    }

    select[disabled] {
      visibility: hidden;
    }

    span.col-label {
      display: block;
      margin: 0.5em;
    }

    .el-input__suffix {
      display: block;
      margin: 0;
      padding: 0;
      position: absolute;

      .el-input__suffix-inner {
        display: block;
        margin: 0;
        padding: 0;
        position: absolute;
        right: 0;

        .el-input__icon {
          line-height: 23px;
        }
      }
    }

    .el-select + span {
      display: block;
      margin: 0;
    }

    .el-select-dropdown__item {
      padding-right: 50px;
    }

    .el-select-dropdown__item.selected::after {
      right: 6px;
      top: 10px;
    }

    .el-select__tags {
      top: auto;
      transform: initial;
      bottom: -1px;
      width: 100%;
      max-width: 100% !important;
    }

    .el-select__tags > span {
      display: none;
    }
  }

  .header-search.loading .el-select {
    visibility: hidden;
  }

  .revision-card-wrapper {
    .toggle-button {
      float: right;
    }

    margin-top: 1em;

    .toggle {
      padding: 0.5em 0.5em 0.5em 1em;
      line-height: 35px;
      font-size: 15px;
      border: 1px solid #d1dbe5;
      border-radius: 4px;
      background: #fff;
      overflow: hidden;
    }
  }

  .revision-card .el-card__header {
    display: none;
  }

  .revision-card .el-card__body .el-table__body-wrapper {
    max-height: 49vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .hide-revisions {
    .revision-card {
      display: none;
    }
  }

  .native-table-outer-row {
    position: relative;


    .native-table-outer {
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      background-color: #fff;
    }
  }
  .show-revisions .native-table-outer-row {

  }

  .el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: auto;
  }

  .text-right {
    text-align: right;
  }

  .question-dialog .el-dialog {
    width: auto;
    max-width: 640px;
    margin: 0 auto;
    height: auto;
    top: 3em;
    bottom: auto;
    .el-dialog__body {
      padding-bottom: 30px;
    }
    .el-checkbox .el-checkbox__input {
      float: none;
      margin-right: 10px;
    }
  }
  .bulk-actions {
    margin-bottom: 5px;
    float: left;
  }
  .bid-add-button {
    float: right;
  }
}
.header-search-with-groups {
    .el-select-group__wrap.other-filters {
      .el-select-dropdown__item {
        opacity:0.35;
      }
      .el-select-dropdown__item.selected,
      .el-select-dropdown__item.hover {
        opacity:0.5;
      }
  }
}
</style>
