<template>
  <el-table
    v-loading="spinner"
    :data="data"
    :class="classes"
    size="mini"
    :highlight-current-row="true"
    stripe
    @cell-click="handleRowClick"
    @sort-change="(val) => $emit('sort-change', val)"
    @filter-change="(val) => $emit('filter-change', val)"
  >
    <el-table-column
      v-for="column in headers"
      :key="column.prop"
      :prop="column.prop"
      :label="column.label"
      :sortable="column.sortable ? 'custom' : false"
      :width="column.width"
      :show-overflow-tooltip="true"
    />
    <el-table-column
      v-if="actions.length"
      :fixed="fixedActionsColumn ? 'right' : false"
      label="Actions"
      :width="actionsColumnWidth || Math.max(70, actions.length * 45)"
      :show-overflow-tooltip="true"
      align="right"
    >
      <template slot-scope="scope">
        <el-button
          v-for="action in availableActions"
          :key="action.type"
          :type="action.type"
          size="mini"
          :icon="getActionButtonIcon(action)"
          :title="action.title"
          @click.stop="
            handleClick('action', {
              event: action.event,
              data: scope.row[action.prop],
            })
          "
        />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { can } from 'common/util'

export default {
  name: 'DataTable',

  props: {
    data: {
      type: Array,
      required: true,
    },

    spinner: {
      type: Boolean,
    },

    headers: {
      type: Array,
      required: true,
    },

    actions: {
      type: Array,
      default: () => [],
    },

    fixedActionsColumn: {
      type: Boolean,
      default: false,
    },

    primaryRoute: {
      type: String,
      default: null,
    },

    onRowClick: {
      type: Function,
      default: null,
    },

    category: {
      type: String,
      required: true,
    },

    actionsColumnWidth: {
      type: Number,
      required: false,
      default: undefined,
    },
  },

  computed: {
    availableActions() {
      return this.actions.filter(
        (action) => !action.capability || can(action.capability)
      )
    },

    classes() {
      return {
        'data-table': true,
        'has-primary-route': !!this.primaryRoute,
      }
    },
  },

  methods: {
    getActionButtonIcon(action) {
      if (action.faIcon) {
        return `${action.faIcon} ${action.faIcon}`
      }
      if (action.icon) {
        return action.icon
      }
      if (action.label) {
        return action.label
      }
    },
    handleClick(event, name) {
      this.$emit(event, name)
    },

    handleRowClick(row) {
      if (this.onRowClick && typeof this.onRowClick === 'function') {
        this.onRowClick(row)
        return
      }
      if (this.primaryRoute) {
        const { id } = row
        this.$router.push(this.primaryRoute.replace(':id', id))
      }
    },

    can(capability) {
      return can(capability, this.category)
    },
  },
}
</script>
