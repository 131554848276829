<template>
  <PositionTable :project="project" />
</template>

<script>
import PositionTable from '../../../../Positions/PositionsTable'

export default {
  name: 'ProjectSinglePositions',
  components: {
    PositionTable,
  },

  props: {
    project: {
      type: Object,
      default: null,
    },
  },
}
</script>
