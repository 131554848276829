<template>
  <div class="claim-email-preview">
    <h2>Preview</h2>

    <el-card>
      <div slot="header">
        <h3>Email</h3>
      </div>
      <div ref="previewEmail" contenteditable="true" @keyup="emailChanged" />
    </el-card>

    <el-card>
      <div slot="header">
        <h3>
          Pdf
          <el-button
            size="small"
            class="pull-right"
            :loading="isDownloading"
            @click="downloadPdf"
            >Download
          </el-button>
        </h3>
      </div>
      <div class="a4paper landscape">
        <div ref="previewPdf" contenteditable="true" @keyup="pdfChanged" />
      </div>
    </el-card>
  </div>
</template>

<script>
import miscApi from 'api/v2/miscellaneous'
import {logErrorMessage} from '../../common/util';

export default {
  name: 'ClaimEmailPreview',

  props: {
    email: {
      type: Object,
      default: null,
    },
    claim: {
      type: Object,
      default: null,
    },
    images: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      previewEmail: '',
      previewPdf: '',
      isDownloading: false,
    }
  },

  watch: {
    email() {
      this.renderPreviews()
    },
  },

  mounted() {
    this.renderPreviews()
  },

  methods: {
    emailChanged(event) {
      this.previewEmail = event.target.innerHTML
      this.$emit('changedEmail', this.previewEmail)
    },

    pdfChanged(event) {
      this.previewPdf = event.target.innerHTML
      this.$emit('changedPdf', this.previewPdf)
    },

    renderPreviews() {
      this.previewEmail = this.email.mail
      this.previewPdf = this.email.pdf
      this.$refs.previewEmail.innerHTML = this.previewEmail
      this.$refs.previewPdf.innerHTML = this.previewPdf

      this.$emit('changedEmail', this.previewEmail)
      this.$emit('changedPdf', this.previewPdf)
    },
    downloadPdf() {
      this.isDownloading = true

      const filename = 'claim-' + this.claim.id + '-email-preview'
      miscApi.html2pdf(
        (data) => {
          let blob = new Blob([data], { type: 'application/pdf' })
          const objURL = window.URL.createObjectURL(blob)

          let link = document.createElement('a')
          link.href = objURL
          link.download = filename + '.pdf'
          link.click()

          window.URL.revokeObjectURL(objURL)

          // window.open(objURL)

          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(objURL)
          }, 100)

          this.isDownloading = false
        },
        (error) => {
          logErrorMessage(error)
        },
        this.previewPdf,
        this.images
      )
    },
  },
}
</script>

<style lang="scss">
.claim-email-preview {
  h3 {
    font-size: 1.4rem;
    margin: 0;
  }

  .el-card {
    margin-bottom: 0.5rem;
  }

  .a4paper {
    // a4wrapper is defined in the template. We render it differently on
    // smaller screens, but have to keep it at a certain width when rendering
    // to pdf
    &,
    .a4wrapper {
      &.landscape {
        width: auto !important;
        max-width: 29.7cm;
      }

      &.portrait {
        width: auto !important;
        max-width: 21cm;
      }
    }

    padding-left: 1.5cm;
    padding-right: 1.5cm;
    border-left: 1px dotted lighten(black, 50%);
    border-right: 1px dotted lighten(black, 50%);
  }
}
</style>
