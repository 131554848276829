<template>
  <div class="comp-project-access">
    <el-row :gutter="20">
      <el-col :span="14">
        <el-card class="users">
          <div slot="header">
            <span>
              Users with access to the tablet app
              <el-badge :value="projectContacts.length" class="item"/>
              <el-button
                class="pull-right"
                size="small"
                type="warning"
                @click="detachAllContacts"
              >REVOKE ALL ACCESS</el-button
              >
            </span>
          </div>

          <el-row :gutter="10" class="user-filters">
            <el-col :span="7">
              <el-input
                v-model="userNameInput"
                placeholder="filter"
                style="padding-left: 34px"
              />
            </el-col>
            <el-col :span="7"> &nbsp;</el-col>
            <el-col :span="5">
              <el-input v-model="userRolesInput" placeholder="filter"/>
            </el-col>
            <el-col :span="5">
              <el-input v-model="userSubcontractorInput" placeholder="filter"/>
            </el-col>
          </el-row>

          <div class="user-list header">
            <el-row :gutter="10">
              <el-col :span="7">
                <span
                  style="display: inline-block; height: 14px; width: 26px"
                />
                Name
              </el-col>
              <el-col :span="7"> Username</el-col>
              <el-col :span="5"> Role</el-col>
              <el-col :span="5"> Subcontractor</el-col>
            </el-row>
          </div>

          <div class="user-list body scrollable">
            <el-row
              v-for="contact in filteredContacts"
              :key="`user-${contact.user.id}`"
              :gutter="10"
              class="user-row"
            >
              <div @click.stop.prevent="toggleContact(contact)">
                <el-col :span="7">
                  <el-checkbox :value="contactIsAssigned(contact)"/>
                  {{ contact.user.name }}
                </el-col>
                <el-col :span="7">
                  <span>{{ contact.user.username }}</span>
                </el-col>
                <el-col :span="5">
                  <span>{{ contact.user.role }}</span>
                </el-col>
                <el-col :span="5">
                  <span>
                    {{
                      contact &&
                      contact.subcontractor &&
                      contact.subcontractor.name
                    }}
                  </span>
                </el-col>
              </div>
            </el-row>
          </div>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card class="subcontractors">
          <el-input v-model="subcontractorInput" placeholder="filter"/>
          <div slot="header">
            <span>
              Subcontractors
              <el-badge :value="projectSubcontractors.length" class="item"/>
            </span>
          </div>

          <div class="subcontractor-list header">
            <el-row :gutter="10">
              <el-col :span="12"> Name</el-col>
            </el-row>
          </div>
          <div class="subcontractor-list body scrollable">
            <el-row
              v-for="subcontractor in filteredSubcontractors"
              :key="subcontractor.id"
              class="subcontractor-row"
              nowrap
            >
              <div @click.stop.prevent="toggleSubcontractor(subcontractor)">
                <el-col :span="24">
                  <el-switch
                    :value="subContractorIsAssigned(subcontractor)"
                    active-text=""
                    inactive-text=""
                  />
                  <span>{{ subcontractor.name }}</span>
                </el-col>
              </div>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import projectsApi from 'api/v2/projects'
import projectContactsApi from 'api/v2/projectContacts'
import projectSubcontractorsApi from 'api/v2/projectSubcontractors'
import subcontractorsApi from 'api/v2/subcontractors'
import {logErrorMessage} from '../../../../common/util';

export default {
  name: 'ProjectAccess',

  props: {
    project: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      projectContacts: [],
      projectAvailableContacts: [],
      projectSubcontractors: [],
      availableSubcontractors: [],
      userNameInput: '',
      userRolesInput: '',
      userSubcontractorInput: '',
      subcontractorInput: '',
    }
  },

  computed: {
    projectId() {
      if (this.$route.params.projectId) {
        return parseInt(this.$route.params.projectId, 10)
      } else {
        return this.project.id
      }
    },
    filteredContacts() {
      let contacts = this.projectAvailableContacts
      if (this.userNameInput) {
        contacts = contacts.filter(
          (contact) =>
            contact.user &&
            contact.user.name
              .toLowerCase()
              .indexOf(this.userNameInput.toLowerCase()) !== -1
        )
      }
      if (this.userRolesInput) {
        contacts = contacts.filter(
          (contact) =>
            contact.user &&
            contact.user.role
              .toLowerCase()
              .indexOf(this.userRolesInput.toLowerCase()) !== -1
        )
      }
      if (this.userSubcontractorInput) {
        contacts = contacts.filter((contact) => {
          if (!contact.user || !contact.user.subcontractor) return false
          return (
            contact.user &&
            contact.user.subcontractor &&
            contact.user.subcontractor.name
              .toLowerCase()
              .indexOf(this.userSubcontractorInput.toLowerCase()) !== -1
          )
        })
      }
      return contacts.filter((contact) => !!contact.user)
    },
    filteredSubcontractors() {
      if (!this.subcontractorInput) return this.availableSubcontractors
      return this.availableSubcontractors.filter(
        (subcontractor) =>
          subcontractor.name
            .toLowerCase()
            .indexOf(this.subcontractorInput.toLowerCase()) !== -1
      )
    },
  },

  mounted() {
    this.fetchProjectContacts()
    this.fetchProjectAvailableContacts()
    this.fetchAvailableSubcontractors()
    this.fetchProjectSubcontractors()
  },

  methods: {
    contactIsAssigned(contact) {
      return (
        this.projectContacts.find(
          (projectContact) => projectContact.id === contact.id
        ) !== undefined
      )
    },
    subContractorIsAssigned(subcontractor) {
      if (!this.projectSubcontractors) return []
      return (
        this.projectSubcontractors.find(
          (projectSubcontractor) => projectSubcontractor.id === subcontractor.id
        ) !== undefined
      )
    },
    toggleSubcontractor(subcontractor) {
      if (this.subContractorIsAssigned(subcontractor)) {
        this.unlinkSubcontractor(subcontractor)
      } else {
        this.linkSubcontractor(subcontractor)
      }
    },
    linkSubcontractor(subcontractor) {
      projectSubcontractorsApi.linkProject(
        () => {
          this.projectSubcontractors.push(subcontractor)
          this.fetchProjectAvailableContacts()
        },
        () => {
          // noop
        },
        this.projectId,
        subcontractor.id
      )
    },
    unlinkSubcontractor(subcontractor) {
      projectSubcontractorsApi.unlinkProject(
        () => {
          this.projectSubcontractors = this.projectSubcontractors.filter(
            (projectSubcontractor) =>
              projectSubcontractor.id !== subcontractor.id
          )
          this.fetchProjectAvailableContacts()
          this.fetchProjectContacts()
        },
        () => {
          // noop
        },
        this.projectId,
        subcontractor.id
      )
    },
    toggleContact(contact) {
      if (this.contactIsAssigned(contact)) {
        this.unlinkContact(contact)
      } else {
        this.linkContact(contact)
      }
    },
    linkContact(contact) {
      projectContactsApi.linkProjectContact(
        () => {
          this.projectContacts.push(contact)
        },
        () => {
          // noop
        },
        this.projectId,
        contact.id
      )
    },
    unlinkContact(contact) {
      projectContactsApi.unlinkProjectContact(
        () => {
          this.projectContacts = this.projectContacts.filter(
            (projectContact) => projectContact.id !== contact.id
          )
        },
        () => {
          // noop
        },
        this.projectId,
        contact.id
      )
    },
    fetchProjectContacts() {
      projectContactsApi.index(
        (response) => {
          this.projectContacts = response
        },
        (error) => {
          logErrorMessage(error)
        },
        this.projectId,
        {include: ['user']}
      )
    },
    fetchProjectAvailableContacts() {
      projectsApi.getAvailableContacts(
        (response) => {
          this.projectAvailableContacts = response
        },
        (error) => {
          logErrorMessage(error)
        },
        this.projectId,
        {include: ['user']}
      )
    },
    fetchProjectSubcontractors() {
      projectSubcontractorsApi.index(
        (response) => {
          this.projectSubcontractors = response
        },
        (error) => {
          logErrorMessage(error)
        },
        this.projectId
      )
    },
    fetchAvailableSubcontractors() {
      subcontractorsApi.index(
        (response) => {
          this.availableSubcontractors = response.sort((a, b) => {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0
          })
        },
        (error) => {
          logErrorMessage(error)
        }
      )
    },
    detachAllContacts() {
      this.$confirm(
        'Are you sure you want to revoke access for all users?',
        'Revoke all access?',
        {
          cancelButtonText: 'No, I am confused',
          confirmButtonText: 'Yes, bring it on!',
        }
      ).then(() => {
        projectsApi.detachAllContacts(
          (response) => {
            this.fetchProjectContacts()
            this.$message.success({
              message: `${response.removeCount} contacts removed`,
            })
          },
          (error) => {
            logErrorMessage(error)
          },
          this.projectId
        )
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~scss/mixins';

@include tiny-vertical-scroll;

.scrollable {
  overflow-y: auto;
  height: calc(100vh - 320px);
  padding-right: 10px;
  margin-right: -1px;
}

::v-deep .el-card__header {
  font-size: 15px;
}

.users,
.subcontractors {
  ::v-deep .el-input input {
    height: auto;
    line-height: 1;
    padding: 3px 10px;
  }

  .el-switch {
    margin-right: 4px;
  }
}

.users .el-input {
  margin-left: -5px;
}

.user-list,
.subcontractor-list {
  &.header {
    padding: 5px;
    font-size: 13px;
    border-bottom: 1px solid black;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .user-row,
  .subcontractor-row {
    padding: 3px 5px;
    font-size: 13px;
    line-height: 24px;
    height: 30px;
    white-space: nowrap;

    div.el-col {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }

    &:nth-child(even) {
      background: rgb(239, 242, 247);
    }

    &:hover {
      background: rgba(32, 160, 255, 0.5);
      cursor: pointer;
    }
  }

  .el-checkbox {
    margin-right: 6px;
  }
}

.user-filters {
}
</style>
