<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="visible"
    title="Edit dropdown options"
    :before-close="cancel"
  >
    <slot name="top" />

    <h6 style="margin: 0">Options</h6>
    <p>
      Provide one option per row, in the following format [value]: [label]. The
      option label is shown in the dropdown, the value is stored in the
      database. Example:
    </p>
    <code>
      yes: Yes, drilling phase completed<br />
      no: No, drilling phase NOT completed<br />
      unknown: Not known<br /><br />
    </code>
    <el-form label-width="120px" label-position="top">
      <el-form-item label="">
        <el-input
          v-model="optionsString"
          type="textarea"
          :autosize="{ minRows: 6 }"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="warning" @click="cancel">cancel</el-button>
        <el-button type="primary" @click="save"> save </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'EditProjectFieldOptionsDialog',
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      visible: true,
      optionsString: this.options
        .map((o) => o.value + ': ' + o.label)
        .join('\n'),
    }
  },

  methods: {
    save() {
      const options = this.optionsString
        .split('\n')
        .map((o) => {
          // red: red
          const valueLabel = o.split(': ')

          return {
            value: valueLabel[0].trim(),
            label: valueLabel[1] ? valueLabel[1].trim() : valueLabel[0].trim(),
          }
        })
        .filter((o) => o.value)

      this.$emit('save', options)
    },
    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>
