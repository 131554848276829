<template>
  <el-dialog
    v-if="visible"
    :before-close="notifyParent"
    :title="title"
    :visible.sync="visible"
    size="tiny"
  >
    <default-form
      :errors="formErrors"
      :form="form"
      :loading="loading"
      @submit="onSubmit"
    />
  </el-dialog>
</template>

<script>
import Vue from 'vue'
import pricingMappingApi from 'api/v2/pricingMapping'
import DefaultForm from 'components/Common/Form/DefaultForm'

export default {
  name: 'MappingDialogTemplate',

  components: {
    DefaultForm,
  },

  props: {
    type: {
      type: String,
      default: 'add',
      validator: function (value) {
        return ['add', 'edit'].indexOf(value) !== -1
      },
    },
    translation: {
      type: Object,
      default: () => ({
        contractor_pricing_reference: '',
        internal_pricing_reference: '',
      }),
    },
    onclose: {
      type: Function,
      default: () => {
      },
    },
    onSuccess: {
      type: Function,
      default: () => {
      },
    },
    params: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      visible: true,
      loading: false,
      formErrors: null,
    }
  },

  computed: {
    title() {
      switch (this.type) {
      case 'edit':
        return 'Edit mapping'
      default:
        return 'Add mapping'
      }
    },

    buttonTitle() {
      switch (this.type) {
      case 'edit':
        return 'Save mapping'
      default:
        return 'Add mapping'
      }
    },

    formItems() {
      return [
        {
          label: 'Contractor identifier',
          prop: 'contractor_pricing_reference',
          required: true,
          autofocus: true,
          rules: [],
        },
        {
          label: 'Internal identifier',
          prop: 'internal_pricing_reference',
          required: true,
          rules: [],
        },
      ]
    },

    form() {
      return {
        items: this.formItems,
        buttons: [
          {
            event: 'submit',
            label: this.buttonTitle,
            type: 'primary',
          },
        ],
        ref: 'pricingItemMappingForm',
        initialModel: {
          contractor_pricing_reference: this.translation
            ? this.translation.contractor_pricing_reference
            : '',
          internal_pricing_reference: this.translation
            ? this.translation.internal_pricing_reference
            : '',
        },
      }
    },
  },

  methods: {
    onSubmit(formData) {
      switch (this.type) {
      case 'edit':
        return this.editMapping(formData)
      default:
        return this.addMapping(formData)
      }
    },

    notifyParent(done) {
      done()
      this.onclose()
      this.$parent.$emit('cancelTemplateDialog')
    },

    addMapping(formData) {
      this.loading = true

      pricingMappingApi.store(
        () => {
          this.$message.success({
            showClose: true,
            message: 'Mapping has been created.',
            duration: 5000,
          })
          this.loading = false
          this.visible = false
          this.$parent.$emit('changedAddItem')
          this.onSuccess()
        },
        (error) => {
          this.loading = false
          this.formErrors = error
          Vue.nextTick(() => {
            this.formErrors = null
          })
        },
        Object.assign(formData, this.params)
      )
    },

    editMapping(formData) {
      //todo:
      this.loading = true
      pricingMappingApi.update(
        (response) => {
          this.$message.success({
            showClose: true,
            message: 'Mapping has been updated.',
            duration: 5000,
          })
          this.loading = false
          this.visible = false
          this.$parent.$emit('changedEditItem', response)
          this.onSuccess()
        },
        (error) => {
          this.loading = false
          this.formErrors = error
          Vue.nextTick(() => {
            this.formErrors = null
          })
        },
        this.translation.id,
        formData
      )
    },
  },
}
</script>

<style lang="scss"></style>
