<template>
  <el-dialog
    class="position-edit-item-dialog"
    :close-on-click-modal="false"
    :visible.sync="visible"
    title="Add Item"
    :before-close="close"
  >
    <el-form label-width="120px" label-position="top">
      <el-form-item ref="comments" label="Comments">
        <el-input v-model="newItem.comments" type="textarea" :required="true" />
      </el-form-item>
      <el-form-item ref="position" label="Pricing item">
        <el-select
          v-model="newItem.pricingItem"
          placeholder="Please select..."
          filterable
          return-object
          class="position-select"
          @change="itemChanged"
        >
          <el-option
            v-for="option in pricingItemOptions"
            :key="option.id"
            :label="option.description"
            :value="option.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="Measurements" class="measurements">
        <el-row :gutter="20">
          <el-col :span="5">
            <el-input
              v-if="
                !newItem.pricingItem ||
                ['M', 'M2', 'M3'].indexOf(newItem.unit) !== -1
              "
              v-model="newItem.length"
              :required="true"
            >
              <template slot="prepend">Length</template>
              <template slot="append">M</template>
            </el-input>
          </el-col>
          <el-col :span="5">
            <el-input
              v-if="
                !newItem.pricingItem ||
                ['M2', 'M3'].indexOf(newItem.unit) !== -1
              "
              v-model="newItem.width"
              :required="true"
              prop="newItem.width"
            >
              <template slot="prepend">Width</template>
              <template slot="append">M</template>
            </el-input>
          </el-col>
          <el-col :span="5">
            <el-input
              v-if="!newItem.pricingItem || newItem.unit === 'M3'"
              v-model="newItem.height"
              :required="true"
            >
              <template slot="prepend">Height</template>
              <template slot="append">M</template>
            </el-input>
          </el-col>
          <el-col :span="5">
            <el-input
              v-if="!newItem || newItem.unit === 'ST' || newItem.unit === 'STD'"
              v-model="newItem.amount"
              :required="true"
            >
              <template slot="prepend">{{
                newItem && newItem.unit === 'STD' ? 'Hours' : 'Amount'
              }}</template>
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item>
        <el-button :loading="loading" type="primary" @click="onSubmit">
          Create
        </el-button>
        <el-button type="warning" @click="close"> Cancel </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import positionItemApi from 'api/v2/positionItems'
import pricingTemplateApi from '../../api/v2/pricingTemplates'
import * as positionUtil from '../../common/positionUtil'

import {formatErrorMessage, logErrorMessage} from '../../common/util'
import { cloneDeep } from 'lodash-es'

export default {
  name: 'PositionItemAddDialog',
  props: {
    position: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      visible: true,
      pricingCategories: [],
      pricingItems: [],
      pricingTemplate: {},
      selectedPricingItem: null,

      unit: null,
      newItem: {
        description: null,
        comments: null,
        width: 0,
        height: 0,
        length: 0,
        amount: 0,
        unit: 'M',
      },
    }
  },
  computed: {
    getMass() {
      return positionUtil.getMass(this.newItem)
    },
    getGross() {
      return positionUtil.getGross(this.newItem, true)
    },
    getNet() {
      return positionUtil.getNet(this.newItem, true)
    },
    pricingItemOptions() {
      if (!this.pricingCategories || !this.pricingItems) {
        return []
      }
      let options = []
      this.pricingCategories.forEach((pricingCategory) => {
        if (pricingCategory.id !== this.position.pricingCategoryId) {
          return
        }
        options = this.pricingItems.filter(
          (pricingItem) => {
            return pricingItem.pricing_category_id === pricingCategory.id && (!pricingItem.hidden || !this.position.subcontractorId)
          }
        )
      })
      return options
    },
  },
  watch: {
    unit: function () {
      switch (this.newItem.unit) {
      case 'M':
      case 'LE':
        this.newItem.width = null
        this.newItem.height = null
        this.newItem.amount = null
        break
      case 'M2':
        this.newItem.height = null
        this.newItem.amount = null
        break
      case 'M3':
        this.newItem.amount = null
        break
      case 'ST':
      case 'STD':
        this.newItem.width = null
        this.newItem.length = null
        this.newItem.height = null
        break
      default:
      }
    },
  },
  mounted() {
    this.getCategories()
  },
  methods: {
    close(done) {
      this.$emit('cancel')
      if (done && typeof done === 'function') {
        this.$nextTick(() => {
          done()
        })
      }
    },
    onSubmit() {
      const item = cloneDeep(this.newItem)
      delete item.pricingItem
      this.loading = true
      positionItemApi.store(
        (response) => {
          this.loading = false
          this.$emit('addItem', response)
          this.close()
        },
        (error) => {
          this.loading = false
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.position.id,
        item
      )
    },
    getCategories() {
      pricingTemplateApi.index(
        (response) => {
          const pricingTemplate = response[0]
          this.$set(this, 'pricingTemplate', pricingTemplate)
          this.$set(this, 'pricingCategories', pricingTemplate.categories)
          this.$set(this, 'pricingItems', pricingTemplate.items)
        },
        (error) => {
          logErrorMessage(error)
        },
        {
          project_id: this.position.projectId,
          // subcontractor_id: this.position.subcontractor
          //   ? this.position.subcontractor.id
          //   : null,
          subcontractor_id: null,
          ofNoSubcontractor: true,
          include: ['categories', 'items'],
        }
      )
    },
    itemChanged(selectedItem) {
      this.updateItemAmounts(selectedItem)
    },
    updateItemAmounts(selectedPricingItem) {
      if (!selectedPricingItem) return
      if (!this.newItem) return
      let pricingItem = this.pricingItems.find((item) => {
        return item.id === selectedPricingItem
      })

      this.newItem.pricing_item_id = pricingItem.id
      this.newItem.unit = pricingItem.unit
      this.unit = pricingItem.unit
    },
  },
}
</script>

<style lang="scss">
.position-edit-item-dialog {
  .position-select {
    display: block;
  }
}
</style>
