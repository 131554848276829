<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>Schedule</span>
    </div>

    <div class="text project-details">
      <el-row
        v-for="details of projectDetails"
        :key="details.key"
        :gutter="20"
      >
        <el-col :span="12" class="key">
          {{ details.label }}
        </el-col>
        <el-col :span="12" class="value">
          <a href="#" @click.prevent="openEditModal(details)">
            <template v-if="details.readableValue">
              {{ details.readableValue }}
            </template>
            <template v-else> set... </template>
          </a>
        </el-col>
      </el-row>
    </div>

    <el-dialog
      :visible="!!editModalDetails"
      :title="
        editModalDetails ? `Set ${editModalDetails.label.toLowerCase()}` : ''
      "
      :show-close="false"
      @close="cancel"
    >
      <template v-if="editModalDetails">
        <el-row>
          <el-col :span="24">
            <el-date-picker
              v-model="formData[editModalDetails.key]"
              type="date"
              placeholder="Pick a day"
              :format="configurationData.date_format_element"
              :clearable="editModalDetails.key !== 'start_at'"
            >
            </el-date-picker>
          </el-col>
        </el-row>
      </template>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">Cancel</el-button>
        <el-button type="primary" @click="submit">Save</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { can } from 'common/util'
import { formatDate, formatDateForRequest } from '../../../../../common/util.js'

export default {
  name: 'ProjectSingleOverviewGeneral',

  props: {
    project: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      formData: {},

      editModalDetails: null,
    }
  },

  computed: {
    ...mapGetters('projects', ['users', 'usersLoading', 'usersErrored']),
    ...mapGetters('configuration', {
      configurationData: 'data',
    }),

    projectDetails() {
      if (!this.project || Object.keys(this.project).length === 0) {
        return []
      }

      return [
        {
          key: 'start_at',
          label: 'Planned start',
          readableValue: formatDate(this.project.startAt),
          value: this.project.startAt,
        },
        {
          key: 'end_at',
          label: 'Planned end',
          readableValue: formatDate(this.project.endAt),
          value: this.project.endAt,
        },
        {
          key: 'realized_start_at',
          label: 'Realized start',
          readableValue: formatDate(this.project.realizedStartAt),
          value: this.project.realizedStartAt,
        },
        {
          key: 'realized_end_at',
          label: 'Realized end',
          readableValue: formatDate(this.project.realizedEndAt),
          value: this.project.realizedEndAt,
        },
      ]
    },
  },

  watch: {
    project: {
      deep: true,
      handler() {
        this.editModalDetails = null
      },
    },
  },

  created() {
    this.fillFormData()
  },

  methods: {
    fillFormData() {
      const currentData = this.projectDetails.reduce((acc, { key, value }) => {
        acc[key] = value

        return acc
      }, {})

      if (this.project && this.project.id) {
        currentData.id = this.project.id
      }

      this.$set(this, 'formData', currentData)
    },

    cancel() {
      this.fillFormData()
      this.editModalDetails = null
    },

    submit() {
      // Clearing a date sets it to undefined, but to send it it needs to be null
      for (const { key } of this.projectDetails) {
        if (this.formData[key] === undefined) {
          this.formData[key] = null
        }
        if (
          [
            'start_at',
            'end-date',
            'realized_start_at',
            'realized_end_at',
          ].includes(key)
        ) {
          this.formData[key] = formatDateForRequest(this.formData[key])
        }
      }
      this.$store.dispatch('projects/saveProject', this.formData)
    },

    canUpdate() {
      return can('update', 'project')
    },

    openEditModal(details) {
      this.editModalDetails = details
    },
  },
}
</script>

<style lang="scss" scoped>
.key,
.value {
  height: 36px;
  line-height: 36px;
}
</style>
