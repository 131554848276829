<template>
  <div
    v-loading="loading"
    class="media-item"
    :class="`${mediaType} ${loading ? 'loading' : ''}`"
  >
    <template v-if="thumbnailUrl">
      <img ref="thumbnail" height="100" @click="showItem" />
    </template>
    <template v-else-if="mediaType === 'png'">
      <img ref="png" height="100" @click="showItem" />
    </template>
    <template v-else-if="mediaType === 'jpg'">
      <img ref="jpg" height="100" @click="showItem" />
    </template>
    <template v-else-if="mediaType === 'pdf'">
      <i class="fa fa-file-text-o" @click="showItem" />
      <span class="filename">
        {{ name }}
      </span>
    </template>
    <template v-else> NOT SUPPORTED {{ mimeType }}</template>
  </div>
</template>

<script>
import { logErrorMessage } from '../../common/util'

export default {
  name: 'MediaItem',

  props: {
    id: {
      type: Number,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    thumbnailUrl: {
      type: String,
      default: null,
    },
    filename: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    mimeType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      mediaType: null,
    }
  },

  mounted() {
    if (this.thumbnailUrl) {
      this.renderThumbnail()
      return
    }
    if (this.mimeType === 'image/png') {
      this.mediaType = 'png'
      this.renderPng()
      return
    }
    if (this.mimeType === 'image/jpeg') {
      this.mediaType = 'jpg'
      this.renderJpg()
      return
    }
    if (this.mimeType === 'application/pdf') {
      this.mediaType = 'pdf'
      this.$nextTick(() => {
        this.loading = false
      })
    }
  },

  methods: {
    showItem() {
      this.$emit('show-media-dialog')
    },
    renderThumbnail() {
      fetch(`${this.thumbnailUrl}`)
        .catch((error) => {
          logErrorMessage(error)
        })
        .then((response) => {
          return response.blob()
        })
        .then((myBlob) => {
          const objectURL = URL.createObjectURL(myBlob)
          this.$refs.thumbnail.src = objectURL
          this.$nextTick(() => {
            this.loading = false
          })
        })
    },
    renderPng() {
      fetch(`${this.url}`)
        .catch((error) => {
          logErrorMessage(error)
        })
        .then((response) => {
          return response.blob()
        })
        .then((myBlob) => {
          const objectURL = URL.createObjectURL(myBlob)
          this.$refs.png.src = objectURL
          this.$nextTick(() => {
            this.loading = false
          })
        })
    },
    renderJpg() {
      fetch(`${this.url}`)
        .catch((error) => {
          logErrorMessage(error)
        })
        .then((response) => {
          return response.blob()
        })
        .then((myBlob) => {
          const objectURL = URL.createObjectURL(myBlob)
          this.$refs.jpg.src = objectURL
          this.$nextTick(() => {
            this.loading = false
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  z-index: 1;
}
.media-item {
  display: inline-block;
  margin: 5px 10px 5px 0px;
  padding: 2px;
  border: 1px solid #ddd;
  cursor: pointer;

  img {
    display: block;
  }

  &.loading {
    min-height: 100px;
    min-width: 100px;
  }

  &.pdf {
    // color: #333;
    font-size: 11px;
    width: 150px;
    height: 100px;
    overflow: hidden;
    position: relative;
    text-align: center;

    .filename {
      display: inline-block;
      margin-top: 5px;
    }

    i {
      position: absolute;
      left: 60px;
      margin-top: 40px;
      font-size: 24px;
    }
  }
}
</style>
