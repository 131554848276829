<template>
  <view-add-edit-delete-view
    :api="usersApi"
    :buttons="buttons"
    :data="users"
    :headers="headers"
    :include="['contact', 'contact.subcontractor']"
    :max-table-width="960"
    :vocabulary="{ single: 'user', plural: 'users' }"
    category="user"
    identifying-prop="name"
    show-table-search
    @data="processData"
  >
    <template slot="addItemDialog">
      <dialog-add-edit-user-and-contact/>
    </template>

    <template slot="editItemDialog" slot-scope="{ item }">
      <dialog-add-edit-user-and-contact :user-item="item"/>
    </template>

    <template slot="extraButtons">
      <el-button :loading="exporting" size="medium" @click="exportUsersAsCSV"
      >Export (csv)
      </el-button
      >
    </template>
  </view-add-edit-delete-view>
</template>

<script>
import {mapGetters} from 'vuex'

import ViewAddEditDeleteView from 'components/Views/Common/ViewAddEditDeleteView'
import DialogAddEditUserAndContact from 'components/Users/DialogAddEditUserAndContact'

import usersApi from 'api/v2/users'

export default {
  name: 'UsersView',

  components: {
    ViewAddEditDeleteView,
    DialogAddEditUserAndContact,
  },

  data() {
    return {
      users: [],

      headers: [
        {
          prop: 'contact.fullname',
          label: 'Name',
        },
        {
          prop: 'username',
          label: 'Username',
        },
        {
          prop: 'email',
          label: 'Email',
        },
        {
          prop: 'role',
          label: 'User role',
          width: 150,
        },
      ],

      buttons: [
        {
          label: 'edit',
          event: 'change',
          faIcon: 'el-icon el-icon-edit',
          title: 'Edit user',
          prop: 'id',
          capability: 'update',
        },
        {
          label: 'delete',
          event: 'delete',
          faIcon: 'el-icon el-icon-delete',
          title: 'Delete user',
          type: 'danger',
          prop: 'id',
          capability: 'delete',
        },
      ],

      exporting: false,
    }
  },

  computed: {
    ...mapGetters('global', ['roles']),

    usersApi() {
      return usersApi
    },
  },

  methods: {
    processData(data) {
      const lookupTable = this.lookupRoles()

      this.users = data.map((row) => {
        row.roleLabel = lookupTable[row.role]
        return row
      })
    },

    lookupRoles() {
      if (!this.roles) {
        return {}
      }

      return this.roles.reduce((acc, role) => {
        acc[role.value] = role.label
        return acc
      }, {})
    },

    exportUsersAsCSV() {
      this.exporting = true
      usersApi.downloadCSV(
        (response) => {
          if (response.data.url) {
            const aElement = document.createElement('a')
            aElement.href = response.data.url
            aElement.download = response.data.filename
            aElement.click()
          }

          this.exporting = false
        },
        () => {
          this.exporting = false
        }
      )
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-table td {
  height: auto;
  padding-top: 2px;
  padding-bottom: 2px;
}

// .pagination {
//   float: right;
//   margin-top: 1rem;

//   &:after {
//     content: '';
//     clear: both;
//     display: block;
//   }
// }

// .header-action-row {
//   margin: 0.5rem 0 1rem;
// }
</style>
