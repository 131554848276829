import ApiResource from '../ApiResource'

class ClaimItemResource extends ApiResource {
  constructor() {
    super({
      name: 'claims.items',
    })
  }

  import(resolve, reject, claimId, positionItems) {
    this.ids = [claimId]

    this.post(`${this.getUrl()}/import`, positionItems)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const claimItemsApi = new ClaimItemResource()

export default claimItemsApi
