<template>
  <div v-loading="loading" class="position-revisions-view">
    <template v-if="data">
      <el-card header="Revisions">
        <el-table
          v-loading="spinner"
          :data="data"
          style="width: 100%"
          size="mini"
          :highlight-current-row="true"
          stripe
        >
          <el-table-column
            prop="updatedAt"
            label="Date"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ formatUpdatedDate(scope.row.updatedAt) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="user"
            label="User"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ scope.row.user.firstname }} {{ scope.row.user.lastname }}
            </template>
          </el-table-column>
          <el-table-column
            prop="itemDescription"
            label="Item"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            prop="fieldName"
            label="Field"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            prop="oldValue"
            label="Old "
            :show-overflow-tooltip="true"
          />
          <el-table-column
            prop="newValue"
            label="New"
            :show-overflow-tooltip="true"
          />
        </el-table>
      </el-card>
    </template>
  </div>
</template>
<script>
import positionsApi from 'api/v2/positions'
import { formatDateTime, formatErrorMessage } from '../../common/util'

export default {
  name: 'ItemsRevisionsTable',
  components: {},

  props: {
    position: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      data: [],
      loading: false,
      spinner: true,
    }
  },
  mounted() {
    this.loadPage()
  },
  methods: {
    formatUpdatedDate(date) {
      return formatDateTime(date)
    },
    loadPage() {
      positionsApi.itemRevisions(
        (response) => {
          this.loading = false
          this.spinner = false
          this.data = response.data
        },
        (error) => {
          this.loading = false
          this.spinner = false
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        }
      )
    },
  },
}
</script>
<style lang="scss">
.position-revisions-view {
  .el-card__body {
    padding: 0;
  }
}
</style>
