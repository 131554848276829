<template>
  <div class="sidebar">
    <router-link to="/">
      <img src="~assets/soli-logo.png" class="logo" />
    </router-link>
    <project-selector />

    <el-menu
      v-if="user"
      class="main-menu el-menu--dark"
      mode="vertical"
      :default-openeds="['/projects', '/reports']"
      :router="true"
    >
      <template v-for="item in items">
        <el-menu-item
          v-if="!item.children"
          :key="item.route"
          :index="item.route"
        >
          <el-badge
            v-if="item.title === 'Notifications' && unresolvedNotificationsValue"
            :value="unresolvedNotificationsValue"
            class="item"
            type="danger"
          >
            {{ item.title }}
          </el-badge>
          <template v-else>{{ item.title }}</template>

        </el-menu-item>
        <el-submenu
          v-else
          :key="item.route"
          :index="item.route.split('/').slice(0, 2).join('/')"
        >
          <template slot="title">
            {{ item.title }}
          </template>
          <el-menu-item
            v-for="subitem in item.children"
            :key="subitem.route"
            :index="`${item.route}/${subitem.route}`"
          >
            {{ subitem.title }}
          </el-menu-item>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
import ProjectSelector from 'components/Core/ProjectSelector'

import { mapGetters } from 'vuex'

import { can } from 'common/util'

export default {
  name: 'CoreMenu',

  components: {
    ProjectSelector,
  },

  data() {
    return {
      showProjectDropdown: true,
      unresolvedNotifications: 0,
      projects: [],
    }
  },

  computed: {
    ...mapGetters('global', ['user', 'notifications']),
    ...mapGetters('projects', ['currentProject']),

    unresolvedNotificationsValue() {
      return this.unresolvedNotifications
    },

    activeProjectId() {
      if (this.activeProject === undefined || !this.activeProject.id) {
        return 1
      }
      return this.activeProject.id
    },

    items() {
      let menuItems = [
        // {
        //   route: "/dashboard",
        //   title: "Dashboard"
        // },
        {
          route: '/notifications',
          title: 'Notifications',
          category: 'notification',
          capability: 'update',
        },
        {
          route: '/projects',
          title: 'Projects',
          category: 'project',
          children: [
            {
              route: 'list',
              title: 'Current projects',
              category: 'project',
            },
            {
              route: 'archived',
              title: 'Archived projects',
              category: 'project',
              capability: 'archive',
            },
            {
              route: 'map',
              title: 'Projects map',
              category: 'project',
            },
            {
              route: 'fields',
              title: 'Project fields',
              category: 'configuration',
              capability: 'update',
            },
            {
              route: 'invoice-fields',
              title: 'Invoice fields',
              category: 'configuration',
              capability: 'update',
            },
            {
              route: 'meta',
              title: 'Area meta',
              category: 'area_meta',
              capability: 'general.view',
            },
          ],
        },
        {
          route: '/bids/open-bids',
          title: 'Bids',
          category: 'bid',
          capability: 'create',
        },
        {
          route: '/positions',
          title: 'Positions',
          category: 'position',
          capability: 'create',
        },
        {
          route: '/position-validation/subcontractor',
          title: 'Approvals',
          category: 'position_validate',
          capability: 'view',
        },
        {
          route: '/purchase-invoices',
          title: 'Purchase Invoices',
          category: 'lerf',
          capability: 'create',
        },
        {
          route: '/lerfs',
          title: 'Sales Invoices',
          category: 'lerf',
          capability: 'create',
        },
        {
          route: '/claims',
          title: 'Claims',
          category: 'claim',
          capability: 'create',
        },
        {
          route: '/reports',
          title: 'Reports',
          category: 'project',
          children: [
            {
              route: 'weekly',
              title: 'Weekly',
              category: 'report',
              capability: 'weekly',
            },
            {
              route: 'lerf',
              title: 'Invoices',
              category: 'report',
              capability: 'lerf',
            },
            {
              route: 'claim',
              title: 'Claim',
              category: 'report',
              capability: 'claim',
            },
            {
              route: 'subcontractors',
              title: 'Subcontractors',
              category: 'report',
              capability: 'subcontractor',
            },
            {
              route: 'project',
              title: 'Project',
              category: 'report',
              capability: 'project',
            },
            {
              route: 'project-meta',
              title: 'Project meta',
              category: 'report',
              capability: 'projectMeta',
            },
            {
              route: 'area-meta',
              title: 'Area meta',
              category: 'report',
              capability: 'areaMeta',
            },
          ],
        },
        {
          route: '/pricing',
          title: 'Pricing',
          category: 'pricing_template',
          capability: 'update',
          children: [
            {
              route: 'templates',
              title: 'Templates',
              category: 'pricing_template',
            },
            {
              route: 'subcategories',
              title: 'Subcategories',
              category: 'pricing_template',
              capability: 'config',
            },
            {
              route: 'mapping',
              title: 'Mapping',
              category: 'pricing_template',
              capability: 'config',
            },
            {
              route: 'sync',
              title: 'Sync',
              category: 'pricing_template',
              capability: 'config',
            },
            {
              route: 'advanced-sync',
              title: 'Advanced Sync',
              category: 'pricing_template',
              capability: 'advanced_sync',
            },
          ],
        },
        {
          route: '/subcontractors',
          title: 'Subcontractors',
          category: 'subcontractor',
          capability: 'update',
          children: [
            {
              route: 'page/1',
              title: 'Subcontractors',
              category: 'subcontractor',
              capability: 'update',
            },
            {
              route: 'archived',
              title: 'Archived',
              category: 'subcontractor',
              capability: 'update',
            },
            {
              route: 'fix-pricing',
              title: 'Fix pricing',
              category: 'pricing_template',
              capability: 'config',
            },

          ],
        },
        {
          route: '/teams',
          title: 'Teams',
          category: 'team',
          capability: 'update',
        },
        {
          route: '/customers',
          title: 'Customers',
          category: 'customer',
          capability: 'update',
        },
        {
          route: '/users',
          title: 'Users',
          category: 'user',
          capability: 'update',
        },
        {
          route: '/cache-control',
          title: 'Cache Control',
          category: 'configuration',
          capability: 'update',
        },
      ]
      menuItems = this.filterOnCapabilities(menuItems)

      return menuItems
    },

    eventStreamForNotificationsCount() {
      if(!this.$store.getters['global/eventStream']) {
        return null
      }
      return this.$store.getters['global/eventStream']['notificationsCount'] ?? null
    },
    currentUser() {
      return this.$store.getters['global/user'];
    },
    canViewProjects() {
      return can('view', 'project')
    },
  },
  watch: {
    eventStreamForNotificationsCount(newCount) {
      newCount = parseInt(newCount)
      this.unresolvedNotifications = newCount
    },
    currentUser(newValue, oldValue) {
      if(!oldValue && newValue) {
        if(this.canViewProjects) {
          this.$store.dispatch('projects/fetchItems');
        }
      }
    },
  },
  created() {
    const self = this
    this.$store.subscribe((mutation) => {
      if(mutation.type === 'global/notifications') {
        self.unresolvedNotifications = mutation.payload?.notResolvedCount
      }
    })
  },
  mounted() {},
  methods: {
    filterOnCapabilities(menuItems) {
      const filteredItems = menuItems.reduce((acc, item) => {
        if (item.children) {
          item.children = this.filterOnCapabilities(item.children)
        }

        // handle subcategories for area meta
        if (
          item.category &&
          item.capability &&
          item.capability.indexOf('.') !== -1 &&
          can(item.capability.split('.')[1], item.category, item.capability.split('.')[0])
        ) {
          acc.push(item)
        }

        // check both on category and capability
        else if (
          item.category &&
          item.capability &&
          can(item.capability, item.category)
        ) {
          acc.push(item)
        }

        // check only on category and capability 'view', if specific capability
        // is not provided on the menu item
        else if (
          item.category &&
          !item.capability &&
          can('view', item.category)
        ) {
          acc.push(item)
        }

        // also add item, in case category is not provided on the menu item
        else if (!item.category) {
          acc.push(item)
        }

        return acc
      }, [])

      return filteredItems
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~scss/variables';
@import '~scss/mixins';
@include tiny-vertical-scroll;


.sidebar {
  background: $light-black;

  ::v-deep .el-menu {
    background-color: $light-black;
    border-right: none;

    &.main-menu {
      height:calc(100vh - 140px);
    }
  }

  ::v-deep .el-menu-item:hover,
  ::v-deep .el-submenu__title:hover {
    background-color: #48576a;
  }
  ::v-deep .el-menu-item:focus,
  ::v-deep .el-submenu__title:focus {
    background-color: transparent;
  }

  ::v-deep .project-selector {
    margin-top: 6px;
    padding: 4px;
  }

  .main-menu {
    overflow-y: auto;
    margin-top: 10px;

    ::v-deep .el-submenu__title,
    ::v-deep .el-menu-item {
      color: #bfcbd9;
      padding: 0 40px;
    }
    ::v-deep .el-menu-item.is-active {
      color: $blue;
    }
  }
  ::v-deep .el-badge__content {
    border:none;
  }
  ::v-deep .el-badge__content.is-fixed {
    position: initial;
    -webkit-transform: translateY(-50%) translateX(0);
    transform: translateY(-50%) translateX(0);
  }



  .logo {
    width: 100%;
  }

  .el-form {
    margin-left: 0;

    .bottom-row {
      display: none;
    }
  }

  ::v-deep .el-menu-item,
  ::v-deep .el-submenu__title {
    height: 35px;
    line-height: 35px;
  }
}
</style>
