<!-- This is not implemented correctly and adding a position is currently disabled -->
<template>
  <el-dialog
    :visible.sync="visible"
    title="Create Notification"
    :before-close="close"
    class="position-add-dialog"
  >
    <el-form
      ref="form"
      label-width="120px"
      label-position="top"
      :model="item"
      :rules="rules"
    >
      <el-row :gutter="5">
        <el-col :span="24">
          <el-form-item ref="subject" label="Subject" prop="subject">
            <el-input
              v-model="item.subject"
              :type="!subjectFixed ? 'text' : 'hidden'"
              :style="!subjectFixed ? '' : 'display:none;'"
              placeholder="Subject"
            />
            <template v-if="subjectFixed">{{subjectFixed}}</template>
          </el-form-item>
          <el-form-item ref="message" :label="openParameters.labels.message" prop="message">
            <el-input
              v-model="item.message"
              type="textarea"
              :placeholder="openParameters.labels.message"
              rows="5"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button type="primary" :disabled="loading" @click="onSubmit"> Send Notification</el-button>
        <el-button type="danger" :disabled="loading" @click="close"> Cancel</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import notificationsApi from '@/js/api/v2/notifications';
import {logErrorMessage} from '../../common/util';

export default {
  name: 'NotificationSendDialog',

  props: {
    subject: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      openParameters: {
        subject: null,
        message: null,
        labels: {
          message: 'Message'
        },
      },
      item: {
        subject: null,
        message: null,
      }
    }
  },
  computed: {
    subjectFixed() {
      return this.subject || this.openParameters.subject
    },
    rules() {
      return {
        subject: [
          {
            required: true,
            message: 'Enter a Subject',
          },
        ],
      }
    },
  },
  beforeCreate() {},
  created() {
    if(this.subject) {
      this.item.subject = this.subject
    }
    if(this.message) {
      this.item.message = this.message
    }
  },
  mounted() {

  },
  methods: {
    openNotificationDialog(subject=null, message=null, labels={}) {
      if(subject) {
        this.item.subject = subject
        this.openParameters.subject = subject
      }
      if(message) {
        this.item.message = message
        this.openParameters.message = message
      }
      if(labels) {
        this.openParameters.labels = {...this.openParameters.labels, ...labels}
      }
      this.visible = true
    },
    close() {
      if(this.loading) {
        return
      }
      this.visible = null
      this.$emit('close')
    },
    getStats() {
      notificationsApi.getStats(
        (response) => {
          this.$store.commit('global/notifications', response);
        })
    },
    onSubmit() {
      if(this.loading) {
        return
      }

      this.loading = true

      this.$refs['form'].validate((valid) => {
        if (valid) {
          let payload = Object.assign({}, this.item)
          payload.section = 'Admin'

          notificationsApi.store(
            () => {
              this.$message({
                type: 'success',
                message: 'Notification created',
                duration: 4000,
              })
              this.visible = null
              this.item = {
                subject: null,
                message: null,
              }
              this.getStats()
              this.$emit('sent')
              this.loading = false
            },
            (error) => {
              this.loading = false
              this.errors = error
              logErrorMessage(error)
              this.loading = false
            },
            payload
          )
        }
      })
    },
  },
}
</script>

<style lang="scss">
.position-add-dialog {
  .el-select {
    width: 100%;
  }
}
</style>
