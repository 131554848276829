import ApiResource from '../ApiResource'

class ProfileResource extends ApiResource {
  constructor() {
    super({
      name: 'profile',
    })
  }
}

const profileApi = new ProfileResource()

export default profileApi
