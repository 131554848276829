<template>
  <div class="bid-finalize-container">
    <el-dialog
      :before-close="close"
      :visible.sync="visible"
      title="Finalize bid"
      width="480"
    >
      <template v-if="!overrideFields">
        <div class="into">
          To finalize the bid, it needs to be assigned to an area. You can
          either the Kst2, or search for the correct area.
        </div>
        <label>Filter Project</label>

        <el-row>
          <el-col :span="24">
            <el-select
              v-model="selectedProject"
              class="selected-project"
              filterable
              multiple
            >
              <el-option
                v-for="item in filterProjectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-tabs v-model="activeTab">
          <el-tab-pane label="Match Kst2" name="kst2">
            <label>Kst2</label>
            <el-row :gutter="20">
              <el-col :span="10">
                <el-input v-model="kst2" />
              </el-col>
              <el-col :span="4">
                <el-button size="medium" type="primary" @click="searchProjects">
                  Search
                </el-button>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="Lookup Area" name="area">
            <label>Area name</label>
            <el-row :gutter="20">
              <el-col :span="10">
                <el-input v-model="area_name" />
              </el-col>
              <el-col :span="4">
                <el-button size="medium" type="primary" @click="searchProjects">
                  Search
                </el-button>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
        <el-row class="search-results">
          <el-col v-if="hasSearched">
            <div class="heading">Search results</div>
            <table
              v-if="searchResults.length"
              cellpadding="0"
              cellspacing="0"
              width="100%"
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Customer</th>
                  <th>Project</th>
                  <th>Area</th>
                  <th>Kst2</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in searchResults"
                  :key="index"
                  :class="item.willOverwrite ? 'overwrite' : ''"
                >
                  <td>
                    <el-radio
                      v-model="selectedProjectArea"
                      :label="selectionValue(item)"
                      @change="selectingProjectAreaItem(item)"
                    ></el-radio>

                    <template v-if="item.willOverwrite">
                      <a
                        class="el-icon-info"
                        @click="showOverrideFields(item)"
                      ></a>
                    </template>
                  </td>
                  <td>{{ item.customerName }}</td>
                  <td>{{ item.projectName }}</td>
                  <td>{{ item.areaName }}</td>
                  <td>{{ item.costObjectCode }}</td>
                </tr>
              </tbody>
            </table>
            <p v-else>There are no matching items.</p>
          </el-col>
        </el-row>
      </template>
      <template v-if="overrideFields">
        <div class="into">
          The selected Project and Area already have meta value for 'Bid
          Management', the data will be replaced with new values from this bid.
        </div>
        <el-row>
          <el-table
            :data="overrideFields"
            :highlight-current-row="true"
            size="mini"
            stripe
          >
            <el-table-column label="Field" prop="label" width="180" />
            <el-table-column label="Existing Meta Value" prop="oldValue" />
            <el-table-column label="Bid Value" prop="newValue" />
          </el-table>
        </el-row>
        <el-row>
          <el-col>
            <el-form
              ref="form"
              label-width="120px"
              label-position="top"
            >
              <el-form-item>
                <el-checkbox
                  v-model="iAgree"
                  :value="true"
                >
                  I understand that existing area meta will be replaced.
                </el-checkbox>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </template>

      <el-row class="text-right actions-row">
        <el-button v-if="!overrideFields" @click="close"> Cancel</el-button>

        <el-button
          v-if="!overrideFields"
          type="primary"
          @click="onSubmit"
        >
          Finalize Bid
        </el-button>

        <el-button
        v-if="overrideFields"
        @click="hideOverrideFields()"
          >Back to area search
        </el-button>

        <el-button
          v-if="overrideFields"
          type="primary"
          @click="selectProjectArea"
        >
          Finalize Bid
        </el-button>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import BidsApi from 'api/v2/bids'
import { formatErrorMessage } from '../../common/util'

export default {
  name: 'BidFinalizeDialog',
  props: {
    bid: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      visible: true,
      checkVisible: false,
      activeTab: 'kst2',
      loadedConfiguration: {
        fillable: {},
      },
      kst2: '',
      area_name: '',
      searchParameters: {},
      finalizationData: {},
      searchResults: [],
      hasSearched: false,
      selectedProject: null,
      selectedProjectArea: null,
      selectedItem: null,
      infoOnProjectArea: null,
      filterProject: null,
      filterProjectList: [],
      overrideFields: false,
      iAgree: false
    }
  },
  computed: {},
  created() {
    this.loadFilterProjectList()
  },
  methods: {
    selectingProjectAreaItem(item) {
      this.selectedItem = item
    },
    showOverrideFields(item) {
      this.checkAreaOverwrite(this.bid.id, item.areaId)
      this.infoOnProjectArea = item.projectId + '_' + item.areaId
    },
    selectProjectArea() {
    //  this.overrideFields = false
    //  this.selectedProjectArea = this.infoOnProjectArea
      this.onSubmit(null, true)
    },
    hideOverrideFields() {
      this.overrideFields = false
      this.iAgree = false
      this.selectedItem = null
      if (this.infoOnProjectArea === this.selectedProjectArea) {
        this.selectedProjectArea = false
      }
    },
    loadFilterProjectList() {
      this.loading = true
      BidsApi.getProjectList(
        (response) => {
          this.filterProjectList = response
          this.loading = false
        },
        (error) => {
          this.loading = false
          this.errors = error
          // eslint-disable-next-line
          console.error(error)
        }
      )
    },
    selectionValue(item) {
      return item.projectId + '_' + item.areaId
    },
    close(done) {
      this.$emit('cancel')
      if (done && typeof done === 'function') {
        done()
      }
    },
    searchProjects() {
      this.searchParameters.area_name = this.area_name
      this.searchParameters.cost_object_code = this.kst2
      this.searchParameters.filter_projects = this.selectedProject

      this.loading = true
      BidsApi.searchProjects(
        (response) => {
          this.searchResults = response
          this.hasSearched = true
          this.loading = false
        },
        (error) => {
          this.loading = false
          this.errors = error
          // eslint-disable-next-line
          console.error(error)
        },
        this.searchParameters
      )
    },
    checkAreaOverwrite(bidId, areaId) {
      this.loading = true
      BidsApi.checkAreaOverwrite(
        (response) => {
          this.overrideFields = response
          this.loading = false
        },
        (error) => {
          this.loading = false
          this.$message.error({
            showClose: true,
            message: formatErrorMessage(error),
            duration: 5000,
          })
        },
        bidId,
        areaId
      )
    },
    onSubmit(event, finalize) {
      if(!this.selectedProjectArea) {
        this.$message.error({
          showClose: true,
          message: 'Search and select an area first.',
          duration: 5000,
        })
        return
      }
      if(finalize) {
        if (!this.iAgree) {
          this.$message.error({
            showClose: true,
            message: 'You must check the box indicating your understanding that existing area meta will be replaced.',
            duration: 5000,
          })
          return
        }
      }

      this.finalizationData.projectId = this.selectedProjectArea.split('_')[0]
      this.finalizationData.areaId = this.selectedProjectArea.split('_')[1]

      if (!finalize && this.selectedItem.willOverwrite) {
        this.showOverrideFields(this.finalizationData)
        return
      }

      BidsApi.finalize(
        (response) => {
          if (response !== 1) {
            this.$message.error({
              showClose: true,
              message: formatErrorMessage('Could not finalize bid.'),
              duration: 5000,
            })
            return
          }
          this.$message({
            type: 'success',
            message: 'Bid finalized',
            duration: 4000,
          })
          this.$router.push({
            name: 'projectTab',
            params: {
              projectId: this.finalizationData.projectId,
              tab: 'areas',
              areaId: this.finalizationData.areaId,
            },
          })
        },
        (error) => {
          this.loading = false
          this.$message.error({
            showClose: true,
            message: formatErrorMessage(error),
            duration: 5000,
          })
        },
        this.bid.id,
        this.finalizationData
      )
    },
  },
}
</script>
<style lang="scss">
.bid-finalize-container {
  .text-right {
    text-align: right;
  }

  .into {
    margin-bottom: 30px;
  }

  .el-tabs {
    border-bottom: 1px solid #d1dbe5;
    padding-bottom: 30px;

    label {
      display: block;
      margin-bottom: 5px;
    }
  }

  .search-results {
    min-height: 150px;
    margin-top: 30px;
    max-height: 300px;
    overflow-y: auto;

    .heading {
      font-weight: bold;
      margin-bottom: 30px;
    }

    table {
      font-size: 80%;

      tr {
        background-color: #fff;

        th,
        td {
          text-align: left;
          padding: 3px 10px;
        }
      }

      tr:nth-child(2n) td {
        background: #f7f7f7;
      }
    }

    .overwrite {
      .el-icon-info {
        cursor: pointer;
      }
    }
  }

  .actions-row {
    margin-top: 30px;
  }

  .el-radio {
    margin-right: 10px;
  }

  .el-radio .el-radio__label {
    display: none;
  }

  .selected-project {
    width: 100%;
  }

  .into + label {
    display: block;
    margin-bottom: 5px;
  }
}
</style>
