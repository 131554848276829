import { generateGetters } from '../../helpers.js'

export default {
  ...generateGetters([
    'items',
    'streets',
    'muffen',
    'mfgs',
    'projectId',
    'selectedArea',

    'loading',
    'errored',
  ]),
}
