<template>
  <div class="view-add-edit-delete-view">
    <div v-if="showAddButton && canCreate" class="header-action-row">
      <span>
        <el-button
          v-if="showAddButton && canCreate"
          type="primary"
          size="medium"
          @click="addItem"
        >
          Add {{ vocabulary.single }}
        </el-button>
      </span>
      <span>
        <slot name="extraButtons"></slot>
      </span>
    </div>

    <div
      v-if="showTableColumsFilter && showTableColumsToggle"
      class="header-action-row table-widget table-column-filter"
      :style="maxTableWidth ? `max-width: ${maxTableWidth}px` : ''"
    >
      <div class="search">
        <h4>Toggle columns</h4>

        <el-checkbox
          v-for="(value, header) in headerToggles"
          :key="header"
          v-model="headerToggles[header]"
          @change="saveHiddenColumns"
        >
          {{ header }}
        </el-checkbox>
      </div>
    </div>

    <div
      v-if="showTableSearch"
      class="header-action-row table-widget"
      :style="maxTableWidth ? `max-width: ${maxTableWidth}px` : ''"
    >
      <div class="search">
        <h4>Search columns</h4>
        <div
          v-for="tableSearch in tableSearches"
          v-show="headerToggles[tableSearch.label]"
          :key="tableSearch.label"
        >
          <el-input
            v-model="tableSearch.search"
            :placeholder="`Search ${tableSearch.label.toLowerCase()}`"
            suffix-icon="el-icon-search"
            size="mini"
            @change="search"
          />
        </div>
      </div>
    </div>

    <div
      class="wrap-table"
      :style="maxTableWidth ? `max-width: ${maxTableWidth}px` : ''"
    >
      <data-table
        v-loading="currentlyLoading"
        :data="data"
        :spinner="spinner"
        :headers="visibleHeaders"
        :actions="buttons"
        :primary-route="primaryRoute"
        :on-row-click="onRowClick"
        :category="category"
        :element-loading-text="`Loading ${vocabulary.plural}...`"
        :fixed-actions-column="fixedActionsColumn"
        :actions-column-width="actionsColumnWidth"
        @action="handleAction"
        @sort-change="changeSort"
      />

      <Pagination
        :per-page="perPage"
        :total="total"
        :current-page="currentPage"
        @pageChange="pageChange"
        @changePageSize="changePageSize"
      />

    </div>

    <slot v-if="showAddItemDialog" name="addItemDialog" />
    <slot
      v-if="editableItem"
      name="editItemDialog"
      :item="editableItem"
      :onclose="
        () => {
          editableItem = null
        }
      "
    />
    <slot
      v-if="duplicatableItem"
      name="cloneItemDialog"
      :item="duplicatableItem"
      :onclose="
        () => {
          duplicatableItem = null
        }
      "
      :reload-page="reloadPage"
    />
  </div>
</template>

<script>
import localforage from 'localforage'

import { AuthorizationError } from 'api/errors'
import DataTable from 'components/Common/DataTable'
import { cloneDeep } from 'lodash-es'
import {can, formatErrorMessage, logErrorMessage} from '../../../common/util';
import Pagination from '@/js/components/Common/DefaultPagination.vue';

export default {
  name: 'ViewAddEditDeleteView',

  components: {
    DataTable,
    Pagination
  },

  props: {
    headers: {
      type: Array,
      required: true,
    },

    api: {
      type: Object,
      required: true,
    },

    // if you want to include related models when fetching data
    include: {
      type: Array,
      default() {
        return []
      },
    },

    // if you want to include params on the index route
    indexParams: {
      type: Object,
      default() {
        return {}
      },
    },

    data: {
      type: Array,
      required: true,
    },

    identifyingProp: {
      type: String,
      required: true,
    },

    vocabulary: {
      type: Object,
      required: true,
    },

    buttons: {
      type: Array,
      default: () => [],
    },

    serverSideConfirmDelete: {
      type: Boolean,
      default: false,
    },

    fixedActionsColumn: {
      type: Boolean,
      default: false,
    },

    addRoute: {
      type: String,
      default: null,
    },

    primaryRoute: {
      type: String,
      default: null,
    },

    showAddButton: {
      type: Boolean,
      default: true,
    },

    onRowClick: {
      type: Function,
      default: null,
    },

    showTableColumsFilter: {
      type: Boolean,
      default: false,
    },

    showTableColumsToggle: {
      type: Boolean,
      default: true,
    },

    showTableSearch: {
      type: Boolean,
      default: false,
    },

    spinner: {
      type: Boolean,
    },

    category: {
      type: String,
      required: false,
      default: null,
    },

    actionsColumnWidth: {
      type: Number,
      required: false,
      default: undefined,
    },

    maxTableWidth: {
      type: Number,
      default: undefined,
    },
  },

  data() {
    return {
      currentlyLoading: false,
      currentPage: parseInt(this.$route.params.pageNr, 10),
      editableItem: null,
      duplicatableItem: null,
      headerToggles: this.headers.reduce((o, header) => {
        o[header.label] = true
        return o
      }, {}),
      hiddenColumnsStore: localforage.createInstance({
        storeName: 'hidden-columns',
      }),
      orderDirection: null,
      orderProp: null,
      perPage: 20,
      showAddItemDialog: false,
      tableSearches: this.headers
        .filter((header) => {
          return header.filterDisabled !== true
        })
        .map((header) => ({
          label: header.label,
          prop: header.prop,
          search: '',
        }))
        .filter((h) => {
          return h.prop !== 'start_at' && h.prop !== 'end_at'
        }),
      total: null,

      timetracking: false,
    }
  },

  computed: {
    pageNr() {
      return parseInt(this.$route.params.pageNr || 1, 10)
    },

    canCreate() {
      return can('create', this.category)
    },

    visibleHeaders() {
      return this.headers.filter((header) => this.headerToggles[header.label])
    },
  },

  watch: {
    pageNr(newVal) {
      this.currentPage = newVal
      this.loadPage(newVal)
    },
  },

  mounted() {
    this.loadPage(this.pageNr)

    this.$on('cancelAddItem', () => {
      this.showAddItemDialog = false
    })
    this.$on('changedAddItem', this.afterAddingItem)

    this.$on('cancelEditItem', () => {
      this.editableItem = null
    })
    this.$on('changedEditItem', () => {
      this.editableItem = null
      this.reloadPage()
    })
    this.$on('duplicatedEditItem', (item) => {
      this.duplicatableItem = null
      this.$router.push(this.primaryRoute.replace(':id', item.id))
    })

    this.hiddenColumnsStore
      .getItem(this.vocabulary.plural)
      .then((hiddenColumns) => {
        hiddenColumns.forEach((header) => {
          if (this.headerToggles[header]) this.headerToggles[header] = false
        })
      })
      .catch(() => {
        // do nothing
      })
  },

  methods: {
    saveHiddenColumns() {
      const hiddenColumns = Object.entries(this.headerToggles)
        // eslint-disable-next-line
        .filter(([key, value]) => !value)
        .map(([key]) => key)

      this.hiddenColumnsStore
        .setItem(this.vocabulary.plural, hiddenColumns)
        .catch(() => {
          // do nothing
        })

      this.pageChange(1)
    },

    search() {
      this.pageChange(1)
    },
    changePageSize(newPageSize) {
      this.perPage = newPageSize
      this.pageChange(1)
    },
    pageChange(val) {
      this.currentlyLoading = true
      if (this.timetracking) {
        this.currentlyLoading = false
      }
      this.currentPage = val

      if (!this.timetracking) {
        this.changeHash()
      }
      this.loadPage(val)
    },

    changeHash() {
      const prefix = window.location.hash.match(/^.+\/page\//)[0]
      window.location.hash = `${prefix}${this.currentPage}`
    },

    loadPage(page) {
      const indexParams = this.indexParams
      this.api.index(
        (response) => {
          this.$emit('data', response.data)

          this.currentlyLoading = false
          this.total = response.meta.total
        },
        (error) => {
          this.currentlyLoading = false

          this.$message.error({
            showClose: true,
            message: formatErrorMessage(error),
            duration: 0,
          })

          if (error instanceof AuthorizationError) {
            this.$router.push('/')
          }
        },
        {
          page,
          ...indexParams,
          orderBy: this.orderProp,
          perPage: this.perPage,
          orderDirection: this.orderDirection,
          ...this.tableSearches.reduce((o, tableSearch) => {
            if (tableSearch.search && this.headerToggles[tableSearch.label]) {
              o[tableSearch.prop.split('.')[0]] = tableSearch.search
            }
            return o
          }, {}),
          include: this.include,
        }
      )
    },

    reloadPage() {
      this.loadPage(this.currentPage)
    },

    archiveItem(id) {
      const item = this.data.find((item) => item.id === id)
      const identifyingName = item[this.identifyingProp]

      this.$confirm(`Do you want to archive "${identifyingName}"?`, 'Warning', {
        confirmButtonText: 'Archive',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        this.api.archive(
          () => {
            this.$message.success({
              showClose: true,
              message: `${identifyingName} has been archived.`,
              duration: 5000,
            })
            this.reloadPage()

            if (this.api.category === 'project') {
              this.$store.dispatch('projects/fetchItems')
            }
          },
          (error) => {
            let errorMessage = `An error occurred while archiving the ${this.vocabulary.single}. Please try again.`

            if (error) {
              logErrorMessage(error)
              errorMessage = error.message
            }

            this.$message.error({
              showClose: true,
              message: errorMessage,
              duration: 5000,
            })
          },
          id
        )
      })
    },

    deleteItemServerSideConfirm(id, force = false) {
      const item = this.data.find((item) => item.id === id)
      const identifyingName = item[this.identifyingProp]

      this.api.delete(
        (response) => {
          if (response.deleted) {
            this.$message.success({
              showClose: true,
              message: `${identifyingName} has been removed.`,
              duration: 5000,
            })
            this.reloadPage()
          }
          if (response.deleted === false) {
            this.$message.info({
              showClose: true,
              message: `${identifyingName} cannot be removed, other items are still linked to it`,
              duration: 5000,
            })
          }
          if (response.confirm) {
            this.$confirm(
              `${response.text}\r\n\r\n` +
                `Do you want to permanently delete "${identifyingName}"?`,
              'Warning',
              {
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                type: 'danger',
              }
            ).then(() => {
              this.deleteItemServerSideConfirm(id, true)
            })
          }
        },
        (error) => {
          logErrorMessage(error)
        },
        id,
        { force }
      )
    },

    deleteItem(id) {
      const item = this.data.find((item) => item.id === id)
      const identifyingName = item[this.identifyingProp]

      this.$confirm(
        `Do you want to permanently delete "${identifyingName}"?`,
        'Warning',
        {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'danger',
        }
      ).then(() => {
        this.api.delete(
          () => {
            this.$message.success({
              showClose: true,
              message: `${identifyingName} has been removed.`,
              duration: 5000,
            })
            this.reloadPage()

            // @todo this does not belong here
            if (this.api.category === 'project') {
              this.$store.dispatch('projects/fetchItems')
            }
          },
          (error) => {
            let errorMessage = `An error occurred while deleting the ${this.vocabulary.single}. Please try again.`

            if (error) {
              logErrorMessage(error)
              errorMessage = error.message
            }

            this.$message.error({
              showClose: true,
              message: errorMessage,
              duration: 5000,
            })
          },
          id
        )
      })
    },

    editItem(id) {
      if (this.routes && this.routes.edit) {
        this.$router.push(this.routes.edit.replace(':id', id))
        return
      }

      this.editableItem = cloneDeep(this.data.find((item) => item.id === id))
    },

    duplicateItem(id) {
      if (this.routes && this.routes.clone) {
        this.$router.push(this.routes.clone.replace(':id', id))
        return
      }

      this.duplicatableItem = cloneDeep(
        this.data.find((item) => item.id === id)
      )
    },

    addItem() {
      if (this.addRoute) {
        this.$router.push(this.addRoute)
        return
      }

      this.showAddItemDialog = true
    },

    afterAddingItem() {
      this.showAddItemDialog = false

      let page = Math.ceil(this.total / this.perPage)
      if (this.total % this.perPage === 0) {
        // We filled the last page, and the current customer has been added on a new page
        page += 1
      }

      this.total += 1
      this.pageChange(page)
    },

    handleAction({ event, data }) {
      const button = this.buttons.find((item) => item.event === event)

      if (button.route) {
        this.$router.push(button.route.replace(':id', data))
        return
      }
      if (button.action) {
        button.action(data)
        return
      }

      if (event === 'change') {
        this.editItem(data)
      } else if (event === 'delete' && !this.serverSideConfirmDelete) {
        this.deleteItem(data)
      } else if (event === 'delete') {
        this.deleteItemServerSideConfirm(data)
      } else if (event === 'archive') {
        this.archiveItem(data)
      } else if (event === 'duplicate') {
        this.duplicateItem(data)
      }
    },

    changeSort({ prop, order }) {
      this.orderProp = prop
      if (order === 'ascending') {
        this.orderDirection = 'asc'
      } else if (order === 'descending') {
        this.orderDirection = 'desc'
      } else {
        this.orderDirection = null
      }

      this.pageChange(1)
    },
  },
}
</script>
