import ApiResource from '../ApiResource'

class PurchaseInvoiceResource extends ApiResource {
  constructor() {
    super({
      name: 'purchase-invoices',
    })
  }

  export(resolve, reject, project_id = null) {
    this.get(`${this.getUrl()}/export`, { params: { project_id } })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const purchaseInvoicesApi = new PurchaseInvoiceResource()

export default purchaseInvoicesApi
