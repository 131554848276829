<template>
  <div>
    <el-form class="form-box" label-width="120px" label-position="top">
      <el-row type="flex" class="row-bg" :gutter="10">
        <el-col :span="6">
          <el-form-item>
            <el-select
              v-model="selectedProject"
              placeholder="Select project..."
              clearable
              filterable
              :disabled="!!selectedProject && filterable"
              @input="
                fetchAreas(selectedProject)
                fetchCategories(selectedProject)
              "
            >
              <el-option
                v-for="project in projects"
                :key="project.id"
                :label="project.name"
                :value="project.name"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item style="margin-bottom: 3px">
            <el-select
              v-model="selectedCategory"
              placeholder="Select category..."
              clearable
              filterable
              :disabled="!selectedProject"
            >
              <el-option
                v-for="(category, index) in categories"
                :key="index"
                :label="category.name"
                :value="category.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-if="selectedCategory">
            <el-select
              v-model="selectedSubcategoryName"
              placeholder="Select subcategory..."
              clearable
              filterable
              :disabled="!selectedCategory"
            >
              <el-option
                v-for="subcategory in filteredSubcategories"
                :key="subcategory.id"
                :label="subcategory.subcategoryName"
                :value="subcategory.subcategoryName"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item>
            <el-select
              v-model="selectedUser"
              placeholder="Select user..."
              clearable
              filterable
            >
              <el-option
                v-for="worker in workers"
                :key="worker.id"
                :label="worker.name"
                :value="worker.name"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item style="margin-bottom: 3px">
            <el-select
              v-model="selectedArea"
              placeholder="Select area..."
              clearable
              :disabled="!selectedProject"
              @input="selectedLocationTypeAndId = null"
            >
              <el-option
                v-for="area in areas"
                :key="area.id"
                :label="area.name"
                :value="area.name"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item>
            <el-select
              v-model="selectedLocationTypeAndId"
              placeholder="Select location..."
              clearable
              filterable
              :disabled="!selectedProject"
            >
              <template v-for="locationType in ['streets', 'mfgs', 'muffen']">
                <el-option-group
                  v-if="locations && locations[locationType]"
                  :key="locationType"
                  :label="locationType"
                >
                  <el-option
                    v-for="location in locations[locationType]"
                    :key="location.id"
                    :label="location.name"
                    :value="locationType + '-' + location.id"
                  />
                </el-option-group>
              </template>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="max-width: 160px; display: inline-block">
            <el-select v-model="selectedDateType">
              <el-option label="startdate/enddate" :value="DATE_START_END"/>
              <el-option label="created between" :value="DATE_CREATED"/>
              <el-option label="updated between" :value="DATE_UPDATED"/>
            </el-select>
          </el-form-item>
          <el-form-item style="max-width: 150px; display: inline-block">
            <el-date-picker
              v-model="startAndEndDate"
              type="daterange"
              placeholder="Pick a start/end date..."
              clearable
            />
          </el-form-item>
        </el-col>
      </el-row>
      <div>
        <el-form-item>
          <el-checkbox v-model="showImages"> Show images?</el-checkbox>
          <el-checkbox v-model="showComments"> Show comments?</el-checkbox>
          <el-checkbox v-model="hideFactorAndPricing">
            Hide factor and pricing?
          </el-checkbox>
          <el-checkbox v-model="hideProcessedItems">
            Hide processed items
          </el-checkbox>
        </el-form-item>
      </div>
      <el-button
        type="primary"
        :disabled="selectedProject === ''"
        :loading="loading"
        @click="submit()"
      >
        Search
      </el-button>
    </el-form>

    <div v-if="importControls" class="form-box spacing">
      <el-button
        type="success"
        :loading="importLoading"
        :disabled="selectedItems.length <= 0"
        @click="importItems"
      >
        Import <b>{{ selectedItems.length }}</b> items
      </el-button>
      <template v-if="purchaseInvoice">
        for: <strong>{{ purchaseInvoice?.subcontractor?.name }}</strong>
      </template>
    </div>

    <div v-if="itemsFetched && inProjectReport" class="projectDetails">
      <p>
        Report for <b>{{ reportDetails.project.name }}</b>
        {{ reportDetails.dateFilter }}
        between
        <b>{{ reportDetails.startdate }} - {{ reportDetails.enddate }}</b>
        <template v-if="reportDetails.area">
          in area <b>{{ reportDetails.area }}</b>
        </template>
      </p>
    </div>



    <!-- Accommodate the filterable table view also -->
    <template v-if="filterable">
      <PurchaseInvoicesImportItems
        v-if="purchaseInvoice && loadItems"
        ref="purchaseInvoiceItemsTable"
        :request-data="invoiceItemsFilter"
        :filter-preset="invoiceItemsFilterPreset"
        @selection-change="tableSelectionChange"
      />
      <SalesInvoicesImportItems
        v-if="lerf && loadItems"
        ref="salesInvoiceItemsTable"
        :request-data="invoiceItemsFilter"
        :filter-preset="invoiceItemsFilterPreset"
        @selection-change="tableSelectionChange"
      />

    </template>

    <!-- Otherwise use the non filtered method -->
    <template v-if="!filterable">
      <div v-if="itemsFetched">
        <h6 style="margin-bottom: 20px">Entries</h6>
        <div v-if="lerf || claim || purchaseInvoice">
          <el-checkbox v-model="selectAll" style="margin-bottom: 5px">
            Select All
          </el-checkbox>
        </div>
      </div>
      <div v-if="loading" style="text-align: center; width: 100%">Loading...</div>
      <template v-if="entries.length">
        <ItemsReportOverview
          v-for="entry in entries"
          :key="entry.id"
          :entry="entry"
          :workers="users"
          :categories="categories"
          :projects="projects"
          :areas="areas"
          :report-details="reportDetails"
          :show-import-controls="importControls"
          :show-mark-controls="!importControls"
        />
      </template>
    </template>

  </div>
</template>

<script>
import projectsApi from 'api/v2/projects'
import projectPricingCategoriesApi from 'api/v2/projectPricingCategories'
import pricingSubcategoriesApi from 'api/v2/pricingSubcategories'
import lerfItems from 'api/v2/lerfItems'
import purchaseInvoiceItems from '@/js/api/v2/purchaseInvoiceItems'
import usersApi from 'api/v2/users'
import projectAreasApi from 'api/v2/projectAreas'
import projectReportApi from 'api/v2/projectReport'
import moment from 'moment'
import {formatErrorMessage, logErrorMessage} from 'common/util'

import ItemsReportOverview from './ItemsReportOverview'
import claimItemsApi from 'api/v2/claimItems'

const DATE_START_END = Symbol('DATE_START_END')
const DATE_CREATED = Symbol('DATE_CREATED')
const DATE_UPDATED = Symbol('DATE_UPDATED')

import PurchaseInvoicesImportItems from '@/js/components/PurchaseInvoices/ImportTableWithFilters.vue'
import SalesInvoicesImportItems from '@/js/components/Lerfs/ImportTableWithFilters.vue'


export default {
  name: 'ProjectReportTable',

  components: {
    ItemsReportOverview,
    PurchaseInvoicesImportItems,
    SalesInvoicesImportItems,
  },

  props: {
    importControls: {
      default: false,
      type: Boolean,
    },
    filterable: {
      default: false,
      type: Boolean,
    },
    lerf: {
      type: Object,
      default: null,
    },
    purchaseInvoice: {
      type: Object,
      default: null,
    },
    claim: {
      type: Object,
      default: null,
    },
  },

  data() {
    // set default start and end dates
    let startDate = moment().subtract('1', 'weeks').format('YYYY-MM-DD');
    let endDate = moment().format('YYYY-MM-DD');

    // check - if report is for lerf or purchase invoice, set default start/end date = beginning of week /  next day
    if(this.lerf || this.purchaseInvoice) {
      startDate = this.purchaseInvoice ? this.purchaseInvoice.startAt : this.lerf.startAt
      endDate = this.purchaseInvoice ? this.purchaseInvoice.endAt : this.lerf.endAt
    }

    return {
      projects: [],
      users: [],
      workers: [],
      selectAll: true,
      selectedProject: '',
      selectedCategory: '',
      selectedSubcategoryName: null,
      selectedLocationTypeAndId: null,
      selectedUser: '',
      selectedArea: '',
      areas: [],
      startAndEndDate: [startDate, endDate],

      DATE_START_END,
      DATE_CREATED,
      DATE_UPDATED,
      selectedDateType: DATE_START_END,

      categories: [],
      subcategories: [],
      allCategories: [],

      showImages: true,
      showComments: true,
      hideFactorAndPricing: false,
      hideProcessedItems: false,

      loading: false,

      loadItems: false,
      itemsFetched: false,
      entries: [],
      filterTableSelectedItems: [],

      importLoading: false,

      reportDetails: {
        project: null,
        category: null,
        showImages: false,
        showComments: false,
        hideFactorAndPricing: false,
        dateFilter: null,
        startdate: null,
        enddate: null,
        user: null,
        area: null,
        isPurchaseInvoice: null,
      },
    }
  },

  computed: {
    inProjectReport() {
      if (this.$route.path.includes('lerfs')) {
        return false
      }
      return true
    },
    workerId() {
      const worker = this.workers.find((worker) => {
        return worker.name === this.selectedUser
      })

      if (!worker) {
        return null
      }

      return worker.id
    },

    areaId() {
      const area = this.areas.find((area) => {
        return area.name === this.selectedArea
      })

      if (!area) {
        return null
      }

      return area.id
    },

    selectedItems() {

      // If the filterable table is used then do not call the API direct, rather refresh the table
      if(this.filterable) {
        return this.filterTableSelectedItems
      }

      let items = []

      if (!this.entries.length) {
        return items
      }

      this.entries.map((entry) => {
        entry.items.map((item) => {
          if (item.selected) {
            items.push(item)
          }
        })
      })

      return items
    },
    filteredSubcategories() {
      if (!this.selectedProject) {
        return null
      }
      if (!this.selectedCategory) {
        return this.subcategories
      }

      const project = this.projects.find((p) => {
        return p.name === project
      })

      return this.subcategories.filter(
        (subcategorie) => subcategorie.categoryName === this.selectedCategory
      )
    },
    locations() {
      if (!this.selectedProject) {
        return null
      }

      if (!this.selectedArea) {
        return {
          streets: this.areas.map((area) => area.streets).flat(),
          mfgs: this.areas.map((area) => area.mfgs).flat(),
          muffen: this.areas.map((area) => area.muffen).flat(),
        }
      }
      const area = this.areas.find((area) => area.id === this.areaId)
      return {
        streets: area.streets,
        mfgs: area.mfgs,
        muffen: area.muffen,
      }
    },
    invoiceItemsFilterPreset() {
      const data = {}
      if(this.purchaseInvoice?.planIndex) {
        data.plan_index = this.purchaseInvoice.planIndex
      }
      if(this.purchaseInvoice?.area) {
        data.area = this.purchaseInvoice.area.join(';')
      }
      if(this.lerf?.area) {
        data.area = this.lerf.area.join(';')
      }
      return data
    },
    invoiceItemsFilter() {
      const data = {}

      if (this.purchaseInvoice) {
        data.purchaseInvoiceId = this.purchaseInvoice.id
      } else if (this.lerf) {
        data.salesInvoiceId = this.lerf.id
      } else {
        throw Error('Must be purchase or sales invoice');
      }

      data.projectId = this.reportDetails.project.id
      data.startDate = this.reportDetails.startdate
      data.endDate = this.reportDetails.enddate
      data.dateFilter = this.reportDetails.dateFilter
      data.hideFactorAndPricing = this.reportDetails.hideFactorAndPricing
      data.showComments = this.reportDetails.showComments
      data.showImages = this.reportDetails.showImages
      data.processed =  this.hideProcessedItems ? 0 : 1
      return data
    }
  },

  watch: {
    selectAll(newValue) {
      this.controlSelectAll(newValue)
    },
  },

  mounted() {
    this.fetchUsers()
    this.fetchProjects()
    this.fetchSubcategories()
    // this.fetchAllCategories()
  },

  methods: {
    // fetchAllCategories() {
    //   if (!this.projectId) return
    //   projectPricingCategoriesApi.index(
    //     (response) => {
    //       this.allCategories = response
    //     },
    //     (error) => {
    //       logErrorMessage(error)
    //     }
    //   ),
    //     this.projectId
    // },
    fetchUsers() {
      usersApi.index(
        (users) => {
          this.users = users
          this.filterWorkers()
        },
        (error) => {
          logErrorMessage(error)
          this.$message.error({
            message: formatErrorMessage(error),
          })
        }
      )
    },

    fetchProjects() {
      projectsApi.index(
        (response) => {
          this.projects = response

          if (this.lerf) {
            this.selectedProject = this.lerf.project.name
          } else if (this.purchaseInvoice) {
            this.selectedProject = this.purchaseInvoice.project.name
          }

          if (this.lerf || this.purchaseInvoice) {
            this.fetchAreas(this.selectedProject)
            this.fetchCategories(this.selectedProject)
          }

        },
        (error) => {
          logErrorMessage(error)
          this.$message.error({
            message: formatErrorMessage(error),
          })
        }
      )
    },

    fetchCategories(project) {
      if (!project) {
        return
      }
      // this.selectedCategory = "";

      const id = this.projects.find((p) => {
        return p.name === project
      }).id

      projectPricingCategoriesApi.index(
        (response) => {
          this.categories = response
        },
        (error) => {
          logErrorMessage(error)
        },
        id
      )
    },

    fetchSubcategories() {
      pricingSubcategoriesApi.index(
        (response) => {
          this.subcategories = response
        },
        (error) => {
          logErrorMessage(error)
        }
      )
    },

    filterWorkers() {
      const workers = this.users.filter((user) => {
        return (
          [
            'worker',
            'project_user',
            'project_leader',
            'project_supervisor',
            'subcontractor_supervisor',
          ].indexOf(user.role) !== -1
        )
      })
      this.workers = workers
    },

    fetchAreas(project) {
      if (!project) {
        return
      }
      this.selectedArea = ''

      const id = this.projects.find((p) => {
        return p.name === project
      }).id

      projectAreasApi.index(
        (response) => {
          this.areas = response
        },
        (error) => {
          logErrorMessage(error)
        },
        id,
        {include: ['muffen', 'streets', 'mfgs']}
      )
    },

    submit() {
      this.loading = true
      this.$set(this, 'reportDetails', {
        project: this.projects.find(
          ({name}) => name === this.selectedProject
        ),
        category_id: this.selectedCategory,
        showImages: this.showImages,
        showComments: this.showComments,
        hideFactorAndPricing: this.hideFactorAndPricing,
        dateFilter:
          this.selectedDateType === DATE_CREATED
            ? 'created'
            : this.selectedDateType === DATE_UPDATED
              ? 'updated'
              : '',
        startdate: this.startAndEndDate[0],
        enddate: this.startAndEndDate[1],
        user_id: this.selectedUser,
        area_id: this.selectedArea,
        isPurchaseInvoice: !!this.purchaseInvoice,
      })
      this.entries = []

      const projectId = this.projects.find((p) => {
        return p.name === this.selectedProject
      }).id

      const params = {}

      if (this.purchaseInvoice) {
        params['isPurchaseInvoice'] = true
      } else {
        params['isSalesInvoice'] = true
      }

      if (this.selectedUser) {
        params['user_id'] = this.workerId
      }

      if (this.selectedArea) {
        params['area_id'] = this.areaId
      }

      if (this.selectedCategory.length) {
        const category = this.categories.find((cat) => {
          return cat.name === this.selectedCategory
        })

        if (category) {
          params['category_id'] = category.id
        }
      }

      if (this.selectedSubcategoryName) {
        params['subcategory_name'] = this.selectedSubcategoryName
      }

      if (this.startAndEndDate) {
        params['startDate'] = this.startAndEndDate[0]
        params['endDate'] = this.startAndEndDate[1]

        switch (this.selectedDateType) {
        case DATE_CREATED:
          params['dateFilter'] = 'created_at'
          break
        case DATE_UPDATED:
          params['dateFilter'] = 'updated_at'
          break
        }
      }

      params['processed'] = this.hideProcessedItems ? 0 : 1

      // If the filterable table is used then do not call the API direct, rather refresh the table
      if(this.filterable) {
        if(this.loadItems) {
          this.loadItems = false
        }
        this.$nextTick(() => {
          this.loadItems = true
          // TODO, this ,ust be done with a callback.
          this.loading = false
        })
        return
      }

      projectReportApi.index(
        (response) => {
          this.loading = false
          this.entries = this.addSelectedToItems(response)
          this.itemsFetched = true
          if (!this.entries.length) {
            this.$message({
              showClose: true,
              type: 'warning',
              message: 'Your current filter did not yield any results',
              duration: 5000,
            })
          }
        },
        (error) => {
          this.loading = false
          logErrorMessage(error)
          this.$message({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 5000,
          })
        },
        projectId,
        params
      )
    },

    addSelectedToItems(entries) {
      entries.forEach((entry) => {
        const cat = this.allCategories.find((category) => {
          return category.id === entry.categoryId
        })
        if (cat) {
          entry['categoryName'] = cat.name
        } else {
          entry['categoryName'] = this.selectedCategory
        }
      })

      if (this.selectedLocationTypeAndId) {
        const [selectedLocationType, selectedLocationId] =
          this.selectedLocationTypeAndId.split('-')
        entries = entries.filter((entry) => {
          switch (selectedLocationType) {
          case 'streets':
            return entry['street'].id === parseInt(selectedLocationId, 10)
          case 'mfgs':
            return entry['mfg'].id === parseInt(selectedLocationId, 10)
          case 'muffen':
            return entry['muffe'].id === parseInt(selectedLocationId, 10)
          default:
            return true
          }
        })
      }

      if (!this.selectedUser.length) {
        entries.forEach((entry) => {
          const user = this.workers.find((worker) => {
            return worker.id === entry.userId
          })
          if (user) {
            entry['userName'] = user.name
          }
        })
      }

      // don't show entries without items, files or images
      return entries
        .filter(
          (entry) =>
            entry.items.length ||
            (entry.files &&
              (entry.files.files.length || entry.files.images.length))
        )
        .map((entry) => {
          entry['items'] = entry.items.map((item) => {
            item['selected'] = true
            return item
          })
          return entry
        })
    },

    controlSelectAll(value) {
      if (!value) {
        this.entries.forEach((entry) => {
          return entry.items.forEach((item) => {
            item['selected'] = false
            return item
          })
        })
      } else {
        this.entries.forEach((entry) => {
          return entry.items.forEach((item) => {
            item['selected'] = true
            return item
          })
        })
      }
    },

    importItems() {
      if (this.claim) {
        this.importClaimItems()
        return
      }
      if (this.purchaseInvoice) {
        this.importPurchaseInvoiceItems()
        return
      }
      if (this.lerf) {
        this.importLerfItems()
        return
      }

      throw Error('Must be purchase, sales invoice or claim');
    },

    importLerfItems() {
      this.importLoading = true
      const importPositionItems = this.selectedItems.map((selectedItem) => {
        return {
          position_id: selectedItem.position_id,
          position_item_id: selectedItem.id,
        }
      })

      lerfItems.import(
        (response) => {
          this.importLoading = false
          this.$emit('reload')
          this.$refs.salesInvoiceItemsTable.refresh()


          this.$confirm(
            'Shall I take you to the Invoice?',
            `${response.length} items imported`,
            {
              confirmButtonText: 'Yes please',
              cancelButtonText: 'No thanks',
              type: 'success',
            }
          ).then(() => {
            // eslint-disable-next-line
            console.log(
              'Taking you to projectLerf route, project: %s, lerf: %s',
              this.lerf.project.id,
              this.lerf.id
            )
            this.$router.push({
              name: 'projectLerfTab',
              params: {
                projectId: this.lerf.project.id,
                lerfId: this.lerf.id,
                tab: 'items',
              },
            })
          })
        },
        (error) => {
          logErrorMessage(error)
          this.importLoading = false
          this.$message({
            showClose: true,
            type: 'error',
            message: 'Import failed: ' + formatErrorMessage(error),
            duration: 5000,
          })
        },
        this.lerf.id,
        {
          items: importPositionItems,
        }
      )
    },

    importPurchaseInvoiceItems() {
      this.importLoading = true
      const importPositionItems = this.selectedItems.map((selectedItem) => {
        return {
          position_id: selectedItem.position_id,
          position_item_id: selectedItem.id,
        }
      })
      purchaseInvoiceItems.import(
        (response) => {
          this.importLoading = false
          this.$emit('reload')
          this.$refs.purchaseInvoiceItemsTable.refresh()

          this.$confirm(
            'Shall I take you to the Purchase Invoice?',
            `${response.length} items imported`,
            {
              confirmButtonText: 'Yes please',
              cancelButtonText: 'No thanks',
              type: 'success',
            }
          ).then(() => {
            // eslint-disable-next-line
            console.log(
              'Taking you to projectPurchaseInvoice route, project: %s, purchaseInvoice: %s',
              this.purchaseInvoice.project.id,
              this.purchaseInvoice.id
            )
            this.$router.push({
              name: 'projectPurchaseInvoiceTab',
              params: {
                projectId: this.purchaseInvoice.project.id,
                purchaseInvoiceId: this.purchaseInvoice.id,
                tab: 'items',
              },
            })
          })
        },
        (error) => {
          logErrorMessage(error)
          this.importLoading = false
          this.$message({
            showClose: true,
            type: 'error',
            message: 'Import failed: ' + formatErrorMessage(error),
            duration: 5000,
          })
        },
        this.purchaseInvoice.id,
        {
          items: importPositionItems,
        }
      )
    },

    importClaimItems() {
      this.importLoading = true
      const importPositionItems = this.selectedItems.map((selectedItem) => {
        return {
          position_id: selectedItem.position_id,
          position_item_id: selectedItem.id,
        }
      })

      claimItemsApi.import(
        (response) => {
          this.importLoading = false
          this.$emit('reload')
          this.$confirm(
            'Shall I take you to the Claim?',
            `${response.length} items imported`,
            {
              confirmButtonText: 'Yes please',
              cancelButtonText: 'No thanks',
              type: 'success',
            }
          ).then(() => {
            this.$router.push({
              name: 'projectClaimTab',
              params: {
                projectId: this.claim.project.id,
                claimId: this.claim.id,
                tab: 'items',
              },
            })
          })
        },
        (error) => {
          logErrorMessage(error)
          this.importLoading = false
          this.$message({
            showClose: true,
            type: 'error',
            message: 'Import failed: ' + formatErrorMessage(error),
            duration: 5000,
          })
        },
        this.claim.id,
        {
          items: importPositionItems,
        }
      )
    },
    tableSelectionChange(values) {
      this.filterTableSelectedItems = values
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  padding: 0 !important;
  margin-right: 5px;
}
</style>
