import ApiResource from '../ApiResource'

class ProjectInvoiceFieldResource extends ApiResource {
  constructor() {
    super({
      name: 'project-invoice-fields',
    })
  }
}

const projectInvoiceFieldsApi = new ProjectInvoiceFieldResource()

export default projectInvoiceFieldsApi
