import ApiResource from '../ApiResource'

class UserResource extends ApiResource {
  constructor() {
    super({
      name: 'users',
    })
  }

  downloadCSV(resolve, reject) {
    this.ids = []
    this.post(`${this.getUrl()}/download`, { format: 'csv' })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const usersApi = new UserResource()

export default usersApi
