import Vue from 'vue'

import { generateSetters } from '../../helpers.js'

export default {
  ...generateSetters([
    'items',
    'users',
    'usersLoading',
    'usersErrored',
    'customers',
    'customersLoading',
    'customersErrored',
    'areas',
    'areasProjectId',
    'areasLoading',
    'areasErrored',
    'currentLerf',
    'currentPurchaseInvoice',
    'currentPosition',
    'currentClaim',
  ]),

  currentProject(state, project) {
    const payload = project

    // Sometimes we do not get all relations back, because they were not updated... but we want to keep them anyway
    if (
      state.currentProject &&
      state.currentProject.id &&
      project &&
      project.id
    ) {
      for (const key of Object.keys(state.currentProject)) {
        if (payload[key] === undefined) {
          payload[key] = state.currentProject[key]
        }
      }
    }

    // First of all set the project as requested
    Vue.set(state, 'currentProject', payload)

    // If this is not a project (something like null, we can't do much else)
    if (!payload || !payload.id) return

    // The list of current projects can be updated with this project
    const items = state.items || []
    const index = items.findIndex(
      ({ id }) => parseInt(id, 10) === parseInt(payload.id, 10)
    )
    if (index === -1) {
      items.push(payload)
    } else {
      items.splice(index, 1, payload)
    }
    Vue.set(state, 'items', items)
  },

  setPnl(state, { data, projectId }) {
    Vue.set(state.pnl, projectId, data)
  },
}
