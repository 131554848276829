import router from 'router/index'

import projectsApi from 'api/v2/projects'
import { AuthorizationError } from 'api/errors'
import { formatErrorMessage } from 'common/util'
import usersApi from 'api/v2/users'
import customersApi from 'api/v2/customers'
import { Message } from 'element-ui'

export default {
  fetchItems({ commit }) {
    return new Promise((resolve) => {
      projectsApi.index(
        (response) => {
          commit('items', response)
          resolve()
        },
        (error) => {
          throw error
        }
      )
    })
  },

  setCurrentProjectById({ commit, state }, projectId) {
    const project = state.items.find((project) => project.id === projectId)
    if (project) {
      commit('currentProject', project)
    }
  },

  fetchAllUsers({ commit, getters }, payload = {}) {
    const { forceReload } = payload

    if (
      !getters.usersErrored &&
      getters.users &&
      getters.users.length &&
      !forceReload
    ) {
      // No need to do all those calls again
      return
    }

    commit('usersLoading', true)
    usersApi.index(
      (users) => {
        commit('usersErrored', false)
        commit('users', users)
        commit('usersLoading', false)
      },
      (error) => {
        commit('usersErrored', true)
        if (error instanceof AuthorizationError) {
          return
        }

        Message.error({
          message: formatErrorMessage(error),
        })
      },
      {
        include: [],
      }
    )
  },

  fetchAllCustomers({ commit, getters }, payload = {}) {
    const { forceReload } = payload

    if (
      !getters.customersErrored &&
      getters.customers &&
      getters.customers.length &&
      !forceReload
    ) {
      // No need to do all those calls again
      return
    }

    commit('customersLoading', true)
    customersApi.index(
      (customers) => {
        commit('customersErrored', false)
        commit('customers', customers)
        commit('customersLoading', false)
      },
      (error) => {
        commit('customersErrored', true)

        if (error instanceof AuthorizationError) {
          return
        }
        if (error) {
          this.$message.error({
            message: formatErrorMessage(error),
          })
        }
      },
      {
        include: [],
      }
    )
  },

  saveProject({ commit, getters }, payload = {}) {
    const apiMethod = getters.currentProject.id ? 'update' : 'store'

    return projectsApi[apiMethod](
      (project) => {
        Message.success({
          showClose: true,
          message: 'Successfully saved',
          duration: 5000,
        })

        commit('currentProject', project)

        router.push('/projects/' + project.id)
      },
      (error) => {
        let errorMessage

        if (error && error.message) {
          errorMessage = error.message
        } else {
          errorMessage = 'Error occurred'
        }

        Message.error({
          showClose: true,
          message: errorMessage,
          duration: 10000,
        })
      },
      getters.currentProject.id || {},
      payload
    )
  },

  saveProjectContact({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      projectsApi.updateContact(
        (project) => {
          commit('currentProject', project)

          resolve()
        },
        (error) => {
          let errorMessage

          if (error && error.message) {
            errorMessage = error.message
          } else {
            errorMessage = 'Error occurred'
          }

          Message.error({
            showClose: true,
            message: errorMessage,
            duration: 10000,
          })

          reject('error while saving')
        },
        id,
        payload
      )
    })
  },
}
