import configurationApi from 'api/v2/configuration'
import {logErrorMessage} from '../../../common/util';

export default {
  fetchData({ commit }) {
    configurationApi.index(
      (response) => {
        commit('data', response.data)
      },
      (error) => {
        logErrorMessage(error)
      }
    )
  },

  updateData({ commit }, data) {
    return configurationApi.update(
      (response) => {
        commit('data', response.data)
      },
      (error) => {
        logErrorMessage(error)
      },
      data
    )
  },
}
