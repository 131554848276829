<template>
  <el-card id="choose-subcontractor" class="box-card">
    <div slot="header" class="clearfix">
      <span>Setup pricing for...</span>
    </div>
    <div class="text project-details">
      <el-radio-group
        v-model="selectedSubcontractor"
        @change="changeSubcontractor"
      >
        <el-radio label="Internal pricing"></el-radio>
        <el-radio
          v-for="subcontractor in subcontractors"
          :key="subcontractor.id"
          :label="subcontractor.name"
        >
          <template v-if="!hasProjectPricingTemplate(subcontractor)"
            >{{ subcontractor.name }}
            <i
                class="el-icon-warning"
                title="Missing Subcontractor Pricing"
            />
          </template>
          <template v-else>
            {{subcontractor.name}}
            <i
                v-if="hasProjectPricingTemplate(subcontractor, true).items_with_mapping_errors !== 0"
                class="el-icon el-icon-info action-edit item-has-issue"
                title="Items in the template need attention"
            ></i>
          </template>
        </el-radio>
      </el-radio-group>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'ProjectSinglePricingChooseSubcontractor',

  props: {
    project: {
      type: Object,
      required: true,
    },
    subcontractors: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    return {
      selectedSubcontractor: 'Internal pricing',
    }
  },
  computed: {
    selectedSubcontractorId() {
      if (this.selectedSubcontractor === 'Internal Pricing') {
        return 'internal'
      }
      const subcontractor = this.subcontractors.find(
        (subcontractor) => subcontractor.name === this.selectedSubcontractor
      )
      return subcontractor ? subcontractor.id : 'internal'
    },
  },
  methods: {
    changeSubcontractor() {
      this.$emit('change-subcontractor', this.selectedSubcontractorId)
    },
    hasProjectPricingTemplate(subcontractor, returnPricing=false) {
      if (
        !subcontractor.pricingTemplates &&
        !subcontractor.pricingTemplates.length
      ) {
        return false
      }
      const existingTemplate = subcontractor.pricingTemplates.find(
        (template) => template.project_id === this.project.id
      )
      if(returnPricing) {
        return existingTemplate
      }
      return existingTemplate ? true : false
    },
  },
}
</script>
<style lang="scss">
#choose-subcontractor {
  .el-card__body {
    padding:10px!important;
  }
  .el-card__header {
    padding:4px 10px!important;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-radio-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}
::v-deep .el-radio {
  margin-left: 0px;
  margin-right: 0px;
  line-height: 30px;
  font-size: 14px;
  padding: 0;

  > div > div {
    padding: 5px 10px;
    line-height: 22px;
  }
  &.selectable:not(.disabled):hover {
    cursor: pointer;
    background: rgba(15, 126, 190, 0.2);
  }
  &.selectable.disabled:hover {
    cursor: not-allowed;
  }
  &:hover {
    background: rgba(15, 126, 190, 0.2);
  }
}

.el-icon-warning {
  color: red;
}
</style>
