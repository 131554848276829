<template>
  <el-dialog
    :visible.sync="visible"
    title="Edit customer"
    :before-close="notifyParent"
  >
    <default-form
      :form="form"
      :errors="formErrors"
      :loading="loading"
      @submit="editCustomer"
    />
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import customersApi from 'api/v2/customers'
import DefaultForm from 'components/Common/Form/DefaultForm'

export default {
  name: 'DialogEditCustomer',

  components: {
    DefaultForm,
  },

  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      visible: true,
      loading: false,
      formErrors: null,
    }
  },

  computed: {
    ...mapGetters('global', ['roles']),

    formItems() {
      return [
        {
          label: 'Name',
          prop: 'name',
          required: true,
          rules: [],
        },
        {
          label: 'Short Name',
          prop: 'short_name',
          required: true,
          rules: [],
        },
        {
          label: 'Second Name',
          prop: 'second_name',
          required: false,
          rules: [],
        },
        {
          label: 'Address',
          prop: 'address',
          required: false,
          rules: [],
        },
        {
          label: 'Details Line 1',
          prop: 'details_1',
          required: false,
          rules: [],
        },
        {
          label: 'Details line 1 on invoice',
          prop: 'details_1_on_invoice',
          required: false,
          type: 'checkbox',
          rules: [],
        },
        {
          label: 'Details Line 2',
          prop: 'details_2',
          required: false,
          rules: [],
        },
        {
          label: 'Details line 2 on invoice',
          prop: 'details_2_on_invoice',
          required: false,
          type: 'checkbox',
          rules: [],
        },
        {
          label: 'Details Line 3',
          prop: 'details_3',
          required: false,
          rules: [],
        },
        {
          label: 'Details line 3 on invoice',
          prop: 'details_3_on_invoice',
          required: false,
          type: 'checkbox',
          rules: [],
        },
        {
          label: 'Details Line 4',
          prop: 'details_4',
          required: false,
          rules: [],
        },
        {
          label: 'Details line 4 on invoice',
          prop: 'details_4_on_invoice',
          required: false,
          type: 'checkbox',
          rules: [],
        },
      ]
    },

    initialModel() {
      return this.customer
    },

    form() {
      return {
        items: this.formItems,
        labelWidth: '250px',
        buttons: [
          {
            event: 'submit',
            label: 'Save changes',
            type: 'primary',
          },
        ],
        initialModel: this.initialModel,
        ref: 'editCustomerForm',
      }
    },
  },

  methods: {
    notifyParent(done) {
      done()
      this.$parent.$emit('cancelEditItem')
    },

    editCustomer(model) {
      this.loading = true
      customersApi.update(
        () => {
          this.$message.success({
            showClose: true,
            message: 'Customer has been updated.',
            duration: 5000,
          })
          this.visible = false
          this.loading = false
          this.$parent.$emit('changedEditItem')
        },
        (error) => {
          this.formErrors = error
          this.loading = false
          Vue.nextTick(() => {
            this.formErrors = null
          })
        },
        this.customer.id,
        model
      )
    },
  },
}
</script>

<style lang="scss"></style>
