<template>
  <div>
    <el-row v-if="project" :gutter="20">
      <el-col :span="4">
        <choose-subcontractor
          :project="project"
          :subcontractors="subcontractors"
          @change-subcontractor="changeSubcontractor"
        />
      </el-col>
      <el-col :span="20">
        <setup-pricing-template
          v-if="!pricingTemplateId"
          :project="project"
          :subcontractor="subcontractor"
          @template-created="fetchProjectPricingTemplates"
          @template-fetched="fetchProjectPricingTemplates"
        />
        <div v-else>
          <pricing-template
            :project="project"
            :subcontractor="subcontractor"
            :pricing-template-id="pricingTemplateId"
            @deleted="fetchProjectPricingTemplates"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ChooseSubcontractor from './ChooseSubcontractor'
import PricingTemplate from 'components/PricingTemplate'
import SetupPricingTemplate from './SetupPricingTemplate'
import projectSubcontractorsApi from 'api/v2/projectSubcontractors'
import pricingTemplatesApi from 'api/v2/pricingTemplates'
import {logErrorMessage} from '../../../../../common/util';
// import projectPricingCategoriesApi from 'api/v2/projectPricingCategories'

export default {
  name: 'ProjectPricing',

  components: {
    ChooseSubcontractor,
    PricingTemplate,
    SetupPricingTemplate,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      subcontractorId: null,
      subcontractors: [],
      pricingTemplates: null,
    }
  },

  computed: {
    subcontractor() {
      if (!this.subcontractors) {
        // subcontractors not loaded yet
        return undefined
      }
      if (!this.subcontractorId) {
        // in case 'Internal pricing' is selected, we have no subcontractor
        return null
      }
      return this.subcontractors.find(
        (subcontractor) => subcontractor.id === this.subcontractorId
      )
    },
    /**
     * Returns the id of the pricing template for the current selection (either
     * internal pricing or for a specific subcontractor). Returns null, in case
     * no existing pricing template was found
     */
    pricingTemplateId() {
      if (!this.pricingTemplates) {
        // pricingTemplates not loaded yet
        return undefined
      }

      // internal pricing template
      if (
        this.subcontractorId === null ||
        this.subcontractorId === 'internal'
      ) {
        const pricingTemplate = this.pricingTemplates.find(
          (pricingTemplate) => pricingTemplate.subcontractor === null
        )
        return pricingTemplate ? pricingTemplate.id : null
      }

      // subcontractor pricing template
      const pricingTemplate = this.pricingTemplates.find(
        (pricingTemplate) =>
          pricingTemplate.subcontractor &&
          pricingTemplate.subcontractor.id === this.subcontractorId
      )
      return pricingTemplate ? pricingTemplate.id : null
    },
  },

  mounted() {
    this.fetchProjectSubcontractors()
    this.fetchProjectPricingTemplates()
  },

  methods: {
    fetchProjectPricingTemplates() {
      pricingTemplatesApi.index(
        (response) => {
          this.pricingTemplates = response
        },
        (error) => {
          logErrorMessage(error)
        },
        {
          project_id: this.project.id,
          include: ['subcontractor'],
        }
      )
    },
    fetchProjectSubcontractors() {
      projectSubcontractorsApi.getSubcontractors(
        (response) => {
          this.$set(this, 'subcontractors', response)
        },
        (error) => {
          logErrorMessage(error)
        },
        this.project.id,
        { include: ['pricingTemplates'] }
      )
    },
    changeSubcontractor(subcontractorId) {
      if (!subcontractorId || subcontractorId === 'internal') {
        this.subcontractorId = null
      } else {
        this.subcontractorId = subcontractorId
      }
    },
    onTemplateCreated() {
      this.fetchProjectPricingTemplates()
    },
  },
}
</script>
