import ApiResource from '../ApiResource'

class ProjectSalesInvoiceMetaFieldResource extends ApiResource {
  constructor() {
    super({
      name: 'projects.sales-invoice.meta-fields',
    })
  }
}

const projectSalesInvoiceMetaFieldsApi = new ProjectSalesInvoiceMetaFieldResource()

export default projectSalesInvoiceMetaFieldsApi
