<template>
  <div>
    <div class="bids-tabs">
      <el-tabs :value="tab" @tab-click="clickedTab">
        <el-tab-pane label="Open bids" name="open-bids">
          <el-row>
            <BidTable
              v-if="tableVisible === true && currentTab === 'open-bids'"
              tab="open-bids"
              :api="api"
              :api-update="apiUpdate"
              @addnew="makeAddDialogVisible"
            />
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Finalized bids" name="finalized-bids">
          <BidTable
            v-if="tableVisible === true && currentTab === 'finalized-bids'"
            tab="finalized-bids"
            :api="api"
            :api-update="apiUpdate"
          />
        </el-tab-pane>
        <el-tab-pane label="Closed bids" name="closed-bids">
          <BidTable
            v-if="tableVisible === true && currentTab === 'closed-bids'"
            tab="closed-bids"
            :api="api"
            :api-update="apiUpdate"
          />
        </el-tab-pane>
      </el-tabs>
      <BidAddDialog
        v-if="addDialogVisible"
        @cancel="closeDialog"
        @itemAdded="refreshPage"
      />
    </div>
  </div>
</template>

<script>
import BidAddDialog from 'components/Bids/AddDialog'
import BidsApi from 'api/v2/bids'
import BidValuesApi from 'api/v2/bidValues'
import BidTable from 'components/Bids/BidTable'

export default {
  name: 'BidsView',
  components: {
    BidTable,
    BidAddDialog,
  },
  props: {
    tab: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      addDialogVisible: false,
      api: BidsApi,
      apiUpdate: BidValuesApi,
      tableVisible: true,
      currentTab: '',
    }
  },
  mounted() {
    this.currentTab = this.tab
  },
  methods: {
    makeAddDialogVisible() {
      this.addDialogVisible = true
    },
    refreshPage() {
      this.tableVisible = false
      this.$nextTick(() => {
        this.tableVisible = true
      })
    },
    closeDialog() {
      this.addDialogVisible = false
    },
    clickedTab(tabComponent) {
      this.currentTab = tabComponent.name
      this.$router.replace(`/bids/${tabComponent.name}`)
      this.refreshPage()
    },
  }
}
</script>
<style>
.bids-tabs {
  margin-top: -5px;
}
</style>
