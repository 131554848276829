import { generateGetters, generateSetters } from '../../helpers.js'

const state = {
  accessToken: null,
  refreshToken: null,
  tokenExpiration: null,
  refreshWindow: 60000,
  username: null,
}

const getters = {
  ...generateGetters([
    'accessToken',
    'refreshToken',
    'expiration',
    'tokenRefreshWindow',
    'username',
    'refreshWindow',
  ]),
}

const mutations = {
  ...generateSetters(['username']),

  tokens(state, response) {
    state.accessToken = response.accessToken
    state.refreshToken = response.refreshToken
  },

  expiration(state, response) {
    state.tokenExpiration = Date.now() + response.expires_in * 1000
  },

  logout(state) {
    state.accessToken = null
    state.refreshToken = null
    state.tokenExpiration = null
  },

  clear(state) {
    // Similar to logout, but prevent even username from being saved
    state.accessToken = null
    state.refreshToken = null
    state.tokenExpiration = null
    state.username = null
  },
}

const actions = {
  logout({ dispatch, commit }) {
    dispatch('global/reset', null, { root: true })
    commit('logout')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
