<!-- This is not implemented correctly and adding a position is currently disabled -->
<template>
  <div>
    <el-dialog
    :visible.sync="visible"
    title="Add Position"
    :before-close="close"
    class="position-add-dialog"
  >
    <el-form
      ref="form"
      label-width="120px"
      label-position="top"
      :model="item"
      :rules="rules"
    >
      <el-form-item
        v-if="!project"
        ref="project_id"
        label="Project"
        prop="project_id"
      >
        <el-select
          v-model="item.project_id"
          placeholder="Please select a project"
          filterable
          @change="loadAreasAndCategories"
        >
          <el-option
            v-for="(option_item, index) in items"
            :key="index"
            :label="option_item.name"
            :value="option_item.id"
          />
        </el-select>
      </el-form-item>


      <el-row :gutter="5">
        <el-col :span="12">
          <el-form-item  ref="executor" prop="executor">
            <el-radio-group v-model="item.executor">
              <el-radio
                v-for="n in executorOptions"
                :key="n"
                :label="n"
                :value="n"
              />
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="5">
        <template v-if="item.executor === 'Soli Personal'">
          <el-col :span="12">
            <el-form-item ref="team" label="Team" prop="team">
              <el-select
                v-if="teams"
                v-model="item.team"
                filterable
                placeholder="Please select a team"
                prop="executor"
              >
                <el-option
                  v-for="(team, index) in teams"
                  :key="index"
                  :label="team.name"
                  :value="team.name"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </template>
        <template v-if="item.executor === 'Nachunternehmer'">
          <el-col :span="12">
            <el-form-item
              ref="subcontractor_id"
              label="Subcontractor"
              prop="subcontractor_id"
            >
              <el-select
                v-if="subcontractors"
                v-model="item.subcontractor_id"
                filterable="filterable"
                placeholder="Please select a Subcontractor"
              >
                <el-option
                  v-for="(subcontractor, index) in subcontractors"
                  :key="index"
                  :label="subcontractor.name"
                  :value="subcontractor.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </template>
      </el-row>

      <el-row
        v-if="showSendNotification"
        :gutter="5"
      >
        <el-col :span="12">
          <el-alert
            :title="showSendNotification"
            type="error"
            :closable="false"
          >
          </el-alert>

          <p>
            <el-button
              v-if="!sendNotificationResponse"
              plain
              size="small"
              @click="openNotificationDialog"
            >
              Request Subcontractor Pricing
            </el-button>
            <el-alert
              v-if="sendNotificationResponse"
              :title="sendNotificationResponse"
              type="success"
              :closable="false"
            >
            </el-alert>
          </p>
        </el-col>
      </el-row>

      <el-row :gutter="5">
        <el-col :span="12">
          <el-form-item
            ref="pricing_category_id"
            label="Category"
            prop="pricing_category_id"
          >
            <el-select
              v-if="categories"
              v-model="item.pricing_category_id"
              placeholder="Please select a category"
              filterable
            >
              <el-option
                v-for="(category, index) in categories"
                :key="index"
                :label="`${category.name} (${category.areaSelection})`"
                :value="category.id"
              />
            </el-select>

          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            v-if="subcategoryOptions.length"
            ref="subcategory"
            label="Sub Category"
            prop="pricingSubCategory"
          >
            <el-select
              v-model="item.pricing_subcategory_id"
              placeholder="Please select a sub category"
              filterable
            >
              <el-option
                v-for="(category, index) in subcategoryOptions"
                :key="index"
                :label="category.subcategoryName"
                :value="category.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      
      <el-row :gutter="35">
        <el-col :span="8">
          <el-form-item 
            v-if="muffeOptions.length"
            ref="muffe_id"
            label="Muffe"
            prop="muffe_id"
          >
            <el-select
              v-if="muffeOptions.length"
              v-model="item.muffe_id"
              placeholder="Please select a Muffe"
              filterable
            >
              <el-option
                v-for="(muffe, index) in muffeOptions"
                :key="index"
                :label="`${muffe.name}`"
                :value="muffe.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item 
            v-if="mfgOptions.length"
            ref="mfg_id"
            label="Mfg"
            prop="mfg_id"
          >
            <el-select
              v-if="mfgOptions.length"
              v-model="item.mfg_id"
              placeholder="Please select a mfg"
              filterable
            >
              <el-option
                v-for="(mfg, index) in mfgOptions"
                :key="index"
                :label="`${mfg.name}`"
                :value="mfg.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="5">
        <el-col :span="12">
          <el-form-item 
            v-if="streetOptions.length"
            ref="street_id"
            label="Street"
            prop="street_id"
          >
            <el-select
              v-if="streetOptions.length"
              v-model="item.street_id"
              placeholder="Please select a street"
              filterable
            >
              <el-option
                v-for="(street, index) in streetOptions"
                :key="index"
                :label="street.name"
                :value="street.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            v-if="item.street_id"
            ref="street_start"
            label="Street Start"
            prop="street_start"
          >
            <el-input
              v-model="item.street_start"
              placeholder="Street Start"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            v-if="item.street_id"
            ref="street_end"
            label="Street End"
            prop="street_end"
          >
            <el-input
              v-model="item.street_end"
              placeholder="Street End"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="5">
        <el-col :span="12">
          <el-form-item ref="start_date" label="Date from" prop="start_date">
            <el-date-picker
              v-model="item.start_date"
              format="dd.MM.yyyy"
              type="date"
              placeholder="Pick a date"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item ref="end_date" label="Date to" prop="end_date">
            <el-date-picker
              v-model="item.end_date"
              format="dd.MM.yyyy"
              type="date"
              placeholder="Pick a date"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button type="primary" @click="onSubmit"> Create</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>

    <SendDialog
      v-if="showSendNotification"
      ref="createNotification"
      :subject="sendNotificationSubject"
      :message="sendNotificationMessage"
      @close="onNotificationDialogClose"
      @sent="onNotificationDialogSent"
    />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import * as util from '../../common/util'
import projectAreasApi from 'api/v2/projectAreas'
import projectPricingCategoriesApi from 'api/v2/projectPricingCategories'
import positionsApi from 'api/v2/positions'
import teamsApi from 'api/v2/teams'
import subcontractorsApi from 'api/v2/subcontractors'
import pricingSubcategoriesResourceAou from 'api/v2/pricingSubcategories'
import {logErrorMessage} from '../../common/util';
import SendDialog from '@/js/components/Notifications/SendDialog.vue'

export default {
  name: 'PositionAddDialog',
  components: {
    SendDialog,
  },
  props: {
    project: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      visible: true,
      errors: null,
      item: {
        project_id: null,
        start_date: moment().locale('de-DE').startOf('week'),
        end_date: moment().locale('de-DE').endOf('week').subtract(2, 'd'),
        street_start: null,
        street_end: null,
        street_id: null,
        executor: 'Soli Personal',
        team: null,
        subcontractor_id: null,
        pricing_category_id: null,
        pricing_subcategory_id: null,
      },
      areas: [],
      teams: [],
      categories: [],
      sub_categories: [],
      id: null,
      loadingSubcontractors: true,
      subcontractors: [],
      executorOptions: ['Soli Personal', 'Nachunternehmer'],
      showSendNotification: null,
      sendNotificationSubject: '',
      sendNotificationMessage: '',
      sendNotificationResponse: null,
    }
  },
  computed: {
    ...mapGetters('projects', ['items']),

    origin() {
      return this.$store.getters['global/getEnumById']('position_origin')
    },
    streetOptions() {
      if (this.areaSelection !== 'street') return []

      let options = []
      this.areas.forEach((area) => {
        if (area.streets && area.streets.length) {
          options = options.concat(area.streets)
        }
      })
      return options
    },
    muffeOptions() {
      if (this.areaSelection !== 'muffe') return []
      
      let options = []

      this.areas.forEach((area) => {
        if (area.muffen && area.muffen.length) {
          options = options.concat(area.muffen)
        }
      })
      return options
    },
    mfgOptions() {
      if (this.areaSelection !== 'mfg') return []
      
      let options = []
      this.areas.forEach((area) => {
        if (area.mfgs && area.mfgs.length) {
          options = options.concat(area.mfgs)
        }
      })
      return options
    },
    subcategoryOptions() {
      if (!this.item.pricing_category_id) return []
      if (!this.sub_categories) return []
      let selectedCategory = this.categories.find(
        (o) => o.id === this.item.pricing_category_id
      )
      if (!selectedCategory) return []
      let options = this.sub_categories.filter(
        (subcategory) => subcategory.categoryName === selectedCategory.name
      )
      return options
    },
    areaSelection() {
      let selectedCategory = this.categories.find(
        (o) => o.id === this.item.pricing_category_id
      )
  
      return (selectedCategory) ? selectedCategory.areaSelection : null
    }, 
    rules() {
      const self = this

      return {
        start_date: [
          {
            required: true,
            type: 'date',
            message: 'Date from is required',
            transform: (value) => (value ? new Date(value) : undefined),
          },
        ],
        end_date: [
          {
            required: true,
            type: 'date',
            message: 'Date to is required',
            transform: (value) => (value ? new Date(value) : undefined),
          },
        ],
        pricing_category_id: [
          {
            required: true,
            message: 'Category is required',
          },
        ],
        street_id: [
          {
            required: true,
            message: 'Street is required',
          },
        ],
        muffe_id: [
          {
            required: true,
            message: 'Muffe is required',
          },
        ],
        mfg_id: [
          {
            required: true,
            message: 'Mfg is required',
          },
        ],
        street_start: [
          {
            required: true,
            message: 'Street start is required',
          },
        ],
        street_end: [
          {
            required: true,
            message: 'Street end is required',
          },
        ],
        executor: [
          {
            required: true,
            message: 'Select one',
          },
        ],
        team: [
          {
            validator(rule, value, cb) {
              if (self.item.executor === 'Soli Personal' && !value) {
                cb(new Error('Please select the team used'))
              }
              cb()
            },
          },
        ],
        subcontractor_id: [
          {
            validator(rule, value, cb) {
              if (self.item.executor !== 'Soli Personal' && !value) {
                cb(new Error('Please select the subcontractor used'))
              }
              cb()
            },
          },
        ],
      }
    },
  },
  watch: {
    'item.pricing_category_id': function () {
      this.item.pricing_subcategory_id = null
    },
    'item.executor': function () {
      if (this.item.executor === 'Soli Personal') {
        this.item.subcontractor_id = null
      }
      if (this.item.executor === 'Nachunternehmer') {
        this.item.team = null
      }
      this.loadCategories()
    },
    'item.subcontractor_id': function () {
      this.loadCategories()
    },
    errors: function (error) {
      if (error !== null) {
        let errorMessage

        if (error && error.message) {
          errorMessage = error.message
        } else {
          errorMessage = 'A validation error occured'

          // Let's set the errors
          Object.entries(error).forEach(([key, value]) => {
            this.$refs[key].error = value[0]
          })
        }

        this.$message.error({
          showClose: true,
          message: errorMessage,
          duration: 10000,
        })
      }
    },
  },
  beforeCreate() {},
  created() {
    this.loadTeams()
    if (this.project) {
      this.item.project_id = this.project.id
      this.loadAreas()
      this.loadCategories()
      this.loadSubcontractors()
      this.loadSubCategories()
    } else {
      this.rules.project_id = [
        {
          required: true,
        },
      ]
    }

    this.loadCategories()
  },
  mounted() {},
  methods: {
    openNotificationDialog() {
      this.$refs.createNotification.openNotificationDialog()
    },
    onNotificationDialogClose() {
      this.sendNotificationResponse = null
    },
    onNotificationDialogSent() {
      this.sendNotificationResponse = 'Notification Created! You will receive an email once the request has been actioned.'
    },
    close(done) {
      this.$emit('cancel')
      if (done && typeof done === 'function') {
        done()
      }
    },
    loadAreasAndCategories() {
      this.loadAreas()
      this.loadCategories()
      this.loadTeams()
      this.loadSubcontractors()
      this.loadSubCategories()
    },
    loadAreas() {
      if (!this.item.project_id) {
        return
      }
      projectAreasApi.index(
        (response) => {
          this.areas = response
          if (!this.areas.length) {
            const ok = confirm('Please add areas before creating a position.')

            if (ok) {
              this.$router.push({
                path: 'projects/edit/:projectId',
                name: 'edit',
                params: { projectId: this.item.project_id },
              })
            }
          }
        },
        (error) => {
          this.loading = false
          logErrorMessage(error)
        },
        this.item.project_id,
        {
          include: ['streets', 'muffen', 'mfgs'],
        }
      )
    },
    loadCategories() {
      this.showSendNotification = null
      this.sendNotificationSubject = ''
      this.sendNotificationResponse = null
      this.sendNotificationMessage = ''

      if (!this.item.project_id) {
        return
      }
      let additional_data = {}
      if(this.item.subcontractor_id) {
        // Ensure that SubContractor Pricing Categories are loaded
        additional_data.subcontractor_id = this.item.subcontractor_id
      }
      projectPricingCategoriesApi.index(
        (response) => {
          this.categories = response
        },
        (error) => {
          this.loading = false
          this.categories = []
          this.item.pricing_category_id = null

          if(error.message === 'Subcontractor template is missing, contact an administrator.') {
            this.$nextTick(() => {
              const selectedSubcontractor = this.subcontractors.filter((subcontractor) => {return subcontractor.id ===  this.item.subcontractor_id})
              this.showSendNotification = error.message
              this.sendNotificationSubject = `Request for the setup of a Subcontractor Template for: Subcontractor = ${selectedSubcontractor[0]?.name || this.item.subcontractor_id} and Project = ${this.project.name}`
              this.sendNotificationMessage = ''
            });
          }

          logErrorMessage(error)
        },
        this.item.project_id,
        additional_data
      )
    },
    loadSubCategories() {
      pricingSubcategoriesResourceAou.index(
        (response) => {
          this.sub_categories = response
        },
        (error) => {
          this.loading = false
          logErrorMessage(error)
        }
      )
    },
    loadTeams() {
      teamsApi.index(
        (response) => {
          this.teams = response
        },
        (error) => {
          this.loading = false
          logErrorMessage(error)
        }
      )
    },
    loadSubcontractors() {
      if (!this.item.project_id) {
        return
      }
      subcontractorsApi.index(
        (response) => {
          this.subcontractors = response
        },
        (error) => {
          this.loading = false
          logErrorMessage(error)
        }
      )
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let payload = Object.assign({}, this.item)

          payload.start_date = util.formatDateForRequest(this.item.start_date)
          payload.end_date = util.formatDateForRequest(this.item.end_date)

          positionsApi.store(
            (response) => {
              this.$message({
                type: 'success',
                message: 'Position created',
                duration: 4000,
              })
              this.$router.push('/projects/' + response.projectId + '/positions/' + response.id)
              this.close()
            },
            (error) => {
              this.loading = false
              this.errors = error
              logErrorMessage(error)
            },
            payload
          )
        }
      })
    },
  },
}
</script>

<style lang="scss">
.position-add-dialog {
  .el-select {
    width: 100%;
  }
}
</style>
