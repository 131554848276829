import ApiResource from '../ApiResource'

class CustomerResource extends ApiResource {
  constructor() {
    super({
      name: 'customers',
    })
  }
}

const customersApi = new CustomerResource()

export default customersApi
