import ApiResource from '../ApiResource'

class PricingSubcategoryResource extends ApiResource {
  constructor() {
    super({
      name: 'pricing-subcategories',
    })
  }
}

const pricingSubcategoriesResourceAou = new PricingSubcategoryResource()

export default pricingSubcategoriesResourceAou
