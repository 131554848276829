/* eslint-disable */
import projectAreasApi from 'api/v2/projectAreas'
import projectAreaLocationsApi from 'api/v2/projectAreaLocations'
import { AuthorizationError } from 'api/errors'
import { formatErrorMessage } from 'common/util'
import snakeCaseKeys from 'snakecase-keys'

const modelTypes = {
  'street': 'streets',
  'mfg': 'mfgs',
  'muffe': 'muffen',
}

const convertPayloadKeys = (payload) => {
  return snakeCaseKeys(payload, { deep: true })
}

export default {

  reset({ commit }) {
    commit('items', [])
    commit('streets', [])
    commit('mfgs', [])
    commit('projectId', null)
    commit('selectedArea', null)
    commit('loading', false)
    commit('errored', false)
  },

  /**
   * Set the current project ID on the store, used for all API requests
   * @param {*} projectId
   */
  setProjectId({ commit }, projectId) {
    commit('projectId', projectId)
  },

  /**
   * Set the current selected area
   * @param {*} area
   */
  selectArea({ commit }, area) {
    if (!area) {
      commit('selectedArea', null)
      commit('streets', [])
      commit('mfgs', [])
      commit('muffen', [])
      return
    }
    commit('selectedArea', {
      id: area.id,
      name: area.name,
    })
    commit('streets', area.streets)
    commit('mfgs', area.mfgs)
    commit('muffen', area.muffen)
  },

  addArea({ commit, getters }, { payload }) {
    const { projectId } = getters
    if (!projectId) throw new Error('projectId not set')

    return new Promise((resolve, reject) => {
      projectAreasApi.store(
        (response) => {
          const items = getters.items
          items.unshift(response)
          commit('items', items)
          resolve(response)
        },
        error => {
          reject(error)
        },
        projectId,
        convertPayloadKeys(payload),
      )
    })
  },

  updateArea({ commit, getters }, { id, payload }) {
    const { projectId } = getters
    if (!projectId) throw new Error('projectId not set')

    return new Promise((resolve, reject) => {
      projectAreasApi.update(
        (response) => {
          const items = getters.items
          const area = items.find(area => area.id === id)
          Object.assign(area, response)
          commit('items', items)
          resolve(response)
        },
        error => {
          reject(error)
        },
        projectId,
        id,
        convertPayloadKeys(payload),
      )
    })
  },

  deleteArea({ commit, getters }, { id, payload }) {
    const { projectId } = getters
    if (!projectId) throw new Error('projectId not set')
    if (!id) throw new Error('id not set')

    return new Promise((resolve, reject) => {
      projectAreasApi.delete(
        (response) => {

          if (response.deleted) {
            commit('items', getters.items.filter(area => area.id !== id))
          }
          resolve(response)
        },
        error => {
          reject(error)
        },
        projectId,
        id,
        convertPayloadKeys(payload),
      )
    })
  },

  addLocation({ commit, getters }, { type = 'street', payload }) {
    const { projectId, selectedArea } = getters
    if (!projectId) throw new Error('projectId not set')
    if (!selectedArea.id) throw new Error('areaId not set')

    return new Promise((resolve, reject) => {
      projectAreaLocationsApi.store(
        (response) => {
          const locationModelType = modelTypes[type]

          // add to main 'items' selection
          const areas = getters.items
          const area = areas.find(area => area.id === selectedArea.id)
          area[locationModelType].unshift(response)
          commit('items', areas)

          // also update the state of streets / mfgs / muffen
          const items = getters[locationModelType]
          items.unshift(response)
          commit(locationModelType, items)

          resolve(response)
        },
        (error) => {
          reject(error)
        },
        projectId,
        selectedArea.id,
        {
          ...convertPayloadKeys(payload),
          type,
        }
      )
    });
  },

  updateLocation({ commit, getters }, { type = 'street', id, payload }) {
    const { projectId, selectedArea } = getters
    if (!projectId) throw new Error('projectId not set')
    if (!selectedArea.id) throw new Error('areaId not set')

    return new Promise((resolve, reject) => {
      projectAreaLocationsApi.update(
        (response) => {
          const locationModelType = modelTypes[type]

          // add to main 'items' selection
          const areas = getters.items
          const area = areas.find(area => area.id === selectedArea.id)
          const location = area[locationModelType].find(location => location.id === id)
          Object.assign(location, response)
          commit('items', areas)

          // also update the state of streets / mfgs / muffen
          const items = getters[locationModelType]
          Object.assign(items.find(location => location.id === id), response)
          commit(locationModelType, items)

          resolve(response)
        },
        (error) => {
          reject(error)
        },
        projectId,
        selectedArea.id,
        id,
        {
          ...convertPayloadKeys(payload),
          type,
        }
      )
    });
  },

  moveLocation({ commit, getters }, { type = 'street', id, moveToAreaId }) {
    const { projectId, selectedArea } = getters
    if (!projectId) throw new Error('projectId not set')
    if (!selectedArea.id) throw new Error('areaId not set')
    if (!id) throw new Error('locationId not set')
    if (!moveToAreaId) throw new Error('moveToAreaId not set')

    const sourceAreaId = selectedArea.id
    const locationModelType = modelTypes[type]

    return new Promise((resolve, reject) => {
      projectAreaLocationsApi.moveToArea(
        (response) => {
          // move location in state
          const areas = getters.items
          const sourceArea = areas.find(area => area.id === sourceAreaId)

          // remove from old area
          sourceArea[locationModelType] = sourceArea[locationModelType].filter(location => location.id !== id)

          // add to new area
          const targetArea = areas.find(area => area.id === moveToAreaId)
          const locations = targetArea[locationModelType]
          locations.unshift(response.data)

          // commit areas to state
          commit('items', areas)

          // also update the state of streets / mfgs / muffen
          commit(locationModelType, sourceArea[locationModelType])
          resolve(response.data)
        },
        error => {
          reject(error)
        },
        projectId,
        selectedArea.id,
        id,
        {
          areaId: moveToAreaId,
          type: type.toLowerCase(),
        }
      )
    })
  },

  deleteLocation({ commit, getters }, { type = 'street', id, payload }) {
    const { projectId, selectedArea } = getters
    if (!projectId) throw new Error('projectId not set')
    if (!selectedArea.id) throw new Error('areaId not set')
    if (!id) throw new Error('locationId not set')

    return new Promise((resolve, reject) => {
      projectAreaLocationsApi.delete(
        (response) => {
          const locationModelType = modelTypes[type]

          if (response.deleted) {
            // remove from main 'items' selection
            const areas = getters.items
            const area = areas.find(area => area.id === selectedArea.id)
            area[locationModelType] = area[locationModelType].filter(location => location.id !== id)
            commit('items', areas)

            // also update the state of streets / mfgs / muffen
            const items = getters[locationModelType]
            commit(locationModelType, items.filter(location => location.id !== id))
          }

          resolve(response)
        },
        (error) => {
          reject(error)
        },
        projectId,
        selectedArea.id,
        id,
        {
          ...convertPayloadKeys(payload),
          type,
        }
      )
    })
  },

  fetchAll({ commit, getters }, payload = {}) {
    const { forceReload, projectId } = payload

    commit('loading', true)

    return new Promise((resolve, reject) => {
      projectAreasApi.index(
        (response) => {
          commit('errored', false)
          commit('items', response)
          commit('loading', false)
          resolve(response)
        },
        (error) => {
          commit('errored', true)

          if (error instanceof AuthorizationError) {
            return
          }

          if (error) {
            reject(error)
            this.$message.error({
              message: formatErrorMessage(error),
            })
          }
        },
        projectId,
        { include: ['muffen', 'streets', 'mfgs'] }
      )
    })
  },

}

