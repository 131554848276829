<template>
  <el-dialog :before-close="handleClose" :title="title" :visible="visible">
    <div v-loading="loading" class="body">
      <template v-if="updateSuccess">
        <el-row class="row-bg" justify="space-around" type="flex">
          <el-col :span="6" style="margin-top: 40px">
            <el-progress
              :percentage="updateProgress"
              status="success"
              type="circle"
            ></el-progress>
          </el-col>
        </el-row>
      </template>

      <template v-else>
        <template v-if="editItem && editItem.parentTemplateId">
          <el-row>
            <span>
              <strong class="danger">WARNING: </strong>a pricing template has
              already been set for this project:
            </span>
            <br/><br/>
            <strong>{{ editItem.parentTemplateName }}</strong>
            <br/><br/>
            <span>
              Setting a new master pricing template, will completely remove the
              current template and all it's
              <strong>{{ editItem.templateItemsCount }} items</strong> and
              categories.
            </span>
          </el-row>
        </template>

        <template v-else-if="editItem.templateItemsCount">
          <el-row>
            <span>
              <strong class="danger">WARNING: </strong>pricing has already been
              set up for this project
            </span>
            <br/><br/>
            <span>
              Setting a new master pricing template, will completely remove the
              current template and all it's
              <strong>{{ editItem.templateItemsCount }} items</strong> and
              categories.
            </span>
          </el-row>
        </template>
        <el-row style="margin-top: 30px">
          <el-select
            v-model="newParentPricingTemplate"
            clearable
            filterable
            placeholder="Select master pricing template"
            style="width: 100%"
          >
            <el-option
              v-for="item in templateOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-row>
      </template
      ><!-- v-if="!updateSuccess" -->
    </div>

    <span v-show="!updateSuccess" slot="footer" class="dialog-footer">
      <el-button :disabled="!!loading" @click="handleClose">Cancel</el-button>
      <el-button
        :disabled="!!loading || !newParentPricingTemplate"
        type="primary"
        @click="updateParentTemplate"
      >
        Confirm
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import pricingSyncApi from 'api/v2/pricingSync'
import {logErrorMessage} from '../../../../common/util';

export default {
  name: 'SelectTemplateModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    templateOptions: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
    editItem: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      loading: false,
      updateProgress: 0,
      updateSuccess: false,
      newParentPricingTemplate: null,
    }
  },
  computed: {
    title() {
      if (this.editItem && this.editItem.parentTemplateId) {
        return `Change master pricing template for ${this.editItem.projectName}`
      }
      return `Set master pricing template for ${this.editItem.projectName}`
    },
  },
  watch: {
    visible(value) {
      if (value) {
        this.init()
      }
    },
  },
  mounted() {
  },
  methods: {
    init() {
      this.newParentPricingTemplate = null
      this.updateSuccess = false
      this.updateProgress = 0
    },
    handleClose(done) {
      this.$emit('update:visible', false)
      if (typeof done === 'function') {
        done()
      }
    },
    async updateParentTemplate() {
      this.$confirm('Are you sure you want to go through with this?')
        .then(() => {
          this.loading = true
          pricingSyncApi.setParentPricingTemplate(
            (response) => {
              this.loading = false
              this.updateSuccess = true
              this.$nextTick(() => {
                this.updateProgress = 100
              })
              this.$emit('updated', response)
              setTimeout(() => {
                this.handleClose()
              }, 1000)
            },
            (error) => {
              logErrorMessage(error)
            },
            {
              projectId: this.editItem.projectId,
              pricingTemplateId: this.editItem.templateId,
              parentPricingTemplateId: this.newParentPricingTemplate,
            }
          )
        })
        .catch(() => {
        })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__headerbtn {
  position: absolute;
  right: 20px;
  top: 20px;
}

.danger {
  color: $danger;
}

.body {
  min-height: 200px;
  line-height: 1.5;
}
</style>
