<template>
  <el-pagination
    :background="true"
    :current-page.sync="localCurrentPage"
    :page-size="localPerPage"
    :pager-count="5"
    :small="true"
    :total="localTotal"
    :page-sizes="localPageSizes"
    class="pagination"
    layout="sizes, total, prev, pager, next"
    @current-change="pageChange"
    @size-change="changePageSize"
  />
</template>

<script>
export default {
  name: 'DefaultPagination',

  props: {
    currentPage: {
      type: Number,
      default: null,
      required: false,
    },
    perPage: {
      type: Number,
      default: null,
      required: false,
    },
    total: {
      type: Number,
      default: null,
      required: false,
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30, 40, 50, 100],
      required: false,
    },
  },
  computed: {
    localCurrentPage: {
      get: function() {
        return this.currentPage
      },
      set: function() {}
    },
    localPerPage() {
      return this.perPage
    },
    localTotal() {
      return this.total
    },
    localPageSizes() {
      return this.pageSizes
    },
  },
  methods: {
    pageChange(val) {
      this.$emit('pageChange', val)
    },
    changePageSize(newPageSize) {
      this.$emit('changePageSize', newPageSize)
    },
  },
}
</script>

<style scoped></style>
