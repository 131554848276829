import ApiResource from '../ApiResource'

class ApprovalItems extends ApiResource {
  constructor() {
    super({
      name: 'projects.approvals.items',
    })
  }
}

const approvalItemsApi = new ApprovalItems()

export default approvalItemsApi
