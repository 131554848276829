import ApiResource from '../ApiResource'

class ContactUserResource extends ApiResource {
  constructor() {
    super({
      name: 'contacts.user',
    })
  }
}

const contactUsersApi = new ContactUserResource()

export default contactUsersApi
