<template>
  <div class="comp-projects-tab">
    <el-row :gutter="20">
      <el-col :span="7">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <el-row>
              <el-col :span="12">
                <span style="line-height: 28px">Available projects</span>
              </el-col>
              <el-col :span="12">
                <el-input
                  v-model="projectFilter"
                  placeholder="search"
                  style="width: 100%; line-height: 28px"
                  suffix-icon="el-icon-search"
                />
              </el-col>
            </el-row>
          </div>
          <div class="text item-container scrollable">
            <el-row
              v-for="project in filteredProjects"
              :key="project.id"
              class="item selectable"
            >
              <div class="item" @click.prevent="toggleProject(project)">
                <el-col :span="20">
                  {{ project.name }}
                </el-col>

                <el-col :span="4" align="right">
                  <el-switch
                    :value="
                      !!projects.find(
                        (linkedProject) => linkedProject.id === project.id
                      )
                    "
                    active-text=""
                    inactive-text=""
                    title="Enable / disable project access"
                  />
                </el-col>
              </div>
            </el-row>
          </div>
        </el-card>
      </el-col>
      <el-col :span="7">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span style="line-height: 28px">Accessible projects</span>
          </div>
          <template v-if="projects.length">
            <div class="text item-container scrollable">
              <el-row
                v-for="project in projects"
                :key="project.id"
                class="item selectable"
              >
                <div class="item" @click="selectedLinkedProjectId = project.id">
                  <el-col :span="20">
                    <el-radio
                      v-model="selectedLinkedProjectId"
                      :label="project.id"
                      style="margin-right: 8px"
                    />
                    {{ project.name }}
                  </el-col>

                  <el-col :span="4" align="right">
                    <el-button
                      icon="fa fa-unlink"
                      size="mini"
                      title="Disable project access"
                      type="secondary"
                      @click.prevent.stop="unlinkProject(project)"
                    />
                  </el-col>
                </div>
              </el-row>
            </div>
          </template>
          <div v-else class="item">
            <small>not linked to any project yet...</small>
          </div>
        </el-card>
      </el-col>
      <el-col :span="10">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <el-row>
              <el-col
                :span="15"
                style="overflow-x: hidden; white-space: nowrap"
              >
                <span style="line-height: 28px; text-overflow: ellipsis">
                  User access
                  <template v-if="selectedLinkedProjectId">
                    for <strong>{{ selectedLinkedProject.name }}</strong>
                  </template>
                </span>
              </el-col>
              <el-col :span="9" align="right">
                <el-input
                  v-model="contactFilter"
                  placeholder="search"
                  style="line-height: 28px; width: calc(100% - 86px)"
                  suffix-icon="el-icon-search"
                />
                <el-button
                  icon="fa fa-plus"
                  size="mini"
                  title="Add contact"
                  type="success"
                  @click.prevent.stop="
                    editContact = undefined
                    showEditContactForm = true
                  "
                >
                  Add user
                </el-button>
              </el-col>
            </el-row>
          </div>
            <div class="text item-container scrollable">
              <div v-if="contacts.length">
                <el-row
                  v-for="contact in filteredContacts"
                  :key="contact.id"
                  :class="{ disabled: !contact.user }"
                  class="item selectable"
                >
                  <div class="item" @click.prevent="toggleContact(contact)">
                    <el-col :span="9">
                      <el-switch
                        :disabled="!selectedLinkedProjectId || !contact.user"
                        :value="
                          !!projectContacts.find(
                            (linkedContact) =>
                              linkedContact.id === contact.id && contact.user
                          )
                        "
                        active-text=""
                        inactive-text=""
                        style="margin-right: 10px"
                      />
                      {{ contact.firstname }} {{ contact.lastname }}
                    </el-col>
                    <el-col :span="6">
                      <small>{{ contact['function'] }}</small>
                    </el-col>

                    <el-col :class="getContactStatusClass(contact)" :span="5">
                      <template v-if="getContactStatus(contact)">
                        <small>{{ getContactStatus(contact) }}</small>
                      </template>
                      <template v-else>
                        <small>{{ contact.user.role }}</small>
                      </template>
                    </el-col>

                    <el-col :span="4" align="right">
                      <el-button
                        icon="el-icon el-icon-edit"
                        size="mini"
                        title="Edit contact"
                        type="primary"
                        @click.prevent.stop="
                          editContact = contact
                          showEditContactForm = true
                        "
                      />
                      <el-button
                        :disabled="!contact.user"
                        class="error"
                        icon="el-icon el-icon-delete"
                        size="mini"
                        title="Delete user account"
                        type="secondary"
                        @click.prevent.stop="deleteContactUser(contact)"
                      />
                    </el-col>
                  </div>
                </el-row>
              </div>
              <div
                v-else-if="selectedLinkedProjectId && !contacts.length"
                class="item"
              >
                <div class="item" style="text-align: center">
                  <small>there are no contacts on this subcontractor</small>
                </div>
              </div>
            </div>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog
      v-if="showEditContactForm"
      :title="
        editContact
          ? `Edit contact: ${editContact.firstname} ${editContact.lastname}`
          : 'Create contact'
      "
      :visible.sync="showEditContactForm"
      @closes="showEditContactForm = false"
    >
      <edit-contact-form
        :edit-contact="editContact"
        :subcontractor="subcontractor"
        @update="fetchContacts"
        @contact-created="editContact = $data"
      />
    </el-dialog>
  </div>
</template>

<script>
import contactsApi from 'api/v2/contacts'
import projectsApi from 'api/v2/projects'
import subcontractorProjectsApi from 'api/v2/subcontractorProjects'
import subcontractorContactsApi from 'api/v2/subcontractorContacts'
import subcontractorProjectContactsApi from 'api/v2/subcontractorProjectContacts'

import EditContactForm from './EditContactForm'

export default {
  name: 'ProjectsTab',
  components: {
    EditContactForm,
  },
  props: {
    subcontractor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      projects: [],
      selectedLinkedProjectId: null,
      allProjects: [],
      contacts: [],
      projectContacts: [],
      projectFilter: '',
      contactFilter: '',
      showEditContactForm: false,
      editContact: null,
    }
  },
  computed: {
    subcontractorId() {
      return this.$route.params.subcontractorId
    },
    selectedLinkedProject() {
      if (!this.selectedLinkedProjectId) return null
      return this.projects.find(
        (project) => project.id === this.selectedLinkedProjectId
      )
    },
    filteredProjects() {
      if (!this.projectFilter) return this.allProjects
      return this.allProjects.filter(
        (project) =>
          project.name
            .toLowerCase()
            .indexOf(this.projectFilter.toLowerCase()) !== -1
      )
    },
    filteredContacts() {
      if (!this.contactFilter) return this.contacts
      return this.contacts.filter(
        (contact) =>
          contact.firstname
            .toLowerCase()
            .indexOf(this.contactFilter.toLowerCase()) !== -1 ||
          contact.lastname
            .toLowerCase()
            .indexOf(this.contactFilter.toLowerCase()) !== -1
      )
    },
  },
  watch: {
    selectedLinkedProjectId(value, oldValue) {
      if (!value) {
        this.projectContacts = []
        return
      }
      if (value === oldValue) return
      this.fetchProjectContacts()
    },
  },
  mounted() {
    this.fetchSubContractorProjects()
    this.fetchAllProjects()
    this.fetchContacts()
  },
  methods: {
    fetchSubContractorProjects() {
      this.loading = true
      subcontractorProjectsApi.index(
        (response) => {
          this.projects = response
          this.$nextTick(() => {
            this.sortProjects()
            this.loading = false
          })
        },
        (error) => this.showApiError(error),
        this.subcontractorId
      )
    },
    fetchAllProjects() {
      projectsApi.index(
        (response) => {
          this.$set(this, 'allProjects', response)
        },
        (error) => this.showApiError(error)
      )
    },
    fetchContacts() {
      subcontractorContactsApi.index(
        (response) => {
          this.contacts = response
        },
        (error) => this.showApiError(error),
        this.subcontractorId,
        {
          include: ['user'],
        }
      )
    },
    fetchProjectContacts() {
      subcontractorProjectContactsApi.index(
        (response) => {
          this.projectContacts = response
        },
        (error) => this.showApiError(error),
        this.subcontractorId,
        this.selectedLinkedProjectId
      )
    },
    async toggleProject(project) {
      const isLinked = this.projects.find(
        (linkedProject) => linkedProject.id === project.id
      )
      if (isLinked) {
        if (this.projectContacts.length > 0) {
          await this.$confirm(
            `One or more users currently have access to this project, are you sure you want to remove project ${project.name} from ${this.subcontractor.name}, also removing access for ${this.contacts.length} users?`,
            'Warning',
            {
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
              type: 'warning',
            }
          )
        }
        this.unlinkProject(project)
      } else {
        this.linkProject(project)
      }
    },
    linkProject(project) {
      if (
        this.projects.find((linkedProject) => linkedProject.id === project.id)
      ) {
        return
      }
      subcontractorProjectsApi.linkProject(
        () => {
          this.projects.push(project)
          this.sortProjects()
          this.selectedLinkedProjectId = project.id
        },
        (error) => this.showApiError(error),
        this.subcontractorId,
        project.id
      )
    },
    unlinkProject(project) {
      subcontractorProjectsApi.unlinkProject(
        () => {
          this.projects = this.projects.filter(
            (linkedProject) => linkedProject.id !== project.id
          )
          if (this.selectedLinkedProjectId === project.id) {
            this.selectedLinkedProjectId = null
          }
        },
        (error) => this.showApiError(error),
        this.subcontractorId,
        project.id
      )
    },
    toggleContact(contact) {
      const isLinked = this.projectContacts.find(
        (linkedContact) => linkedContact.id === contact.id
      )
      if (isLinked) {
        this.unlinkContact(contact)
      } else {
        this.linkContact(contact)
      }
    },
    linkContact(contact) {
      if (!this.subcontractorId) return
      if (!this.selectedLinkedProjectId) return
      if (
        this.projectContacts.find(
          (linkedContact) => linkedContact.id === contact.id
        )
      ) {
        return
      }
      subcontractorProjectContactsApi.linkProjectContact(
        () => {
          this.projectContacts.push(contact)
        },
        (error) => this.showApiError(error),
        this.subcontractorId,
        this.selectedLinkedProjectId,
        contact.id
      )
    },
    unlinkContact(contact) {
      if (!this.subcontractorId) return
      if (!this.selectedLinkedProjectId) return
      subcontractorProjectContactsApi.unlinkProjectContact(
        () => {
          this.projectContacts = this.projectContacts.filter(
            (linkedContact) => linkedContact.id !== contact.id
          )
        },
        (error) => this.showApiError(error),
        this.subcontractorId,
        this.selectedLinkedProjectId,
        contact.id
      )
    },
    async deleteContactUser(contact) {
      try {
        await this.$confirm(
          'This will delete the user account for this contact. Continue?',
          'Warning',
          {
            confirmButtonText: 'Yes, delete',
            cancelButtonText: 'No, I want my mommy',
            type: 'warning',
          }
        )
        contactsApi.deleteUser(
          () => {
            this.$message({
              type: 'success',
              message: 'User deleted',
            })
            const existingContact = this.contacts.find(
              (subcontractorContact) => subcontractorContact.id === contact.id
            )
            this.$set(existingContact, 'user', undefined)
            this.$nextTick(() => {
              this.$set(this, 'contacts', this.contacts)
            })
          },
          (error) => this.showApiError(error),
          contact.id,
          contact.user.id
        )
      } catch (e) {
        if (e === 'cancel') {
          this.$message({
            type: 'info',
            message: 'Delete canceled',
          })
        } else {
          this.$message({
            type: 'error',
            message: e.message,
          })
        }
      }
    },
    getContactStatus(contact) {
      if (!contact.user) {
        return 'no user account'
      }
    },
    getContactStatusClass(contact) {
      if (this.getContactStatus(contact)) {
        return 'error'
      }
      return ''
    },
    sortProjects() {
      this.projects.sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        return 0
      })
    },
    showApiError(error) {
      this.$message.error({
        showClose: true,
        message: error.message || error,
        duration: 5000,
      })
    },
  },
}
</script>

<style lang="scss">
@import '~scss/mixins';

.comp-projects-tab {
  @include tiny-vertical-scroll;

  .scrollable {
    overflow-y: auto;
    height: calc(100vh - 254px);
    padding-right: 10px;
    margin-right: -1px;
  }

  .error {
    color: red;
  }

  .el-radio__label {
    display: none;
  }

  .item-container {
    margin: -20px -20px;
    padding: 10px 0;
  }

  .item {
    font-size: 14px;
    padding: 0 5px;

    > div > div {
      padding: 5px 10px;
      line-height: 22px;
    }

    &.selectable:not(.disabled):hover {
      cursor: pointer;
      background: rgba(15, 126, 190, 0.2);
    }

    &.selectable.disabled:hover {
      cursor: not-allowed;
    }
  }
}
</style>
