import moment from 'moment'

export const getSyncStatus = (item) => {
  if (!item) return {}
  if (!item.parentPricingTemplate) {
    return {}
  }
  if (!item.syncedAt) {
    return { type: 'warning', text: 'Never synced' }
  }

  const templateSyncedAt = moment(item.syncedAt)
  // todo: should be item.parentPricingTemplate.UpdatedAt
  const parentUpdatedAt = moment(item.parentPricingTemplate.updated_at)
  const templateUpdatedAt = moment(item.updatedAt)

  if (templateSyncedAt.diff(parentUpdatedAt) < 0) {
    return { type: 'danger', text: 'Outdated' }
  }
  if (
    templateUpdatedAt.diff(parentUpdatedAt) > 0 &&
    templateUpdatedAt.diff(templateSyncedAt) > 0
  ) {
    return { type: 'primary', text: 'Local changes' }
  }
  if (templateSyncedAt.diff(parentUpdatedAt) >= 0) {
    return { type: 'success', text: 'Up-to-date' }
  }

  return { type: 'gray', text: 'unknown' }
}
