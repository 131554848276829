<template>
  <el-form
    class="price-input"
    :class="{
      'price-input--subcontractor':
        subcontractor,
    }"
  >
    <i class="el-icon-plus"/>

    <el-form-item
      style="flex-grow: 1"
      :rules="{ type: 'integer', message: 'Please select a number' }"
    >
      <el-input ref="reference" v-model="reference" placeholder="Reference"/>
    </el-form-item>

    <el-form-item
      v-if="subcontractor"
      style="flex-grow: 1"
      :rules="{ type: 'integer', message: 'Please select a number' }"
    >
      <el-input
        ref="reference"
        v-model="internal_pricing_item_reference"
        placeholder="Soli Ref."
        :disabled="(pricingTemplate.projectId || !canAddReference) ? true : false"
      />
    </el-form-item>

    <el-form-item
      style="flex-grow: 10"
      :rules="{ required: true, message: 'description is required' }"
    >
      <el-input v-model="description" placeholder="Description"/>
    </el-form-item>
    <el-form-item
      style="flex-grow: 1"
      :rules="{
        required: true,
        message: 'unit is required',
        trigger: 'blur',
      }"
    >
      <el-select v-model="unit" placeholder="Unit" filterable>
        <el-option label="M" value="M"/>
        <el-option label="M2" value="M2"/>
        <el-option label="M3" value="M3"/>
        <el-option label="ST" value="ST"/>
        <el-option label="STD" value="STD"/>
      </el-select>
    </el-form-item>

    <el-form-item style="flex-grow: 1" :rules="{ type: 'integer' }">
      <el-input v-model="price" placeholder="Price" type="number"/>
    </el-form-item>
    <el-form-item style="flex-grow: 1">
      <el-checkbox v-model="local_fixed">fixed</el-checkbox>
    </el-form-item>
    <el-form-item style="flex-grow: 1">
      <el-checkbox v-model="hidden">hidden</el-checkbox>
    </el-form-item>
    <el-form-item
      v-if="subcontractor && pricingTemplate.projectId"
      style="flex-grow: 1"
    >
      <el-checkbox v-model="not_purchase_invoicable">not inv.</el-checkbox>
    </el-form-item>
    <el-form-item style="flex-grow: 1">
      <el-input v-model="plan_index" placeholder="Planindex" type="text"/>
    </el-form-item>
    <el-form-item v-if="!subcontractor && !pricingTemplate.projectId" style="flex-grow: 1">
      <el-input v-model="plan_index_subcategory" placeholder="PlanindexSubCategory" type="text"/>
    </el-form-item>

    <el-form-item style="flex-grow: 1">
      <el-input v-model="bonus_index" placeholder="Bonusindex" type="text"/>
    </el-form-item>

    <el-form-item style="flex-grow: 1">
      <el-input
        v-model="prognose_mtb_art_index"
        placeholder="Artindex_Prognose_mTB"
        type="text"
      />
    </el-form-item>
    <el-form-item style="flex-grow: 1">
      <span class="actions">
        <el-button size="mini" type="success" icon="el-icon-check"  @click="addPricingItem">Add</el-button>
      </span>
    </el-form-item>
  </el-form>
</template>

<script>
import pricingTemplateItemsApi from 'api/v2/pricingTemplateItems'
import {can, formatErrorMessage} from 'common/util'
import camelCaseKeys from 'camelcase-keys'

export default {
  name: 'AddPricingItem',

  props: {
    areaSelection: {
      type: String,
      required: true,
    },
    pricingTemplate: {
      type: Object,
      required: true,
    },
    pricingCategory: {
      type: Object,
      required: true,
    },
    subcontractor: {
      type: Object,
      required: false,
      default() {
        return undefined
      },
    },
  },

  data() {
    return {
      reference: null,
      internal_pricing_item_reference: null,
      description: '',
      unit: '',
      price: null,
      plan_index: null,
      plan_index_subcategory: null,
      bonus_index: null,
      prognose_mtb_art_index: null,
      local_fixed: null,
      hidden: null,
      not_purchase_invoicable: null,
    }
  },

  computed: {
    internalPricingItems() {
      return this.pricingTemplate.internalPricingTemplate.pricing_items.filter(
        (item) => item.pricing_category.area_selection === this.areaSelection
      )
    },
    canAddReference() {
      return can('set_internal_ref', 'pricing_template');
    },
  },

  watch: {
    internal_pricing_item_reference(newValue, oldValue) {
      if (newValue) {
        const pricingItem =
          this.pricingTemplate.internalPricingTemplate.pricing_items.find(
            (item) => item.reference === newValue
          )
        const oldPricingItem = oldValue
          ? this.pricingTemplate.internalPricingTemplate.pricing_items.find(
            (item) => item.reference === oldValue
          )
          : null

        // If these don't match, you cannot save the thing
        this.unit = pricingItem.unit

        // This allows the user to double check if it is the correct id
        if (
          !this.description ||
          (oldPricingItem && oldPricingItem.description === this.description)
        ) {
          this.description = pricingItem.description
        }
      }
    },
  },

  methods: {
    addPricingItem() {
      const newPricingItem = {
        pricing_category_id: this.pricingCategory.id,
        factor: 1,
        reference: this.reference,
        internal_pricing_item_reference: this.internal_pricing_item_reference,
        description: this.description,
        unit: this.unit,
        price: Number(this.price),
        plan_index: this.plan_index,
        plan_index_subcategory: this.plan_index_subcategory,
        bonus_index: this.bonus_index,
        prognose_mtb_art_index: this.prognose_mtb_art_index,
        local_fixed: this.local_fixed,
        hidden: this.hidden,
        not_purchase_invoicable: this.not_purchase_invoicable,
      }
      pricingTemplateItemsApi.store(
        (response) => {
          this.$emit('item-added', camelCaseKeys(response, {deep: true}))
          this.pricingItemAddedSuccessfully()
        },
        (error) => {
          this.$message({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 5000,
          })
        },
        this.pricingTemplate.id,
        newPricingItem
      )
    },
    pricingItemAddedSuccessfully() {
      this.$refs.reference.$el.children[0].focus()
      this.reference = null
      this.internal_pricing_item_reference = null
      this.description = ''
      this.unit = ''
      this.price = null
      this.plan_index = null
      this.plan_index_subcategory = null
      this.bonus_index = null
      this.prognose_mtb_art_index = null
      this.local_fixed = null
      this.hidden = null
      this.not_purchase_invoicable = null
    },
  },
}
</script>

<style lang="scss" scoped>
.actions {
  text-align: right;
  display: block;
  padding-right: 5px;
}

::v-deep .el-input {
  padding-right: 0px;
}

::v-deep .el-select {
  padding-right: 0px;

  .el-input {
    padding-right: 0;

    i {
      margin-right: 0px;
    }
  }
}

::v-deep input {
  margin-left: 0;
}

::v-deep .el-input {
  width: calc(100% - 10px);
}

.el-button {
  padding: 0px;
  height: 24px;
  width: 60px;
}

.el-form {
  height: 30px;
}

::v-deep .el-form-item {
  margin-bottom: 0px!important;
}

::v-deep .el-icon-plus {
  padding: 0 0 0 15px;
}


::v-deep .el-input {
    .el-input__inner {
    height: 24px;
  }
}

::v-deep .el-collapse-item__content {
  padding: 0px;
}

::v-deep .el-form-item__content {
  line-height: 1.7;
}
</style>
