<template>
  <div :class="{ menu_visible: loggedIn }" class="root-view">
    <CoreTopbar v-if="loggedIn" :logged-in="loggedIn" class="core-topbar"/>
    <CoreMenu v-if="loggedIn" class="core-menu"/>
    <div class="core-body">
      <router-view v-if="loadedInitial || !loggedIn" name="body"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import CoreMenu from '../../Core/Menu'
import CoreTopbar from '../../Core/Topbar'

export default {
  name: 'RootView',

  components: {
    CoreMenu,
    CoreTopbar,
  },

  props: {
    loggedIn: Boolean,
    loadedInitial: Boolean,
  },

  computed: {
    ...mapGetters('global', ['titleColor', 'titleTag', 'customTitle']),

    title() {
      if (this.customTitle) {
        return this.customTitle
      }

      if (this.$route.meta.title instanceof Function) {
        return this.$route.meta.title(this.$store, this.$route.params)
      }

      if (this.$route && this.$route.meta && this.$route.meta.title) {
        return this.$route.meta.title
      }

      return ''
    },
  },
}
</script>

<style lang="scss">
.core-menu {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: $sidebar-width;
  z-index: z($z_indices, menu);
}

.core-topbar {
  height: $topbar-height;
  top: 0;
  position: sticky;
  z-index: z($z_indices, top-bar);
}

.core-body {
  background-color: $extra-light-grey;
  display: flex;
  flex-direction: column;
  z-index: z($z_indices, body);
}

.menu_visible {
  .core-body {
    margin-left: $sidebar-width;
    padding: $body-padding;
    min-height: calc(100vh - #{$topbar-height});
    // min-height: calc(100vh);
    overflow: auto;
  }
}
</style>
