<template>
  <div>
    <el-col :span="8">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span style="line-height: 28px">Subcontractor details</span>
        </div>
        <div class="text">
          <default-form
            v-if="subcontractor && subcontractor.id"
            :form="form"
            :loading="loading"
            :errors="formErrors"
            @submit="saveSubcontractor"
          />
        </div>
      </el-card>
    </el-col>

    <el-col :span="24">
      <template v-if="revisions && revisions.length">
        <revision-table
          card-title="Subcontractor revisions"
          :revisions="revisions"
          type="subcontractor"
        />
      </template>
    </el-col>
  </div>
</template>

<script>
import DefaultForm from 'components/Common/Form/DefaultForm'
import RevisionTable from '../../../Common/RevisionTable'

import subcontractorsApi from 'api/v2/subcontractors'
import snakeCaseKeys from 'snakecase-keys'

export default {
  name: 'OverviewTab',
  components: {
    DefaultForm,
    RevisionTable,
  },
  props: {
    subcontractor: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      formErrors: null,
    }
  },
  computed: {
    subcontractorId() {
      return this.$route.params.subcontractorId
    },
    revisions() {
      if (!this.subcontractor) {
        return
      }

      return Array.from(this.subcontractor.revisionHistory || []).reverse()
    },
    form() {
      return {
        initialModel: Object.assign({}, this.subcontractor),
        items: [
          {
            label: 'Name',
            prop: 'name',
            required: true,
            rules: [],
          },
          {
            label: 'Phone',
            prop: 'phone',
            required: false,
            rules: [],
          },
          {
            label: 'Email',
            prop: 'email',
            required: false,
            rules: [],
          },
          {
            label: 'Address',
            prop: 'address',
            required: false,
            rules: [],
          },
          {
            label: 'Postcode',
            prop: 'postcode',
            required: false,
            rules: [],
          },
          {
            label: 'City',
            prop: 'city',
            required: false,
            rules: [],
          },
          {
            label: 'Country',
            prop: 'country',
            required: false,
            rules: [],
          },
          {
            label: 'Creditor number',
            prop: 'creditorNumber',
            required: false,
            rules: [],
          },
        ],
        buttons: [
          {
            event: 'submit',
            label: 'Save',
            type: 'primary',
          },
        ],
        ref: 'addSubcontractorForm',
      }
    },
  },
  methods: {
    saveSubcontractor(subcontractor) {
      this.loading = true
      subcontractorsApi.update(
        (response) => {
          this.$emit('save', response)
          this.loading = false
          this.$message.success({
            message: 'Subcontractor updated',
          })
        },
        (error) => {
          this.loading = false
          this.$message.error({
            showClose: true,
            message: error,
            duration: 10000,
          })
        },
        this.subcontractorId,
        snakeCaseKeys(subcontractor)
      )
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .el-form-item {
  margin-bottom: 14px;
}
::v-deep .el-form-item__label {
  padding: 0 10px 0 0;
  line-height: 28px;
}
::v-deep .el-form-item__content {
  line-height: 24px;
}
::v-deep .el-input input {
  height: 28px;
}
</style>
