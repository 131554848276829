<template>
  <el-dialog
    class="claim-edit-item-dialog"
    :close-on-click-modal="false"
    :visible.sync="visible"
    title="Add Claim item"
    :before-close="onClose"
  >
    <ClaimItemForm
      :claim="claim"
      :claim-item="newItem"
      :loading="loading"
      :on-submit="onSubmit"
      @cancel="onClose"
    />
  </el-dialog>
</template>

<script>
import claimItemsApi from 'api/v2/claimItems'

import ClaimItemForm from './ItemForm'

import { formatErrorMessage } from '../../common/util'

export default {
  name: 'ClaimItemAddDialog',
  components: { ClaimItemForm },
  props: {
    claim: {
      type: Object,
      required: true,
    },
    claimItem: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      visible: true,
      newItem: {
        description: null,
        comment: null,
        width: 0,
        height: 0,
        length: 0,
        amount: 0,
        pricing_item_id: null,
        price: null,
        factor: 1,
        unit: 'M',
        section: null,
        custom_category: null,
      },
    }
  },

  methods: {
    onClose(done) {
      this.$emit('cancel')
      if (done && typeof done === 'function') {
        this.$nextTick(() => {
          done()
        })
      }
    },
    onSubmit(item) {
      this.loading = true

      claimItemsApi.store(
        (response) => {
          this.loading = false
          this.$emit('addItem', response)
          this.onClose()
        },
        (error) => {
          this.loading = false
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.claim.id,
        item
      )
    },
  },
}
</script>
