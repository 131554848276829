<template>
  <div>
    <el-tabs
      v-if="position"
      ref="tabs"
      v-model="activeName"
      @tab-click="tabChange"
    >
      <el-tab-pane v-loading="loadingOverview" label="Overview" name="overview">
        <PositionOverview
          v-if="position && activeName === 'overview'"
          :position="position"
          @update="update"
        />
      </el-tab-pane>
      <el-tab-pane label="Items" name="items">
        <PositionItems
          v-if="position && activeName === 'items'"
          ref="items"
          :item="position"
        />
      </el-tab-pane>
      <el-tab-pane label="Images" name="images">
        <PositionImages
          v-if="position && activeName === 'images'"
          ref="images"
          :position="position"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import positionsApi from 'api/v2/positions'
import PositionOverview from './Overview'
import PositionItems from './Items'
import PositionImages from './Images.vue'

import {formatErrorMessage} from 'common/util'

export default {
  name: 'PositionSingle',

  components: {
    PositionOverview,
    PositionItems,
    PositionImages,
  },

  data() {
    return {
      activeName: 'overview',
      position: null,
      loadingOverview: true,
    }
  },

  watch: {
    $route(val) {
      this.activeName = val.params.tab
    },
  },

  mounted() {
    this.loadPosition()

    if (this.$route.params.tab) {
      this.activeName = this.$route.params.tab
    }
  },

  methods: {
    reloadPositions() {
      if (!this.$refs.items) {
        return
      }
      this.$refs.items.loadPage()
    },
    tabChange(e) {
      this.$router.replace(
        '/projects/' +
        this.position.projectId +
        '/positions/' +
        this.position.id +
        '/' +
        e.name,
        () => {
          this.$route.params.tab = e.name
        },
        () => {
          this.$refs.tabs.setCurrentName(this.$route.params.tab)
          this.activeName = this.$route.params.tab
        }
      )

      if (e.label === 'Items') {
        if (!this.$refs.items) {
          return
        }
        this.$refs.items.loadPage(1)
      }
      if (e.label === 'Overview') {
        this.position = null
        this.loadPosition()
      }
    },
    update() {
      // this.position = item
      this.loadPosition()
    },
    loadPosition() {
      this.loadingOverview = true

      positionsApi.show(
        (response) => {
          this.position = response
          this.$store.commit('projects/currentPosition', response)
          this.loadingOverview = false
        },
        (error) => {
          this.loadingOverview = false
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.$route.params.positionId,
        {include: ['project', 'location', 'subcontractor']}
      )
    },
  },
}
</script>
