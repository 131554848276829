<template>
  <el-dialog
    class="position-edit-item-dialog"
    :visible.sync="visible"
    title="Edit Position item"
    :before-close="close"
    :close-on-click-modal="false"
  >
    <el-form v-if="positionItem" label-width="120px" label-position="top">
      <el-form-item ref="comments" label="Comments">
        <el-input
          v-model="editItem.comments"
          type="textarea"
          :required="true"
        />
      </el-form-item>

      <el-form-item
        v-if="pricingItemOptions.length"
        ref="position"
        label="Pricing item"
      >
        <el-select
          v-model="editItem.pricingItem"
          placeholder="Please select..."
          filterable
          return-object
          class="position-select"
          @change="itemChanged"
        >
          <el-option
            v-for="option in pricingItemOptions"
            :key="option.id"
            :label="option.description"
            :value="option.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="Measurements" class="measurements">
        <el-row :gutter="20">
          <el-col :span="5">
            <el-input
              v-if="shouldShow('length')"
              v-model="editItem.length"
              :required="true"
            >
              <template slot="prepend">Length</template>
              <template slot="append">M</template>
            </el-input>
          </el-col>
          <el-col :span="5">
            <el-input
              v-if="shouldShow('width')"
              v-model="editItem.width"
              :required="true"
            >
              <template slot="prepend">Width</template>
              <template slot="append">M</template>
            </el-input>
          </el-col>
          <el-col :span="5">
            <el-input
              v-if="shouldShow('height')"
              v-model="editItem.height"
              :required="true"
            >
              <template slot="prepend">Height</template>
              <template slot="append">M</template>
            </el-input>
          </el-col>
          <el-col :span="5">
            <el-input
              v-if="shouldShow('amount')"
              v-model="editItem.amount"
              :required="true"
            >
              <template slot="prepend">{{
                editItem.pricingItem && editItem.unit === 'STD'
                  ? 'Hours'
                  : 'Amount'
              }}</template>
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item>
        <el-button :loading="loading" type="primary" @click="onSubmit">
          Update
        </el-button>
        <el-button type="warning" @click="close"> Cancel </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'
import { cloneDeep } from 'lodash-es'
import positionItemApi from 'api/v2/positionItems'
import pricingTemplateApi from '../../api/v2/pricingTemplates'
import * as positionUtil from 'common/positionUtil'
import { formatErrorMessage } from 'common/util'

export default {
  name: 'PositionItemEditDialog',
  props: {
    position: {
      type: Object,
      default: null,
    },
    positionItem: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      visible: true,
      pricingCategories: [],
      pricingItems: [],
      unit: null,
      editItem: {
        pricingItem: null,
        unit: null,
      },
      items: [],
    }
  },
  computed: {
    getMass() {
      return positionUtil.getMass(this.editItem)
    },
    getGross() {
      return positionUtil.getGross(this.editItem, true)
    },
    getNet() {
      return positionUtil.getNet(this.editItem, true)
    },
    pricingItemOptions() {
      if (!this.pricingCategories || !this.pricingItems) {
        return []
      }
      let options = []
      this.pricingCategories.forEach((pricingCategory) => {
        if (pricingCategory.id !== this.position.pricingCategoryId) {
          return
        }
        options = this.pricingItems.filter(
          (pricingItem) =>
            pricingItem.pricing_category_id === pricingCategory.id
        )
      })
      return options
    },
  },
  watch: {
    unit: function () {
      switch (this.editItem.unit) {
      case 'M':
      case 'LE':
        this.editItem.width = null
        this.editItem.height = null
        this.editItem.amount = null
        break
      case 'M2':
        this.editItem.height = null
        this.editItem.amount = null
        break
      case 'M3':
        this.editItem.amount = null
        break
      case 'ST':
      case 'STD':
        this.editItem.width = null
        this.editItem.length = null
        this.editItem.height = null
        break
      default:
      }
    },
  },
  mounted() {
    this.editItem = cloneDeep(camelCaseKeys(this.positionItem, { deep: true }))
    this.getCategories()
  },
  methods: {
    close(done) {
      this.$emit('cancel')
      if (done && typeof done === 'function') {
        this.$nextTick(() => {
          done()
        })
      }
    },
    shouldShow(name) {
      if (!this.editItem.pricingItem) {
        return false
      }
      let allow = null
      this.updateItemAmounts(this.editItem.pricingItem)
      if (this.editItem.unit === 'M' || this.editItem.unit === 'LE') {
        allow = ['length']
      } else if (this.editItem.unit === 'M2') {
        allow = ['length', 'width']
      } else if (this.editItem.unit === 'M3') {
        allow = ['length', 'width', 'height']
      } else if (this.editItem.unit === 'ST' || this.editItem.unit === 'STD') {
        allow = ['amount']
      } else {
        allow = []
      }
      let result = allow.indexOf(name)
      if (result > -1) {
        return true
      }
      return false
    },
    onSubmit() {
      this.editItem.item_id = this.editItem.itemId

      this.loading = true
      positionItemApi.update(
        (response) => {
          this.loading = false
          this.$emit('updateItem', response)
          this.close()
        },
        (error) => {
          this.loading = false
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.position.id,
        this.editItem.id,
        snakeCaseKeys(this.editItem)
      )
    },
    getCategories() {
      pricingTemplateApi.index(
        (response) => {
          const pricingTemplate = response[0]
          this.pricingCategories = pricingTemplate.categories
          this.pricingItems = pricingTemplate.items
          this.updateItemAmounts(this.editItem.pricingItemId)
        },
        () => {
          // Do nothing
        },
        {
          project_id: this.position.project.id,
          subcontractor_id: null,
          ofNoSubcontractor: true,
          include: ['categories', 'items'],
        }
      )
    },
    itemChanged(selectedItem) {
      this.updateItemAmounts(selectedItem)
    },
    updateItemAmounts(selectedPricingItem) {
      if (!selectedPricingItem) return
      if (this.pricingItems.length <= 0) return

      const pricingItem = this.pricingItems.find((item) => {
        return item.id === selectedPricingItem
      })

      this.editItem.pricing_item_id = pricingItem.id
      this.editItem.price = pricingItem.price
      this.editItem.pricingItem = pricingItem.id
      this.editItem.unit = pricingItem.unit
      this.unit = pricingItem.unit
    },
  },
}
</script>
<style lang="scss">
.position-edit-item-dialog {
  .position-select {
    display: block;
  }
}
</style>
