<template>
  <div>
    <p>
      <strong>Position Item: </strong><br />
      <span>{{ positionItem.id }} | {{ positionItem.description }}v </span>
    </p>

    <PricingItemPartial
      v-if="positionItem.pricing_item"
      :pricing-item="positionItem.pricing_item"
    />
    <span v-else>No Pricing Item</span>

  </div>
</template>

<script>

import PricingItemPartial from './PricingItem.vue';

export default {
  name: 'PositionItemPartial',
  components: {
    PricingItemPartial
  },
  props: {
    positionItem: {
      type: Object,
      default: null,
      required: true
    },
  },
}
</script>

<style scoped></style>
