<template>
  <el-card v-if="showMetaFields" class="box-card">
    <div slot="header" class="clearfix">
      <span>{{ title }}</span>
    </div>

    <projectMetaFields
      :area-id="areaId"
      :can-edit="canUpdate"
      :category="category"
      :on-success="onSuccess"
      :project="project"
      :project-fields="projectFieldsOfDepartment"
      :invoice="invoice"
    />
  </el-card>
</template>

<script>
import {can} from 'common/util'
import {startCase} from 'lodash'

import ProjectMetaFields from './ProjectMetaFields'

export default {
  name: 'EditProjectMetaFields',

  components: {ProjectMetaFields},

  props: {
    category: {
      type: String,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    invoice: {
      type: Object,
      required: false,
      default: () => {}
    },
    projectFields: {
      type: Array,
      required: true,
    },
    onSuccess: {
      type: Function,
      required: true,
    },
    areaId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      isEditing: false,
    }
  },

  computed: {
    title() {
      return startCase(this.category)
    },
    canUpdate() {
      return can('update', 'project') || can('view', 'project')
    },
    projectFieldsOfDepartment() {
      return this.projectFields.filter((pf) => pf.category === this.category)
    },
    showMetaFields() {
      return (
        this.projectFieldsOfDepartment &&
        this.projectFieldsOfDepartment.length &&
        this.projectFieldsOfDepartment.filter((pf) => pf.canEdit || pf.value)
          .length > 0
      )
    },
  },
}
</script>
