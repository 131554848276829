<template>
  <div class="position-images">

    <input ref="fileInput" type="file" multiple @input="uploadDocument"/>
    <el-button v-if="allowEdit" icon="plus" type="primary" @click="selectDocument">
      Upload Images/Files
    </el-button>

    <template v-if="images.length">
      <h2>Images</h2>
      <div class="image-wrapper">
        <div
          v-for="image in images"
          :key="image"
          :title="image"
          class="image-thumbnail"
          @click="openImageModal(image)"
        >
          <div :style="{'background-image': `url('${image}')`}"></div>
          <el-button
            v-if="isSuperAdmin && allowEdit"
            class="el-icon el-icon-delete"
            type="danger"
            size="mini"
            @click.stop="deleteDocument(image)"
          ></el-button>
        </div>
      </div>
    </template>


    <template v-if="files.length">
      <h2>Files</h2>
      <div class="image-wrapper">
        <div
          v-for="file in files"
          :key="file"
          :title="file"
          class="file-thumbnail"
          @click="openDocument(file)"
        >
          <div>
            <i class="el-icon el-icon-document"></i>
            <span>.{{file.split('.').pop()}}</span>
          </div>
          <el-button
            v-if="isSuperAdmin && allowEdit"
            class="el-icon el-icon-delete"
            type="danger"
            size="mini"
            @click.stop="deleteDocument(file)"
          ></el-button>
        </div>
      </div>
    </template>

    <imageModal
      v-if="showImageModal"
      :picture="imageModalUrl"
      @close="hideImageModal"
    />
  </div>
</template>

<script>

import positionFileApi from '@/js/api/v2/positionFiles';
import imageModal from '@/js/components/Items/ImageModal.vue';

export default {
  name: 'PositionImages',
  components: {
    imageModal
  },

  props: {
    position: {
      type: Object,
      default: () => {}
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      images: [],
      files: [],
      showImageModal: false,
      imageModalUrl: '',
    }
  },

  computed: {
    isSuperAdmin() {
      return this.$store.getters['global/user']['role'] === 'super_admin'
    }
  },
  mounted() {
    this.loadImages()
  },
  methods: {
    loadImages() {
      positionFileApi.index(
        (response) => {
          this.images = response.images
          this.files = response.files
        },
        () => {

        },
        this.position.id
      );
    },
    openDocument(url) {
      window.open(url, '_blank');
    },
    openImageModal(url) {
      this.showImageModal = true
      this.imageModalUrl = url
    },
    hideImageModal() {
      this.showImageModal = false
      this.imageModalUrl = ''
    },

    selectDocument() {
      this.$refs.fileInput.click()
    },

    uploadDocument(event) {
      let files = []
      for (const file of event.target.files) {
        files.push(file)
      }
      positionFileApi.upload(
        () => {
          this.loadImages()
        },
        () => {},
        this.position.id,
        files
      )
    },
    deleteDocument(url) {
      if(!this.isSuperAdmin) {
        return;
      }
      if(!confirm('Are you sure?')) {
        return;
      }
      positionFileApi.delete(
        () => {this.loadImages()},
        () => {},
        this.position.id,
        {
          url: url
        },
      );
    }
  },
}
</script>

<style lang="scss">
.position-images {
  input {
    display: none;
  }
  .picture-modal {
    z-index: 10000000;

    .picture {

    }
  }
  .image-wrapper:before,
  .image-wrapper:after {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height:0;
    overflow: hidden;
  }
  .image-thumbnail,
  .file-thumbnail {
    width:150px;
    height:150px;
    position: relative;
    float: left;
    box-sizing: border-box;
    border:2px solid transparent;
    overflow: hidden;

    .el-icon-delete {
      position: absolute;
      top:-50px;
      right:-50px;
      border-bottom-left-radius: 8px!important;
      border-top-right-radius: 8px!important;
    }
  }
  .file-thumbnail {
    width:100px;
    height:100px;
  }

  .image-thumbnail > div,
  .file-thumbnail > div {
    border-radius: 8px;
    position: absolute;
    top:5px;
    left:5px;
    right:5px;
    bottom:5px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    background-color: white;

    .el-icon-document {
      height:auto;
      width:auto;
      font-size: 50px;
      display: inline-block;
      margin-top:15px;
    }
    span {
      display: block;
      font-weight: bold;
      font-size: 12px;
    }

  }
  .image-thumbnail:hover,
  .file-thumbnail:hover {
    cursor: pointer;
    > div {
      opacity:0.75;
    }
    .el-icon-delete {
      top:0px;
      right:0px;
    }
  }

}
</style>
