<template>
  <div v-loading="loading" class="data-table-alternate-ver-2">
    <el-row v-if="(tableOptions.showRefresh || !tableOptions.allowActions || cachedVersion.cacheTime || cachedVersion.notCached)" class="page-actions">
      <slot name="pageActions">
        <el-col>
          <el-button
            v-if="tableOptions.showRefresh"
            size="small"
            type="primary"
            @click.prevent="refresh()"
          >
            Refresh
          </el-button>
          <el-button
            v-if="hasFiltersApplied() && !tableOptions.allowActions"
            size="small"
            type="primary"
            @click.prevent="clearFilters()"
          >
            Clear Filters
          </el-button>

          <span v-if="cachedVersion.cacheTime" class="is-cached-version">
            Cached: <i>{{cachedVersion.cacheTime}}</i>
              <el-button
                size="small"
                type="default"
                icon="el-icon el-icon-refresh"
                title="Clear cache and refresh"
                @click.prevent="loadPage(null, true)"
              />
          </span>
          <span v-else class="is-cached-version">Cached: Latest Un-Cached Data</span>
        </el-col>
      </slot>
    </el-row>
    <div ref="main" :class="containerCssClass">
      <el-row class="native-table-outer-row">
        <el-col class="native-table-outer">
          <el-table
            v-if="renderTable"
            ref="ElTable"
            :data="tableData"
            :height="tableHeight"
            :highlight-current-row="true"
            :row-class-name="rowClassName"
            size="mini"
            stripe
            sortable="custom"
            empty-text="There are no data to display for this resource or filter combination."
            @cell-click="cellClick"
            @sort-change="changeSort"
            @selection-change="handleSelectionChange"
            @select-all="handleSelectAll"
          >
            <el-table-column
              v-if="tableOptions.allowSelection"
              :fixed="true"
              type="selection"
              width="40"
            >
              <!-- eslint-disable vue/no-unused-vars -->
              <template slot-scope="scope">
                <slot
                  :row="scope.row"
                  name="selection"
                >
                  <el-checkbox
                    v-if="allowRowSelection(scope.row)"
                    :value="isChecked(scope.row)" @change="toggleRowSelection(scope.row)"
                  />
                </slot>
              </template>
            </el-table-column>
            <el-table-column
              v-if="tableOptions.allowActions"
              :fixed="true"
              :width="getColumnWidth('_rowActions')"
            >
              <template v-if="hasFiltersApplied()" slot="header">
                <div class="filters-clear">
                  <el-button size="mini" type="primary" @click="clearFilters">
                    Clear
                  </el-button>
                </div>
              </template>
              <!-- eslint-disable vue/no-unused-vars -->
              <template slot-scope="scope">
                <slot
                  :row="scope.row"
                  name="actions"
                ></slot>
                <el-button
                  v-for="(actionData, actionName) in rowActions"
                  :key="actionName"
                  :icon="actionData.icon ? actionData.icon : ''"
                  :type="actionData.type ? actionData.type : ''"
                  size="mini"
                  @click.native.stop.prevent="rowAction(actionName, scope.row)"
                >
              <span v-if="actionData.label">
                {{ actionData.label }}
              </span>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              v-for="(column, indexNumber) in columnsToRender"
              :key="`${column}_${indexNumber}`"
              :class-name="getColumnClass(column)"
              :column-key="column"
              :fixed="isFixedLeft(column, indexNumber)"
              :label="column"
              :prop="column"
              :show-overflow-tooltip="true"
              :sortable="getColumnSortable(column)"
              :width="getColumnWidth(column)"
            >
              <!-- eslint-disable vue/no-unused-vars -->
              <template slot="header" slot-scope="scope">
                <div
                  :class="loading ? 'loading' : ''"
                  class="header-search"
                  @click.stop="() => {}"
                >
                  <div
                    v-if="filterElementCurrentCol !== column && getColumnFilterType(column) !== 'disabled'"
                    class="search-value"
                    @click.stop="headerClick($event, column)"
                    @mouseover.once.stop="headerClick($event, column)"
                  >
                    {{getFilterLabel(column)}}
                  </div>
                </div>
                {{ visibleTableColumnsNames[column] ?? column }}
              </template>
              <template slot-scope="scope">
                <slot
                  :column="column"
                  :row="scope.row"
                >
                  {{ getColumnValue(column, scope.row)}}
                  <span class="editable-icon">
                    <i class="el-icon-edit"></i>
                  </span>
                </slot>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row class="pagination-row">
        <el-col>
          <el-button class="setting" type="primary" @click="dialogConfigOpen"><i class="el-icon-setting"></i></el-button>
          <slot name="settingsRow">

          </slot>
          <Pagination
            :current-page="currentPage"
            :page-sizes="tableOptions.pageSizes"
            :per-page="perPageDefault"
            :total="total"
            @changePageSize="changePageSize"
            @pageChange="pageChange"
          />
        </el-col>
      </el-row>


      <el-row
        v-if="tableOptions.showRevisions && loadedRevisions && loadedRevisions.length"
        class="revision-card-wrapper"
      >
        <el-row class="toggle">
          Revisions

          <el-button
            class="toggle-button"
            type="primary"
            @click="showHideRevisions"
          >
            <template v-if="revisionsVisible === true">
              Hide Revisions
            </template>
            <template v-if="revisionsVisible === false">
              Show Revisions
            </template>
          </el-button>
        </el-row>
        <div v-if="tableOptions.showRevisions && loadedRevisions && loadedRevisions.length">
          <revision-table
            :height="revisionTableHeight"
            :revisions="loadedRevisions"
            card-title="Revisions"
            type="general"
          >
          </revision-table>
        </div>
      </el-row>
    </div>
    <el-dialog
      :visible.sync="dialogConfigVisible"
      title="Configuration"
      top="0"
      @close="onDialogConfigClose"
    >
      <el-row class="top-row" type="flex">
        <el-col :span="4">
          <h4>Categories</h4>
          <div>
            <el-checkbox-group v-model="filteredCategories">
              <el-checkbox
                v-for="(element, index) in loadedConfiguration.categories"
                :key="element"
                :label="element"
              >
                {{ categoryLabels[element] ?? element }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </el-col>
        <el-col :offset="1" :span="8">
          <h4>Available Fields</h4>
          <el-button class="refresh-fields" size="mini" type="primary" @click="loadConfig()">Refresh</el-button>
          <span class="add-all" @click="addAllFieldsToVisible">Add All</span>
          <draggable
            v-if="showLists"
            :list="availableTableColumnsList"
            class="list-group"
            group="visibleFields"
          >
            <div
              v-for="(element, index) in availableTableColumnsList"
              :key="`available_${element.name}_${index}`"
              :title="element.key"
              class="list-group-item"
              @dblclick.prevent="addFieldToVisible(element)"
            >
              {{ element.name }}
              <i
                v-if="
                searchKeywords[element.id] &&
                searchKeywords[element.id].length
              "
                class="el-icon-warning has-filter"
              >
              <span>
                filter(s): {{ Array.isArray(searchKeywords[element.id]) ? searchKeywords[element.id].join(', ') : searchKeywords[element.id] }}
              </span>
              </i>
              <span :class="'cat-' + element.category">
              {{ categoryLabels[element.category] ?? element.category }}
            </span>
            </div>
          </draggable>
        </el-col>
        <el-col :offset="1" :span="8">
          <h4>Selected Fields</h4>
          <span class="remove-all" @click="removeAllFieldsFromVisible">Remove All</span>
          <draggable
            v-if="showLists"
            :list="loadedConfiguration.visibleColumns"
            class="list-group"
            group="visibleFields"
          >
            <div
              v-for="(element, index) in loadedConfiguration.visibleColumns"
              :key="`selected_${element.name}_${index}`"
              class="list-group-item"
              @dblclick.prevent="removeFieldFromVisible(element)"
            >
              {{ element.name }}
              <i
                v-if="
                searchKeywords[element.id] &&
                searchKeywords[element.id].length
              "
                class="el-icon-warning has-filter"
              >
              <span>
                filter(s): {{ Array.isArray(searchKeywords[element.id]) ? searchKeywords[element.id].join(', ') : searchKeywords[element.id] }}
              </span>
              </i>
              <span :class="'cat-' + element.category">
              {{ categoryLabels[element.category] ?? element.category }}
            </span>
            </div>
          </draggable>
        </el-col>
      </el-row>
      <el-row class="bottom-row" type="flex">
        <el-col :span="22" justify="right">
          <el-button
            class="setting"
            type="primary"
            @click="dialogConfigVisible = false"
          >
            Close
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <div id="filterElementContainer">
      <div id="filterTextElement">
      <el-input
        v-model="filterTextElementModel"
        clearable
        placeholder="Filter"
        @click.stop.prevent="() => {}"
        @change="searchFilterChange"
      />
    </div>
      <div id="filterSelectElement">
      <el-select
        v-if="filterSelectElementRendered"
        ref="filterSelectElementSelect"
        v-model="filterSelectElementModel"
        clearable
        filterable
        multiple
        popper-class="header-search-with-groups"
        @change="searchFilterChange"
      >
        <el-option-group>
          <el-option
            v-for="(value, option_index) in filterSelectElementOptionsFiltered"
            :key="'f'+option_index"
            :label="value"
            :value="value"
          />
        </el-option-group>
        <el-option-group
          class="other-filters"
        >
          <el-option
            v-for="(value, option_index) in filterSelectElementOptionsOther"
            :key="'o'+option_index"
            :class="filters[filterElementCurrentCol] && filters[filterElementCurrentCol].includes(value) ? 'hidden' : ''"
            :label="value"
            :value="value"
          />
        </el-option-group>
      </el-select>
    </div>
    </div>
    <div id="cellEditElementContainer">
      <div id="cellEditElement">
        <el-input
          v-if="editInputType === 'text'"
          v-model="cellEditModel"
          class="cell-input"
          size="small"
          type="text"
          @blur="updateField($event)"
          @keyup.enter.native="handleEnter($event)"
        />
        <el-input
          v-if="editInputType === 'number'"
          v-model="cellEditModel"
          :step="editNumberInputStep"
          class="cell-input"
          size="small"
          type="number"
          @blur="updateField($event)"
          @keyup.enter.native="handleEnter($event)"
        />
        <el-date-picker
          v-if="editInputType === 'date'"
          v-model="cellEditModel"
          class="cell-input"
          format="dd.MM.yyyy"
          placeholder="Pick a date"
          size="small"
          style="width: 100%"
          type="date"
          @change="handleEnter($event)"
        />
        <el-select
          v-if="editInputType === 'dropdown'"
          v-model="cellEditModel"
          class="cell-input"
          @change="updateField($event)"
        >
          <el-option
            v-for="item in cellEditOptions"
            :key="item.value"
            :label="item.label"
            :selected="cellEditModel == item.value"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
  </div>
</template>
<script>
import {formatErrorMessage, logErrorMessage} from '@/js/common/util';
import Pagination from '@/js/components/Common/DefaultPagination.vue';
import draggable from 'vuedraggable'
import * as util from '@/js/common/util';
import RevisionTable from '@/js/components/Common/RevisionTable.vue';
import moment from 'moment';

export default {
  name: 'DataTableAlternateVer2',
  components: {
    RevisionTable,
    draggable,
    Pagination,
  },
  props: {
    // The api used by this table is passed in from the parent
    api: {
      type: Object,
      required: true,
    },
    indexMethod: {
      type: String,
      required: false,
      default: () => {
        return 'index'
      },
    },
    tableName: {
      type: String,
      required: true,
    },
    // Pass data through from tha parent component, if any given.
    // This is useful if there are data to always pass to the controller that is separate to the
    // filter/sorting function applied here
    requestData: {
      type: Object,
      required: false,
      default: () => {
      },
    },
    requestIds: {
      type: Array,
      required: false,
      default: () => [],
    },
    // Table options passed from the parent
    dataTableOptions: {
      type: Object,
      required: false,
      default: () => {
      },
    },
    filterTypes: {
      type: Object,
      required: false,
      default: () => {
      },
    },
    rowActions: {
      type: Object,
      required: false,
      default: () => {
      },
    },
    filterPreset: {
      type: Object,
      default: () => {
      },
    },
    fieldCategoryPreset: {
      type: Array,
      default: () => [],
    },
    fieldCategoryLabels: {
      type: Object,
      default: () => {
      },
    },
    fieldCategoryDefaults: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      enableLogOverlay: false,
      windowHeight: window.innerHeight,
      tableHeight: window.innerHeight,
      revisionTableHeight: 300,
      // Defaults for table options
      tableOptionDefaults: {
        allowSelection: false,
        allowActions: true,
        showRefresh: false,
        showRevisions: false,
        tableHeightOffset: 150,
        pageSizes: [15, 30, 50, 100],
      },
      tableOptions: {},
      // defaults for column display options
      fixedTableColumns: [],
      visibleTableColumns: [],
      visibleTableColumnsNames: {},
      visibleTableColumnsKeys: {},
      columnsToRender: [],
      // Loading placeholder
      loading: true,
      renderTable: false,
      checkedItems: [],
      // Pagination
      currentPage: 1,
      total: null,
      order: null,
      orderBy: null,
      perPageDefault: 30,
      perPage: 30,
      // Fix the first n columns
      maxFixedColumns: 4,
      // Table data, filters and selection
      tableData: [],
      filters: {},
      cachedVersion: {},
      searchKeywords: [],
      filterFocussed: null,
      // Column selection
      dialogConfigVisible: false,
      filteredCategories: ['general'],
      loadedConfiguration: {
        visibleColumns: null,
        categories: ['general'],
        possibleColumns: []
      },
      availableTableColumnsList: [],
      categoryLabels: {
        general: 'General',
      },
      loadedRevisions: [],
      revisionsVisible: false,
      showLists: true,
      filterTextElementModel: null,
      filterElementCurrentCol: null,
      filterSelectElementRendered: true,
      filterSelectElementModel: null,
      filterSelectElementOptionsFiltered: [],
      filterSelectElementOptionsOther: [],
      cellEditModel: null,
      cellEditOptions: [],
      editInputType: null,
      editNumberInputStep: '1',
      cellEditCurrentCell: null,
      cellEditCurrentCol: null,
      cellEditCurrentRow: null,
    }
  },
  computed: {
    containerCssClass() {
      let classes = []
      classes.push(this.revisionsVisible ? 'show-revisions' : 'hide-revisions')
      classes.push(this.tab)

      if(this.order) {
        classes.push('is-sorting')
        classes.push(`is-sorting-order-${this.order}`)
        classes.push(`is-sorting-col-${this.orderBy}`)
      }
      return classes.join(' ')
    },
  },
  watch: {
    filteredCategories() {
      this.filterAvailableTableColumnsList()
    },
    loading(newValue) {
      if (!newValue) {
        this.$nextTick(() => {
          this.leftTableOffset()
        })
      }
    },
  },
  async mounted() {
    this.setTableOptions()
    this.setPerPage()
    this.setTableHeight()
    this.setRevisionTableHeight()
    await this.loadConfig()
    this.loadPage(1)
  },
  methods: {
    async awaitTest() {
      const data = null;
      return new Promise(function(resolve) {
        window.setTimeout(function() {
          resolve(data);
        }, 5000);
      });
    },
    updateField() {
      let data = {
        field: this.cellEditCurrentCol,
        value: this.cellEditModel,
        row: this.cellEditCurrentRow,
        type: this.editInputType,
      }
      this.$emit('update-field', data);

      const editField = document.getElementById('cellEditElement')
      document.getElementById('cellEditElementContainer').append(editField)

      this.cellEditModel = null
      this.cellEditCurrentCell = null
      this.cellEditCurrentCol = null
      this.cellEditCurrentRow = null
      this.editInputType = null
    },
    handleEnter($event) {
      this.updateField($event)
    },
    headerClick($event, column) {
      this.headerUnClick()

      // set the columns that is need to get a filter attached
      this.filterElementCurrentCol = column

      // Get the column that need the search
      const headerSearch = $event.target.closest('.header-search')

      if(this.getColumnFilterType(column) === 'text') {
        // Set the current value of the filter
        this.filterTextElementModel = this.searchKeywords[column]

        // Append the search to header Search
        const searchField = document.getElementById('filterTextElement')
        headerSearch.appendChild(searchField)
      }
      if(this.getColumnFilterType(column) === 'select') {
        // Set the current value of the filter
        this.filterSelectElementModel = this.searchKeywords[column]

        // Temporarily hide the select element, this is toi ensure proper rendering after kement is chnaged
        this.filterSelectElementRendered = false

        // Set the possible select options
        this.filterSelectElementOptionsFiltered  = this.getColumnFilters(column, 'F')
        this.filterSelectElementOptionsOther  = this.getColumnFilters(column, 'O')

        // Append the search to header Search
        const searchField = document.getElementById('filterSelectElement')
        headerSearch.appendChild(searchField)

        // redraw the select
        this.$nextTick(() => {
          this.filterSelectElementRendered = true
        })
      }
    },
    headerUnClick() {
      const searchTextField = document.getElementById('filterTextElement')
      document.getElementById('filterElementContainer').append(searchTextField)

      const searchSelectField = document.getElementById('filterSelectElement')
      document.getElementById('filterElementContainer').append(searchSelectField)

      this.filterElementCurrentCol = null
      this.filterTextElementModel = null
      this.filterSelectElementModel = null
      this.filterSelectElementOptionsFiltered = []
      this.filterSelectElementOptionsOther = []
    },
    setTableOptions() {
      this.tableOptions = {...this.tableOptionDefaults, ...this.dataTableOptions}
    },
    setPerPage() {
      if(this.tableOptions.pageSizes.includes(this.perPageDefault)) {
        this.perPage = this.perPageDefault
        return
      }
      this.perPage = this.tableOptions.pageSizes[0]
    },
    setTableHeight() {
      // When the revisions are visible the table need to be small
      let height = this.windowHeight
      if (this.revisionsVisible) {
        height = 500
      }
      height -= this.tableOptions.tableHeightOffset
      if (this.tableOptions.showRevisions && this.loadedRevisions && this.loadedRevisions.length) {
        height -= 50
      }
      this.tableHeight = `${height}`
    },
    setRevisionTableHeight() {
      // The revisions table must be small enough to fit without the page getting a scrollbar
      this.revisionTableHeight = `${this.windowHeight - 500}`
    },
    setColumnsToRender() {
      if (this.visibleTableColumns === undefined) {
        this.columnsToRender = []
      }
      this.columnsToRender = this.fixedTableColumns.concat(this.visibleTableColumns);
    },
    refresh(reloadConfig = false) {
      if (reloadConfig) {
        this.tableData = []
        this.loadConfig()
      } else {
        this.loadPage()
      }
    },
    handleSelectionChange(val) {
      this.checkedItems = val
      this.$emit('selection-change', val)
    },
    handleSelectAll(val) {
      const self = this
      val = val.filter((row) => {
        return self.allowRowSelection(row)
      })
      this.$emit('select-all', val)
    },
    isChecked(row) {
      for (const i in this.checkedItems) {
        if (this.checkedItems[i] == row) {
          return true
        }
      }
      return false
    },
    allowRowSelection(row) {
      if (typeof this.$parent.allowRowSelection === 'function') {
        return this.$parent.allowRowSelection(row)
      }
      return true
    },
    toggleRowSelection(row) {
      this.$refs.ElTable.toggleRowSelection(row)
    },
    changeSort({prop, order}) {
      const newOrder = !order ? null : ((order === 'ascending') ? 'asc' : 'desc')
      if(this.orderBy === prop && this.order === newOrder) {
        this.order = null
        this.orderBy = null
        this.pageChange(1)
        return
      }

      this.orderBy = prop
      this.order = newOrder

      if (this.order === null || this.orderBy === null) {
        this.order = null
        this.orderBy = null
      }
      this.pageChange(1)
    },
    hasFiltersApplied() {
      let filters = this.filterSearchKeywords()
      return Object.keys(filters).length > 0;
    },
    clearFilters() {
      this.searchKeywords = {}
      this.loadPage(1)
    },
    getFilterLabel(column) {
      const filtersApplied = (this.searchKeywords[column] && this.searchKeywords[column].length) ? this.searchKeywords[column]  : null
      if(!filtersApplied) {
        return 'Filter'
      }
      if(!Array.isArray(filtersApplied)) {
        return filtersApplied
      }
      const labelString = filtersApplied.length > 1 ? 'Filters' : 'Filter'
      return `${filtersApplied.length} ${labelString}`
    },
    getColumnFilterType(column) {
      if (this.loadedConfiguration.possibleColumns[column] && this.loadedConfiguration.possibleColumns[column].filter) {
        return this.loadedConfiguration.possibleColumns[column].filter;
      }
      if (this.filterTypes[column]) {
        return this.filterTypes[column]
      }
      return 'select'
    },
    getColumnSortable(column) {
      if (this.loadedConfiguration.possibleColumns[column] && this.loadedConfiguration.possibleColumns[column].sortable) {
        if (this.loadedConfiguration.possibleColumns[column].sortable === 'disabled') {
          return false
        }
        if (this.loadedConfiguration.possibleColumns[column].sortable === 'enabled') {
          return 'custom'
        }
        //return this.loadedConfiguration.possibleColumns[column].sortable
      }
      return 'custom'
    },
    getColumnValue(column, row) {
      let value = null
      if (this.visibleTableColumnsKeys[column] && this.visibleTableColumnsKeys[column].indexOf('.') > 0) {
        const keys = this.visibleTableColumnsKeys[column].split('.');
        value = (row[column] && row[column][keys[1]]) ? row[column][keys[1]] : ''
      } else {
        value = row[column] || ''
      }
      // Apply a formatted if needed
      if (this.loadedConfiguration.possibleColumns[column] && this.loadedConfiguration.possibleColumns[column].format) {
        return this[this.loadedConfiguration.possibleColumns[column].format](value)
      }

      return value
    },
    getColumnFilters(column, group) {
      if (group === 'F') {
        if (!this.filters[column]) {
          return []
        }
        return this.filters[column];
      }
      if (!this.filtersAllPossible || !this.filtersAllPossible[column]) {
        return []
      }
      return this.filtersAllPossible[column];
    },
    isFixedLeft(column, indexNumber) {
      if (indexNumber >= this.maxFixedColumns) {
        return false
      }
      return this.columnsToRender.includes(column) && this.fixedTableColumns.includes(column);
    },
    getColumnWidth(column) {
      if (this.tableOptions && this.tableOptions.columnWidth && this.tableOptions.columnWidth[column]) {
        return this.tableOptions.columnWidth[column]
      }
      if (['id'].includes(column)) {
        return 100
      }
      return (this.visibleTableColumnsNames[column] ?? column)?.length * 10 + 40
    },
    leftTableOffset() {
      let column = null
      let fixed = null
      let width = 0
      for (const indexNumber in this.columnsToRender) {
        column = this.columnsToRender[indexNumber]
        fixed = this.isFixedLeft(column, indexNumber)
        if (!fixed) {
          continue
        }
        width += this.getColumnWidth(column)
      }
      if(this.tableOptions.allowActions) {
        width += this.getColumnWidth('_rowActions')
      }
      if (this.tableOptions.allowSelection) {
        width += 40;
      }
      let tables_bodies = document.querySelectorAll(
        '.native-table-outer-row  .el-table--scrollable-x .el-table__body-wrapper > table'
      )
      for (let i = 0; i < tables_bodies.length; i++) {
        tables_bodies[i].style['margin-left'] = '-' + width + 'px'
      }
      let tables_headers = document.querySelectorAll(
        '.native-table-outer-row  .el-table--scrollable-x .el-table__header-wrapper > table'
      )
      for (let i = 0; i < tables_headers.length; i++) {
        tables_headers[i].style['margin-left'] = '-' + width + 'px'
      }
      let tables_bodies_wrap = document.querySelectorAll(
        '.native-table-outer-row  .el-table--scrollable-x .el-table__body-wrapper'
      )
      for (let i = 0; i < tables_bodies_wrap.length; i++) {
        tables_bodies_wrap[i].style['margin-left'] = width + 'px'
        tables_bodies_wrap[i].style['width'] = 'calc(100% - ' + width + 'px)'
      }
      let tables_headers_wrap = document.querySelectorAll(
        '.native-table-outer-row  .el-table--scrollable-x .el-table__header-wrapper'
      )
      for (let i = 0; i < tables_headers_wrap.length; i++) {
        tables_headers_wrap[i].style['margin-left'] = width + 'px'
        tables_headers_wrap[i].style['width'] = 'calc(100% - ' + width + 'px)'
      }
    },
    getColumnClass(column) {
      let classes = []
      if (this.hasFilterApplied(column)) {
        classes.push('has-applied-filter')
      }
      if(this.loadedConfiguration.possibleColumns[column]?.editable){
        classes.push('is-editable')
      }
      if(this.orderBy === column) {
        classes.push('is-sorting')
      }
      return classes.join(' ')
    },
    hasFilterApplied(column) {
      if (
        this.searchKeywords[column] &&
        this.searchKeywords[column].length > 0
      ) {
        return true
      }
      return false
    },
    changePageSize(newPageSize) {
      this.perPage = newPageSize
      this.pageChange(1)
    },
    pageChange(val) {
      this.currentPage = val
      this.loadPage(val)
    },
    searchFilterChange(value) {
      this.searchKeywords[this.filterElementCurrentCol] = value
      this.$nextTick(() => {
        this.$refs.filterSelectElementSelect.blur();
        this.$nextTick(() => {
          this.loadPage(1)
        })
      })
    },
    filterSearchKeywords() {
      let keywordsFiltered = {}
      for (const field in this.searchKeywords) {
        let value = this.searchKeywords[field]
        if (value && value.length > 0) {
          if (this.visibleTableColumns.includes(field)) {
            keywordsFiltered[field] = value
          } else if (this.fixedTableColumns.includes(field)) {
            keywordsFiltered[field] = value
          }
        }
      }
      return keywordsFiltered
    },
    loadConfig() {
      const config = null;
      const self = this
      return new Promise(function(resolve) {
        self.api[self.indexMethod](
          (response) => {
            // Check the state of the config window -> this process will chnage that and will require a re-render if currently open
            const prevState = self.dialogConfigVisible;
            if (prevState) {
              self.dialogConfigVisible = false;
            }
            self.loadedConfiguration.possibleColumns = {}
            let preLoadColumns = false
            if (!self.loadedConfiguration.visibleColumns) {
              self.loadedConfiguration.visibleColumns = [];
              preLoadColumns = true
            }

            /**
             * Set Possible, Visible Columns and categories
             */
            for (const key in response) {
              const fid = response[key].id
              // All columns are possible columns
              self.loadedConfiguration.possibleColumns[fid] = response[key]
              // Add categories
              if (!self.loadedConfiguration.categories.includes(response[key].category)) {
                self.loadedConfiguration.categories.push(response[key].category)
                self.filteredCategories.push(response[key].category)
              }
              if (preLoadColumns && self.fieldCategoryDefaults.length && self.fieldCategoryDefaults.includes(response[key].category)) {
                self.loadedConfiguration.visibleColumns.push(response[key])
              }
            }
            // If for some reason a current visible column is not included in the possible, then filter it ouy
            self.loadedConfiguration.visibleColumns = self.loadedConfiguration.visibleColumns.filter((column) => {
              return self.loadedConfiguration.possibleColumns[column.id]
            })
            // Re-open the config window if needed
            self.$nextTick(() => {
              if (prevState) {
                self.dialogConfigVisible = prevState;
              }
            });
            self.updateVisibleFields()
            if (self.tableData.length <= 0) {
              for (const field in self.filterPreset) {
                self.searchKeywords[field] = self.filterPreset[field]
              }
            }
            resolve(config)
          },
          (error) => {
            self.$message.error({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 0,
            })
            resolve(config)
          },
          ...self.requestIds,
          {
            ...{
              loadAfConfig: true,
              isAfTable: true
            },
            // Pass data through from tha parent component, if any given
            ...self.requestData
          }
        )
      })
    },
    loadPage(page = null, clearCache = null) {
      this.loading = true
      this.headerUnClick();

      this.$nextTick(() => {
        // Build the API request postData object
        const postData = {
          ...{
            page: page ?? this.currentPage,
            perPage: this.perPage,
            orderBy: this.orderBy ?? null,
            orderDirection: this.order ?? null,
            searchKeywords: JSON.stringify(this.filterSearchKeywords()),
            isAfTable: true,
            visibleColumns: this.columnsToRender
          },
          // Pass data through from the parent component, if any given
          ...this.requestData
        }

        // If the Cache clear is triggered, the current page is reloaded with a clear cache instruction
        if(clearCache) {
          postData.afClearCache = true
        }

        // The API call Response Handler
        const responseCallback = (response) => {
          // row and column data
          this.tableData = response.data

          // Set filter values (inclusive as well as possible)
          if (response.filters) {
            this.filters = response.filters
          }
          if (response.filtersAllPossible) {
            this.filtersAllPossible = response.filtersAllPossible
          }

          // Set the cache state information
          if (response.cachedVersion) {
            this.cachedVersion = response.cachedVersion
          }

          // paging information
          this.total = response.meta.total
          this.currentPage = response.meta.current_page

          this.$emit('page-loaded', response);

          this.renderTable = false
          this.$nextTick(() => {
            this.loading = false
            this.renderTable = true
          })

          if (this.tableOptions.showRevisions) {
            this.loadRevisions()
          }
        }

        // The API call Error Handler
        const errorCallback = (error) => {
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        }

        // Do the API call
        this.api[this.indexMethod](responseCallback, errorCallback, ...this.requestIds, postData)
      })
    },
    loadRevisions() {
      this.api.revisions(
        (response) => {
          this.loadedRevisions = response
        },
        (error) => {
          this.$message.error({
            showClose: true,
            type: 'error',
            message: util.formatErrorMessage(error),
            duration: 0,
          })
        }
      )
    },
    showHideRevisions() {
      this.revisionsVisible = !this.revisionsVisible
      this.setTableHeight()
    },
    dialogConfigOpen() {
      this.headerUnClick()
      this.dialogConfigVisible = true
    },
    onDialogConfigClose() {
      this.loading = true
      this.updateVisibleFields()
      this.refresh()
    },
    addAllFieldsToVisible() {
      for (const index in this.availableTableColumnsList) {
        const column = this.availableTableColumnsList[index]
        this.loadedConfiguration.visibleColumns.push(column)
      }
      this.filterAvailableTableColumnsList()
    },
    addFieldToVisible(element) {
      this.loadedConfiguration.visibleColumns.push(element)
      this.filterAvailableTableColumnsList()
      this.updateVisibleFields()
    },
    removeFieldFromVisible(element) {
      this.loadedConfiguration.visibleColumns = this.loadedConfiguration.visibleColumns.filter((elem) => elem !== element);
      this.filterAvailableTableColumnsList()
      this.updateVisibleFields()
    },
    removeAllFieldsFromVisible() {
      for (const index in this.loadedConfiguration.visibleColumns) {
        const column = this.loadedConfiguration.visibleColumns[index]
        this.availableTableColumnsList.push(column)
      }
      this.loadedConfiguration.visibleColumns = []
      this.filterAvailableTableColumnsList()
    },
    updateVisibleFields() {
      this.visibleTableColumns = []
      this.fixedTableColumns = []
      this.visibleTableColumnsNames = {}
      this.visibleTableColumnsKeys = {}

      // If no columns are selected to show then all the columns will show
      const readFrom = this.loadedConfiguration.visibleColumns.length ? this.loadedConfiguration.visibleColumns :  this.loadedConfiguration.possibleColumns
      for (const index in readFrom) {
        const column = readFrom[index]
        const colKey = column.id.toString()

        // Marked hidden from Backend Controller
        if(!column.hidden) {
          if (
            // Marked sticky from Backend Controller
            column.sticky &&

            // Max of [n] columns allowed to be sticky
            this.fixedTableColumns.length < this.maxFixedColumns
          ) {
            this.fixedTableColumns.push(colKey)
          } else if(!column.hidden) {
            this.visibleTableColumns.push(colKey)
          }

          this.visibleTableColumnsNames[colKey] = column.name
          this.visibleTableColumnsKeys[colKey] = column.key ?? colKey
        }
      }
      localStorage[this.tableName + '_visibleColumns'] = JSON.stringify(this.loadedConfiguration.visibleColumns)
      this.filterAvailableTableColumnsList()
      this.setColumnsToRender()
    },
    filterAvailableTableColumnsList() {
      let visible = []
      this.availableTableColumnsList = []
      for (const index in this.loadedConfiguration.visibleColumns) {
        const column = this.loadedConfiguration.visibleColumns[index]
        visible.push(column.id)
      }
      for (const index in this.loadedConfiguration.possibleColumns) {
        const column = this.loadedConfiguration.possibleColumns[index]
        if (!visible.includes(column.id)) {
          if (this.filteredCategories.includes(column.category)) {
            if (!column.hidden) {
              this.availableTableColumnsList.push(column)
            }
          }
        }
      }
    },
    cellDblClick(row) {
      this.$emit('cell-dblclick', row)
    },
    cellClick(row, column, target) {

      if(
        (column.id === this.cellEditCurrentCell && row === this.cellEditCurrentRow) ||
        !this.loadedConfiguration.possibleColumns[column.columnKey]?.editable
      ) {
        return;
      }

      this.editInputType = this.loadedConfiguration.possibleColumns[column.columnKey].type

      if (['text', 'string'].includes(this.editInputType)) {
        this.editInputType = 'text'
      } else if (['number', 'decimal'].includes(this.editInputType)) {
        if (this.editInputType === 'decimal') {
          this.editNumberInputStep = '0.01'
        } else {
          this.editNumberInputStep = '1'
        }
        this.editInputType = 'number'
      }

      this.cellEditModel = row[column.columnKey]
      this.cellEditCurrentCell = column.id
      this.cellEditCurrentRow = row
      this.cellEditCurrentCol = column.columnKey

      if(this.editInputType === 'dropdown') {
        this.cellEditOptions = this.loadedConfiguration.possibleColumns[column.columnKey].options
      }
      const editField = document.getElementById('cellEditElement')
      target.appendChild(editField)
    },
    archiveItem(item) {
      const identifyingName = item.name
      this.$confirm(`Do you want to archive "${identifyingName}"?`, 'Warning', {
        confirmButtonText: 'Archive',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        this.api.archive(
          () => {
            this.$message.success({
              showClose: true,
              message: `${identifyingName} has been archived.`,
              duration: 5000,
            })
            this.refresh()
          },
          (error) => {
            let errorMessage = `An error occurred while archiving the ${this.vocabulary.single}. Please try again.`
            if (error) {
              logErrorMessage(error)
              errorMessage = error.message
            }
            this.$message.error({
              showClose: true,
              message: errorMessage,
              duration: 5000,
            })
          },
          item.id
        )
      })
    },
    rowAction(action, data) {
      if (this.rowActions[action].emit === false) {
        if (this[action]) {
          this[action](data)
        }
        return;
      }
      this.$emit('row-action', {action, data});
      this.$emit(`row-action-${action}`, data);
    },
    rowClassName(tableRow) {
      if (this.tableOptions.rowClassName) {
        return this.tableOptions.rowClassName(tableRow);
      }
      return ''
    },

    /** This is a set of columns value filters that can be used from table config in the backend */
    commaSeparate(value) {
      if (!value) {
        return ''
      }
      if (Array.isArray(value)) {
        return value.join(', ')
      }
      return value
    },
    shortDate(value) {
      if (!value) {
        return ''
      }
      return moment(String(value)).format('DD.MM.YYYY')
    },
    longDate(value) {
      if (!value) {
        return ''
      }
      return moment(String(value)).format('DD.MM.YYYY hh:mm')
    },
    money(value) {
      if(value === 0) {
        return '0.00'
      }
      if (!value) {
        return ''
      }
      return Number.isInteger(value) ? value.toFixed(2) : value
    },
    yesOrEmpty(value) {
      return value ? 'Yes' : ''
    }
  }
}
</script>
<style lang="scss">
.data-table-alternate-ver-2 {
  .is-cached-version {
    padding: 7px 9px;
    font-size: 12px;
    border-radius: 0;
  }
  #filterElementContainer {
    display: none;
  }
  #cellEditElementContainer {
    position: relative;
  }
  #cellEditElement {
    position: absolute;
    top:2px;
    left:0;
    right:0;
    bottom:2px;
  }
  #filterTextElement, #filterSelectElement {
    /* display: none; */
  }
  .header-search {
    height:25px;

    #filterTextElement, #filterSelectElement {
      display: block;
    }
    .search-value {
      background: white;
      border: 1px solid #DCDFE6;
      height:23px;
      color: #DCDFE6;
      padding:3px 10px;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 5px;
      font-family:Arial,serif;
    }
  }

  .page-actions {
    margin-bottom:0.5rem;
  }
  .el-table th.el-table__cell>.cell {
    padding-right:5px;
    padding-left:5px;
  }
  .el-table td.el-table__cell>.cell {
    padding-right:5px;
    padding-left:5px;
    height:23px;
  }

  .el-table--mini th.el-table__cell.has-applied-filter {
    background: rgba(64, 158, 255, 0.5);
  }

  .el-table .el-table-column--selection .cell {
    padding-left: 0;
    padding-right: 0;
    text-align: center;

    .el-checkbox__input {

    }
  }

  .el-table__empty-block {
    justify-content: normal;
    text-align: initial;
    -webkit-box-align: initial;
    -ms-flex-align: initial;
    align-items: normal;
    font-size: 1.25em;
    font-weight: bold;
  }

  .filters-clear {
    text-align: center;
  }

  .table-card {
    margin-top: 0 !important;

    .el-card__body {
      padding: 0;
    }
  }

  table .el-select .el-input__inner {
    height: 23px !important;
  }
  table .cell-input.el-select .el-input__inner {
    height: 27px !important;
  }



  .el-select-dropdown__item {
    padding: 0px 10px;
  }

  .el-table__cell {
    .editable-icon {
      position: absolute;
      right: 5px;
      top: 0;
      display: none;
      .el-icon-edit {
        font-size: 12px;
      }
    }
  }

  .is-editable.el-table__cell:hover {
    .editable-icon {
      display: block;
    }
    cursor: pointer;
  }
  .el-table th {
    border-top: 1px solid transparent;
  }
  .is-sorting .el-table th.is-sorting {
    border-top: 1px solid #657080;;
  }
  .is-sorting.is-sorting-order-asc th.is-sorting {
    .sort-caret.ascending {
      border-bottom-color: #657080;
    }
  }
  .is-sorting.is-sorting-order-desc th.is-sorting {
    .sort-caret.descending {
      border-top-color: #657080;
    }
  }

  .finalized-bids .cell-content:hover,
  .closed-bids .cell-content:hover {
    cursor: default;
  }


  .cell-input {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;

    .el-input__inner {
      height: 27px;
    }
  }

  .el-dialog {
    top: 3em;
    bottom: 3em;
    left: 3em;
    right: 3em;
    width: auto;
    overflow: auto;
    position: fixed;
    margin: 0;

    .bottom-row {
      text-align: right;
      padding-top: 60px;
    }
  }

  .el-dialog__body {
    padding-bottom: 60px;
  }

  .list-group {
    width: 100%;
    min-height: calc(100%);
    border: 1px solid rgba(206, 206, 206, 0.5);
    list-style-type: none;
    counter-reset: css-counter 0;

    .list-group-item {
      padding: 0.25rem 0.5rem;
      border-bottom: 1px solid rgba(206, 206, 206, 0.5);
      cursor: pointer;
      counter-increment: css-counter 1;

      span {
        background: purple;
        border-radius: 5px;
        display: inline-block;
        float: right;
        color: white;
        font-size: 0.85em;
        padding: 0.25em 0.5em;
      }

      .has-filter {
        span {
          visibility: hidden;
        }

        &:hover {
          span {
            visibility: visible;
          }
        }
      }

      span.cat-bid_management {
        background: green;
      }

      span.cat-controlling {
        background: darkorange;
      }

      span.cat-engineering {
        background: darkslateblue;
      }

      span.cat-project {
        background: darkred;
      }

      span.cat-general {
        background: teal;
      }
    }

    .list-group-item:before {
      content: counter(css-counter) '. ';
    }

    .list-group-item:last-child {
      border-bottom: none;
    }

    .sortable-chosen {
      padding: 0.25rem 1rem;
      background: rgba(206, 206, 206, 0.5);
    }
  }

  .add-all,
  .remove-all,
  .refresh-fields {
    float: right;
    cursor: pointer;
    margin-top: -2em;
  }

  .refresh-fields {
    margin-right: 5em;
    margin-top: -2.5em;
  }

  .pagination-row {
    margin-top: 1em;

    .setting {
      cursor: pointer;
      height: 28px;
      line-height: 28px;
      width: 28px;
      padding: 0;
    }

    .el-pagination {
      margin-top: 0;
    }

    .export {
      cursor: pointer;
      height: 28px;
      line-height: 28px;
      padding: 0 15px;
    }
  }

  .el-checkbox {
    display: block;
    margin: 0;
    padding: 0;

    .el-checkbox__input {
      float: right;
    }

    .el-checkbox__label {
      padding: 0;
    }
  }

  .el-checkbox:before,
  .el-checkbox:after {
    clear: both;
    content: '';
    height: 0;
    line-height: 0;
    overflow: hidden;
    display: block;
  }

  h4 {
    padding: 0;
    font-size: 1em;
    margin: 0 0 1em 0;
  }

  .header-search {
    min-height: 24px;
    .el-select {
      width: 100%;
    }
    .el-input {
      .el-input__inner {
        height: 23px;
      }
    }

    select {
      width: 100%;
      display: block;
      border-radius: 3px;
      border: 1px solid #dde0e5;
    }

    .el-select__input input {
      margin-left: 5px;
      cursor: pointer;
    }

    select[disabled] {
      visibility: hidden;
    }

    span.col-label {
      display: block;
      margin: 0.5em;
    }

    .el-input__suffix {
      display: block;
      margin: 0;
      padding: 0;
      position: absolute;

      .el-input__suffix-inner {
        display: block;
        margin: 0;
        padding: 0;
        position: absolute;
        right: 0;

        .el-input__icon {
          line-height: 23px;
        }
      }
    }

    .el-select + span {
      display: block;
      margin: 0;
    }

    .el-select-dropdown__item {
      padding-right: 50px;
    }

    .el-select-dropdown__item.selected::after {
      right: 6px;
      top: 10px;
    }

    .el-select__tags {
      top: auto;
      transform: initial;
      bottom: -1px;
      width: 100%;
      max-width: 100% !important;
    }

    .el-select__tags > span {
      display: none;
    }
  }

  .header-search.loading .el-select {
    visibility: hidden;
  }

  .revision-card-wrapper {
    .toggle-button {
      float: right;
    }

    margin-top: 1em;

    .toggle {
      padding: 0.5em 0.5em 0.5em 1em;
      line-height: 35px;
      font-size: 15px;
      border: 1px solid #d1dbe5;
      border-radius: 4px;
      background: #fff;
      overflow: hidden;
    }
  }

  .revision-card .el-card__header {
    display: none;
  }

  .revision-card .el-card__body .el-table__body-wrapper {
    max-height: 49vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .hide-revisions {
    .revision-card {
      display: none;
    }
  }

  .native-table-outer-row {
    position: relative;

    .native-table-outer {
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      background-color: #fff;
    }
  }

  .show-revisions .native-table-outer-row {

  }

  .el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: auto;
  }

  .text-right {
    text-align: right;
  }

  .question-dialog .el-dialog {
    width: auto;
    max-width: 640px;
    margin: 0 auto;
    height: auto;
    top: 3em;
    bottom: auto;

    .el-dialog__body {
      padding-bottom: 30px;
    }

    .el-checkbox .el-checkbox__input {
      float: none;
      margin-right: 10px;
    }
  }

  .bulk-actions {
    margin-bottom: 5px;
    float: left;
  }

  .bid-add-button {
    float: right;
  }
}

.header-search-with-groups {
  .el-select-group__wrap.other-filters {
    .el-select-dropdown__item {
      opacity: 0.35;
    }

    .el-select-dropdown__item.selected,
    .el-select-dropdown__item.hover {
      opacity: 0.5;
    }
  }
}
</style>

