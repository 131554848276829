<template>
  <div>
    <DataTableAlternate
      ref="dataTable"
      :api="Api()"
      :data-table-options="dataTableOptionsFiltered"
      :request-data="additionalRequestData"
      :filter-preset="filterPreset"
      :row-actions="rowActions"
      table-name="PurchaseInvoicesImportItemsTable"
      @selection-change="selectionChange"
      @row-action="rowAction"
      @page-loaded="pageLoaded"
    >
      <template #actions="{ row }">
        <template
          v-if="additionalRequestData.showImages && pageData.positions && pageData.positions[row.position_id]?.allImageUrls?.length > 0"
        >
          <el-button
            :key="`${row.id}_images`"
            icon="el-icon el-icon-picture"
            size="mini"
            @click.native.stop.prevent="showImages(row)"
          />
          {{pageData.positions[row.position_id]?.allImageUrls?.length}}
        </template>
      </template>
    </DataTableAlternate>


    <el-dialog
      :visible.sync="imagesDialogVisible"
      title="Position Information"
    >

      <div class="header-wrapper">
        <div v-if="imagesDialogPosition.location?.area" class="area">
          <span>Area: </span>
          {{ imagesDialogPosition.location?.area.name }}
        </div>
        <div v-if="imagesDialogPosition.street" class="street">
          <span>Street: </span>
          {{ imagesDialogPosition.street.name }}
          {{ [imagesDialogPosition.street_start, imagesDialogPosition.street_end].join(' - ') }}
        </div>
        <div v-if="imagesDialogPosition.muffe" class="area">
          <span>Muffe: </span>
          {{ imagesDialogPosition.muffe.name }}
        </div>
        <div v-if="imagesDialogPosition.mfg" class="area">
          <span>MFG: </span>
          {{ imagesDialogPosition.mfg.name }}
        </div>
        <div class="executor">
          <span>Executor: </span>
          <template v-if="imagesDialogPosition.executor"
          >{{ imagesDialogPosition.executor }}
            <template v-if="imagesDialogPosition.executorName"
            >: {{ imagesDialogPosition.executorName }}
            </template
            >
          </template>
        </div>
        <div class="comments">
          <span>Comments: </span>
          <template v-if="imagesDialogPosition.comments">{{ imagesDialogPosition.comments }}</template>
        </div>
      </div>
      <div class="image-wrapper">
          <div
            v-for="image in imagesDialogImages"
            :key="image"
            :title="image"
            class="image-thumbnail"
            @click="openImageModal(image)"
          >
            <div :style="{'background-image': `url('${image}')`}"></div>
          </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imagesDialogVisible = false">Close</el-button>
      </span>
    </el-dialog>

    <imageModal
      v-if="showImageModal"
      :picture="imageModalUrl"
      @close="hideImageModal"
    />
  </div>
</template>
<script>
import DataTableAlternate from '@/js/components/Common/DataTableAlternate';
import purchaseInvoiceItemImportApi from '@/js/api/v2/purchaseInvoiceItemImport';
import imageModal from '@/js/components/Items/ImageModal.vue';

export default {
  name: 'TableWithFilters',
  components: {
    imageModal,
    DataTableAlternate
  },
  props: {
    requestData: {
      type: Object,
      default: null,
    },
    filterPreset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataTableOptions: {
        allowSelection: true,
        tableHeightOffset: 550,
        pageSizes: [100, 200, 500],
        columnWidth: {
          _rowActions: 10,
          created_at: 100,
          id: 90,
          position_id: 120,
          project: 200,
          person: 200,
          area: 160,
          subcontractor_reference: 200,
          subcontractor_price: 180,
          comments: 200,
          position_comments: 200,
          description: 300,
        },
        rowClassName: function (tableRow) {
          let classes = []
          if (tableRow.row.imported_purchase === 'Yes') {
            classes.push('has-changes')
          }
          return classes.join(' ')
        },
      },
      pageData: {},
      imagesDialogVisible: false,
      imagesDialogPosition: {},
      imagesDialogImages: [],
      showImageModal: false,
      imageModalUrl: '',
    }
  },
  computed: {
    dataTableOptionsFiltered() {
      const data = this.dataTableOptions
      if(this.requestData.showImages) {
        data.columnWidth._rowActions = 60
      }
      data.showRefresh = false
      return data
    },
    additionalRequestData() {
      return this.requestData
    },
    rowActions() {
      let rowActions = {}
      return rowActions;
    },
  },
  methods: {
    Api() {
      return purchaseInvoiceItemImportApi
    },
    allowRowSelection() {
      return true
    },
    selectionChange(val) {
      this.$emit('selection-change', val)
    },
    rowAction(action) {
      if (this[action.action]) {
        this[action.action](action.data);
      }
    },
    showImages(row) {
      this.imagesDialogVisible = true
      this.imagesDialogPosition = this.pageData.positions[row.position_id]
      this.imagesDialogImages = this.pageData.positions[row.position_id]?.allImageUrls
    },
    openImageModal(url) {
      this.showImageModal = true
      this.imageModalUrl = url
    },
    hideImageModal() {
      this.showImageModal = false
      this.imageModalUrl = ''
    },
    pageLoaded(data) {
      this.pageData = data
    },
    refresh() {
      this.$refs.dataTable.refresh(true)
    },
  }
}

</script>
<style scoped>
.picture-modal {
  z-index: 10000000;
}
.image-wrapper:before,
.image-wrapper:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height:0;
  overflow: hidden;
}
.image-thumbnail {
  width:200px;
  height:200px;
  position: relative;
  float: left;
  box-sizing: border-box;
  border:3px solid white;
  background: #efefef;
}

.image-thumbnail > div {
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.image-thumbnail > div:hover {
  opacity:0.5;
  cursor: pointer;
}

::v-deep .el-table__row.has-changes.current-row ,
::v-deep .el-table__row.has-changes {
  background: rgb(29, 185, 93);
}

::v-deep .el-table__row.has-changes.current-row > td,
::v-deep .el-table__row.has-changes.hover-row > td,
::v-deep .el-table__row.has-changes.hover-row.current-row > td {
  background: rgba(29, 185, 93, 1);
}

::v-deep .el-table__row.el-table__row--striped.has-changes.hover-row.current-row > td.el-table__cell,
::v-deep .el-table__row.el-table__row--striped.has-changes.current-row > td.el-table__cell,
::v-deep .el-table__row.el-table__row--striped.has-changes > td.el-table__cell {
  background: rgba(29, 185, 93, 1);
}

::v-deep .el-table__row.el-table__row--striped.has-changes.hover-row.current-row > td.
::v-deep .el-table__row.el-table__row--striped.has-changes.hover-row > td {
  background: rgba(29, 185, 93, 1);
}
.header-wrapper {
  font-size: 13px;
  margin: 10px 0 5px 0;

  .street,
  .area,
  .executor,
  .comments {
    line-height: 1.5;
    font-weight: bold;

    > span {
      display: inline-block;
      width: 100px;
    }
  }

  .comments {
    font-weight: normal;
  }
}

</style>
