<template>
  <div>
    <el-form
      ref="form"
      :model="position"
      label-position="top"
      label-width="120px"
    >

      <template v-if="fieldDisabled">
        <div class="request-changes">
          <el-alert
            :closable="false"
            title="A validated positions cannot be updated"
            type="error"
            :show-icon="true"
          >
          </el-alert>
          <el-button
            v-if="!sendNotificationResponse"
            plain
            size="small"
            title="A validated positions cannot be updated"
            @click="requestChangesModal"
          >
            Request change
          </el-button>
          <span v-else>
              {{sendNotificationResponse}}
            </span>
        </div>
      </template>


      <el-row :gutter="35">
        <el-col :span="8">
          <el-form-item ref="user_id" label="User" prop="user_id">
            <el-select
              v-model="position.userId"
              :filterable="true"
              :loading="loadingUsers"
              :disabled="fieldDisabled"
              placeholder="Please select a user"
            >
              <el-option
                v-for="(item, index) in users"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :span="8">
          <el-form-item ref="executor" prop="executor">
            <el-radio-group
              :disabled="fieldDisabled"
              :value="position.executor"
              @input="onExecutorInput"
            >
              <el-radio
                v-for="n in executorOptions"
                :key="n"
                :label="n"
                :value="n"
              />
            </el-radio-group>

            <el-tooltip
              v-if="originalPosition.executor !== position.executor"
              :content="changedTooltipMessage"
            >
              <span class="el-icon-information">&nbsp;</span>
            </el-tooltip>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :span="8">
          <el-form-item
            v-if="position.executor === 'Soli Personal' && teamOptions.length"
            ref="team"
            label="Team"
            prop="team"
          >
            <el-select
              v-model="position.team"
              :filterable="true"
              :disabled="fieldDisabled"
              placeholder="Please select a team"
            >
              <el-option
                v-for="(team, index) in teamOptions"
                :key="index"
                :label="team.name"
                :value="team.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="
            position.executor === 'Nachunternehmer' &&
            subcontractorOptions.length
          "
            ref="subcontractor_id"
            label="Subcontractor"
            prop="subcontractor_id"
          >
            <el-select
              :filterable="true"
              :loading="loadingSubContractors"
              :value="position.subcontractorId"
              :disabled="fieldDisabled"
              placeholder="Please select a subcontractor"
              @input="onSubcontractorIdInput"
            >
              <el-option
                v-for="(item, index) in subcontractorOptions"
                :key="index"
                :label="`${item.name}`"
                :value="item.id"
              />
            </el-select>

            <el-tooltip
              v-if="
              originalPosition.executor === position.executor &&
              originalPosition.subcontractorId !== position.subcontractorId
            "
              :content="changedTooltipMessage"
            >
              <span class="el-icon-information">&nbsp;</span>
            </el-tooltip>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row
        v-if="showSendNotification"
        :gutter="5"
      >
        <el-col :span="12">
          <el-alert
            :closable="false"
            :title="showSendNotification"
            type="error"
          >
          </el-alert>

          <p>
            <el-button
              v-if="!sendNotificationResponse"
              plain
              size="small"
              @click="openNotificationDialog"
            >
              Request Subcontractor Pricing
            </el-button>
            <el-alert
              v-if="sendNotificationResponse"
              :closable="false"
              :title="sendNotificationResponse"
              type="success"
            >
            </el-alert>
          </p>
        </el-col>
      </el-row>

      <el-row :gutter="35">
        <el-col :span="8">
          <el-form-item
            ref="pricing_category_id"
            label="Category"
            prop="pricing_category_id"
          >
            <el-select
              v-if="categories.length"
              :value="position.pricingCategoryId"
              :disabled="fieldDisabled"
              placeholder="Please select a category"
              @input="onPricingCategoryIdInput"
            >
              <el-option
                v-for="(category, index) in categories"
                :key="index"
                :label="`${category.name}  (${category.areaSelection})`"
                :value="category.id"
              />
            </el-select>

            <el-tooltip
              v-if="
              originalPosition.pricingCategoryId !== position.pricingCategoryId
            "
              :content="changedTooltipMessage"
            >
              <span class="el-icon-information">&nbsp;</span>
            </el-tooltip>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            v-if="subcategoryOptions.length"
            ref="pricing_subcategory_id"
            label="Sub Category"
            prop="pricing_subcategory_id"
          >
            <el-select
              :value="position.pricingSubcategoryId"
              :disabled="fieldDisabled"
              placeholder="Please select a category"
              @input="onPricingSubCategoryIdInput"
            >
              <el-option
                v-for="(category, index) in subcategoryOptions"
                :key="index"
                :label="`${category.subcategoryName}`"
                :value="category.id"
              />
            </el-select>
            <el-tooltip
              v-if="
              originalPosition.pricingSubcategoryId !==
              position.pricingSubcategoryId
            "
              :content="changedTooltipMessage"
            >
              <span class="el-icon-information">&nbsp;</span>
            </el-tooltip>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :span="8">
          <el-form-item
            v-if="streetOptions.length"
            ref="street_id"
            label="Street"
            prop="street_id"
          >
            <el-select
              v-model="position.streetId"
              :disabled="fieldDisabled"
              placeholder="Please select a street"
            >
              <el-option
                v-for="(street, index) in streetOptions"
                :key="index"
                :label="`${street.name}`"
                :value="street.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="muffeOptions.length"
            ref="muffe_id"
            label="Muffe"
            prop="muffe_id"
          >
            <el-select
              v-model="position.muffeId"
              :disabled="fieldDisabled"
              placeholder="Please select a muffe"
            >
              <el-option
                v-for="(muffe, index) in muffeOptions"
                :key="index"
                :label="`${muffe.name}`"
                :value="muffe.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="mfgOptions.length"
            ref="mfg_id"
            label="Mfg"
            prop="mfg_id"
          >
            <el-select
              v-model="position.mfgId"
              :disabled="fieldDisabled"
              placeholder="Please select a mfg">
              <el-option
                v-for="(mfg, index) in mfgOptions"
                :key="index"
                :label="`${mfg.name}`"
                :value="mfg.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item v-if="areaSelection === 'street'" label="Street Start">
            <el-input
              v-model="position.streetStart"
              :disabled="fieldDisabled"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item v-if="areaSelection === 'street'" label="Street end">
            <el-input
              v-model="position.streetEnd"
              :disabled="fieldDisabled"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :span="8">
          <el-form-item ref="start_date" label="Start date" prop="start_date">
            <el-date-picker
              v-model="position.startDate"
              :picker-options="startDatePickerOptions"
              :disabled="fieldDisabled"
              format="yyyy.MM.dd"
              placeholder="Pick a date"
              style="width: 100%"
              type="date"
            />
            <el-tooltip
              v-if="validationErrors.start_date"
              content="The 'Start Date' cannot be in the future"
            >
              <span class="el-icon-information">&nbsp;</span>
            </el-tooltip>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item ref="end_date" label="End date" prop="end_date">
            <el-date-picker
              v-model="position.endDate"
              :picker-options="endDatePickerOptions"
              :disabled="fieldDisabled"
              format="yyyy.MM.dd"
              placeholder="Pick a date"
              style="width: 100%"
              type="date"
            />
            <el-tooltip
              v-if="validationErrors.end_date"
              content="The 'End Date' must be after the 'Start Date' and cannot be in the future."
            >
              <span class="el-icon-information">&nbsp;</span>
            </el-tooltip>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Description">
        <el-input v-model="position.comments" type="textarea"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit"> Update</el-button>
      </el-form-item>
    </el-form>

    <SendDialog
      v-if="showSendNotification || fieldDisabled"
      ref="createNotification"
      :subject="sendNotificationSubject"
      :message="sendNotificationMessage"
      @close="onNotificationDialogClose"
      @sent="onNotificationDialogSent"
    />
  </div>
</template>

<script>
import positionsApi from 'api/v2/positions'
import subcontractorsApi from 'api/v2/subcontractors'
import usersApi from 'api/v2/users'
import projectPricingCategoriesApi from 'api/v2/projectPricingCategories'
import {formatErrorMessage} from 'common/util'
import pricingSubcategoriesResourceAou from 'api/v2/pricingSubcategories'
import projectAreasApi from 'api/v2/projectAreas'
import teamsApi from 'api/v2/teams'
import * as util from '../../common/util'
import {logErrorMessage} from '../../common/util'
import SendDialog from '@/js/components/Notifications/SendDialog.vue'

export default {
  name: 'PositionFormGeneral',
  components: {
    SendDialog,
  },
  props: {
    inheritedPosition: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      position: this.inheritedPosition,
      selectedSubcontractor: null,
      users: [],
      teams: [],
      subcontractors: [],
      categories: [],
      sub_categories: [],
      areas: [],
      busy: true,
      loadingUsers: true,
      loadingTeams: true,
      loadingSubContractors: true,
      loadingCategories: true,
      loadingSubCategories: true,
      loadingAreas: true,
      executorOptions: ['Soli Personal', 'Nachunternehmer'],
      originalPosition: {},
      changedFields: {},
      changedTooltipMessage:
        'This field was changed, position items will be removed.',
      startDatePickerOptions: {
        disabledDate: this.disabledStartDate,
      },
      endDatePickerOptions: {
        disabledDate: this.disabledEndDate,
      },
      validationErrors: {
        start_date: false,
        end_date: false,
      },
      showSendNotification: null,
      sendNotificationSubject: '',
      sendNotificationMessage: '',
      sendNotificationResponse: null,
    }
  },
  computed: {
    busyLoading() {
      if (
        this.loadingTeams ||
        this.loadingAreas ||
        this.loadingUsers ||
        this.loadingSubContractors ||
        this.loadingCategories ||
        this.loadingSubCategories
      ) {
        return true
      }
      return false
    },
    clearPositionItems() {
      if (this.originalPosition.executor !== this.position.executor) return true
      if (
        this.originalPosition.pricingCategoryId !==
        this.position.pricingCategoryId
      )
        return true
      if (
        this.originalPosition.pricingSubcategoryId !==
        this.position.pricingSubcategoryId
      )
        return true
      if (
        this.originalPosition.subcontractorId !== this.position.subcontractorId
      )
        return true
      return false
    },
    areaSelection() {
      if (!this.position.pricingCategoryId) return
      let selected = this.categories.filter(
        (category) => category.id === this.position.pricingCategoryId
      )
      if (selected.length <= 0) return
      return selected[0].areaSelection
    },
    subcategoryOptions() {
      if (!this.position.pricingCategoryId) return []
      if (!this.sub_categories) return []
      let selectedCategory = this.categories.find(
        (category) => category.id === this.position.pricingCategoryId
      )
      if (!selectedCategory) return []
      let options = this.sub_categories.filter(
        (subcategory) => subcategory.categoryName === selectedCategory.name
      )
      return options
    },
    streetOptions() {
      if (this.areaSelection !== 'street') return []
      let streets = []
      this.areas.forEach(function (item) {
        item.streets.forEach(function (streetItem) {
          streets.push(streetItem)
        })
      })
      return streets
    },
    muffeOptions() {
      if (this.areaSelection !== 'muffe') return []
      let muffen = []
      this.areas.forEach(function (item) {
        item.muffen.forEach(function (streetItem) {
          muffen.push(streetItem)
        })
      })
      return muffen
    },
    mfgOptions() {
      if (this.areaSelection !== 'mfg') return []
      let mfgs = []
      this.areas.forEach(function (item) {
        item.mfgs.forEach(function (streetItem) {
          mfgs.push(streetItem)
        })
      })
      return mfgs
    },
    teamOptions() {
      return this.teams
    },
    subcontractorOptions() {
      return this.subcontractors
    },
    isSuperAdmin() {
      return this.$store.getters['global/user']['role'] === 'super_admin'
    },
    fieldDisabled() {
      // This can later be moved to be a method if needed for specific fields
      // For now only add to fields that should not be edited if the position is already validated
      if(!this.isSuperAdmin && this.position.executorPositionValidatedAt) {
        return true
      }
      return false
    },
    sendRequestNotificationSubject() {
      let parts = [];
      parts.push('Request change on locked position')
      if(this.position) {
        parts.push(`Position: # ${this.position.id}`)
        parts.push(`Project: ${this.position.project.name}`)
      }
      return parts.join(', ')
    },
  },
  watch: {
    'position.pricingCategoryId'() {
      this.position.pricingSubcategoryId = null
      this.position.streetId = null
      this.position.muffeId = null
      this.position.mfgId = null
    },
    'position.startDate'() {
      this.isValidStartDate()
      this.isValidEndDate()
    },
    'position.endDate'() {
      this.isValidStartDate()
      this.isValidEndDate()
    },
  },
  created() {
    this.loadUsers()
    this.loadSubContractors()
    this.loadCategories()
    this.loadSubCategories()
    this.loadAreas()
    this.loadTeams()
    this.originalPosition = JSON.parse(JSON.stringify(this.position))
  },
  methods: {
    openNotificationDialog() {
      this.$refs.createNotification.openNotificationDialog()
    },
    requestChangesModal() {
      this.$refs.createNotification.openNotificationDialog(
        this.sendRequestNotificationSubject,
        '',
        {
          message: 'Reason for the change?'
        }
      )
    },
    onNotificationDialogClose() {
      this.sendNotificationResponse = null
    },
    onNotificationDialogSent() {
      this.sendNotificationResponse = 'Notification Created! You will receive an email once the request has been actioned.'
    },
    validateForm() {
      if (!this.isValidStartDate()) {
        return false
      }
      if (!this.isValidEndDate()) {
        return false
      }
      return true
    },
    isValidStartDate() {
      let startDate = util.formatDateForRequest(this.position.startDate)
      let now = util.formatDateForRequest(new Date())
      let isValid = startDate <= now
      this.validationErrors.start_date = !isValid
      return isValid
    },
    isValidEndDate() {
      let startDate = util.formatDateForRequest(this.position.startDate)
      let endDate = util.formatDateForRequest(this.position.endDate)
      let isValid = false
      if (endDate >= startDate) {
        isValid = true
      }
      this.validationErrors.end_date = !isValid
      return isValid
    },
    disabledStartDate(date) {
      return date > new Date()
    },
    disabledEndDate(date) {
      let startDate = util.formatDateForRequest(this.position.startDate)
      let testDate = util.formatDateForRequest(date)
      if (testDate >= startDate) {
        return false
      }
      return true
    },
    agreeToClearPositions() {
      if (confirm('All items on this position will be removed.')) {
        return true
      }
      return false
    },
    onSubcontractorIdInput(value) {
      this.changedFields.subcontractorId = true
      this.position.subcontractorId = value
      this.position.pricingCategoryId = null
      this.position.pricingSubcategoryId = null
      this.loadCategories()
    },
    onPricingCategoryIdInput(value) {
      this.changedFields.pricingCategoryId = true
      this.position.pricingCategoryId = value
      this.position.pricingSubcategoryId = null
    },
    onPricingSubCategoryIdInput(value) {
      this.changedFields.pricingSubcategoryId = true
      this.position.pricingSubcategoryId = value
    },
    onExecutorInput(value) {
      this.changedFields.executor = true
      this.position.executor = value
      this.position.pricingCategoryId = null
      this.position.pricingSubcategoryId = null
      this.position.subcontractorId = null
      this.position.team = null
      if (this.position.executor === 'Nachunternehmer') {
        this.categories = []
      } else {
        this.loadCategories();
      }
    },
    loadUsers() {
      this.loadingUsers = true
      usersApi.index(
        (response) => {
          this.loadingUsers = false
          this.users = response
        },
        (error) => {
          this.loadingUsers = false
          throw error
        },
        {all: true}
      )
    },
    loadSubContractors() {
      let data_params = {
        all: true
      }
      if (this.position.subcontractorId) {
        data_params.loadIfArchived = [this.position.subcontractorId]
      }
      this.loadingSubContractors = true
      subcontractorsApi.index(
        (response) => {
          this.loadingSubContractors = false
          this.subcontractors = response
        },
        (error) => {
          this.loadingSubContractors = false
          throw error
        },
        data_params
      )
    },
    loadCategories() {
      if (!this.position.projectId) return
      let additional_data = {}
      if (this.position.subcontractorId) {
        // Ensure that SubContractor Pricing Categories are loaded
        additional_data.subcontractor_id = this.position.subcontractorId
      }
      this.loadingCategories = true
      this.showSendNotification = null
      this.sendNotificationSubject = ''
      this.sendNotificationResponse = null
      this.sendNotificationMessage = ''

      projectPricingCategoriesApi.index(
        (response) => {
          this.categories = response
          this.loadingCategories = false
        },
        (error) => {
          this.loadingCategories = false
          this.categories = []

          if(error.message === 'Subcontractor template is missing, contact an administrator.') {
            this.$nextTick(() => {
              const selectedSubcontractor = this.subcontractors.filter((subcontractor) => {return subcontractor.id ===  this.position.subcontractorId})
              this.showSendNotification = error.message
              this.sendNotificationSubject = `Request for the setup of a Subcontractor Template for: Subcontractor = ${selectedSubcontractor[0]?.name || this.position.subcontractorId} and Project = ${this.position.project.name}`
              this.sendNotificationMessage = ''
            });
          }

          logErrorMessage(error)
        },
        this.position.projectId,
        additional_data
      )
    },
    loadSubCategories() {
      this.loadingSubCategories = true
      pricingSubcategoriesResourceAou.index(
        (response) => {
          this.sub_categories = response
          this.loadingSubCategories = false
        },
        (error) => {
          this.loadingSubCategories = false
          logErrorMessage(error)
        }
      )
    },
    loadAreas(include = ['streets', 'muffen', 'mfgs']) {
      if (!this.position.projectId) return
      this.loadingAreas = true
      projectAreasApi.index(
        (response) => {
          this.areas = response
          this.loadingAreas = false
          if (!this.areas.length) {
            const ok = confirm('Please add areas before creating a position.')
            if (ok) {
              this.$router.push({
                path: 'projects/edit/:projectId',
                name: 'edit',
                params: {projectId: this.position.projectId},
              })
            }
          }
        },
        (error) => {
          this.loadingAreas = false
          logErrorMessage(error)
        },
        this.position.projectId,
        {
          include: include,
        }
      )
    },
    loadTeams() {
      this.loadingTeams = true
      teamsApi.index(
        (response) => {
          this.teams = response
          this.loadingTeams = false
        },
        (error) => {
          this.loading = false
          logErrorMessage(error)
        }
      )
    },
    onSubmit() {
      if (this.clearPositionItems && !this.agreeToClearPositions()) {
        return
      }
      let isValid = this.validateForm()
      if (!isValid) {
        this.$message({
          showClose: true,
          type: 'error',
          message: 'Validation Errors',
        })
        return
      }
      this.position.startDate = util.formatDateForRequestDateTime(
        this.position.startDate
      )
      this.position.endDate = util.formatDateForRequestDateTime(
        this.position.endDate
      )
      if (this.position.executor === 'Soli Personal') {
        this.position.subcontractorId = null
      }
      if (this.position.executor === 'Nachunternehmer') {
        this.position.team = null
      }
      positionsApi.update(
        (response) => {
          this.$emit('update', response)
          this.$message({
            type: 'success',
            message: 'Updated',
            duration: 3000,
          })
          this.originalPosition = JSON.parse(JSON.stringify(this.position))
        },
        (error) => {
          this.loading = false
          this.$message({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.position.id,
        {
          comments: this.position.comments,
          project_id: this.position.projectId,
          pricing_category_id: this.position.pricingCategoryId,
          pricing_subcategory_id: this.position.pricingSubcategoryId,
          street_id: this.position.streetId,
          muffe_id: this.position.muffeId,
          mfg_id: this.position.mfgId,
          street_start: this.position.streetStart,
          street_end: this.position.streetEnd,
          start_date: this.position.startDate,
          end_date: this.position.endDate,
          executor: this.position.executor,
          team: this.position.team,
          subcontractor_id: this.position.subcontractorId,
          user_id: this.position.userId,
          clear_position_items: this.clearPositionItems,
        }
      )
    },
  },
}
</script>
<style scoped>
.el-alert {
  margin: 20px 0;
}

.el-alert:first-child {
  margin: 0;
}

.el-form-item__content .el-tooltip {
  position: absolute;
  top: -1.5em;
  right: 0;
}

.el-form-item__content .el-radio-group + .el-tooltip {
  top: 0.85em;
}
.request-changes {
  margin-top:15px;
  margin-bottom:30px;

  .el-alert {
    margin-bottom:15px;
  }
}
</style>
