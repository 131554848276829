import ApiResource from '../ApiResource'

class ProjectAreaResource extends ApiResource {
  constructor() {
    super({
      name: 'projects.areas',
    })
  }
}

const projectAreasApi = new ProjectAreaResource()

export default projectAreasApi
