<template>
  <el-dialog
    :visible.sync="visible"
    title="Edit team"
    :before-close="notifyParent"
  >
    <default-form
      :form="form"
      :errors="formErrors"
      :loading="loading"
      @submit="editTeam"
    />
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import teamsApi from 'api/v2/teams'
import DefaultForm from 'components/Common/Form/DefaultForm'

export default {
  name: 'DialogEditTeam',

  components: {
    DefaultForm,
  },

  props: {
    team: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      visible: true,
      loading: false,
      formErrors: null,
    }
  },

  computed: {
    ...mapGetters('global', ['roles']),

    formItems() {
      return [
        {
          label: 'Name',
          prop: 'name',
          required: true,
          rules: [],
        },
      ]
    },

    initialModel() {
      return this.team
    },

    form() {
      return {
        items: this.formItems,
        buttons: [
          {
            event: 'submit',
            label: 'Save changes',
            type: 'primary',
          },
        ],
        initialModel: this.initialModel,
        ref: 'editTeamForm',
      }
    },
  },

  methods: {
    notifyParent(done) {
      done()
      this.$parent.$emit('cancelEditItem')
    },

    editTeam(model) {
      this.loading = true
      teamsApi.update(
        () => {
          this.$message.success({
            showClose: true,
            message: 'Team has been updated.',
            duration: 5000,
          })
          this.visible = false
          this.loading = false
          this.$parent.$emit('changedEditItem')
        },
        (error) => {
          this.formErrors = error
          this.loading = false
          Vue.nextTick(() => {
            this.formErrors = null
          })
        },
        this.team.id,
        model
      )
    },
  },
}
</script>

<style lang="scss"></style>
