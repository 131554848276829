var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticClass:"position-edit-item-dialog",attrs:{"close-on-click-modal":false,"visible":_vm.visible,"title":"Add Item","before-close":_vm.close},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-form',{attrs:{"label-width":"120px","label-position":"top"}},[_c('el-form-item',{ref:"comments",attrs:{"label":"Comments"}},[_c('el-input',{attrs:{"type":"textarea","required":true},model:{value:(_vm.newItem.comments),callback:function ($$v) {_vm.$set(_vm.newItem, "comments", $$v)},expression:"newItem.comments"}})],1),_vm._v(" "),_c('el-form-item',{ref:"position",attrs:{"label":"Pricing item"}},[_c('el-select',{staticClass:"position-select",attrs:{"placeholder":"Please select...","filterable":"","return-object":""},on:{"change":_vm.itemChanged},model:{value:(_vm.newItem.pricingItem),callback:function ($$v) {_vm.$set(_vm.newItem, "pricingItem", $$v)},expression:"newItem.pricingItem"}},_vm._l((_vm.pricingItemOptions),function(option){return _c('el-option',{key:option.id,attrs:{"label":option.description,"value":option.id}})}),1)],1),_vm._v(" "),_c('el-form-item',{staticClass:"measurements",attrs:{"label":"Measurements"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":5}},[(
              !_vm.newItem.pricingItem ||
              ['M', 'M2', 'M3'].indexOf(_vm.newItem.unit) !== -1
            )?_c('el-input',{attrs:{"required":true},model:{value:(_vm.newItem.length),callback:function ($$v) {_vm.$set(_vm.newItem, "length", $$v)},expression:"newItem.length"}},[_c('template',{slot:"prepend"},[_vm._v("Length")]),_vm._v(" "),_c('template',{slot:"append"},[_vm._v("M")])],2):_vm._e()],1),_vm._v(" "),_c('el-col',{attrs:{"span":5}},[(
              !_vm.newItem.pricingItem ||
              ['M2', 'M3'].indexOf(_vm.newItem.unit) !== -1
            )?_c('el-input',{attrs:{"required":true,"prop":"newItem.width"},model:{value:(_vm.newItem.width),callback:function ($$v) {_vm.$set(_vm.newItem, "width", $$v)},expression:"newItem.width"}},[_c('template',{slot:"prepend"},[_vm._v("Width")]),_vm._v(" "),_c('template',{slot:"append"},[_vm._v("M")])],2):_vm._e()],1),_vm._v(" "),_c('el-col',{attrs:{"span":5}},[(!_vm.newItem.pricingItem || _vm.newItem.unit === 'M3')?_c('el-input',{attrs:{"required":true},model:{value:(_vm.newItem.height),callback:function ($$v) {_vm.$set(_vm.newItem, "height", $$v)},expression:"newItem.height"}},[_c('template',{slot:"prepend"},[_vm._v("Height")]),_vm._v(" "),_c('template',{slot:"append"},[_vm._v("M")])],2):_vm._e()],1),_vm._v(" "),_c('el-col',{attrs:{"span":5}},[(!_vm.newItem || _vm.newItem.unit === 'ST' || _vm.newItem.unit === 'STD')?_c('el-input',{attrs:{"required":true},model:{value:(_vm.newItem.amount),callback:function ($$v) {_vm.$set(_vm.newItem, "amount", $$v)},expression:"newItem.amount"}},[_c('template',{slot:"prepend"},[_vm._v(_vm._s(_vm.newItem && _vm.newItem.unit === 'STD' ? 'Hours' : 'Amount'))])],2):_vm._e()],1)],1)],1),_vm._v(" "),_c('el-form-item',[_c('el-button',{attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("\n        Create\n      ")]),_vm._v(" "),_c('el-button',{attrs:{"type":"warning"},on:{"click":_vm.close}},[_vm._v(" Cancel ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }