<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>Pricing template for: {{ templateName }}</span>
    </div>

    <div v-if="importScreen" class="column">
      <span
      >Pricing has not yet been setup. You can import one of the templates
        below, or start adding items manually</span
      >
      <div>
        <el-button
          size="small"
          type="primary"
          @click="createEmptyProjectPricingTemplate"
        >
          Add Items Manually
        </el-button>
      </div>

      <template v-if="subcontractor">
        <h2>Subcontractor Pricing Templates</h2>
        <div>
          <el-radio-group
            v-model="selectedSubcontractorTemplateName"
            class="column"
          >
            <el-radio
              v-for="template in subcontractorTemplates"
              :key="template.id"
              :label="template.name"
            >
            </el-radio>
          </el-radio-group>
          <div>
            <el-button
              :disabled="!selectedSubcontractorTemplateId"
              size="small"
              type="primary"
              @click="importSubcontractorTemplate"
            >
              Import selected subcontractor template
            </el-button>
            <!-- No subcontractor specific templates available -->
          </div>
        </div>
      </template>

      <h2>Global Pricing Templates</h2>
      <div class="column">
        <el-radio-group v-model="selectedInternalTemplateName" class="column">
          <el-radio
            v-for="template in globalTemplates"
            :key="template.id"
            :label="template.name"
          >
          </el-radio>
        </el-radio-group>
      </div>
      <div>
        <el-button
          :disabled="!selectedInternalTemplateId"
          size="small"
          type="primary"
          @click="importGlobalTemplate"
        >
          Import selected global template
        </el-button>
      </div>
    </div>
  </el-card>
</template>

<script>
import pricingTemplatesApi from 'api/v2/pricingTemplates'
import {formatErrorMessage, logErrorMessage} from '../../../../../common/util';

export default {
  name: 'SetupPricingTemplate',

  props: {
    project: {
      type: Object,
      required: true,
    },
    subcontractor: {
      type: Object,
      required: false,
      default() {
        return undefined
      },
    },
  },

  data() {
    return {
      pricingTemplates: [],
      selectedInternalTemplateName: null,
      selectedSubcontractorTemplateName: null,
      importScreen: true,
    }
  },

  computed: {
    selectedInternalTemplateId() {
      if (!this.selectedInternalTemplateName) {
        return null
      }
      const template = this.globalTemplates.find(
        (template) => template.name === this.selectedInternalTemplateName
      )
      return template ? template.id : null
    },
    selectedSubcontractorTemplateId() {
      if (!this.selectedSubcontractorTemplateName) {
        return null
      }
      const template = this.subcontractorTemplates.find(
        (template) => template.name === this.selectedSubcontractorTemplateName
      )
      return template ? template.id : null
    },
    templateName() {
      if (this.subcontractor) {
        return this.subcontractor.name
      } else {
        return 'Internal'
      }
    },
    globalTemplates() {
      return this.pricingTemplates.filter(
        (pricingTemplate) =>
          pricingTemplate.project === null &&
          pricingTemplate.subcontractor === null
      )
    },
    subcontractorTemplates() {
      if (!this.subcontractor) return []
      return this.pricingTemplates.filter(
        (pricingTemplate) =>
          pricingTemplate.project === null &&
          pricingTemplate.subcontractor &&
          pricingTemplate.subcontractor.id === this.subcontractor.id
      )
    },
  },

  mounted() {
    this.fetchPricingTemplates()
  },

  methods: {
    fetchPricingTemplates() {
      pricingTemplatesApi.index(
        (response) => {
          if (response.length) {
            this.pricingTemplates = response
          }
        },
        (error) => {
          logErrorMessage(error)
        },
        {include: ['project', 'subcontractor']}
      )
    },
    fetchProjectPricingTemplate() {
      pricingTemplatesApi.index(
        (response) => {
          if (response.length === 1) {
            this.$emit('template-fetched', response[0])
            return
          }
          if (response.length > 1) {
            this.$message.error({
              showClose: true,
              message:
                'More than 1 pricing template found for this project, this should not happen!',
              duration: 10000,
            })
            this.$emit('template-fetched', response[0])
            return
          }
          this.createEmptyProjectPricingTemplate()
        },
        (error) => {
          logErrorMessage(error)
        },
        {
          project_id: this.project.id,
          subcontractor_id: this.subcontractor ? this.subcontractor.id : null,
        }
      )
    },
    createEmptyProjectPricingTemplate() {
      pricingTemplatesApi.store(
        (response) => {
          this.$emit('template-created', response.id)
        },
        (error) => {
          logErrorMessage(error)
        },
        {
          project_id: this.project.id,
          subcontractor_id: this.subcontractor ? this.subcontractor.id : null,
          name: this.subcontractor
            ? this.project.name
            : `${this.project.name} (internal)`,
        }
      )
    },
    importGlobalTemplate() {
      this.importTemplate(this.selectedInternalTemplateId)
    },
    importSubcontractorTemplate() {
      this.importTemplate(this.selectedSubcontractorTemplateId)
    },
    importTemplate(pricingTemplateId) {
      pricingTemplatesApi.copyToProject(
        () => {
          this.fetchProjectPricingTemplate()
          this.$message.success({
            showClose: true,
            message: 'Template imported',
            duration: 5000,
          })
        },
        (error) => {
          logErrorMessage(error)
          this.$message.error({
            showClose: true,
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        pricingTemplateId,
        {
          project_id: this.project.id,
          subcontractor_id: this.subcontractor ? this.subcontractor.id : null,
        }
      )
    },
  },
}
</script>

<style lang="scss" scoped>
h2 {
  font-size: 16px;
  margin: 20px 0 5px 0;
}

.column {
  display: flex;
  flex-direction: column;
}

::v-deep.el-radio {
  margin-left: 0px;
  line-height: 30px;
}

.el-button {
  margin-top: 10px;
}
</style>
