import ApiResource from '../ApiResource'

class ProjectPurchaseInvoiceMetaFieldResource extends ApiResource {
  constructor() {
    super({
      name: 'projects.purchase-invoice.meta-fields',
    })
  }
}

const projectPurchaseInvoiceMetaFieldsApi = new ProjectPurchaseInvoiceMetaFieldResource()

export default projectPurchaseInvoiceMetaFieldsApi
