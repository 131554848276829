<template>
  <div class="table-position-items">
    <el-table
      v-loading.body="loading"
      :data="sortedItems"
      sum-text="Sum"
      size="mini"
      :highlight-current-row="true"
      stripe
      :default-sort="{ prop: 'position_item_id', order: 'ascending' }"
      @sort-change="(val) => $emit('sort-change', val)"
      @filter-change="(val) => $emit('filter-change', val)"
    >
      <el-table-column label="Description" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.description }}
          <template v-if="scope.row.comments">
            | {{ scope.row.comments }}
          </template>
        </template>
      </el-table-column>

      <template v-if="position.subcontractorId">
        <el-table-column
          prop="missing_subcontractor_pricing_item"
          label="Missing Sub. Pricing"
          width="150"
          align="right"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="not_purchase_invoicable"
          label="Not P. Invoicable"
          width="120"
          align="right"
          :show-overflow-tooltip="true"
        />
      </template>

      <el-table-column
        prop="price"
        label="Price"
        width="70"
        align="right"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="amount"
        label="Amount"
        width="100"
        align="right"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="length"
        label="Length"
        width="100"
        align="right"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="width"
        label="Width"
        width="100"
        align="right"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="height"
        label="Height"
        width="100"
        align="right"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="factor"
        label="Factor"
        width="100"
        align="right"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        fixed="right"
        label="Operations"
        width="140"
        align="right"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">

          <template v-if="scope.row.missing_soli_reference">
            <el-button
              size="mini"
              icon="el-icon el-icon-warning"
            >
            </el-button>
          </template>
          <template
            v-if="isSuperAdmin || !position.executorPositionValidatedAt"
          >
            <el-button
              size="mini"
              icon="el-icon el-icon-edit"
              @click.native.prevent="editItem(scope.$index)"
            >
            </el-button>
            <el-button
              size="mini"
              icon="el-icon el-icon-delete"
              type="danger"
              @click.native.prevent="removeItem(scope.$index)"
            >
            </el-button>
          </template>
          <template v-else>
            <el-button
              v-if="!sendNotificationResponse"
              plain
              size="mini"
              title="Items from validated positions cannot be changed"
              @click="notifyModal"
            >
              Request change
            </el-button>
            <span v-else>
              {{sendNotificationResponse}}
            </span>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <SendDialog
      v-if="showSendNotification"
      ref="createNotificationRequest"
      subject=""
      message=""
      @close="onNotificationDialogClose"
      @sent="onNotificationDialogSent"
    />

  </div>
</template>

<script>
import numeral from 'numeral'
import SendDialog from '@/js/components/Notifications/SendDialog.vue';

export default {
  name: 'PositionItemsTable',
  components: {SendDialog},

  props: {
    items: {
      type: Array,
      default: () => []
    },
    position: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sendNotificationMessage: '',
      sendNotificationResponse: null,
    }
  },
  computed: {
    // tries to sort the items similar as to the sort of the project report
    sortedItems() {
      let sortedItems = this.items
      return sortedItems
        .sort((a, b) => {
          // sort by id
          if (a.positionItemId > b.positionItemId) return 1
          if (a.positionItemId < b.positionItemId) return -1
          return 0
        })
        .sort((a, b) => {
          // sort by creation date of original item
          // console.log(a)
          if (!a.positionItem || !b.positionItem) return 0
          if (a.positionItem.created_at > b.positionItem.created_at) return -1
          if (a.positionItem.created_at < b.positionItem.created_at) return 1
          // console.log('not sorting a.id')
          return 0
        })
    },
    isSuperAdmin() {
      return this.$store.getters['global/user']['role'] === 'super_admin'
    },
    showSendNotification() {
      return !this.isSuperAdmin && this.position.executorPositionValidatedAt
    },
    sendNotificationSubject() {
      let parts = [];
      parts.push('Request items change on locked position')
      if(this.position) {
        parts.push(`Position: # ${this.position.id}`)
        parts.push(`Project: ${this.position.project.name}`)
      }
      return parts.join(', ')
    },
  },

  methods: {
    editItem(index) {
      this.$emit('editItem', index)
    },

    removeItem(index) {
      this.$emit('removeItem', index)
    },
    formatNumber(number) {
      return numeral(number).format()
    },
    formatPrice(number) {
      return number
    },
    notifyModal() {
      this.$refs.createNotificationRequest.openNotificationDialog(
        this.sendNotificationSubject,
        this.sendNotificationMessage,
        {
          message: 'Reason for the change?'
        }
      )
    },
    onNotificationDialogClose() {
      this.sendNotificationResponse = null
    },
    onNotificationDialogSent() {
      this.sendNotificationResponse = 'Change requested.'
    },
  },
}
</script>

<style lang="scss">
.table-position-items {
  .el-table {
    width: 100%;
  }

  .el-table td,
  .el-table th {
    height: 30px;

    div.cell {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>
