import ApiResource from '../ApiResource'

class TeamResource extends ApiResource {
  constructor() {
    super({
      name: 'teams',
    })
  }

  downloadCSV(resolve, reject) {
    this.ids = []
    this.post(`${this.getUrl()}/download`, { format: 'csv' })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const teamsApi = new TeamResource()

export default teamsApi
