export default {
  items: [],

  streets: [],
  mfgs: [],
  muffen: [],

  projectId: null,
  selectedArea: null,

  loading: false,
  errored: false,
}
