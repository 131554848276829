<template>
  <el-form
    v-loading="loading"
    :inline="true"
    class="form-box claim-email-controls"
    label-width="120px"
  >
    <el-row>
      <el-col :span="2"> From: </el-col>
      <el-col :span="22">
        <el-form-item ref="from_name">
          <el-input
            v-model="from_name"
            placeholder="From name"
            :required="true"
          />
        </el-form-item>

        <el-form-item ref="from_email">
          <el-input
            v-model="from_email"
            placeholder="From email"
            :required="true"
          />
        </el-form-item>
      </el-col>

      <el-col :span="2"> Cc: </el-col>
      <el-col :span="22">
        <div
          v-for="(ccContact, i) in ccContacts"
          :key="i"
        >
          <el-form-item ref="cc_name">
            <el-input
              v-model="ccContact.name"
              placeholder="CC name"
              :required="true"
            />
          </el-form-item>

          <el-form-item ref="cc_email">
            <el-input
              v-model="ccContact.email"
              placeholder="CC email"
              :required="true"
            />
          </el-form-item>

          <el-button type="danger" @click="removeCC(i)"> Remove </el-button>
          <br />
        </div>
        <el-button type="primary" @click="addCC"> Add </el-button>
      </el-col>

      <el-col :span="2"> To: </el-col>
      <el-col :span="22">
        <el-form-item>
          <el-button-group>
            <el-button type="primary" @click="setProjectLeader">
              Project leader
            </el-button>
            <el-button type="primary" @click="setExternal">
              External
            </el-button>
          </el-button-group>
        </el-form-item>

        <el-form-item ref="to_name">
          <el-input v-model="to_name" placeholder="To name" :required="true" />
        </el-form-item>

        <el-form-item ref="to_email">
          <el-input
            v-model="to_email"
            placeholder="To email"
            :required="true"
          />
        </el-form-item>
      </el-col>

      <el-col :span="2"> &nbsp; </el-col>
      <el-col :span="22">
        <el-form-item style="display: inline-block">
          <el-button type="primary" @click="onSubmit"> Send </el-button>
          <el-button type="primary" @click="onReload"> Reload </el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ClaimEmailControls',

  props: {
    loading: Boolean,
    claim: {
      type: Object,
      default: null,
    },
    errors: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      from_name: null,
      from_email: null,
      to_name: null,
      to_email: null,
      ccContacts: [
        {
          name: '',
          email: '',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('global', ['user']),

    project() {
      if (this.claim) {
        return this.$store.getters['projects/getProjectById'](
          this.claim.projectId
        )
      }

      return null
    },
  },
  watch: {
    project() {
      this.setExternal()
    },

    errors(error) {
      if (error !== null) {
        let errorMessage

        if (error && error.message) {
          errorMessage = error.message
        } else {
          errorMessage = 'A validation error occured'

          // Let's set the errors
          Object.entries(error).forEach(([key, value]) => {
            this.$refs[key].error = value[0]
          })
        }

        this.$message.error({
          showClose: true,
          message: errorMessage,
          duration: 10000,
        })
      }
    },
  },
  mounted() {
    this.from_name = this.user.name
    this.from_email = this.user.email
  },
  methods: {
    setProjectLeader() {
      this.to_name = this.project.projectLeadContact.fullname
      this.to_email = this.project.projectLeadContact.email
    },

    setExternal() {
      this.to_name = this.project.externalCustomerContactName
      this.to_email = this.project.externalCustomerContactEmail
    },

    onSubmit() {
      this.$emit('send', {
        from_name: this.from_name,
        from_email: this.from_email,
        to_name: this.to_name,
        to_email: this.to_email,
        cc_contacts: this.nonEmptyCCContacts(),
      })
    },

    onReload() {
      this.$emit('reload')
    },

    addCC() {
      this.ccContacts.push({
        name: '',
        email: '',
      })
    },

    removeCC(i) {
      this.ccContacts.splice(i, 1)
    },

    nonEmptyCCContacts() {
      return this.ccContacts.filter((item) => item.email.length > 0)
    },
  },
}
</script>

<style lang="scss">
.claim-email-controls {
  .el-button {
    margin-bottom: 22px;
  }
}
</style>
