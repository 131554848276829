<template>
  <ProjectReportTable />
</template>

<script>
import ProjectReportTable from 'components/Items/ProjectReportTable'

export default {
  name: 'ProjectReport',

  components: {
    ProjectReportTable,
  },
}
</script>

<style></style>
