import ApiResource from '../ApiResource'

class SalesInvoiceItemImportResource extends ApiResource {
  constructor() {
    super({
      name: 'sales-invoice-import',
    })
  }
}

const salesInvoiceItemImportApi = new SalesInvoiceItemImportResource()

export default salesInvoiceItemImportApi
