import ApiResource from '../ApiResource'

class PositionItemResource extends ApiResource {
  constructor() {
    super({
      name: 'positions.items',
    })
  }

  updateInvoiceStatus(
    resolve,
    reject,
    positionId,
    positionItemId,
    invoiceStatus
  ) {
    this.ids = [positionId, positionItemId]

    this.put(`${this.getUrl()}/invoice`, { invoiceStatus })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const positionItemApi = new PositionItemResource()

export default positionItemApi
