<template>
  <div>
    <el-card id="template-details" v-loading="loading" class="box-card">
      <div slot="header" class="clearfix header">
        <span>Pricing template for: {{ templateName }}</span>
      </div>
      <!-- SUBSCONTRACTOR INFO ACTIONS -->
      <div v-if="pricingTemplate">
        <table class="table-borderless">
          <tr>
            <td>Template:</td>
            <td>
              {{ pricingTemplate.id }}: {{ pricingTemplate.name }}
              <template v-if="pricingTemplate?.project?.name && pricingTemplate.subcontractorId">
                {{pricingTemplate?.project.name}}
              </template>
            </td>
            <td>
              <template v-if="pricingTemplate.parentPricingTemplate">
                <el-tag :hit="true" :type="getSyncStatus(pricingTemplate).type">
                  {{ getSyncStatus(pricingTemplate).text }}
                </el-tag>
                <el-button
                  :disabled="getSyncStatus(pricingTemplate).text === 'Up-to-date'"
                  size="mini"
                  type="secondary"
                  @click="sync(pricingTemplate)"
                >
                  Sync
                </el-button>
              </template>
            </td>
          </tr>
          <tr>
            <td>Parent template:</td>
            <td>
              {{
                pricingTemplate.parentPricingTemplate
                  ? `${pricingTemplate.parentPricingTemplate.id}: ${pricingTemplate.parentPricingTemplate.name}`
                  : 'not set'
              }}
              <el-button
                class="inline-action-button"
                icon="el-icon el-icon-edit"
                size="mini"
                @click="onEditParent()"
              />
            </td>
            <td/>
          </tr>
          <tr
            v-if="pricingTemplate.internalPricingTemplate || (!pricingTemplate.internalPricingTemplate && (pricingTemplate.subcontractorId && !pricingTemplate.projectId))">
            <td>Linked Soli template:</td>
            <td>
              <template v-if="pricingTemplate.internalPricingTemplate">
                {{ pricingTemplate.internalPricingTemplate.id }}:
                {{ pricingTemplate.internalPricingTemplate.name }}
              </template>
            </td>
            <td>
              <el-tag
                :hit="true"
                :type="isInternalRefencesSynced ? 'success' : 'danger'"
              >
                {{ isInternalRefencesSynced ? 'synced' : 'outdated' }}
              </el-tag>
              <el-button
                size="mini"
                type="secondary"
                @click="syncInternalReferences(pricingTemplate)"
              >
                Sync Soli references...
              </el-button>

              {{ internalReferencesSyncProgress }}

            </td>
          </tr>
        </table>
      </div>
      <!-- SELECT PARENT TEMPLATE MODAL -->
      <select-template-modal
        v-if="selectTemplateModalVisible"
        :edit-item="pricingTemplateEditing"
        :template-options="parentablePricingTemplates"
        :visible.sync="selectTemplateModalVisible"
        @updated="fetchAll"
      />
      <!-- TEMPLATE ACTIONS  -->
      <div style="display: flex; justify-content: flex-end; align-items: center">
        <div style=" margin-right: auto;">
          <strong style="font-size: 14px;margin-left: 1px">Show hidden items</strong>
          <el-switch v-model="showHiddenItems"/>
        </div>

        <el-button
          v-if="isSuperAdmin && pricingTemplate?.isSubcontractorTemplate && pricingTemplate?.isProjectTemplate"
          size="small"
          type="default"
          @click="fixPositionItems"
        >
          Fix Position Items
        </el-button>
        <el-button size="small" type="primary" @click="$refs.fileInput.click()">
          Import .xlsx
        </el-button>
        <el-button size="small" type="primary" @click="exportTemplate">
          Export .xlsx
        </el-button>
        <el-button
          size="small"
          type="danger"
          :disabled="pricingTemplatePositionData?.hasPositions"
          :title="pricingTemplatePositionData?.hasPositions ? 'Subcontractor have Positions on Project and the Template cannot be deleted.' : ''"
          @click="deletePricingTemplate"
        >
          Delete
        </el-button>
        <input
          ref="fileInput"
          class="file-input"
          type="file"
          @input="importTemplate"
        />
      </div>

      <div v-if="pricingTemplate" id="template-header">
        <el-tabs v-model="activeAreaSelection">
          <el-tab-pane label="Street" name="street">
            <PricingTemplateCategories
              :pricing-categories="streetCategories"
              :pricing-template="pricingTemplate"
              :subcontractor="subcontractor"
              :options="{showHiddenItems: showHiddenItems}"
              area-selection="street"
              @deleteCategory="deleteCategory"
              @deletePricingItem="deletePricingItem"
              @updateCategory="updateCategory"
              @item-added="handleItemAdded"
              @item-updated="handleItemUpdated"
              @open-mapping-status="showMappingStatus"
            />
          </el-tab-pane>

          <el-tab-pane label="MFG" name="mfg">
            <PricingTemplateCategories
              :pricing-categories="mfgCategories"
              :pricing-template="pricingTemplate"
              :subcontractor="subcontractor"
              :options="{showHiddenItems: showHiddenItems}"
              area-selection="mfg"
              @deleteCategory="deleteCategory"
              @deletePricingItem="deletePricingItem"
              @updateCategory="updateCategory"
              @item-added="handleItemAdded"
              @item-updated="handleItemUpdated"
              @open-mapping-status="showMappingStatus"
            />
          </el-tab-pane>

          <el-tab-pane label="Muffe" name="muffe">
            <PricingTemplateCategories
              :pricing-categories="muffeCategories"
              :pricing-template="pricingTemplate"
              :subcontractor="subcontractor"
              :options="{showHiddenItems: showHiddenItems}"
              area-selection="muffe"
              @deleteCategory="deleteCategory"
              @deletePricingItem="deletePricingItem"
              @updateCategory="updateCategory"
              @item-added="handleItemAdded"
              @item-updated="handleItemUpdated"
              @open-mapping-status="showMappingStatus"
            />
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="add-category">
        <el-input
          v-model="newCategory"
          placeholder="new category"
          size="small"
          @keyup.enter.native="addCategory"
        />
        <el-button size="small" type="success" @click="addCategory">
          Add Category
        </el-button>
      </div>
    </el-card>
    <SendDialog ref="createNotification"/>

    <el-dialog
      ref="mappingStatusDialog"
      :visible.sync="mappingStatusDialogVisible"
      :title="`Mapping Status:
      ${mappingStatusDialogData?.pricingTemplate?.name}
      ${mappingStatusDialogData?.pricingTemplate?.project?.name}
      ${mappingStatusDialogData?.pricingItem?.reference}`"
    >

      <table class="default">
        <tr>
          <th colspan="2">Template</th>
        </tr>
        <tr>
          <td>Template</td>
          <td>{{ mappingStatusDialogData?.pricingTemplate?.name }}</td>
        </tr>
        <tr>
          <td>Project</td>
          <td>{{ mappingStatusDialogData?.pricingTemplate?.project?.name }}</td>
        </tr>
        <tr>
          <th colspan="2">Pricing Item</th>
        </tr>
        <tr>
          <td>Reference</td>
          <td>{{ mappingStatusDialogData?.pricingItem?.reference }}</td>
        </tr>
        <tr>
          <td>Soli Reference</td>
          <td>{{ mappingStatusDialogData?.pricingItem?.internalReference }}</td>
        </tr>
        <tr>
          <td>Description</td>
          <td>{{ mappingStatusDialogData?.pricingItem?.description }}</td>
        </tr>
        <tr>
          <td>Unit</td>
          <td>{{ mappingStatusDialogData?.pricingItem?.unit }}</td>
        </tr>
        <tr>
          <td>Price</td>
          <td>{{ mappingStatusDialogData?.pricingItem?.price }}</td>
        </tr>
        <tr>
          <td>Local Fixed</td>
          <td>{{ mappingStatusDialogData?.pricingItem?.localFixed ? 'fixed' : '' }}</td>
        </tr>
        <tr>
          <td>Not Invoiceable</td>
          <td>{{ mappingStatusDialogData?.pricingItem?.notPurchaseInvoicable ? 'Yes' : '' }}</td>
        </tr>
        <tr>
          <td>PlanIndex</td>
          <td>{{ mappingStatusDialogData?.pricingItem?.planIndex }}</td>
        </tr>
        <tr>
          <td>BonusIndex</td>
          <td>{{ mappingStatusDialogData?.pricingItem?.bonusIndex }}</td>
        </tr>
        <tr>
          <td>Artindex_Prognose_mTB</td>
          <td>{{ mappingStatusDialogData?.pricingItem?.prognoseMtbArtIndex }}</td>
        </tr>
        <tr>
          <th colspan="2">Mapping Status</th>
        </tr>
        <tr>
          <td colspan="2">{{ mappingStatusDialogData?.pricingItem?.mappingStatus }}</td>
        </tr>
      </table>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :disabled="loading" @click="openSendDialog">Notify</el-button>
        <el-button type="danger" :disabled="loading" @click="mappingStatusDialogVisible=false; mappingStatusDialogData=null;">Close</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {cloneDeep} from 'lodash-es'
import snakeCaseKeys from 'snakecase-keys'
import PricingTemplateCategories from '@/js/components/PricingTemplate/PricingTemplateCategories.vue';
import pricingTemplatesApi from 'api/v2/pricingTemplates'
import pricingTemplateCategoriesApi from 'api/v2/pricingTemplateCategories'
import pricingTemplateItemsApi from 'api/v2/pricingTemplateItems'
import {getSyncStatus} from 'common/templateUtils'
import pricingSyncApi from 'api/v2/pricingSync'
import SelectTemplateModal from '../Views/Pricing/Sync/SelectTemplateModal'
import {logErrorMessage} from '../../common/util';
import SendDialog from '@/js/components/Notifications/SendDialog.vue';

export default {
  name: 'PricingTemplate',
  components: {
    SendDialog,
    PricingTemplateCategories,
    SelectTemplateModal,
  },
  props: {
    pricingTemplateId: {
      type: Number,
      required: true,
    },
    subcontractor: {
      type: Object,
      required: false,
      default() {
        return undefined
      },
    },
    project: {
      type: Object,
      required: false,
      default() {
        return undefined
      },
    },
  },
  data() {
    return {
      selectTemplateModalVisible: false,
      pricingTemplateEditing: null,
      selectedParentTemplateId: null,
      parentablePricingTemplates: [],
      activeAreaSelection: 'street',
      pricingTemplate: null,
      pricingCategories: [],
      pricingItems: [],
      newCategory: '',
      fetchingPricingTemplate: true,
      fetchingPricingTemplateCategories: true,
      fetchingPricingTemplateItems: true,
      fixingPositionItems: false,
      isInternalRefencesSynced: null,
      internalReferencesSyncProgress: null,
      pricingTemplatePositionData: null,
      mappingStatusDialogVisible: false,
      mappingStatusDialogData: null,
      showHiddenItems:true,
    }
  },

  computed: {
    loading() {
      return (
        this.fetchingPricingTemplate ||
        this.fetchingPricingTemplateCategories ||
        this.fetchingPricingTemplateItems ||
        this.fixingPositionItems
      )
    },
    categoriesWithItems() {
      if (!this.pricingCategories.length) {
        return []
      }
      const categories = cloneDeep(this.pricingCategories)
      this.pricingItems.forEach((pricingItem) => {
        const category = categories.find(
          (category) => category.id === pricingItem.pricingCategoryId
        )
        if (!category) {
          // eslint-disable-next-line
          console.warn(
            'Got item without category %s',
            pricingItem.pricingCategoryId
          )
          return
        }
        if (!category.items) {
          category.items = []
        }
        category.items.push(pricingItem)
      })
      return categories
    },
    streetCategories() {
      if (!this.categoriesWithItems.length) {
        return
      }
      return this.categoriesWithItems.filter(
        (category) => category.areaSelection === 'street'
      )
    },
    mfgCategories() {
      if (!this.categoriesWithItems.length) {
        return
      }
      return this.categoriesWithItems.filter(
        (category) => category.areaSelection === 'mfg'
      )
    },
    muffeCategories() {
      if (!this.categoriesWithItems.length) {
        return
      }
      return this.categoriesWithItems.filter(
        (category) => category.areaSelection === 'muffe'
      )
    },
    templateName() {
      if (this.subcontractor) {
        return this.subcontractor.name
      } else {
        return 'Internal'
      }
    },
    isSuperAdmin() {
      const user = this.$store.getters['global/user']
      return user.role === 'super_admin'
    },
    eventStreamForSync() {
      if (!this.$store.getters['global/eventStream']) {
        return null
      }
      return this.$store.getters['global/eventStream']['checkOrSyncInternalReferences_683'] ?? null
    }
  },
  watch: {
    eventStreamForSync(newCount) {
      //eslint-disable-next-line
      console.log(newCount)
      this.internalReferencesSyncProgress = newCount
    },
    subcontractor(value, oldValue) {
      if (value !== oldValue) {
        this.pricingTemplate = {}
        this.pricingCategories = []
        this.pricingItems = []
        this.fetchAll()
      }
    },
    pricingTemplateId(value, oldValue) {
      if (value !== oldValue) {
        this.pricingTemplate = {}
        this.pricingCategories = []
        this.pricingItems = []
        this.fetchAll()
      }
    },
  },

  mounted() {
    this.fetchAll()
    this.fetchParentablePricingTemplates()
  },

  methods: {
    showMappingStatus(data) {
      this.mappingStatusDialogVisible = true
      this.mappingStatusDialogData = data
    },
    openSendDialog() {

      let subject = []
      subject.push('Missing Soli Reference :')
      subject.push(this.mappingStatusDialogData?.pricingTemplate?.name)
      subject.push(this.mappingStatusDialogData?.pricingTemplate?.project?.name)
      subject.push('|')
      subject.push(this.mappingStatusDialogData?.pricingCategory?.areaSelection)
      subject.push('-')
      subject.push(this.mappingStatusDialogData?.pricingCategory?.name)
      subject.push('| REF:')
      subject.push(this.mappingStatusDialogData?.pricingItem?.reference)

      this.$refs.createNotification.openNotificationDialog(
        subject.join(' ')
      );
    },
    onEditParent() {
      this.pricingTemplateEditing = {
        parentTemplateId: this.pricingTemplate.parentId,
        parentTemplateName: this.pricingTemplate.parentPricingTemplate
          ? this.pricingTemplate.parentPricingTemplate.name
          : null,
        templateItemsCount: this.pricingItems.length,

        templateId: this.pricingTemplate.id,
        projectName: this.pricingTemplate.name,
        projectId: this.pricingTemplate.projectId,
      }
      this.selectTemplateModalVisible = true
    },
    getSyncStatus(item) {
      return getSyncStatus(item)
    },
    sync(item) {
      pricingSyncApi.store(
        () => {
          this.fetchAll()
        },
        (error) => {
          this.$message.error({
            message: error.message,
          })
        },
        {
          projectId: item.projectId,
          pricingTemplateId: item.id,
        }
      )
    },
    syncInternalReferences(pricingTemplate) {
      pricingSyncApi.syncInternalReferences(
        () => {
          this.fetchAll()
        },
        (error) => {
          this.$message.error({
            message: error.message,
          })
        },
        pricingTemplate.id
      )
    },
    fetchAll() {
      this.fetchPricingTemplate()
      this.fetchPricingTemplateCategories()
      this.fetchPricingTemplateItems()
    },
    fetchParentablePricingTemplates() {
      pricingTemplatesApi.index(
        (response) => {
          if (response.length) {
            this.parentablePricingTemplates = response.filter(
              (pt) =>
                pt.projectId === null &&
                pt.id !== this.pricingTemplate.id &&
                (pt.subcontractorId === this.pricingTemplate.subcontractorId ||
                  pt.subcontractorId === null)
            )
          }
        },
        (error) => {
          logErrorMessage(error)
        },
        {include: ['project', 'subcontractor']}
      )
    },
    fetchPricingTemplate() {
      this.fetchingPricingTemplate = true

      pricingTemplatesApi.show(
        (response) => {
          this.pricingTemplate = response
          this.fetchingPricingTemplate = false
          this.selectedParentTemplateId = this.pricingTemplate.parentId
          if (this.pricingTemplate.internalPricingTemplate) {
            this.fetchPricingTemplateCheckInternalReferences()
          } else if (!this.pricingTemplate.internalPricingTemplate && (this.pricingTemplate.subcontractorId && !this.pricingTemplate.projectId)) {
            this.fetchPricingTemplateCheckInternalReferences()
          }
          if (this.pricingTemplate?.isSubcontractorTemplate && this.pricingTemplate?.isProjectTemplate) {
            this.checkProjectPositions()
          } else {
            this.pricingTemplatePositionData = null
          }
        },
        (error) => {
          logErrorMessage(error)
        },
        this.pricingTemplateId
      )
    },
    fetchPricingTemplateCategories() {
      this.fetchingPricingTemplateCategories = true
      pricingTemplateCategoriesApi.index(
        (response) => {
          this.pricingCategories = response
          this.fetchingPricingTemplateCategories = false
        },
        (error) => {
          logErrorMessage(error)
        },
        this.pricingTemplateId
      )
    },
    fetchPricingTemplateItems() {
      this.fetchingPricingTemplateItems = true
      pricingTemplateItemsApi.index(
        (response) => {
          this.pricingItems = response
          this.fetchingPricingTemplateItems = false
        },
        (error) => {
          logErrorMessage(error)
        },
        this.pricingTemplateId
      )
    },
    fetchPricingTemplateCheckInternalReferences() {
      pricingSyncApi.checkInternalReferences(
        (response) => {
          const mutations =
            response.changes.items.added.length +
            response.changes.items.not_found_removed.length +
            response.changes.items.updated.length

          this.isInternalRefencesSynced = !mutations
        },
        (error) => {
          this.$message.error({
            message: error.message,
          })
        },
        this.pricingTemplateId
      )
    },
    deletePricingTemplate() {
      this.$confirm(
        'Do you want to permanently delete this template?',
        'Warning',
        {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'warning',
          customClass: 'confirm-delete',
          confirmButtonClass: 'el-button--danger',
        }
      ).then(() => {
        pricingTemplatesApi.delete(
          (response) => {
            if (response.deleted) {
              this.$emit('deleted')
            }
            if (response.deleted === false) {
              this.$message({
                showClose: true,
                type: 'error',
                message: 'Could not delete template, there are still items on it',
                duration: 3000,
              })
            }
          },
          (error) => {
            this.$message({
              showClose: true,
              type: 'error',
              message: `Could not delete template: ${error.message}`,
              duration: 3000,
            })
          },
          this.pricingTemplateId
        )
      })
    },
    async deletePricingItem(item, force) {
      pricingTemplateItemsApi.delete(
        (response) => {
          if (response.confirm) {
            this.$confirm(
              response.text +
              '\r\n' +
              `Do you want to permanently delete "${item.description}"?`,
              'Warning',
              {
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                type: 'warning',
                customClass: 'confirm-delete',
              }
            ).then(() => {
              this.deletePricingItem(item, true)
            })
          } else {
            this.pricingItems = this.pricingItems.filter(
              (pricingItem) => pricingItem.id !== item.id
            )
            this.$message.success({
              showClose: true,
              message: `${item.description} has been removed`,
              duration: 5000,
            })
          }
        },
        (error) => {
          this.$message({
            showClose: true,
            type: 'error',
            message: `Could not delete pricing item: ${error.message}`,
            duration: 3000,
          })
        },
        this.pricingTemplateId,
        item.id,
        {force}
      )
    },
    handleItemAdded(item) {
      this.pricingItems.unshift(item)
      this.handleTemplateUpdated()
    },
    handleItemUpdated(item) {
      const updateIndex = this.pricingItems.findIndex(
        (pricingItem) => pricingItem.id === item.id
      )
      this.$set(this.pricingItems, updateIndex, item)
      this.handleTemplateUpdated()
    },
    handleTemplateUpdated() {
      this.pricingTemplate.updatedAt = '9999-01-01T00:00:00.000000Z'
      if (this.pricingTemplate.internalPricingTemplate) {
        this.fetchPricingTemplateCheckInternalReferences()
      } else if (!this.pricingTemplate.internalPricingTemplate && (this.pricingTemplate.subcontractorId && !this.pricingTemplate.projectId)) {
        this.fetchPricingTemplateCheckInternalReferences()
      }
    },
    addCategory() {
      let addCategoryObject = {
        name: this.newCategory,
        cost_unit: null,
        area_selection: this.activeAreaSelection,
      }

      if (this.subcontractor) {
        addCategoryObject.subcontractor_id = this.subcontractor.id
      }

      pricingTemplateCategoriesApi.store(
        () => {
          this.newCategory = ''
          this.fetchPricingTemplateCategories()
          this.handleTemplateUpdated()
        },
        (error) => {
          this.$message({
            showClose: true,
            type: 'error',
            message: `Could not create category: ${error.message}`,
            duration: 3000,
          })
        },
        this.pricingTemplateId,
        {
          subcontractorId: this.subcontractor ? this.subcontractor.id : null,
          ...addCategoryObject,
        }
      )
    },
    deleteCategory(categoryId, force) {
      pricingTemplateCategoriesApi.delete(
        (response) => {
          if (response.confirm) {
            this.$confirm(response.text, {
              confirmButtonText: 'Delete',
              cancelButtonText: 'Cancel',
              type: 'warning',
              customClass: 'confirm-delete',
              confirmButtonClass: 'el-button--danger',
            })
              .then(() => {
                this.deleteCategory(categoryId, true)
              })
              .catch(() => {
              })
          } else {
            this.fetchPricingTemplateCategories()
            this.handleTemplateUpdated()
          }
        },
        (error) => {
          this.$message({
            showClose: true,
            type: 'error',
            message: `Could delete category: ${error.message}`,
            duration: 3000,
          })
        },
        this.pricingTemplateId,
        categoryId,
        {force}
      )
    },
    async updateCategory(name, costUnit, categoryId) {
      let updateCategoryObject = {
        name,
        subcontractor_id: this.subcontractor_id,
        area_selection: this.activeAreaSelection,
        cost_unit: costUnit,
      }
      pricingTemplateCategoriesApi.update(
        () => {
          this.fetchPricingTemplateCategories()
          this.handleTemplateUpdated()
        },
        (error) => {
          this.$message({
            showClose: true,
            type: 'error',
            message: `Could not update category: ${error.message}`,
            duration: 3000,
          })
        },
        this.pricingTemplateId,
        categoryId,
        snakeCaseKeys(updateCategoryObject)
      )
    },
    importTemplate(event) {
      const file = event.target.files[0]
      pricingTemplatesApi.import(
        () => {
          this.$refs.fileInput.value = ''
          this.fetchAll()
        },
        (error) => {
          this.$refs.fileInput.value = ''
          this.$message.error({
            showClose: true,
            type: 'error',
            message: error.message,
            duration: 0,
          })
        },
        this.pricingTemplateId,
        file
      )
    },
    exportTemplate() {
      pricingTemplatesApi.export(
        (response) => {
          // this.generatingExcelFile = false
          window.location = response
        },
        (error) => {
          // this.generatingExcelFile = false
          this.$message.error({
            showClose: true,
            type: 'error',
            message: error.message,
            duration: 0,
          })
        },
        this.pricingTemplateId
      )
    },
    checkProjectPositions() {
      pricingTemplatesApi.checkProjectPositions(
        (response) => {
          this.pricingTemplatePositionData = response
        },
        (error) => {
          this.$message.error({
            showClose: true,
            type: 'error',
            message: error.response?.data?.error ?? error.message,
            duration: 0,
          })
          this.pricingTemplatePositionData = null
        },
        this.pricingTemplate.id,
      )
    },
    fixPositionItems() {
      this.fixingPositionItems = true
      pricingTemplatesApi.fixMissingReferences(
        (response) => {
          this.fixingPositionItems = false
          if (response.totalCount === 0) {
            alert('No Position Items to fix')
            return
          }
          alert(`${response.updatedCount} / ${response.totalCount} was Updated`)
        },
        (error) => {
          this.fixingPositionItems = false
          this.$message.error({
            showClose: true,
            type: 'error',
            message: error.response?.data?.error ?? error.message,
            duration: 0,
          })
        },
        this.pricingTemplate.id,
      )
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__content {
  width: 100%;
  overflow-x: auto !important;

}

::v-deep .el-tab-pane {
  min-width: 1250px;
}

.file-input {
  display: none;
}

#template-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.el-tabs {
  width: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.table-borderless {
  width: 100%;

  tr {
    td {
      padding: 3px 5px 3px 0;
      font-size: 14px;

      &:first-child {
        font-weight: bold;
        width: 250px;
      }

      &:last-child {
        // width: 99%;
        text-align: right;
      }
    }
  }
}

.place-buttons {
  position: relative;
  z-index: 100;
  top: 54px;
  right: 10px;
}

.add-category {
  display: flex;
  align-items: center;
}

.add-category .el-input {
  width: 200px;
}

.add-category .el-button {
  margin: 10px 0px 10px 10px;
}

::v-deep .el-collapse-item__content {
  padding: 0px;
}

// I think you do not want to set this to a fixed width??
::v-deep .el-tabs__item {
  width: 100px;
}


table.default {
  width: 100%;

  tr {
    background: initial;

    th,
    td {
      text-align: initial;
      padding: 5px;
      background: initial;
    }

    th {
      font-size: 1.1em;
    }
  }

  tr:nth-child(odd) {
    background: #eef1f6;
  }
}


</style>
