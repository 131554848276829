<template>
  <el-dialog
    :visible.sync="visible"
    title="Add Claim"
    :before-close="close"
    :close-on-click-modal="false"
  >
    <el-form
      ref="form"
      label-width="120px"
      label-position="top"
      :model="item"
      :rules="rules"
    >
      <el-form-item
        v-if="!project"
        ref="project_id"
        label="Project"
        prop="project_id"
      >
        <el-select
          v-model="item.project_id"
          placeholder="Please select a project"
          @change="loadAreas"
        >
          <el-option
            v-for="(option_item, index) in items"
            :key="index"
            :label="option_item.name"
            :value="option_item.id"
          />
        </el-select>
      </el-form-item>

      <el-row :gutter="5">
        <el-col :span="8">
          <el-form-item label="Type" prop="claim_type">
            <el-select v-model="item.claim_type">
              <el-option
                v-for="claimType in claimTypes"
                :key="claimType.value"
                :label="claimType.label"
                :value="claimType.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="5">
        <el-col :span="12">
          <el-form-item ref="start_at" label="Date from" prop="start_at">
            <el-date-picker
              v-model="item.start_at"
              format="dd.MM.yyyy"
              type="date"
              placeholder="Pick a date"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Date to">
            <el-date-picker
              v-model="item.end_at"
              format="dd.MM.yyyy"
              type="date"
              placeholder="Pick a date"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="5">
        <el-col :span="12">
          <el-form-item ref="category" label="Category" prop="category">
            <el-input v-model="item.category" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item ref="area" label="Area" prop="area">
            <el-select v-model="item.area" placeholder="Please select an area">
              <el-option
                v-for="(option_item, index) in areas"
                :key="index"
                :label="option_item.name"
                :value="option_item.name"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Description">
        <el-input v-model="item.description" type="textarea" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit"> Create </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import * as util from 'common/util'
import projectAreasApi from 'api/v2/projectAreas'
import claimsApi from 'api/v2/claims'

export default {
  name: 'ClaimAddDialog',

  props: {
    project: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      visible: true,
      errors: null,
      item: {
        project_id: this.project ? this.project.id : null,
        start_at: moment().locale('de-DE').startOf('week'),
        end_at: moment().locale('de-DE').endOf('week').subtract(2, 'd'),
        area: null,
        claim_type: 'default',
      },
      areas: [],
      id: null,
      claimTypes: [
        {
          label: 'D-04',
          value: 'd04',
        },
        {
          label: 'Default',
          value: 'default',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('projects', ['items']),

    rules() {
      return {
        start_at: [
          {
            type: 'date',
            message: 'Date from is required',
            transform: (value) => (value ? new Date(value) : undefined),
          },
        ],
      }
    },
  },
  watch: {
    errors: function (error) {
      if (error !== null) {
        let errorMessage

        if (error && error.message) {
          errorMessage = error.message
        } else {
          errorMessage = 'A validation error occured'

          // Let's set the errors
          Object.entries(error).forEach(([key, value]) => {
            this.$refs[key].error = value[0]
          })
        }

        this.$message.error({
          showClose: true,
          message: errorMessage,
          duration: 10000,
        })
      }
    },
  },
  created() {
    if (this.project) {
      this.item.project_id = this.project.id
      this.loadAreas()
    } else {
      this.rules.project_id = [
        {
          required: true,
          message: 'Please select a project',
        },
      ]
    }
  },
  methods: {
    close(done) {
      this.$emit('cancel')
      if (done && typeof done === 'function') {
        done()
      }
    },
    loadAreas() {
      projectAreasApi.index(
        (response) => {
          this.areas = response

          if (!this.areas.length) {
            const ok = confirm('Please add areas before creating a claim.')

            if (ok) {
              this.$router.push({
                path: 'projects',
                name: 'projectTab',
                params: {
                  projectId: this.item.project_id,
                  tab: 'areas',
                },
              })
            }
          }
        },
        (error) => {
          this.loading = false
          // eslint-disable-next-line
          console.error(error)
        },
        this.item.project_id
      )
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let payload = Object.assign({}, this.item)
          payload.start_at = util.formatDateForRequest(this.item.start_at)
          payload.end_at = util.formatDateForRequest(this.item.end_at)

          claimsApi.store(
            (response) => {
              this.$message({
                type: 'success',
                message: 'Claim created',
                duration: 4000,
              })
              this.$router.push({
                name: 'projectClaim',
                params: {
                  projectId: response.projectId,
                  claimId: response.id,
                  tab: 'claims',
                },
              })
              this.close()
            },
            (error) => {
              this.loading = false
              this.errors = error
              // eslint-disable-next-line
              console.error(error)
            },
            payload
          )
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
