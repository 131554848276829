<template>
  <!-- Don't drop "elem-app" id -->
  <div id="elem-app">
    <router-view :logged-in="loggedIn" :loaded-initial="loadedInitial" />
  </div>
</template>

<script>
import apiAuth from 'api/auth'
import { mapGetters } from 'vuex'

import { AuthenticationError } from 'api/errors'

import '../scss/base.scss'
import app from '../../config/app';
import notificationsApi from '@/js/api/v2/notifications';

export default {
  name: 'App',

  data() {
    return {
      tokenRefreshTimeoutId: null,
      loadedInitial: false,
    }
  },

  computed: {
    ...mapGetters('auth', [
      'accessToken',
      'refreshToken',
      'expiration',
      'refreshWindow',
    ]),

    loggedIn() {
      return this.accessToken !== null
    },
  },

  watch: {
    loggedIn(nowLoggedIn) {
      if (nowLoggedIn) {
        this.fetchInitial()
      } else {
        // We were logged out
        // Since we don't want them to see the current view anymore, let's redirect them to the login page
        // Any message notifications should be handled by whatever caused us to be logged out
        this.$router.push('/login')

        // We discard most of this data, as we do not want to store it when logged out
        this.loadedInitial = false
      }
    },

    loadedInitial(newState, oldState) {
      if(newState && !oldState) {
        // Start the events data calls
        const config = this.$store.getters['configuration/data'] ?? {};
        if(config?.enable_event_stream) {
          const self = this
          const evtSource = new EventSource(`${app.api.baseUrl}event-stream/global`);
          evtSource.addEventListener('DB:EventStream/Global', function (e) {
            const eventData = JSON.parse(e.data);
            let currentStore = self.$store.getters['global/eventStream'] ?? {};
            currentStore = {...currentStore, ...eventData}
            self.$store.commit('global/eventStream', currentStore);
          });
        } else {
          this.unresolvedNotificationsUpdate();
        }
      }
    }
  },

  created() {
    if (this.tokenExpiration !== null) {
      this.tokenRefreshTimeoutId = window.setTimeout(
        this.refreshAccessToken.bind(this),
        Date.now() - this.tokenExpiration - this.refreshWindow
      )
    }

    if (this.loggedIn) {
      this.fetchInitial()
    }
  },

  beforeDestroy() {
    window.clearTimeout(this.tokenRefreshTimeoutId)
  },

  methods: {
    unresolvedNotificationsUpdate() {
      notificationsApi.getEvents(
        (eventData) => {
          let currentStore = this.$store.getters['global/eventStream'] ?? {};
          currentStore = {...currentStore, ...eventData}
          this.$store.commit('global/eventStream', currentStore);

          setTimeout(() => {
            this.unresolvedNotificationsUpdate()
          }, 30000)
        }, (error) => {
          //eslint-disable-next-line
          console.log(error)
        })
    },
    // FIXME This does currently not do anything
    refreshAccessToken() {
      apiAuth.refreshAccessToken(
        () => {},
        () => {},
        this.refreshToken
      )
    },

    fetchInitial() {
      Promise.all([
        this.$store.dispatch('global/fetchInitialData'),
        // Removing this because it is unwanted and causes issues if Subcontractor Supervisors pr a user
        // without projects view access logs in
        // this.$store.dispatch('projects/fetchItems'),
      ]).then(
        () => {
          this.loadedInitial = true
        },
        (error) => {
          if (error instanceof AuthenticationError) {
            // Probably an expired token.
            return
          }

          // We do not reload ourselves, becaue we might get stuck in a reload loop, DDOS'ing our sever and making it
          // impossible for the user to do something sensible.
          this.$message.error(
            'Could not load the initial data required to run the application. Please reload.'
          )
        }
      )
    },
  },
}
</script>
