import ApiResource from '../ApiResource'

class SubcontractorsReportResource extends ApiResource {
  constructor() {
    super({
      name: 'item-subcontractor-report',
    })

    this.getItem = this.show
  }

  export(resolve, reject, format, params) {
    this.get(`${this.getUrl()}/${format}`, { params: params })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const subcontractorsReportApi = new SubcontractorsReportResource()

export default subcontractorsReportApi
