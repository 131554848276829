import numeral from 'numeral'

export const TAX = 1.19

export const getQuantity = (item) => {
  if (['M', 'M2', 'M3'].indexOf(item.unit) !== -1) {
    return getMass(item)
  }

  return item.amount
}

export const getMass = function (item) {
  let result = item.factor

  if (item.width > 0) {
    result *= item.width
  }

  if (item.length > 0) {
    result *= item.length
  }

  if (item.height > 0) {
    result *= item.height
  }

  return result
}

export const getNet = function (item, formatted) {
  var result = getQuantity(item) * item.price

  if (formatted) {
    return numeral(result).format()
  }

  return result
}

export const getGross = function (item, formatted) {
  var result = getNet(item) * TAX

  if (formatted) {
    return numeral(result).format()
  }

  return result
}
