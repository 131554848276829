<template>
  <el-dialog
    class="lerf-edit-item-dialog"
    :visible.sync="visible"
    title="Edit Invoice item"
    :before-close="close"
    :close-on-click-modal="false"
  >
    <el-form v-if="lerfItem" label-width="120px" label-position="top">
      <el-form-item ref="external_reference" label="Comment">
        <el-input v-model="editItem.comment" :required="true" />
      </el-form-item>
      <el-form-item ref="section" label="Section">
        <el-input v-model="editItem.section" />
      </el-form-item>
      <el-form-item ref="reference" label="Reference">
        <el-input v-model="editItem.reference" />
      </el-form-item>
      <el-form-item ref="position" label="Pricing item">
        <el-select
          v-model="editItem.pricingItem"
          placeholder="Please select..."
          filterable
          return-object
          value-key="id"
          class="position-select"
          @change="itemChanged"
        >
          <el-option-group
            v-for="optionGroup in pricingItemOptions"
            :key="optionGroup.id"
            :label="optionGroup.name"
            :visible="true"
          >
            <el-option
              v-for="option in optionGroup.options"
              :key="option.id"
              :label="`${option.reference} / ${option.description}`"
              :value="option"
            />
          </el-option-group>
        </el-select>
      </el-form-item>

      <template
        v-if="!editItem.positionId"
      >
        <el-form-item
          v-if="streetOptions.length"
          ref="street_id"
          label="Street"
        >
          <el-select
            v-model="editItem.streetId"
            placeholder="Please select a street"
          >
            <el-option
              v-for="(street, index) in streetOptions"
              :key="index"
              :label="`${street.name}`"
              :value="street.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="muffeOptions.length"
          ref="muffe_id"
          label="Muffe"
        >
          <el-select
            v-model="editItem.muffeId"
            placeholder="Please select a muffe"
          >
            <el-option
              v-for="(muffe, index) in muffeOptions"
              :key="index"
              :label="`${muffe.name}`"
              :value="muffe.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="mfgOptions.length"
          ref="mfg_id"
          label="Mfg"
        >
          <el-select
            v-model="editItem.mfgId"
            placeholder="Please select a mfg">
            <el-option
              v-for="(mfg, index) in mfgOptions"
              :key="index"
              :label="`${mfg.name}`"
              :value="mfg.id"
            />
          </el-select>
        </el-form-item>
      </template>

      <el-form-item label="Measurements" class="measurements">
        <el-row :gutter="20">
          <el-col :span="5">
            <el-input
              v-if="
                !editItem.pricingItem ||
                ['M', 'M2', 'M3'].indexOf(editItem.pricingItem.unit) !== -1
              "
              v-model="editItem.length"
              :required="true"
            >
              <template slot="prepend">Length</template>
              <template slot="append">M</template>
            </el-input>
          </el-col>
          <el-col :span="5">
            <el-input
              v-if="
                !editItem.pricingItem ||
                ['M2', 'M3'].indexOf(editItem.pricingItem.unit) !== -1
              "
              v-model="editItem.width"
              :required="true"
              prop="editItem.width"
            >
              <template slot="prepend">Width</template>
              <template slot="append">M</template>
            </el-input>
          </el-col>
          <el-col :span="5">
            <el-input
              v-if="!editItem.pricingItem || editItem.pricingItem.unit === 'M3'"
              v-model="editItem.height"
              :required="true"
            >
              <template slot="prepend">Height</template>
              <template slot="append">M</template>
            </el-input>
          </el-col>
          <el-col :span="5">
            <el-input
              v-if="
                !editItem.pricingItem ||
                editItem.pricingItem.unit === 'ST' ||
                editItem.pricingItem.unit === 'STD'
              "
              v-model="editItem.amount"
              :required="true"
            >
              <template slot="prepend">{{
                editItem.pricingItem && editItem.pricingItem.unit === 'STD'
                  ? 'Hours'
                  : 'Amount'
              }}</template>
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-input v-model="editItem.factor" :required="true">
              <template slot="prepend">Factor</template>
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item label="Calculated" class="calculated">
        <el-row :gutter="20">
          <el-col :span="5">
            <el-input
              v-model="getMass"
              :required="true"
              :readonly="true"
              disabled
            >
              <template slot="prepend">Mass</template>
              <template v-if="editItem.pricingItem" slot="append">{{
                editItem.pricingItem.unit
              }}</template>
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-input v-model="editItem.price" :required="true">
              <template slot="prepend">Price</template>
              <template slot="append">EUR</template>
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-input
              v-model="getGross"
              :required="true"
              :readonly="true"
              disabled
            >
              <template slot="prepend">Gross</template>
              <template slot="append">EUR</template>
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-input
              v-model="getNet"
              :required="true"
              :readonly="true"
              disabled
            >
              <template slot="prepend">Net</template>
              <template slot="append">EUR</template>
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item>
        <el-button :loading="loading" type="primary" @click="onSubmit">
          Update
        </el-button>
        <el-button type="warning" @click="close"> Cancel </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'
import { cloneDeep } from 'lodash-es'
import lerfItemsApi from 'api/v2/lerfItems'
import pricingTemplateApi from '../../api/v2/pricingTemplates'
import * as lerfUtil from 'common/lerfUtil'
import {formatErrorMessage, logErrorMessage} from '../../common/util'
import projectPricingCategoriesApi from '@/js/api/v2/projectPricingCategories';
import projectAreasApi from '@/js/api/v2/projectAreas';

export default {
  name: 'LerfItemEditDialog',
  props: {
    lerf: {
      type: Object,
      default: null,
    },
    lerfItem: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      visible: true,
      pricingCategories: [],
      pricingItems: [],
      editItem: {},
      items: [],
      categories: [],
      areas: [],
    }
  },
  computed: {
    streetOptions() {
      if (this.areaSelection !== 'street') return []
      let streets = []
      this.areas.forEach(function (item) {
        item.streets.forEach(function (streetItem) {
          streets.push(streetItem)
        })
      })
      return streets
    },
    muffeOptions() {
      if (this.areaSelection !== 'muffe') return []
      let muffen = []
      this.areas.forEach(function (item) {
        item.muffen.forEach(function (streetItem) {
          muffen.push(streetItem)
        })
      })
      return muffen
    },
    mfgOptions() {
      if (this.areaSelection !== 'mfg') return []
      let mfgs = []
      this.areas.forEach(function (item) {
        item.mfgs.forEach(function (streetItem) {
          mfgs.push(streetItem)
        })
      })
      return mfgs
    },
    areaSelection() {
      if (!this.editItem?.pricingItem?.pricingCategoryId) return
      let selected = this.categories.filter(
        (category) => category.id === this.editItem.pricingItem.pricingCategoryId
      )
      if (selected.length <= 0) return
      return selected[0].areaSelection
    },
    getMass() {
      return lerfUtil.getMass(this.editItem)
    },
    getGross() {
      return lerfUtil.getGross(this.editItem, true)
    },
    getNet() {
      return lerfUtil.getNet(this.editItem, true)
    },
    pricingItemOptions() {
      if (!this.pricingCategories || !this.pricingItems) {
        return []
      }
      const options = []
      this.pricingCategories.forEach((pricingCategory) => {
        const optionGroup = {
          id: `cat-${pricingCategory.id}`,
          name: pricingCategory.name,
          options: [],
        }
        optionGroup.options = this.pricingItems.filter(
          (pricingItem) =>
            pricingItem.pricing_category_id === pricingCategory.id
        )
        options.push(optionGroup)
      })
      return options
    },
  },
  watch: {
    'editItem.pricingItem'(newValue, oldValue) {
      if(!oldValue) return

      this.editItem.pricingSubcategoryId = null
      this.editItem.streetId = null
      this.editItem.muffeId = null
      this.editItem.mfgId = null
    },
  },
  mounted() {
    this.editItem = cloneDeep(camelCaseKeys(this.lerfItem, { deep: true }))
    this.getCategories()
    this.loadAreas()
  },
  methods: {
    close(done) {
      this.$emit('cancel')
      if (done && typeof done === 'function') {
        this.$nextTick(() => {
          done()
        })
      }
    },
    onSubmit() {
      this.editItem.item_id = this.editItem.itemId

      this.loading = true
      lerfItemsApi.update(
        (response) => {
          this.loading = false
          this.$emit('updateItem', response)
          this.close()
        },
        (error) => {
          this.loading = false
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.lerf.id,
        this.editItem.id,
        snakeCaseKeys(this.editItem),
      )
    },
    getCategories() {
      pricingTemplateApi.index(
        (response) => {
          const pricingTemplate = response[0]
          this.pricingCategories = pricingTemplate.categories
          this.pricingItems = pricingTemplate.items
        },
        () => {
          // Do nothing
        },
        {
          project_id: this.lerf.project.id,
          include: ['categories', 'items'],
        }
      )
      projectPricingCategoriesApi.index(
        (response) => {
          this.categories = response
        },
        (error) => {
          this.loadingCategories = false
          this.categories = []
          logErrorMessage(error)
        },
        this.editItem.lerf.projectId,
      )
    },
    loadAreas(include = ['streets', 'muffen', 'mfgs']) {
      if (!this.editItem.lerf.projectId) return
      this.loadingAreas = true
      projectAreasApi.index(
        (response) => {
          this.areas = response
          this.loadingAreas = false
          if (!this.areas.length) {
            const ok = confirm('Please add areas before creating a invoice item.')
            if (ok) {
              this.$router.push({
                path: 'projects/edit/:projectId',
                name: 'edit',
                params: {projectId: this.editItem.lerf.projectId},
              })
            }
          }
        },
        (error) => {
          this.loadingAreas = false
          logErrorMessage(error)
        },
        this.editItem.lerf.projectId,
        {
          include: include,
        }
      )
    },
    itemChanged(selectedItem) {
      this.updateItemAmounts(selectedItem)
    },
    updateItemAmounts(selectedPricingItem) {
      if (!selectedPricingItem) return

      const pricingItem = this.pricingItems.find((item) => {
        return item.id === selectedPricingItem.id
      })
      this.editItem.pricingItem.pricingCategoryId = pricingItem.pricing_category_id

      this.editItem.pricing_item_id = pricingItem.id
      this.editItem.price = pricingItem.price
      this.editItem.factor = pricingItem.factor
      this.editItem.unit = pricingItem.unit
    },
  },
}
</script>

<style lang="scss">
.lerf-edit-item-dialog {
  ::v-deep .position-select {
    width: 100%;
  }

  ::v-deep .measurements input,
  ::v-deep .calculated input {
    text-align: right;
  }
}
</style>
