<template>
  <div>
    <el-card class="discount-card">
      <el-form>
        <el-form-item class="compact">
          <el-col :span="14">
            <label>Net<small>(after discount)</small></label>
          </el-col>
          <el-col :span="10" style="text-align:right;">{{ formatNumber(netAfterDiscount) }}</el-col>
        </el-form-item>
        <el-form-item class="compact">
          <el-col :span="14">Invoicing factor</el-col>
          <el-col :span="10" style="text-align:right;">
            <el-input
              v-model="invoiceForm.invoicingFactor"
              class="mini"
              max="1"
              min="0"
              size="mini"
              step="0.01"
              type="number"
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item class="compact">
          <el-col :span="14">
            <label>Net<small>(adjusted for factor)</small></label>
          </el-col>
          <el-col :span="10" style="text-align:right;">{{ formatNumber(netAfterFactor) }}</el-col>
        </el-form-item>
        <el-button :disabled="updating || !changed" type="primary" @click="update">Update</el-button>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import numeral from 'numeral';
import purchaseInvoicesApi from '@/js/api/v2/purchaseInvoices';
import lerfResourceApi from '@/js/api/v2/lerfs';
import {formatErrorMessage} from '@/js/common/util';

export default {
  name: 'InvoicingFactor',
  props: {
    purchaseInvoice: {
      type: Object,
      default: () => {
      },
    },
    salesInvoice: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      invoice: {},
      invoiceForm: {},
      updating: false
    }
  },
  computed: {
    invoiceApi() {
      return this.purchaseInvoice ? purchaseInvoicesApi : lerfResourceApi
    },
    netAfterFactor() {
      return this.netAfterDiscount * this.invoiceForm.invoicingFactor
    },
    netAfterDiscount() {
      return (this.invoiceForm.net / 100) * (100 - this.invoiceForm.discountPercentage)
    },
    changed() {
      return this.invoiceForm.invoicingFactor !== this.invoice.invoicingFactor
    },
  },
  watch: {
    'invoiceForm.invoicing_factor'(newValue) {
      // if the value is set to 0 it will default back to 1
      if (newValue === '0') {
        this.invoiceForm.invoicingFactor = '1'
      }
    }
  },
  created() {
    this.invoice = this.purchaseInvoice ? this.purchaseInvoice : this.salesInvoice
    this.invoiceForm = JSON.parse(JSON.stringify(this.invoice))
  },
  mounted() {},
  methods: {
    formatNumber(number) {
      return numeral(number).format('0,0.00')
    },
    update() {
      // if someone enters a value manually
      if(parseFloat(this.invoiceForm.invoicingFactor) > 1.00 || parseFloat(this.invoiceForm.invoicingFactor) <= 0) {
        alert('Invalid value (0.01 - 1.00 is allowed)')
        return false
      }

      this.updating = true
      this.invoiceApi.update(
        (response) => {
          this.updating = false
          this.updated = true
          this.$emit('update', response)
          this.invoice.invoicingFactor = this.invoiceForm.invoicingFactor

          this.$message({
            type: 'success',
            message: 'Updated',
            duration: 3000,
          })
        },
        (error) => {
          this.loading = false
          this.$message({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.invoice.id,
        {
          invoicing_factor: this.invoiceForm.invoicingFactor,
        }
      )
    },
    refresh(data) {
      this.invoiceForm.discountPercentage = data.discountPercentage
    }
  },
}
</script>

<style lang="scss">
:root .el-form-item.compact {
  margin-left: -5px;
  margin-right: -5px;

  label {
    position: relative;
    display: block;
  }

  small {
    display: block;
    line-height: 1;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}


.el-input--mini .el-input__inner {
  font-size: 12px;
  line-height: 28px;
  height: 28px;
}
</style>
