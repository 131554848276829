import ApiResource from '../ApiResource'

class ProjectPricingCategoryResource extends ApiResource {
  constructor() {
    super({
      name: 'projects.pricing-categories',
    })
  }
}

const projectPricingCategoriesApi = new ProjectPricingCategoryResource()

export default projectPricingCategoriesApi
