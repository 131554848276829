import ApiResource from '../ApiResource'

class PositionResource extends ApiResource {
  constructor() {
    super({
      name: 'positions',
    })
  }
  revisions(resolve, reject) {
    this.get(`${this.getUrl()}/revisions`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }
  itemRevisions(resolve, reject) {
    this.get(`${this.getUrl()}/items-revisions`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const positionsApi = new PositionResource()

export default positionsApi
