<template>
  <view-add-edit-delete-view
    :headers="headers"
    :api="approvalsApi"
    :include="[
      'project',
      'subcontractor',
      'subcontractorContact',
      'createdByContact',
      'approvedByContact',
    ]"
    :data="approvals"
    :spinner="spinner"
    identifying-prop="name"
    :vocabulary="{ single: 'approval', plural: 'approvals' }"
    :buttons="buttons"
    :actions-column-width="100"
    add-route="/approvals/add"
    :on-row-click="viewApproval"
    category="approval"
    show-table-search
    show-table-colums-filter
    :show-table-colums-toggle="false"
    @data="processData"
  />
</template>

<script>
import ViewAddEditDeleteView from 'components/Views/Common/ViewAddEditDeleteView'
import approvalsApi from 'api/v2/approvals'
import {formatErrorMessage, logErrorMessage} from '../../../common/util';

export default {
  name: 'ApprovalsView',

  components: {
    ViewAddEditDeleteView,
  },

  filters: {
    dateOnly(date) {
      return date.toString().substring(0, 10)
    },
  },

  data() {
    return {
      approvals: [],

      spinner: true,

      headers: [
        {
          prop: 'reference',
          label: 'Reference',
          width: 120,
        },
        {
          prop: 'created_at',
          label: 'Created at',
          filterDisabled: true,
          width: 100,
        },
        {
          prop: 'created_by_contact.fullname',
          label: 'Created by',
          filterDisabled: true,
          width: 150,
        },
        {
          prop: 'approved_at',
          label: 'Approved at',
          filterDisabled: true,
          width: 100,
        },
        {
          prop: 'approved_by_contact.fullname',
          label: 'Approved by',
          filterDisabled: true,
          width: 150,
        },
        {
          prop: 'project.name',
          label: 'Project',
        },
        {
          prop: 'subcontractor.name',
          label: 'Subcontractor',
          width: 130,
        },
        {
          prop: 'subcontractor_contact.fullname',
          label: 'Bauleiter',
          filterDisabled: true,
          width: 130,
        },
        {
          prop: 'item_count',
          label: 'Items',
          filterDisabled: true,
          width: 130,
        },
        {
          prop: 'status',
          label: 'Status',
          width: 120,
        },
      ],
    }
  },

  computed: {
    approvalsApi() {
      return approvalsApi
    },
    buttons() {
      return [
        {
          label: 'Download approval',
          type: 'primary',
          title: 'Download approval',
          faIcon: 'el-icon el-icon-download',
          prop: 'id',
          capability: 'download',
          action: (id) => {
            approvalsApi.download(
              () => {

              },
              (error) => {
                logErrorMessage(error)
                this.$message.error({
                  showClose: true,
                  message: formatErrorMessage(error),
                  duration: 0,
                })
              },
              id
            )
          },
        },
        {
          label: 'Delete approval',
          event: 'delete',
          type: 'danger',
          title: 'Delete approval',
          faIcon: 'el-icon el-icon-delete',
          prop: 'id',
          capability: 'delete',
        },
      ]
    },
  },

  mounted() {},

  methods: {
    processData(data) {
      this.approvals = data.map((approval) => {
        approval.item_count = `${approval.approved_items_count} | ${approval.undecided_items_count} | ${approval.denied_items_count} | ${approval.total_items_count}`
        approval.created_at = this.$options.filters.dateOnly(
          approval.created_at
        )
        approval.approved_at = this.$options.filters.dateOnly(
          approval.approved_at
        )
        return approval
      })
      this.spinner = false
    },
    viewApproval(row) {
      this.$router.push({
        name: 'projectApproval',
        params: {
          projectId: row.project_id,
          approvalId: row.id,
          tab: 'approvals',
        },
      })
      logErrorMessage('should nav to')
      logErrorMessage(row)
    },
  },
}
</script>

<style lang="scss" scoped></style>
