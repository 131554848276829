<template>
  <div>
    <lerf-email-controls
      :lerf="lerf"
      :loading="loading"
      :errors="errors"
      @send="send"
      @reload="reload"
    />
    <lerf-email-preview
      v-if="email"
      ref="preview"
      :email="email"
      :lerf="lerf"
      :images="images"
      @changedEmail="emailChanged"
      @changedPdf="pdfChanged"
    />
    <EmailImagePicker
      v-if="!loading && email"
      :email="email"
      @change="imageSelectionChanged"
      @type="changedType"
    />
    <EmailAdditionalFilePicker
      v-if="email"
      :lerf="lerf"
      :email="email"
      @change="additionalFileSelectionChanged"
    />
  </div>
</template>

<script>
import projectLerfEmailApi from 'api/v2/projectLerfEmail'
import LerfEmailControls from 'components/Lerfs/EmailControls'
import EmailImagePicker from 'components/Common/EmailImagePicker'
import LerfEmailPreview from 'components/Lerfs/EmailPreview'
import { formatErrorMessage } from 'common/util'
import EmailAdditionalFilePicker from '@/js/components/Common/EmailAdditionalFilePicker.vue';

export default {
  name: 'LerfEmail',

  components: {
    EmailAdditionalFilePicker,
    LerfEmailControls,
    EmailImagePicker,
    LerfEmailPreview,
  },

  props: {
    lerf: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      errors: {},
      changed: false,
      email: null,
      bodyEmail: null,
      bodyPdf: null,
      images: [],
      additionalFiles: [],
      type: 'pdf',
      listeningForChanges: false,
    }
  },

  watch: {
    changed(isChanged) {
      this.$store.commit('global/unsavedChanges', isChanged)
    },

    $route: function () {
      if (this.$route.params.tab === 'email') {
        this.listeningForChanges = false
        this.loadEmail()

        this.startListeningForChangesInABit()
      } else {
        this.changed = false
      }
    },
  },

  mounted() {
    this.loadEmail()
    this.startListeningForChangesInABit()
  },

  methods: {
    emailChanged(data) {
      if (this.listeningForChanges) {
        this.changed = true
      }
      this.bodyEmail = data
    },

    pdfChanged(data) {
      if (this.listeningForChanges) {
        this.changed = true
      }
      this.bodyPdf = data
    },

    imageSelectionChanged(data) {
      if (this.listeningForChanges) {
        this.changed = true
      }
      this.images = data
    },

    additionalFileSelectionChanged(data) {
      if (this.listeningForChanges) {
        this.changed = true
      }
      this.additionalFiles = data
    },

    changedType(data) {
      if (this.listeningForChanges) {
        this.changed = true
      }
      this.type = data
    },

    loadEmail() {
      this.loading = true
      if (this.changed) {
        var confirm = window.confirm(
          'Are you sure? \n Your changes will be lost!'
        )

        if (!confirm) {
          return
        }
      }

      projectLerfEmailApi.index(
        (response) => {
          this.email = response
          this.changed = false
          this.body = null
          this.images = response.images

          this.loading = false
        },
        (error) => {
          this.$message({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
          this.loading = false
        },
        this.lerf.projectId,
        this.lerf.id
      )
    },

    reload() {
      this.loadEmail()
    },

    send(data) {
      this.loading = true
      let confirm = window.confirm(
        'Are you sure you want to send the email to ' + data.to_name
      )

      data.body_email = this.bodyEmail
      data.body_pdf = this.bodyPdf

      data.images = this.images
      data.additionalFiles = this.additionalFiles
      data.type = this.type
      data.orientation = 'landscape'
      data.options = {fontHeightRatio: 0.75}

      if (confirm) {
        projectLerfEmailApi.store(
          () => {
            this.$message({
              type: 'success',
              message: 'Email sent',
              duration: 1000,
            })
            this.loading = false
            this.$emit('reload')
          },
          (error) => {
            if (error.message) {
              this.$message({
                showClose: true,
                type: 'error',
                message: formatErrorMessage(error),
                duration: 0,
              })
            }

            this.errors = error
            this.loading = false
          },
          this.lerf.projectId,
          this.lerf.id,
          data
        )
      } else {
        this.$message({
          type: 'warning',
          message: 'Sending cancelled',
          duration: 1000,
        })
        this.loading = false
      }
    },

    startListeningForChangesInABit() {
      window.setTimeout(() => {
        if (!this.loading) {
          this.listenForChangesNow()
        } else {
          this.startListeningForChangesInABit()
        }
      }, 1000)
    },

    listenForChangesNow() {
      this.listeningForChanges = true
    },
  },
}
</script>
