<template>
  <div>
    <el-form ref="form" label-position="top">
      <el-form-item label="Update status">
        <el-select
          ref="status"
          v-model="claimForm.status"
          placeholder="please select status"
          @change="onChange"
        >
          <el-option
            v-for="status in statuses"
            :key="status.id"
            :label="
              status.value === currentStatus
                ? `${status.label} (current)`
                : status.label
            "
            :value="status.value"
            :disabled="status.value === currentStatus"
          />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" :disabled="!updated" @click="update">
          Update
        </el-button>
      </el-form-item>
    </el-form>

    <StatusHistory :items="claim.statuses" />
  </div>
</template>

<script>
import claimsApi from 'api/v2/claims'
import StatusHistory from './StatusHistory'
import { formatErrorMessage } from 'common/util'

// import { mapGetters } from 'vuex'

export default {
  name: 'FormStatus',
  components: {
    StatusHistory,
  },
  props: {
    claim: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      claimForm: {
        status: null,
      },
      form: {},
      updated: false,
      currentStatus: null,
      statusHistory: [],
    }
  },
  computed: {
    // todo claims
    // ...mapGetters('global', ['claimUpdateRules']),

    statuses() {
      return this.$store.getters['global/getEnumById']('claim_status', true)
    },
  },

  created() {
    this.claimForm = this.claim
  },
  mounted() {
    this.currentStatus = this.claim.status
    this.statusHistory = this.claim.statusHistory
    // todo claims : What does this do? Seems to be needing to happen on backend if at all...
    // if (
    //   !Object.keys(this.claimUpdateRules).includes(this.currentStatus) &&
    //   this.statusHistory.length >= 2
    // ) {
    //   this.currentStatus = this.statusHistory[1].statusNew
    // }
  },

  methods: {
    onChange() {
      this.updated = true
    },
    update() {
      claimsApi.update(
        (response) => {
          if(response) {
            this.currentStatus = this.claim.status
            this.updated = false

            this.$emit('update')

            this.$message({
              type: 'success',
              message: 'Updated',
              duration: 2000,
            })
          }
        },
        (error) => {
          this.loading = false
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.claim.id,
        {
          status: this.claimForm.status,
        }
      )
    },
  },
}
</script>
