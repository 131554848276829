<template>
  <PositionTable />
</template>

<script>
import PositionTable from '../../Positions/PositionsTable'

export default {
  name: 'PositionsView',
  components: {
    PositionTable,
  },
}
</script>
