<template>
  <el-card class="comp-list-view box-card list-view">
    <div slot="header" class="card-header">
      <el-row align="middle" type="flex">
        <el-col :span="filterable ? 12 : 24" class="header">
          {{ title }}
        </el-col>
        <el-col v-if="filterable" :span="12" class="header">
          <el-input v-model="filter" placeholder="filter"/>
        </el-col>
        <el-col class="sub-header sort">
          <el-button
            class="el-icon el-icon-caret-bottom"
            size="small"
            title="Sort list"
            @click="isSort = !isSort"
          ></el-button>
        </el-col>
      </el-row>
    </div>

    <div class="scrollable">
      <el-row
        v-for="item in filteredSortedItems"
        :key="item.id"
        :gutter="5"
        align="middle"
        class="item"
        type="flex"
      >
        <el-col
          :span="secondaryLabelAttribute ? 24 - secondaryLabelColumnSpan : 24"
          class="content label"
        >
          <div @click.stop="selectItem(item)">
            <el-radio v-model="selectedLabel" :label="item[labelAttribute]"/>
          </div>
        </el-col>
        <el-col v-if="secondaryLabelAttribute" :span="secondaryLabelColumnSpan">
          {{ item[secondaryLabelAttribute] }}
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'ProjectsSingleAreasListView',

  props: {
    // The initial set of items to populate this view with
    items: {
      type: Array,
      required: true,
    },
    // items and localItems is an array with objects. This attribute is used to display one attribute in the list view
    labelAttribute: {
      type: String,
      required: true,
    },
    // an optional second attribute
    secondaryLabelAttribute: {
      type: String,
      required: false,
      default: null,
    },
    // if we have a secondary item label, how wide should it's column be
    secondaryLabelColumnSpan: {
      type: Number,
      default: 12,
      validator: (value) => {
        if (!value) return true
        return value > 0 && value < 25
      },
    },
    // The title of the card
    title: {
      type: String,
      required: true,
    },
    // If set, you can filter this view
    filterable: {
      type: Boolean,
      default: false,
    },
    // If set, you can sort this view (by default, new items are added in the top, rather than in alphabetical order
    sortable: {
      type: Boolean,
      default: false,
    },
    // If a row can be selected
    selectable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      addedName: '',
      filter: '',
      isSort: false,
      addedSecondaryValue: '',
      editedItem: null,
      deletionInformation: null,
      selectedLabel: null,
    }
  },

  computed: {
    filteredSortedItems() {
      let items = this.items.filter(
        (item) =>
          item[this.labelAttribute]
            .toLowerCase()
            .indexOf(this.filter.toLowerCase()) >= 0
      )

      if (this.isSort) {
        items = items.sort((a, b) => {
          return a[this.labelAttribute].localeCompare(
            b[this.labelAttribute],
            ['de', 'en'],
            {sensitivity: 'base'}
          )
        })
      }

      return items
    },
  },

  methods: {
    selectItem(item) {
      if (!item) {
        return
      }

      this.selectedId = item.id
      this.selectedLabel = item[this.labelAttribute]
      this.$emit('select', item)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~scss/mixins';

@include tiny-vertical-scroll;

::v-deep .el-card__body {
  margin: 0 -10px;

  .el-row {
    font-size: 14px;
    line-height: 30px;
    padding: 0 10px;

    &.item:hover {
      background: rgba(15, 126, 190, 0.2);
    }

    &.item .el-button {
      visibility: hidden;
    }

    &.item:hover .el-button {
      visibility: visible;
    }
  }

  .el-radio__label {
    padding-left: 12px;
  }
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 400px);
  padding-right: 2px;
  margin-right: -1px;
}

.sub-header,
.content {
  float: none;

  &:not(.addition-input) {
    width: unset;
  }

  &.addition-input {
    margin-left: -3px;
    margin-right: 25px;
  }

  ::v-deep input {
    height: auto;
    font-size: 14px;
    line-height: 22px;
  }

  &.sort {
    width: 62px;
    text-align: right;
  }
}

.addition-input,
.content.label {
  flex-grow: 1;
}

.content.label {
  cursor: pointer;
}

.addition-icon {
  color: #95f204;
  min-width: 30px;
}

.spacer {
  height: 5px;
}
</style>
