<template>
  <div>
    <div class="header-action-row table-widget table-column-filter">
      <div class="search">
        <h4>Filter</h4>
        <el-form class="dates-filter">
          <el-form-item class="date-filter">
            <el-date-picker
              v-model="start_date"
              :picker-options="quickPicks"
              placeholder="Start date"
              size="mini"
              type="date"
            />
          </el-form-item>
          <el-form-item class="date-filter">
            <el-date-picker
              v-model="end_date"
              :picker-options="quickPicks"
              clearable
              placeholder="End date"
              size="mini"
              type="date"
            />
          </el-form-item>
          <el-form-item class="date-filter">
            <el-select
              v-model="selectedProject"
              clearable
              filterable
              placeholder="Project Name"
              size="mini"
            >
              <el-option
                v-for="project in projects"
                :key="project.id"
                :label="project.name"
                :value="project.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item class="date-filter">
            <el-select
              v-model="selectedSubcontractor"
              clearable
              filterable
              placeholder="Subcontractor"
              size="mini"
            >
              <el-option
                v-for="subcontractor in subcontractors"
                :key="subcontractor.id"
                :label="subcontractor.name"
                :value="subcontractor.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item class="show-items-without-date-filter">
            <el-checkbox v-model="showItemsWithoutDate" :checked="false">
              Show items without date
            </el-checkbox>
          </el-form-item>
        </el-form>
        <div>
          <el-button
            :loading="loading"
            class="search-button"
            size="medium"
            type="primary"
            @click="getDatesReport"
          >
            Search
          </el-button>
        </div>
      </div>
    </div>

    <div v-loading="loading" class="wrap-table lerfs-reporting-table">
      <template v-if="data.length">
        <el-table
          v-loading="loading"
          :data="data"
          :highlight-current-row="true"
          :max-height="maxHeight"
          border
          class="weekly-report"
          size="mini"
          stripe
          @sort-change="changeSort"
          @filter-change="changeFilter"
        >
          <el-table-column
            :show-overflow-tooltip="true"
            label="#"
            prop="id"
            width="60"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Added to approval"
            prop="isAddedToApproval"
            width="140"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Approved?"
            prop="isApproved"
            width="90"
          />

          <el-table-column label="Internal">
            <el-table-column
              :show-overflow-tooltip="true"
              label="Reference"
              prop="reference"
              width="90"
            />
            <el-table-column
              :show-overflow-tooltip="true"
              label="Description"
              prop="description"
              width="200"
            />
            <el-table-column
              :show-overflow-tooltip="true"
              label="Price"
              prop="price"
              width="80"
            />
            <el-table-column
              :show-overflow-tooltip="true"
              label="Unit"
              prop="unit"
              width="60"
            />
          </el-table-column>

          <el-table-column label="Subcontractor">
            <el-table-column
              :show-overflow-tooltip="true"
              label="Reference"
              prop="subcontractorReference"
              width="90"
            />
            <el-table-column
              :show-overflow-tooltip="true"
              label="Description"
              prop="subcontractorDescription"
              width="200"
            />
            <el-table-column
              :show-overflow-tooltip="true"
              label="Price"
              prop="subcontractorPrice"
              width="80"
            />
            <el-table-column
              :show-overflow-tooltip="true"
              label="Unit"
              prop="subcontractorUnit"
              width="60"
            />
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            label="Position #"
            prop="positionId"
            width="90"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Project"
            prop="projectName"
            width="100"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Category"
            prop="categoryName"
            width="120"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Subcategory"
            prop="subcategoryName"
            width="100"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Team"
            prop="team"
            width="100"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Executor"
            prop="executor"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="User"
            prop="user"
            width="80"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Creditor number"
            prop="subcontractorCreditorNumber"
            width="120"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Cost obj."
            prop="costCenter"
            width="80"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Cost object code"
            prop="costObjectCode"
            width="120"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Area Code"
            prop="areaCode"
            width="90"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Area Name"
            prop="areaName"
            width="200"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Length"
            prop="length"
            width="80"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Width"
            prop="width"
            width="80"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Height"
            prop="height"
            width="80"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Amount"
            prop="amount"
            width="80"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Factor"
            prop="factor"
            width="80"
          />
          <el-table-column
            :formatter="(r, c, val) => formatDateFromData(val)"
            :show-overflow-tooltip="true"
            label="Start"
            prop="startDate"
            width="90"
          />
          <el-table-column
            :formatter="(r, c, val) => formatDateFromData(val)"
            :show-overflow-tooltip="true"
            label="End"
            prop="endDate"
            width="90"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Invoice reference"
            prop="lerfExternalReference"
            width="120"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Approval reference"
            prop="approvalReference"
            width="140"
          />
          <el-table-column
            :formatter="(r, c, val) => (val ? '✓' : '')"
            :show-overflow-tooltip="true"
            label="Approved"
            prop="approved"
            width="90"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Comments"
            prop="comments"
            width="200"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Created at"
            prop="createdAt"
            width="145"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Updated at"
            prop="updatedAt"
            width="145"
          />
        </el-table>

        <el-row class="bottom-row">
          <el-col :span="12">
            <el-button
              :loading="generatingExcelFile"
              type="primary"
              @click="generateExcelFile('xlsx')"
            >Download excel file
            </el-button>
            <el-button
              :loading="generatingExcelFile"
              type="primary"
              @click="generateExcelFile('csv')"
            >Download csv file
            </el-button>
          </el-col>
          <el-col :span="12">
            <el-pagination
              :background="true"
              :current-page.sync="currentPage"
              :hide-on-single-page="true"
              :page-size="perPage"
              :pager-count="5"
              :small="true"
              :total="total"
              class="pagination"
              layout="sizes, total, prev, pager, next"
              @current-change="perPageChange"
              @size-change="changePageSize"
            />
          </el-col>
        </el-row>
      </template>
    </div>
  </div>
</template>

<script>
import camelCaseKeys from 'camelcase-keys'
import moment from 'moment'
import {
  formatDate,
  formatDateForRequest,
  formatErrorMessage,
} from 'common/util'

import subcontractorsReportApi from 'api/v2/subcontractorsReport'
import projectsApi from 'api/v2/projects'
import subcontractorsApi from 'api/v2/subcontractors'

export default {
  name: 'SubcontractorsReportTable',

  data() {
    return {
      data: [],
      projects: [],
      subcontractors: [],
      id: null,
      currentPage: 1,
      total: null,
      perPage: 50,
      loading: false,
      generatingExcelFile: false,

      start_date: moment()
        .startOf('week')
        .subtract('1', 'weeks')
        .format('YYYY-MM-DD'),
      end_date: moment()
        .startOf('week')
        .subtract('1', 'days')
        .format('YYYY-MM-DD'),
      selectedProject: '',
      selectedSubcontractor: '',

      showItemsWithoutDate: 0,

      quickPicks: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: 'Today',
            onClick(picker) {
              picker.$emit('pick', new Date())
            },
          },
          {
            text: 'Yesterday',
            onClick(picker) {
              const date = new Date(Date.now() - 3600 * 24 * 1000)
              picker.$emit('pick', date)
            },
          },
        ],
      },
    }
  },

  computed: {
    maxHeight() {
      return window.innerHeight - 250
    },
  },

  created() {
    this.fetchProjects()
    this.fetchSubcontractors()
  },

  mounted() {
    this.getDatesReport()
  },

  methods: {
    fetchProjects() {
      projectsApi.index(
        (response) => {
          this.projects = response
        },
        (error) => {
          this.$message.error({
            message: formatErrorMessage(error),
          })
        }
      )
    },
    fetchSubcontractors() {
      subcontractorsApi.index(
        (response) => {
          this.subcontractors = response
        },
        (error) => {
          this.$message.error({
            message: formatErrorMessage(error),
          })
        },
        {
          withArchived: true,
        }
      )
    },
    getDatesReport() {
      this.loading = true

      const startDate = formatDateForRequest(this.start_date)
      const endDate = formatDateForRequest(this.end_date)

      const params = {
        page: 1,
        startDate,
        endDate,
        showItemsWithoutDate: this.showItemsWithoutDate ? 1 : 0,
      }

      if (this.selectedProject) {
        params.projectId = this.selectedProject
      }

      this.updateData(params, startDate, endDate)
    },
    loadPage(page) {
      this.loading = true

      this.data = []

      const params = {}
      let startDate, endDate

      if (this.start_date) {
        startDate = formatDateForRequest(this.start_date)
      }

      if (this.end_date) {
        endDate = formatDateForRequest(this.end_date)
      }

      params['showItemsWithoutDate'] = this.showItemsWithoutDate ? 1 : 0

      if (this.selectedProject) {
        params.projectId = this.selectedProject
      }

      this.updateData({page, ...params}, startDate, endDate)
    },

    generateExcelFile(extension) {
      this.generatingExcelFile = true

      const startDate = formatDateForRequest(this.start_date)
      const endDate = formatDateForRequest(this.end_date)

      const params = {}
      params['startDate'] = startDate
      params['endDate'] = endDate
      params['showItemsWithoutDate'] = this.showItemsWithoutDate ? 1 : 0
      params['extension'] = extension
      params['subcontractorId'] = this.selectedSubcontractor

      if (this.selectedProject) {
        params.projectId = this.selectedProject
      }

      subcontractorsReportApi.export(
        (response) => {
          this.generatingExcelFile = false
          window.location = response
        },
        (error) => {
          this.generatingExcelFile = false
          if (error) {
            this.$message.error({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 0,
            })
          }
        },
        'export',
        params
      )
    },

    pageChange(val, sortDirection, prop) {
      this.loading = true

      const start = (val - 1) * this.perPage
      const end = start + this.perPage
      const params = {
        subcontractorId: this.selectedSubcontractor,
      }

      if (prop === 'startDate') {
        prop = 'start_date'
      }

      if (prop === 'endDate') {
        prop = 'end_date'
      }

      if (prop === 'costCenter') {
        prop = 'cost_center'
      }

      params['page'] = val
      params[sortDirection] = prop

      if (this.start_date) {
        const startDate = formatDateForRequest(this.start_date)
        params['startDate'] = startDate
      }
      if (this.end_date) {
        const endDate = formatDateForRequest(this.end_date)
        params['endDate'] = endDate
      }

      params['showItemsWithoutDate'] = this.showItemsWithoutDate ? 1 : 0

      if (this.selectedProject) {
        params.projectId = this.selectedProject
      }

      this.updateData(params, start, end)
    },

    updateData(params) {
      params.subcontractorId = this.selectedSubcontractor
      params.perPage = this.perPage

      subcontractorsReportApi.index(
        (response) => {
          // this.data = response.slice(start, end)
          // this.total = response.length

          this.data = response.data.map((item) => camelCaseKeys(item))

          this.total = response.meta.total
          this.loading = false
        },
        (error) => {
          this.loading = false
          if (error) {
            this.$message.error({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 0,
            })
          }
        },
        params
      )
    },

    perPageChange(val) {
      this.currentPage = val
      this.loadPage(val)
    },

    getOriginLabel(value) {
      if (value) {
        return this.origin[value].label
      }

      return null
    },

    changeSort({prop, order}) {
      let sortDirection
      if (order === 'ascending') {
        sortDirection = 'sortBy'
      } else if (order === 'descending') {
        sortDirection = 'sortByDesc'
      } else {
        sortDirection = null
        return
      }

      if (prop === 'projectName') {
        prop = 'project_name'
      }

      if (prop === 'categoryName') {
        prop = 'category_name'
      }

      if (prop === 'costCenter') {
        prop = 'cost_center'
      }

      if (prop === 'areaCode') {
        prop = 'area_code'
      }

      if (prop === 'areaName') {
        prop = 'area_name'
      }

      if (prop === 'isApproved') {
        prop = 'is_approved'
      }

      if (prop === 'isAddedToApproval') {
        prop = 'is_added_to_approval'
      }

      this.pageChange(1, sortDirection, prop)
    },

    changeFilter(filters) {
      this.filters = filters

      this.pageChange(1)
    },

    changePageSize(newPageSize) {
      this.perPage = newPageSize

      this.perPageChange(1)
    },

    formatDateFromData(date) {
      return formatDate(date)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .lerfs-reporting-table {
  .bottom-row {
    margin-top: 1em;
  }

  th {
    vertical-align: middle;
  }

  th .cell {
    vertical-align: middle;
  }

  .day-9 {
    background: rgba(#ffdc00, 0.4) !important;
  }

  .day-8 {
    background: rgba(#ff4136, 0.4) !important;
  }

  .day-7 {
    background: rgba(#2ecc40, 0.4) !important;
  }

  .day-6 {
    background: rgba(#b10dc9, 0.4) !important;
  }

  .day-5 {
    background: rgba(#001f3f, 0.4) !important;
    color: #fff;
  }

  .day-4 {
    background: rgba(#ff851b, 0.4) !important;
  }

  .day-3 {
    background: rgba(#0074d9, 0.4) !important;
  }

  .day-2 {
    background: rgba(#7fdbff, 0.4) !important;
  }

  .day-1 {
    background: rgba(#01ff70, 0.4) !important;
  }
}
</style>
