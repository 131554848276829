import ApiResource from '../ApiResource'

class ApprovalResource extends ApiResource {
  constructor() {
    super({
      name: 'approvals',
    })
  }

  report(resolve, reject, params) {
    this.get(`${this.getUrl()}/report`, { params })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  download(resolve, reject, approvalId) {
    this.ids = [approvalId]
    this.get(`${this.getUrl()}/get-download-url`)
      .then((response) => {
        window.open(response.data, '_blank').focus()
      })
      .catch((error) => reject(error))
  }
}

const globalApprovalsApi = new ApprovalResource()

export default globalApprovalsApi
