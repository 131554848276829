<template>
  <div>
    <el-tabs v-if="lerf" ref="tabs" v-model="activeName" @tab-click="tabChange">
      <el-tab-pane v-loading="loadingOverview" label="Overview" name="overview">
        <LerfOverview
          v-if="lerf && activeName === 'overview'"
          :lerf="lerf"
          @update="update"
        />
      </el-tab-pane>
      <el-tab-pane label="Items" name="items">
        <LerfItems
          v-if="lerf && activeName === 'items'"
          ref="items"
          :item="lerf"
        />
      </el-tab-pane>
      <el-tab-pane label="Import" name="import">
        <LerfImport
          v-if="lerf && activeName === 'import'"
          :lerf="lerf"
          @reload="reloadLerfs"
        />
      </el-tab-pane>
      <el-tab-pane label="Email" name="email">
        <LerfEmail
          v-if="lerf && activeName === 'email'"
          :lerf="lerf"
          @reload="loadLerf"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="lerf.status === 'seven' || lerf.status === 'nine'"
        label="eVergabe"
        name="evergabe"
      >
        <LerfEvergabe
          v-if="activeName === 'evergabe' && lerf && lerf.status === 'seven'"
          :lerf="lerf"
          @reload="loadLerf"
        />
        <div v-else>
          <el-card class="lerf-email-image-picker">
            <div slot="header">
              <h3>Evergabe</h3>
            </div>
            <div>
              <p>
                <span v-if="lerf.evergabe_filename">
                  <a :href="`/pdfs/${lerf.evergabe_filename}`" target="_blank">
                    {{ lerf.evergabe_filename }} </a
                  >, uploaded on {{ lerf.evergabe_uploaded_at }}
                </span>
                <span v-else> There is no Evergabe PDF File to show. </span>
              </p>
            </div>
          </el-card>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import lerfsApi from 'api/v2/lerfs'
import LerfOverview from './Overview'
import LerfItems from './Items'
import LerfImport from './Import'
import LerfEmail from './Email'
import LerfEvergabe from './Evergabe'
import {formatErrorMessage} from 'common/util'

export default {
  name: 'LerfSingle',

  components: {
    LerfOverview,
    LerfItems,
    LerfImport,
    LerfEmail,
    LerfEvergabe,
  },

  data() {
    return {
      activeName: 'overview',
      lerf: null,
      loadingOverview: true,
    }
  },

  watch: {
    $route(val) {
      this.activeName = val.params.tab
    },
  },

  mounted() {
    this.loadLerf()

    if (this.$route.params.tab) {
      this.activeName = this.$route.params.tab
    }
  },

  methods: {
    reloadLerfs() {
      if (!this.$refs.items) {
        return
      }
      this.$refs.items.loadPage()
    },
    tabChange(e) {
      this.$router.replace(
        '/projects/' +
        this.lerf.projectId +
        '/lerfs/' +
        this.lerf.id +
        '/' +
        e.name,
        () => {
          this.$route.params.tab = e.name
        },
        () => {
          this.$refs.tabs.setCurrentName(this.$route.params.tab)
          this.activeName = this.$route.params.tab
        }
      )

      if (e.label === 'Items') {
        if (!this.$refs.items) {
          return
        }
        this.$refs.items.loadPage(1)
      }
    },
    update() {
      // this.lerf = item
      this.loadLerf()
    },
    loadLerf() {
      this.loadingOverview = true
      lerfsApi.show(
        (response) => {
          this.lerf = response
          this.$store.commit('projects/currentLerf', response)
          this.loadingOverview = false
        },
        (error) => {
          this.loadingOverview = false
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.$route.params.lerfId,
        {include: ['statuses', 'statuses.user', 'project', 'subcontractor']}
      )
    },
  },
}
</script>
