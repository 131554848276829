<template>
  <el-dialog
    :visible.sync="visible"
    title="Add team"
    :before-close="notifyParent"
  >
    <default-form
      :form="form"
      :loading="loading"
      :errors="formErrors"
      @submit="addTeam"
    />
  </el-dialog>
</template>

<script>
import Vue from 'vue'
import teamsApi from 'api/v2/teams'
import DefaultForm from 'components/Common/Form/DefaultForm'

export default {
  name: 'DialogAddTeam',

  components: {
    DefaultForm,
  },

  data() {
    return {
      visible: true,
      loading: false,
      formErrors: null,
    }
  },

  computed: {
    formItems() {
      return [
        {
          label: 'Name',
          prop: 'name',
          required: true,
          rules: [],
        },
      ]
    },

    form() {
      return {
        items: this.formItems,
        buttons: [
          {
            event: 'submit',
            label: 'Add team',
            type: 'primary',
          },
        ],
        ref: 'addTeamForm',
      }
    },
  },

  methods: {
    notifyParent(done) {
      done()
      this.$parent.$emit('cancelAddItem')
    },

    addTeam(model) {
      this.loading = true
      teamsApi.store(
        () => {
          this.$message.success({
            showClose: true,
            message: 'Team has been created.',
            duration: 3000,
          })
          this.loading = false
          this.visible = false
          this.$parent.$emit('changedAddItem')
        },
        (error) => {
          this.formErrors = error
          this.loading = false
          Vue.nextTick(() => {
            this.formErrors = null
          })
        },
        model
      )
    },
  },
}
</script>

<style lang="scss"></style>
