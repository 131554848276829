import ApiResource from '../ApiResource'

class PositionsFixMissingSubcontractors extends ApiResource {
  constructor() {
    super({
      name: 'fix-position-sub-pricing',
    })
  }
}

const positionsFixMissingSubcontractorsApi = new PositionsFixMissingSubcontractors()

export default positionsFixMissingSubcontractorsApi
