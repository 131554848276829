<template>
  <view-add-edit-delete-view
    :actions-column-width="100"
    :api="subcontractorsApi"
    :buttons="buttons"
    :data="subcontractors"
    :headers="headers"
    :show-add-button="false"
    :vocabulary="{ single: 'subcontractor', plural: 'subcontractors' }"
    category="subcontractor"
    class="subcontractor-archive-table"
    identifying-prop="name"
    show-table-search
    @data="processData"
  />
</template>

<script>
import ViewAddEditDeleteView from 'components/Views/Common/ViewAddEditDeleteView'
import {formatErrorMessage} from 'common/util'
import subcontractorsApi from 'api/v2/subcontractors'

export default {
  name: 'SubcontractorArchiveView',

  components: {
    ViewAddEditDeleteView
  },

  data() {
    return {
      subcontractors: [],

      headers: [
        {
          prop: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          prop: 'archived_at',
          label: 'Archived at',
          sortable: true,
        },
      ],
    }
  },

  computed: {
    subcontractorsApi() {
      return {
        index:
          subcontractorsApi.getArchivedSubcontractors.bind(subcontractorsApi),
        delete: subcontractorsApi.delete.bind(subcontractorsApi),
      }
    },

    buttons() {
      return [
        {
          label: 'Restore',
          title: 'Restore subcontractor',
          faIcon: 'el-icon el-icon-refresh-left',
          event: 'restore',
          action: this.restoreSubcontractor,
          prop: 'id',
          capability: 'update',
        },
        {
          label: 'Delete',
          type: 'danger',
          title: 'Delete subcontractor',
          faIcon: 'el-icon el-icon-delete',
          event: 'delete',
          prop: 'id',
          capability: 'delete',
        },
      ]
    },
  },

  methods: {
    processData(data) {
      this.subcontractors = data
    },

    restoreSubcontractor(id) {
      subcontractorsApi.unarchive(
        (response) => {
          this.$message.success({
            message: `Project ${response.name} has been restored`,
          })

          this.subcontractors = this.subcontractors.filter((row) => {
            return row.id !== id
          })
        },
        (error) => {
          this.$message.error({
            showClose: true,
            message: formatErrorMessage(error),
            duration: 5000,
          })
        },
        id
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.subcontractor-archive-table {
  max-width: 1000px;
}
</style>
