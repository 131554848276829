<template>
  <div
      v-if="renderTable"
      class="data-table-alternate"
    >
      <el-row v-if="tableOptions.showRefresh" class="page-actions">
        <slot name="pageActions">
          <el-col>
            <el-button size="small" type="primary" @click.prevent="refresh()">Refresh</el-button>
          </el-col>
        </slot>
      </el-row>

      <div ref="main" :class="containerCssClass">
        <el-row class="native-table-outer-row">
          <el-col class="native-table-outer">
            <el-table
              ref="ElTable"
              v-loading="loading"
              :data="tableData"
              :height="tableHeight"
              :highlight-current-row="true"
              :row-class-name="rowClassName"
              size="mini"
              stripe
              sortable="custom"
              empty-text="There are no data to display for this resource or filter combination."
              @cell-dblclick="cellDblClick"
              @sort-change="changeSort"
              @selection-change="handleSelectionChange"
              @select-all="handleSelectAll"
            >
              <el-table-column
                v-if="tableOptions.allowSelection"
                :fixed="true"
                type="selection"
                width="40"
              >
                <!-- eslint-disable vue/no-unused-vars -->
                <template slot-scope="scope">
                  <slot
                    :row="scope.row"
                    name="selection"
                  >
                    <el-checkbox
                      v-if="allowRowSelection(scope.row)"
                      :value="isChecked(scope.row)" @change="toggleRowSelection(scope.row)"
                    />
                  </slot>
                </template>
              </el-table-column>
              <el-table-column
                :fixed="true"
                :width="getColumnWidth('_rowActions')"
              >
                <template v-if="hasFiltersApplied()" slot="header">
                  <div class="filters-clear">
                    <el-button size="mini" type="primary" @click="clearFilters">
                      Clear
                    </el-button>
                  </div>
                </template>
                <!-- eslint-disable vue/no-unused-vars -->
                <template slot-scope="scope">
                  <slot
                    :row="scope.row"
                    name="actions"
                  ></slot>
                  <el-button
                    v-for="(actionData, actionName) in rowActions"
                    :key="actionName"
                    :icon="actionData.icon ? actionData.icon : ''"
                    :type="actionData.type ? actionData.type : ''"
                    size="mini"
                    @click.native.stop.prevent="rowAction(actionName, scope.row)"
                  >
                <span v-if="actionData.label">
                  {{ actionData.label }}
                </span>
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column
                v-for="(column, indexNumber) in columnsToRender"
                :key="`${column}_${indexNumber}`"
                :class-name="getColumnClass(column)"
                :column-key="column"
                :fixed="isFixedLeft(column, indexNumber)"
                :label="column"
                :label-class-name="getColumnClass(column)"
                :prop="column"
                :show-overflow-tooltip="true"
                :sortable="getColumnSortable(column)"
                :width="getColumnWidth(column)"
              >
                <!-- eslint-disable vue/no-unused-vars -->
                <template slot="header" slot-scope="scope">
                  <div
                    :class="loading ? 'loading' : ''" class="header-search"
                    @click.stop="() => {}"
                  >

                    <el-input
                      v-if="getColumnFilterType(column) === 'text'"
                      v-model="searchKeywords[column]"
                      :fetch-suggestions="querySearchAsync"
                      clearable
                      placeholder="Filter"
                      @change="searchFilterChange($event, column)"
                    />

                    <el-select
                      v-if="getColumnFilterType(column) === 'select'"
                      v-model="searchKeywords[column]"
                      clearable
                      filterable
                      multiple
                      popper-class="header-search-with-groups"
                      @change="searchFilterChange($event, column)"
                      @focus="filterFocussed=column"
                    >
                      <el-option-group
                        v-if="getColumnFilters(column, 'F').length"
                      >
                        <el-option
                          v-for="(value, option_index) in getColumnFilters(column, 'F')"
                          :key="'f'+option_index"
                          :label="value"
                          :value="value"
                        />
                      </el-option-group>
                      <el-option-group
                        v-if="getColumnFilters(column, 'O').length"
                        class="other-filters"
                      >
                        <el-option
                          v-for="(value, option_index) in getColumnFilters(column, 'O')"
                          :key="'o'+option_index"
                          :class="filters[column] && filters[column].includes(value) ? 'hidden' : ''"
                          :label="value"
                          :value="value"
                        >
                          {{ value }}
                        </el-option>
                      </el-option-group>
                    </el-select>
                  </div>
                  {{ visibleTableColumnsNames[column] ?? column }}
                </template>
                <template slot-scope="scope">
                  <slot
                    :column="column"
                    :row="scope.row"
                  >
                    {{ getColumnValue(column, scope.row) }}
                  </slot>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row class="pagination-row">
          <el-col>
            <el-button class="setting" type="primary" @click="dialogConfigVisible = true"><i class="el-icon-setting"></i>
            </el-button>
            <Pagination
              :current-page="currentPage"
              :page-sizes="tableOptions.pageSizes"
              :per-page="perPageDefault"
              :total="total"
              @changePageSize="changePageSize"
              @pageChange="pageChange"
            />
          </el-col>
        </el-row>


        <el-row
          v-if="tableOptions.showRevisions && loadedRevisions && loadedRevisions.length"
          class="revision-card-wrapper"
        >
          <el-row class="toggle">
            Revisions

            <el-button
              class="toggle-button"
              type="primary"
              @click="revisionsVisible = !revisionsVisible"
            >
              <template v-if="revisionsVisible === true">
                Hide Revisions
              </template>
              <template v-if="revisionsVisible === false">
                Show Revisions
              </template>
            </el-button>
          </el-row>
          <div v-if="tableOptions.showRevisions && loadedRevisions && loadedRevisions.length">
            <revision-table
              :height="revisionTableHeight"
              :revisions="loadedRevisions"
              card-title="Revisions"
              type="general"
            >
            </revision-table>
          </div>
        </el-row>
      </div>
      <el-dialog
        :visible.sync="dialogConfigVisible"
        title="Configuration"
        top="0"
        @close="onDialogConfigClose"
      >
        <el-row class="top-row" type="flex">
          <el-col :span="4">
            <h4>Categories</h4>
            <div>
              <el-checkbox-group v-model="filteredCategories">
                <el-checkbox
                  v-for="(element, index) in loadedConfiguration.categories"
                  :key="element"
                  :label="element"
                >
                  {{ categoryLabels[element] ?? element }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-col>
          <el-col :offset="1" :span="8">
            <h4>Available Fields</h4>
            <el-button class="refresh-fields" size="mini" type="primary" @click="loadConfig()">Refresh</el-button>
            <span class="add-all" @click="addAllFieldsToVisible">Add All</span>
            <draggable
              v-if="showLists"
              :list="availableTableColumnsList"
              class="list-group"
              group="visibleFields"
            >
              <div
                v-for="(element, index) in availableTableColumnsList"
                :key="`available_${element.name}_${index}`"
                :title="element.key"
                class="list-group-item"
                @dblclick.prevent="addFieldToVisible(element)"
              >
                {{ element.name }}
                <i
                  v-if="
                  searchKeywords[element.id] &&
                  searchKeywords[element.id].length
                "
                  class="el-icon-warning has-filter"
                >
                <span>
                  filter(s): {{ Array.isArray(searchKeywords[element.id]) ? searchKeywords[element.id].join(', ') : searchKeywords[element.id] }}
                </span>
                </i>
                <span :class="'cat-' + element.category">
                {{ categoryLabels[element.category] ?? element.category }}
              </span>
              </div>
            </draggable>
          </el-col>
          <el-col :offset="1" :span="8">
            <h4>Selected Fields</h4>
            <span class="remove-all" @click="removeAllFieldsFromVisible">Remove All</span>
            <draggable
              v-if="showLists"
              :list="loadedConfiguration.visibleColumns"
              class="list-group"
              group="visibleFields"
            >
              <div
                v-for="(element, index) in loadedConfiguration.visibleColumns"
                :key="`selected_${element.name}_${index}`"
                class="list-group-item"
                @dblclick.prevent="removeFieldFromVisible(element)"
              >
                {{ element.name }}
                <i
                  v-if="
                  searchKeywords[element.id] &&
                  searchKeywords[element.id].length
                "
                  class="el-icon-warning has-filter"
                >
                <span>
                  filter(s): {{ Array.isArray(searchKeywords[element.id]) ? searchKeywords[element.id].join(', ') : searchKeywords[element.id] }}
                </span>
                </i>
                <span :class="'cat-' + element.category">
                {{ categoryLabels[element.category] ?? element.category }}
              </span>
              </div>
            </draggable>
          </el-col>
        </el-row>
        <el-row class="bottom-row" type="flex">
          <el-col :span="22" justify="right">
            <el-button
              class="setting"
              type="primary"
              @click="dialogConfigVisible = false"
            >
              Close
            </el-button>
          </el-col>
        </el-row>
      </el-dialog>
    </div>
</template>
<script>
import {formatErrorMessage, logErrorMessage} from '@/js/common/util';
import Pagination from '@/js/components/Common/DefaultPagination.vue';
import draggable from 'vuedraggable'
import * as util from '@/js/common/util';
import RevisionTable from '@/js/components/Common/RevisionTable.vue';
import moment from 'moment';

export default {
  name: 'DataTableAlternate',
  components: {
    RevisionTable,
    draggable,
    Pagination,
  },
  props: {
    // The api used by this table is passed in from the parent
    api: {
      type: Object,
      required: true,
    },
    indexMethod: {
      type: String,
      required: false,
      default: () => {
        return 'index'
      },
    },
    tableName: {
      type: String,
      required: true,
    },
    // Pass data through from tha parent component, if any given.
    // This is useful if there are data to always pass to the controller that is separate to the
    // filter/sorting function applied here
    requestData: {
      type: Object,
      required: false,
      default: () => {
      },
    },
    // Table options passed from the parent
    dataTableOptions: {
      type: Object,
      required: false,
      default: () => {
      },
    },
    filterTypes: {
      type: Object,
      required: false,
      default: () => {
      },
    },
    rowActions: {
      type: Object,
      required: false,
      default: () => {
      },
    },
    filterPreset: {
      type: Object,
      default: () => {
      },
    },
    fieldCategoryPreset: {
      type: Array,
      default: () => [],
    },
    fieldCategoryLabels: {
      type: Object,
      default: () => {
      },
    },
    fieldCategoryDefaults: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      windowHeight: window.innerHeight,
      // Defaults for table options
      tableOptionDefaults: {
        allowSelection: false,
        showRefresh: false,
        showRevisions: false,
        tableHeightOffset: 150,
        pageSizes: [15, 30, 50, 100],
      },
      // defaults for column display options
      fixedTableColumns: [],
      visibleTableColumns: [],
      visibleTableColumnsNames: {},
      visibleTableColumnsKeys: {},
      // Loading placeholder
      loading: null,
      renderTable: false,
      checkedItems: [],
      // Pagination
      currentPage: 1,
      total: null,
      perPageDefault: 30,
      // Fix the first n columns
      maxFixedColumns: 0,
      // Table data, filters and selection
      tableData: [],
      filters: {},
      searchKeywords: [],
      filterFocussed: null,
      // Column selection
      dialogConfigVisible: false,
      filteredCategories: ['general'],
      loadedConfiguration: {
        visibleColumns: null,
        categories: ['general'],
        possibleColumns: []
      },
      availableTableColumnsList: [],
      categoryLabels: {
        general: 'General',
      },
      loadedRevisions: [],
      revisionsVisible: false,
      showLists: true,
    }
  },
  computed: {
    perPage() {
      if(this.tableOptions.pageSizes.includes(this.perPageDefault)) {
        return this.perPageDefault
      }
      return this.tableOptions.pageSizes[0]
    },
    tableOptions() {
      return {...this.tableOptionDefaults, ...this.dataTableOptions}
    },
    tableHeight() {
      // When the revisions are visible the table need to be small
      let height = this.windowHeight
      if (this.revisionsVisible) {
        height = 500
      }
      height -= this.tableOptions.tableHeightOffset
      if (this.tableOptions.showRevisions && this.loadedRevisions && this.loadedRevisions.length) {
        height -= 50
      }
      return `${height}`
    },
    revisionTableHeight() {
      // The revisions table must be small enough to fit without the page getting a scrollbar
      return `${this.windowHeight - 500}`
    },
    columnsToRender() {
      if (this.visibleTableColumns === undefined) {
        return [];
      }
      return this.fixedTableColumns.concat(this.visibleTableColumns);
    },
    containerCssClass() {
      let classes = []
      classes.push(this.revisionsVisible ? 'show-revisions' : 'hide-revisions')
      classes.push(this.tab)
      return classes.join(' ')
    },
  },
  watch: {
    filteredCategories() {
      this.filterAvailableTableColumnsList()
    },
    loading(newValue) {
      if (!newValue) {
        this.$nextTick(() => {
          this.updateVisibleFields()
          this.$nextTick(() => {
            this.leftTableOffset()
            this.updateFiltersLabels()
          })
        })
      }
    },
    'loadedConfiguration.visibleColumns'(newValue) {
      this.visibleTableColumns = [];
      for (const key in newValue) {
        this.visibleTableColumns.push(newValue[key].id)
      }
    }
  },
  mounted() {
    if (localStorage[this.tableName + '_visibleColumns']) {
      this.loadedConfiguration.visibleColumns = JSON.parse(localStorage[this.tableName + '_visibleColumns'])
    }
    this.loadConfig()
    if (this.fieldCategoryPreset?.length) {
      this.filteredCategories = this.fieldCategoryPreset
    }
    if (this.fieldCategoryLabels) {
      this.categoryLabels = this.fieldCategoryLabels
    }
  },
  methods: {
    commaSeparate(value) {
      if (!value) {
        return ''
      }
      if (Array.isArray(value)) {
        return value.join(', ')
      }
      return value
    },
    shortDate(value) {
      if (!value) {
        return ''
      }
      return moment(String(value)).format('DD.MM.YYYY')
    },
    longDate(value) {
      if (!value) {
        return ''
      }
      return moment(String(value)).format('DD.MM.YYYY hh:mm')
    },
    money(value) {
      if(value == 0) {
        return '0.00'
      }
      if (!value) {
        return ''
      }
      return value.toFixed(2)
    },
    refresh(reloadConfig = false) {
      if (reloadConfig) {
        this.tableData = []
        this.loadConfig()
      } else {
        this.loadPage()
      }
    },
    handleSelectionChange(val) {
      this.checkedItems = val
      this.$emit('selection-change', val)
    },
    handleSelectAll(val) {
      const self = this
      val = val.filter((row) => {
        return self.allowRowSelection(row)
      })
      this.$emit('select-all', val)
    },
    isChecked(row) {
      for (const i in this.checkedItems) {
        if (this.checkedItems[i] == row) {
          return true
        }
      }
      return false
    },
    allowRowSelection(row) {
      if (typeof this.$parent.allowRowSelection === 'function') {
        return this.$parent.allowRowSelection(row)
      }
      return true
    },
    toggleRowSelection(row) {
      this.$refs.ElTable.toggleRowSelection(row)
    },
    changeSort({prop, order}) {
      this.orderBy = prop
      if (order === 'ascending') {
        this.order = 'asc'
      } else if (order === 'descending') {
        this.order = 'desc'
      } else {
        this.order = null
      }
      if (this.order === null || this.orderBy === null) {
        this.order = null
        this.orderBy = null
      }
      this.pageChange(1)
      return false
    },
    hasFiltersApplied() {
      let filters = this.filterSearchKeywords()
      return Object.keys(filters).length > 0;
    },
    clearFilters() {
      this.searchKeywords = {}
      this.loadPage(1)
    },
    updateFiltersLabels() {
      let filter_selects = document.querySelectorAll(
        '.header-search .el-select'
      )
      for (let i = 0; i < filter_selects.length; i++) {
        const number_filters = filter_selects[i].querySelectorAll('.el-tag')
        if (!number_filters || number_filters.length === 0) {
          filter_selects[i].querySelectorAll('input')[0].placeholder = ''
          continue
        }
        let suffix_string = '1 filter'
        if (number_filters.length > 1) {
          suffix_string = number_filters.length + ' filters'
        }
        filter_selects[i].querySelectorAll('input')[0].placeholder =
          suffix_string
      }
    },
    getColumnFilterType(column) {
      if (this.loadedConfiguration.possibleColumns[column] && this.loadedConfiguration.possibleColumns[column].filter) {
        return this.loadedConfiguration.possibleColumns[column].filter;
      }
      if (this.filterTypes[column]) {
        return this.filterTypes[column]
      }
      return 'select'
    },
    getColumnSortable(column) {
      if (this.loadedConfiguration.possibleColumns[column] && this.loadedConfiguration.possibleColumns[column].sortable) {
        if (this.loadedConfiguration.possibleColumns[column].sortable === 'disabled') {
          return false
        }
        if (this.loadedConfiguration.possibleColumns[column].sortable === 'enabled') {
          return 'custom'
        }
        //return this.loadedConfiguration.possibleColumns[column].sortable
      }
      return 'custom'
    },
    getColumnValue(column, row) {
      let value = null
      if (this.visibleTableColumnsKeys[column] && this.visibleTableColumnsKeys[column].indexOf('.') > 0) {
        const keys = this.visibleTableColumnsKeys[column].split('.');
        value = (row[column] && row[column][keys[1]]) ? row[column][keys[1]] : ''
      } else {
        value = row[column] || ''
      }
      // Apply a formatted if needed
      if (this.loadedConfiguration.possibleColumns[column] && this.loadedConfiguration.possibleColumns[column].format) {
        return this[this.loadedConfiguration.possibleColumns[column].format](value)
      }

      return value
    },
    getColumnFilters(column, group) {
      if (this.filterFocussed !== column) {
        return []
      }
      if (group === 'F') {
        if (!this.filters[column]) {
          return []
        }
        return this.filters[column];
      }
      if (!this.filtersAllPossible || !this.filtersAllPossible[column]) {
        return []
      }
      return this.filtersAllPossible[column];
    },
    isFixedLeft(column, indexNumber) {
      if (indexNumber >= this.maxFixedColumns) {
        return false
      }
      if (this.fixedTableColumns.includes(column)) {
        return true
      }
      return false
    },
    getColumnWidth(column) {
      if (this.tableOptions && this.tableOptions.columnWidth && this.tableOptions.columnWidth[column]) {
        return this.tableOptions.columnWidth[column]
      }
      if (['id'].includes(column)) {
        return 100
      }
      return (this.visibleTableColumnsNames[column] ?? column)?.length * 10 + 40
    },
    leftTableOffset() {
      let column = null
      let fixed = null
      let width = 0
      for (const indexNumber in this.columnsToRender) {
        column = this.columnsToRender[indexNumber]
        fixed = this.isFixedLeft(column, indexNumber)
        if (!fixed) {
          continue
        }
        width += this.getColumnWidth(column)
      }
      width += this.getColumnWidth('_rowActions')
      if (this.tableOptions.allowSelection) {
        width += 40;
      }
      let tables_bodies = document.querySelectorAll(
        '.native-table-outer-row  .el-table--scrollable-x .el-table__body-wrapper > table'
      )
      for (let i = 0; i < tables_bodies.length; i++) {
        tables_bodies[i].style['margin-left'] = '-' + width + 'px'
      }
      let tables_headers = document.querySelectorAll(
        '.native-table-outer-row  .el-table--scrollable-x .el-table__header-wrapper > table'
      )
      for (let i = 0; i < tables_headers.length; i++) {
        tables_headers[i].style['margin-left'] = '-' + width + 'px'
      }
      let tables_bodies_wrap = document.querySelectorAll(
        '.native-table-outer-row  .el-table--scrollable-x .el-table__body-wrapper'
      )
      for (let i = 0; i < tables_bodies_wrap.length; i++) {
        tables_bodies_wrap[i].style['margin-left'] = width + 'px'
        tables_bodies_wrap[i].style['width'] = 'calc(100% - ' + width + 'px)'
      }
      let tables_headers_wrap = document.querySelectorAll(
        '.native-table-outer-row  .el-table--scrollable-x .el-table__header-wrapper'
      )
      for (let i = 0; i < tables_headers_wrap.length; i++) {
        tables_headers_wrap[i].style['margin-left'] = width + 'px'
        tables_headers_wrap[i].style['width'] = 'calc(100% - ' + width + 'px)'
      }
    },
    getColumnClass(column) {
      if (this.hasFilterApplied(column)) {
        return 'has-applied-filter'
      }
      return ''
    },
    hasFilterApplied(column) {
      if (
        this.searchKeywords[column] &&
        this.searchKeywords[column].length > 0
      ) {
        return true
      }
      return false
    },
    changePageSize(newPageSize) {
      this.perPageDefault = newPageSize
      this.pageChange(1)
    },
    pageChange(val) {
      this.currentPage = val
      this.loadPage(val)
    },
    searchFilterChange(value, field) {
      this.searchKeywords[field] = value
      this.loadPage(1)
    },
    querySearchAsync() {
    },
    filterSearchKeywords() {
      let keywordsFiltered = {}
      for (const field in this.searchKeywords) {
        let value = this.searchKeywords[field]
        if (value && value.length > 0) {
          if (this.visibleTableColumns.includes(field)) {
            keywordsFiltered[field] = value
          } else if (this.fixedTableColumns.includes(field)) {
            keywordsFiltered[field] = value
          }
        }
      }
      return keywordsFiltered
    },
    loadConfig() {
      this.api[this.indexMethod](
        (response) => {
          const prevState = this.dialogConfigVisible;
          if (prevState) {
            this.dialogConfigVisible = false;
          }
          this.loadedConfiguration.possibleColumns = {}
          let preLoadColumns = false
          if (!this.loadedConfiguration.visibleColumns) {
            this.loadedConfiguration.visibleColumns = [];
            preLoadColumns = true
          }

          for (const key in response) {
            const fid = response[key].id
            this.loadedConfiguration.possibleColumns[fid] = response[key]
            if (!this.loadedConfiguration.categories.includes(response[key].category)) {
              this.loadedConfiguration.categories.push(response[key].category)
            }
            if (preLoadColumns && this.fieldCategoryDefaults.length && this.fieldCategoryDefaults.includes(response[key].category)) {
              this.loadedConfiguration.visibleColumns.push(response[key])
            }
          }

          // Clean Visible Columns in case the possible have changed
          this.loadedConfiguration.visibleColumns = this.loadedConfiguration.visibleColumns.filter((column) => {
            return this.loadedConfiguration.possibleColumns[column.id]
          })

          this.$nextTick(() => {
            if (prevState) {
              this.dialogConfigVisible = prevState;
            }
          });

          this.loading = false
          this.updateVisibleFields()
          if (this.tableData.length <= 0) {
            for (const field in this.filterPreset) {
              this.searchKeywords[field] = this.filterPreset[field]
            }
            this.loadPage(1)
          }
        },
        (error) => {
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        {
          ...{
            loadAfConfig: true,
            isAfTable: true
          },
          // Pass data through from tha parent component, if any given
          ...this.requestData
        }
      )
    },
    loadPage(page = null) {
      const keywords = JSON.stringify(this.filterSearchKeywords())
      this.$nextTick(() => {
        this.loading = true
      });
      this.api[this.indexMethod](
        (response) => {
          // row and column data
          this.tableData = response.data
          // Set filter values
          if (response.filters) {
            this.filters = response.filters
          }
          if (response.filtersAllPossible) {
            this.filtersAllPossible = response.filtersAllPossible
          }
          // paging information
          this.total = response.meta.total
          this.currentPage = response.meta.current_page
          this.$nextTick(() => {
            this.loading = false
            this.renderTable = true
          });
          this.$emit('page-loaded', response);
          if (this.tableOptions.showRevisions) {
            this.loadRevisions()
          }
        },
        (error) => {
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        {
          ...{
            page: page ?? this.currentPage,
            perPage: this.perPage,
            orderBy: this.orderBy,
            orderDirection: this.order,
            searchKeywords: keywords,
            isAfTable: true,
            visibleColumns: this.visibleTableColumns,
          },
          // Pass data through from tha parent component, if any given
          ...this.requestData
        }
      )
    },
    loadRevisions() {
      this.api.revisions(
        (response) => {
          this.loadedRevisions = response
        },
        (error) => {
          this.$message.error({
            showClose: true,
            type: 'error',
            message: util.formatErrorMessage(error),
            duration: 0,
          })
        }
      )
    },
    onDialogConfigClose() {
      this.loading = true;
      this.renderTable = false
      this.$nextTick(() => {
        this.loading = false;
        this.renderTable = true
        this.refresh()
      })
    },
    addAllFieldsToVisible() {
      for (const index in this.availableTableColumnsList) {
        const column = this.availableTableColumnsList[index]
        this.loadedConfiguration.visibleColumns.push(column)
      }
      this.filterAvailableTableColumnsList()
    },
    addFieldToVisible(element) {
      this.loadedConfiguration.visibleColumns.push(element)
      this.filterAvailableTableColumnsList()
      this.updateVisibleFields()
    },
    removeFieldFromVisible(element) {
      this.loadedConfiguration.visibleColumns = this.loadedConfiguration.visibleColumns.filter((elem) => elem !== element);
      this.filterAvailableTableColumnsList()
      this.updateVisibleFields()
    },
    removeAllFieldsFromVisible() {
      for (const index in this.loadedConfiguration.visibleColumns) {
        const column = this.loadedConfiguration.visibleColumns[index]
        this.availableTableColumnsList.push(column)
      }
      this.loadedConfiguration.visibleColumns = []
      this.filterAvailableTableColumnsList()
    },
    updateVisibleFields() {
      // Set the table fields/columns
      this.visibleTableColumns = []
      this.fixedTableColumns = []
      this.visibleTableColumnsNames = {}
      this.visibleTableColumnsKeys = {}
      // set Fixed Columns
      for (const index in this.loadedConfiguration.possibleColumns) {
        const column = this.loadedConfiguration.possibleColumns[index]
        const colKey = column.id.toString()
        if (this.fixedTableColumns.length < this.maxFixedColumns && !column.hidden) {
          this.fixedTableColumns.push(colKey)
          this.visibleTableColumnsNames[colKey] = column.name
          this.visibleTableColumnsKeys[colKey] = column.key ?? colKey
        }
      }
      // and Also other columns
      if (this.loadedConfiguration.visibleColumns.length > 0) {
        for (const index in this.loadedConfiguration.visibleColumns) {
          const column = this.loadedConfiguration.visibleColumns[index]
          const colKey = column.id.toString()
          if (!this.fixedTableColumns.includes(colKey) && !column.hidden) {
            this.visibleTableColumns.push(colKey)
            this.visibleTableColumnsNames[colKey] = column.name
            this.visibleTableColumnsKeys[colKey] = column.key ?? colKey
          }
        }
      } else {
        for (const index in this.loadedConfiguration.possibleColumns) {
          const column = this.loadedConfiguration.possibleColumns[index]
          const colKey = column.id.toString()
          if (!this.fixedTableColumns.includes(colKey) && !column.hidden) {
            this.visibleTableColumns.push(colKey)
            this.visibleTableColumnsNames[colKey] = column.name
            this.visibleTableColumnsKeys[colKey] = column.key ?? colKey
          }
        }
      }
      localStorage[this.tableName + '_visibleColumns'] = JSON.stringify(this.loadedConfiguration.visibleColumns)
      this.filterAvailableTableColumnsList()
    },
    filterAvailableTableColumnsList() {
      let visible = []
      this.availableTableColumnsList = []
      for (const index in this.loadedConfiguration.visibleColumns) {
        const column = this.loadedConfiguration.visibleColumns[index]
        visible.push(column.id)
      }
      let assignedToFixed = 0
      for (const index in this.loadedConfiguration.possibleColumns) {
        const column = this.loadedConfiguration.possibleColumns[index]
        if (!visible.includes(column.id)) {
          if (this.filteredCategories.includes(column.category)) {
            if (assignedToFixed >= this.maxFixedColumns) {
              if (!column.hidden) {
                this.availableTableColumnsList.push(column)
              }
            } else {
              assignedToFixed++
            }
          }
        }
      }
    },
    cellDblClick(row) {
      this.$emit('cell-dblclick', row)
    },
    archiveItem(item) {
      const identifyingName = item.name
      this.$confirm(`Do you want to archive "${identifyingName}"?`, 'Warning', {
        confirmButtonText: 'Archive',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        this.api.archive(
          () => {
            this.$message.success({
              showClose: true,
              message: `${identifyingName} has been archived.`,
              duration: 5000,
            })
            this.refresh()
          },
          (error) => {
            let errorMessage = `An error occurred while archiving the ${this.vocabulary.single}. Please try again.`
            if (error) {
              logErrorMessage(error)
              errorMessage = error.message
            }
            this.$message.error({
              showClose: true,
              message: errorMessage,
              duration: 5000,
            })
          },
          item.id
        )
      })
    },
    rowAction(action, data) {
      if (this.rowActions[action].emit === false) {
        if (this[action]) {
          this[action](data)
        }
        return;
      }
      this.$emit('row-action', {action, data});
      this.$emit(`row-action-${action}`, data);
    },
    rowClassName(tableRow) {
      if (this.tableOptions.rowClassName) {
        return this.tableOptions.rowClassName(tableRow);
      }
      return ''
    }
  }
}
</script>
<style lang="scss">
.data-table-alternate {
  .page-actions {
    margin-bottom:0.5rem;
  }
  .el-table th.el-table__cell>.cell,
  .el-table td.el-table__cell>.cell {
    padding-right:5px;
    padding-left:5px;
  }
  .el-table--mini th.el-table__cell.has-applied-filter {
    background: rgba(64, 158, 255, 0.5);
  }

  .el-table .el-table-column--selection .cell {
    padding-left: 0;
    padding-right: 0;
    text-align: center;

    .el-checkbox__input {

    }
  }
  .el-table__empty-block {
    padding-left:1.25em;
    justify-content: normal;
    text-align: initial;
    -webkit-box-align: initial;
    -ms-flex-align: initial;
    align-items: normal;
    font-size: 1.25em;
    font-weight: bold;
  }
  .el-table--scrollable-x .el-table__empty-block {
    padding-left:0;
  }
  .filters-clear {
    text-align: center;
  }

  .table-card {
    margin-top: 0 !important;

    .el-card__body {
      padding: 0;
    }
  }

  table .el-select .el-input__inner {
    height: 23px !important;
  }

  .el-select-dropdown__item {
    padding: 0px 10px;
  }

  .cell-content {
    .editable-icon {
      position: absolute;
      right: 5px;
      opacity: 0;
      top: 0;

      el-icon-edit {
        font-size: 12px;
      }
    }

    .el-input--small .el-input__inner {
      height: 23px;
    }
  }

  .cell-content:hover {
    .editable-icon {
      opacity: 1;
    }

    cursor: pointer;
  }

  .finalized-bids .cell-content:hover,
  .closed-bids .cell-content:hover {
    cursor: default;
  }

  .cell-input {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;

    .el-input__inner {
      height: 27px;
    }
  }

  .el-dialog {
    top: 3em;
    bottom: 3em;
    left: 3em;
    right: 3em;
    width: auto;
    overflow: auto;
    position: fixed;
    margin: 0;

    .bottom-row {
      text-align: right;
      padding-top: 60px;
    }
  }

  .el-dialog__body {
    padding-bottom: 60px;
  }

  .list-group {
    width: 100%;
    min-height: calc(100%);
    border: 1px solid rgba(206, 206, 206, 0.5);
    list-style-type: none;
    counter-reset: css-counter 0;

    .list-group-item {
      padding: 0.25rem 0.5rem;
      border-bottom: 1px solid rgba(206, 206, 206, 0.5);
      cursor: pointer;
      counter-increment: css-counter 1;

      span {
        background: purple;
        border-radius: 5px;
        display: inline-block;
        float: right;
        color: white;
        font-size: 0.85em;
        padding: 0.25em 0.5em;
      }

      .has-filter {
        span {
          visibility: hidden;
        }

        &:hover {
          span {
            visibility: visible;
          }
        }
      }

      span.cat-bid_management {
        background: green;
      }

      span.cat-controlling {
        background: darkorange;
      }

      span.cat-engineering {
        background: darkslateblue;
      }

      span.cat-project {
        background: darkred;
      }

      span.cat-general {
        background: teal;
      }
    }

    .list-group-item:before {
      content: counter(css-counter) '. ';
    }

    .list-group-item:last-child {
      border-bottom: none;
    }

    .sortable-chosen {
      padding: 0.25rem 1rem;
      background: rgba(206, 206, 206, 0.5);
    }
  }

  .add-all,
  .remove-all,
  .refresh-fields {
    float: right;
    cursor: pointer;
    margin-top: -2em;
  }

  .refresh-fields {
    margin-right: 5em;
    margin-top: -2.5em;
  }

  .pagination-row {
    margin-top: 1em;

    .setting {
      cursor: pointer;
      height: 28px;
      line-height: 28px;
      width: 28px;
      padding: 0;
    }

    .el-pagination {
      margin-top: 0;
    }

    .export {
      cursor: pointer;
      height: 28px;
      line-height: 28px;
      padding: 0 15px;
    }
  }

  .el-checkbox {
    display: block;
    margin: 0;
    padding: 0;

    .el-checkbox__input {
      float: right;
    }

    .el-checkbox__label {
      padding: 0;
    }
  }

  .el-checkbox:before,
  .el-checkbox:after {
    clear: both;
    content: '';
    height: 0;
    line-height: 0;
    overflow: hidden;
    display: block;
  }

  h4 {
    padding: 0;
    font-size: 1em;
    margin: 0 0 1em 0;
  }

  .header-search {
    min-height: 24px;
    .el-select {
      width: 100%;
    }
    .el-input {
      .el-input__inner {
        height: 23px;
      }
    }

    select {
      width: 100%;
      display: block;
      border-radius: 3px;
      border: 1px solid #dde0e5;
    }

    .el-select__input input {
      margin-left: 5px;
      cursor: pointer;
    }

    select[disabled] {
      visibility: hidden;
    }

    span.col-label {
      display: block;
      margin: 0.5em;
    }

    .el-input__suffix {
      display: block;
      margin: 0;
      padding: 0;
      position: absolute;

      .el-input__suffix-inner {
        display: block;
        margin: 0;
        padding: 0;
        position: absolute;
        right: 0;

        .el-input__icon {
          line-height: 23px;
        }
      }
    }

    .el-select + span {
      display: block;
      margin: 0;
    }

    .el-select-dropdown__item {
      padding-right: 50px;
    }

    .el-select-dropdown__item.selected::after {
      right: 6px;
      top: 10px;
    }

    .el-select__tags {
      top: auto;
      transform: initial;
      bottom: -1px;
      width: 100%;
      max-width: 100% !important;
    }

    .el-select__tags > span {
      display: none;
    }
  }

  .header-search.loading .el-select {
    visibility: hidden;
  }

  .revision-card-wrapper {
    .toggle-button {
      float: right;
    }

    margin-top: 1em;

    .toggle {
      padding: 0.5em 0.5em 0.5em 1em;
      line-height: 35px;
      font-size: 15px;
      border: 1px solid #d1dbe5;
      border-radius: 4px;
      background: #fff;
      overflow: hidden;
    }
  }

  .revision-card .el-card__header {
    display: none;
  }

  .revision-card .el-card__body .el-table__body-wrapper {
    max-height: 49vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .hide-revisions {
    .revision-card {
      display: none;
    }
  }

  .native-table-outer-row {
    position: relative;

    .native-table-outer {
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      background-color: #fff;
    }
  }

  .show-revisions .native-table-outer-row {

  }

  .el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: auto;
  }

  .text-right {
    text-align: right;
  }

  .question-dialog .el-dialog {
    width: auto;
    max-width: 640px;
    margin: 0 auto;
    height: auto;
    top: 3em;
    bottom: auto;

    .el-dialog__body {
      padding-bottom: 30px;
    }

    .el-checkbox .el-checkbox__input {
      float: none;
      margin-right: 10px;
    }
  }

  .bulk-actions {
    margin-bottom: 5px;
    float: left;
  }

  .bid-add-button {
    float: right;
  }
}

.header-search-with-groups {
  .el-select-group__wrap.other-filters {
    .el-select-dropdown__item {
      opacity: 0.35;
    }

    .el-select-dropdown__item.selected,
    .el-select-dropdown__item.hover {
      opacity: 0.5;
    }
  }
}
</style>

