<template>
  <div>
    <!-- FILTERS -->
    <el-form class="form-box" label-position="top" label-width="120px">
      <el-row :gutter="10" class="row-bg" type="flex">
        <el-col :span="6">
          <el-form-item>
            <el-select
              v-model="selectedProjectId"
              clearable
              filterable
              placeholder="Select project..."
              @input="
                fetchAreas(selectedProjectId)
                fetchCategories(selectedProjectId)
              "
            >
              <el-option
                v-for="project in projects"
                :key="project.id"
                :label="project.name"
                :value="project.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item style="margin-bottom: 3px">
            <el-select
              v-model="selectedCategoryId"
              :disabled="!selectedProjectId"
              clearable
              filterable
              placeholder="Select category..."
              @input="selectedSubcategoryName = null"
            >
              <el-option
                v-for="(category, index) in categories"
                :key="index"
                :label="category.name"
                :value="category.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="selectedCategoryId && filteredSubcategories.length"
          >
            <el-select
              v-model="selectedSubcategoryName"
              clearable
              filterable
              placeholder="Select subcategory..."
            >
              <el-option
                v-for="subcategory in filteredSubcategories"
                :key="subcategory.id"
                :label="subcategory.subcategoryName"
                :value="subcategory.subcategoryName"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item>
            <el-select
              v-model="selectedUserId"
              clearable
              filterable
              placeholder="Select user..."
            >
              <el-option
                v-for="worker in workers"
                :key="worker.id"
                :label="worker.name"
                :value="worker.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item style="margin-bottom: 3px">
            <el-select
              v-model="selectedAreaId"
              :disabled="!selectedProjectId"
              clearable
              placeholder="Select area..."
              @input="selectedLocationTypeAndId = null"
            >
              <el-option
                v-for="area in areas"
                :key="area.id"
                :label="area.name"
                :value="area.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item>
            <el-select
              v-model="selectedLocationTypeAndId"
              :disabled="!selectedProjectId"
              clearable
              filterable
              placeholder="Select location..."
            >
              <template v-for="locationType in ['streets', 'mfgs', 'muffen']">
                <el-option-group
                  v-if="locations && locations[locationType]"
                  :key="locationType"
                  :label="locationType"
                >
                  <el-option
                    v-for="location in locations[locationType]"
                    :key="location.id"
                    :label="location.name"
                    :value="locationType + '-' + location.id"
                  />
                </el-option-group>
              </template>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="max-width: 160px; display: inline-block">
            <el-select v-model="selectedDateType">
              <el-option :value="DATE_START_END" label="startdate/enddate"/>
              <el-option :value="DATE_CREATED" label="created between"/>
              <el-option :value="DATE_UPDATED" label="updated between"/>
            </el-select>
          </el-form-item>
          <el-form-item style="max-width: 150px; display: inline-block">
            <el-date-picker
              v-model="startAndEndDate"
              clearable
              placeholder="Pick a start/end date..."
              type="daterange"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-button
        :loading="loading"
        size="medium"
        type="primary"
        @click="getDatesReport()"
      >
        Search
      </el-button>
    </el-form>

    <!-- TABLE -->
    <div v-loading="loading" class="lerfs-reporting-table">
      <template v-if="data">
        <el-table
          v-loading="spinner"
          :data="data"
          :highlight-current-row="true"
          :max-height="maxHeight"
          border
          class="weekly-report"
          size="mini"
          stripe
          @sort-change="changeSort"
          @filter-change="changeFilter"
        >
          <el-table-column
            :show-overflow-tooltip="true"
            label="#"
            prop="id"
            width="60"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Description"
            prop="description"
            sortable="custom"
            width="200"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Position #"
            prop="positionId"
            width="90"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Project"
            prop="projectName"
            sortable="custom"
            width="100"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Category"
            prop="categoryName"
            width="120"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Subcategory"
            prop="subcategoryName"
            width="100"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Team"
            prop="team"
            sortable="custom"
            width="100"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Executor"
            prop="executor"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="User"
            prop="user"
            sortable="custom"
            width="80"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Cost obj."
            prop="costCenter"
            sortable="custom"
            width="100"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Cost object code"
            prop="costObjectCode"
            sortable="custom"
            width="150"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Area Name"
            prop="areaName"
            sortable="custom"
            width="200"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Length"
            prop="length"
            sortable="custom"
            width="100"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Width"
            prop="width"
            sortable="custom"
            width="100"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Height"
            prop="height"
            sortable="custom"
            width="100"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Amount"
            prop="menge"
            sortable="custom"
            width="100"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Factor"
            prop="factor"
            sortable="custom"
            width="100"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Price"
            prop="price"
            sortable="custom"
            width="100"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Gross"
            prop="gross"
            sortable="custom"
            width="100"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Net"
            prop="net"
            sortable="custom"
            width="80"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Origin"
            prop="origin"
            sortable="custom"
            width="120"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Lerf Status"
            prop="lerfStatus"
            sortable="custom"
            width="140"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="External Reference"
            prop="externalReference"
            sortable="custom"
            width="160"
          />
          <el-table-column
            :formatter="(r, c, val) => formatDateFromData(val)"
            :show-overflow-tooltip="true"
            label="Start"
            prop="startDate"
            sortable="custom"
            width="90"
          />
          <el-table-column
            :formatter="(r, c, val) => formatDateFromData(val)"
            :show-overflow-tooltip="true"
            label="End"
            prop="endDate"
            sortable="custom"
            width="90"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Comments"
            prop="comments"
            width="200"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Created at"
            prop="createdAt"
            sortable="custom"
            width="145"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Updated at"
            prop="updatedAt"
            sortable="custom"
            width="145"
          />
        </el-table>

        <el-row class="bottom-row">
          <el-col :span="12">
            <el-button
              :loading="generatingExcelFile"
              type="primary"
              @click="generateExcelFile('xlsx')"
            >Download excel file
            </el-button
            >
            <el-button
              :loading="generatingExcelFile"
              type="primary"
              @click="generateExcelFile('csv')"
            >Download csv file
            </el-button
            >
          </el-col>
          <el-col :span="12">
            <el-pagination
              :background="true"
              :current-page.sync="currentPage"
              :page-size="perPage"
              :pager-count="5"
              :small="true"
              :total="total"
              class="pagination"
              layout="sizes, total, prev, pager, next"
              @current-change="perPageChange"
              @size-change="changePageSize"
            />
          </el-col>
        </el-row>
      </template>
    </div>
  </div>
</template>

<script>
import camelCaseKeys from 'camelcase-keys'
import moment from 'moment'

import {
  formatDate,
  formatDateForRequest,
  formatErrorMessage,
} from 'common/util'

import lerfReportApi from 'api/v2/lerfReport'
import projectsApi from 'api/v2/projects'
import usersApi from 'api/v2/users'
import projectPricingCategoriesApi from 'api/v2/projectPricingCategories'
import pricingSubcategoriesApi from 'api/v2/pricingSubcategories'
import projectAreasApi from 'api/v2/projectAreas'
import {logErrorMessage} from '@/js/common/util';

const DATE_START_END = Symbol('DATE_START_END')
const DATE_CREATED = Symbol('DATE_CREATED')
const DATE_UPDATED = Symbol('DATE_UPDATED')

export default {
  name: 'LerfReportTable',

  data() {
    const lastWeek = moment().subtract('1', 'weeks').format('YYYY-MM-DD')
    const today = moment().format('YYYY-MM-DD')
    return {
      data: [],
      projects: [],
      users: [],
      id: null,
      currentPage: 1,
      total: null,
      perPage: 50,
      loading: false,
      generatingExcelFile: false,

      spinner: true,

      startAndEndDate: [lastWeek, today],
      DATE_START_END,
      DATE_CREATED,
      DATE_UPDATED,
      selectedDateType: DATE_START_END,

      selectedProjectId: null,

      selectedUserId: null,

      selectedCategoryId: null,
      selectedSubcategoryName: null,
      categories: [],
      subcategories: [],

      selectedAreaId: null,
      areas: [],

      selectedLocationTypeAndId: null,

      showItemsWithoutDate: 0,

      quickPicks: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: 'Today',
            onClick(picker) {
              picker.$emit('pick', new Date())
            },
          },
          {
            text: 'Yesterday',
            onClick(picker) {
              const date = new Date(Date.now() - 3600 * 24 * 1000)
              picker.$emit('pick', date)
            },
          },
        ],
      },
    }
  },

  computed: {
    maxHeight() {
      return window.innerHeight - 250
    },
    workers() {
      return this.users.filter((user) => {
        return (
          [
            'worker',
            'project_user',
            'project_leader',
            'project_supervisor',
            'subcontractor_supervisor',
          ].indexOf(user.role) !== -1
        )
      })
    },
    filteredSubcategories() {
      if (!this.selectedProjectId) {
        return []
      }

      if (!this.selectedCategoryId) {
        return this.subcategories
      }

      const selectedCategory = this.categories.find(
        (c) => c.id === this.selectedCategoryId
      )

      return this.subcategories.filter(
        (subcategorie) => subcategorie.categoryName === selectedCategory.name
      )
    },
    locations() {
      if (!this.selectedProjectId) {
        return null
      }

      if (!this.selectedAreaId) {
        return {
          streets: this.areas.map((area) => area.streets).flat(),
          mfgs: this.areas.map((area) => area.mfgs).flat(),
          muffen: this.areas.map((area) => area.muffen).flat(),
        }
      }
      const area = this.areas.find((area) => area.id === this.selectedAreaId)
      return {
        streets: area.streets,
        mfgs: area.mfgs,
        muffen: area.muffen,
      }
    },
  },

  created() {
    this.fetchProjects()
    this.fetchUsers()
    this.fetchSubcategories()
  },

  mounted() {
    this.loadPage()
  },

  methods: {
    fetchProjects() {
      projectsApi.index(
        (response) => {
          this.projects = response
        },
        (error) => {
          this.$message.error({
            message: formatErrorMessage(error),
          })
        }
      )
    },
    fetchUsers() {
      usersApi.index(
        (users) => {
          this.users = users
        },
        (error) => {
          logErrorMessage(error)
          this.$message.error({
            message: formatErrorMessage(error),
          })
        }
      )
    },

    fetchCategories(projectId) {
      this.selectedCategoryId = null
      this.selectedSubcategoryName = null

      if (!projectId) {
        return
      }

      projectPricingCategoriesApi.index(
        (response) => {
          this.categories = response
        },
        (error) => {
          logErrorMessage(error)
        },
        projectId
      )
    },

    fetchSubcategories() {
      pricingSubcategoriesApi.index(
        (response) => {
          this.subcategories = response
        },
        (error) => {
          logErrorMessage(error)
        }
      )
    },

    fetchAreas(projectId) {
      if (!projectId) {
        return
      }
      this.selectedArea = ''

      projectAreasApi.index(
        (response) => {
          this.areas = response
        },
        (error) => {
          logErrorMessage(error)
        },
        projectId,
        {include: ['muffen', 'streets', 'mfgs']}
      )
    },

    getDatesReport() {
      this.loading = true
      this.currentPage = 1

      this.updateData()
    },
    loadPage() {
      this.loading = true
      this.data = []
      this.updateData()
    },

    generateExcelFile(extension) {
      this.generatingExcelFile = true

      const startDate = formatDateForRequest(this.startAndEndDate[0])
      const endDate = formatDateForRequest(this.startAndEndDate[1])

      const params = {}
      params['startDate'] = startDate
      params['endDate'] = endDate
      params['showItemsWithoutDate'] = this.showItemsWithoutDate ? 1 : 0
      params['extension'] = extension

      params.projectId = this.selectedProjectId

      if (this.startAndEndDate) {
        switch (this.selectedDateType) {
        case DATE_CREATED:
          params['dateFilter'] = 'created_at'
          break
        case DATE_UPDATED:
          params['dateFilter'] = 'updated_at'
          break
        }
      }

      if (this.selectedUserId) {
        params.user_id = this.selectedUserId
      }

      if (this.selectedCategoryId) {
        params.category_id = this.selectedCategoryId
      }

      if (this.selectedSubcategoryName) {
        params.subcategory_name = this.selectedSubcategoryName
      }

      if (this.selectedAreaId) {
        params['area_id'] = this.selectedAreaId
      }

      if (this.selectedLocationTypeAndId) {
        const [selectedLocationType, selectedLocationId] =
          this.selectedLocationTypeAndId.split('-')
        params.location_type = selectedLocationType
        params.location_id = selectedLocationId
      }

      lerfReportApi.export(
        (response) => {
          this.generatingExcelFile = false
          window.location = response
        },
        (error) => {
          this.generatingExcelFile = false
          if (error) {
            this.$message.error({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 0,
            })
          }
        },
        {
          ...params,
          extension,
        }
      )
    },

    pageChange(val, sortDirection, prop) {
      this.loading = true
      const params = {}
      if (prop === 'startDate') {
        prop = 'start_date'
      }

      if (prop === 'endDate') {
        prop = 'end_date'
      }

      if (prop === 'costCenter') {
        prop = 'cost_center'
      }

      params[sortDirection] = prop

      this.updateData(params)
    },

    updateData(params = {}) {
      params.page = this.currentPage

      if (this.selectedProjectId) {
        params.projectId = this.selectedProjectId
      }
      params['showItemsWithoutDate'] = this.showItemsWithoutDate ? 1 : 0

      const startDate = formatDateForRequest(this.startAndEndDate[0])
      const endDate = formatDateForRequest(this.startAndEndDate[1])

      params.perPage = this.perPage
      params.startDate = startDate
      params.endDate = endDate

      if (this.startAndEndDate) {
        switch (this.selectedDateType) {
        case DATE_CREATED:
          params['dateFilter'] = 'created_at'
          break
        case DATE_UPDATED:
          params['dateFilter'] = 'updated_at'
          break
        }
      }

      if (this.selectedUserId) {
        params.user_id = this.selectedUserId
      }

      if (this.selectedCategoryId) {
        params.category_id = this.selectedCategoryId
      }

      if (this.selectedSubcategoryName) {
        params.subcategory_name = this.selectedSubcategoryName
      }

      if (this.selectedAreaId) {
        params['area_id'] = this.selectedAreaId
      }

      if (this.selectedLocationTypeAndId) {
        const [selectedLocationType, selectedLocationId] =
          this.selectedLocationTypeAndId.split('-')
        params.location_type = selectedLocationType
        params.location_id = selectedLocationId
      }

      lerfReportApi.index(
        (response) => {
          this.data = response.data.map((item) => camelCaseKeys(item))
          this.total = response.meta.total
          this.loading = false
          this.spinner = false
        },
        (error) => {
          this.loading = false
          if (error) {
            this.$message.error({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 0,
            })
          }
        },
        params
      )
    },

    perPageChange(val) {
      this.loading = true
      this.currentPage = val
      this.loadPage()
    },

    getOriginLabel(value) {
      if (value) {
        return this.origin[value].label
      }

      return null
    },

    changeSort({prop, order}) {
      let sortDirection
      if (order === 'ascending') {
        sortDirection = 'sortBy'
      } else if (order === 'descending') {
        sortDirection = 'sortByDesc'
      } else {
        sortDirection = null
        return
      }

      if (prop === 'projectName') {
        prop = 'project_name'
      }

      if (prop === 'categoryName') {
        prop = 'category_name'
      }

      if (prop === 'costCenter') {
        prop = 'cost_center'
      }

      if (prop === 'areaCode') {
        prop = 'area_code'
      }

      if (prop === 'areaName') {
        prop = 'area_name'
      }

      this.pageChange(1, sortDirection, prop)
    },

    changeFilter(filters) {
      this.filters = filters

      this.pageChange(1)
    },

    changePageSize(newPageSize) {
      this.perPage = newPageSize

      this.perPageChange(1)
    },

    formatDateFromData(date) {
      return formatDate(date)
    },
  },
}
</script>

<style lang="scss" scoped>
.dates-filter {
  display: flex;
  align-items: center;
}

.date-filter {
  margin-right: 10px;
}

.search-button {
  margin-bottom: 20px;
}

::v-deep .lerfs-reporting-table {
  .bottom-row {
    margin-top: 1em;
  }

  .day-9 {
    background: rgba(#ffdc00, 0.4) !important;
  }

  .day-8 {
    background: rgba(#ff4136, 0.4) !important;
  }

  .day-7 {
    background: rgba(#2ecc40, 0.4) !important;
  }

  .day-6 {
    background: rgba(#b10dc9, 0.4) !important;
  }

  .day-5 {
    background: rgba(#001f3f, 0.4) !important;
    color: #fff;
  }

  .day-4 {
    background: rgba(#ff851b, 0.4) !important;
  }

  .day-3 {
    background: rgba(#0074d9, 0.4) !important;
  }

  .day-2 {
    background: rgba(#7fdbff, 0.4) !important;
  }

  .day-1 {
    background: rgba(#01ff70, 0.4) !important;
  }
}
</style>
