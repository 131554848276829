<template>
  <div class="wrapper items-report-overview">
    <div class="header">
      <div v-if="area" class="area">
        <span>Area: </span>
        {{ area.name }}
      </div>
      <div v-if="entry.street" class="street">
        <span>Street: </span>
        {{ entry.street.name }}
        {{ [entry.streetStart, entry.streetEnd].join(' - ') }}
      </div>
      <div v-if="muffe" class="area">
        <span>Muffe: </span>
        {{ muffe.name }}
      </div>
      <div v-if="mfg" class="area">
        <span>MFG: </span>
        {{ mfg.name }}
      </div>
      <div class="executor">
        <span>Executor: </span>
        <template v-if="entry.executor"
        >{{ entry.executor }}
          <template v-if="entry.executorName"
          >: {{ entry.executorName }}
          </template
          >
        </template>
      </div>
      <div class="comments">
        <span>Comments: </span>
        <template v-if="entry.comments">{{ entry.comments }}</template>
      </div>
    </div>
    <div v-if="reportDetails.showImages" class="imagesWrapper">
      <div style="display: flex; flex-wrap: wrap">
        <template v-if="entry.files && entry.files.images">
          <div v-for="image in entry.files.images" :key="image">
            <img
              :src="image"
              alt=""
              style="width: 200px; heigth: auto; margin: 3px"
              @click="openModal(image)"
            />
          </div>
        </template>
        <template v-if="entry.files && entry.files.files">
          <div v-for="file in entry.files.files" :key="file" class="filebox">
            <a :href="file" target="_blank">
              <i aria-hidden="true" class="fa fa-file"></i>
              <span>{{ filename(file) }}</span>
            </a>
          </div>
        </template>

        <imageModal
          v-if="showModal"
          :picture="imageModalUrl"
          @close="hideModal"
        />
      </div>
    </div>

    <!--
      We use a native table here instead of en element table, because the overhead of that component is so great
      that rendering of a project report takes ages, while loading the data is fast. Using a native table makes it much
      quicker
    -->
    <table v-if="entry.items.length" :class="tableClasses">
      <thead>
      <tr>
        <th v-if="showImportControls" width="10">Import?</th>
        <th v-if="showMarkControls" width="10">Mark?</th>
        <th width="20">Imported</th>
        <th width="90">Date</th>
        <th width="100">Person</th>
        <th v-if="entry.categoryId" width="100">Category</th>
        <th width="200">Project</th>
        <th width="120">Reference</th>
        <th>Description</th>
        <th
          v-if="!reportDetails.hideFactorAndPricing"
          class="text-right"
          width="80"
        >
          Price
        </th>
        <th class="text-right" width="80">Unit</th>

        <th width="120">Subcontractor reference</th>
        <th>Subcontractor description</th>
        <th
          v-if="!reportDetails.hideFactorAndPricing"
          class="text-right"
          width="80"
        >
          Subcontractor price
        </th>
        <th class="text-right" width="80">Subcontractor unit</th>
        <th class="text-right" width="80">Menge /<br/>Abmessungen</th>
        <th
          v-if="!reportDetails.hideFactorAndPricing"
          class="text-right"
          width="80"
        >
          Factor
        </th>
        <th class="text-right" width="80">Subcontractor total</th>
        <th class="text-right" width="80">Total</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="item in entry.items">
        <tr
          :key="item.id"
          :class="tableRowClassName(item)"
          @click="toggleRow(item)"
        >
          <td v-if="showImportControls">
            <el-form>
              <el-form-item>
                <el-checkbox
                  :value="item.selected"
                  @change="selectedCheck(item)"
                />
              </el-form-item>
            </el-form>
          </td>
          <td v-if="showMarkControls">
            <el-form>
              <el-form-item>
                <el-checkbox
                  :value="item.invoice_status"
                  @change="invoicedCheck(item)"
                />
              </el-form-item>
            </el-form>
          </td>
          <td class="imported-cell">
            <template v-if="isPurchaseInvoice" >
              {{ item.imported_purchase }}
            </template>
            <template v-else>
              {{ item.imported }}
            </template>
          </td>
          <td>{{ item.created_at | shortDate }}</td>
          <td>{{ item.person }}</td>
          <td v-if="item.categoryId">{{ item.categoryName }}</td>
          <td>{{ item.project_name }}</td>
          <td>{{ item.reference }}</td>
          <td>{{ item.description }}</td>
          <td v-if="!reportDetails.hideFactorAndPricing" class="text-right">
            {{ item.price | toFixed(2, 2) }}
          </td>
          <td class="text-right">{{ item.unit }}</td>

          <td>{{ item.subcontractor_reference || '...' }}</td>
          <td>{{ item.subcontractor_description || '...' }}</td>
          <td v-if="!reportDetails.hideFactorAndPricing" class="text-right">
            {{ item.subcontractor_price | toFixed(2, 2) }}
          </td>
          <td class="text-right">{{ item.subcontractor_unit || '...' }}</td>
          <td class="text-right">
            {{ getAmountOrDimensions(item) | toFixed }}
          </td>
          <td v-if="!reportDetails.hideFactorAndPricing" class="text-right">
            {{ item.factor }}
          </td>
          <td class="text-right">
            {{ item.subcontractor_total | toFixed(2, 2) }}
          </td>
          <td class="text-right">{{ item.total | toFixed(2, 2) }}</td>
        </tr>
        <template v-if="reportDetails.showComments && item.comments">
          <tr
            :key="`${item.id}-comments`"
            :class="tableRowClassName(item)"
            class="comments"
          >
            <td></td>
            <td colspan="100">
              {{ item.comments }}
            </td>
          </tr>
        </template>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import imageModal from './ImageModal'
import positionItemApi from 'api/v2/positionItems'
import {escape} from 'lodash-es'
import {logErrorMessage} from '../../common/util';

export default {
  name: 'ItemsReportOverview',

  components: {
    imageModal,
  },

  filters: {
    shortDate(value) {
      return value.toString().split(' ')[0]
    },
    toFixed(value, min = 0, max = 2) {
      if (typeof value == 'undefined' || value === null) return '...'
      if (min === max) {
        return parseFloat(value).toFixed(max)
      }
      let decimals
      if (Math.floor(value) === value) {
        decimals = 0
      } else {
        decimals = value.toString().split('.')[1].length || 0
      }
      return parseFloat(value).toFixed(
        Math.max(Math.min(decimals, max), Math.min(min, max))
      )
    },
  },

  props: {
    entry: {
      type: Object,
      required: true,
    },
    workers: {
      type: Array,
      default: () => [],
    },
    areas: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    projects: {
      type: Array,
      default: () => [],
    },
    reportDetails: {
      required: true,
      type: Object,
    },
    showImportControls: {
      type: Boolean,
      default: false,
    },
    showMarkControls: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showModal: false,
      imageModalUrl: '',
      allCategories: [],

      updatingItems: {},
    }
  },

  computed: {
    tableClasses() {
      return {
        'can-toggle': this.showImportControls || this.showMarkControls,
        'allow-marking': this.showMarkControls,
        'allow-importing': this.showImportControls,
      }
    },
    project() {
      return this.projects.find(
        (project) => project.id === this.entry.projectId
      )
    },
    area() {
      if (this.muffe) {
        const muffeAreaId =
          this.muffe.area_id || (this.muffe.pivot && this.muffe.pivot.area_id)
        return this.areas.find((area) => area.id === muffeAreaId)
      }
      if (this.mfg) {
        const mfgAreaId =
          this.mfg.area_id || (this.mfg.pivot && this.mfg.pivot.area_id)
        return this.areas.find((area) => area.id === mfgAreaId)
      }
      if (this.entry.street && this.entry.street.id) {
        return this.areas.find((area) => area.id === this.entry.street.area_id)
      }
      if (this.entry.street && this.entry.street.area) {
        return this.entry.street.area
      }
      return false
    },
    muffe() {
      let muffe
      if (!this.entry.muffeId) {
        return muffe
      }
      this.areas.some((area) => {
        if (!area.muffen) return
        return area.muffen.some((areaMuffe) => {
          if (areaMuffe.id === this.entry.muffeId) {
            muffe = areaMuffe
            return true
          }
        })
      })
      return muffe
    },
    mfg() {
      let mfg
      if (!this.entry.mfgId) {
        return mfg
      }
      this.areas.some((area) => {
        if (!area.mfgs) return
        return area.mfgs.some((areaMfg) => {
          if (areaMfg.id === this.entry.mfgId) {
            mfg = areaMfg
            return true
          }
        })
      })
      return mfg
    },
    isPurchaseInvoice() {
      return this.reportDetails.isPurchaseInvoice
    }
  },

  created() {
    if (this.showImportControls && this.showMarkControls) {
      // eslint-disable-next-line
      console.warn(
        'ItemsReportOverview currently cannot handle two sets of checkboxes without it causing weird issues!'
      )
    }
  },

  methods: {
    openModal(url) {
      this.showModal = true
      this.imageModalUrl = url
    },
    hideModal() {
      this.showModal = false
      this.imageModalUrl = ''
    },

    getAmountOrDimensions(item) {
      switch (item.unit) {
      case 'ST':
      case 'STD':
        return item.amount
      case 'M':
        return item.length
      case 'M2':
        return item.length * item.width
      case 'M3':
        return item.length * item.width * item.height
      }
    },

    tableRowClassName(item) {

      if(this.isPurchaseInvoice) {
        return {
          'imported-row': item.imported_purchase === 'Yes',
          'not-imported-row': item.imported_purchase === 'No',
          'invoice-status-row': item.purchase_invoice_status,
          'not-invoice-status-row': !item.purchase_invoice_status,
        }
      }
      return {
        'imported-row': item.imported === 'Yes',
        'not-imported-row': item.imported === 'No',
        'invoice-status-row': item.invoice_status,
        'not-invoice-status-row': !item.invoice_status,
      }
    },

    getEntryUserName(entry) {
      if (entry.userId) {
        const worker = this.workers.find((worker) => {
          return worker.id === entry.userId
        })
        if (worker) {
          return worker.name
        }
      }

      if (this.user) {
        return this.user.name
      }
    },

    countDecimals(value) {
      if (Math.floor(value) === value) return 0
      return value.toString().split('.')[1].length || 0
    },


    invoicedCheck(item) {
      this.$set(item, 'invoice_status', !item.invoice_status)
      this.updateItem(item)
    },
    selectedCheck(item) {
      this.$set(item, 'selected', !item.selected)
    },
    toggleRow(item) {
      if (this.showImportControls && this.showMarkControls) {
        // HALT AND CATCH FIRE
        // eslint-disable-next-line
        console.warn(
          'ItemsReportOverview currently cannot handle two sets of checkboxes without it causing weird issues!'
        )

      } else if (this.showImportControls) {
        this.$set(item, 'selected', !item.selected)
      } else if (this.showMarkControls) {
        this.$set(item, 'invoice_status', !item.invoice_status)
        this.updateItem(item)
      }
    },

    updateItem(item) {
      // return
      if (this.updatingItems[item.id]) {
        return
      }
      this.updatingItems[item.id] = true

      this.$nextTick(() => {
        positionItemApi.updateInvoiceStatus(
          () => {
            this.updatingItems[item.id] = false
          },
          (error) => {
            logErrorMessage(error)
            this.updatingItems[item.id] = false
          },
          item.position_id,
          item.id,
          item.invoice_status
        )
      })
    },

    filename(file) {
      if (!file) {
        return ''
      }

      const parts = file.split('/')
      const filename = parts[parts.length - 1]

      return escape(filename).replace(/([_-])/g, '$1&shy;')
    },
  },
}
</script>

<style lang="scss">
.items-report-overview {
  font-size: 12px;
  margin-bottom: 30px;

  .text-right {
    text-align: right;
  }

  table td {
    padding: 0;
  }

  .header {
    font-size: 13px;
    margin: 10px 0 5px 0;

    .street,
    .area,
    .executor,
    .comments {
      line-height: 1.5;
      font-weight: bold;

      > span {
        display: inline-block;
        width: 100px;
      }
    }

    .comments {
      font-weight: normal;
    }
  }

  .imported-row {
    // If we can toggle the row on this column, show it for the whole row
    .allow-importing & {
      background-color: #2a8b2d;

      &:hover {
        background-color: lighten(#2a8b2d, 5%);
      }
    }

    // Always show it on the imported row
    .imported-cell {
      background-color: #2a8b2d;
    }

    &:hover {
      .imported-cell {
        background-color: lighten(#2a8b2d, 5%);
      }
    }
  }

  .not-imported-row {
    // If we can toggle the row on this column, show it for the whole row
    .allow-importing & {
      background-color: #e29090;
      border-left: 2px solid #e29090;
      border-right: 2px solid #e29090;

      &:hover {
        background-color: lighten(#e29090, 5%);
      }
    }

    // Always show it on the imported row
    .imported-cell {
      background-color: #e29090;
    }

    &:hover {
      .imported-cell {
        background-color: lighten(#e29090, 5%);
      }
    }
  }

  .allow-marking {
    .invoice-status-row {
      background-color: #2a8b2d;
      border-left: 2px solid #2a8b2d;
      border-right: 2px solid #2a8b2d;

      &:hover {
        background-color: lighten(#2a8b2d, 5%);
      }
    }

    .not-invoice-status-row {
      background-color: #e29090;
      border-left: 2px solid #e29090;
      border-right: 2px solid #e29090;

      &:hover {
        background-color: lighten(#e29090, 5%);
      }
    }
  }

  .items-report-overview {
    padding-bottom: 25px;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    td,
    th {
      padding: 2px 4px;
    }

    th {
      text-align: left;
      border-bottom: 2px solid $black;
    }

    tr.comments {
      border-bottom: 2px solid #777 !important;
      border-right: 2px solid #777 !important;
      border-left: 2px solid #777 !important;
      font-style: italic;
    }
  }

  .can-toggle tbody tr {
    cursor: pointer;
  }

  // Stupid checkboxes double the height of rows
  .el-form-item {
    margin-bottom: 0;
  }

  .el-checkbox,
  ::v-deep .el-form-item__content {
    line-height: 18px;
  }

  .filebox {
    border: 1px solid grey;

    a {
      display: flex;
      width: 200px;
      height: 100%;
      padding: 10px;
      text-align: center;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }

    .fa {
      font-size: 30px;
    }
  }
}
</style>
