<template>
  <div class="position-validate-subcontractor">
    <DataTableAlternate
      ref="positionValidationTable"
      table-name="positionValidationTable"
      :api="api"
      :request-data="{include:['project']}"
      :data-table-options="dataTableOptions"
    >
      <template #actions="{ row }">
        <el-button
          :key="`${row.id}_select`"
          :icon="!row.executor_position_validation  ?  'el-icon el-icon-close' : 'el-icon el-icon-check'"
          :title="!row.executor_position_validation  ? 'Review and validate' : 'Review and Un-validate'"
          :type="!row.executor_position_validation  ?  'danger' :  'success'"
          size="mini"
          @click="loadPosition(row)"
        />
      </template>
    </DataTableAlternate>

    <el-dialog
      top="0"
      :visible.sync="dialogVisible"
      :modal="true"
      class="position-validation-dialog"
      :class="{
        'is-locked' : !selectedPosition.allowUnvalidate
      }"
    >
      <template #title>
        <h3>Review and set validation</h3>
      </template>
      <el-row :gutter="24">
        <el-col :span="6">
          <h4>
            Position
            <span v-if="selectedPosition.executorPositionValidation" class="validated-badge validated">validated</span>
            <span v-if="!selectedPosition.executorPositionValidation" class="validated-badge not-validated">not validated</span>
          </h4>
          <strong>Kst2:</strong><br />{{selectedPosition.costObjectCode}}<br /><br />
          <strong>Project:</strong><br />{{selectedPosition.project}}<br /><br />
          <strong>Area:</strong><br />{{selectedPosition.polygon}}<br /><br />
          <strong>Executor:</strong><br />{{selectedPosition.executorName}}<br /><br />
          <strong>Start Date:</strong><br />{{ formatDate(selectedPosition.startDate)}}<br /><br />
          <strong>End Date:</strong><br />{{formatDate(selectedPosition.endDate) }}<br /><br />
          <strong>Created:</strong><br />{{ formatDateTime(selectedPosition.createdAt) }}<br /><br />
          <strong>Created By:</strong><br />{{selectedPosition.createdBy}}<br /><br />
          <strong>Comments:</strong><br />{{selectedPosition.comments}}<br />

          <h5>Validation</h5>
          <template v-if="selectedPosition.executorPositionValidation">
            <strong>Validated At:</strong><br />{{formatDateTime(selectedPosition.executorPositionValidatedAt)}}<br /><br />
            <strong>Validated By:</strong><br />{{selectedPosition.executorPositionValidatedBy}}<br /><br />
          </template>
          <template v-else-if="selectedPosition.executorPositionUnvalidatedAt">
            <strong>Un-Validated At:</strong><br />{{formatDateTime(selectedPosition.executorPositionUnvalidatedAt)}}<br /><br />
            <strong>Un-Validated By:</strong><br />{{selectedPosition.executorPositionUnvalidatedBy}}<br /><br />
          </template>
          <template v-else>
            Not Validated
          </template>
        </el-col>
        <el-col :span="18">
          <h4>Items</h4>
          <el-table
            :data="invoiceAbleItems"
            style="width: 100%"
            :highlight-current-row="true"
            :default-sort = "{prop: 'date', order: 'descending'}"
            size="mini"
            :height="tableHeight"
            stripe
          >
            <el-table-column v-if="!isSubcontractor"  width="80" prop="id" label="ID" sortable />
            <el-table-column prop="description" label="Description" sortable />
            <el-table-column width="100" prop="amount" label="Amount" sortable />
            <el-table-column width="100" prop="length" label="Length" sortable />
            <el-table-column width="100" prop="width" label="Width" sortable />
            <el-table-column width="100" prop="height" label="Height" sortable />
            <el-table-column width="100" prop="price" label="Price" sortable />
            <el-table-column v-if="!isSubcontractor" width="180" prop="missing_subcontractor_pricing_item" label="Missing Sub. Pricing" sortable>
              <template slot-scope="scope">{{scope.row.missing_subcontractor_pricing_item ? 'Yes' : ''}}</template>
            </el-table-column>
            <el-table-column v-if="!isSubcontractor" width="140" prop="not_purchase_invoicable" label="Not P. Invoicable" sortable>
              <template slot-scope="scope">{{scope.row.not_purchase_invoicable ? 'Yes' : ''}}</template>
            </el-table-column>
            <el-table-column prop="comments" label="Comments" sortable />
          </el-table>


          <PositionImages
              v-if="dialogVisible"
              ref="images"
              :position="selectedPosition"
              :allow-edit="false"
          />

          <el-row :gutter="24" class="actions-row">
            <el-col :span="24">
              <template v-if="canChangeState">
                <p>
                  <el-alert v-if="selectedPosition.invoiced" title="Some items are already invoiced" type="error" :closable="false" show-icon></el-alert>
                </p>
                <el-checkbox v-model="doValidationStatusChange">
                  Set validation:
                </el-checkbox>
                <el-select
                  v-model="validationStatus"
                  :disabled="!doValidationStatusChange"
                  placeholder=""
                  size="small"
                >
                  <el-option
                    v-for="(item, key) of validationOptions"
                    :key="key"
                    :label="item"
                    :value="key"
                  />
                </el-select>
                <el-button :disabled="!doValidationStatusChange" type="success" @click="setApprovalStatus">Update</el-button>
                <el-button type="danger" @click="dialogVisible = false; selectedPosition={};doValidationStatusChange=false">Back</el-button>
              </template>
              <template v-else>
                <el-alert title="This validation status of this position is locked" type="warning" :closable="false">
                  <el-button
                    v-if="!sendNotificationResponse"
                    plain
                    size="small"
                    @click="notifyModal"
                  >
                    Request a change
                  </el-button>
                  <p v-else>
                    {{sendNotificationResponse}}
                  </p>
                </el-alert>
                <p>
                  <el-button type="danger" @click="dialogVisible = false; selectedPosition={};doValidationStatusChange=false">Back</el-button>
                </p>
              </template>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-dialog>

    <SendDialog
      v-if="showSendNotification"
      ref="createNotificationRequest"
      subject=""
      message=""
      @close="onNotificationDialogClose"
      @sent="onNotificationDialogSent"
    />
  </div>
</template>

<script>

import DataTableAlternate from '@/js/components/Common/DataTableAlternate.vue';
import positionValidationApi from '@/js/api/v2/positionValidation';
import {formatDate, formatDateTime} from '@/js/common/util';
import camelcaseKeys from 'camelcase-keys';
import SendDialog from '@/js/components/Notifications/SendDialog.vue';
import PositionImages from '@/js/components/Views/Positions/Single/Images.vue';

export default {
  name: 'PositionValidationSubcontractorView',
  components: {
    PositionImages,
    DataTableAlternate,
    SendDialog
  },
  data() {
    return {
      windowHeight: window.innerHeight,
      dataTableOptions: {
        showRefresh: true,
        tableHeightOffset: 160,
        columnWidth: {
          _rowActions: 60,
          id: 120,
          project: 200,
          area: 200,
          executor: 120,
          comments: 250,
        }
      },
      dialogVisible: false,
      selectedPosition: {},
      doValidationStatusChange: false,
      validationStatus: null,
      validationOptions: {
        validated: 'Validated',
        unvalidated: 'Un-Validated',
      },
      sendNotificationMessage: '',
      sendNotificationResponse: null,
      invoiceAbleItems: [],
    }
  },
  computed: {
    api() {
      return positionValidationApi
    },
    tableHeight() {
      let height = this.windowHeight
      height -= 620
      if(!this.selectedPosition.allowUnvalidate) {
        height -= 150
      } else if(this.selectedPosition.invoiced) {
        height -= 150
      }
      return `${height}`
    },
    showSendNotification() {
      return !this.canChangeState
    },
    sendNotificationSubject() {
      let parts = [];
      parts.push('Request Validation change on locked position')
      if(this.selectedPosition) {
        parts.push(`Position: # ${this.selectedPosition.id}`)
        parts.push(`Project: ${this.selectedPosition.project}`)
      }
      return parts.join(', ')
    },
    isSuperAdmin() {
      return this.$store.getters['global/user']['role'] === 'super_admin'
    },
    isSubcontractor() {
      return this.$store.getters['global/user']['role'] === 'subcontractor_supervisor'
    },
    canChangeState() {
      if(this.isSuperAdmin) {
        return true
      }
      if(this.selectedPosition?.invoiced) {
        return false
      }

      if((this.selectedPosition?.executorPositionValidation !== 'Validated') || this.selectedPosition?.allowUnvalidate) {
        return true
      }
      return false
    }
  },
  methods: {
    formatDate,
    formatDateTime,
    setApprovalStatus() {
      if(!this.validationStatus) {
        alert('You must choose a status.');
        return
      }
      positionValidationApi.update(
        () => {
          this.selectedPosition = {}
          this.dialogVisible = false
          this.doValidationStatusChange = false
          this.validationStatus = null
          this.$refs.positionValidationTable.refresh()

          this.$message({
            type: 'success',
            message: 'Validation state updated',
            duration: 4000,
          })
        },
        (error) => {
          this.$message.error({
            showClose: true,
            message: error.message,
            duration: 10000,
          })
        },
        this.selectedPosition.id,
        {
          executor_position_validation: this.validationStatus
        },
      );
    },
    loadPosition(position) {
      this.selectedPosition = camelcaseKeys(position)
      positionValidationApi.show(
        (position) => {
          this.selectedPosition = position
          this.dialogVisible = true
          this.validationStatus = null
          this.invoiceAbleItems = this.selectedPosition.items.filter((item) => {
            if(!this.isSubcontractor) {
              return true
            }
            if(item.not_purchase_invoicable === true) {
              return false
            }
            return true
          })
        },
        (error) => {
          this.$message.error({
            showClose: true,
            message: error.message,
            duration: 10000,
          })
          this.selectedPosition = {}
          this.dialogVisible = false
          this.validationStatus = null
        },
        this.selectedPosition.id,
      );
    },
    notifyModal() {
      this.$refs.createNotificationRequest.openNotificationDialog(
        this.sendNotificationSubject,
        this.sendNotificationMessage,
        {
          message: 'Reason for the change?'
        }
      )
    },
    onNotificationDialogClose() {
      this.sendNotificationResponse = null
    },
    onNotificationDialogSent() {
      this.sendNotificationResponse = 'Notification Created! You will receive an email once the request has been actioned.'
    },
    showDialog() {
      return !!(this.dialogVisible && this.invoiceAbleItems);
    },
  },
}
</script>
<style lang="scss">
.position-validate-subcontractor {
  .validated-badge {
    font-size: 0.8em;
    padding:0.1em 1em;
    color: #FFF;
    background-color: #F56C6C;

    &.validated {
      background-color: #b3e19d;
      border-color: #b3e19d;
    }
  }

  .position-validation-dialog {
    .el-dialog {
      top: 30px;
      bottom: 30px;
      left: 30px;
      right: 30px;
      width: auto;
      overflow: auto;
      position: fixed;
      margin: 0;

      &.is-locked {

      }
      .bottom-row {
        text-align: right;
        padding-top: 60px;
      }

      .actions-row {
        padding-top: 20px;
      }

    }
    .el-dialog__body h4 {
      margin-top:0;
    }
    .el-dialog__footer .el-button {
      padding:8px 20px;
    }
    .el-dialog__header {
      h3 {
        margin: 0;
      }
    }
  }
  .position-images {
    margin-top: 15px;
    height:285px;
    overflow: auto;

    h2 {
      display: none;
    }
  }
}
</style>
