import ApiResource from '../ApiResource'

class ProjectSubcontractorResource extends ApiResource {
  constructor() {
    super({
      name: 'projects.subcontractors',
    })

    this.getSubcontractors = this.index
  }

  linkProject(resolve, reject, projectId, subcontractorId) {
    this.ids = [projectId, subcontractorId]
    this.post(`${this.getUrl()}/attach`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }

  unlinkProject(resolve, reject, projectId, subcontractorId) {
    this.ids = [projectId, subcontractorId]
    this.post(`${this.getUrl()}/detach`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const projectSubcontractorsApi = new ProjectSubcontractorResource()

export default projectSubcontractorsApi
