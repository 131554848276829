import ApiResource from '../ApiResource'

class CacheControlResource extends ApiResource {
  constructor() {
    super({
      name: 'cache-control',
    })
  }
}

const cacheControlApi = new CacheControlResource()

export default cacheControlApi
