import moment from 'moment'
import store from '../store'
import * as Sentry from '@sentry/vue'

export const generateToken = function () {
  let token = ''
  let possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (var i = 0; i < 32; i++) {
    token += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return token
}

export const formatDateForRequest = function (value) {
  if (value) {
    return moment(value).format('YYYY-MM-DD')
  }

  return null
}

export const formatDateForRequestDateTime = function (value) {
  if (value) {
    return moment(value).format('YYYY-MM-DD 00:00:00')
  }

  return null
}

export const getDateFormat = function (type) {
  const config = store.getters['configuration/data']

  switch (type) {
  case 'element':
    return config.date_format_element
  case 'moment':
    return config.date_format_moment
  default:
    throw new Error(`Invalid type: ${type}`)
  }
}

export const getWeekFormat = function (type) {
  const config = store.getters['configuration/data']

  switch (type) {
  case 'element':
    return config.week_format_element
  case 'moment':
    return config.week_format_moment
  default:
    throw new Error(`Invalid type: ${type}`)
  }
}

export const formatDate = function (value) {
  if (!value) {
    return value
  }

  const format = getDateFormat('moment')

  return moment(value).format(format)
}

export const formatDateTime = function (value) {
  if (!value) {
    return value
  }

  let config = store.getters['configuration/data']
  let format

  if (config && config.datetime_format) {
    format = config.datetime_format
  } else {
    format = 'DD.MM.YYYY HH:mm:ss'
  }

  return moment(value).format(format)
}

export const generateHash = function () {
  let token = ''
  let possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (var i = 0; i < 10; i++) {
    token += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return token
}

export const getAppId = function () {
  if (window.localStorage.getItem('appId') === null) {
    window.localStorage.setItem('appId', generateToken())
  }

  return window.localStorage.getItem('appId')
}

export const assert = function (assertion, message, extra) {
  if (assertion) {
    return
  }

  Sentry.withScope((scope) => {
    scope.setContext('extra', extra)

    Sentry.captureException(new Error(message))
  })
}

export const selectAll = function () {
  return Promise.reject(
    new Error(
      'Function uses an aggregation function that gets all information by crawling pages, while all data can (and should) be retrieved at once'
    )
  )
}

export const processFilters = function (params, filters) {
  if (filters) {
    for (let prop of Object.keys(filters)) {
      const key = `f[${prop}]`

      if (filters[prop].length) {
        params[key] = filters[prop]
      }
    }
  }

  return params
}

export const processOrder = function (params, prop, direction) {
  if (prop) {
    const order = direction || 'asc'
    const key = `o[${prop}]`

    params[key] = order
  }

  return params
}

export const can = function (action, category, subcategory) {
  const user = store.getters['global/user']

  // If there is no user loaded then sirely nothing can be allowed
  if(!user) {
    // TODO, how do we handle this
    // The system do not wait for the user Object on first login and there could be a call to can()
    // with no possibility of being checked
    // a false return is likely the safest
    // Maybe change how/when global/user is initially populated
    return false
  }

  if (!user.capabilities || !user.capabilities[category]) {
    // eslint-disable-next-line
    console.warn(`"${category}" is not defined as a capability category`)
    return true
  }

  // subcategories are used for the nested permissions on area meta
  if (subcategory) {
    return user.capabilities &&
      user.capabilities[category] &&
      user.capabilities[category][subcategory] &&
      user.capabilities[category][subcategory].indexOf(action) !== -1
  }

  return (
    user.capabilities &&
    user.capabilities[category] &&
    user.capabilities[category].indexOf(action) !== -1
  )
}

export const numberWithMaxDigits = function (num, digits) {
  if (digits < 0) {
    return num
  }

  return Math.round(num * Math.pow(10, digits)) / Math.pow(10, digits)
}

export const formatErrorMessage = function (error) {
  if (error.errors) {
    return formatValidationErrorMessage(error)
  }
  let message
  if (error && error.message) {
    message = error.message
  } else {
    message = 'An error occured'
  }
  return message
}
export const logErrorMessage = function (error) {
  // eslint-disable-next-line
  console.error(error)
}
const formatValidationErrorMessage = (error) => {
  if (!error.errors) return error
  let message = ''
  for (let field in error.errors) {
    message += error.errors[field].join(' | ') + ' | '
  }
  message = message.slice(0, -3)
  return message
}
