import initialDataApi from 'api/v2/initialData'

import { generateSetters, generateGetters } from '../../helpers.js'

const state = {
  enums: {},
  user: null,
  unsavedChanges: false,
  lerfUpdateRules: null,
  eventStream: null,
  purchaseInvoiceUpdateRules: null,
  claimUpdateRules: null,

  titleTag: null,
  titleColor: null,
  customTitle: null,
}

const getters = {
  ...generateGetters([
    'enums',
    'user',
    'unsavedChanges',
    'lerfUpdateRules',
    'purchaseInvoiceUpdateRules',
    'claimUpdateRules',
    'titleTag',
    'titleColor',
    'customTitle',
    'notifications',
    'eventStream',
  ]),

  roles(state) {
    return state.enums.user_role
  },

  projectStatuses(state) {
    return state.enums.project_status
  },

  getEnumById: (state) => (id, canSelectFilter) => {
    if (!state.enums[id]) {
      return {}
    }

    let items = []

    if (canSelectFilter) {
      items = state.enums[id].filter((item) => {
        return item.meta.can_select
      })
    } else {
      items = state.enums[id]
    }

    return items.reduce((acc, status) => {
      acc[status.value] = status
      return acc
    }, {})
  },
}

const mutations = {
  ...generateSetters([
    'user',
    'titleTag',
    'titleColor',
    'lerfUpdateRules',
    'purchaseInvoiceUpdateRules',
    'claimUpdateRules',
    'customTitle',
    'notifications',
    'eventStream',
  ]),

  enums(state, enums) {
    enums.forEach((enumObj) => {
      state.enums[enumObj.name] = enumObj.choices
    })
  },

  reset(state) {
    state.enums = {}
    state.user = null
    state.unsavedChanges = false
    state.titleTag = null
    state.titleColor = null
    state.notifications = null
    state.eventStream = null
  },

  unsavedChanges(state, unsavedChanges) {
    state.unsavedChanges = unsavedChanges

    if (unsavedChanges) {
      window.onbeforereload = function () {
        return 'Are you sure'
      }
    }
  },
}

const actions = {
  fetchInitialData({ commit }) {

    // Reset columns for Projects
    // TODO: There should be a better place to put this.
    delete localStorage.currentProjectsTable_visibleColumns

    return new Promise((resolve) => {
      initialDataApi.index(
        (response) => {
          if (!response.enums || !response.user || !response.configuration) {
            throw new Error(
              'Api version mismatch. The frontend expects data that the backend does not send.'
            )
          }
          commit('notifications', response.notifications)
          commit('enums', response.enums)
          commit('user', response.user)
          commit('lerfUpdateRules', response.lerfStatusRules)
          commit('purchaseInvoiceUpdateRules', response.purchaseInvoiceStatusRules)
          commit('claimUpdateRules', response.claimStatusRules)
          commit('configuration/data', response.configuration, {
            root: true,
          })
          resolve()
        },
        (error) => {
          throw error
        }
      )
    })
  },

  resetCustomTitle({ commit }) {
    commit('customTitle', null)
  },

  reset({ commit }) {
    commit('reset')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
