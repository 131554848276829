<template>
  <div>
    <div v-if="projectFields !== null">
      <el-button type="primary" @click="addField()"
      >Add invoice field
      </el-button
      >
      <div
        v-for="(projectField, index) in projectFields"
        :key="index"
        class="project_field"
      >
        <div class="options_row">
          <div class="project_field_input small">
            <label>order</label>
            <el-input v-model="projectField.displayOrder" type="number"/>
          </div>
          <div class="project_field_input">
            <label>name</label>
            <el-input v-model="projectField.name"/>
          </div>
          <div class="project_field_input">
            <label>type<br/></label>
            <el-select v-model="projectField.type">
              <el-option v-for="type in types" :key="type" :value="type">{{
                  type
                }}
              </el-option>
            </el-select>
          </div>
          <div class="project_field_input">
            <label>category<br/></label>
            <el-select v-model="projectField.category">
              <el-option
                v-for="category in categories"
                :key="category"
                :label="startCase(category)"
                :value="category"
              />
            </el-select>
          </div>
          <div class="project_field_input big">
            <label>description</label>
            <el-input
              v-model="projectField.description"
              :autosize="{ minRows: 2 }"
              type="textarea"
            />
          </div>
          <div
            v-if="projectField.type === 'dropdown'"
            class="project_field_input"
          >
            <label>{{ getNumberOfOptions(projectField) }} option(s)<br/></label>
            <el-button size="small" @click.prevent="showOptions(projectField)">
              Manage options
            </el-button>
          </div>

          <div style="float: right">
            <el-button
              icon="el-icon el-icon-delete"
              size="small"
              style="float: right"
              type="danger"
              @click.prevent="removeField(projectField)"
            />
          </div>
        </div>
        <div class="options_row">
          <div class="project_field_input">
            <el-checkbox v-model="projectField.updateable" class="isinline" /> <label>Can be updated<br/></label>
          </div>

          <div class="project_field_input">
            <el-checkbox v-model="projectField.showOnSalesInvoices" class="isinline" /> <label>On Sales Invoices<br/></label>
          </div>
          <div class="project_field_input">
            <el-checkbox v-model="projectField.showOnPurchaseInvoices" class="isinline" /> <label>On Purchase Invoices<br/></label>
          </div>

          <div
            v-if="projectField.category === 'bid_management'"
            class="project_field_input"
          >
            <el-checkbox v-model="projectField.requiredForBids" class="isinline" /> <label>Required<br/></label>
          </div>

          <div class="project_field_input">
            <el-checkbox v-model="projectField.group" style="display:inline"  class="isinline" /> <label>Group field</label>
            <el-input
              v-if="projectField.group"
              v-model="projectField.groupName" type="text"
            />
          </div>
        </div>
      </div>
      <el-row>
        <el-col :span="12">
          <el-button type="primary" @click="addField()"
          >Add invoice field
          </el-button
          >
        </el-col>
        <el-col :span="12" class="align-right">
          <el-button
            v-loading="isLoading"
            :disabled="isLoading"
            type="primary"
            @click="onSubmit"
          >
            Save
          </el-button>
        </el-col>
      </el-row>
    </div>

    <EditProjectFieldOptionsDialog
      v-if="editingField && editingField.type === 'dropdown'"
      :options="editingField.options ? editingField.options : []"
      @cancel="editingField = null"
      @save="saveOptions"
    >
      <template slot="top">
        <h6 style="margin: 0">Field</h6>
        <p>{{ editingField.name }}</p>
      </template>
    </EditProjectFieldOptionsDialog>

    <div
      v-if="isLoading"
      v-loading="isLoading && projectFields === null"
      style="padding: 50px 20px"
    />
  </div>
</template>

<script>
import projectInvoiceFieldsApi from 'api/v2/projectInvoiceFields'
import {AuthorizationError} from 'api/errors'
import {formatErrorMessage} from 'common/util'
import {cloneDeep, startCase} from 'lodash-es'
import snakeCaseKeys from 'snakecase-keys'
import EditProjectFieldOptionsDialog from 'components/ProjectField/EditOptionsDialog'

export default {
  name: 'ManageInvoiceFields',

  components: {EditProjectFieldOptionsDialog},

  data() {
    return {
      editingField: null,
      isLoading: false,
      types: [
        'string',
        'text',
        'number',
        'decimal',
        'date',
        'datetime',
        'dropdown',
      ],
      categories: [
        'general',
        'customer',
        'bid_management',
        'controlling',
        'bauleiters',
        'engineering',
        'invoicing',
        'finance',
        'closing',
        'other',
      ],
      projectFields: null,
    }
  },

  mounted() {
    this.getCustomFields()
  },

  methods: {
    startCase,
    getCustomFields() {
      this.isLoading = true
      projectInvoiceFieldsApi.index(
        (response) => {
          this.$set(this, 'projectFields', response)
          this.isLoading = false
        },
        (error) => {
          this.isLoading = false

          if (error instanceof AuthorizationError) {
            this.$router.push('/')
          }

          this.$message.error({
            message: formatErrorMessage(error),
          })
        }
      )
    },
    addField() {
      this.projectFields.push({
        name: '',
        description: null,
        type: 'string',
        updateable: 0,
        requiredForBids: 0,
        showOnSalesInvoices: true,
        showOnPurchaseInvoices: true,
        group: false,
        group_name: '',
        displayOrder: this.projectFields.length + 1,
        options: this.projectFields.type === 'dropdown' ? [] : null,
      })
    },
    removeField(projectField) {
      this.projectFields = this.projectFields.filter(
        (pf) => pf !== projectField
      )
    },

    getNumberOfOptions(projectField) {
      return projectField.options ? projectField.options.length : 0
    },
    showOptions(projectField) {
      this.editingField = projectField
    },

    saveOptions(options) {
      this.editingField.options = options
      this.editingField = null
    },
    onSubmit() {
      const fields = this.projectFields.map((field) => {
        return snakeCaseKeys(cloneDeep(field))
      })
      this.isLoading = true

      projectInvoiceFieldsApi.store(
        (response) => {
          this.$set(this, 'projectFields', response)
          this.isLoading = false
          this.$message({
            type: 'success',
            message: 'Saved',
            duration: 4000,
          })
        },
        (error) => {
          this.isLoading = false

          if (error instanceof AuthorizationError) {
            this.$router.push('/')
          }

          this.$message.error({
            message: formatErrorMessage(error),
          })
        },
        {project_fields: fields}
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.project_field {
  display: flow-root;
  /*
  margin: 10px 0;

  background: #fff;
  padding: 15px 10px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;

   */

  .options_row {
    display: flow-root;
  }

  .isinline {
    display: inline-block;
    margin-right: 0px;
  }
  .isinline + label {
    display: inline-block;
    margin-right: 15px;
  }


  label {
    font-size: 14px;
    font-weight: bold;
    color: #666;
    padding-bottom: 3px;
    display: block;
  }
}

.project_field_input {
  float: left;
  /* width: 33%; */
  margin: 0 5px 10px 5px;

  &.small {
    width: 50px;
  }

  &.full {
    clear: both;
    width: 100%;
  }

  &.big {
    width: 400px;
  }
}
</style>
