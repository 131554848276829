import ApiResource from '../ApiResource'

class ProjectPurchaseInvoiceItemResource extends ApiResource {
  constructor() {
    super({
      name: 'projects.purchase-invoices.items',
    })

    this.getSingleItem = this.show
    this.deleteItem = this.delete
  }
}

const projectPurchaseInvoiceItemsApi = new ProjectPurchaseInvoiceItemResource()

export default projectPurchaseInvoiceItemsApi
