<template>
  <div>
    <TableWithFilters />
  </div>
</template>

<script>
import TableWithFilters from '../../PurchaseInvoices/TableWithFilters.vue'

export default {
  name: 'PurchaseInvoicesView',
  components: {
    TableWithFilters,
  },
}
</script>
