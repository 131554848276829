<template>
  <subcontractors-report-table />
</template>

<script>
import SubcontractorsReportTable from './SubcontractorsReportTable'

export default {
  name: 'SubcontractorsReport',
  components: {
    SubcontractorsReportTable,
  },
}
</script>
