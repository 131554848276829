import ApiResource from '../ApiResource'

class ConfigurationResource extends ApiResource {
  constructor() {
    super({
      name: 'configuration',
    })
  }
}

const configurationsApi = new ConfigurationResource()

export default configurationsApi
