<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
        <span>
          {{ formItem.id }} | {{ formItem.description || formItem.section }}
        </span>
    </div>
    <div class="text item">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-row :gutter="20">
            <PricingItemPartial
              v-if="formItem.pricing_item"
              :pricing-item="formItem.pricing_item"
            />
            <span v-else>No Pricing Item</span>
          </el-row>
          <el-row :gutter="20">
            <PositionItemPartial
              v-if="formItem.position_item"
              :position-item="formItem.position_item"
            />
            <span v-else>No Position Item</span>
          </el-row>
        </el-col>
        <el-col :span="20">
          <el-form
            ref="project-general-form"
            :model="formItem"
            :rules="formRules"
            label-position="top"
          >
            <el-row :gutter="5">
              <el-col
                v-for="(fieldOptions, fieldKey) in editFields"
                :key="`field${fieldKey}`"
                :span="6"
              >
                <el-form-item
                  :ref="fieldKey"
                  :label="fieldOptions.label || fieldKey"
                  :prop="fieldKey"
                  size="small"
                >
                  <el-col :span="18">
                    <el-input
                      v-model="formItem[fieldKey]"
                      :disabled="isInputDisabled(fieldKey)"
                      :type="fieldOptions.type || 'text'"
                      size="small"
                    />
                  </el-col>
                  <el-col :span="4">
                    <el-button
                      v-if="
                          formItem[fieldKey] !==
                          originalFormItem[fieldKey]
                        "
                      size="small"
                      type="primary"
                      @click="
                          formItem[fieldKey] =
                            originalFormItem[fieldKey]
                        "
                    >reset
                    </el-button>
                  </el-col>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12" class="align-left">
                <el-button
                  size="small"
                  type="danger"
                  @click="rowAction({ action: 'cancel', data: {} })"
                >Cancel
                </el-button>
              </el-col>
              <el-col :span="12" class="align-right">
                <el-checkbox
                  v-if="false"
                  v-model="showSelf"
                >Show this item in preview
                </el-checkbox>
                <el-button
                  v-if="loadPreview"
                  size="small"
                  type="info"
                  @click="preview"
                >
                  Refresh Preview
                </el-button>
                <el-button
                  v-if="Object.keys(changedColumns).length"
                  size="small"
                  type="success"
                  @click="updateAndSync"
                >
                  Update and Sync
                </el-button>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import PricingItemPartial from './Partials/PricingItem.vue'
import PositionItemPartial from './Partials/PositionItem.vue'

export default {
  name: 'InvoiceForm',
  components: {
    PricingItemPartial,
    PositionItemPartial
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    loadPreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formItem: {},
      formRules: {
        description: [
          {
            required: true,
            message: 'Please enter a description',
            trigger: 'blur',
          },
        ],
      },
      editFields: {
        reference: {},
        description: {},
        unit: {},
        price: {type: 'number'},
        plan_index: {},
        bonus_index: {},
        prognose_mtb_art_index: {},
      },
      showSelf: true,
      matchingOnly: true,
    }
  },
  computed: {
    originalFormItem() {
      return this.item
    },
    changedColumns() {
      const changedColumns = {}
      for (const key in this.formItem) {
        if (this.formItem[key] !== this.originalFormItem[key]) {
          changedColumns[key] = {
            original: this.originalFormItem[key],
            current: this.formItem[key],
          }
        }
      }
      return changedColumns
    },
  },
  watch: {
    formItem() {
      this.$emit('change', this.formItem);
    },
  },
  created() {},
  mounted() {
    this.formItem = JSON.parse(JSON.stringify(this.item))
  },
  methods: {
    isInputDisabled(field) {
      if (field === 'internal_pricing_item_reference') {
        if (!this.formItem.pricing_template) {
          return true
        }
        if (!this.formItem.pricing_template.is_subcontractor_template) {
          return true
        }
        if (this.formItem.pricing_template.is_project_template) {
          return true
        }
        return false
      }
      return false
    },
    updateAndSync() {
      this.$emit('update-and-sync');
    },
    rowAction({action, data}) {
      this.$emit('row-action', {action, data});
    },
    preview() {
      this.$emit('preview');
    }
  },
}
</script>

<style scoped>

</style>
