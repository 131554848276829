<template>
  <div class="project-single-overview">
    <el-row :gutter="20">
      <el-col :span="8">
        <overview-general :project="{}" :on-cancel="onCancel" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import OverviewGeneral from './Single/Overview/General.vue'

export default {
  name: 'ProjectsCreate',

  components: {
    OverviewGeneral,
  },

  data() {
    return {}
  },

  mounted() {
    // first clear the current project
    this.$store.commit('projects/currentProject', {})
  },

  methods: {
    onCancel() {
      this.$router.push('/projects')
    },
  },
}
</script>

<style lang="scss">
.project-single-overview {
  .cell {
    width: inherit !important;
  }

  .el-tag {
    float: right;
  }

  .lerfs {
    margin-top: 1rem;
  }

  .project-details {
    .key {
      text-align: right;
      font-weight: bold;
    }

    .el-row {
      + .el-row {
        margin-top: 0.5rem;
      }
    }
  }

  .el-card {
    + .el-card {
      margin-top: 1.5rem;
    }
  }
  .planned-vs-realized {
    td,
    th {
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        text-align: right;
      }
    }
  }
}
</style>
