import { post } from './'

import config from 'config/app'

const ROUTE_LOGIN = 'oauth/token'
// const ROUTE_REFRESH = 'oauth/refresh'

export default {
  refreshAccessToken(cb, error) {
    error({ error: 'Not implemented' })
  },

  login(cb, error, loginData) {
    const clientId = config.api.clientId
    const clientSecret = config.api.clientSecret

    const data = {
      username: loginData.username,
      password: loginData.password,
      client_id: clientId,
      client_secret: clientSecret,
      grant_type: 'password',
      scope: '*',
    }

    post(ROUTE_LOGIN, data)
      .then((response) => {
        cb(response.data)
      })
      .catch((response) => {
        error(response.data)
      })
  },
}
