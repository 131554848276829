<template>
  <div>
    <el-form :model="claim" label-width="120px" label-position="top">
      <el-form-item label="Date from">
        <el-date-picker
          v-model="claimForm.startAt"
          :picker-options="pickerOptions"
          :format="dateFormat"
          type="date"
          placeholder="Pick a date"
          style="width: 100%"
          @change="fromChanged"
        />
      </el-form-item>

      <el-form-item label="Date to">
        <el-date-picker
          v-model="claimForm.endAt"
          :picker-options="pickerOptions"
          :format="dateFormat"
          type="date"
          placeholder="Pick a date"
          style="width: 100%"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit"> Update </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import * as util from 'common/util'
import moment from 'moment'
import claimsApi from 'api/v2/claims'

export default {
  name: 'ClaimFormSchedule',
  props: {
    claim: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      claimForm: {
        startAt: null,
        endAt: null,
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
    }
  },
  computed: {
    dateFormat() {
      return util.getDateFormat('element')
    },
  },
  created() {
    this.claimForm = this.claim
  },
  methods: {
    fromChanged() {
      let fromMoment = moment(this.claimForm.startAt)
      this.claimForm.endAt = fromMoment
        .locale('de-DE')
        .endOf('week')
        .subtract(2, 'd')
    },
    onSubmit() {
      claimsApi.update(
        (response) => {
          this.$message({
            type: 'success',
            message: 'Updated',
            duration: 4000,
          })
          this.$emit('update', response)
        },
        (error) => {
          this.loading = false
          this.$message.error({
            showClose: true,
            message: util.formatErrorMessage(error),
            duration: 0,
          })
        },
        this.claim.id,
        {
          start_at: util.formatDateForRequest(this.claimForm.startAt),
          end_at: util.formatDateForRequest(this.claimForm.endAt),
        }
      )
    },
  },
}
</script>
