<template>
  <el-dialog
    :visible.sync="visible"
    title="Add PurchaseInvoice"
    :before-close="close"
    class="purchase-invoice-add-dialog"
  >
    <el-form
      ref="form"
      label-width="120px"
      label-position="top"
      :model="item"
      :rules="rules"
    >
      <el-form-item
        v-if="!project"
        ref="project_id"
        label="Project"
        prop="project_id"
        style="margin-bottom: 5px;"
      >
        <el-select
          v-if="!excludeArchivedProjects"
          v-model="item.project_id"
          placeholder="Please select a project"
          filterable
          @change="loadAreas"
        >
          <el-option
            v-for="(option_item, index) in items"
            :key="index"
            :label="option_item.name"
            :value="option_item.id"
          />
        </el-select>
        <el-select
          v-if="excludeArchivedProjects"
          v-model="item.project_id"
          placeholder="Please select a project"
          filterable
          @change="loadAreas"
        >
          <el-option
            v-for="(option_item, index) in projectsArchived"
            :key="index"
            :label="option_item.name"
            :value="option_item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="!project"
      >
        <el-checkbox
          v-model="excludeArchivedProjects"
          @change="loadAreas"
        >
          Archived projects
        </el-checkbox>
      </el-form-item>

      <el-row :gutter="5">
        <el-col :span="12">
          <el-form-item ref="start_at" label="Date from" prop="start_at">
            <el-date-picker
              v-model="item.start_at"
              format="dd.MM.yyyy"
              type="date"
              placeholder="Pick a date"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Date to">
            <el-date-picker
              v-model="item.end_at"
              format="dd.MM.yyyy"
              type="date"
              placeholder="Pick a date"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
        ref="external_reference"
        label="External reference (Invoice number)"
        prop="external_reference"
      >
        <el-input v-model="item.external_reference" />
      </el-form-item>
      <el-form-item
        ref="external_reference_second"
        label="External reference (Beleg/Auftrag number)"
        prop="external_reference_second"
      >
        <el-input v-model="item.external_reference_second" />
      </el-form-item>
      <el-row :gutter="5">
        <el-col :span="12">
          <el-form-item ref="category" label="Category" prop="pricingCategory">
            <el-select
              v-if="categories.length"
              v-model="item.pricing_category_id"
              placeholder="Please select a category"
            >
              <el-option
                v-for="(category, index) in categories"
                :key="index"
                :label="`${category.name} (${category.areaSelection})`"
                :value="category.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item ref="area" label="Area" prop="area">
            <el-select
              v-model="item.area"
              placeholder="Please select an area"
              multiple
              filterable
            >
              <el-option
                v-for="(option_item, index) in areas"
                :key="index"
                :label="option_item.name"
                :value="option_item.name"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="allowOriginChange" :span="12">
          <el-form-item ref="origin" label="Origin" prop="origin">
            <el-select
              v-model="item.origin"
              placeholder="Please select an origin"
            >
              <el-option
                v-for="(option_item, index) in origin"
                :key="index"
                :label="option_item.label"
                :value="option_item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            v-if="item.origin === 'nachunternehmer'"
            ref="subcontractor_id"
            label="Subcontractor"
            prop="subcontractor_id"
          >
            <el-select
              v-model="item.subcontractor_id"
              placeholder="Please select a subcontractor"
              :loading="loadingSubcontractors"
              filterable
            >
              <el-option
                v-for="(option_item, index) in subcontractors"
                :key="index"
                :label="option_item.name"
                :value="option_item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
        ref="plan_index"
        label="Plan index"
        prop="plan_index"
      >
        <el-input v-model="item.plan_index" />
      </el-form-item>
      <el-form-item label="Description">
        <el-input v-model="item.description" type="textarea" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit"> Create </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import * as util from 'common/util'
import projectsApi from 'api/v2/projects'
import projectAreasApi from 'api/v2/projectAreas'
import projectPricingCategoriesApi from 'api/v2/projectPricingCategories'
import purchaseInvoicesApi from '@/js/api/v2/purchaseInvoices'
import subcontractorsApi from 'api/v2/subcontractors'
import {logErrorMessage} from '../../common/util'

export default {
  name: 'AddDialog',

  props: {
    project: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      visible: true,
      errors: null,
      item: {
        project_id: null,
        start_at: moment().locale('de-DE').startOf('week'),
        end_at: moment().locale('de-DE').endOf('week').subtract(2, 'd'),
        external_reference: null,
        external_reference_second: null,
        plan_index: null,
        class_of_service: null,
        origin: 'nachunternehmer',
        area: null,
        subcontractor_id: null,
      },
      allowOriginChange: false,
      areas: [],
      categories: [],
      id: null,
      loadingSubcontractors: true,
      subcontractors: [],
      excludeArchivedProjects: false,
      projectsArchived: [],
    }
  },
  computed: {
    ...mapGetters('projects', ['items']),

    origin() {
      return this.$store.getters['global/getEnumById']('purchase_invoice_origin')
    },

    rules() {
      const self = this

      return {
        start_at: [
          {
            type: 'date',
            message: 'Date from is required',
            transform: (value) => (value ? new Date(value) : undefined),
          },
        ],
        origin: [
          {
            required: true,
            type: 'string',
            message: 'Origin is required',
          },
        ],
        subcontractor: [
          {
            validator(rule, value, cb) {
              if (self.item.origin === 'nachunternehmer' && !value) {
                cb(
                  new Error(
                    'Please select the subcontractor used for this Invoice'
                  )
                )
              }

              cb()
            },
          },
        ],
      }
    },
  },
  watch: {
    errors: function (error) {
      if (error !== null) {
        let errorMessage = []

        if (error && error.message) {
          errorMessage.push(error.message)
        } else {
          errorMessage.push('A validation error occurred')
        }
        // Let's set the errors
        Object.entries(error.errors).forEach(([key, value]) => {
          if (this.$refs[key]) {
            this.$refs[key].error = value[0]
          }
          errorMessage.push(value[0])
        })
        this.$message.error({
          showClose: true,
          message: errorMessage.join(', '),
          duration: 10000,
        })
      }
    },
    'excludeArchivedProjects': function () {
      this.item.project_id = null
    },
    'item.project_id': function () {
      this.loadCategories()
    },
    'item.origin': function (origin) {
      if (origin === 'nachunternehmer' && this.loadingSubcontractors) {
        this.loadSubcontractors()
      }

      if (origin !== 'nachunternehmer') {
        this.item.subcontractor_id = null
      }
    },
  },
  beforeCreate() {},
  created() {
    if (this.project) {
      this.item.project_id = this.project.id
      this.loadAreas()
    } else {
      this.rules.project_id = [
        {
          required: true,
        },
      ]
    }

    this.loadCategories()
  },
  mounted() {
    this.loadArchivedProjects()
    this.loadSubcontractors()
  },
  methods: {
    close(done) {
      this.$emit('cancel')
      if (done && typeof done === 'function') {
        done()
      }
    },
    loadSubcontractors() {
      subcontractorsApi.index(
        (response) => {
          this.loadingSubcontractors = false
          this.subcontractors = response
        },
        (error) => {
          this.loadingSubcontractors = false
          throw error
        },
        { all: true }
      )
    },
    loadAreas() {
      if (typeof this.item.project_id === 'undefined' || this.item.project_id === null) {
        return
      }
      projectAreasApi.index(
        (response) => {
          this.areas = response

          if (!this.areas.length) {
            const ok = confirm('Please add areas before creating a purchaseInvoice.')

            if (ok) {
              this.$router.push({
                path: 'projects',
                name: 'projectTab',
                params: {
                  projectId: this.item.project_id,
                  tab: 'areas',
                },
              })
            }
          }
        },
        (error) => {
          this.loading = false
          logErrorMessage(error)
        },
        this.item.project_id
      )
    },
    loadCategories() {
      // Request could use the preselected project ID or the one from the drop down
      let requestProjectId = null
      if (this.project && this.project.id) {
        requestProjectId = this.project.id
      } else if (this.item && this.item.project_id) {
        requestProjectId = this.item.project_id
      }
      if (!requestProjectId) {
        return
      }

      projectPricingCategoriesApi.index(
        (response) => {
          this.categories = response
        },
        (error) => {
          this.loading = false
          logErrorMessage(error)
        },
        requestProjectId
      )
    },
    loadArchivedProjects() {
      projectsApi.getArchivedProjects(
        (response) => {
          this.projectsArchived = response
        },
        (error) => {
          this.loading = false
          logErrorMessage(error)
        }
      )
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let payload = Object.assign({}, this.item)
          payload.start_at = util.formatDateForRequest(this.item.start_at)
          payload.end_at = util.formatDateForRequest(this.item.end_at)

          purchaseInvoicesApi.store(
            (response) => {
              this.$message({
                type: 'success',
                message: 'PurchaseInvoice created',
                duration: 4000,
              })

              this.$router.push({
                name: 'projectPurchaseInvoice',
                params: {
                  projectId: payload.project_id,
                  purchaseInvoiceId: response.id,
                  tab: 'purchase-invoices',
                },
              })
              this.close()
            },
            (error) => {
              this.loading = false
              this.errors = error
              logErrorMessage(error)
            },
            payload
          )
        }
      })
    },
  },
}
</script>

<style lang="scss">
.purchase-invoice-add-dialog {
  .el-select {
    width: 100%;
  }
}
.project-hidden {
  display: none;
}
</style>
