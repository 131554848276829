<template>
  <el-dialog
    class="purchaseInvoice-edit-item-dialog"
    :visible.sync="visible"
    title="Edit Invoice item"
    :before-close="close"
    :close-on-click-modal="false"
  >
    <el-form v-if="purchaseInvoiceItem" label-width="120px" label-position="top">
      <el-form-item ref="external_reference" label="Comment">
        <el-input v-model="editItem.comment" :required="true" />
      </el-form-item>
      <el-form-item ref="section" label="Section">
        <el-input v-model="editItem.section" />
      </el-form-item>
      <el-form-item ref="reference" label="Reference">
        <el-input v-model="editItem.reference" />
      </el-form-item>
      <el-form-item ref="position" label="Pricing item">
        <el-select
          v-model="editItem.pricingItem"
          placeholder="Please select..."
          filterable
          return-object
          value-key="id"
          class="position-select"
          @change="itemChanged"
        >
          <el-option-group
            v-for="optionGroup in pricingItemOptions"
            :key="optionGroup.id"
            :label="optionGroup.name"
            :visible="true"
          >
            <el-option
              v-for="option in optionGroup.options"
              :key="option.id"
              :label="`${option.reference} / ${option.description}`"
              :value="option"
            />
          </el-option-group>
        </el-select>
      </el-form-item>

      <el-form-item label="Measurements" class="measurements">
        <el-row :gutter="20">
          <el-col :span="5">
            <el-input
              v-if="
                !editItem.pricingItem ||
                ['M', 'M2', 'M3'].indexOf(editItem.pricingItem.unit) !== -1
              "
              v-model="editItem.length"
              :required="true"
            >
              <template slot="prepend">Length</template>
              <template slot="append">M</template>
            </el-input>
          </el-col>
          <el-col :span="5">
            <el-input
              v-if="
                !editItem.pricingItem ||
                ['M2', 'M3'].indexOf(editItem.pricingItem.unit) !== -1
              "
              v-model="editItem.width"
              :required="true"
              prop="editItem.width"
            >
              <template slot="prepend">Width</template>
              <template slot="append">M</template>
            </el-input>
          </el-col>
          <el-col :span="5">
            <el-input
              v-if="!editItem.pricingItem || editItem.pricingItem.unit === 'M3'"
              v-model="editItem.height"
              :required="true"
            >
              <template slot="prepend">Height</template>
              <template slot="append">M</template>
            </el-input>
          </el-col>
          <el-col :span="5">
            <el-input
              v-if="
                !editItem.pricingItem ||
                editItem.pricingItem.unit === 'ST' ||
                editItem.pricingItem.unit === 'STD'
              "
              v-model="editItem.amount"
              :required="true"
            >
              <template slot="prepend">{{
                editItem.pricingItem && editItem.pricingItem.unit === 'STD'
                  ? 'Hours'
                  : 'Amount'
              }}</template>
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-input v-model="editItem.factor" :required="true">
              <template slot="prepend">Factor</template>
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item label="Calculated" class="calculated">
        <el-row :gutter="20">
          <el-col :span="5">
            <el-input
              v-model="getMass"
              :required="true"
              :readonly="true"
              disabled
            >
              <template slot="prepend">Mass</template>
              <template v-if="editItem.pricingItem" slot="append">{{
                editItem.pricingItem.unit
              }}</template>
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-input v-model="editItem.price" :required="true">
              <template slot="prepend">Price</template>
              <template slot="append">EUR</template>
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-input
              v-model="getGross"
              :required="true"
              :readonly="true"
              disabled
            >
              <template slot="prepend">Gross</template>
              <template slot="append">EUR</template>
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-input
              v-model="getNet"
              :required="true"
              :readonly="true"
              disabled
            >
              <template slot="prepend">Net</template>
              <template slot="append">EUR</template>
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item>
        <el-button :loading="loading" type="primary" @click="onSubmit">
          Update
        </el-button>
        <el-button type="warning" @click="close"> Cancel </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'
import { cloneDeep } from 'lodash-es'
import purchaseInvoiceItemsApi from 'api/v2/purchaseInvoiceItems'
import pricingTemplateApi from '../../api/v2/pricingTemplates'
import * as purchaseInvoiceUtil from 'common/purchaseInvoiceUtil'
import { formatErrorMessage } from '../../common/util'

export default {
  name: 'PurchaseInvoiceItemEditDialog',
  props: {
    purchaseInvoice: {
      type: Object,
      default: null,
    },
    purchaseInvoiceItem: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      visible: true,
      pricingCategories: [],
      pricingItems: [],
      editItem: {},
      items: [],
    }
  },
  computed: {
    getMass() {
      return purchaseInvoiceUtil.getMass(this.editItem)
    },
    getGross() {
      return purchaseInvoiceUtil.getGross(this.editItem, true)
    },
    getNet() {
      return purchaseInvoiceUtil.getNet(this.editItem, true)
    },
    pricingItemOptions() {
      if (!this.pricingCategories || !this.pricingItems) {
        return []
      }
      const options = []
      this.pricingCategories.forEach((pricingCategory) => {
        const optionGroup = {
          id: `cat-${pricingCategory.id}`,
          name: pricingCategory.name,
          options: [],
        }
        optionGroup.options = this.pricingItems.filter(
          (pricingItem) =>
            pricingItem.pricing_category_id === pricingCategory.id
        )
        options.push(optionGroup)
      })
      return options
    },
  },
  mounted() {
    this.editItem = cloneDeep(camelCaseKeys(this.purchaseInvoiceItem, { deep: true }))
    this.getCategories()
  },
  methods: {
    close(done) {
      this.$emit('cancel')
      if (done && typeof done === 'function') {
        this.$nextTick(() => {
          done()
        })
      }
    },
    onSubmit() {
      this.editItem.item_id = this.editItem.itemId

      this.loading = true
      purchaseInvoiceItemsApi.update(
        (response) => {
          this.loading = false
          this.$emit('updateItem', response)
          this.close()
        },
        (error) => {
          this.loading = false
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.purchaseInvoice.id,
        this.editItem.id,
        snakeCaseKeys(this.editItem)
      )
    },
    getCategories() {
      pricingTemplateApi.index(
        (response) => {
          const pricingTemplate = response[0]
          this.pricingCategories = pricingTemplate.categories
          this.pricingItems = pricingTemplate.items
        },
        () => {
          // Do nothing
        },
        {
          project_id: this.purchaseInvoice.project.id,
          subcontractor_id: this.purchaseInvoice.subcontractor
            ? this.purchaseInvoice.subcontractor.id
            : null,
          include: ['categories', 'items'],
        }
      )
    },
    itemChanged(selectedItem) {
      this.updateItemAmounts(selectedItem)
    },
    updateItemAmounts(selectedPricingItem) {
      if (!selectedPricingItem) return

      const pricingItem = this.pricingItems.find((item) => {
        return item.id === selectedPricingItem.id
      })

      this.editItem.pricing_item_id = pricingItem.id
      this.editItem.price = pricingItem.price
      this.editItem.factor = pricingItem.factor
      this.editItem.unit = pricingItem.unit
    },
  },
}
</script>

<style lang="scss">
.purchaseInvoice-edit-item-dialog {
  ::v-deep .position-select {
    width: 100%;
  }

  ::v-deep .measurements input,
  ::v-deep .calculated input {
    text-align: right;
  }
}
</style>
