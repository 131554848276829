import { generateSetters } from '../../helpers.js'

export default {
  ...generateSetters(['pnl']),

  setLerfOverview(state, { statuses, summary }) {
    state.lerfOverview = statuses
    state.lerfOverviewSummary = summary
  },
}
