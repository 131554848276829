<template>
  <div class="project-single-overview">
    <el-col v-if="isNewProject" :span="8"> NEW</el-col>
    <el-row
      v-if="!isNewProject && project && projectFields"
      v-loading="isLoading"
      :gutter="20"
      element-loading-text="Loading project..."
    >
      <el-col :span="8">
        <overview-general
          :project="project"
          :project-fields="
            projectFields
              ? projectFields.filter((pf) => pf.category === 'general')
              : null
          "
          :set-project-fields="setProjectFields"
        />

        <overview-schedule v-if="false && !isNewProject" :project="project"/>
      </el-col>
      <el-col v-if="!isNewProject" :span="8">
        <overview-customer
          v-if="!isNewProject"
          :project="project"
          :project-fields="
            projectFields
              ? projectFields.filter((pf) => pf.category === 'customer')
              : null
          "
          :set-project-fields="setProjectFields"
        />
        <OverviewDepartment
          v-if="isSuperAdmin && can('view', 'area_meta', 'bid_management')"
          :on-success="setProjectFields"
          :project="project"
          :project-fields="projectFields"
          category="bid_management"
        />
        <OverviewDepartment
          v-if="isSuperAdmin && can('view', 'area_meta', 'controlling')"
          :on-success="setProjectFields"
          :project="project"
          :project-fields="projectFields"
          category="controlling"
        />
      </el-col>
      <el-col v-if="!isNewProject" :span="8">
        <OverviewDepartment
          v-if="isSuperAdmin && can('view', 'area_meta', 'bauleiters')"
          :on-success="setProjectFields"
          :project="project"
          :project-fields="projectFields"
          category="bauleiters"
        />
        <OverviewDepartment
          v-if="isSuperAdmin && can('view', 'area_meta', 'engineering')"
          :on-success="setProjectFields"
          :project="project"
          :project-fields="projectFields"
          category="engineering"
        />
        <OverviewDepartment
          v-if="isSuperAdmin && can('view', 'area_meta', 'other')"
          :on-success="setProjectFields"
          :project="project"
          :project-fields="projectFields"
          category="other"
        />
      </el-col>
    </el-row>

    <div v-if="allRevisions && allRevisions.length">
      <revision-table
        :revisions="allRevisions"
        card-title="Project meta Fields revisions"
        type="projectMeta"
      >
        <template slot="buttons">
          <el-button
            :loading="isDownloading"
            size="small"
            style="float: right"
            @click="downloadRevisionHistory"
          >
            export
          </el-button>
        </template>
      </revision-table>
    </div>
  </div>
</template>

<script>
import projectMetaFieldsApi from 'api/v2/projectMetaFields'
import {AuthorizationError} from 'api/errors'
import {formatDate, formatErrorMessage} from 'common/util'
import { can } from 'common/util'

import OverviewGeneral from './Overview/General.vue'
import OverviewCustomer from './Overview/Customer.vue'
import OverviewSchedule from './Overview/Schedule.vue'
import OverviewDepartment from './Overview/Department.vue'
import RevisionTable from '../../../Common/RevisionTable.vue'
import {orderBy} from 'lodash'

export default {
  name: 'ProjectSingleOverview',

  components: {
    OverviewGeneral,
    OverviewCustomer,
    OverviewSchedule,
    OverviewDepartment,
    RevisionTable,
  },

  props: {
    project: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      projectFields: null,
      isDownloading: false,
    }
  },

  computed: {
    isNewProject() {
      return !this.project || !this.project.id
    },
    allRevisions() {
      if (!this.projectFields) {
        return null
      }
      return orderBy(
        this.projectFields.reduce((acc, current) => {
          if (current.revisionHistory) {
            const revisionHistory = current.revisionHistory.map((h) => ({
              name: current.name,
              category: current.category,
              ...h,
            }))
            return acc.concat(revisionHistory)
          }

          return acc
        }, []),
        ['updated_at'],
        ['desc']
      )
    },
    isSuperAdmin() {
      return this.$store.getters['global/user']['role'] === 'super_admin'
    }
  },

  mounted() {
    if (!this.isNewProject) {
      this.fetchProjectFields()
    }
  },

  methods: {
    can(action, category, subcategory) {
      return can(action, category, subcategory)
    },
    formatDate(value) {
      return formatDate(value)
    },
    fetchProjectFields() {
      this.isLoading = true
      projectMetaFieldsApi.index(
        (response) => {
          this.isLoading = false
          this.$set(this, 'projectFields', response)
        },
        (error) => {
          this.isLoading = false
          if (error instanceof AuthorizationError) {
            this.$router.push('/')
          }

          this.$message.error({
            message: formatErrorMessage(error),
          })
        },
        this.project.id
      )
    },

    setProjectFields(projectFields) {
      this.$set(this, 'projectFields', projectFields)
    },

    downloadRevisionHistory() {
      this.isDownloading = true

      projectMetaFieldsApi.exportRevisionHistory(
        (response) => {
          let blob = new Blob([response])
          let link = document.createElement('a')
          const objURL = window.URL.createObjectURL(blob)
          link.href = objURL
          link.download = 'project-meta-revision-history-export.csv'
          link.click()

          window.URL.revokeObjectURL(objURL)

          this.isDownloading = false
        },
        (error) => {
          this.isDownloading = false
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.project.id
      )
    },
  },
}
</script>

<style lang="scss">
.project-single-overview {
  .cell {
    width: inherit !important;
  }

  .el-tag {
    float: right;
  }

  .lerfs {
    margin-top: 1rem;
  }

  .project-details {
    .key {
      text-align: right;
      font-weight: bold;
    }

    .el-row {
      + .el-row {
        margin-top: 0.5rem;
      }
    }
  }

  .el-card {
    + .el-card {
      margin-top: 1.5rem;
    }
  }

  .planned-vs-realized {
    td,
    th {
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        text-align: right;
      }
    }
  }

  .table-card {
    margin-top: 3rem;

    .el-card__body {
      padding: 0;
    }
  }
}
</style>
