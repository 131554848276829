<template>
  <el-dialog :visible.sync="showDialog" :title="formTitle">
    <default-form
      v-if="showDialog"
      ref="form"
      :form="form"
      :loading="loading"
      :errors="formErrors"
      @submit="addOrUpdateSubcategory"
    />
  </el-dialog>
</template>

<script>
import snakeCaseKeys from 'snakecase-keys'
import pricingSubcategoriesApi from 'api/v2/pricingSubcategories'
import DefaultForm from 'components/Common/Form/DefaultForm'

export default {
  name: 'AddEditSubcategoryDialog',

  components: {
    DefaultForm,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    subcategory: {
      type: Object,
      default() {
        return null
      },
    },
    areaSelection: {
      type: String,
      required: true,
    },
    categoryName: {
      type: String,
      default: null,
    },
    subcontractorId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      showDialog: false,
      loading: false,
      formErrors: null,
    }
  },

  computed: {
    formTitle() {
      return this.subcategory ? 'Edit subcategory' : 'Add subcategory'
    },
    formItems() {
      return [
        {
          label: 'Category name',
          prop: 'categoryName',
          required: true,
          disabled: !!this.subcategory || !!this.categoryName,
          rules: [],
        },
        {
          label: 'Subcategory name',
          prop: 'subcategoryName',
          required: true,
          rules: [],
        },
        {
          label: 'Cost unit',
          prop: 'costUnit',
          required: false,
          rules: [],
        },
      ]
    },

    form() {
      return {
        items: this.formItems,
        buttons: [
          {
            event: 'submit',
            label: this.subcategory ? 'Update subcategory' : 'Add subcategory',
            type: 'primary',
          },
        ],
        ref: 'addCategoryForm',
        initialModel: {
          id: this.subcategory ? this.subcategory.id : null,
          subcontractorId: this.subcontractorId,
          areaSelection: this.areaSelection,
          categoryName: this.subcategory
            ? this.subcategory.categoryName
            : this.categoryName || null,
          subcategoryName: this.subcategory
            ? this.subcategory.subcategoryName
            : this.subcategoryName || null,
          costUnit: this.subcategory ? this.subcategory.costUnit : null,
        },
      }
    },
  },

  watch: {
    visible(value) {
      this.$nextTick(() => {
        this.showDialog = value
      })
    },
    showDialog(value) {
      this.$emit('update:visible', value)
    },
    subcategory: {
      immediate: true,
      handler() {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      },
    },
  },

  methods: {
    addOrUpdateSubcategory(model) {
      if (this.subcategory) {
        this.updateSubcategory(model)
      } else {
        this.addSubcategory(model)
      }
    },
    addSubcategory(model) {
      this.loading = true
      pricingSubcategoriesApi.store(
        () => {
          this.$message.success({
            showClose: true,
            message: 'Category has been created.',
            duration: 5000,
          })
          this.loading = false
          this.showDialog = false
          this.$emit('change')
        },
        (error) => {
          this.loading = false
          this.formErrors = error
          this.$nextTick(() => {
            this.formErrors = null
          })
        },
        snakeCaseKeys(model)
      )
    },
    updateSubcategory(model) {
      this.loading = true
      pricingSubcategoriesApi.update(
        () => {
          this.$message.success({
            showClose: true,
            message: 'Category has been updated.',
            duration: 5000,
          })
          this.loading = false
          this.showDialog = false
          this.$emit('change')
        },
        (error) => {
          this.loading = false
          this.formErrors = error
          this.$nextTick(() => {
            this.formErrors = null
          })
        },
        this.subcategory.id,
        snakeCaseKeys(model)
      )
    },
  },
}
</script>

<style lang="scss"></style>
