import ApiResource from '../ApiResource'

class positionValidationResource extends ApiResource {
  constructor() {
    super({
      name: 'position-validation',
    })
  }
}

const positionValidationApi = new positionValidationResource()

export default positionValidationApi
