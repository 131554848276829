<template>
  <el-dialog
    ref="dialog"
    :title="mediaItem.file_name"
    :visible="visible"
    top="48px"
    :before-close="handleCloseImageDialog"
  >
    <div v-loading="loading">
      <template v-if="isImage()">
        <img
          ref="image"
          :src="mediaItem.url"
          class="image"
          @load="imageLoaded"
        />
      </template>

      <template v-if="isPdf()">
        <embed
          :src="mediaItem.url"
          type="application/pdf"
          class="pdf"
          :height="innerHeight"
          width="100%"
        />
      </template>
    </div>
  </el-dialog>
</template>

<script>
let resizeTimer
export default {
  name: 'MediaDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    mediaItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      innerWidth: 400,
      loading: true,
      resized: false,
    }
  },
  computed: {
    innerHeight() {
      return '800px'
    },
  },
  watch: {
    visible(value) {
      if (value) {
        this.resize()
      }
    },
  },
  mounted() {
    this.resize()
  },
  methods: {
    resize() {
      const el = this.$refs.dialog.$el.children[0]
      if (this.isPdf()) {
        el.style.width = '90vw'
        this.loading = false
        return
      }
      if (this.isImage()) {
        resizeTimer = setInterval(() => {
          const imageWidth = this.$refs.image && this.$refs.image.clientWidth
          if (imageWidth) {
            el.style.width = `${imageWidth + 40}px`
            clearInterval(resizeTimer)
          }
        }, 100)
      }
    },
    imageLoaded() {
      this.loading = false
    },
    handleCloseImageDialog(done) {
      this.$emit('update:visible', false)
      done()
    },
    isPdf() {
      return ['application/pdf'].indexOf(this.mediaItem.mime_type) !== -1
    },
    isImage() {
      return (
        ['image/png', 'image/jpg', 'image/jpeg'].indexOf(
          this.mediaItem.mime_type
        ) !== -1
      )
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  // width: 90vh;
  max-height: 90vh;
}
img,
.pdf {
  max-height: 80vh;
  // max-width: 80vw;
}
.pdf {
  width: 100%;
}
</style>
