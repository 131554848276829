import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

// Views
import RootView from 'components/Views/Core'
import LoginView from 'components/Views/Auth/LoginView'
import NotFoundView from 'components/Views/NotFound'
import DebugView from 'components/Views/DebugView'
import ProfileView from 'components/Views/ProfileView'

import ApprovalsView from 'components/Views/Approvals'
import ApprovalsAddView from 'components/Views/Approvals/Add'
import ApprovalsSingleView from 'components/Views/Approvals/Single'
import ProjectsView from 'components/Views/Projects'
import ProjectDetailsView from 'components/Views/ProjectDetails'
import ProjectsArchiveView from 'components/Views/Projects/Archive'
import ProjectSingleView from 'components/Views/Projects/Single'
import ProjectsCreateView from 'components/Views/Projects/ProjectsCreate'
import ManageProjectFields from 'components/Views/Projects/ManageProjectFields'
import ManageInvoiceFields from 'components/Views/Projects/ManageInvoiceFields.vue';
import ProjectsMeta from 'components/Views/ProjectsMeta'

const ProjectsMap = () => import('components/Views/Projects/Map')

import LerfsView from 'components/Views/Lerfs'
import PurchaseInvoicesView from 'components/Views/PurchaseInvoices'
import LerfSingleView from 'components/Views/Lerfs/Single'
import PurchaseInvoiceSingleView from 'components/Views/PurchaseInvoices/Single'
import PositionsView from 'components/Views/Positions'
import PositionSingleView from 'components/Views/Positions/Single'

import BidsView from 'components/Views/Bids'
import BidEdit from 'components/Views/Bids/edit'

import ClaimsView from 'components/Views/Claims'
import ClaimSingleView from 'components/Views/Claims/Single'

import TeamsView from 'components/Views/Teams'
import SubcontractorsView from 'components/Views/Subcontractors'
import SubcontractorSingleView from 'components/Views/Subcontractors/Single'
import SubcontractorsArchiveView from 'components/Views/Subcontractors/Archive'
import FixPricing from 'components/Views/Subcontractors/FixPricing.vue';
import WeeklyReportView from 'components/Views/Reports/WeeklyReport'
import LerfReportView from 'components/Views/Reports/LerfReport'
import ClaimReportView from 'components/Views/Reports/ClaimReport'
import SubcontractorsReportView from 'components/Views/Reports/SubcontractorsReport'
import ProjectReportView from 'components/Views/Reports/ProjectReport'
import ProjectMetaReportView from 'components/Views/Reports/ProjectMetaReport'
import AreaMetaReport from 'components/Views/Reports/AreaMetaReport'
import PricingTemplatesView from 'components/Views/Pricing/Templates'
import PricingTemplateSingleView from 'components/Views/Pricing/Templates/Single'
import PricingSubcategoriesView from 'components/Views/Pricing/Subcategories'
import PricingSyncView from 'components/Views/Pricing/Sync'
import PricingAdvancedSyncView from 'components/Views/Pricing/AdvancedSync'
import PricingTranslationView from 'components/Views/Pricing/Mapping'
import CustomersView from 'components/Views/Customers'
import UsersView from 'components/Views/Users'
import NotificationsView from 'components/Views/Notifications'
import PositionValidationSubcontractorView from  'components/Views/Positions/Validation/Subcontractor'
import CacheControlView from 'components/Views/CacheControl';

Vue.use(VueRouter)

// Catches Navigation errors, duplicated, cancelled or aborted and print any other navigation error
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (
      ['NavigationDuplicated'].includes(err.name) ||
      [4, 8, 16].includes(err.type)
    ) {
      return
    }
    // eslint-disable-next-line
    console.error(err)
  })
}

const routes = [
  {
    path: '/',
    component: RootView,
    meta: {},
    children: [
      {
        path: '',
        redirect: '/projects',
      },
      {
        path: 'debug',
        components: {
          body: DebugView,
        },
        meta: {
          needsAuth: false,
          title() {
            let str = 'Debug'

            return str
              .split('')
              .sort(() => {
                return 0.5 - Math.random()
              })
              .join('')
          },
        },
      },
      {
        path: 'users',
        redirect: '/users/page/1',
        meta: {
          needsAuth: true,
        },
      },
      {
        path: 'users/page/:pageNr',
        components: {
          body: UsersView,
        },
        meta: {
          needsAuth: true,
          title: 'Users',
        },
      },

      {
        path: 'customers',
        redirect: '/customers/page/1',
        meta: {
          needsAuth: true,
        },
      },
      {
        path: 'customers/page/:pageNr',
        components: {
          body: CustomersView,
        },
        meta: {
          needsAuth: true,
          title: 'Customers',
        },
      },
      {
        path: 'subcontractors',
        redirect: '/subcontractors/page/1',
        meta: {
          needsAuth: true,
        },
      },
      {
        path: 'subcontractors/fix-pricing',
        components: {
          body: FixPricing,
        },
        meta: {
          needsAuth: true,
          title: 'Fix pricing',
        },
      },
      {
        path: 'subcontractors/fix-pricing/:positionId',
        components: {
          body: FixPricing,
        },
        meta: {
          needsAuth: true,
          title: 'Fix pricing',
        },
      },
      {
        path: 'subcontractors/archived',
        redirect: '/subcontractors/archived/page/1',
        meta: {
          needsAuth: true,
          title: 'Subcontractors archive',
        },
      },
      {
        path: 'subcontractors/archived/page/:pageNr',
        components: {
          body: SubcontractorsArchiveView,
        },
        meta: {
          needsAuth: true,
          title: 'Subcontractors archive',
        },
      },
      {
        path: 'subcontractors/page/:pageNr',
        components: {
          body: SubcontractorsView,
        },
        meta: {
          needsAuth: true,
          title: 'Subcontractors',
        },
      },
      {
        path: 'subcontractors/:subcontractorId',
        redirect: '/subcontractors/:subcontractorId/overview',
      },
      {
        path: 'subcontractors/:subcontractorId/:tab',
        components: {
          body: SubcontractorSingleView,
        },
        meta: {
          needsAuth: true,
          title: 'Subcontractor',
        },
      },
      {
        path: 'teams',
        redirect: '/teams/page/1',
        meta: {
          needsAuth: true,
        },
      },
      {
        path: 'teams/page/:pageNr',
        components: {
          body: TeamsView,
        },
        meta: {
          needsAuth: true,
          title: 'Teams',
        },
      },

      {
        path: 'projects',
        redirect: '/projects/list',
        meta: {
          needsAuth: true,
        },
      },
      {
        path: 'projects/list',
        redirect: '/projects/list/page/1',
        meta: {
          needsAuth: true,
        },
      },
      {
        path: 'projects/list/page/:pageNr',
        components: {
          body: ProjectsView,
        },
        meta: {
          needsAuth: true,
          title: 'Projects',
        },
      },

      {
        path: 'projects/archived',
        redirect: '/projects/archived/page/1',
        meta: {
          needsAuth: true,
        },
      },
      {
        path: 'projects/archived/page/:pageNr',
        components: {
          body: ProjectsArchiveView,
        },
        meta: {
          needsAuth: true,
          title: 'Projects archive',
        },
      },

      {
        path: 'projects/map',
        components: {
          body: ProjectsMap,
        },
        meta: {
          needsAuth: true,
          title: 'Projects map',
        },
      },

      {
        path: 'projects/fields',
        components: {
          body: ManageProjectFields,
        },
        meta: {
          needsAuth: true,
          title: 'Project fields',
        },
      },
      {
        path: 'projects/invoice-fields',
        components: {
          body: ManageInvoiceFields,
        },
        meta: {
          needsAuth: true,
          title: 'Invoice fields',
        },
      },
      {
        path: 'projects/meta',
        components: {
          body: ProjectsMeta,
        },
        meta: {
          needsAuth: true,
          title: 'Stammdaten (Area Meta)',
        },
      },
      {
        path: 'pricing',
        redirect: '/pricing/templates',
      },
      {
        path: 'pricing/templates',
        redirect: '/pricing/templates/page/1',
      },
      {
        path: 'pricing/templates/page/:pageNr',
        components: {
          body: PricingTemplatesView,
        },
        meta: {
          needsAuth: true,
          title: 'Templates',
        },
      },
      {
        path: 'pricing/templates/edit/:pricingTemplateId',
        name: 'pricingTemplateEdit',
        components: {
          body: PricingTemplateSingleView,
        },
        meta: {
          needsAuth: true,
          title: 'Edit Template',
        },
      },
      {
        path: 'pricing/subcategories',
        components: {
          body: PricingSubcategoriesView,
        },
        meta: {
          needsAuth: true,
          title: 'Pricing Subcategories',
        },
      },
      {
        path: 'pricing/sync',
        components: {
          body: PricingSyncView,
        },
        meta: {
          needsAuth: true,
          title: 'Pricing Sync',
        },
      },
      {
        path: 'pricing/advanced-sync',
        redirect: () => {
          return 'pricing/advanced-sync/pricing-template'
        },
      },
      {
        path: 'pricing/advanced-sync/:tab',
        components: {
          body: PricingAdvancedSyncView,
        },
        meta: {
          needsAuth: true,
          title: 'Pricing Advanced Sync',
        },
        children: [
          {
            path: ':pricingItemId',
            components: {
              body: PricingAdvancedSyncView,
            },
            meta: {
              needsAuth: true,
              title: 'Pricing Advanced Sync: Item',
            },
          },
        ]
      },
      {
        path: 'pricing/mapping',
        redirect: 'pricing/mapping/page/1',
        meta: {
          needsAuth: true,
        },
      },
      {
        path: 'pricing/mapping/page/:pageNr',
        components: {
          body: PricingTranslationView,
        },
        meta: {
          needsAuth: true,
          title: 'Mapping',
        },
      },

      {
        path: 'approvals',
        redirect: '/approvals/list',
        meta: {
          needsAuth: true,
        },
      },
      {
        path: 'approvals/list',
        redirect: '/approvals/list/page/1',
        meta: {
          needsAuth: true,
        },
      },
      {
        path: 'approvals/list/page/:pageNr',
        components: {
          body: ApprovalsView,
        },
        meta: {
          needsAuth: true,
          title: 'Approvals',
        },
      },
      {
        path: 'approvals/add',
        components: {
          body: ApprovalsAddView,
        },
        meta: {
          needsAuth: true,
          title: 'Add Approval',
        },
      },
      {
        path: 'positions',
        components: {
          body: PositionsView,
        },
        meta: {
          needsAuth: true,
          title: 'Positions',
        },
      },
      {
        path: 'position-validation/subcontractor',
        components: {
          body: PositionValidationSubcontractorView,
        },
        meta: {
          needsAuth: true,
          title: 'Approvals',
        },
      },
      {
        path: 'purchase-invoices',
        components: {
          body: PurchaseInvoicesView,
        },
        meta: {
          needsAuth: true,
          title: 'Purchase Invoices',
        },
      },
      {
        path: 'lerfs',
        components: {
          body: LerfsView,
        },
        meta: {
          needsAuth: true,
          title: 'Sales Invoices',
        },
      },
      {
        path: 'bids',
        components: {
          body: BidsView,
        },
        props: {
          body: {
            tab: 'open-bids',
          },
        },
        children: [
          {
            path: 'open-bids',
            props: {
              body: {
                tab: 'open-bids',
              },
            },
            meta: {
              needsAuth: true,
              title: 'Open bids',
            },
          },
          {
            path: 'finalized-bids',
            props: {
              body: {
                tab: 'finalized-bids',
              },
            },
            meta: {
              needsAuth: true,
              title: 'Finalized bids',
            },
          },
          {
            path: 'closed-bids',
            props: {
              body: {
                tab: 'closed-bids',
              },
            },
            meta: {
              needsAuth: true,
              title: 'Closed bids',
            },
          },
        ],
      },
      {
        path: 'bids/:bidId/edit',
        components: {
          body: BidEdit,
        },
      },
      {
        path: 'claims',
        components: {
          body: ClaimsView,
        },
        meta: {
          needsAuth: true,
          title: 'Claims',
        },
      },

      {
        path: 'reports/weekly',
        components: {
          body: WeeklyReportView,
        },
        meta: {
          needsAuth: true,
          title: 'Weekly report',
        },
      },

      {
        path: 'reports/lerf',
        components: {
          body: LerfReportView,
        },
        meta: {
          needsAuth: true,
          title: 'Invoice report',
        },
      },

      {
        path: 'reports/claim',
        components: {
          body: ClaimReportView,
        },
        meta: {
          needsAuth: true,
          title: 'Claim report',
        },
      },

      {
        path: 'reports/subcontractors',
        components: {
          body: SubcontractorsReportView,
        },
        meta: {
          needsAuth: true,
          title: 'Subcontractors report',
        },
      },

      {
        path: 'reports/project',
        components: {
          body: ProjectReportView,
        },
        meta: {
          needsAuth: true,
          title: 'Project report',
        },
      },

      {
        path: 'reports/project-meta',
        components: {
          body: ProjectMetaReportView,
        },
        meta: {
          needsAuth: true,
          title: 'Project meta report',
        },
      },

      {
        path: 'reports/area-meta',
        components: {
          body: AreaMetaReport,
        },
        meta: {
          needsAuth: true,
          title: 'Area meta report',
        },
      },

      {
        path: 'projects',
        components: {
          body: ProjectDetailsView,
        },
        meta: {
          needsAuth: true,
        },
        children: [
          {
            path: 'add',
            name: 'newProject',
            components: {
              body: ProjectsCreateView,
            },
            props: {
              body: {
                tab: 'overview',
              },
            },
            meta: {
              needsAuth: true,
              title: 'New project',
            },
          },
          {
            path: ':projectId',
            redirect: (to) => {
              return `${to.params.projectId}/overview`
            },
            meta: {
              needsAuth: true,
            },
          },
          {
            path: ':projectId/positions',
            name: 'projectPositions',
            components: {
              body: ProjectSingleView,
            },
            props: {
              body: {
                tab: 'positions',
              },
            },
            meta: {
              needsAuth: true,
              title: (store) => {
                let project = store.getters['projects/currentProject']

                if (project) {
                  return project.name
                }
              },
            },
            children: [
              {
                path: ':positionId',
                name: 'projectPosition',
                components: {
                  body: PositionSingleView,
                },
                redirect: (to) => {
                  return `${to.params.positionId}/overview`
                },
                props: {
                  body: true,
                },
                meta: {
                  needsAuth: true,
                },
                children: [
                  {
                    path: ':tab',
                    name: 'projectPositionTab',
                    components: {
                      body: PositionSingleView,
                    },
                    meta: {
                      needsAuth: true,
                      title: (store) => {
                        const project = store.getters['projects/currentProject']
                        const position =
                          store.getters['projects/currentPosition']
                        const positionReference =
                          position.externalReference || ''
                        const positiondescription = position.description || ''

                        if (project) {
                          return `${project.name}, POSITION: ${positionReference} - ${positiondescription}`
                        }
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: ':projectId/lerfs',
            name: 'projectLerfs',
            components: {
              body: ProjectSingleView,
            },
            props: {
              body: {
                tab: 'lerfs',
              },
            },
            meta: {
              needsAuth: true,
              title: (store) => {
                let project = store.getters['projects/currentProject']

                if (project) {
                  return project.name
                }
              },
            },
            children: [
              {
                path: ':lerfId',
                name: 'projectLerf',
                components: {
                  body: LerfSingleView,
                },
                redirect: (to) => {
                  return `${to.params.lerfId}/overview`
                },
                props: {
                  body: true,
                },
                meta: {
                  needsAuth: true,
                },
                children: [
                  {
                    path: ':tab',
                    name: 'projectLerfTab',
                    components: {
                      body: LerfSingleView,
                    },
                    meta: {
                      needsAuth: true,
                      title: (store) => {
                        const project = store.getters['projects/currentProject']
                        const lerf = store.getters['projects/currentLerf']
                        const lerfReference = lerf.externalReference || ''
                        const lerfdescription = lerf.description || ''

                        if (project) {
                          return `${project.name}, Invoice: ${lerfReference} - ${lerfdescription}`
                        }
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: ':projectId/purchase-invoices',
            name: 'projectPurchaseInvoices',
            components: {
              body: ProjectSingleView,
            },
            props: {
              body: {
                tab: 'purchase-invoices',
              },
            },
            meta: {
              needsAuth: true,
              title: (store) => {
                let project = store.getters['projects/currentProject']

                if (project) {
                  return project.name
                }
              },
            },
            children: [
              {
                path: ':purchaseInvoiceId',
                name: 'projectPurchaseInvoice',
                components: {
                  body: PurchaseInvoiceSingleView,
                },
                redirect: (to) => {
                  return `${to.params.purchaseInvoiceId}/overview`
                },
                props: {
                  body: true,
                },
                meta: {
                  needsAuth: true,
                },
                children: [
                  {
                    path: ':tab',
                    name: 'projectPurchaseInvoiceTab',
                    components: {
                      body: PurchaseInvoiceSingleView,
                    },
                    meta: {
                      needsAuth: true,
                      title: (store) => {
                        const project = store.getters['projects/currentProject']
                        const purchaseInvoice = store.getters['projects/currentPurchaseInvoice']
                        const purchaseInvoiceReference = purchaseInvoice.externalReference || ''
                        const purchaseInvoiceDescription = purchaseInvoice.description || ''

                        if (project) {
                          return `${project.name}, Invoice: ${purchaseInvoiceReference} - ${purchaseInvoiceDescription}`
                        }
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: ':projectId/claims',
            name: 'projectClaims',
            components: {
              body: ProjectSingleView,
            },
            props: {
              body: {
                tab: 'claims',
              },
            },
            meta: {
              needsAuth: true,
              title: (store) => {
                let project = store.getters['projects/currentProject']

                if (project) {
                  return project.name
                }
              },
            },
            children: [
              {
                path: ':claimId',
                name: 'projectClaim',
                components: {
                  body: ClaimSingleView,
                },
                redirect: (to) => {
                  return `${to.params.claimId}/overview`
                },
                props: {
                  body: true,
                },
                meta: {
                  needsAuth: true,
                },
                children: [
                  {
                    path: ':tab',
                    name: 'projectClaimTab',
                    components: {
                      body: ClaimSingleView,
                    },
                    meta: {
                      needsAuth: true,
                      title: (store) => {
                        const project = store.getters['projects/currentProject']
                        const claim = store.getters['projects/currentClaim']
                        const claimReference = claim.externalReference || ''
                        const claimdescription = claim.description || ''

                        if (project) {
                          return `${project.name}, Claim: ${claimReference} - ${claimdescription}`
                        }
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: ':projectId/approvals',
            name: 'projectApprovals',
            components: {
              body: ProjectSingleView,
            },
            props: {
              body: {
                tab: 'approvals',
              },
            },
            meta: {
              needsAuth: true,
              title: (store) => {
                let project = store.getters['projects/currentProject']

                if (project) {
                  return project.name
                }
              },
            },
            children: [
              {
                path: ':approvalId',
                name: 'projectApproval',
                components: {
                  body: ApprovalsSingleView,
                },
                meta: {
                  needsAuth: true,
                  title: (store) => {
                    const project = store.getters['projects/currentProject']
                    return project ? project.name : ''
                  },
                },
              },
            ],
          },
          {
            path: ':projectId/areas',
            name: 'projectAreas',
            components: {
              body: ProjectSingleView,
            },
            props: {
              body: {
                tab: 'areas',
              },
            },
            meta: {
              needsAuth: true,
              title: (store) => {
                let project = store.getters['projects/currentProject']

                if (project) {
                  return project.name
                }
              },
            },
          },
          {
            path: ':projectId/area-meta',
            name: 'projectAreaMeta',
            components: {
              body: ProjectSingleView,
            },
            props: {
              body: {
                tab: 'area-meta',
              },
            },
            meta: {
              needsAuth: true,
              title: (store) => {
                let project = store.getters['projects/currentProject']

                if (project) {
                  return project.name
                }
              },
            },
          },
          {
            path: ':projectId/:tab',
            name: 'projectTab',
            components: {
              body: ProjectSingleView,
            },
            props: {
              body: true,
            },
            meta: {
              needsAuth: true,
              title: (store) => {
                let project = store.getters['projects/currentProject']

                if (project) {
                  return project.name
                }
              },
            },
          },
        ],
      },

      {
        path: 'profile',
        components: {
          body: ProfileView,
        },
        meta: {
          title: 'Profile',
          needsAuth: true,
        },
      },
      {
        path: 'notifications',
        components: {
          body: NotificationsView,
        },
        meta: {
          needsAuth: true,
          title: 'Notifications',
        },
      },
      {
        path: 'cache-control',
        components: {
          body: CacheControlView,
        },
        meta: {
          needsAuth: true,
          title: 'Cache Control',
        },
      },
      // These routes do not need authentication
      {
        path: 'login',
        components: {
          body: LoginView,
        },
        beforeEnter(to, from, next) {
          if (store.getters['auth/accessToken']) {
            next('/')
          } else {
            next()
          }
        },
      },
      {
        path: '*',
        components: {
          body: NotFoundView,
        },
      },
    ],
  },
]

const Router = new VueRouter({
  rootURL: '/',
  // mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes,
})

Router.beforeEach((to, from, next) => {
  if (!hasAuthentication(to, next)) {
    next('/login')
    return
  }

  // console.log(from.path, to.path)
  // console.error(to)

  let go = checkForUnsavedChanges()

  next(go)
})

function checkForUnsavedChanges() {
  let unsavedChanges = store.getters['global/unsavedChanges']

  if (unsavedChanges) {
    let confirm = window.confirm(
      'You have unsaved changes, are you sure you want to navigate away from the page?'
    )

    if (!confirm) {
      return false
    }

    store.commit('global/unsavedChanges', false)
  }

  return true
}

window.addEventListener('beforeunload', (e) => {
  const hasUnsavedChanges = store.getters['global/unsavedChanges']
  const confirmationMessage =
    'You have unsaved changes, are you sure you want to navigate away from the page?'

  if (hasUnsavedChanges) {
    // We do not want to get this message when the store gets loaded from localStorage
    store.commit('global/unsavedChanges', false)

    // A short while later we reset the value. If the user navigated away, this safely gets skipped
    // especially because execution is frozen completely when a confirmation popup is on
    window.setTimeout(() => {
      store.commit('global/unsavedChanges', true)
    }, 200)

    e.returnValue = confirmationMessage
    return confirmationMessage
  }

  // Else we do nothing :-)
})

// Prevent guest from reaching authenticated routes.
// @return bool has authentication or does not require authentication
function hasAuthentication(to) {
  const requiresAuthentication = to.meta && to.meta.needsAuth
  const loggedIn = !!store.state.auth.accessToken

  return !requiresAuthentication || (requiresAuthentication && loggedIn)
}

export default Router
