<template>
  <el-form
    :ref="form.ref"
    label-position="right"
    :label-width="form?.labelWidth ?? '150px'"
    :rules="allRules"
    :model="model"
  >
    <el-form-item
      v-for="item in filteredFormItems"
      :key="item.label"
      :ref="item.prop"
      :label="item.label"
      :prop="item.prop"
      :disabled="item.disabled === true"
      :error="getFieldError(item)"
    >
      <el-select
        v-if="item.type === 'select'"
        v-model="model[item.prop]"
        :disabled="item.disabled === true"
        :placeholder="item.placeholder || 'Please choose an item'"
      >
        <el-option
          v-for="option in item.options"
          :key="option.value"
          :value="option.value"
          :label="option.label"
        />
      </el-select>
      <el-checkbox
        v-if="item.type === 'checkbox'"
        v-model="model[item.prop]"
        :disabled="item.disabled === true"
      >
      </el-checkbox>
      <el-input
        v-else
        :ref="item.autofocus ? 'autoFocusInput' : null"
        v-model="model[item.prop]"
        :type="item.type || 'text'"
        :disabled="item.disabled === true"
      />
    </el-form-item>

    <el-row type="flex" justify="center" align="middle" class="bottom-row">
      <el-button
        v-for="button in filteredFormButtons"
        :key="button.event"
        :loading="loading"
        :type="button.type"
        @click="handleClick(button)"
      >
        {{ button.label }}
      </el-button>
    </el-row>
  </el-form>
</template>

<script>
export default {
  name: 'DefaultForm',

  props: {
    loading: {
      default: false,
      type: Boolean,
    },
    form: {
      type: Object,
      required: true,
      validator: (value) => {
        return (
          true &&
          value.items &&
          value.items.length > 0 &&
          value.buttons &&
          value.buttons.length > 0 &&
          value.ref
        )
      },
    },
    errors: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  data() {
    return {
      model: {},
    }
  },

  computed: {
    filteredFormItems() {
      return this.form.items.filter((item) => item.render !== false)
    },
    filteredFormButtons() {
      return this.form.buttons.filter((button) => button.render !== false)
    },
    allRules() {
      if (!this.form || !this.form.items) {
        // Try again later
        return
      }

      const rules = this.form.items.reduce((acc, current) => {
        const rules = []

        if (current.required) {
          rules.push({
            required: true,
            message: `${current.label} is required!`,
            trigger: 'blur',
          })
        }
        if (current.rules) {
          Array.prototype.push.apply(rules, current.rules)
        }
        acc[current.prop] = rules
        return acc
      }, {})

      return rules
    },
  },

  watch: {
    errors(error) {
      if (error !== null) {
        let errorMessage

        if (error && error.message) {
          errorMessage = error.message
        } else {
          errorMessage = 'A validation error occured'
        }

        this.$message.error({
          showClose: true,
          message: errorMessage,
          duration: 10000,
        })
      }
    },
  },

  mounted() {
    if (this.form.initialModel) {
      this.model = this.form.initialModel
    }

    this.$nextTick(() => {
      if (this.$refs.autoFocusInput) {
        this.$refs.autoFocusInput[0].$el.children[0].focus()
      }
    })
  },

  methods: {
    resetFields() {
      return this.$refs[this.form.ref].resetFields()
    },
    validate() {
      return this.$refs[this.form.ref].validate()
    },
    getFieldError(formItem) {
      if (this.errors && this.errors[formItem.prop]) {
        if (Array.isArray(this.errors[formItem.prop])) {
          return this.errors[formItem.prop].join('\r\n')
        }
        return this.errors[formItem.prop]
      }
      return null
    },
    handleClick(button) {
      if (button.event === 'submit') {
        this.$refs[this.form.ref].validate((valid) => {
          if (valid) {
            this.$emit(button.event, this.model)
          }
        })
      } else {
        this.$emit(button.event, this.model)
      }
    },
  },
}
</script>

<style lang="scss"></style>
