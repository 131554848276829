import ApiResource from '../ApiResource'

class LerfOverviewResource extends ApiResource {
  constructor() {
    super({
      name: 'lerf-overview',
    })
  }
}

const lerfOverviewApi = new LerfOverviewResource()

export default lerfOverviewApi
