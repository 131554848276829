<template>
  <div class="evergabe">
    <el-row v-if="socketConnected" :gutter="20">
      <el-col :span="8">
        <el-form class="form-box">
          <el-button
            :disabled="processing"
            class="start"
            type="success"
            @click="startProcess"
          >
            Start Process
          </el-button>
          <p v-if="processing">Processing...</p>
          <ol v-if="log.length > 0">
            <li
              v-for="(item, index) in log"
              :key="index"
              :class="item | logItemClass"
              @click="triggerLogAction(item)"
            >
              <span>
                <fa-icon v-if="item.screenshot" name="image"/>
                <fa-icon
                  v-if="item.update && item.update.evergabe_filename"
                  name="link"
                />
              </span>
              <span>{{item.message}}</span>
            </li>
          </ol>
        </el-form>
      </el-col>
      <el-col v-if="screenshot" :span="16">
        <el-form class="form-box">
          <img
            v-if="screenshot"
            :src="screenshot | screenshotHref"
            class="screenshot"
          />
        </el-form>
      </el-col>
    </el-row>
    <el-row v-if="!socketConnected || socketError" class="error">
      <p v-if="!socketConnected">Not connected to the automation service.</p>
      <p v-if="socketError">
        {{ socketError }}
      </p>
    </el-row>
  </div>
</template>

<script>
import lerfsApi from 'api/v2/lerfs'
import lerfItemsApi from 'api/v2/lerfItems'
import {formatErrorMessage} from 'common/util'

import io from 'socket.io-client'
import config from 'config/app'

export default {
  name: 'LerfEvergabe',
  filters: {
    logItemClass(item) {
      let classes = []
      if (item.screenshot || item.link) {
        classes.push('clickable')
      }
      switch (item.status) {
      case 1:
        classes.push('success')
        break
      case -1:
        classes.push('error')
        break
      }
      return classes.join(' ')
    },
    screenshotHref(value) {
      return config.evergabe && config.evergabe.url + '/screenshots/' + value
    },
  },
  props: {
    lerf: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // Status
      processing: false,
      screenshot: null,
      log: [],
      items: [],
      // Socket stuff
      socket: null,
      socketConnected: false,
      socketAuthenticated: false,
      socketError: false,
      socketEventsBinded: false,
    }
  },
  mounted() {
    this.socket = io(config.evergabe && config.evergabe.url)
    this.socket.on(
      'connect',
      function () {
        this.socketConnected = true
        // Perform the authentication
        this.socket.emit('authentication', {
          username: config.evergabe.username,
          password: config.evergabe.password,
        })
        // Bind the authenticated event
        this.socket.on(
          'authenticated',
          function () {
            this.socketAuthenticated = true
            this.bindSocketEvents()
          }.bind(this)
        )
        // Bind the unauthorized event
        this.socket.on(
          'unauthorized',
          function (res) {
            this.socketError = res.message
          }.bind(this)
        )
      }.bind(this)
    )
    // Bind the disconnected event
    this.socket.on(
      'disconnect',
      function () {
        this.socketConnected = false
        this.socketAuthenticated = false
      }.bind(this)
    )
  },
  destroyed() {
    this.socket.close()
  },
  methods: {
    bindSocketEvents() {
      // Only bind the event listeners if they haven't been binded before
      if (!this.socketEventsBinded) {
        this.socketEventsBinded = true
        // New item in the log
        this.socket.on(
          'new log',
          function (logItem) {
            this.log.push(logItem)
            // If the update is set, we expect a key and value
            if (logItem.update) {
              let toUpdate = {}
              toUpdate[logItem.update.key] = logItem.update.value
              lerfsApi.update(
                () => {
                },
                (error) => {
                  this.$message({
                    showClose: true,
                    type: 'error',
                    message: formatErrorMessage(error),
                    duration: 0,
                  })
                },
                this.lerf.id,
                toUpdate
              )
            }
          }.bind(this)
        )
        // Having the processing of the item unlocked
        this.socket.on(
          'processing finished',
          function () {
            this.processing = false
            this.$emit('reload')
          }.bind(this)
        )
      }
    },
    startProcess() {
      this.processing = true
      // Fetch items
      lerfItemsApi.index(
        (response) => {
          // Start lerf processing
          this.socket.emit('process lerfs', [
            Object.assign(this.lerf, {
              items: response,
            }),
          ])
        },
        (error) => {
          this.log.push(error)
        },
        this.lerf.id
      )
    },
    triggerLogAction(item) {
      if (item.screenshot) {
        this.screenshot = item.screenshot
      } else if (item.update && item.update.evergabe_filename) {
        window.open('/' + item.update.evergabe_filename, '_blank')
      } else {
        this.screenshot = null
      }
    },
    showScreenshot() {
    },
  },
}
</script>

<style lang="scss">
.evergabe {
  .start {
    width: 100%;
  }

  .screenshot {
    width: 100%;
  }

  .error {
    color: red;
  }

  .success {
    color: green;
  }

  .clickable {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
