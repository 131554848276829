<template>
  <li class="pricing-item" :class="{ editing: isEditing }" @click="toggleEdit">
    <div @click.prevent.stop="(e) => deletePricingItem(pricingItem, false)">
      <i class="el-icon-delete"/>
    </div>
    <template v-if="!isEditing">
      <span>{{ pricingItem.reference }}</span>
      <span v-if="subcontractor">
        {{pricingItem.internalPricingItemReference }}
      </span>
      <span>{{ pricingItem.description }}</span>

      <span class="field-unit">{{ pricingItem.unit }}</span>
      <span class="field-price">{{ pricingItem.price }}</span>
      <span>{{ pricingItem.localFixed ? 'fixed' : '' }}</span>
      <span>{{ pricingItem.hidden ? 'hidden' : '' }}</span>
      <span
        v-if="subcontractor && pricingTemplate.projectId"
      >{{ pricingItem.notPurchaseInvoicable ? 'yes' : '' }}</span>

      <span>{{ pricingItem.planIndex }}</span>
      <span v-if="!subcontractor && !pricingTemplate.projectId">{{ pricingItem.planIndexSubcategory }}</span>
      <span>{{ pricingItem.bonusIndex }}</span>
      <span>{{ pricingItem.prognoseMtbArtIndex }}</span>
    </template>

    <template v-if="isEditing">
      <el-input v-model="editPricingItem.reference"/>

      <el-input
        v-if="subcontractor && !pricingTemplate.projectId && canAddReference"
        v-model="editPricingItem.internalPricingItemReference"
      />
      <el-input
        v-else-if="subcontractor"
        :placeholder="editPricingItem.internalPricingItemReference"
        :disabled="(pricingTemplate.projectId || !canAddReference) ? true : false"
      />

      <el-input v-model="editPricingItem.description"/>

      <el-select v-model="editPricingItem.unit" placeholder="Unit" filterable>
        <el-option label="M" value="M"/>
        <el-option label="M2" value="M2"/>
        <el-option label="M3" value="M3"/>
        <el-option label="ST" value="ST"/>
        <el-option label="STD" value="STD"/>
      </el-select>

      <el-input v-model="editPricingItem.price" type="number"/>
      <el-checkbox
        v-model="editPricingItem.localFixed"
        :checked="editPricingItem.localFixed"
      >
        fixed
      </el-checkbox>
      <el-checkbox
        v-model="editPricingItem.hidden"
        :checked="editPricingItem.hidden"
      >
        hidden
      </el-checkbox>
      <el-checkbox
        v-if="subcontractor && pricingTemplate.projectId"
        v-model="editPricingItem.notPurchaseInvoicable"
        :checked="editPricingItem.notPurchaseInvoicable"
      >
        not inv.
      </el-checkbox>

      <el-input v-model="editPricingItem.planIndex" type="text"/>
      <el-input v-if="!subcontractor && !pricingTemplate.projectId" v-model="editPricingItem.planIndexSubcategory" type="text"/>
      <el-input v-model="editPricingItem.bonusIndex" type="text"/>
      <el-input v-model="editPricingItem.prognoseMtbArtIndex" type="text"/>
    </template>

    <span class="actions">
      <el-button
        v-if="isEditing"
        size="mini"
        type="success"
        icon="el-icon-check"
        class="action-save"
        title="Save"
        @click.stop="saveItem"
      />
      <el-button
        v-if="isEditing"
        size="mini"
        type="secondary"
        class="action-cancel"
        icon="el-icon-close"
        title="Cancel"
        @click.stop="cancelEdit"
      />

      <i class="el-icon el-icon-edit action-edit" title="Click to edit"></i>

      <i
        v-if="pricingItem.mappingStatus"
        class="el-icon el-icon-info action-edit item-has-issue"
        :title="pricingItem.mappingStatus"
        @click.stop.prevent="showMappingStatus()"
      ></i>
    </span>
  </li>
</template>

<script>
import pricingTemplateItemsApi from 'api/v2/pricingTemplateItems'
import {cloneDeep} from 'lodash-es'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'
import {can, formatErrorMessage} from 'common/util'

export default {
  name: 'PricingItem',
  components: {},
  props: {
    areaSelection: {
      type: String,
      required: true,
    },
    pricingItem: {
      type: Object,
      required: true,
    },
    pricingTemplate: {
      type: Object,
      required: true,
    },
    pricingCategory: {
      type: Object,
      required: true,
    },
    subcontractor: {
      type: Object,
      required: false,
      default: undefined,
    },
  },

  data() {
    return {
      editPricingItem: Object.assign({}, this.pricingItem),
      isEditing: false,
    }
  },
  computed: {
    internalPricingItems() {
      return this.pricingTemplate.internalPricingTemplate.pricing_items.filter(
        (item) => item.pricing_category.area_selection === this.areaSelection
      )
    },
    canAddReference() {
      return can('set_internal_ref', 'pricing_template');
    },
  },
  watch: {
    pricingItem(value) {
      this.editPricingItem = Object.assign({}, value)
    },

    internal_pricing_item_reference(newValue, oldValue) {
      if (newValue) {
        const pricingItem =
          this.pricingTemplate.internalPricingTemplate.pricing_items.find(
            (item) => item.reference === newValue
          )
        const oldPricingItem = oldValue
          ? this.pricingTemplate.internalPricingTemplate.pricing_items.find(
            (item) => item.reference === oldValue
          )
          : null

        // If these don't match, you cannot save the thing
        this.editPricingItem.unit = pricingItem.unit

        // This allows the user to double check if it is the correct id
        if (
          !this.editPricingItem.description ||
          (oldPricingItem &&
            oldPricingItem.description === this.editPricingItem.description)
        ) {
          this.editPricingItem.description = pricingItem.description
        }
      }
    },
  },
  methods: {
    showMappingStatus() {
      this.$emit('open-mapping-status', {
        pricingItem: this.pricingItem,
        pricingTemplate: this.pricingTemplate,
        subcontractor: this.subcontractor,
        pricingCategory: this.pricingCategory,
      })
    },
    toggleEdit() {
      if (!this.isEditing) {
        this.originalPricingItem = cloneDeep(this.editPricingItem)
        this.isEditing = !this.isEditing
      }
    },
    cancelEdit() {
      this.editPricingItem = Object.assign({}, this.pricingItem)
      this.$nextTick(() => {
        this.$set(this, 'isEditing', false)
      })
    },
    async saveItem() {
      if (this.pricingItem === this.editPricingItem) return
      pricingTemplateItemsApi.update(
        (response) => {
          this.$emit('item-updated', camelCaseKeys(response, {deep: true}))
          this.isEditing = false
        },
        (error) => {
          this.$message({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 5000,
          })
        },
        this.pricingTemplate.id,
        this.editPricingItem.id,
        Object.assign({}, snakeCaseKeys(this.editPricingItem))
      )
    },
    deletePricingItem(item, force) {
      this.$emit('deletePricingItem', item, force)
    },
    clickPricingItem(id) {
      this.$emit('clickPricingItem', id)
    },
  },
}
</script>

<style lang="scss" scoped>
.actions {
  .el-button {
    margin: 0;
  }

  display: inline-block;
  text-align: right;
  padding-right: 5px;

  > i {
    visibility: hidden;

    &.item-has-issue {
      visibility: visible;
    }
  }
}

.action-save {
  padding: 4px 20px;
  margin-right: 0px;
}

.action-cancel {
  padding: 4px 10px;
  margin-right: 20px;
}

.el-icon-delete {
  color: red;
  padding-left: 15px;
}

li.pricing-item span {
  word-break: break-word;
}
li.pricing-item:not(.editing):hover {
  cursor: pointer;

  .action-edit {
    visibility: visible;
  }
}

li.editing {
  ::v-deep .el-input {
    padding-right: 0px;
    .el-input__inner {
      height:24px;
    }
  }

  ::v-deep .el-select {
    padding-right: 0px;

    .el-input {
      padding-right: 0;

      i {
        margin-right: 10px;
      }
    }
  }

  ::v-deep input {
    margin-left: 0;
    background: white;
    width: calc(100% - 10px);
    padding-left:5px!important;
  }

  .actions .action-edit {
    display: none;
  }
}

::v-deep .el-input__inner {
  background: inherit;
  height: inherit;
}
</style>
