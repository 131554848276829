<template>
  <el-row class="category-node" justify="space-between">
    <el-col :span="14">{{ category.label }}</el-col>
    <el-col :span="10" class="buttons">
      <el-button
        type="success"
        size="mini"
        @click.capture.stop="addSubcategory(category.label)"
      >
        Add subcategory to {{ category.label }}
      </el-button>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'CategoryNode',

  props: {
    category: {
      type: Object,
      required: true,
    },
  },

  methods: {
    addSubcategory(categoryName) {
      this.$emit('add', categoryName)
    },
  },
}
</script>

<style lang="scss"></style>
