<template>
  <view-add-edit-delete-view
    :headers="headers"
    :api="pricingTemplatesApi"
    :data="pricingTemplates"
    :vocabulary="{ single: 'template', plural: 'templates' }"
    :buttons="buttons"
    :max-table-width="900"
    identifying-prop="name"
    category="user"
    show-table-search
    server-side-confirm-delete
    :include="['project', 'categories']"
    :index-params="{ ofBaseType: true, ofNoSubcontractor: true }"
    primary-route="/pricing/templates/edit/:id"
    @data="processData"
  >
    <template slot="addItemDialog" slot-scope="{ item, onclose }">
      <DialogTemplate
        type="add"
        :visible="true"
        :template="item"
        :onclose="onclose"
      />
    </template>
    <template slot="editItemDialog" slot-scope="{ item, onclose }">
      <DialogTemplate
        type="edit"
        :visible="true"
        :template="item"
        :onclose="onclose"
      />
    </template>

    <template slot="cloneItemDialog" slot-scope="{ item, onclose }">
      <DialogTemplate
        type="duplicate"
        :visible="true"
        :template="item"
        :onclose="onclose"
      />
    </template>
  </view-add-edit-delete-view>
</template>

<script>
import ViewAddEditDeleteView from 'components/Views/Common/ViewAddEditDeleteView'
import DialogTemplate from './DialogTemplate'

import pricingTemplatesApi from 'api/v2/pricingTemplates'

export default {
  name: 'PricingTemplatesView',

  components: {
    ViewAddEditDeleteView,
    DialogTemplate,
  },

  data() {
    return {
      pricingTemplates: [],

      headers: [
        {
          prop: 'name',
          label: 'Name',
        },
      ],

      buttons: [
        {
          label: 'edit',
          event: 'change',
          faIcon: 'el-icon el-icon-edit',
          title: 'Edit user',
          prop: 'id',
          capability: 'update',
        },
        {
          label: 'duplicate',
          faIcon: 'el-icon el-icon-copy-document',
          title: 'Duplicate template',
          prop: 'id',
          event: 'duplicate',
          capability: 'update',
        },
        {
          label: 'delete',
          event: 'delete',
          faIcon: 'el-icon el-icon-delete',
          title: 'Delete user',
          type: 'danger',
          prop: 'id',
          capability: 'delete',
        },
      ],
    }
  },

  computed: {
    pricingTemplatesApi() {
      return pricingTemplatesApi
    },
  },

  methods: {
    processData(data) {
      this.pricingTemplates = data
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-table td {
  height: auto;
  padding-top: 2px;
  padding-bottom: 2px;
}
</style>
