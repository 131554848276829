import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import { pick } from 'lodash-es'

import app from 'config/app'
import store from '../store'

import {
  AuthenticationError,
  AuthorizationError,
  NotFoundError,
} from './errors'

import { processOrder, processFilters } from 'common/util'

function isObject(obj) {
  const type = typeof obj

  return obj !== null && type === 'object'
}

function normalizeKeys(data) {
  if (Array.isArray(data)) {
    return data.map((data) => {
      if (!isObject(data)) {
        return data
      }
      return camelCaseKeys(data)
    })
  } else if (!isObject(data)) {
    return data
  }

  return camelCaseKeys(data)
}

export function requestData(options = {}, isNormalizeKeys = true) {
  const baseUrl = app.api.baseUrl
  const token = store.state.auth.accessToken

  let url = `${baseUrl}${options.resource}`

  const requestConfig = {
    url: url,
    method: options.method,
    validateStatus: function (status) {
      return [200, 201, 204].indexOf(status) !== -1
    },
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }

  if (options.body) {
    requestConfig.data = options.body
    delete options.body
  }
  // Manual merge
  if (options.headers) {
    for (let key of Object.keys(options.headers)) {
      requestConfig.headers[key] = options.headers[key]
    }
    delete options.headers
  }
  Object.assign(requestConfig, options)

  return axios(requestConfig).then(
    (result) => {
      if (!result || !result.data) {
        return
      }

      if (
        result.headers['content-disposition'] &&
        result.headers['content-disposition'].indexOf('attachment') !== -1
      ) {
        return result.data
      }

      return {
        data: isNormalizeKeys ? normalizeKeys(result.data, true) : result.data,
      }
    },
    (error) => {
      if (!error || !error.response) {
        // The requests are not made... at all?
        // console.error(
        //   'API request did not yield any response. Either configuration error or blocked by the browser.'
        // )
        throw error
      }

      let errorMessage
      let errorObj

      if (error.response.data && error.response.data.errors) {
        errorObj = error.response.data
      } else if (error.response.data && error.response.data.message) {
        errorObj = error.response.data
        errorMessage = errorObj.message
      }

      if (error.response.status === 403) {
        // Everyone is able to see the dashboard as far as I know
        throw new AuthorizationError(errorMessage)
      } else if (error.response.status === 401) {
        // We are going to clear the token
        store.dispatch('auth/logout')

        throw new AuthenticationError(errorMessage)
      } else if (error.response.status === 404) {
        if (errorMessage) {
          throw new NotFoundError(errorMessage)
        }
        // uncomment next two lines when using axios mocking
        // console.error('Error during request %o', requestConfig)
        // throw error
      }

      if (errorObj) {
        throw errorObj
      } else {
        throw error
      }
    }
  )
}
export function post(resource, body = {}, options = {}) {
  return requestData(Object.assign(options, { resource, method: 'POST', body }))
}

export function get(
  resource,
  options = {},
  additionalOptions = {},
  normalizeKeys = true
) {
  if (!options.params) {
    options.params = {}
  }

  if (!additionalOptions) {
    additionalOptions = {}
  }

  if (additionalOptions.search) {
    options.params.q = additionalOptions.search
  }

  if (additionalOptions.pageSize) {
    options.params.page_size = additionalOptions.pageSize
  }

  if (additionalOptions.all) {
    options.params.all = additionalOptions.all
  }

  if (additionalOptions.includes) {
    for (let i = 0; i < additionalOptions.includes.length; i++) {
      options.params[`include[${i}]`] = additionalOptions.includes[i]
    }
  }

  options.params = processOrder(
    options.params,
    additionalOptions.orderBy,
    additionalOptions.orderDirection
  )
  options.params = processFilters(options.params, additionalOptions.filters)

  if (!options.params) options.params = {}

  // @todo, this is really strange, should remove this!!
  Object.assign(
    options.params,
    pick(additionalOptions, [
      'name',
      'status',
      'internal_contact_name',
      'customer',
      'project_manager_contact',
      'project_lead_contact',
    ])
  )

  return requestData(
    Object.assign({}, options, { resource, method: 'GET' }),
    normalizeKeys
  )
}

export function put(resource, body = {}, options = {}) {
  return requestData(
    Object.assign({}, options, { resource, method: 'PUT', body })
  )
}

export function patch(resource, body = {}, options = {}) {
  return requestData(
    Object.assign({}, options, { resource, method: 'PATCH', body })
  )
}

export function remove(resource, options = {}) {
  return requestData(Object.assign({}, options, { resource, method: 'DELETE' }))
}
