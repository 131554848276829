<template>
  <div>
    <el-row :gutter="24">
      <el-col :span="18">
        <el-header content="detail">
          <h4>Cached Tables
            <el-button
              size="small"
              type="primary"
              @click.prevent="loadIndex()"
            >
              Refresh List
            </el-button>
          </h4>
        </el-header>
        <template v-if="indexData?.cachedTables?.length">
          <el-table
            v-loading="loading"
            :data="indexData?.cachedTables"
            style="width: 100%"
            :highlight-current-row="true"
            :default-sort = "{prop: 'name', order: 'asc'}"
            size="mini"
            stripe
          >
            <el-table-column prop="name" label="Name" sortable />
            <el-table-column prop="itemsCount" label="Number of cached views" sortable />
            <el-table-column prop="filtersCachedCount" label="Number of columns with cached filters" sortable />
            <el-table-column width="100">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.config_enabled"
                  :disabled="!scope.row.config_allowed"
                  @change="updateCache(scope.row)"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column width="50">
              <template slot-scope="scope">
                <el-button
                  size="small"
                  type="default"
                  icon="el-icon el-icon-delete"
                  title="Clear cache and refresh"
                  @click.prevent="clearCache(scope.row)"
                />
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template v-else>
          There are currently no cache entries.
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import cacheControlApi from '@/js/api/v2/cacheControl'

export default {
  name: 'CacheControlView',

  components: {

  },

  data() {
    return {
      loading: true,
      indexData: null,
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {
    this.loadIndex()
  },
  methods: {
    loadIndex() {
      this.loading = true
      cacheControlApi.index(
        (response) => {
          this.indexData = response
          this.loading = false
        },
        () => {
          this.loading = false
        }
      )
    },
    clearCache(row) {
      this.loading = true
      cacheControlApi.delete(
        () => {
          this.loadIndex()
          this.loading = false
          this.$message({
            type: 'success',
            message: 'Cache entry cleared.',
            duration: 4000,
          })
        },
        (error) => {
          this.loading = false
          this.$message.error({
            showClose: true,
            message: error.message,
            duration: 10000,
          })
        },
        {
          cacheKey:row.cacheKey
        }
      )
    },
    updateCache(row) {
      this.loading = true
      cacheControlApi.update(
        () => {
          this.loadIndex()
          this.loading = false
          this.$message({
            type: 'success',
            message: 'Cache configuration updated.',
            duration: 4000,
          })
        },
        (error) => {
          this.loading = false
          this.$message.error({
            showClose: true,
            message: error.message,
            duration: 10000,
          })
        },
        {
          cacheKey:row.cacheKey,
          cacheKeyConfig:{
            enabled: row.config_enabled
          },
        }
      )
    }
  },
}
</script>

<style lang="scss" scoped>
  .el-header {
    padding: 0;
    margin: 0;
    height: initial!important;
    h4 {
      margin-top: 0;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .el-row + .el-row {
    margin-top: 3em;
  }
</style>
