<template>
  <div class="note-editor">
    <el-input
      v-model="note"
      type="textarea"
      :rows="6"
      placeholder="Type your note here. Select text to apply styling."
    >
    </el-input>
    <el-button class="save-button" @click="saveNote"> Save</el-button>
  </div>
</template>

<script>
import projectNotesApi from 'api/v2/projectNotes'
import { formatErrorMessage } from 'common/util'

export default {
  name: 'NoteEditor',

  components: {},

  props: {
    project: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      note: '',
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    }
  },

  methods: {
    saveNote() {
      projectNotesApi.store(
        (data) => {
          this.$emit('save', data.data)
          this.note = ''
          this.$message({
            message: 'Saved note',
            type: 'success',
          })
        },
        (error) => {
          this.$message({
            message: formatErrorMessage(error),
            type: 'error',
          })
        },
        this.project.id,
        { note: this.note }
      )
    },
  },
}
</script>

<style lang="scss">
.note-editor {
  max-width: 60em;

  .editor {
    width: 100%;
    min-height: 10em;
    background: $white;
    border: 1px solid $black;
    padding: 10px;

    > * {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .save-button {
    float: right;
    margin-top: 5px;
  }

  &:after {
    clear: both;
    content: '';
    display: block;
  }
}
</style>
