import lerfOverviewApi from 'api/v2/lerfOverview'
import {logErrorMessage} from '../../../common/util';

export default {
  fetchLerfOverview({ commit }) {
    lerfOverviewApi.index(
      (response) => {
        commit('setLerfOverview', response.data)
      },
      (error) => {
        logErrorMessage(error)
      }
    )
  },
}
