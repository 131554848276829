import ApiResource from '../ApiResource'

class ProjectClaimResource extends ApiResource {
  constructor() {
    super({
      name: 'projects.claims',
    })
  }
}

const projectClaimsApi = new ProjectClaimResource()

export default projectClaimsApi
