import { cloneDeep } from 'lodash-es'

/**
 * Generates getters that map a function name to a similarly named property of the state
 * @param {string[]} keys
 */
export function generateGetters(keys) {
  return keys.reduce((acc, key) => {
    acc[key] = (state) => cloneDeep(state[key])

    return acc
  }, {})
}

/**
 * Generates setters that map
 * @param {string[]} keys
 */
export function generateSetters(keys) {
  return keys.reduce((acc, key) => {
    acc[key] = (state, payload) => (state[key] = cloneDeep(payload))

    return acc
  }, {})
}
