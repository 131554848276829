var { merge } = require('webpack-merge')

let localConfig = {}

try {
  localConfig = require('./app_local')
} catch (e) {
  if (!(e instanceof Error) || e.code !== 'MODULE_NOT_FOUND') {
    throw e
  }
}

const config = {
  title: 'SoliDB',
  locale: 'en-GB',
  currencySymbol: '€', // which currency symbol should we display when showing money
  currency: 'EUR',
  version: 'v0.0.1',
  precisionDigit: 2, // the precision digit for all values
  appId: 'VBi7tpxHpijqYwrotFq',
  commitSha: '18197e7c3c801e31429689b0015660018cd3cd23',
  commitTag: '',
  numberFormat: '0,0.0',
  api: {
    baseUrl: '%BASE_URL%',
    clientSecret: '%CLIENT_SECRET%',
    clientId: -1,
  },
  media: {
    baseUrl: '%BASE_URL%/storage'.replace(/\/\//g, '/'),
  },
  mapboxAccessToken:
    'pk.eyJ1IjoiY2VuYXM2OSIsImEiOiJjanI3eHUxYWQwMTc5M3psOG93dG00c2c1In0.EmEwIOM7NkLclWCFcBnk5w',
}

export default merge(config, localConfig)
