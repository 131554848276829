<template>
  <div
    v-loading="areasLoading"
    class="project-areas"
    element-loading-text="Loading areas..."
  >
    <el-row :gutter="20">
      <el-col :span="9" class="header"> &nbsp;</el-col>
      <el-col :span="15" class="header">
        <div class="title">
          <template v-if="selectedArea">
            {{ selectedArea.name }}
          </template>
          <template v-else>Please select an area...</template>
        </div>
      </el-col>
    </el-row>
    <template v-if="!areasErrored">
      <el-row :gutter="20">
        <el-col :span="9">
          <list-view
            :add-handler="addArea"
            :delete-handler="deleteArea"
            :project-id="project.id"
            :items="areas"
            :secondary-label-column-span="4"
            :update-handler="updateArea"
            filterable
            label-attribute="name"
            secondary-label-attribute="costObjectCode"
            selectable
            sortable
            entity="area"
            title="Areas"
            @select="selectArea"
          />
        </el-col>
        <el-col :span="5">
          <list-view
            v-if="selectedArea"
            :key="selectedArea.id"
            :project-id="project.id"
            :area="selectedArea"
            :add-handler="addStreet"
            :delete-handler="deleteStreet"
            :items="streets"
            :update-handler="updateStreet"
            filterable
            label-attribute="name"
            sortable
            entity="street"
            title="Streets"
          />
        </el-col>
        <el-col :span="5">
          <list-view
            v-if="selectedArea"
            :key="selectedArea.id"
            :project-id="project.id"
            :area="selectedArea"
            :add-handler="addMfg"
            :delete-handler="deleteMfg"
            :items="mfgs"
            :update-handler="updateMfg"
            filterable
            label-attribute="name"
            sortable
            entity="MFG"
            title="MFGs"
          />
        </el-col>
        <el-col :span="5">
          <list-view
            v-if="selectedArea"
            :key="selectedArea.id"
            :project-id="project.id"
            :area="selectedArea"
            :add-handler="addMuffe"
            :delete-handler="deleteMuffe"
            :items="muffen"
            :update-handler="updateMuffe"
            filterable
            label-attribute="name"
            sortable
            entity="muffe"
            title="Muffen"
          />
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
import ListView from './ListView'
import snakeCaseKeys from 'snakecase-keys'
import { mapGetters } from 'vuex'

export default {
  name: 'ProjectAreas',

  components: {
    ListView,
  },

  props: {
    project: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      areaPreSelected: false,
    }
  },

  computed: {
    ...mapGetters('projectAreas', {
      areas: 'items',
      areasLoading: 'loading',
      areasErrored: 'errored',
      selectedArea: 'selectedArea',
      streets: 'streets',
      muffen: 'muffen',
      mfgs: 'mfgs',
    }),
  },

  async created() {
    await this.$store.dispatch('projectAreas/reset')
    await this.$store.dispatch('projectAreas/setProjectId', this.project.id)
    this.fetchAreas()
  },
  methods: {
    convertPayloadKeys(payload) {
      return snakeCaseKeys(payload, { deep: true })
    },

    selectArea(area) {
      this.$store.dispatch('projectAreas/selectArea', area)
    },

    async fetchAreas() {
      this.$store.dispatch('projectAreas/fetchAll', {
        projectId: this.project.id,
      })
    },

    async addArea({ payload }) {
      return this.$store.dispatch('projectAreas/addArea', { payload: payload })
    },

    async deleteArea({ id, payload }) {
      return this.$store.dispatch('projectAreas/deleteArea', { id, payload })
    },

    async updateArea({ id, payload }) {
      payload = {
        name: payload.name,
        costObjectCode: payload.costObjectCode,
      }
      return this.$store.dispatch('projectAreas/updateArea', { id, payload: payload })
    },

    async addLocation({ type, payload }) {
      return this.$store.dispatch('projectAreas/addLocation', { type, payload: payload })
    },

    async updateLocation({ id, type, payload }) {
      return this.$store.dispatch('projectAreas/updateLocation', { id, type, payload: payload })
    },

    async deleteLocation({ id, type, payload }) {
      return this.$store.dispatch('projectAreas/deleteLocation', { id, type, payload: payload })
    },

    addStreet({ payload }) {
      return this.addLocation({
        type: 'street',
        payload,
      })
    },

    updateStreet({ payload, id }) {
      return this.updateLocation({
        id,
        type: 'street',
        payload,
      })
    },

    deleteStreet({ id, payload }) {
      return this.deleteLocation({
        id,
        type: 'street',
        payload,
      })
    },

    addMfg({ payload }) {
      return this.addLocation({
        type: 'mfg',
        payload,
      })
    },

    updateMfg({ payload, id }) {
      return this.updateLocation({
        id,
        type: 'mfg',
        payload,
      })
    },

    deleteMfg({ id, payload }) {
      return this.deleteLocation({
        id,
        type: 'mfg',
        payload,
      })
    },

    addMuffe({ payload }) {
      return this.addLocation({
        type: 'muffe',
        payload,
      })
    },

    updateMuffe({ payload, id }) {
      return this.updateLocation({
        id,
        type: 'muffe',
        payload,
      })
    },

    deleteMuffe({ id, payload }) {
      return this.deleteLocation({
        id,
        type: 'muffe',
        payload,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  height: 38px;

  .title {
    padding: 10px;
    background: #324157;
    color: white;
    text-align: center;
  }
}
</style>
