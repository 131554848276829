import ApiResource from '../ApiResource'

class PricingMappingResource extends ApiResource {
  constructor() {
    super({
      name: 'pricing-mapping',
    })
  }
  revisions(resolve, reject, params) {
    this.get(`${this.getUrl()}/revisions`, { params: params })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const pricingMappingApi = new PricingMappingResource()

export default pricingMappingApi
