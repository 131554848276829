import { generateGetters } from '../../helpers.js'

export default {
  ...generateGetters([
    'items',
    'users',
    'usersLoading',
    'usersErrored',
    'customers',
    'customersLoading',
    'customersErrored',
    'areas',
    'areasProjectId',
    'areasLoading',
    'areasErrored',
    'currentProject',
    'currentLerf',
    'currentPurchaseInvoice',
    'currentPosition',
    'currentClaim',
  ]),

  getProjectById: (state) => (id) => {
    return state.items.find((item) => parseInt(item.id) === parseInt(id))
  },
}
