var render = function render(){var _vm=this,_c=_vm._self._c;return _c('view-add-edit-delete-view',{attrs:{"headers":_vm.headers,"api":_vm.approvalsApi,"include":[
    'project',
    'subcontractor',
    'subcontractorContact',
    'createdByContact',
    'approvedByContact',
  ],"data":_vm.approvals,"spinner":_vm.spinner,"identifying-prop":"name","vocabulary":{ single: 'approval', plural: 'approvals' },"buttons":_vm.buttons,"actions-column-width":100,"add-route":"/approvals/add","on-row-click":_vm.viewApproval,"category":"approval","show-table-search":"","show-table-colums-filter":"","show-table-colums-toggle":false},on:{"data":_vm.processData}})
}
var staticRenderFns = []

export { render, staticRenderFns }