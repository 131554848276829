import ApiResource from '../ApiResource'

class SubcontractorContactResource extends ApiResource {
  constructor() {
    super({
      name: 'subcontractors.contacts',
    })
  }

  linkProject(resolve, reject, subcontractorId, projectId) {
    this.ids = [subcontractorId, projectId]
    this.post(`${this.getUrl()}/attach`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }

  unlinkProject(resolve, reject, subcontractorId, projectId) {
    this.ids = [subcontractorId, projectId]
    this.post(`${this.getUrl()}/dettach`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const subcontractorContactsApi = new SubcontractorContactResource()

export default subcontractorContactsApi
