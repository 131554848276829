import Vue from 'vue'
import Vuex from 'vuex'

// The default config should be generated on build time
import auth from './modules/auth'
import dashboard from './modules/dashboard'
import configuration from './modules/configuration'
import global from './modules/global'
import projects from './modules/projects'
import projectAreas from './modules/projectAreas'

import createLogger from 'vuex/dist/logger'
import createPersistedState from 'vuex-persistedstate'

// Tell Vue we are using Vuex
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const persistance = {
  paths: ['auth'],
  filter() {
    return true
    // const route = mutation.type.split('/')[0]
    // return route === 'auth'
  },
}

const loggerConfig = {}

const appStore = {
  modules: {
    auth,
    dashboard,
    configuration,
    global,
    projects,
    projectAreas,
  },
  // Global/App level store functionality goes here
  state: {},
  strict: true,
  plugins: debug
    ? [createLogger(loggerConfig), createPersistedState(persistance)]
    : [createPersistedState(persistance)],
}

const store = new Vuex.Store(appStore)

if (debug) {
  window.$store = store
}

export default store
