import ApiResource from '../ApiResource'

class InitialDataResource extends ApiResource {
  constructor() {
    super({
      name: 'initial-data',
    })
  }
}

const initialDataApi = new InitialDataResource()

export default initialDataApi
