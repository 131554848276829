import ApiResource from '../ApiResource'

class SubcontractorResource extends ApiResource {
  constructor() {
    super({
      name: 'subcontractors',
    })

    this.getItem = this.show
  }

  getArchivedSubcontractors(resolve, reject, params) {
    this.ids = []
    this.get(`${this.getUrl()}?onlyArchived=1`, {
      params: {
        ...params,
      },
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  archive(resolve, reject, subcontractorId) {
    this.ids = [subcontractorId]
    this.post(`${this.getUrl()}/archive`)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  unarchive(resolve, reject, subcontractorId) {
    this.ids = [subcontractorId]
    this.post(`${this.getUrl()}/unarchive`)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const subcontractorsApi = new SubcontractorResource()

export default subcontractorsApi
