<template>
  <el-row class="subcategory-node" justify="space-between">
    <el-col :span="18">{{ category.label }}</el-col>
    <el-col :span="3">{{ category.costUnit }}</el-col>
    <el-col :span="3" class="buttons">
      <el-button
        type="secondary"
        size="mini"
        icon="el-icon el-icon-edit"
        @click="editItem(category)"
      ></el-button>
      <el-button
        type="danger"
        icon="el-icon el-icon-delete"
        class="danger"
        size="mini"
        @click="deleteItem(category)"
      />
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'SubcategoryNode',

  props: {
    category: {
      type: Object,
      required: true,
    },
  },

  methods: {
    editItem(subcategory) {
      this.$emit('edit', subcategory.id)
    },
    deleteItem(subcategory) {
      this.$emit('delete', subcategory.id)
    },
  },
}
</script>

<style lang="scss"></style>
