<template>
  <div class="picture-modal" @click="close">
    <span class="close">&times;</span>
    <img
      class="picture"
      :src="picture"
    />
  </div>
</template>

<script>
export default {
  name: 'ImageModal',

  props: {
    picture: {
      type: String,
      required: true
    }
  },

  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.picture-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}

.picture {
  display: block;
  height: auto;
  width: 80%;
  max-width: 700px;
  margin: 0 auto;
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
</style>
