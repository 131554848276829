/**
 * @todo refactor maybe, see other subcontractor class wrt linking projects
 */
import ApiResource from '../ApiResource'

class SubcontractorProjectContactResource extends ApiResource {
  constructor() {
    super({
      name: 'subcontractors.projects.contacts',
    })
    this.getItem = this.show
    this.getProjects = this.index
  }

  linkProjectContact(resolve, reject, subcontractorId, projectId, contactId) {
    this.ids = [subcontractorId, projectId, contactId]
    this.post(`${this.getUrl()}/attach`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }

  unlinkProjectContact(resolve, reject, subcontractorId, projectId, contactId) {
    this.ids = [subcontractorId, projectId, contactId]
    this.post(`${this.getUrl()}/detach`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const subcontractorProjectContactsApi =
  new SubcontractorProjectContactResource()

export default subcontractorProjectContactsApi
