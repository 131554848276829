<template>
  <AreaMetaReportTable />
</template>

<script>
import AreaMetaReportTable from './AreaMetaReportTable'

export default {
  name: 'AreaMeta',
  components: {
    AreaMetaReportTable,
  },
}
</script>
