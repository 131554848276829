<template>
  <view-add-edit-delete-view
    :headers="headers"
    :api="projectsArchiveApi"
    :data="projects"
    identifying-prop="name"
    :vocabulary="{ single: 'project', plural: 'projects' }"
    :buttons="buttons"
    :show-add-button="false"
    :actions-column-width="100"
    category="project"
    show-table-search
    @data="processData"
  />
</template>
<script>
import ViewAddEditDeleteView from 'components/Views/Common/ViewAddEditDeleteView'
import { formatErrorMessage } from 'common/util'
import projectsApi from 'api/v2/projects'

export default {
  name: 'ProjectsView',

  components: {
    ViewAddEditDeleteView,
  },

  data() {
    return {
      projects: [],

      headers: [
        {
          prop: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          prop: 'project_manager_contact.fullname',
          label: 'Project Supervisor',
          sortable: true,
        },
        {
          prop: 'project_lead_contact.fullname',
          label: 'Project Leader',
          sortable: true,
        },
        {
          prop: 'customer.name',
          label: 'Customer',
          sortable: true,
        },
      ],
    }
  },

  computed: {
    projectsArchiveApi() {
      return {
        index: projectsApi.getArchivedProjects.bind(projectsApi),
        delete: projectsApi.delete.bind(projectsApi),
      }
    },

    buttons() {
      return [
        {
          label: 'Restore',
          title: 'Restore project',
          faIcon: 'el-icon el-icon-refresh-left',
          event: 'restore',
          action: this.restoreProject,
          prop: 'id',
          capability: 'update',
        },
        {
          label: 'Delete',
          type: 'danger',
          title: 'Delete project',
          faIcon: 'el-icon el-icon-delete',
          event: 'delete',
          prop: 'id',
          capability: 'delete',
        },
      ]
    },
  },

  methods: {
    processData(data) {
      this.projects = data
    },

    restoreProject(id) {
      projectsApi.restoreProject(
        (response) => {
          this.$message.success({
            message: `Project ${response.name} has been restored`,
          })

          this.projects = this.projects.filter((row) => {
            return row.id !== id
          })
        },
        (error) => {
          this.$message.error({
            showClose: true,
            message: formatErrorMessage(error),
            duration: 5000,
          })
        },
        id
      )
    },
  },
}
</script>
