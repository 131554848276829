<template>
  <router-view name="body" />
</template>

<script>
export default {
  name: 'ProjectsDetails',
}
</script>

<style lang="scss"></style>
