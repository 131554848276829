import ApiResource from '../ApiResource'

class ProjectLerfEmailResource extends ApiResource {
  constructor() {
    super({
      name: 'projects.lerfs.email',
    })

    this.getSingleEmail = this.show
    this.deleteEmail = this.delete
  }
  versionIndex(resolve, reject, ...params) {
    this.get(`${this.getUrl()}/version`, params)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }
  versionStore(resolve, reject, html, images) {
    this.post(
      `${this.getUrl()}/version`,
      { html, images },
    )
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }
  versionShow = (resolve, reject, versionId, ...params) => {
    this.get(`${this.getUrl()}/version/${versionId}`, params)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }

  additionalFilesIndex(resolve, reject, ...params) {
    this.get(`${this.getUrl()}/additional-files`, params)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }

  /**
   * Upload an "Additional Files" file for use in the Email Send
   */
  additionalFilesUpload(resolve, reject, projectId, lerfId, files) {
    const formData = new FormData()

    for (const index in files) {
      formData.append(`files[${index}]`, files[index])
    }
    this.post(`${this.getUrl()}/additional-files`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  /**
   * Delete an "Additional Files" file
   */
  additionalFileDelete(resolve, reject, projectId, lerfId, token) {
    this.remove(`${this.getUrl()}/additional-files/${token}`)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const projectLerfEmailApi = new ProjectLerfEmailResource()

export default projectLerfEmailApi
