<template>
  <div>
    <DataTableAlternateVer2
      v-if="true"
      ref="projectsMetaTable"
      table-name="projectMetaTable"
      :data-table-options="dataTableOptions"
      :api="api"
      :field-category-defaults="['area_fields', 'general']"
      :request-data="{showArchived:showArchived}"
      @update-field="updateToServer"
    >
      <template #settingsRow>
        <el-switch
          v-model="showArchived"
          active-text="Include archived"
        />
      </template>
    </DataTableAlternateVer2>
  </div>
</template>

<script>
import DataTableAlternateVer2 from '@/js/components/Common/DataTableAlternateVer2.vue';
import ProjectAreaMetaApi from '@/js/api/v2/projectAreaMeta';
import moment from 'moment/moment';
import projectMetaFieldsApi from '@/js/api/v2/projectMetaFields';
import * as util from '@/js/common/util';

export default {
  name: 'ProjectsMeta',
  components: {
    DataTableAlternateVer2,
  },
  data() {
    return {
      showArchived: false,
      rows: [],
      dataTableOptions: {
        useHtmlTable: false,
        showRefresh: false,
        allowActions: false,
        allowSelection: false,
        showRevisions: true,
        tableHeightOffset: 250,
        pageSizes: [15, 30, 50, 100],
        columnWidth: {
          _rowActions: 50,
          cost_object_code: 80,
          project_name: 200,
          area_name: 200,
          region: 150,
          managing_area: 150,
        }
      },
    }
  },
  computed: {
    api() {
      return ProjectAreaMetaApi
    }
  },
  watch: {
    showArchived() {
      this.$refs.projectsMetaTable.refresh()
    }
  },
  mounted() {

  },
  created() {
  },
  methods: {
    updateToServer(data) {
      const row = data.row
      const type = data.type

      if (row[data.field] === undefined || row[data.field] === null) {
        row[data.field] = ''
      }
      if (row[data.field] === data.value) {
        return ''
      }
      data.project_id = row.project_id
      data.project_field_id = data.field.replace('meta_', '')

      let originalValue = row[data.field]
      if (
        data.value &&
        ['date'].includes(type)
      ) {
        data.value = moment(data.value, 'DD.MM.YYYY').format(
          'YYYY-MM-DD 00:00:00'
        )
      }
      row[data.field] = data.value
      this.editInputShow = false

      projectMetaFieldsApi.store(
        (response) => {
          if (!response.message) {
            response.message = 'Value Saved'
          }
          if (response.validationErrors) {
            let error_message = [
              '<strong>Value not saved, see error(s):</strong><br />'
            ]

            for (const field_key in response.validationErrors) {
              error_message.push(
                response.validationErrors[field_key].name + ': ' + response.validationErrors[field_key].error
              )
            }
            this.$message.error({
              showClose: true,
              type: 'error',
              dangerouslyUseHTMLString: true,
              message: error_message.join('<br />'),
              duration: 0,
            })
          } else {
            this.$message.success({
              message: response.message,
            })
          }

        },
        (error) => {
          row[data.field] = originalValue
          this.$message.error({
            showClose: true,
            type: 'error',
            message: util.formatErrorMessage(error),
            duration: 0,
          })
        },
        data.project_id,
        {
          project_meta: [
            {
              project_field_id: data.project_field_id,
              value: data.value,
            },
          ],
          area_id: row.area_id,
          project_id: row.project_id,
          return_errors: true,
        }
      )
    },
  },
}
</script>
<style lang="scss">

</style>
