<template>
  <div>
    <el-row :gutter="10">
      <el-col>
        <el-card class="approvals">
          <div slot="header">
            <span>
              <el-button type="primary" @click="newApproval">
                Add Approval
              </el-button>
            </span>
          </div>

          <el-row :gutter="10" class="approval-filters">
            <el-col :span="2">
              <el-input
                v-model="numberSearchInput"
                placeholder="filter"
                @change="searchNumber"
              />
            </el-col>
            <el-col :span="2"> &nbsp;</el-col>
            <el-col :span="4">
              <el-input
                v-model="projectSearchInput"
                placeholder="filter"
                @change="searchProject"
              />
            </el-col>
            <el-col :span="5">
              <el-input
                v-model="subcontractorSearchInput"
                placeholder="filter"
                @change="searchSubcontractor"
              />
            </el-col>
            <el-col :span="1"> &nbsp;</el-col>
            <el-col :span="1"> &nbsp;</el-col>
            <el-col :span="1"> &nbsp;</el-col>
            <el-col :span="1"> &nbsp;</el-col>
            <el-col :span="2">
              <el-select
                v-model="statusSearchInput"
                placeholder="filter"
                @change="searchStatus"
              >
                <el-option label="Approved" value="approved"/>
                <el-option label="Pending" value="pending"/>
              </el-select>
            </el-col>
          </el-row>

          <div class="approval-list header">
            <el-row :gutter="5">
              <el-col :span="2"> Reference</el-col>
              <el-col :span="2"> Date</el-col>
              <el-col :span="4"> Project</el-col>
              <el-col :span="4"> Subcontractor</el-col>
              <el-col :span="1"> Approved</el-col>
              <el-col :span="1" class="bottom"> Pending</el-col>
              <el-col :span="1"> Disapproved</el-col>
              <el-col :span="1" class="bottom"> Total</el-col>
              <el-col :span="2"> Status</el-col>
              <el-col :span="2"> Bauleiter</el-col>
              <el-col :span="2"> Approver</el-col>
              <el-col :span="2" align="right"> Actions</el-col>
            </el-row>
          </div>
          <div class="approval-list body scrollable">
            <div
              v-for="approval in approvals"
              :key="approval.id"
              :gutter="10"
              class="approval-row"
              @click="handleRowClick(approval)"
            >
              <el-col :span="2">
                <div>
                  <span
                  ><template v-if="approval.reference">{{
                      approval.reference
                    }}</template></span
                  >
                </div>
              </el-col>
              <el-col :span="2">
                <span
                ><template v-if="approval.approvedAt">{{
                    approval.approvedAt
                  }}</template></span
                >
              </el-col>
              <el-col :span="4">
                <span
                ><template v-if="approval.project && approval.project.name">{{
                    approval.project.name
                  }}</template></span
                >
              </el-col>
              <el-col :span="4">
                <span
                ><template
                  v-if="approval.subcontractor && approval.subcontractor.name"
                >{{ approval.subcontractor.name }}</template
                ></span
                >
              </el-col>

              <el-col :span="1">
                <span
                ><template
                  v-if="
                      approval.approved_items_count ||
                      approval.approved_items_count === 0
                    "
                >{{ approval.approved_items_count }}</template
                ></span
                >
              </el-col>
              <el-col :span="1">
                <span
                ><template
                  v-if="
                      approval.undecided_items_count ||
                      approval.undecided_items_count === 0
                    "
                >{{ approval.undecided_items_count }}</template
                ></span
                >
              </el-col>
              <el-col :span="1">
                <span
                ><template
                  v-if="
                      approval.denied_items_count ||
                      approval.denied_items_count === 0
                    "
                >{{ approval.denied_items_count }}</template
                ></span
                >
              </el-col>
              <el-col :span="1">
                <span
                ><template
                  v-if="
                      approval.total_items_count ||
                      approval.total_items_count === 0
                    "
                >{{ approval.total_items_count }}</template
                ></span
                >
              </el-col>
              <el-col :span="2">
                <span
                ><template v-if="approval.status">{{
                    approval.status
                  }}</template></span
                >
              </el-col>
              <el-col :span="2">
                <span
                ><template
                  v-if="
                      approval.subcontractor_contact &&
                      approval.subcontractor_contact.fullname
                    "
                >{{ approval.subcontractor_contact.fullname }}</template
                ></span
                >
              </el-col>
              <el-col :span="2">
                <div>
                  <span
                  ><template v-if="approval.approved_by_contact">{{
                      approval.approved_by_contact
                    }}</template></span
                  >
                </div>
              </el-col>
              <el-col :span="2">
                <el-button
                  size="mini"
                  icon="el-icon el-icon-download"
                  type="primary"
                  @click.stop="downloadApproval(approval)"
                >
                </el-button>
                <el-button
                  icon="el-icon el-icon-delete"
                  size="mini"
                  type="danger"
                  @click.stop="deleteApproval(approval)"
                />
              </el-col>
            </div>
          </div>
          <el-pagination
            v-if="total !== null && perPage !== null && total > perPage"
            :current-page.sync="currentPage"
            :page-size="perPage"
            :total="total"
            class="pagination"
            layout="total, prev, pager, next"
            @current-change="fetchApprovals"
          />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import projectApprovalsApi from 'api/v2/projectApprovals'
import approvalsApi from 'api/v2/approvals'
import {logErrorMessage, formatErrorMessage} from '../../../../../common/util';

export default {
  name: 'ProjectApprovals',
  components: {},

  data() {
    return {
      approvals: [],
      numberSearchInput: null,
      projectSearchInput: null,
      subcontractorSearchInput: null,
      statusSearchInput: null,
      projects: [],
      form: {
        project: null,
        subcontractor: null,
        contact: null,
        name: null,
      },
      subcontractors: [],
      contacts: [],
      currentPage: null,
      perPage: 20,
      total: null,
    }
  },

  computed: {
    projectId() {
      return this.$route.params.projectId
    },
  },

  mounted() {
    this.fetchApprovals(1)
  },

  methods: {
    newApproval() {
      this.$router.push('/approvals/add')
    },

    handleRowClick(row) {
      this.$router.push(`/projects/${row.project_id}/approvals/${row.id}`)
    },

    fetchApprovals(page, params) {
      projectApprovalsApi.index(
        (response) => {
          this.approvals = response.data
          this.currentPage = response.meta.current_page
          this.perPage = response.meta.per_page
          this.total = response.meta.total
        },
        (error) => {
          logErrorMessage(error)
          this.$message.error({
            showClose: true,
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.projectId,
        {
          page,
          include: [
            'project',
            'subcontractor',
            'subcontractorContact',
            'approvedByContact',
          ],
          ...params,
        }
      )
    },
    deleteApproval(approval) {
      projectApprovalsApi.delete(
        () => {
          this.fetchApprovals(1)
        },
        (error) => {
          logErrorMessage(error)
          this.$message.error({
            showClose: true,
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        approval.project_id,
        approval.id
      )
    },
    downloadApproval(approval) {
      approvalsApi.download(
        () => {

        },
        (error) => {
          logErrorMessage(error)
          this.$message.error({
            showClose: true,
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        approval.id
      )
    },
    searchNumber(reference) {
      this.fetchApprovals(this.currentPage, {reference})
    },
    searchProject(project) {
      this.fetchApprovals(this.currentPage, {project: project.name})
    },
    searchSubcontractor(subcontractor) {
      this.fetchApprovals(this.currentPage, {
        subcontractor: subcontractor.name,
      })
    },
    searchStatus(status) {
      this.fetchApprovals(this.currentPage, {status})
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~scss/mixins';

@include tiny-vertical-scroll;

.scrollable {
  overflow-y: auto;
  height: calc(100vh - 320px);
  padding-right: 10px;
  margin-right: -1px;
}

::v-deep .el-card__header {
  font-size: 15px;
}

.pagination {
  float: right;
  margin-top: 1rem;

  &:after {
    content: '';
    clear: both;
    display: block;
  }
}

.approvals,
.subcontractors {
  ::v-deep .el-input input {
    height: auto;
  }

  .el-switch {
    margin-right: 4px;
  }
}

.approvals .el-input {
  margin-left: -5px;
}

.approval-list,
.subcontractor-list {
  &.header {
    padding: 5px;
    font-size: 13px;
    border-bottom: 1px solid black;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .approval-row,
  .subcontractor-row {
    padding: 3px 5px;
    font-size: 13px;
    line-height: 24px;
    height: 30px;
    white-space: nowrap;

    div.el-col {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }

    &:nth-child(even) {
      background: rgb(239, 242, 247);
    }

    &:hover {
      background: rgba(32, 160, 255, 0.5);
      cursor: pointer;
    }
  }

  .el-checkbox {
    margin-right: 6px;
  }
}

.el-button {

}

.bottom {
  bottom: 15px;
  position: relative;
}
</style>
