<template>
  <el-card class="lerf-email-additional-file-picker position-images">
    <div slot="header">
      <h3>Additional files
        <input ref="fileInput" type="file" multiple @input="uploadDocument"/>
        <el-button
            v-if="allowEdit"
            icon="el-icon-plus"
            size="small"
            type="primary"
            @click="selectDocument"
        >Add</el-button>
      </h3>
    </div>
    <div>
      If you want to add additional files to your email, you can do that here.
      <template v-if="uploadedFiles?.images?.length">
        <h6>Images</h6>
        <multiple-picker v-model="imageList">
          <div class="image-wrapper">
            <div
                v-for="(image, index) in uploadedFiles?.images"
                :key="image.token"
                v-loading="loading"
                :title="image.name"
                class="image-thumbnail"
            >
              <div
                  :style="{'background-image': `url('${image.url}')`}"
                  @click="openImageModal(image.url)"
              >
              </div>
              <span class="checkbox">
                <el-checkbox
                    :key="index"
                    :label="image.token"
                    @click.stop="() => {}"
                >
                </el-checkbox>
              </span>
              <el-button
                v-if="isSuperAdmin && allowEdit"
                class="el-icon el-icon-delete"
                type="danger"
                size="mini"
                @click.stop="deleteDocument(image.token)"
              ></el-button>
            </div>
          </div>
        </multiple-picker>
      </template>
      <template v-if="uploadedFiles?.files?.length">
        <h6>Files</h6>
        <multiple-picker v-model="fileList">
          <div class="image-wrapper">
            <div
                v-for="(file, index) in uploadedFiles?.files"
                :key="file.token"
                v-loading="loading"
                :title="file.name"
                class="image-thumbnail"
            >
              <div @click="openDocument(file.url)">
                <i class="el-icon el-icon-document"></i>
                <span>{{file.name}}</span>
              </div>
              <span class="checkbox">
                <el-checkbox
                    :key="index"
                    :label="file.token"
                    @click.stop="() => {}"
                >
                </el-checkbox>
              </span>
            </div>
          </div>
        </multiple-picker>
      </template>
    </div>
    <imageModal
        v-if="showImageModal"
        :picture="imageModalUrl"
        @close="hideImageModal"
    />
  </el-card>
</template>

<script>
import projectLerfEmailApi from '@/js/api/v2/projectLerfEmail';
import projectPurchaseInvoiceEmailApi from '@/js/api/v2/projectPurchaseInvoiceEmail';
import {formatErrorMessage} from '@/js/common/util';
import imageModal from '@/js/components/Items/ImageModal.vue';
import MultiplePicker from '@/js/components/Common/Form/MultiplePicker.vue';

export default {
  name: 'LerfEmailAdditionalFilePicker',
  components: {MultiplePicker, imageModal},

  props: {
    lerf: {
      type: Object,
      default: null,
    },
    purchaseInvoice: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      fileList: [],
      imageList: [],
      loading: true,
      uploadedFiles: {},
      allowEdit: true,
      showImageModal: false,
      imageModalUrl: '',
    }
  },

  computed: {
    isSuperAdmin() {
      return this.$store.getters['global/user']['role'] === 'super_admin'
    },
    invoiceId() {
      return this.lerf?.id ? this.lerf.id : this.purchaseInvoice.id
    },
    projectId() {
      return this.lerf?.projectId ? this.lerf.projectId : this.purchaseInvoice.projectId
    },
    api() {
      return this.lerf ? projectLerfEmailApi : projectPurchaseInvoiceEmailApi
    }
  },
  watch: {
    fileList() {
      this.emitChange()
    },
    imageList() {
      this.emitChange()
    },
  },
  mounted() {
    this.loadIndex()
  },
  methods: {
    emitChange() {
      this.$emit('change', {
        images: this.imageList,
        files: this.fileList,
      })
    },
    openImageModal(url) {
      this.showImageModal = true
      this.imageModalUrl = url
    },
    hideImageModal() {
      this.showImageModal = false
      this.imageModalUrl = ''
    },
    openDocument(url) {
      window.open(url, '_blank');
    },
    selectDocument() {
      this.$refs.fileInput.click()
    },
    loadIndex() {
      this.loading = true
      this.api.additionalFilesIndex(
        (response) => {
          this.uploadedFiles = response.data
          this.loading = false
        },
        (error) => {
          this.$message({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
          this.loading = false
        },
        this.projectId,
        this.invoiceId
      )
    },
    uploadDocument(event) {
      let files = []
      for (const file of event.target.files) {
        files.push(file)
      }
      this.api.additionalFilesUpload(
        () => {
          this.loadIndex()
        },
        (error) => {
          this.$message({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.projectId,
        this.invoiceId,
        files
      )
    },
    deleteDocument(token) {
      if(!confirm('Are you sure?')) {
        return;
      }
      this.api.additionalFileDelete(
        () => {
          this.loadIndex()
        },
        (error) => {
          this.$message({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.projectId,
        this.invoiceId,
        token
      );
    }
  },
}
</script>

<style lang="scss">
.lerf-email-additional-file-picker {
  margin-bottom: 0.5rem;

  h3 {
    font-size: 1.4em;
    margin: 0;
  }
}
.position-images {
  h6 {
    margin: 10px 0 15px 0;
  }
  .multiple-picker {
    .checkbox {
      position: absolute;
      z-index: 10000001;
      background: white;
      width: 20px;
      height:20px;
      .el-checkbox {
        width: initial;
        margin: 0;
        display: initial;
      }
      .el-checkbox__label {
        display: none;
      }
    }
  }
}
</style>
