<template>
  <div v-loading="loading" class="claims-view">
    <template v-if="data">
      <el-row :gutter="24" class="header-action-row">
        <el-col :span="12">
          <el-button
            v-if="canAddClaim"
            type="primary"
            size="medium"
            @click="addDialogVisible = true"
          >
            Add Claim
          </el-button>
          <div class="grid-content bg-purple" />
        </el-col>
        <el-col :span="6" style="text-align: right">
          <el-button
            type="primary"
            :loading="isDownloading"
            size="medium"
            @click="exportClaims"

          >
            Export
          </el-button>
        </el-col>
        <el-col :span="6">
          <el-input
            v-model="searchValue"
            class="grid-content bg-purple"
            placeholder="Search by reference"
            suffix-icon="el-icon-search"
          />
        </el-col>
      </el-row>

      <el-table
        v-loading="spinner"
        :data="data"
        :summary-method="getSummaries"
        show-summary
        sum-text="Sum"
        stripe
        size="mini"
        :highlight-current-row="true"
        @cell-dblclick="openItem"
        @sort-change="changeSort"
        @filter-change="changeFilter"
      >
        <el-table-column
          prop="reference"
          label="Reference"
          width="150"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="category"
          label="Category"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="description"
          label="Description"
          sortable="custom"
          :show-overflow-tooltip="true"
          width="120"
        />
        <el-table-column
          prop="project.name"
          label="Project"
          width="200"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="area"
          label="Area"
          sortable="custom"
          width="100"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="start_at"
          label="Date from"
          width="120"
          sortable="custom"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ formatDateFromData(scope.row.start_at) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="end_at"
          label="Date to"
          width="100"
          sortable="custom"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ formatDateFromData(scope.row.end_at) }}
          </template>
        </el-table-column>

        <el-table-column
          prop="status"
          column-key="status"
          label="Status"
          filter-placement="bottom-end"
          sortable="custom"
          :filters="filterStatus"
          width="280"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-tag
              :color="status[scope.row.status].meta.color"
              :effect="tagBackground(scope.row.status)"
              close-transition
            >
              {{ status[scope.row.status].label }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          prop="net"
          label="Net"
          align="right"
          :formatter="(r, c, val) => formatNumber(val)"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          width="100"
          prop="gross"
          label="Gross"
          align="right"
          :formatter="(r, c, val) => formatNumber(val)"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          fixeds="right"
          label="Operations"
          width="110"
          class-name="actions"
          :show-overflow-tooltip="true"
          align="right"
        >
          <template slot-scope="scope">
            <el-button
              v-if="canEditClaim"
              size="mini"
              icon="el-icon el-icon-edit"
              @click.native.prevent="editItem(scope.$index)"
            >
            </el-button>
            <el-button
              v-if="canRemoveClaim"
              type="danger"
              size="mini"
              icon="el-icon el-icon-delete"
              @click.native.stop.prevent="removeItem(scope.$index)"
            >
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :per-page="perPage"
        :total="total"
        :current-page="currentPage"
        @pageChange="pageChange"
        @changePageSize="changePageSize"
      />
      <ClaimAddDialog
        v-if="addDialogVisible"
        :project="project"
        @cancel="closeDialog"
      />
    </template>
  </div>
</template>

<script>
import { formatDate, can, formatErrorMessage, logErrorMessage } from 'common/util'
import { getNet, getGross } from 'common/lerfUtil'
import claimsApi from 'api/v2/claims'
import projectClaimsApi from 'api/v2/projectClaims'
import ClaimAddDialog from './AddDialog'
import numeral from 'numeral'
import Pagination from '@/js/components/Common/DefaultPagination.vue';


export default {
  name: 'ClaimsTable',
  components: {
    ClaimAddDialog,
    Pagination
  },

  props: {
    project: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      data: [],
      id: null,
      currentPage: 1,
      total: null,
      perPage: 20,
      loading: false,
      addDialogVisible: false,
      summaryData: [],
      searchValue: null,
      orderProp: null,
      orderDirection: null,
      filters: null,
      spinner: true,
      isDownloading: false,
    }
  },

  computed: {
    status() {
      return this.$store.getters['global/getEnumById']('claim_status')
    },

    filterStatus() {
      return Object.values(this.status).map((item) => {
        return {
          text: item.label,
          value: item.value,
        }
      })
    },

    canAddClaim() {
      return can('create', 'claims')
    },

    canRemoveClaim() {
      return can('delete', 'claims')
    },

    canEditClaim() {
      return can('update', 'claims')
    },
  },

  watch: {
    searchValue() {
      this.search()
    },
    project() {
      this.pageChange(1)
    },
  },

  mounted() {
    this.loadPage(1)
  },

  methods: {
    tagBackground(status) {
      if (
        [
          'one',
          'two',
          'three',
          'four',
          'five',
          'six',
          'seven', // Green Background
          'eight',
          'nine' + '',
        ].includes(status)
      ) {
        return 'dark'
      }
      return 'light'
    },
    getNet(a, b) {
      return getNet(a, b)
    },

    getGross(a, b) {
      return getGross(a, b)
    },

    search() {
      this.pageChange(1)
    },

    openItem(item) {
      this.$router.push(
        '/projects/' + item.project_id + '/claims/' + item.id + '/overview'
      )
    },

    closeDialog() {
      this.addDialogVisible = false
    },

    editItem(index) {
      this.$router.push(
        '/projects/' +
          this.data[index].project_id +
          '/claims/' +
          this.data[index].id
      )
    },

    removeItem(index) {
      let confirm = window.confirm('Are you sure?')

      if (confirm) {
        claimsApi.delete(
          () => {
            this.$message({
              type: 'success',
              message: 'Deleted',
              duration: 3000,
            })

            this.data.splice(index, 1)
            this.loadSummaries()
          },
          (error) => {
            this.$message({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 0,
            })
          },
          this.data[index].id
        )
      }
    },
    changePageSize(newPageSize) {
      this.perPage = newPageSize
      this.pageChange(1)
    },
    pageChange(val) {
      this.loading = true
      this.spinner = true
      this.currentPage = val
      this.loadPage(val)
    },

    formatDateFromData(date) {
      return formatDate(date)
    },

    getSummaries() {
      return this.summaryData
    },

    loadSummaries() {
      if (this.project) {
        projectClaimsApi.index(
          (response) => {
            this.summaryData = response
          },
          (error) => {
            logErrorMessage('Failed to get summary')
            this.$message.error({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 0,
            })
          },
          this.project.id,
          {
            summary: true,
            search: this.searchValue,
            orderBy: this.orderProp,
            orderDirection: this.orderDirection,
            filters: this.filters,
          }
        )
      } else {
        claimsApi.index(
          (response) => {
            this.summaryData = response
          },
          () => {
            logErrorMessage('Failed to get summary')
          },
          {
            summary: true,
            search: this.searchValue,
            orderBy: this.orderProp,
            orderDirection: this.orderDirection,
            filters: this.filters,
          }
        )
      }
    },

    loadPage(page) {
      this.data = []
      if (this.project) {
        projectClaimsApi.index(
          (response) => {
            this.loading = false
            this.spinner = false
            this.data = response.data
            this.total = response.meta.total
            this.perPage = response.meta.perPage
          },
          (error) => {
            this.loading = false
            this.spinner = false
            this.$message.error({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 0,
            })
          },
          this.project.id,
          {
            page,
            perPage: this.perPage,
            include: ['project'],
            search: this.searchValue,
            orderBy: this.orderProp,
            orderDirection: this.orderDirection,
            filters: this.filters,
          }
        )
      } else {
        claimsApi.index(
          (response) => {
            this.loading = false
            this.spinner = false
            this.data = response.data
            this.total = response.meta.total
            this.perPage = response.meta.per_page
          },
          (error) => {
            this.loading = false
            this.spinner = false
            this.$message.error({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 0,
            })
          },
          {
            page,
            perPage: this.perPage,
            search: this.searchValue,
            orderBy: this.orderProp,
            orderDirection: this.orderDirection,
            filters: this.filters,
            include: ['project'],
          }
        )
      }

      this.loadSummaries()
    },

    changeSort({ prop, order }) {
      this.orderProp = prop
      if (order === 'ascending') {
        this.orderDirection = 'asc'
      } else if (order === 'descending') {
        this.orderDirection = 'desc'
      } else {
        this.orderDirection = null
      }

      this.pageChange(1)
    },

    changeFilter(filters) {
      this.filters = filters

      this.pageChange(1)
    },

    formatNumber(number) {
      return numeral(number).format()
    },

    exportClaims() {
      this.isDownloading = true

      const filename = this.project
        ? `project-${this.project.name}-claims-export`
        : 'claims-export'

      claimsApi.export(
        (response) => {
          let blob = new Blob([response])
          let link = document.createElement('a')
          const objURL = window.URL.createObjectURL(blob)
          link.href = objURL
          link.download = filename + '.csv'
          link.click()

          window.URL.revokeObjectURL(objURL)

          this.isDownloading = false
        },
        (error) => {
          this.isDownloading = false
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.project ? this.project.id : null
      )
    },
  },
}
</script>

<style lang="scss"></style>
