<template>
  <el-dialog
    :visible.sync="visible"
    title="Add customer"
    :before-close="notifyParent"
  >
    <default-form
      :form="form"
      :loading="loading"
      :errors="formErrors"
      @submit="addCustomer"
    />
  </el-dialog>
</template>

<script>
import Vue from 'vue'
import customersApi from 'api/v2/customers'
import DefaultForm from 'components/Common/Form/DefaultForm'
import {logErrorMessage} from '../../common/util';

export default {
  name: 'DialogAddCustomer',

  components: {
    DefaultForm,
  },

  data() {
    return {
      visible: true,
      loading: false,
      formErrors: null,
    }
  },

  computed: {
    formItems() {
      return [
        {
          label: 'Name',
          prop: 'name',
          required: true,
          rules: [],
        },
        {
          label: 'Short Name',
          prop: 'short_name',
          required: true,
          rules: [],
        },
        {
          label: 'Second Name',
          prop: 'second_name',
          required: false,
          rules: [],
        },
        {
          label: 'Address',
          prop: 'address',
          required: false,
          rules: [],
        },
        {
          label: 'Details Line 1',
          prop: 'details_1',
          required: false,
          rules: [],
        },
        {
          label: 'Details line 1 on invoice',
          prop: 'details_1_on_invoice',
          required: false,
          type: 'checkbox',
          rules: [],
        },
        {
          label: 'Details Line 2',
          prop: 'details_2',
          required: false,
          rules: [],
        },
        {
          label: 'Details line 2 on invoice',
          prop: 'details_2_on_invoice',
          required: false,
          type: 'checkbox',
          rules: [],
        },
        {
          label: 'Details Line 3',
          prop: 'details_3',
          required: false,
          rules: [],
        },
        {
          label: 'Details line 3 on invoice',
          prop: 'details_3_on_invoice',
          required: false,
          type: 'checkbox',
          rules: [],
        },
        {
          label: 'Details Line 4',
          prop: 'details_4',
          required: false,
          rules: [],
        },
        {
          label: 'Details line 4 on invoice',
          prop: 'details_4_on_invoice',
          required: false,
          type: 'checkbox',
          rules: [],
        },
      ]
    },

    form() {
      return {
        items: this.formItems,
        initialModel: {
          'details_1_on_invoice' : true,
          'details_2_on_invoice' : true,
          'details_3_on_invoice' : true,
          'details_4_on_invoice' : true,
        },
        labelWidth: '250px',
        buttons: [
          {
            event: 'submit',
            label: 'Add customer',
            type: 'primary',
          },
        ],
        ref: 'addCustomerForm',
      }
    },
  },

  methods: {
    notifyParent(done) {
      done()
      this.$parent.$emit('cancelAddItem')
    },

    addCustomer(model) {
      this.loading = true
      customersApi.store(
        () => {
          this.$message.success(
            {
              showClose: true,
              message: 'Customer has been created.',
              duration: 5000,
            },
            (error) => {
              logErrorMessage(error)
            }
          )
          this.loading = false
          this.visible = false
          this.$parent.$emit('changedAddItem')
        },
        (error) => {
          this.formErrors = error
          this.loading = false
          Vue.nextTick(() => {
            this.formErrors = null
          })
        },
        model
      )
    },
  },
}
</script>

<style lang="scss"></style>
