<template>
  <div>
    <el-card class="email-image-picker">
      <div slot="header">
        <h3>Images and Attachments</h3>
      </div>
      <p>
        You can add the imported images or additionally uploaded images to the existing pdf or add them as separate image files.<br /><br />
        <el-radio v-model="type" class="radio" label="pdf"> PDF </el-radio>
        <el-radio v-model="type" class="radio" label="images"> Images </el-radio>
      </p>
    </el-card>
    <el-card class="email-image-picker">
      <div slot="header">
        <h3>Imported images</h3>
      </div>

      <p v-if="email.images.length">
        Please choose which automatically imported photos should be added to the email.
      </p>

      <p v-if="email.images.length">
        <el-checkbox
          v-model="selectedAllImages"
          @change="selectedAllImagesChanged"
        >
          Select All
        </el-checkbox>
      </p>

      <multiple-picker v-if="showSelection && email.images.length" v-model="selectedImages">
        <el-checkbox
          v-for="(image, index) in email.images"
          :key="index"
          :label="image"
        >
          <img :src="image" />
        </el-checkbox>
      </multiple-picker>
      <span v-else> The attached items do not have any images. </span>
    </el-card>
  </div>
</template>

<script>
import MultiplePicker from 'components/Common/Form/MultiplePicker'

export default {
  name: 'EmailImagePicker',

  components: {
    MultiplePicker,
  },

  props: {
    email: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedImages: this.email.images,
      selectedAllImages: true,
      showSelection: true,
      type: 'pdf',
    }
  },
  watch: {
    selectedImages: {
      deep: true,
      handler(newSelection) {
        this.$emit('change', newSelection)
        if(newSelection.length !== this.email.images.length) {
          this.selectedAllImages = false
        } else {
          this.selectedAllImages = true
        }
      },
    },

    type(newType) {
      this.$emit('type', newType)
    },
  },
  methods: {
    selectedAllImagesChanged() {
      let newSelection = [];
      if(this.selectedAllImages) {
        newSelection = this.email.images
      }

      this.$emit('change', newSelection)
      this.selectedImages = newSelection

      this.showSelection = false
      this.$nextTick(() => {
        this.showSelection = true
      })
    }
  }
}
</script>

<style lang="scss">
.email-image-picker {
  margin-bottom: 0.5rem;

  h3 {
    font-size: 1.4em;
    margin: 0;
  }
}
</style>
