<template>
  <el-form label-width="120px" label-position="top">
    <el-form-item ref="position" label="Pricing item">
      <el-select
        v-model="pricingItem"
        placeholder="Please select..."
        filterable
        return-object
        value-key="id"
        clearable
        class="position-select"
        @change="itemChanged"
      >
        <el-option-group
          v-for="optionGroup in pricingItemOptions"
          :key="optionGroup.id"
          :label="optionGroup.name"
          :visible="true"
        >
          <el-option
            v-for="option in optionGroup.options"
            :key="option.id"
            :label="`${option.reference} / ${option.description}`"
            :value="option"
          />
        </el-option-group>
      </el-select>
    </el-form-item>

    <template v-if="!item.pricing_item_id">
      <el-form-item label="Description">
        <el-input v-model="item.description" :required="true" />
      </el-form-item>

      <el-form-item label="Unit">
        <el-select v-model="item.unit" placeholder="Unit">
          <el-option label="M" value="M" />
          <el-option label="M2" value="M2" />
          <el-option label="M3" value="M3" />
          <el-option label="ST" value="ST" />
          <el-option label="STD" value="STD" />
        </el-select>
      </el-form-item>
    </template>

    <el-form-item label="Comment">
      <el-input v-model="item.comment" :required="true" />
    </el-form-item>
    <el-form-item label="Section">
      <el-input v-model="item.section" />
    </el-form-item>
    <el-form-item label="Custom category">
      <el-input v-model="item.custom_category" />
    </el-form-item>

    <el-form-item label="Measurements" class="measurements">
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input
            v-if="['M', 'M2', 'M3'].indexOf(item.unit) !== -1"
            v-model="item.length"
            :required="true"
          >
            <template slot="prepend">Length</template>
            <template slot="append">M</template>
          </el-input>
        </el-col>
        <el-col :span="5">
          <el-input
            v-if="['M2', 'M3'].indexOf(item.unit) !== -1"
            v-model="item.width"
            :required="true"
            prop="item.width"
          >
            <template slot="prepend">Width</template>
            <template slot="append">M</template>
          </el-input>
        </el-col>
        <el-col :span="5">
          <el-input
            v-if="item.unit === 'M3'"
            v-model="item.height"
            :required="true"
          >
            <template slot="prepend">Height</template>
            <template slot="append">M</template>
          </el-input>
        </el-col>
        <el-col :span="5">
          <el-input
            v-if="item.unit === 'ST' || item.unit === 'STD'"
            v-model="item.amount"
            :required="true"
          >
            <template slot="prepend">{{
              item.unit === 'STD' ? 'Hours' : 'Amount'
            }}</template>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-input v-model="item.factor" :required="true">
            <template slot="prepend">Factor</template>
          </el-input>
        </el-col>
      </el-row>
    </el-form-item>

    <el-form-item label="Calculated" class="calculated">
      <el-row :gutter="20">
        <el-col v-if="['M', 'M2', 'M3'].indexOf(item.unit) !== -1" :span="5" >
          <el-input
            v-model="getMass"
            :required="true"
            :readonly="true"
            disabled
          >
            <template slot="prepend">Mass</template>
            <template slot="append">{{ item.unit }}</template>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-input v-model="item.price" :required="true">
            <template slot="prepend">Price</template>
            <template slot="append">EUR</template>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-input
            v-model="getGross"
            :required="true"
            :readonly="true"
            disabled
          >
            <template slot="prepend">Gross</template>
            <template slot="append">EUR</template>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-input v-model="getNet" :required="true" :readonly="true" disabled>
            <template slot="prepend">Net</template>
            <template slot="append">EUR</template>
          </el-input>
        </el-col>
      </el-row>
    </el-form-item>

    <el-form-item>
      <el-button :loading="loading" type="primary" @click="onSubmit(item)">
        {{ isEditing ? 'Update' : 'Create' }}
      </el-button>
      <el-button type="warning" @click="cancel"> Cancel </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import pricingTemplateApi from '../../api/v2/pricingTemplates'
import * as lerfUtil from '../../common/lerfUtil'
import {logErrorMessage} from '../../common/util'

export default {
  name: 'ClaimItemForm',
  props: {
    claim: {
      type: Object,
      required: true,
    },
    claimItem: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      pricingCategories: [],
      pricingItems: [],
      pricingTemplate: {},
      pricingItem: null,
      item: {
        description: null,
        comment: null,
        width: 0,
        height: 0,
        length: 0,
        amount: 0,
        pricing_item_id: null,
        price: null,
        factor: 1,
        unit: 'M',
        section: null,
        custom_category: null,
      },
    }
  },
  computed: {
    isEditing() {
      return !!this.item.id
    },
    getMass() {
      return lerfUtil.getMass(this.item)
    },
    getGross() {
      return lerfUtil.getGross(this.item, true)
    },
    getNet() {
      return lerfUtil.getNet(this.item, true)
    },
    pricingItemOptions() {
      if (!this.pricingCategories || !this.pricingItems) {
        return []
      }
      const options = []
      this.pricingCategories.forEach((pricingCategory) => {
        const optionGroup = {
          id: `cat-${pricingCategory.id}`,
          name: pricingCategory.name,
          options: [],
        }
        optionGroup.options = this.pricingItems.filter(
          (pricingItem) =>
            pricingItem.pricing_category_id === pricingCategory.id
        )
        options.push(optionGroup)
      })
      return options
    },
  },
  mounted() {
    if (this.claimItem) {
      this.item = {
        id: this.claimItem.id,
        description: this.claimItem.description,
        comment: this.claimItem.comment,
        width: this.claimItem.width,
        height: this.claimItem.height,
        length: this.claimItem.length,
        amount: this.claimItem.amount,
        pricing_item_id: this.claimItem.pricing_item_id,
        price: this.claimItem.price,
        factor: this.claimItem.factor,
        unit: this.claimItem.unit,
        section: this.claimItem.section,
        custom_category: this.claimItem.custom_category,
      }
    }
    this.getCategories()
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },

    getCategories() {
      pricingTemplateApi.index(
        (response) => {
          const pricingTemplate = response[0]

          this.$set(this, 'pricingTemplate', pricingTemplate)
          this.$set(this, 'pricingCategories', pricingTemplate.categories)
          this.$set(this, 'pricingItems', pricingTemplate.items)

          this.pricingItem = this.pricingItems.find((item) => {
            return item.id === this.claimItem.pricing_item_id
          })
        },
        (error) => {
          logErrorMessage(error)
        },
        {
          project_id: this.claim.projectId,
          subcontractor_id: this.claim.subcontractor
            ? this.claim.subcontractor.id
            : null,
          include: ['categories', 'items'],
        }
      )
    },
    itemChanged(selectedItem) {
      this.updateItemAmounts(selectedItem)
    },
    updateItemAmounts(selectedPricingItem) {
      this.item.pricing_item_id = null
      if (!selectedPricingItem) return
      if (!this.item) return

      const pricingItem = this.pricingItems.find((item) => {
        return item.id === selectedPricingItem.id
      })

      this.item.pricing_item_id = pricingItem.id
      this.item.description = pricingItem.description
      this.item.price = pricingItem.price
      this.item.factor = pricingItem.factor
      this.item.unit = pricingItem.unit
    },
  },
}
</script>

<style lang="scss" scoped>
.position-select {
  width: 100%;
}

.measurements input,
.calculated input {
  text-align: right;
}
</style>
