<template>
  <div>
    <el-table :data="items" border style="width: 100%">
      <el-table-column prop="created_at" label="Date" width="180">
        <template slot-scope="scope">
          {{ formatDate(scope.row.created_at) }}
        </template>
      </el-table-column>
      <el-table-column prop="user" label="User" width="180">
        <template slot-scope="scope">
          {{ scope.row.user ? scope.row.user.name : '' }}
        </template>
      </el-table-column>
      <el-table-column prop="tag" label="New">
        <template slot-scope="scope">
          <el-tag
            :color="statuses[scope.row.status_new].meta.color"
            :effect="tagBackground(scope.row.status_new)"
            close-transition
          >
            {{ statuses[scope.row.status_new].label }}
          </el-tag>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import * as util from 'common/util'

export default {
  name: 'LeftStatusHistory',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    statuses() {
      return this.$store.getters['global/getEnumById']('lerf_status')
    },
  },
  methods: {
    tagBackground(status) {
      if (
        [
          'one',
          'two',
          'three',
          'four',
          'five',
          'six',
          'seven', // Green Background
          'eight',
          'nine' + '',
        ].includes(status)
      ) {
        return 'dark'
      }
      return 'light'
    },
    formatDate(value) {
      return util.formatDateTime(value)
    },
  },
}
</script>
