import ApiResource from '../ApiResource'

class PricingTemplateResource extends ApiResource {
  constructor() {
    super({
      name: 'pricing-templates',
    })
  }

  import(resolve, reject, pricingTemplateId, file) {
    this.ids = [pricingTemplateId]

    const formData = new FormData()
    formData.append('excel', file)

    this.post(`${this.getUrl()}/import`, formData)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  export(resolve, reject, pricingTemplateId, ...params) {
    this.ids = [pricingTemplateId]
    this.post(`${this.getUrl()}/export`, params)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  copyToProject(resolve, reject, pricingTemplateId, params) {
    this.ids = [pricingTemplateId]
    this.post(`${this.getUrl()}/copy-to-project`, params)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  duplicate(resolve, reject, pricingTemplateId, params) {
    this.ids = [pricingTemplateId]
    this.post(`${this.getUrl()}/duplicate`, params)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  fixMissingReferences(resolve, reject, pricingTemplate, params) {
    this.post(`api/v2/pricing-templates/${pricingTemplate}/fix-missing-references`, params)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  checkProjectPositions(resolve, reject, pricingTemplate, params) {
    this.get(`api/v2/pricing-templates/${pricingTemplate}/check-positions`, params)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const pricingTemplatesApi = new PricingTemplateResource()

export default pricingTemplatesApi
