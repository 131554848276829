<template>
  <div>
    <router-view name="body" />
  </div>
</template>

<script>
export default {
  name: 'ProjectPosition',
}
</script>

<style lang="scss"></style>
