<template>
  <el-card class="box-card revision-card table-card">
    <div slot="header" class="clearfix">
      <span>{{ cardTitle }}</span>
      <slot name="buttons"></slot>
    </div>

    <el-table
      :data="revisions"
      size="mini"
      :highlight-current-row="true"
      :height="tableHeight"
      stripe
    >
      <el-table-column
        prop="updated_at"
        label="date"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.updated_at">{{ formatDateTime(scope.row.updated_at) }}</span>
          <span v-if="scope.row.updatedAt">{{ formatDateTime(scope.row.updatedAt) }}</span>
        </template>
      </el-table-column>
      <template v-if="['projectAreaMeta', 'bidValue'].includes(type)">
        <el-table-column prop="username" label="user" />

        <template v-if="type === 'projectAreaMeta'">
          <el-table-column prop="costObjectCode" label="Kst2" />
          <el-table-column prop="projectName" label="project_name" />
        </template>

        <template v-if="type !== 'bidValue'">
          <el-table-column prop="category" label="category" />
        </template>

        <template v-if="type === 'bidValue' && !hideColumns.includes('bidValue.projectname')">
          <el-table-column prop="projectname" label="projectname" />
        </template>
        <template v-if="type === 'bidValue' && !hideColumns.includes('bidValue.area')">
          <el-table-column prop="area" label="area" />
        </template>

        <el-table-column prop="name" label="name" />
      </template>
      <template v-if="type === 'projectMeta'">
        <el-table-column prop="category" label="category" />
        <el-table-column prop="name" label="name" />
      </template>
      <template v-if="!['projectAreaMeta', 'bidValue'].includes(type)">
        <el-table-column prop="user.firstname" label="firstname" />
        <el-table-column prop="user.lastname" label="lastname" />
      </template>
      <template v-if="type === 'subcontractor'">
        <el-table-column prop="fieldName" label="name" />
      </template>
      <el-table-column prop="oldValue" label="old" />
      <el-table-column prop="newValue" label="new" />
    </el-table>
  </el-card>
</template>

<script>
import { formatDateTime } from 'common/util'

export default {
  name: 'RevisionTable',
  props: {
    cardTitle: {
      type: String,
      required: true,
    },

    revisions: {
      type: Array,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: 'auto',
    },
    hideColumns: {
      type: Array,
      required: false,
      default: () => [],
    },

  },
  computed: {
    tableHeight() {
      return this.height
    },
  },
  created() {

  },
  methods: {
    formatDateTime(value) {
      return formatDateTime(value)
    },
  },
}
</script>

<style lang="scss" scoped>
.revision-card {
  margin-top: 3rem;
}
</style>
