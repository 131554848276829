<template>
  <div class="comp-pricing-tab">
    <el-row :gutter="14">
      <el-col :span="minimizeApplied ? 2 : 5" :class="{ 'minimized-template-window': minimizeApplied }">
        <el-card id="template-selector">
          <div slot="header">
            <div v-if="minimizeApplied" class="minimized-template-head-content" @click="maximizeTemplateWindow()" >
              <span class="template-card-title">Templates</span>
            </div>

            <el-row>
              <el-col :span="14" :class="{'hidden' : minimizeApplied}">
                <span>Templates</span>
              </el-col>
              <el-col :span="10" align="right" :class="{'hidden' : minimizeApplied}">
                <el-button
                  align="right"
                  icon="fa fa-plus"
                  size="mini"
                  title="Add template"
                  type="success"
                  @click.native.prevent.stop="showAddTemplateDialog"
                >
                  Add template
                </el-button>
                <el-button
                    icon="el-icon el-icon-arrow-down"
                    size="mini"
                    type="secondary"
                    @click="minimizeTemplateWindow()"
                ></el-button>
                <!-- should be refactored to DialogTemplate -->
                <el-dialog
                  ref="editTemplateDialog"
                  :visible.sync="editDialogVisible"
                  class="nobeforeorafter"
                  title="Update template name"
                >
                  <el-input
                    ref="editTemplateNameInput"
                    v-model="editTemplateName"
                    placeholder="Template name"
                  />
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="editDialogVisible = false"
                    >Cancel</el-button
                    >
                    <el-button type="primary" @click="updateTemplate"
                    >Update Template</el-button
                    >
                  </span>
                </el-dialog>
                <!-- should be refactored to DialogTemplate -->
                <el-dialog
                  :show-close="false"
                  :title="`Do you really want to delete ${deleteTemplateName}? `"
                  :visible.sync="deleteDialogVisible"
                >
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="deleteDialogVisible = false"
                    >Cancel</el-button
                    >
                    <el-button
                      type="danger"
                      @click="deleteTemplate(deleteTemplateId)"
                    >Delete</el-button
                    >
                  </span>
                </el-dialog>
              </el-col>
            </el-row>
          </div>
          <DialogTemplate
            v-if="dialogType"
            :on-success="
              () => {
                fetchSubcontractorTemplates()
                selectedTemplate = null
                dialogType = null
              }
            "
            :onclose="
              () => {
                selectedTemplate = null
                dialogType = null
              }
            "
            :params="{ subcontractor_id: subcontractorId }"
            :template="selectedTemplate"
            :type="dialogType"
            :visible="true"
          />
          <div>
            <div class="column templates">
              <el-row
                v-for="template in subcontractorTemplates"
                :key="template.id"
                :gutter="2"
                align="middle"
                class="row"
                justify="space-between"
                type="flex"
              >
                <el-col>
                  <span>
                    <el-radio
                      :key="template.name"
                      v-model="currentSubcontractorTemplate"
                      :label="template.id"
                      class="template"
                    >
                      <div>
                        {{ template.name }}
                        <span v-if="template.project" class="project">
                          {{ template.project.name }}
                        </span>
                        <i
                            v-if="template.itemsWithMappingErrors !== 0"
                            class="el-icon el-icon-info action-edit item-has-issue"
                            title="Items in the template need attention"
                        ></i>
                      </div>
                    </el-radio>
                  </span>
                </el-col>
                <el-col :span="6" style="text-align: right;white-space: nowrap;width: 100px;">
                  <el-button
                    icon="el-icon el-icon-edit"
                    size="mini"
                    type="secondary"
                    @click="showEditTemplateDialog(template)"
                  ></el-button>

                  <el-button
                    icon="el-icon el-icon-copy-document "
                    size="mini"
                    type="secondary"
                    @click="showDuplicateTemplateDialog(template)"
                  ></el-button>

                  <el-button
                    class="danger el-button--danger"
                    icon="el-icon el-icon-delete"
                    size="mini"
                    type="secondary"
                    @click="
                      showDeleteTemplateDialog(template.id, template.name)
                    "
                  ></el-button>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-card>
      </el-col>

      <el-col :span="!minimizeApplied ? 19 : 23">
        <el-card v-if="!currentSubcontractorTemplate">
          <div slot="header">Edit templates</div>
          <div>
            <p>Select a template on the left</p>
          </div>
        </el-card>
        <pricing-template
          v-else
          :pricing-template-id="currentSubcontractorTemplate"
          :subcontractor="subcontractor"
          @chooseSubcontractorTemplate2="fetchSubcontractorTemplate"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import pricingTemplatesApi from 'api/v2/pricingTemplates'
import PricingTemplate from 'components/PricingTemplate'
import DialogTemplate from 'components/Views/Pricing/Templates/DialogTemplate'

import {cloneDeep} from 'lodash-es'
import {logErrorMessage} from '@/js/common/util';

export default {
  name: 'PricingTab',
  components: {PricingTemplate, DialogTemplate},
  props: {
    subcontractor: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      subcontractorTemplates: [],
      currentSubcontractorTemplate: null,
      subcontractorTemplate: {},
      dialogVisible: false,
      editDialogVisible: false,
      deleteDialogVisible: false,
      subcontractorId: this.subcontractor.id,
      newTemplateName: '',
      editTemplateName: '',
      editTemplateId: null,
      deleteTemplateName: '',
      deleteTemplateId: null,
      selectedTemplate: null,
      dialogType: null,
      minimizeApplied: false,
    }
  },

  watch: {
    // currentSubcontractorTemplate(templateId) {
    //   this.fetchSubcontractorTemplate(templateId)
    // },
  },

  mounted() {
    this.fetchSubcontractorTemplates()
  },

  methods: {
    /**
     * Fetch ALL subcontractor pricing templates
     */
    fetchSubcontractorTemplates() {
      pricingTemplatesApi.index(
        (result) => {
          this.subcontractorTemplates = result
        },
        (error) => {
          logErrorMessage(error)
        },
        {
          include: ['project'],
          subcontractor_id: this.subcontractorId,
        }
      )
    },

    /**
     * Fetch a SPECIFIC subcontractor pricing templates
     */
    fetchSubcontractorTemplate(templateId) {
      pricingTemplatesApi.show(
        (result) => {
          this.subcontractorTemplate = result
        },
        (error) => {
          logErrorMessage(error)
        },
        templateId,
        {
          subcontractor_id: this.subcontractorId,
          include: ['categories', 'items'],
        }
      )
    },

    showAddTemplateDialog() {
      this.dialogType = 'add'
      this.selectedTemplate = null
    },

    showEditTemplateDialog(template) {
      this.editDialogVisible = true
      this.editTemplateName = template.name
      this.editTemplateId = template.id

      this.$nextTick(() => {
        this.$refs.editTemplateNameInput.$el.children[0].focus()
      })
    },

    showDuplicateTemplateDialog(template) {
      this.dialogType = 'duplicate'
      this.selectedTemplate = cloneDeep(template)
    },

    showDeleteTemplateDialog(templateId, templateName) {
      this.deleteDialogVisible = true
      this.deleteTemplateName = templateName
      this.deleteTemplateId = templateId
    },

    addTemplate() {
      this.dialogVisible = false
      pricingTemplatesApi.store(
        () => {
          this.fetchSubcontractorTemplates()
          this.newTemplateName = null
        },
        (error) => {
          this.$message({
            showClose: true,
            type: 'error',
            message: `Unable to add template: ${error}`,
            duration: 5000,
          })
        },
        {
          subcontractor_id: this.subcontractorId,
          name: this.newTemplateName,
        }
      )
    },

    updateTemplate() {
      pricingTemplatesApi.update(
        () => {
          this.dialogVisible = false
          this.fetchSubcontractorTemplates()
          this.$message({
            showClose: false,
            type: 'success',
            message: 'Template name updated',
            duration: 1000,
          })
        },
        (error) => {
          this.$message({
            showClose: true,
            type: 'error',
            message: `Unable to add template: ${error}`,
            duration: 5000,
          })
        },
        this.subcontractorId,
        this.editTemplateId,
        {
          name: this.editTemplateName,
        }
      )
    },

    deleteTemplate(templateId) {
      pricingTemplatesApi.delete(
        (result) => {
          if (result && result.deleted === false) {
            this.$message({
              showClose: true,
              type: 'error',
              message:
                'Unable to delete template, other items are linked to it',
              duration: 2000,
            })
            return
          }
          this.deleteDialogVisible = false
          this.$set(
            this,
            'subcontractorTemplates',
            this.subcontractorTemplates.filter(
              (template) => template.id !== templateId
            )
          )
          this.$message({
            showClose: false,
            type: 'success',
            message: 'Template deleted deleted',
            duration: 1000,
          })
        },
        (error) => {
          logErrorMessage(error)
          this.$message({
            showClose: true,
            type: 'error',
            message: 'Unable to delete pricing category',
            duration: 2000,
          })
        },
        this.deleteTemplateId,
        {subcontractor_id: this.subcontractorId}
      )
    },

    minimizeTemplateWindow() {
      this.minimizeApplied = !this.minimizeApplied;
    },

    maximizeTemplateWindow() {
      this.minimizeApplied = !this.minimizeApplied;
    }
  },
}
</script>

<style lang="scss" scoped>
.key,
.value {
  height: 36px;
  line-height: 36px;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:hover {
    background: rgba(15, 126, 190, 0.2);
  }
}

.el-radio {
  margin-left: 0px;
  margin-right: 0px;
  line-height: 1.4;
  white-space: normal;
  padding:5px;
}

.template {
  display: flex;
  align-items: center;
}

.templates {
  .el-button + .el-button {
    margin-left: 4px;
  }
}
.hidden {
  display:none;
}
</style>

<style lang="scss">
#template-selector, #template-details {
  .el-card__body {
    padding:10px!important;
  }
  .el-card__header {
    padding:4px 10px!important;
  }
}
.nobeforeorafter .el-dialog .el-dialog__header {
  display: flex;
  justify-content: space-between;

  &:after {
    display: none;
  }

  &:before {
    display: none;
  }
}

.minimized-template-window {
  margin: 3% -3% 0% -3%;

  .el-card.is-always-shadow {
    transform: rotate(90deg);

    // Styles for the element when not hovered
    transition: transform 0.3s ease;
    &:hover {
      cursor:pointer;
    }

    // Target the child divs with classes el-col-col-14 and el-col-el-col-10
    .el-card__body {
      display: none;
    }
  }
}

</style>
