<template>
  <ProjectMetaReportTable />
</template>

<script>
import ProjectMetaReportTable from './ProjectMetaReportTable'

export default {
  name: 'ProjectMeta',
  components: {
    ProjectMetaReportTable,
  },
}
</script>
