<template>
  <div
    v-if="canView"
    class="projects-table"
  >
    <el-row :gutter="24" class="header-action-row">
      <el-col :span="24">
        <el-button
          v-if="canCreate"
          type="primary"
          size="medium"
          @click="addItem"
        >
          Add Project
        </el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="24">
        <DataTableAlternate
          ref="projectsTable"
          table-name="currentProjectsTable"
          :api="projectsDataApi()"
          :data-table-options="filteredTableOptions"
          :request-data="dataTableRequestData"
          :row-actions="rowActions"
          :field-category-preset="['project', 'bid_management', 'controlling', 'engineering', 'other', 'general']"
          :field-category-labels="{project:'Project', bid_management:'Bid Management', controlling:'Controlling', engineering:'Engineering', other:'Other', general:'General'}"
          :field-category-defaults="['project']"
          @row-action-editItem="editItem"
          @row-action-exportToZip="exportToZip"
          @cell-dblclick="editItem"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import projectsApi from 'api/v2/projects'
import DataTableAlternate from '@/js/components/Common/DataTableAlternate.vue';
import {can, formatErrorMessage} from '@/js/common/util';
import {AxiosError} from 'axios';

export default {
  name: 'ProjectsList',

  components: {
    DataTableAlternate
  },

  data() {
    return {
      projects: [],
      dataTableOptions: {
        showRefresh: false,
        allowSelection: false,
        tableHeightOffset: 180,
        columnWidth: {
          _rowActions: 100,
          id: 80,
          name: 250,
          customer: 250,
          invoice_template: 140,
        }
      },
      dataTableRequestData: {
        include: ['projectManagerContact', 'projectLeadContact', 'customer']
      }
    }
  },
  computed: {
    filteredTableOptions() {
      return JSON.parse(JSON.stringify(this.dataTableOptions))
    },
    rowActions() {
      let rowActions = {}
      rowActions.editItem = {
        label: false,
        icon: 'el-icon el-icon-edit',
        type: 'primary'
      }
      if (can('delete', 'project')){
        rowActions.archiveItem = {
          label: false,
          icon: 'el-icon el-icon-time',
          type: 'warning',
          emit: false
        }
      }
      rowActions.exportToZip = {
        label: false,
        icon: 'el-icon el-icon-download',
        type: 'default'
      }
      return rowActions;
    },
    canCreate() {
      return can('create', 'project')
    },
    canView() {
      return can('view', 'project')
    },
  },
  created() {
    if (!this.canView) {
      this.$router.replace('/position-validation/subcontractor')
    }
  },
  methods: {
    editItem(row) {
      this.$router.push('/projects/' + row.id)
    },
    exportToZip(row) {
      this.$refs.projectsTable.loading = true
      projectsApi.exportToZip(
        (data) => {
          this.$refs.projectsTable.loading = false
          if(data.data.url) {
            window.location = data.data.url
          } else {
            this.$message({
              message: 'Cannot generate a download',
              type: 'error',
            })
          }
        },
        (error) => {
          this.$refs.projectsTable.loading = false
          let msg = 'There was an error'
          //Handle error for responseType: 'arraybuffer'
          if (error instanceof AxiosError && error.response) {
            msg = JSON.parse(new TextDecoder().decode(error.response.data)).message;
          } else {
            msg = formatErrorMessage(error)
          }

          this.$message({
            message: msg,
            type: 'error',
          })
        },
        row.id,
      )

    },
    addItem() {
      this.$router.push('/projects/add')
    },
    projectsDataApi() {
      return projectsApi
    },
  },
}
</script>
