<template>
  <div>
    <el-row
      v-if="isSuperAdmin || !item.executorPositionValidatedAt"
      :gutter="20" class="header-action-row">
      <el-col :span="18">
        <el-button type="primary" @click="addDialogVisible = true">
          Add Item
        </el-button>
        <div class="grid-content bg-purple" />
      </el-col>
      <el-col :span="6" />
    </el-row>
    <div class="wrap-table">
      <PositionItemsTable
        :items="data"
        :position="item"
        :loading="loading"
        @editItem="editItem"
        @removeItem="removeItem"
        @sort-change="changeSort"
        @filter-change="changeFilter"
      />
    </div>
    <el-pagination
      class="pagination"
      :current-page.sync="currentPage"
      :page-size="perPage"
      layout="total, prev, pager, next"
      :total="total"
      :small="true"
      :pager-count="5"
      :hide-on-single-page="true"
      :background="true"
      @current-change="pageChange"
    />

    <el-row class="position-items-revisions">
      <ItemsRevisionsTable
        v-if="showRevisions"
        :position="item"
        :loading="loading"
        @editItem="editItem"
        @removeItem="removeItem"
        @sort-change="changeSort"
        @filter-change="changeFilter"
      />
    </el-row>

    <PositionEditItemDialog
      v-if="editDialogVisible"
      :position-item="editPositionItem"
      :position="item"
      @updateItem="updateItem"
      @cancel="closeDialog"
    />

    <PositionAddItemDialog
      v-if="addDialogVisible"
      :position="item"
      @addItem="addItem"
      @cancel="closeDialog"
    />
  </div>
</template>

<script>
import positionItemApi from 'api/v2/positionItems'
import PositionItemsTable from 'components/Positions/ItemsTable'
import PositionEditItemDialog from 'components/Positions/EditItemDialog'
import PositionAddItemDialog from 'components/Positions/AddItemDialog'
import { formatErrorMessage } from 'common/util'
import projectPositionItemsApi from 'api/v2/projectPositionItems'
import ItemsRevisionsTable from 'components/Positions/ItemsRevisionsTable'

export default {
  name: 'PositionItems',

  components: {
    PositionItemsTable,
    ItemsRevisionsTable,
    PositionAddItemDialog,
    PositionEditItemDialog,
  },

  props: {
    item: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      data: [],
      addDialogVisible: false,
      editPositionItemIndex: null,
      editDialogVisible: false,
      currentPage: 1,
      total: null,
      perPage: 20,
      loading: false,
      orderProp: null,
      orderDirection: null,
      filters: null,
      showRevisions: true,
    }
  },

  computed: {
    editPositionItem() {
      return this.data[this.editPositionItemIndex]
    },
    isSuperAdmin() {
      return this.$store.getters['global/user']['role'] === 'super_admin'
    },
  },

  mounted() {
    this.loadPage(1)
  },

  methods: {
    removeItem(index) {
      positionItemApi.delete(
        () => {
          this.$message({
            type: 'success',
            message: 'Deleted',
            duration: 3000,
          })

          this.data.splice(index, 1)
          this.showRevisions = false
          this.$nextTick(() => {
            this.showRevisions = true
          })
        },
        (error) => {
          this.$message({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.item.id,
        this.data[index].id
      )
    },
    editItem(index) {
      this.editPositionItemIndex = index
      this.editDialogVisible = true
    },
    updateItem() {
      this.loadPage(this.currentPage)

      this.$message({
        type: 'success',
        message: 'Item Updated',
        duration: 3000,
      })
    },
    addItem(newItem) {
      this.data.unshift(newItem)

      this.$message({
        type: 'success',
        message: 'Item Added',
        duration: 3000,
      })


      this.showRevisions = false
      this.$nextTick(() => {
        this.showRevisions = true
      })
    },
    closeDialog() {
      this.addDialogVisible = false
      this.editDialogVisible = false
      this.editPositionItemIndex = null
    },
    pageChange(val) {
      this.loading = true
      this.currentPage = val
      this.loadPage(val)
    },
    loadPage(page) {
      if (this.item.projectId) {
        projectPositionItemsApi.index(
          (response) => {
            this.loading = false
            this.data = Object.values(response.data)
            this.total = response.meta.total
            this.perPage = response.meta.per_page
            this.showRevisions = false
            this.$nextTick(() => {
              this.showRevisions = true
            })
          },
          (error) => {
            this.loading = false
            this.$message.error({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 5000,
            })
          },
          this.item.projectId,
          this.item.id,
          {
            page,
            include: ['pricingItem'],
            orderBy: this.orderProp,
            orderDirection: this.orderDirection,
            filters: this.filters,
          }
        )
      } else {
        positionItemApi.index(
          (response) => {
            this.loading = false
            this.data = Object.values(response.data)
            this.total = response.meta.total
            this.perPage = response.meta.per_page
          },
          (error) => {
            this.loading = false
            this.$message.error({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 5000,
            })
          },
          this.item.projectId,
          this.item.id,
          {
            page,
            include: ['pricingItem'],
            orderBy: this.orderProp,
            orderDirection: this.orderDirection,
            filters: this.filters,
          }
        )
      }
    },
    changeSort({ prop, order }) {
      this.orderProp = prop
      if (order === 'ascending') {
        this.orderDirection = 'asc'
      } else if (order === 'descending') {
        this.orderDirection = 'desc'
      } else {
        this.orderDirection = null
      }
      this.pageChange(1)
    },

    changeFilter(filters) {
      this.filters = filters

      this.pageChange(1)
    },
  },
}
</script>

<style lang="scss">
.position-items-revisions {
  margin-top: 4em;
}

.positions-view {
  .pagination {
    float: right;
    margin-top: 1rem;

    &:after {
      content: '';
      clear: both;
      display: block;
    }
  }
}
</style>
