<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>Customer</span>
    </div>

    <div class="text project-details">
      <template v-if="editMode">
        <el-form
          ref="project-customer-form"
          :model="formData"
          :rules="rules"
          label-position="right"
          label-width="50%"
        >
          <el-row
            v-for="{ key, label } of projectDetails"
            :key="key"
            :gutter="20"
          >
            <el-form-item :ref="key" :label="label" :prop="key">
              <template
                v-if="
                  [
                    'external_customer_contact_email',
                    'external_customer_contact_name',
                    'internal_customer_contact_name',
                    'internal_customer_contact_email',
                  ].includes(key)
                "
              >
                <el-input v-model="formData[key]"/>
              </template>
              <template v-else-if="['customer_id'].includes(key)">
                <el-select
                  v-model="formData[key]"
                  :disabled="customersErrored"
                  :loading="customersLoading"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="customer in customers"
                    :key="customer.id"
                    :label="customer.name"
                    :value="customer.id"
                  />
                </el-select>
              </template>
              <template v-else> No type selected</template>
            </el-form-item>
          </el-row>

          <el-row>
            <el-col :span="24" class="align-right">
              <el-button @click="cancel">Cancel</el-button>
              <el-button type="primary" @click="submit">Save</el-button>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template v-else>
        <el-row
          v-for="{ key, readableValue } of projectDetails"
          :key="key"
          :gutter="20"
        >
          <template v-if="key === 'customer_id'">
            <el-col :span="24">
              {{ readableValue }}
            </el-col>
          </template>
          <template v-else-if="key === 'internal_customer_contact_name'">
            <el-col :span="24">
              <b>Internal contact</b>
            </el-col>
            <el-col :span="24">
              {{ readableValue }}
            </el-col>
          </template>
          <template v-else-if="key === 'external_customer_contact_name'">
            <el-col :span="24">
              <b>External contact</b>
            </el-col>
            <el-col :span="24">
              {{ readableValue }}
            </el-col>
          </template>
          <template v-else>
            <el-col :span="24">
              {{ readableValue }}
            </el-col>
          </template>
        </el-row>

        <el-row>
          <el-col :span="24" class="align-right">
            <el-button v-if="canUpdate" size="small" @click="editMode = true">
              Edit
            </el-button>
          </el-col>
        </el-row>
      </template>

      <!-- META FIELDS -->
      <template v-if="showMetaFields">
        <hr style="margin: 30px 0"/>
        <projectMetaFields
          :can-edit="canUpdate"
          :on-success="setProjectFields"
          :project="project"
          :project-fields="projectFields"
        />
      </template>
    </div>
  </el-card>
</template>

<script>
import {can} from 'common/util'
import {mapGetters} from 'vuex'
import {Message} from 'element-ui'

import ProjectMetaFields from './ProjectMetaFields'

export default {
  name: 'ProjectSingleOverviewCustomer',

  components: {ProjectMetaFields},

  props: {
    project: {
      type: Object,
      required: true,
    },
    projectFields: {
      type: Array,
      required: true,
    },
    setProjectFields: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      editMode: false,

      formData: {},
    }
  },

  computed: {
    ...mapGetters('projects', [
      'customers',
      'customersLoading',
      'customersErrored',
    ]),

    canUpdate() {
      return can('update', 'project')
    },

    rules() {
      const self = this

      return {
        customer_id: [
          {
            type: 'integer',
            required: true,
            message: 'Please select a customer',
            trigger: 'blur',
          },
        ],
        internal_customer_contact_name: [
          {
            validator(rule, value, cb) {
              const result =
                !self.formData['internal_customer_contact_email'] || value

              if (result) {
                cb()
              } else {
                cb(false)
              }
            },
            message:
              'When entering an internal contact, please fill in all fields',
            trigger: 'blur',
          },
        ],
        internal_customer_contact_email: [
          {
            validator(rule, value, cb) {
              const result =
                !self.formData['internal_customer_contact_name'] || value

              if (result) {
                cb()
              } else {
                cb(false)
              }
            },
            message: 'Email should be filled if a name is given',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Field should be formatted as an email',
            trigger: 'blur',
          },
        ],
        external_customer_contact_name: [
          {
            validator(rule, value, cb) {
              const result =
                !self.formData['external_customer_contact_email'] || value

              if (result) {
                cb()
              } else {
                cb(false)
              }
            },
            message:
              'When entering an external contact, please fill in all fields',
            trigger: 'blur',
          },
        ],
        external_customer_contact_email: [
          {
            validator(rule, value, cb) {
              const result =
                !self.formData['external_customer_contact_name'] || value

              if (result) {
                cb()
              } else {
                cb(false)
              }
            },
            message: 'Email should be filled if a name is given',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Field should be formatted as an email',
            trigger: 'blur',
          },
        ],
      }
    },

    projectDetails() {
      if (!this.project || Object.keys(this.project).length === 0) {
        return []
      }

      return [
        {
          key: 'customer_id',
          label: 'Customer',
          readableValue: this.project.customer
            ? this.project.customer.name
            : '',
          value: this.project.customer ? this.project.customer.id : null,
        },
        {
          key: 'internal_customer_contact_name',
          label: 'Internal contact (name)',
          readableValue: this.project.internalCustomerContactName || '-',
          value: this.project.internalCustomerContactName || '',
        },
        {
          key: 'internal_customer_contact_email',
          label: 'Internal contact (email)',
          readableValue: this.project.internalCustomerContactEmail || '-',
          value: this.project.internalCustomerContactEmail || '',
        },
        {
          key: 'external_customer_contact_name',
          label: 'External contact (name)',
          readableValue: this.project.externalCustomerContactName || '-',
          value: this.project.externalCustomerContactName || '',
        },
        {
          key: 'external_customer_contact_email',
          label: 'External contact (email)',
          readableValue: this.project.externalCustomerContactEmail || '-',
          value: this.project.externalCustomerContactEmail || '',
        },
      ]
    },

    projectPayload() {
      return {
        customer_id: this.formData.customer_id,
      }
    },

    showMetaFields() {
      return (
        this.projectFields &&
        this.projectFields.length &&
        this.projectFields.filter((pf) => pf.canEdit || pf.value).length > 0
      )
    },
  },

  watch: {
    project: {
      deep: true,
      handler() {
        this.editMode = false
      },
    },
  },

  created() {
    this.fillFormData()
    this.$store.dispatch('projects/fetchAllCustomers')
  },

  methods: {
    fillFormData() {
      const currentData = this.projectDetails.reduce((acc, {key, value}) => {
        acc[key] = value

        return acc
      }, {})

      if (this.project && this.project.id) {
        currentData.id = this.project.id
      }

      this.$set(this, 'formData', currentData)
    },

    cancel() {
      this.fillFormData()
      this.editMode = false
    },

    submit() {
      this.$refs['project-customer-form'].validate((valid) => {
        if (!valid) {
          return
        }

        let promise = Promise.resolve()

        promise = promise.then(() =>
          this.$store.dispatch('projects/saveProject', this.formData)
        )

        promise.catch(() => {
          Message.error({
            showClose: true,
            message: 'Error while saving data',
            duration: 10000,
          })
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.key,
.value {
  height: 36px;
  line-height: 36px;
}
</style>
