import { render, staticRenderFns } from "./AddPricingItem.vue?vue&type=template&id=95cae662&scoped=true&"
import script from "./AddPricingItem.vue?vue&type=script&lang=js&"
export * from "./AddPricingItem.vue?vue&type=script&lang=js&"
import style0 from "./AddPricingItem.vue?vue&type=style&index=0&id=95cae662&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95cae662",
  null
  
)

export default component.exports