export default {
  items: [],
  pnl: {},

  currentProject: {},
  currentLerf: {},
  currentPurchaseInvoice: {},
  currentPosition: {},
  currentClaim: {},

  users: [],
  usersLoading: false,
  usersErrored: false,

  customers: [],
  customersLoading: false,
  customersErrored: false,

}
