import ApiResource from '../ApiResource'

class PricingAdvancedSyncResource extends ApiResource {
  constructor() {
    super({
      name: 'pricing-advanced-sync',
    })
  }
  preview(resolve, reject, params) {
    this.post(`${this.getUrl()}/preview`, params)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const pricingAdvancedSyncApi = new PricingAdvancedSyncResource()

export default pricingAdvancedSyncApi
