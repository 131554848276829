<template>
  <el-dialog
    v-loading="loading"
    :before-close="handleClose"
    :title="dialogTitle"
    :visible.sync="showDialog"
  >
    <div v-if="approval && summary && summary.items" class="content">
      <table class="table summary">
        <tbody>
        <tr>
          <td width="120">Project:</td>
          <td>{{ approval.project.name }}</td>
        </tr>
        <tr>
          <td>Subcontractor:</td>
          <td>{{ approval.subcontractor.name }}</td>
        </tr>
        <tr>
          <td>Bauleiter:</td>
          <td>{{ approval.subcontractorContact.fullname }}</td>
        </tr>
        <tr>
          <td>Period:</td>
          <td>{{ period }}</td>
        </tr>
        </tbody>
      </table>

      <div v-if="!summary.items.length" class="no-results">
        <strong>No available items to add</strong>
      </div>

      <div v-if="summary.items.length" class="scrollable">
        <table class="table el-table position-items">
          <thead>
          <tr>
            <th width="80">Occurences</th>
            <th width="80">Reference</th>
            <th width="200">Description</th>
            <th class="text-right" width="40">Anzahl</th>
            <th class="text-right" width="70">Länge</th>
            <th class="text-right" width="70">Breite</th>
            <th class="text-right" width="70">Tiefe</th>
            <th width="30">Unit</th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in summary.items"
            :key="`${item.locationType}${item.reference}`"
          >
            <td>{{ item.occurences }}</td>
            <td>{{ item.reference }}</td>
            <td>{{ item.description }}</td>
            <td class="text-right">{{ item.amount }}</td>
            <td class="text-right">{{ item.length }}</td>
            <td class="text-right">{{ item.width }}</td>
            <td class="text-right">{{ item.height }}</td>
            <td>{{ item.unit }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">Cancel</el-button>
      <el-button
        :disabled="!summary.items || !summary.items.length"
        type="success"
        @click="addItems()"
      >Add all items
      </el-button
      >
      <el-button
        :disabled="!summary.items || !summary.items.length"
        type="primary"
        @click="addAndApproveItems()"
      >
        Add and approve all items
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import moment from 'moment'
import projectApprovalsApi from 'api/v2/projectApprovals'
import {logErrorMessage} from '../../../common/util';

export default {
  name: 'SummaryDialog',
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    approval: {
      type: Object,
      required: true,
    },
    // these hold the positions from the available items report
    positions: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
    dateStart: {
      type: Date,
      default: function() {
        return null
      },
    },
    dateEnd: {
      type: Date,
      default: function() {
        return null
      },
    },
  },
  data() {
    return {
      loading: false,
      showDialog: this.visible,
    }
  },
  computed: {
    approvalId() {
      if (!this.approval) return undefined
      return this.approval.id
    },
    dialogTitle() {
      return 'Positions summary'
    },
    period() {
      if (this.dateEnd && this.dateStart) {
        const dateStart = moment(this.dateStart.toString()).format('DD-MM-YYYY')
        const dateEnd = moment(this.dateEnd.toString()).format('DD-MM-YYYY')
        return `${dateStart} - ${dateEnd}`
      }
      return ''
    },
    positionItems() {
      if (!this.positions.length) {
        return []
      }
      const positionItems = []
      this.positions.forEach((position) => {
        position.items.forEach((positionItem) => {
          positionItems.push(positionItem)
        })
      })
      return positionItems
    },
    summary() {
      if (!this.positions.length) {
        return {}
      }
      const summary = {
        dateStart: null,
        dateEnd: null,
        positionCount: 0,
        items: [],
      }
      const positionItems = []
      this.positions.forEach((position) => {
        summary.positionCount += 1
        if (
          !summary.dateStart ||
          moment(position.startDate) < summary.dateStart
        ) {
          summary.dateStart = moment(position.startDate)
        }
        if (!summary.dateEnd || moment(position.endDate) > summary.dateEnd) {
          summary.dateEnd = moment(position.endDate)
        }

        position.items.forEach((positionItem) => {
          // skip items that have already been imported to other approvals
          if (positionItem.already_imported) {
            return
          }
          // skip items that are already on this approval
          if (
            this.approval.items.find(
              (approvalItem) =>
                approvalItem.position_item_id === positionItem.id
            )
          ) {
            return
          }
          const existingPositionItem = positionItems.find(
            (existingItem) =>
              existingItem.reference === positionItem.reference &&
              existingItem.locationType === position.locationType
          )
          if (!existingPositionItem) {
            const newItem = {
              id: positionItem.id,
              locationType: position.locationType,
              reference: positionItem.reference,
              description: positionItem.description,
              unit: positionItem.unit,
              amount: positionItem.amount || 0,
              length: positionItem.length || 0,
              height: positionItem.height || 0,
              width: positionItem.width || 0,
              occurences: 1,
            }
            positionItems.push(newItem)
          } else {
            Object.assign(existingPositionItem, existingPositionItem, {
              amount: existingPositionItem.amount + positionItem.amount,
              length: existingPositionItem.length + positionItem.length,
              height: existingPositionItem.height + positionItem.height,
              width: existingPositionItem.width + positionItem.width,
              occurences: existingPositionItem.occurences + 1,
            })
          }
        })
      })
      summary.items = positionItems.map((item) => {
        return Object.assign(item, {
          amount: item.amount ? item.amount.toFixed(0) : null,
          width: item.width ? item.width.toFixed(2) : null,
          length: item.length ? item.length.toFixed(2) : null,
          height: item.height ? item.height.toFixed(2) : null,
        })
      })
      return summary
    },
  },
  watch: {
    visible(value) {
      this.showDialog = value
    },
  },
  methods: {
    closeDialog() {
      this.$emit('update:visible', false)
    },
    handleClose(done) {
      this.closeDialog()
      if (done && typeof done === 'function') {
        done()
      }
    },
    addAndApproveItems() {
      this.addItems(true)
    },
    addItems(approve = false) {
      const positionItemIds = this.positionItems.reduce((acc, positionItem) => {
        if (!positionItem.already_imported) {
          acc.push(positionItem.id)
        }
        return acc
      }, [])
      this.loading = true
      this.$nextTick(() => {
        projectApprovalsApi.addItems(
          (response) => {
            this.$emit('approval-updated', response)
            let successMessage
            if (approve) {
              successMessage = `Successfully added and approved ${response.items.length} items`
            } else {
              successMessage = `Successfully added ${response.items.length} items`
            }
            this.$nextTick(() => {
              this.loading = false
              this.closeDialog()
            })
            setTimeout(() => {
              this.$message.success({
                showClose: true,
                message: successMessage,
                duration: 5000,
              })
            }, 500)
          },
          (error) => {
            logErrorMessage(error)
          },
          this.projectId,
          this.approvalId,
          {approve, positionItemIds}
        )
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.text-right {
  text-align: right;
}

table {
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;

  tbody tr td {
    height: 33px;
  }

  thead tr th,
  tbody tr td {
    padding: 1px 4px 2px 4px;
  }

  &.summary {
    width: 300px;
    margin-bottom: 20px;

    tbody tr td {
      height: 22px;
      padding: 0;
    }
  }

  &.position-items {
  }
}
</style>
