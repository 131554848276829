import ApiResource from '../ApiResource'

class LerfReportResource extends ApiResource {
  constructor() {
    super({
      name: 'lerf-item-report',
      capabilityCategory: 'lerf',
    })
  }

  export(resolve, reject, params) {
    this.get(`${this.getUrl()}/export`, { params: params })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const lerfReportApi = new LerfReportResource()

export default lerfReportApi
