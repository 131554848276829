<template>
  <div>
    <el-card class="subcategories-view">
      <div slot="header" class="clearfix">
        <el-select
          v-model="subcontractorId"
          class="subcontractor-select"
          placeholder="Select subcontractor"
          @change="fetchPricingSubcategories"
        >
          <el-option-group label="Soli Infratechnik">
            <el-option :value="null" label="Internal pricing"/>
          </el-option-group>
          <el-option-group label="Subcontractors">
            <el-option
              v-for="subcontractor in subcontractors"
              :key="subcontractor.id"
              :label="subcontractor.name"
              :value="subcontractor.id"
            >
            </el-option>
          </el-option-group>
        </el-select>

        <el-select
          v-model="areaSelection"
          class="area-select"
          placeholder="Select area type"
        >
          <el-option
            v-for="areaSelectionOption in areaSelectionOptions"
            :key="areaSelectionOption"
            :label="areaSelectionOption | capitalize"
            :value="areaSelectionOption"
          >
          </el-option>
        </el-select>

        <el-button
          size="small"
          style="float: right"
          type="primary"
          @click="addSubcategory"
        >
          Add category
        </el-button>
      </div>

      <el-tree
        v-loading.body="loading"
        :data="nestedSubcategories"
        :expand-on-click-node="true"
        :props="defaultProps"
        :render-content="renderContent"
        class="category-tree"
        default-expand-all
      />
    </el-card>
    <add-edit-subcategory-dialog
      :area-selection="areaSelection"
      :category-name="categoryName"
      :subcategory="subcategory"
      :subcontractor-id="subcontractorId"
      :visible.sync="showAddCategoryDialog"
      @change="fetchPricingSubcategories"
    />
  </div>
</template>

<script>
import {cloneDeep} from 'lodash-es'
import pricingSubcategoriesApi from 'api/v2/pricingSubcategories'
import subcontractorsApi from 'api/v2/subcontractors'
import AddEditSubcategoryDialog from './AddEditSubcategoryDialog'
import CategoryNode from './CategoryNode'
import SubcategoryNode from './SubcategoryNode'
import {logErrorMessage} from '../../../../common/util';

export default {
  name: 'SubcategoriesView',

  components: {
    AddEditSubcategoryDialog,
  },

  filters: {
    capitalize(string) {
      if (typeof string !== 'string') return ''
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
  },


  data() {
    return {
      categoryName: null, // used when creating a new subcategory
      subcategory: null, // used when updating a subcategory
      subcontractorId: null, // used when creating or updating a subcategory
      subcontractors: [],
      subcategories: [],
      areaSelection: 'street',
      areaSelectionOptions: ['street', 'mfg', 'muffe'],
      fetchingSubcategories: false,
      fetchingSubcontractors: false,
      showAddCategoryDialog: false,

      defaultProps: {
        children: 'children',
        label: 'label',
      },
    }
  },

  computed: {
    loading() {
      return this.fetchingSubcategories || this.fetchingSubcontractors
    },
    nestedSubcategories() {
      const nestedSubcategories = []
      if (!this.subcategories.length) {
        return nestedSubcategories
      }
      this.subcategories.forEach((subcategory) => {
        if (subcategory.areaSelection !== this.areaSelection) {
          return
        }
        let mainCategory = nestedSubcategories.find(
          (nestedSubcategory) =>
            nestedSubcategory.label === subcategory.categoryName
        )
        if (!mainCategory) {
          mainCategory = {
            id: subcategory.categoryName,
            label: subcategory.categoryName,
            children: [],
          }
          nestedSubcategories.push(mainCategory)
        }
        mainCategory.children.push({
          id: subcategory.id,
          label: subcategory.subcategoryName,
          costUnit: subcategory.costUnit,
        })
      })
      return nestedSubcategories
    },
  },

  mounted() {
    this.fetchSubcontractors()
    this.fetchPricingSubcategories()
  },
  methods: {
    fetchSubcontractors() {
      this.fetchingSubcontractors = true
      subcontractorsApi.index(
        (response) => {
          this.fetchingSubcontractors = false
          this.subcontractors = response
        },
        (error) => {
          logErrorMessage(error)
        }
      )
    },
    fetchPricingSubcategories() {
      this.fetchingSubcategories = true
      pricingSubcategoriesApi.index(
        (result) => {
          this.fetchingSubcategories = false
          this.$set(this, 'subcategories', result)
        },
        (error) => {
          logErrorMessage(error)
        },
        {subcontractor_id: this.subcontractorId}
      )
    },
    addSubcategory(categoryName) {
      this.$set(this, 'subcategory', null)
      if (categoryName && typeof categoryName === 'string') {
        this.$set(this, 'categoryName', categoryName)
      } else {
        this.$set(this, 'categoryName', null)
      }
      this.showAddCategoryDialog = true
    },
    editSubcategory(pricingSubcategoryId) {
      this.$set(
        this,
        'subcategory',
        cloneDeep(
          this.subcategories.find(
            (subcategory) => subcategory.id === pricingSubcategoryId
          )
        )
      )
      this.$nextTick(() => {
        this.showAddCategoryDialog = true
      })
    },
    deleteSubcategory(pricingSubcategoryId) {
      pricingSubcategoriesApi.delete(
        (response) => {
          if (response) {
            this.$message.success({
              showClose: true,
              message: 'Category has been removed has been removed',
              duration: 5000,
            })
            this.$set(
              this,
              'subcategories',
              this.subcategories.filter(
                (subcategory) => subcategory.id !== response
              )
            )
          }
        },
        (error) => {
          this.$message.info({
            showClose: true,
            message: error.message,
            duration: 5000,
          })
          logErrorMessage(error)
        },
        pricingSubcategoryId
      )
    },
    renderContent(createElement, {node, data}) {
      if (node.level === 1) {
        return createElement(CategoryNode, {
          props: {category: data},
          on: {add: this.addSubcategory},
        })
      }
      if (node.level === 2) {
        return createElement(SubcategoryNode, {
          props: {category: data},
          on: {
            edit: this.editSubcategory,
            delete: this.deleteSubcategory,
          },
        })
      }
      return createElement('not supported')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-card__header {
  padding-top: 10px;
  height: 50px;
}

.subcategories-view {
  font-size: 12px;
  width: 706px;
}

.category-tree {
  padding: 10px;
  width: 664px;
  height: calc(100vh - 172px);
  overflow-y: auto;
}

.subcontractor-select {
  width: 300px;
}

.area-select {
  width: 100px;
  margin-left: 10px;
}

::v-deep .el-tree-node__content {
  line-height: 30px;
  height: 30px;
  display: flex;

  .el-row {
    width: 100%;
  }
}

::v-deep .el-tree-node__expand-icon {
  margin: 8px;
}

::v-deep .el-tree > .el-tree-node {
  border-top: 1px solid #324157;
  padding-top: 5px;
  margin-top: 5px;

  &:first-child {
    border-top: none;
    padding-top: 0;
    margin-top: 0;
  }
}

::v-deep .category-node .buttons button {
  max-width: 240px;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}

::v-deep .category-node,
::v-deep .subcategory-node {
  width: 576px;
  display: inline-flex;

  .buttons {
    text-align: right;
  }
}
</style>
