<template>
  <div class="single-approval-view">
    <el-row>
      <el-col :span="18">
        <template v-if="!isApproved">
          <el-button
            :disabled="!approvedItemsCount"
            size="medium"
            type="primary"
            @click="finalizeApproval"
          >
            Finalize
          </el-button>
          <el-button size="medium" type="danger" @click="deleteApproval">
            Delete
          </el-button>
          <el-button size="medium" type="primary" @click="downloadApproval">
            Preview
          </el-button>
        </template>
        <template v-else>
          <el-button type="primary" @click="emailApproval">
            <template v-if="!approval.subcontractorContact">
              Email to contact
            </template>
            <template v-else>
              Email to {{ approval.subcontractorContact.email }}
            </template>
          </el-button>
          <el-button type="primary" @click="downloadApproval">
            Download
          </el-button>
        </template>
      </el-col>
      <el-col :span="6" class="status">
        <el-tag>{{ approval.status }}</el-tag>
      </el-col>
    </el-row>
    <el-card v-loading.body="fetchingApproval" class="approval-details">
      <div slot="header">Approval details</div>

      <template v-if="fetchingApprovalError">
        Error while loading data
      </template>
      <template v-else-if="!fetchingApproval">
        <el-row :gutter="20">
          <el-col :span="8">
            <span class="subcontractor">{{ approval.subcontractor.name }}</span>
            <br/>
            <span class="subcontractor-contact">
              {{ approval.subcontractorContact.fullname }}
            </span>
            <br/>
            <br/>
            <span class="creator">
              <template v-if="approval.createdByContact">
                <strong>Created by</strong>
                <br/>
                {{ approval.createdByContact.fullname }}
              </template>
            </span>
          </el-col>
          <el-col :span="8" class="approval-title">
            {{ approval.project.name }}
          </el-col>
          <el-col :span="8" class="approval-meta">
            <span class="reference">
              {{ !isApproved ? 'Concept' : '' }}
              {{ approval.reference }}
            </span>
            <br/>
            <br/>
            <span class="approver">
              <template v-if="approval.approvedByContact">
                <strong>Approved by</strong>
                <br/>
                {{ approval.approvedByContact.fullname }}
              </template>
            </span>
            <br/>
            <span class="date">
              {{ approval.approvedAt }}
            </span>
          </el-col>
        </el-row>
      </template>
    </el-card>

    <el-card v-if="approval && !fetchingApproval" class="approved-items">
      <div slot="header">
        <span>Items on this approval</span>
        <template v-if="!isApproved">
          <el-button
            :disabled="!reportItems.length"
            class="quick-select-button pull-right"
            @click="showAvailableItemsSummary"
          >
            Quick select
          </el-button>
          <el-button
            :disabled="!approval.items.length"
            class="reset-button pull-right"
            style="margin-right: 10px"
            type="secondary"
            @click="resetItems"
          >
            Reset
          </el-button>
        </template>
      </div>

      <template v-if="Object.values(processedItems).length">
        <el-row v-for="(item, key) in processedItems" :key="key" :gutter="20">
          <el-col :span="24">
            <div class="intro">
              <h3>
                <template v-if="item.location">
                  {{ item.location }}
                </template>
              </h3>
            </div>

            <el-table
              :data="item.items"
              :highlight-current-row="true"
              :row-class-name="getApprovedItemRowClassName"
              size="mini"
              stripe
              style="width: 100%"
            >
              <el-table-column
                :show-overflow-tooltip="true"
                label="Date"
                width="100"
              >
                <!-- eslint-disable vue/no-unused-vars -->
                <template slot-scope="scope">
                  {{ scope.row.created_at.split(' ')[0] }}
                </template>
                <!-- eslint-enable -->
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="true"
                label="Reference"
                prop="reference"
                width="100"
              />
              <el-table-column
                :show-overflow-tooltip="true"
                label="Description"
                min-width="250"
                prop="description"
              />
              <el-table-column
                :show-overflow-tooltip="true"
                align="right"
                label="Länge/Anzahl"
                width="120"
              >
                <template slot-scope="scope">
                  {{ scope.row.length ? scope.row.length : scope.row.amount }}
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="true"
                align="right"
                label="Breite"
                prop="width"
                width="65"
              />
              <el-table-column
                :show-overflow-tooltip="true"
                align="right"
                label="Tiefe"
                prop="height"
                width="65"
              />
              <el-table-column
                :show-overflow-tooltip="true"
                align="right"
                label="Mult."
                prop="factor"
                width="65"
              />
              <el-table-column
                :show-overflow-tooltip="true"
                align="right"
                label="Menge"
                prop="menge"
                width="100"
              />
              <el-table-column
                :show-overflow-tooltip="true"
                label="Unit"
                prop="unit"
                width="60"
              />
              <el-table-column
                :show-overflow-tooltip="true"
                label="Comments"
                prop="comments"
                width="250"
              />
              <el-table-column
                :show-overflow-tooltip="true"
                label="Status"
                width="100"
              >
                <template slot-scope="scope">
                  <template v-if="scope.row.approved === null">
                    Undecided
                  </template>
                  <template v-else-if="scope.row.approved"> Accepted</template>
                  <template v-else> Rejected</template>
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="true"
                align="right"
                label=""
                width="150"
              >
                <template slot-scope="scope">
                  <el-button-group v-if="!isApproved">
                    <el-button
                      :disabled="scope.row.approved"
                      icon="el-icon el-icon-check"
                      size="mini"
                      title="Approve"
                      type="primary"
                      @click="approveItem(scope.row)"
                    />
                    <el-button
                      :disabled="scope.row.approved === null"
                      icon="el-icon el-icon-circle-close"
                      size="mini"
                      title="Reject"
                      type="warning"
                      @click="setUndecidedItem(scope.row)"
                    />
                    <el-button
                      :disabled="scope.row.approved === false"
                      icon="el-icon el-icon-close"
                      size="mini"
                      title="Reject"
                      type="danger"
                      @click="rejectItem(scope.row)"
                    />
                  </el-button-group>
                  <el-button-group v-if="!isApproved">
                    <el-button
                      icon="fa fa-unlink"
                      size="mini"
                      title="Remove from approval"
                      type="warning"
                      @click.prevent.stop="removeItem(scope.row)"
                    />
                  </el-button-group>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </template>
      <template v-else>
        No items yet. Try selecting items from below.
      </template>
    </el-card>

    <el-card v-if="!isApproved" class="available-items">
      <div slot="header">Available Items</div>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-date-picker
            v-model="startDate"
            :disabled="!!approval.startDate"
            clearable
            placeholder="Pick a start date..."
            style="max-width: 140px"
            type="date"
          />

          <el-date-picker
            v-model="endDate"
            :disabled="!!approval.endDate"
            placeholder="Pick an end date..."
            style="max-width: 140px"
            type="date"
          />

          <el-select
            v-model="selectedAreaId"
            clearable
            placeholder="Select area..."
            @input="selectedLocationTypeAndId = null"
          >
            <el-option
              v-for="area in areas"
              :key="area.id"
              :label="area.name"
              :value="area.id"
            />
          </el-select>

          <el-select
            v-model="selectedLocationTypeAndId"
            clearable
            filterable
            placeholder="Select location..."
          >
            <template v-for="locationType in ['streets', 'mfgs', 'muffen']">
              <el-option-group
                v-if="locations && locations[locationType]"
                :key="locationType"
                :label="locationType"
              >
                <el-option
                  v-for="location in locations[locationType]"
                  :key="location.id"
                  :label="location.name"
                  :value="locationType + '-' + location.id"
                />
              </el-option-group>
            </template>
          </el-select>

          <el-button
            :disabled="!reportItems.length"
            class="quick-select-button pull-right"
            @click="showAvailableItemsSummary"
          >
            Quick select
          </el-button>
        </el-col>
      </el-row>

      <template v-if="reportItemsFiltered.length">
        <el-row
          v-for="(item, key) in reportItemsFiltered"
          :key="key"
          :gutter="20"
        >
          <el-col :span="24">
            <div class="intro">
              <h2>
                Positions {{ item.id }}
                <small>
                  ({{ item.startDate | shortDate }} -
                  {{ item.endDate | shortDate }})
                </small>
              </h2>
              <h3>
                <template v-if="item.location.area && item.location.area.name">
                  {{ item.location.area.name }}
                </template>
                <template v-if="item.location.name">
                  - {{ item.location.name }}
                </template
                >
              </h3>
            </div>

            <div v-if="item.media && item.media.length" class="media">
              <media-item
                v-for="file in item.media"
                :id="file.id"
                :key="file.id"
                :filename="file.file_name"
                :mime-type="file.mime_type"
                :name="file.name"
                :thumbnail-url="file.thumbnail_url"
                :url="file.url"
                @show-media-dialog="showImageDialog(file)"
              />
            </div>

            <el-table
              :data="item.items"
              :highlight-current-row="true"
              :row-class-name="getAvailableItemRowClassName"
              size="mini"
              stripe
              style="width: 100%"
            >
              <el-table-column
                :show-overflow-tooltip="true"
                label="Category"
                width="200"
              >
                <template slot-scope="scope">
                  {{ item.pricingCategory.name }}
                  <template v-if="item.pricingSubcategory">
                    / {{ item.pricingSubcategory.subcategoryName }}
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="true"
                label="Location"
                prop="location.area.name"
                width="200"
              />
              <el-table-column
                :show-overflow-tooltip="true"
                label="Reference"
                prop="reference"
                width="100"
              />
              <el-table-column
                :show-overflow-tooltip="true"
                label="Description"
                min-width="250"
                prop="description"
              />
              <el-table-column
                :show-overflow-tooltip="true"
                align="right"
                label="Länge/Anzahl"
                width="120"
              >
                <template slot-scope="scope">
                  {{ scope.row.length ? scope.row.length : scope.row.amount }}
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="true"
                align="right"
                label="Breite"
                prop="width"
                width="65"
              />
              <el-table-column
                :show-overflow-tooltip="true"
                align="right"
                label="Tiefe"
                prop="height"
                width="65"
              />
              <el-table-column
                :show-overflow-tooltip="true"
                align="right"
                label="Menge"
                prop="menge"
                width="100"
              />
              <el-table-column
                :show-overflow-tooltip="true"
                label="Unit"
                prop="unit"
                width="50"
              />
              <el-table-column
                :show-overflow-tooltip="true"
                label="Comments"
                prop="comments"
                width="250"
              />
              <el-table-column
                :show-overflow-tooltip="true"
                label=""
                width="40"
              >
                <template slot-scope="scope">
                  <el-button-group>
                    <el-button
                      :disabled="availableItemDisabled(scope.row)"
                      :title="
                        availableItemDisabled(scope.row)
                          ? 'Already imported'
                          : 'Import item'
                      "
                      icon="upload2"
                      size="mini"
                      type="primary"
                      @click="createFromReportItem(scope.row)"
                    />
                  </el-button-group>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </template>
      <template v-else-if="reportItemsFiltered === [] && !fetchingApproval">
        No results
      </template>
    </el-card>
    <media-dialog
      :media-item="currentMediaItem"
      :visible.sync="mediaDialogVisible"
    />
    <summary-dialog
      :approval="approval"
      :date-end="endDate"
      :date-etart="startDate"
      :positions="reportItemsFiltered"
      :project-id="projectId"
      :visible.sync="summaryDialogVisible"
      @approval-updated="handleApprovalUpdated"
    />
  </div>
</template>

<script>
import {Message} from 'element-ui'
import {formatDateForRequest, formatErrorMessage, logErrorMessage} from '../../../common/util'
import snakeCaseKeys from 'snakecase-keys'
import projectApprovalsApi from 'api/v2/projectApprovals'
import projectApprovalItemsApi from 'api/v2/projectApprovalItems'
import approvalsApi from 'api/v2/approvals'

import MediaItem from 'components/Common/MediaItem'
import MediaDialog from 'components/Common/MediaDialog.vue'
import {cloneDeep} from 'lodash-es'
import SummaryDialog from './SummaryDialog'
import moment from 'moment'

export default {
  name: 'SingleApprovalsView',

  components: {
    MediaItem,
    MediaDialog,
    SummaryDialog,
  },

  filters: {
    shortDate(dateString) {
      if (!dateString.toString()) {
        return dateString
      }
      return moment(dateString).format('DD-MM-YYYY')
    },
  },

  data() {
    return {
      fetchingApproval: true,
      fetchingApprovalError: null,
      approval: {},

      startDate: null,
      endDate: null,

      reportItems: [],

      selectedAreaId: null,
      selectedLocationTypeAndId: null,

      currentMediaItem: {},
      mediaDialogVisible: false,
      summaryDialogVisible: false,
    }
  },

  computed: {
    projectId() {
      return parseInt(this.$route.params.projectId, 10)
    },

    approvalId() {
      return parseInt(this.$route.params.approvalId, 10)
    },

    isApproved() {
      return this.approval.status === 'Approved'
    },

    processedItems() {
      const processedItems = this.approval.items.reduce(
        (acc, item) => {
          const positionId = item.position ? item.position.id : 0

          if (item.position && !acc[positionId]) {
            acc[item.position.id] = {
              position: item.position,
              location: '',
              items: [],
            }
          }

          acc[positionId].location = item.location
          acc[positionId].items.push(item)

          return acc
        },
        {
          0: {
            position: null,
            items: [],
          },
        }
      )

      if (processedItems[0].items.length === 0) {
        delete processedItems[0]
      }

      return processedItems
    },

    importedPositionItemIds() {
      if (!this.approval || !this.approval.items) {
        return []
      }

      return this.approval.items.map((item) => item.position_item_id)
    },

    approvedItemsCount() {
      if (!this.approval.items) return null
      return this.approval.items.reduce((acc, item) => {
        if (item.approved === true) acc++
        return acc
      }, 0)
    },
    undecidedItemsCount() {
      if (!this.approval.items) return null
      return this.approval.items.reduce((acc, item) => {
        if (item.approved === null) acc++
        return acc
      }, 0)
    },
    deniedItemsCount() {
      if (!this.approval.items) return null
      return this.approval.items.reduce((acc, item) => {
        if (item.approved === false) acc++
        return acc
      }, 0)
    },
    reportItemsFiltered() {
      return this.reportItems.filter((item) => {
        if (
          this.selectedAreaId &&
          item.location.area.id !== this.selectedAreaId
        ) {
          return false
        }

        if (this.selectedLocationTypeAndId) {
          const [selectedLocationType, selectedLocationId] =
            this.selectedLocationTypeAndId.split('-')

          if (item.location.id !== parseInt(selectedLocationId, 10)) {
            return false
          }

          switch (selectedLocationType) {
          case 'streets':
            return item.locationType === 'Soli\\DB\\Models\\Street'
          case 'mfgs':
            return item.locationType === 'Soli\\DB\\Models\\Mfg'
          case 'muffen':
            return item.locationType === 'Soli\\DB\\Models\\Muffe'
          default:
            return true
          }
        }

        return true
      })
    },
    areas() {
      const areas = []

      this.reportItems.forEach((item) => {
        if (!areas.find((a) => a.id === item.location.area.id)) {
          areas.push(item.location.area)
        }
      })
      return areas
    },
    locations() {
      const locations = {
        streets: [],
        mfgs: [],
        muffen: [],
      }

      this.reportItems.forEach((item) => {
        let locationType

        switch (item.locationType) {
        case 'Soli\\DB\\Models\\Street':
          locationType = 'streets'

          break
        case 'Soli\\DB\\Models\\Mfg':
          locationType = 'mfgs'
          locations.mfgs.push(item.location)
          break
        case 'Soli\\DB\\Models\\Muffe':
          locationType = 'muffen'
          break
        }
        if (!locations[locationType].find((l) => l.id === item.location.id)) {
          if (
            !this.selectedAreaId ||
            item.location.area.id === this.selectedAreaId
          ) {
            locations[locationType].push(item.location)
          }
        }
      })
      return locations
    },
  },

  watch: {
    '$route.params': {
      deep: true,
      immediate: true,
      handler() {
        this.fetchApproval()
      },
    },
  },

  mounted() {
  },

  methods: {
    showImageDialog(item) {
      this.currentMediaItem = cloneDeep(item)
      this.mediaDialogVisible = true
    },
    availableItemDisabled(row) {
      return (
        this.importedPositionItemIds.includes(row.id) || row.already_imported
      )
    },

    handleApprovalUpdated(approval) {
      this.$set(this, 'approval', Object.assign({}, this.approval, approval))
    },

    fetchApproval() {
      projectApprovalsApi.show(
        (approval) => {
          this.approval = approval
          this.fetchingApproval = false
          this.endDate = this.endDate || approval.endDate
          this.startDate = this.startDate || approval.startDate
          this.fetchReport()
        },
        (error) => {
          let errorMessage

          this.fetchingApprovalError = true
          this.fetchingApproval = false

          if (error && error.message) {
            errorMessage = error.message
          } else {
            errorMessage = 'Error occurred'
          }

          Message.error({
            showClose: true,
            message: errorMessage,
            duration: 10000,
          })
        },
        this.projectId,
        this.approvalId,
        {
          include: [
            'project',
            'subcontractor',
            'subcontractorContact',
            'createdByContact',
            'approvedByContact',
            'items',
            'items.position',
          ],
        }
      )
    },

    fetchReport() {
      if (!this.approval || !this.approval.projectId) {
        return
      }
      projectApprovalsApi.positions(
        (items) => {
          this.reportItems = items
        },
        (error) => {
          throw error
        },
        this.approval.projectId,
        this.approval.id,
        {
          startDate: formatDateForRequest(this.startDate),
          endDate: formatDateForRequest(this.endDate),
        }
      )
    },

    approveItem(item) {
      this.updateItem(item, {
        approved: true,
      })
    },

    setUndecidedItem(item) {
      this.updateItem(item, {
        approved: null,
      })
    },

    rejectItem(item) {
      this.updateItem(item, {
        approved: false,
      })
    },

    createFromReportItem(item) {
      this.createItem({
        position_item_id: item.id,
        description: item.description,
        amount: item.amount,
        length: item.length,
        width: item.width,
        height: item.height,
        factor: item.factor,
        unit: item.unit,
        comments: item.comments,
        reference: item.reference,
        invoiceStatus: item.invoiceStatus,
      })
    },

    getApprovedItemRowClassName(row) {
      return Object.entries({
        'approval-pending': row.approved === null,
        'approval-approved': row.approved,
        'approval-rejected': row.approved === false,
      })
        .reduce((acc, [key, bool]) => {
          if (bool) {
            acc.push(key)
          }

          return acc
        }, [])
        .join(' ')
    },

    getAvailableItemRowClassName(row) {
      const isImported = this.availableItemDisabled(row)

      return Object.entries({
        'available-item': !isImported,
        'imported-item': isImported,
      })
        .reduce((acc, [key, bool]) => {
          if (bool) {
            acc.push(key)
          }

          return acc
        }, [])
        .join(' ')
    },

    /**
     * @param payload You basically call this with data in a position item + the id of the position item as positionItemId
     */
    createItem(payload) {
      return projectApprovalItemsApi.store(
        (item) => {
          Message.success({
            showClose: true,
            message: 'Item added',
            duration: 1000,
          })

          this.approval.items.push(snakeCaseKeys(item))
        },
        () => {
          Message.error({
            showClose: true,
            message:
              'Could not add to approval. Has it already been added to a different approval?',
            duration: 5000,
          })
        },
        this.projectId,
        this.approvalId,
        payload
      )
    },

    updateItem(item, payload) {
      return projectApprovalItemsApi.update(
        (item) => {
          Message.success({
            showClose: true,
            message: 'Item updated',
            duration: 3000,
          })

          const existingItem = this.approval.items.find(
            (approvalItem) => approvalItem.id === item.id
          )
          if (!existingItem) {
            // eslint-disable-next-line
            console.info(
              'We believed to have all items loaded here, so why didn\'t we find the item with id',
              item.id
            )
            this.approval.items.push(item)
          } else {
            // Update
            for (const key of Object.keys(item)) {
              existingItem[key] = item[key]
            }
          }
        },
        (error) => {
          Message.error({
            showClose: true,
            message: error.message,
            duration: 10000,
          })
        },
        this.projectId,
        this.approvalId,
        item.id,
        payload
      )
    },

    removeItem(item) {
      return projectApprovalItemsApi.delete(
        () => {
          const index = this.approval.items.findIndex(
            (approvalItem) => approvalItem.id === item.id
          )
          if (index === -1) {
            // eslint-disable-next-line
            console.info(
              'A deleted item is not present on its parent while it should'
            )
          } else {
            this.approval.items.splice(index, 1)
          }
        },
        (error) => {
          Message.error({
            showClose: true,
            message: error,
            duration: 10000,
          })
        },
        this.projectId,
        this.approvalId,
        item.id
      )
    },

    resetItems() {
      this.$confirm(
        'Do you want to reset this approval, unlinking all items?',
        'Warning',
        {
          confirmButtonText: 'Reset',
          cancelButtonText: 'Cancel',
          type: 'warning',
          customClass: 'confirm-reset',
          confirmButtonClass: 'el-button--warnig',
        }
      )
        .then(() => {
          projectApprovalsApi.resetItems(
            (response) => {
              this.$set(
                this,
                'approval',
                Object.assign({}, this.approval, response)
              )
              Message.success({
                showClose: true,
                message: 'Approval has been reset',
                duration: 3000,
              })
            },
            (error) => {
              Message.error({
                showClose: true,
                message: error,
                duration: 10000,
              })
            },
            this.projectId,
            this.approvalId
          )
        })
        .catch(() => {
          // noop
        })
    },

    finalizeApproval() {
      if (this.undecidedItemsCount) {
        let message
        if (this.undecidedItemsCount === 1) {
          message = `There is still ${this.undecidedItemsCount} undecided item, please accept, reject or unlink it first`
        } else {
          message = `There are still ${this.undecidedItemsCount} undecided items, please accept, reject or unlink them first`
        }
        this.$confirm(message, 'Cannot finalize approval', {
          confirmButtonText: 'Close',
          cancelButtonText: 'Close',
          type: 'warning',
          confirmButtonClass: 'hidden',
        })
          .then(() => {
          })
          .catch(() => {
          })
        return
      }

      let summary = `approving ${this.approvedItemsCount} items`

      if (this.deniedItemsCount) {
        summary += ` and rejecting ${this.deniedItemsCount} items`
      }

      this.$confirm(
        `Do you want to finalize this approval, ${summary}?`,
        'Finalize approval',
        {
          confirmButtonText: 'Finalize',
          cancelButtonText: 'Cancel',
          type: 'warning',
          confirmButtonClass: 'el-button--warnig',
        }
      )
        .then(() => {
          projectApprovalsApi.approveAll(
            (response) => {
              this.$set(
                this,
                'approval',
                Object.assign({}, this.approval, response)
              )
              Message.success({
                showClose: true,
                message: 'Approval has been finalized',
                duration: 3000,
              })
            },
            () => {
              Message.error({
                showClose: true,
                message:
                  'An error occurred while approving. Please try again later.',
                duration: 10000,
              })
            },
            this.projectId,
            this.approvalId
          )
        })
        .catch(() => {
        })
    },

    downloadApproval() {
      return approvalsApi.download(
        () => {

        },
        (error) => {
          logErrorMessage(error)
          this.$message.error({
            showClose: true,
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.approvalId
      )
    },

    emailApproval() {
      return projectApprovalsApi.email(
        () => {
          Message.success({
            showClose: true,
            message: 'Email sent',
            duration: 3000,
          })
        },
        () => {
          Message.error({
            showClose: true,
            message: 'Could not send email. Please try again.',
            duration: 10000,
          })
        },
        this.projectId,
        this.approvalId,
        {
          emailed_to_contact_id: this.approval.subcontractorContactId,
        }
      )
    },

    deleteApproval() {
      this.$confirm(
        'Are you sure you want to delete this approval, also resetting all items?',
        'Delete approval',
        {
          confirmButtonText: 'DELETE',
          cancelButtonText: 'Cancel',
          type: 'warning',
          confirmButtonClass: 'el-button--danger',
        }
      )
        .then(() => {
          projectApprovalsApi.delete(
            () => {
              Message.success({
                showClose: true,
                message: 'Approval deleted',
                duration: 3000,
              })
              setTimeout(() => {
                this.$router.push({
                  name: 'projectApprovals',
                  params: {projectId: this.projectId},
                })
              }, 500)
            },
            (error) => {
              Message.error({
                showClose: true,
                message: error.message,
                duration: 5000,
              })
            },
            this.projectId,
            this.approvalId
          )
        })
        .catch(() => {
          // noop
        })
    },

    showAvailableItemsSummary() {
      this.summaryDialogVisible = true
    },
  },
}
</script>

<style lang="scss" scoped>
.reset-button,
.quick-select-button {
  padding: 7px 12px;
}

.quick-select-button {
  background: rgb(174, 32, 255);
  color: white;

  &:hover {
    background: rgba(174, 32, 255, 0.8);
    border-color: rgba(174, 32, 255, 0.9);
  }
}

.approval-details,
.approved-items,
.available-items {
  margin-top: 1em;
  font-size: 0.9em;
  line-height: 1.3;

  ::v-deep .el-card__header {
    font-weight: bold;
    color: rgb(50, 65, 87);
  }
}

.reference {
  font-size: 1.5em;
}

.subcontractor {
  font-size: 1.3em;
}

.approval-title {
  font-size: x-large;
  text-align: center;
}

.approval-meta {
  text-align: right;
}

.status {
  text-align: right;
}

::v-deep .approved-items,
::v-deep .available-items {
  .cell {
    padding-left: 5px;
    padding-right: 5px;
  }

  td,
  th {
    height: 30px;
  }

  .el-table {
    margin-top: 5px;
  }

  .el-button-group {
    margin-left: 5px;
  }

  .intro {
    padding-top: 1em;

    h2 {
      font-size: 16px;
      margin: 10px 0 0 0;
    }

    h3 {
      font-size: 14px;
      margin: 5px 0 0 0;
    }
  }

  .el-button--mini {
    height: 24px;
  }
}

::v-deep .approval-rejected {
  td {
    background: lighten(rgba(255, 73, 73, 0.5), 10);
  }
}

::v-deep .approval-approved {
  td {
    background: lighten(rgba(19, 106, 102, 0.5), 20);
  }
}

el-table__row approval-approved ::v-deep .imported-item {
  td {
    color: #555;
    background: #eee;
  }
}
</style>
