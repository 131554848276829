<template>
  <div>
    <el-tabs :value="currentTab" @tab-click="clickedTab">
      <el-tab-pane label="Open" name="open">
        <DataTableAlternate
          v-if="currentTab === 'open'"
          ref="notificationsTableOpen"
          :api="notificationApi()"
          :data-table-options="filteredTableOptions"
          table-name="notifications"
        >
          <template #actions="{ row }">
            <el-button
              :key="`${row.id}_resolve`"
              :disabled="row.resolved_at ? true : false"
              icon="el-icon el-icon-check"
              size="mini"
              :title="row.resolved_at ? 'Is Resolved' : 'Mark Resolved'"
              :style="row.resolved_at ? 'visibility:hidden;' : ''"
              type="primary"
              @click="openResolveDialog(row)"
            />
            <el-button
              :key="`${row.id}_archive`"
              icon="el-icon el-icon-time"
              size="mini"
              title="Archive"
              type="warning"
              @click="archiveItem(row)"
            />
            <el-button
              :key="`${row.id}_view`"
              icon="el-icon el-icon-info"
              size="mini"
              title="View"
              type="default"
              @click="viewItem(row)"
            />
          </template>
        </DataTableAlternate>
      </el-tab-pane>
      <el-tab-pane label="Archived" name="archived">
        <DataTableAlternate
          v-if="currentTab === 'archived'"
          ref="notificationsTableArchived"
          :api="notificationApi()"
          :data-table-options="filteredTableOptions"
          :request-data="{onlyTrashed:true}"
          table-name="notifications"
        >
          <template #actions="{ row }">
            <el-button
              :key="`${row.id}_restore`"
              icon="el-icon el-icon-refresh-left "
              size="mini"
              title="Archive"
              type="default"
              @click="restoreItem(row)"
            />
          </template>
        </DataTableAlternate>
      </el-tab-pane>
    </el-tabs>

    <ResolveDialog
      v-if="selectedNotification"
      ref="resolveNotification"
      :notification="selectedNotification"
      @changed="onNotificationsChanged"
      @close="onNotificationDialogClose"
      @sent="onNotificationDialogSent"
    />

    <ViewDialog
      v-if="viewNotification"
      ref="viewNotification"
      :notification="viewNotification"
      @close="viewNotification = null"
    />
  </div>
</template>

<script>

import notificationsApi from '@/js/api/v2/notifications'
import DataTableAlternate from '@/js/components/Common/DataTableAlternate.vue';
import ResolveDialog from '@/js/components/Notifications/ResolveDialog.vue';
import ViewDialog from '@/js/components/Notifications/ViewDialog.vue';
import {logErrorMessage} from '@/js/common/util';

export default {
  name: 'NotificationsView',

  components: {
    ViewDialog,
    ResolveDialog,
    DataTableAlternate
  },

  data() {
    return {
      notifications: [],
      currentTab: 'open',
      dataTableOptions: {
        showRefresh: false,
        allowSelection: false,
        tableHeightOffset: 180,
        pageSizes: [100, 200, 500],
        columnWidth: {
          _rowActions: 110,
          section: 100,
          subject: 200,
          message: 450,
          response: 400,
          user: 120,
          created_at: 125,
          resolved_at: 125,
        }
      },
      selectedNotification: null,
      viewNotification: null,
    }
  },
  computed: {
    filteredTableOptions() {
      // Make a non referenced clone of the default options
      const options = JSON.parse(JSON.stringify(this.dataTableOptions))

      if(this.currentTab === 'archived') {
        // Update the columns width
        options.columnWidth._rowActions = 50
      }
      return options
    },
    eventStreamForNotificationsCount() {
      if(!this.$store.getters['global/eventStream']) {
        return null
      }
      return this.$store.getters['global/eventStream']['notificationsCount'] ?? null
    }
  },
  watch: {
    eventStreamForNotificationsCount() {
      this.$refs.notificationsTableOpen.refresh(false)
    },
  },
  methods: {
    onNotificationDialogClose() {
      this.selectedNotification = null
    },
    onNotificationDialogSent() {
      this.$refs.notificationsTableOpen.refresh(false)
      this.selectedNotification = null
      this.getStats()
    },
    onNotificationsChanged() {
      this.$refs.notificationsTableOpen.refresh(false)
      this.selectedNotification = null
      this.getStats()
    },
    notificationApi() {
      return notificationsApi
    },
    openResolveDialog(viewItem) {
      this.selectedNotification = viewItem
    },
    viewItem(item) {
      this.viewNotification = item
    },
    archiveItem(item) {
      notificationsApi.delete(
        () => {
          this.$message({
            type: 'success',
            message: 'Notification archived',
            duration: 4000,
          })
          this.$refs.notificationsTableOpen.refresh(false)
          this.getStats()
        },
        (error) => {
          alert(error)
          this.errors = error
          logErrorMessage(error)
          this.loading = false
        },
        item.id
      )
    },
    getStats() {
      notificationsApi.getStats(
        (response) => {
          this.$store.commit('global/notifications', response);
        })
    },
    restoreItem(item) {
      notificationsApi.restore(
        () => {
          this.$message({
            type: 'success',
            message: 'Notification restored',
            duration: 4000,
          })
          this.$refs.notificationsTableArchived.refresh(false)
          this.getStats()
        },
        (error) => {
          alert(error)
          this.errors = error
          logErrorMessage(error)
          this.loading = false
        },
        item.id
      )
    },
    clickedTab(tabComponent) {
      this.currentTab = tabComponent.name
    },
  },
}
</script>

<style lang="scss">

</style>
