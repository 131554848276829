<!-- This is not implemented correctly and adding a position is currently disabled -->
<template>
  <el-dialog
    :visible.sync="loadedNotification"
    :title="title"
    :before-close="close"
    class="position-view-dialog"
  >
    <div>
      <strong>Section</strong>
      <p>{{loadedNotification.section}}</p>
    </div>
    <div>
      <strong>Subject</strong>
      <p>{{loadedNotification.subject}}</p>
    </div>
    <div>
      <strong>Message</strong>
      <p>{{loadedNotification.message}}</p>
    </div>
    <div>
      <strong>Response</strong>
      <p>{{loadedNotification.response ?? '---'}}</p>
    </div>

    <hr />
    <div>
      <strong>Created At</strong>
      <p>{{longDate(loadedNotification.createdAt)}} | User:  {{loadedNotification.user.name}} | {{loadedNotification.user.username}} </p>
    </div>

    <div v-if="loadedNotification.seenAt">
      <strong>Seen At</strong>
      <p>{{longDate(loadedNotification.seenAt)}} | User: {{loadedNotification.seenBy?.name ?? ''}} | {{loadedNotification.seenBy?.username ?? ''}} </p>
    </div>
    <div v-if="loadedNotification.resolvedAt">
      <strong>Seen At</strong>
      <p>{{longDate(loadedNotification.resolvedAt)}} | User:  {{loadedNotification.resolvedBy?.name ?? ''}} | {{loadedNotification.resolvedBy?.username ?? ''}} </p>
    </div>
    <div v-if="loadedNotification.deletedAt">
      <strong>Archived At</strong>
      <p>{{longDate(loadedNotification.deletedAt)}} </p>
    </div>


  </el-dialog>
</template>

<script>

import notificationsApi from '@/js/api/v2/notifications';
import {logErrorMessage} from '@/js/common/util';
import moment from 'moment/moment';

export default {
  name: 'NotificationViewDialog',

  props: {
    notification: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      loadedNotification: null,
    }
  },
  computed: {
    title() {
      return `Notification: ${this.loadedNotification.section ?? '--'} Section | ${this.loadedNotification.subject ?? ''}`
    }
  },
  created() {
    this.load()
  },
  methods: {
    longDate(value) {
      if(!value) {
        return ''
      }
      return moment(String(value)).format('DD.MM.YYYY hh:mm')
    },
    close() {
      if(this.loading) {return}
      this.loadedNotification = null
      this.$emit('close')
    },

    load() {
      notificationsApi.showNotification(
        (response) => {
          this.loadedNotification = response
          this.visible = true
        },
        (error) => {
          alert(error)
          this.errors = error
          logErrorMessage(error)
          this.loading = false
        },
        this.notification.id
      )
    },
  },
}
</script>

<style lang="scss">

</style>
