<template>
  <el-dialog
    v-if="visible"
    :before-close="notifyParent"
    :title="title"
    :visible.sync="visible"
  >
    <default-form
      :errors="formErrors"
      :form="form"
      :loading="loading"
      @submit="onSubmit"
    />
  </el-dialog>
</template>

<script>
import Vue from 'vue'
import pricingTemplatesApi from 'api/v2/pricingTemplates'
import DefaultForm from 'components/Common/Form/DefaultForm'

export default {
  name: 'DialogTemplate',

  components: {
    DefaultForm,
  },

  props: {
    type: {
      type: String,
      default: 'add',
      validator: function (value) {
        return ['add', 'edit', 'duplicate'].indexOf(value) !== -1
      },
    },
    template: {
      type: Object,
      default: () => ({
        name: '',
      }),
    },
    onclose: {
      type: Function,
      default: () => {
      },
    },
    onSuccess: {
      type: Function,
      default: () => {
      },
    },
    params: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      visible: true,
      loading: false,
      formErrors: null,
    }
  },

  computed: {
    title() {
      switch (this.type) {
      case 'edit':
        return 'Edit template'
      case 'duplicate':
        return 'Duplicate template'
      default:
        return 'Add template'
      }
    },

    buttonTitle() {
      switch (this.type) {
      case 'edit':
        return 'Save template'
      case 'duplicate':
        return 'Duplicate template'
      default:
        return 'Add template'
      }
    },

    formItems() {
      return [
        {
          label: 'Name',
          prop: 'name',
          required: true,
          autofocus: true,
          rules: [],
        },
      ]
    },

    form() {
      return {
        items: this.formItems,
        buttons: [
          {
            event: 'submit',
            label: this.buttonTitle,
            type: 'primary',
          },
        ],
        ref: 'addTemplateForm',
        initialModel: {
          name:
            this.type === 'duplicate'
              ? this.template.name + ' (copy)'
              : this.template
                ? this.template.name
                : '',
        },
      }
    },
  },

  methods: {
    onSubmit(formData) {
      switch (this.type) {
      case 'edit':
        return this.editTemplate(formData)
      case 'duplicate':
        return this.duplicateTemplate(formData)
      default:
        return this.addTemplate(formData)
      }
    },

    notifyParent(done) {
      done()
      this.onclose()
      this.$parent.$emit('cancelTemplateDialog')
    },

    addTemplate(formData) {
      this.loading = true

      pricingTemplatesApi.store(
        () => {
          this.$message.success({
            showClose: true,
            message: 'Template has been created.',
            duration: 5000,
          })
          this.loading = false
          this.visible = false
          this.$parent.$emit('changedAddItem')
          this.onSuccess()
        },
        (error) => {
          this.loading = false
          this.formErrors = error
          Vue.nextTick(() => {
            this.formErrors = null
          })
        },
        Object.assign(formData, this.params)
      )
    },

    editTemplate(formData) {
      //todo:
      this.loading = true
      pricingTemplatesApi.update(
        (response) => {
          this.$message.success({
            showClose: true,
            message: 'Template has been updated.',
            duration: 5000,
          })
          this.loading = false
          this.visible = false
          this.$parent.$emit('changedEditItem', response)
          this.onSuccess()
        },
        (error) => {
          this.loading = false
          this.formErrors = error
          Vue.nextTick(() => {
            this.formErrors = null
          })
        },
        this.template.id,
        formData
      )
    },

    duplicateTemplate(formData) {
      this.loading = true
      pricingTemplatesApi.duplicate(
        (response) => {
          this.$message.success({
            showClose: true,
            message: 'Template has been duplicated.',
            duration: 5000,
          })
          this.loading = false
          this.visible = false
          this.$parent.$emit('duplicatedEditItem', response)
          this.onSuccess()
        },
        (error) => {
          this.loading = false
          this.formErrors = error
          Vue.nextTick(() => {
            this.formErrors = null
          })
        },
        this.template.id,
        formData
      )
    },
  },
}
</script>

<style lang="scss"></style>
