import ApiResource from '../ApiResource'

class PricingTemplateItemResource extends ApiResource {
  constructor() {
    super({
      name: 'pricing-templates.items',
    })
  }
}

const pricingTemplateItemsApi = new PricingTemplateItemResource()

export default pricingTemplateItemsApi
