<template>
  <span>
    <el-collapse>
      <el-collapse-item
        v-for="pricingCategory in pricingCategories"
        :key="pricingCategory.name"
        :class="{
          withInternalReference: subcontractor,
          withNotInvoicable: subcontractor && pricingTemplate.projectId,
          isNativePricingTemplate: !subcontractor && !pricingTemplate.projectId,
        }"
      >
        <template slot="title">
          <div class="category">
            <span>
              <span>{{ pricingCategory.name }}</span>
              <el-tag v-if="pricingCategory.costUnit" type="info">{{
                pricingCategory.costUnit
              }}</el-tag>
            </span>
            <span>
              <i
                  v-if="pricingCategory?.items?.length && pricingCategory?.items.filter((item) => item.mappingStatus).length"
                  class="el-icon el-icon-info action-edit item-has-issue"
                  title="Items in the category need attention"
              ></i>
              <i
                class="header-icon el-icon-edit"
                @click.prevent.stop="(e) => editCategory(pricingCategory)"
              />
              <i
                class="header-icon el-icon-close"
                @click.prevent.stop="(e) => deleteCategory(pricingCategory)"
              />
            </span>
          </div>
        </template>
        <add-pricing-item
          :area-selection="areaSelection"
          :pricing-template="pricingTemplate"
          :pricing-category="pricingCategory"
          :subcontractor="subcontractor"
          @item-added="handleItemAdded"
        />
        <ul v-if="pricingCategory.items && pricingCategory.items.length">
          <template
            v-for="pricingItem in pricingCategory.items"
          >
          <pricing-item
            v-if="!pricingItem.hidden || !options?.showHiddenItems === false"
            :key="pricingItem.id"
            :pricing-item="pricingItem"
            :subcontractor="subcontractor"
            :pricing-template="pricingTemplate"
            :pricing-category="pricingCategory"
            :area-selection="areaSelection"
            @deletePricingItem="deletePricingItem"
            @item-updated="handleItemUpdated"
            @open-mapping-status="showMappingStatus"
          />
          </template>
        </ul>
      </el-collapse-item>
    </el-collapse>
    <el-dialog :visible.sync="editDialogVisible" title="Edit Category">
      <div>
        <span>Category name:</span>
        <el-input
          v-model="editCategoryName"
          placeholder="Category name"
          @keydown.enter.stop.native="(e) => updateCategory()"
        />
      </div>
      <div>
        <span>Cost Unit:</span>
        <el-input
          v-model="editCostUnit"
          placeholder="Cost Unit"
          @keydown.enter.stop.native="(e) => updateCategory()"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click.prevent.stop="(e) => updateCategory()"
          >Edit category</el-button
        >
      </span>
    </el-dialog>
  </span>
</template>

<script>
import AddPricingItem from './AddPricingItem'
import PricingItem from './PricingItem'

export default {
  name: 'PricingTemplateCategories',

  components: {
    AddPricingItem,
    PricingItem,
  },

  props: {
    areaSelection: {
      type: String,
      required: true,
    },
    pricingTemplate: {
      type: Object,
      required: true,
    },
    pricingCategories: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    subcontractor: {
      type: Object,
      required: false,
      default() {
        return undefined
      },
    },
    options: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },

  data() {
    return {
      editDialogVisible: false,
      editCategoryName: '',
      editCategoryId: null,
      editCostUnit: '',
      editablePricingItemId: null,
    }
  },

  methods: {
    showMappingStatus(data) {
      this.$emit('open-mapping-status', data)
    },
    deletePricingItem(item, force) {
      this.$emit('deletePricingItem', item, force)
    },
    handleItemAdded(item) {
      this.$emit('item-added', item)
    },
    handleItemUpdated(item) {
      this.$emit('item-updated', item)
    },
    deleteCategory(pricingCategory) {
      this.$emit('deleteCategory', pricingCategory.id)
    },
    editCategory(pricingCategory) {
      this.editCategoryName = pricingCategory.name
      this.editCostUnit = pricingCategory.costUnit
      this.editCategoryId = pricingCategory.id
      this.editDialogVisible = true
    },
    updateCategory() {
      this.$emit(
        'updateCategory',
        this.editCategoryName,
        this.editCostUnit,
        this.editCategoryId
      )
      this.editDialogVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep input {
  padding-left:5px!important;
}
.key,
.value {
  height: 36px;
  line-height: 36px;
}

.el-icon-close {
  color: red;
  font-weight: 900;
  padding: 5px;
}

.el-icon-edit {
  padding: 5px;
}

.el-icon-plus {
  padding: 0;
}


li {
  font-size:12px;
  display: grid;
  /*                     DEL   | REF   | DESC |  UNIT | PRICE | FIXED | Hidden | P Index | P Index | B Index | A Index   | ACTIONS  */
  grid-template-columns: 40px    90px    auto    70px   70px    80px    80px     150px                130px     130px       70px;
  grid-gap: 2px;

  align-self: center;
  background: #eef1f6;
  &:nth-child(2n) {
    background: #ffffff;
  }

}
.price-input {
  display: grid;
  grid-gap: 2px;
  /*                     DEL   | REF   | DESC |  UNIT | PRICE | FIXED | Hidden | P Index | P Index | B Index | A Index   | ACTIONS  */
  grid-template-columns: 40px    90px   auto     70px   70px    80px    80px    150px                130px     130px        70px;

  align-self: center;
  align-items: center;
  background-color: rgb(202, 249, 130);
}

.withInternalReference {
  li, .price-input {
    /*                     DEL   | REF   | Soli. REF | DESC |  UNIT | PRICE | FIXED | Hidden | P Index | P Index | B Index | A Index   | ACTIONS  */
    grid-template-columns: 40px   90px     90px        auto    70px   70px    80px    80px     150px               130px     130px       70px;
  }
  &.withNotInvoicable {
    li, .price-input {
      /*                     DEL   | REF   | Soli. REF | DESC |  UNIT | PRICE | FIXED | Hidden | NOT Inv. | P Index | P Index | B Index | A Index   | ACTIONS     */
      grid-template-columns: 40px   90px     90px        auto    70px    70px    80px    80px     70px      150px                130px     130px       70px;
    }
  }
}
.isNativePricingTemplate {
  li, .price-input {
    /*                     DEL   | REF   | DESC |  UNIT | PRICE | FIXED | Hidden | P Index | P Index | B Index | A Index   | ACTIONS  */
    grid-template-columns: 40px  90px     auto    70px    70px    80px    80px      150px     150px     130px     130px       70px;
  }
}
ul {
  padding: 0;
  margin: 0;
}
::v-deep .el-dialog__body div {
  display: flex;
  align-items: center;
  span {
    width: 160px;
  }
  input {
    margin: 5px;
  }
}

.category {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
}

::v-deep .el-collapse-item__header {
  background: rgba(46, 143, 244, 0.192156862745098);
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  line-height: 30px;
  height: 30px;

  .category > span > span:first-child {
    display: inline-block;
    min-width: 250px;
  }

  .el-tag {
    margin-left: 10px;
  }
}
</style>
