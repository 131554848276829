<template>
  <LerfTableWithFilters :project="project" />
</template>

<script>
import LerfTableWithFilters from '@/js/components/Lerfs/LerfTableWithFilters.vue';

export default {
  name: 'ProjectSingleLerfs',
  components: {
    LerfTableWithFilters
  },

  props: {
    project: {
      type: Object,
      default: null,
    },
  },
}
</script>
