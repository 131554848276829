import ApiResource from '../ApiResource'

class ProjectReportResource extends ApiResource {
  constructor() {
    super({
      name: 'projects.report',
    })
  }
}

const projectReportsApi = new ProjectReportResource()

export default projectReportsApi
