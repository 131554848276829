<template>
  <div class="comp-subcontractors-view">
    <div class="header-action-row">
      <span>
        <el-button
          class="header-action-row"
          size="medium"
          type="primary"
          @click="visibleDialog.addNew = true"
        >
          Add subcontractor
        </el-button>
      </span>
    </div>
      <div
        class="header-action-row table-widget table-column-filter"
        style="max-width: 1280px"
      >
        <div class="search">
          <h4>Search columns</h4>
          <div>
            <el-input
              v-model="searchName"
              placeholder="Search name"
              size="mini"
              suffix-icon="el-icon-search"
              @change="debouncedSearch"
            />
          </div>
        </div>
      </div>
      <div style="max-width: 1280px">
        <el-table
          :data="subcontractors"
          :highlight-current-row="true"
          class="data-table"
          size="mini"
          stripe
          @sort-change="sortChange"
          @cell-dblclick="onCellClick"
        >
          <el-table-column
            :show-overflow-tooltip="true"
            label="Name"
            prop="name"
            sortable
          />
          <el-table-column
            :show-overflow-tooltip="true"
            :width="200"
            label="Projects"
          >
            <template slot-scope="scope">
              <el-button size="mini" type="text">
                Assigned to {{ scope.row.projects.length }} projects
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            :width="200"
            label="Contacts"
          >
            <template slot-scope="scope">
              <el-button size="mini" type="text">
                {{ scope.row.contacts.length }} contacts
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            :width="120"
            align="right"
            label="Actions"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                title="View project"
                type="primary"
                @click.stop="$router.push(`/subcontractors/${scope.row['id']}`)"
              >
                view
              </el-button>
              <el-button
                icon="el-icon el-icon-time"
                size="mini"
                title="Archive project"
                type="warning"
                @click.stop="archiveSubcontractor(scope.row['id'])"
              >
              </el-button>
              <el-button
                icon="el-icon el-icon-delete"
                size="mini"
                title="Delete project"
                type="danger"
                @click.stop="deleteSubcontractor(scope.row['id'])"
              />
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :per-page="perPage"
          :total="total"
          :current-page="currentPage"
          @pageChange="pageChange"
          @changePageSize="changePageSize"
        />
      </div>
      <el-dialog
        :before-close="(e) => handleClose('addNew')"
        :visible.sync="visibleDialog.addNew"
        title="Add subcontractor"
      >
        <el-form
          ref="addSubcontractorForm"
          :model="form"
          label-position="right"
          label-width="150px"
        >
          <el-form-item label="Name" required>
            <el-input v-model="form.name"/>
          </el-form-item>
          <el-form-item label="Phone">
            <el-input v-model="form.phone"/>
          </el-form-item>
          <el-form-item label="Email">
            <el-input v-model="form.email"/>
          </el-form-item>
          <el-form-item label="Address">
            <el-input v-model="form.address"/>
          </el-form-item>
          <el-form-item label="Postcode">
            <el-input v-model="form.postcode"/>
          </el-form-item>
          <el-form-item label="City">
            <el-input v-model="form.city"/>
          </el-form-item>
          <el-form-item label="Country">
            <el-input v-model="form.country"/>
          </el-form-item>
          <el-form-item label="Creditor number">
            <el-input v-model="form.creditorNumber"/>
          </el-form-item>
          <el-form-item>
            <el-button @click="(e) => handleClose('addNew')">Cancel</el-button>
            <el-button type="primary" @click="addSubcontractor(form)">
              Save
            </el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
  </div>
</template>
<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import subcontractorsApi from 'api/v2/subcontractors'
import snakeCaseKeys from 'snakecase-keys'
import {debounce} from 'lodash-es'
import {logErrorMessage, formatErrorMessage} from '@/js/common/util';
import Pagination from '@/js/components/Common/DefaultPagination.vue';


export default {
  name: 'SubcontractorsView',

  components: {
    Pagination
  },

  data() {
    return {
      form: {
        name: '',
        phone: null,
        email: null,
        address: '',
        postcode: '',
        city: '',
        country: '',
        creditorNumber: '',
      },
      subcontractors: [],
      visibleDialog: {
        addNew: false,
        projects: false,
        users: false,
        edit: false,
      },
      activeSubcontractor: {},
      assignedProjectIDs: [],
      assignedUserIDs: [],
      users: [],
      usersOptions: [],
      currentPage: Number(this.$route.params.pageNr),
      perPage: 20,
      total: null,
      sortParams: {orderBy: null, orderDirection: null},

      searchName: '',

      debouncedSearch: () => {
      }, // Gets overridden on mount
    }
  },

  computed: {
    ...mapGetters('projects', ['items']),

    projectOptions() {
      return this.items.map((project) => {
        const {name, id} = project
        return {label: name, value: id}
      })
    },
  },

  mounted() {
    this.fetchSubcontractors(this.currentPage)

    this.debouncedSearch = debounce(this.search.bind(this), 300)
  },

  methods: {
    onCellClick(row, column) {
      if (column.label === 'Name') {
        this.$router.push(`/subcontractors/${row.id}`)
      } else {
        this.$router.push(`/subcontractors/${row.id}/projects`)
      }
    },

    handleClose(type) {
      this.visibleDialog[type] = false
    },

    fetchSubcontractors(page) {
      const params = {
        page,
        perPage: this.perPage,
        include: ['projects', 'contacts', 'contacts.user'],
        ...this.sortParams,
      }
      if (this.searchName) {
        params['f[name]'] = this.searchName
      }

      subcontractorsApi.index(
        (response) => {
          this.subcontractors = response.data
          this.currentPage = response.meta.current_page
          this.perPage = response.meta.per_page
          this.total = response.meta.total
        },
        (error) => {
          logErrorMessage(error)
          this.$message.error({
            showClose: true,
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        params
      )
    },

    sortChange(sortProps) {
      if (this.total > this.perPage) {
        const order = sortProps.order === 'descending' ? 'desc' : 'asc'
        this.sortParams.orderBy = sortProps.prop
        this.sortParams.orderDirection = order
      }
      this.fetchSubcontractors(this.currentPage)
    },

    addSubcontractor(subcontractor) {
      subcontractorsApi.store(
        (response) => {
          this.$message.success({
            showClose: true,
            message: 'Subcontractor has been created.',
            duration: 5000,
          })
          this.$router.push(`/subcontractors/${response.id}`)
        },
        (error) => {
          this.formErrors = error
          Vue.nextTick(() => {
            this.formErrors = null
          })
        },
        snakeCaseKeys(subcontractor)
      )
    },

    archiveSubcontractor(id) {
      const {name} = this.subcontractors.find((subc) => subc.id === id)
      this.$confirm(`Do you want to archive "${name}"?`, 'Warning', {
        confirmButtonText: 'Archive',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        subcontractorsApi.archive(
          () => {
            this.$message.success({
              showClose: true,
              message: `${name} has been archived.`,
              duration: 5000,
            })
            this.fetchSubcontractors(this.currentPage)
          },
          (error) => {
            let errorMessage = 'An error occurred while archiving this subcontractor. Please try again.'

            if (error) {
              logErrorMessage(error)
              errorMessage = error.message
            }

            this.$message.error({
              showClose: true,
              message: errorMessage,
              duration: 5000,
            })
          },
          id
        )
      })
    },

    deleteSubcontractor(id) {
      const {name} = this.subcontractors.find((subc) => subc.id === id)
      this.$confirm(`Do you want to permanently delete "${name}"?`, 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        subcontractorsApi.delete(
          () => {
            this.$message.success({
              showClose: true,
              message: `${name} has been removed.`,
              duration: 5000,
            })
            this.fetchSubcontractors(this.currentPage)
          },
          (error) => {
            let errorMessage = 'An error occurred while deleting this subcontractor. Please try again.'

            if (error) {
              logErrorMessage(error)
              errorMessage = error.message
            }

            this.$message.error({
              showClose: true,
              message: errorMessage,
              duration: 5000,
            })
          },
          id
        )
      })
    },
    changePageSize(newPageSize) {
      this.perPage = newPageSize
      this.pageChange(1)
    },
    pageChange() {
      const prefix = window.location.hash.match(/^.+\/page\//)[0]
      window.location.hash = `${prefix}${this.currentPage}`
      this.fetchSubcontractors(this.currentPage)
    },

    search() {
      this.fetchSubcontractors(this.currentPage)
    },
  },
}
</script>
