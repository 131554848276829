<template>
  <div class="import">
    <project-report-table
      :import-controls="true"
      :filterable="true"
      :purchase-invoice="purchaseInvoice"
    />
  </div>
</template>

<script>
import {findIndex} from 'lodash-es'
import purchaseInvoiceItemsApi from '@/js/api/v2/purchaseInvoiceItems'
import ProjectReportTable from '@/js/components/Items/ProjectReportTable'
import {logErrorMessage, formatErrorMessage} from '../../../../common/util';

export default {
  name: 'PurchaseInvoiceImport',
  components: {
    ProjectReportTable,
  },
  props: {
    purchaseInvoice: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      importLoading: false,
      multipleSelection: [],
    }
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.table.$refs.table.toggleRowSelection(row)
        })
      } else {
        this.$refs.table.$refs.table.clearSelection()
      }
    },
    importItems() {
      this.importLoading = true
      purchaseInvoiceItemsApi.create(
        (result) => {
          this.importLoading = false
          this.toggleSelection()
          this.$emit('reload')
          this.$message({
            type: 'success',
            message: result.length + ' items imported',
            duration: 1000,
          })
          this.markAsExisting(result)
        },
        (error) => {
          logErrorMessage(error)
          this.importLoading = false
          this.$message({
            showClose: true,
            type: 'error',
            message: 'Import failed: ' + formatErrorMessage(error),
            duration: 0,
          })
        },
        this.purchaseInvoice.id,
        {
          items: this.multipleSelection,
        }
      )
    },
    markAsExisting(resultItems) {
      resultItems.forEach((resultItem) => {
        let index = findIndex(this.items, (i) => {
          return i.app_position_item_id === resultItem.app_position_item_id
        })

        if (index >= 0) {
          let newItem = Object.assign({}, this.items[index])
          newItem.exists = true
          this.items.splice(index, 1, newItem)
        }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // fetchItems(data) {
    //   this.loading = true
    //   projectsApi.getPositionItems(
    //     (response) => {
    //       this.items = response
    //       this.$refs.table.toggleAll()
    //       this.loading = false
    //     },
    //     (error) => {
    //       console.error(error)
    //       this.loading = false
    //       this.items = []
    //     },
    //     this.purchaseInvoice.project.id,
    //     this.purchaseInvoice.id,
    //     data
    //   )
    // },
  },
}
</script>

<style lang="scss">
.import {
  .spacing {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
