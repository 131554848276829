<template>
  <ClaimReportTable />
</template>

<script>
import ClaimReportTable from './ClaimReportTable'

export default {
  name: 'ClaimReport',
  components: {
    ClaimReportTable,
  },
}
</script>
