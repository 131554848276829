<template>
  <div>
    <el-row :gutter="20" class="header-action-row">
      <el-col :span="18">
        <el-button
          type="primary"
          size="medium"
          @click="addDialogVisible = true"
        >
          Add Item
        </el-button>
        <div class="grid-content bg-purple" />
      </el-col>
      <el-col :span="6" />
    </el-row>
    <DataTableAlternateVer2
        ref="salesInvoiceItems"
        table-name="salesInvoiceItems"
        :api="api"
        :request-ids="requestIds"
        :request-data="requestData"
        :data-table-options="dataTableOptions"
        :row-actions="rowActions"
        @row-action-edit="editItem"
        @row-action-delete="removeItem"
    />
    <LerfEditItemDialog
      v-if="editDialogVisible"
      :lerf-item="editLerfItem"
      :lerf="item"
      @updateItem="updateItem"
      @cancel="closeDialog"
    />
    <LerfAddItemDialog
      v-if="addDialogVisible"
      :lerf="item"
      @addItem="addItem"
      @cancel="closeDialog"
    />
  </div>
</template>

<script>
import lerfItemsApi from 'api/v2/lerfItems'
import projectLerfItemsApi from 'api/v2/projectLerfItems'
import LerfEditItemDialog from 'components/Lerfs/EditItemDialog'
import LerfAddItemDialog from 'components/Lerfs/AddItemDialog'
import {formatErrorMessage} from '@/js/common/util';
import DataTableAlternateVer2 from '@/js/components/Common/DataTableAlternateVer2.vue';

export default {
  name: 'LerfItems',

  components: {
    DataTableAlternateVer2,
    LerfAddItemDialog,
    LerfEditItemDialog,
  },

  props: {
    item: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      addDialogVisible: false,
      editLerfItem: null,
      editDialogVisible: false,
      requestData: {
        include: ['pricingItem', 'location', 'lerf']
      },
      dataTableOptions: {
        showRefresh: false,
        tableHeightOffset: 270,
        columnWidth: {
          _rowActions: 80,
          is_import: 100,
          executed_at: 130,
          net: 100,
          gross: 100,
          mass: 100,
          factor: 100,
          section: 150,
          description: 280,
          comment: 280,
        }
      },
      rowActions: {
        'edit': {
          icon : 'el-icon el-icon-edit'
        },
        'delete': {
          icon : 'el-icon el-icon-delete',
          type: 'danger'
        }
      }
    }
  },

  computed: {
    api() {
      if (this.item.projectId) {
        return projectLerfItemsApi
      }
      return lerfItemsApi
    },
    requestIds() {
      if (this.item.projectId) {
        return [this.item.projectId, this.item.id]
      }
      return [this.item.id]
    }
  },
  mounted() {},
  methods: {
    removeItem(data) {
      lerfItemsApi.delete(
        () => {
          this.$message({
            type: 'success',
            message: 'Deleted',
            duration: 3000,
          })
          this.$refs.salesInvoiceItems.refresh()
        },
        (error) => {
          this.$message({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.item.id,
        data.id
      )
    },
    editItem(data) {
      this.editLerfItem = data
      this.editDialogVisible = true
    },
    updateItem() {
      this.$refs.salesInvoiceItems.refresh()
      this.$message({
        type: 'success',
        message: 'Item Updated',
        duration: 3000,
      })
    },
    addItem() {
      this.$refs.salesInvoiceItems.refresh()
      this.$message({
        type: 'success',
        message: 'Item Added',
        duration: 3000,
      })
    },
    closeDialog() {
      this.addDialogVisible = false
      this.editDialogVisible = false
      this.editLerfItem = null
    },
  },
}
</script>
