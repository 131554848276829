<template>
  <div v-loading.body="loadingData" class="project-single-view">
    <el-tabs v-model="tab" @tab-click="setTab(tab)">
      <el-tab-pane label="Overview" name="overview">
        <overview-tab
          v-if="subcontractor"
          :subcontractor="subcontractor"
          @save="updateInternalSubcontractor"
        />
      </el-tab-pane>

      <el-tab-pane label="Projects and access" name="projects">
        <projects-tab
          v-if="subcontractor && subcontractor.id"
          :subcontractor="subcontractor"
        />
      </el-tab-pane>

      <el-tab-pane label="Pricing templates" name="pricing-templates">
        <pricing-tab
          v-if="subcontractor && subcontractor.id"
          :subcontractor="subcontractor"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import subcontractorsApi from 'api/v2/subcontractors'
import OverviewTab from './OverviewTab'
import ProjectsTab from './ProjectsTab'
import PricingTab from './PricingTab.vue'

export default {
  name: 'SubcontractorSingleView',
  components: {
    OverviewTab,
    ProjectsTab,
    PricingTab,
  },
  data() {
    return {
      subcontractor: null,
      loadingData: false,
      tab: 'overview',
    }
  },
  computed: {
    subcontractorId() {
      return this.$route.params.subcontractorId
    },
  },
  beforeDestroy() {
    this.$store.dispatch('global/resetCustomTitle')
  },
  created() {
    this.setTabFromRoute()
    this.fetchSubContractor()
  },
  methods: {
    fetchSubContractor() {
      this.loading = true
      subcontractorsApi.getItem(
        (response) => {
          this.$set(this, 'subcontractor', response)
          this.$store.commit('global/customTitle', response.name)
          this.$nextTick(() => {
            this.loading = false
          })
        },
        (error) => {
          this.$message.error({
            showClose: true,
            message: error,
            duration: 10000,
          })
        },
        this.subcontractorId
      )
    },
    setTabFromRoute() {
      if (this.$route.params.tab) {
        this.tab = this.$route.params.tab
      }
    },
    setTab() {
      this.$router.push(`/subcontractors/${this.subcontractorId}/${this.tab}`)
    },
    updateInternalSubcontractor(data) {
      this.$set(this, 'subcontractor', data)
    },
  },
}
</script>

<style scoped lang="scss"></style>
