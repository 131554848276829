import ApiResource from '../ApiResource'

class PurchaseInvoiceItemImportResource extends ApiResource {
  constructor() {
    super({
      name: 'purchase-invoice-import',
    })
  }
}

const purchaseInvoiceItemImportApi = new PurchaseInvoiceItemImportResource()

export default purchaseInvoiceItemImportApi
