<template>
  <div class="view-single-project-notes">
    <h1>Add new</h1>
    <note-editor :project="project" @save="savedNote" />

    <h1>History</h1>
    <note-history :project="project" :extra-notes="extraNotes" />
  </div>
</template>

<script>
import NoteEditor from 'components/Projects/NoteEditor'
import NoteHistory from 'components/Projects/NoteHistory'

export default {
  name: 'ViewSingleProjectNotes',

  components: {
    NoteEditor,
    NoteHistory,
  },

  props: {
    project: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      extraNotes: [],
    }
  },

  methods: {
    savedNote(note) {
      this.extraNotes.unshift(note)
    },
  },
}
</script>

<style lang="scss">
.view-single-project-notes {
  h1 {
    font-size: 1.3em;
  }

  h2 {
    font-size: 1.2em;
  }

  h3 {
    font-size: 1.1em;
  }
}
</style>
