import ApiResource from '../ApiResource'

class MiscResource extends ApiResource {
  constructor() {
    super({
      name: 'misc',
    })
  }

  html2pdf(resolve, reject, html, images, orientation='landscape', options={}) {
    this.post(
      `${this.getUrl()}/html2pdf`,
      { html, images, orientation, options },
      { responseType: 'arraybuffer' }
    )
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }

  saveLerfPDFContents(resolve, reject, lerf_id, html, images, options={}) {
    this.post(
      `${this.getUrl()}/saveLerfPDFContents`,
      { lerf_id, html, images, options },
    )
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }
  savePurchaseInvoicePDFContents(resolve, reject, purchase_invoice_id, html, images, options={}) {
    this.post(
      `${this.getUrl()}/savePurchaseInvoicePDFContents`,
      { purchase_invoice_id, html, images, options },
    )
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const miscApi = new MiscResource()

export default miscApi
