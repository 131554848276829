import ApiResource from '../ApiResource'

class BidValuesResource extends ApiResource {
  constructor() {
    super({
      name: 'bids.bid-values',
    })
  }
}

const BidValuesApi = new BidValuesResource()

export default BidValuesApi
