import ApiResource from '../ApiResource'

class ProjectClaimEmailResource extends ApiResource {
  constructor() {
    super({
      name: 'projects.claims.email',
    })

    this.getSingleEmail = this.show
    this.deleteEmail = this.delete
  }
}

const projectClaimEmailApi = new ProjectClaimEmailResource()

export default projectClaimEmailApi
