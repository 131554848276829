<template>
  <div>
    <p v-if="!isPricingItem">
      <strong>Pricing Item: </strong><br />
      <span>{{ pricingItem.id }} | {{ pricingItem.description }}v </span>
    </p>
    <p>
      <strong>Template: </strong><br />
      <span v-if="pricingItem.pricing_template">
        {{ pricingItem.pricing_template.id }} | {{ pricingItem.pricing_template.name }}
      </span>
      <span v-else>No Template</span>
    </p>
    <p v-if="pricingItem.pricing_category">
      <strong>Area:</strong><br />
      <span>{{ pricingItem.pricing_category.area_selection }}/{{pricingItem.pricing_category.name }}</span>
    </p>
    <p v-if="pricingItem.pricing_template">
      <strong>Pricing For:</strong><br/>
      <template v-if="pricingItem.pricing_template.is_internal_template">Internal Template</template>
      <template v-if="pricingItem.pricing_template.is_subcontractor_template">Subcontractor Template</template>
      <template v-if="pricingItem.pricing_template.is_project_template">Project Template</template>
    </p>
  </div>
</template>

<script>
export default {
  name: 'PricingItemPartial',
  props: {
    pricingItem: {
      type: Object,
      default: null,
      required: true
    },
    isPricingItem: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped></style>
