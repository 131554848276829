<template>
  <ClaimsTable />
</template>

<script>
import ClaimsTable from '../../Claims/ClaimsTable'

export default {
  name: 'ClaimsView',
  components: {
    ClaimsTable,
  },
}
</script>
