/**
 * @todo refactor maybe, see other subcontractor class wrt linking projects
 */
import ApiResource from '../ApiResource'

class SubcontractorProjectResource extends ApiResource {
  constructor() {
    super({
      name: 'subcontractors.projects',
    })

    this.getItem = this.show
    this.getProjects = this.index
  }

  linkProject(resolve, reject, subcontractorId, projectId) {
    this.ids = [subcontractorId, projectId]
    this.post(`${this.getUrl()}/attach`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }

  unlinkProject(resolve, reject, subcontractorId, projectId) {
    this.ids = [subcontractorId, projectId]
    this.post(`${this.getUrl()}/detach`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const subcontractorProjectsApi = new SubcontractorProjectResource()

export default subcontractorProjectsApi
