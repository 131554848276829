<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card>
          <FormGeneral :claim="claim" @update="update" />
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card>
          <FormSchedule :claim="claim" @update="update" />
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <FormStatus :claim="claim" @update="update" />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import FormGeneral from 'components/Claims/FormGeneral'
import FormSchedule from 'components/Claims/FormSchedule'
import FormStatus from 'components/Claims/FormStatus'

export default {
  name: 'ClaimOverview',
  components: {
    FormGeneral,
    FormSchedule,
    FormStatus,
  },
  props: {
    claim: {
      type: Object,
      required: true,
    },
  },
  methods: {
    update(data) {
      this.$emit('update', data)
    },
  },
}
</script>
