<template>
  <el-dialog
    title="Login in Soli DB app"
    :visible="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    class="login-dialog"
  >
    <el-form
      ref="loginForm"
      label-position="top"
      :rules="allRules"
      :model="loginData"
    >
      <el-form-item :error="loginData.error" />
      <el-form-item
        v-for="item in formItems"
        :key="item.label"
        :ref="item.prop"
        :label="item.label"
        :prop="item.prop"
        @keydown.enter.stop.native="login"
      >
        <el-input v-model="loginData[item.prop]" :type="item.type || 'text'" />
      </el-form-item>
      <el-row type="flex" class="bottom-row">
        <el-button type="primary" native-type="button" @click="login">
          Login
        </el-button>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
import auth from 'api/auth'
import config from 'config/app'
import { mapGetters } from 'vuex'

export default {
  name: 'LoginView',
  data() {
    return {
      formItems: [
        {
          label: 'Email',
          prop: 'username',
          required: true,
          rules: [],
        },
        {
          label: 'Password',
          prop: 'password',
          required: true,
          type: 'password',
          rules: [],
        },
      ],
      loginData: {
        username: this.username,
        password: '',
        error: null,
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['username']),

    allRules() {
      const rules = this.formItems.reduce((acc, current) => {
        const rules = []

        if (current.required) {
          rules.push({
            required: true,
            message: `${current.label} is required!`,
            trigger: 'blur',
          })
        }
        if (current.rules) {
          Array.prototype.push.apply(rules, current.rules)
        }
        acc[current.prop] = rules
        return acc
      }, {})

      return rules
    },
    resetLink() {
      return `${config.api.baseUrl}cms/password/reset`
    },
  },
  methods: {
    defaultRules(required, label) {
      if (!required) {
        return []
      } else {
        return [{ required: true, message: `${label} is required!` }]
      }
    },
    login() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          auth.login(
            (response) => {
              this.$store.commit('auth/username', this.loginData.username)
              this.$store.commit('auth/tokens', response)
              this.$router.replace('/')
            },
            () => {
              this.loginData.error = 'Login failed.'
              window.setTimeout(() => {
                this.loginData.error = null
              }, 2000)
            },
            this.loginData
          )
        }

        return false
      })
    },
  },
}
</script>

<style lang="scss">
.login-dialog {


  .el-dialog__body {
    padding-top: 1rem;
  }
}
</style>
