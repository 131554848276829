import ApiResource from '../ApiResource'

class PositionFileResource extends ApiResource {
  constructor() {
    super({
      name: 'positions.files',
    })
  }

  upload(resolve, reject, positionId, files) {
    const formData = new FormData()

    for (const index in files) {
      formData.append(`files[${index}]`, files[index])
    }
    this.ids = [positionId]

    this.post(this.getUrl(), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const positionFileApi = new PositionFileResource()

export default positionFileApi
