<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="16">
        <el-card>
          <FormGeneral :inherited-position="position" @update="update"/>
        </el-card>
      </el-col>
    </el-row>
    <el-row v-if="renderRevisionsTable" :gutter="20" class="projects-revisions">
      <el-col :span="24">
        <RevisionsTable :position="position"/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import FormGeneral from 'components/Positions/FormGeneral'
import RevisionsTable from 'components/Positions/RevisionsTable'

export default {
  name: 'PositionOverview',
  components: {
    FormGeneral,
    RevisionsTable,
  },
  props: {
    position: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      renderRevisionsTable: true,
    }
  },
  methods: {
    update(data) {
      this.$emit('update', data)
      this.renderRevisionsTable = false
      this.$nextTick(() => {
        // Adding the component back in
        this.renderRevisionsTable = true
      })
    },
  },
}
</script>
<style scoped>
.projects-revisions {
  margin-top: 30px;
}
</style>
