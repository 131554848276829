<template>
  <el-card class="comp-list-view box-card list-view">
    <div slot="header" class="card-header">
      <el-row align="middle" type="flex">
        <el-col :span="filterable ? 12 : 24" class="header">
          {{ title }}
        </el-col>
        <el-col v-if="filterable" :span="12" class="header">
          <el-input v-model="filter" placeholder="filter"/>
        </el-col>
      </el-row>
    </div>

    <el-row v-if="canAdd || sortable" :gutter="5" align="middle" type="flex" class="inner-header">
      <template v-if="canAdd">
        <el-col class="sub-header addition-icon">
          <i aria-hidden="true" class="el-icon el-icon-plus"></i>
        </el-col>
        <el-col
          :span="secondaryLabelAttribute ? 24 - secondaryLabelColumnSpan : 24"
          class="sub-header addition-input"
        >
          <el-input
            ref="mainInput"
            v-model="addedName"
            :maxlength="255"
            placeholder="add new"
            @keydown.native.enter="addNewItem"
          />
        </el-col>
        <el-col
          v-if="secondaryLabelAttribute"
          :span="secondaryLabelColumnSpan"
          class="sub-header addition-input additional-column"
        >
          <el-input
            v-model="addedSecondaryValue"
            :maxlength="16"
            placeholder="add new"
            @keydown.native.enter="addNewItem"
          />
        </el-col>
      </template>
      <template v-else>
        <el-col class="sub-header addition-input">&nbsp;</el-col>
      </template>

    </el-row>

    <el-row>
      <el-col class="spacer"></el-col>
    </el-row>

    <div class="scrollable">
      <el-row
        v-for="item in filteredAndSortedItems"
        :key="item.id"
        :gutter="5"
        align="middle"
        class="item"
        type="flex"
      >
        <el-col
          :span="secondaryLabelAttribute ? 24 - secondaryLabelColumnSpan : 24"
          class="content label"
        >
          <div @click.stop="selectItem(item)">
            <template v-if="selectable">
              <el-radio v-model="selectedLabel" :label="item[labelAttribute]"/>
            </template>
            <template v-else>
              {{ item[labelAttribute] }}
            </template>
          </div>
        </el-col>
        <el-col v-if="secondaryLabelAttribute" :span="secondaryLabelColumnSpan" class="additional-content-column">
          {{ item[secondaryLabelAttribute] }}
        </el-col>
        <el-col v-if="canUpdate && ['street', 'muffe', 'mfg'].indexOf(entity.toLocaleLowerCase()) !== -1" class="content icon">
          <el-button
            icon="el-icon el-icon-rank"
            size="mini"
            type="secondary"
            title="Move to different area"
            @click="openMoveDialog(item)"
          ></el-button>
        </el-col>
        <el-col v-if="canUpdate" class="content icon">
          <el-button
            icon="el-icon el-icon-edit"
            size="mini"
            type="secondary"
            title="Edit name"
            @click="editItem(item)"
          ></el-button>
        </el-col>
        <el-col v-if="canDelete" class="content icon">
          <el-button
            type="danger"
            icon="el-icon el-icon-delete"
            size="mini"
            title="Delete"
            @click="deleteItem(item, false)"
          ></el-button>
        </el-col>
      </el-row>
    </div>

    <el-dialog
      :show-close="false"
      :title="`Move '${moveItem.name}' to a different area`"
      :visible="!!showMoveDialog"
      @close="closeMoveDialog"
    >
      <template v-if="area && areas && areas.length">
        <p>Move {{ entity }} <strong>{{ moveItem.name }}</strong> from area <strong>{{ area.name }}</strong> to area:</p>

        <el-select v-model="moveToAreaId" filterable placeholder="Select">
          <el-option
            v-for="item in moveToAreaOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>

        <span slot="footer" class="dialog-footer">
          <el-button @click="closeMoveDialog">Cancel</el-button>
          <el-button type="primary" :disabled="!moveToAreaId" @click="moveLocationToSelectedArea">Move</el-button>
        </span>
      </template>
    </el-dialog>


    <el-dialog
      :show-close="false"
      :title="`Change ${title}`"
      :visible="!!editedItem"
      @close="editedItem = null"
    >
      <template v-if="!!editedItem">
        <el-row :gutter="10">
          <el-col :span="8">
            <span>{{ labelAttribute | niceLabel }}</span>
          </el-col>
          <el-col :span="16">
            <el-input v-model="editedItem[labelAttribute]" :maxlength="255"/>
          </el-col>
          <el-col v-if="secondaryLabelAttribute" :span="8">
            <span>{{ secondaryLabelAttribute | niceLabel }}</span>
          </el-col>
          <el-col v-if="secondaryLabelAttribute" :span="16">
            <el-input
              v-model="editedItem[secondaryLabelAttribute]"
              :maxlength="255"
            />
          </el-col>
        </el-row>

        <span slot="footer" class="dialog-footer">
          <el-button @click="editedItem = null">Cancel</el-button>
          <el-button type="primary" @click="saveEditedItem">Save</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      title="Bid"
      :visible="viewBidDetails"
      @close="(viewBidDetails = false) && (bid=false)"
    >
      <el-table
        :data="bidTableValues"
        :highlight-current-row="true"
        size="mini"
        stripe
      >
        <el-table-column label="Field" prop="label" />
        <el-table-column label="Value" prop="value" />
      </el-table>
    </el-dialog>


    <el-dialog
      :show-close="false"
      :visible="!!deletionInformation"
      :title="`Do you really want to delete this ${entity}?`"
      @close="deletionInformation = null"
    >
      <template v-if="!!deletionInformation && !!deletionInformation.reasons && deletionInformation.reasons.length">

        <div
          v-for="reason in deletionInformation.reasons"
          :key="reason.related"
          style="margin-bottom: 40px"
        >
          <div v-if="reason.related === 'bid'">
            <p><strong class="danger">WARNING!! This {{ entity }} is linked to a bid</strong></p>
            <p>{{ reason.message }}</p>
            <el-button
              v-if="reason.related === 'bid'"
              type="primary"
              size="small"
              @click="loadBidDetails"
            >View bid
            </el-button>
          </div>
          <div
            v-if="reason.related === 'location'"
          >
            <p><strong class="danger">WARNING!! This {{ entity }} still has one or more streets / MFGs / muffen</strong></p>
            <p>{{ reason.message }}</p>
          </div>
          <div
            v-if="reason.related === 'position'"
          >
            <p><strong class="danger">WARNING!! One or more positions are already created on this {{ entity }}</strong></p>
            <p>{{ reason.message }}</p>
          </div>
        </div>

        <div v-if="!deletionInformation.canDelete">
          <br><br>
          <p>Please contact an administrator if you need this {{ entity }} deleted</p>
          <p><a href="mailto:business.intelligence@soli-infra.de" target="_blank">business.intelligence@soli-infra.de</a></p>
        </div>


        <span slot="footer" class="dialog-footer">
          <el-button @click="deletionInformation = null">Cancel</el-button>
          <el-button
            :disabled="!deletionInformation.canDelete"
            type="danger"
            @click="deleteItem(deletionInformation.item, true)"
          >Delete</el-button
          >
        </span>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { cloneDeep } from 'lodash-es'
import { formatErrorMessage } from '@/js/common/util';
import BidsApi from '@/js/api/v2/bids';

export default {
  name: 'ProjectsSingleAreasListView',


  filters: {
    niceLabel(string) {
      const result = string.replace(/([A-Z])/g, ' $1')
      return result.charAt(0).toUpperCase() + result.slice(1)
    },
  },
  props: {
    // Project ID
    projectId: {
      type: Number,
      required: true,
    },

    // The currently selected area
    area: {
      type: Object,
      required: false,
      default: () => {},
    },

    // The initial set of items to populate this view with
    items: {
      type: Array,
      required: true,
    },

    // items and localItems is an array with objects. This attribute is used to display one attribute in the list view
    labelAttribute: {
      type: String,
      required: true,
    },

    // an optional second attribute
    secondaryLabelAttribute: {
      type: String,
      required: false,
      default: null,
    },

    // if we have a secondary item label, how wide should it's column be
    secondaryLabelColumnSpan: {
      type: Number,
      default: 12,
      validator: (value) => {
        if (!value) return true
        return value > 0 && value < 25
      },
    },

    // name of the entity (area / street / muffe / MFG)
    entity: {
      type: String,
      required: true,
    },

    // The title of the card
    title: {
      type: String,
      required: true,
    },

    // Function must return a promise
    addHandler: {
      type: Function,
      default: null,
    },
    // Function must return a promise
    deleteHandler: {
      type: Function,
      default: null,
    },
    // Function must return a promise
    updateHandler: {
      type: Function,
      default: null,
    },

    // If set, you can filter this view
    filterable: {
      type: Boolean,
      default: false,
    },
    // If set, you can sort this view (by default, new items are added in the top, rather than in alphabetical order
    sortable: {
      type: Boolean,
      default: false,
    },
    // If a row can be selected
    selectable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      filter: '',
      addedName: '',
      addedSecondaryValue: '',
      editedItem: null,
      deletionInformation: null,
      selectedId: null,
      selectedLabel: null,
      viewBidDetails: false,
      bid: false,
      showMoveDialog: false,
      moveItem: {},
      moveToAreaId: null,
    }
  },

  computed: {
    ...mapGetters('projectAreas', { areas: 'items' }),
    moveToAreaOptions() {
      return this.areas.filter(area => area.id !== this.area.id).sort((a, b) => a.name.localeCompare(b.name))
    },
    bidTableValues() {
      let array = []
      array.push({
        label:'Created By',
        value: this.bid.createdBy
      });
      for (const key in this.bid.meta) {
        array.push(this.bid.meta[key]);
      }
      return array
    },
    canAdd() {
      return typeof this.addHandler === 'function'
    },
    canDelete() {
      return typeof this.deleteHandler === 'function'
    },
    canUpdate() {
      return typeof this.updateHandler === 'function'
    },
    canSelect() {
      return true
    },

    filteredAndSortedItems() {
      if (!this.items) return []
      return this.sort(this.items.filter(item => {
        if (!item || !item[this.labelAttribute]) return false
        return item[this.labelAttribute]
          .toLowerCase()
          .indexOf(this.filter.toLowerCase()) >= 0
      }))
    }
  },

  methods: {
    sort(items) {
      return items.sort((a, b) => {
        return a[this.labelAttribute].localeCompare(
          b[this.labelAttribute],
          ['de', 'en'],
          {sensitivity: 'base'}
        )
      })
    },

    openMoveDialog(item) {
      this.moveItem = item
      this.showMoveDialog = true
      this.moveToArea = null
    },
    closeMoveDialog() {
      this.moveItem = {}
      this.showMoveDialog = false
    },
    async moveLocationToSelectedArea() {
      const location = Object.assign({}, this.moveItem)
      const targetArea = this.areas.find(area => area.id === this.moveToAreaId)
      const currentArea = this.area

      const updateString = `${this.entity} '${location.name}' from area '${currentArea.name}' to area '${targetArea.name}'`

      this.$store.dispatch('projectAreas/moveLocation', {
        type: this.entity.toLowerCase(),
        id: this.moveItem.id,
        moveToAreaId: this.moveToAreaId,
      }).then(() => {
        this.closeMoveDialog()
        this.$emit('update')
        this.$message.success(
          {
            message: `Moved ${updateString}`,
            duration: 10000,
          })
      }).catch(error => {
        this.$message.error({
          message: `Error moving ${updateString}: ${error.message}`,
          duration: 0,
        })
      })

    },

    loadBidDetails() {
      BidsApi.showForArea(
        (response) => {
          this.bid = response
          this.viewBidDetails = true
        },
        (error) => {
          this.loading = false
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.deletionInformation.item.id,
        {
          include: ['bid_values'],
        }
      )
    },

    addNewItem() {
      if (
        !this.canAdd ||
        !this.addedName.length ||
        this.items.find(item => item.name.toLocaleLowerCase() === this.addedName.toLocaleLowerCase())
      ) {
        return
      }

      const payload = {
        [this.labelAttribute]: this.addedName,
      }

      if (this.secondaryLabelAttribute) {
        payload[this.secondaryLabelAttribute] = this.addedSecondaryValue
      }

      this.addHandler({payload})
        .then(() => {
          this.addedName = ''
          this.addedSecondaryValue = ''
          this.$refs.mainInput.$el.children[0].focus()
        })
        .catch((error) => {
          this.$message.error({
            showClose: true,
            message: error.message || error,
            duration: 5000,
          })
        })
    },

    editItem(item) {
      this.editedItem = cloneDeep(item)
    },

    saveEditedItem() {
      if (!this.canUpdate || !this.editedItem) {
        return
      }

      this.updateHandler({id: this.editedItem.id, payload: this.editedItem})
        .then((item) => {
          // Finally we probably should hide the popup
          this.editedItem = null

          // And if we modified the item that is selected, lets update it by selecting it again with the new data!
          if (this.selectedId === item.id) {
            this.selectItem(item)
          }
        })
        .catch(error => {
          this.$message.error({
            showClose: true,
            message: error.message || error,
            duration: 5000,
          })
        })
    },

    deleteItem(item, force = false) {
      if (!this.canDelete || !item) {
        return
      }

      this.deleteHandler({id: item.id, payload: { force }})
        .then((response) => {
          if (response.deleted) {
            // Remove popup
            this.deletionInformation = null

            // And if we deleted the item that is selected, lets update it by selecting nothing instead
            if (this.selectedId === item.id) {
              this.selectItem({})
            }
          } else {
            this.deletionInformation = {
              item,
              ...response,
            }
          }
        })
        .catch(error => {
          this.$message.error({
            showClose: true,
            message: error.message || error,
            duration: 5000,
          })
        })
    },

    selectItem(item) {
      if (!this.canSelect || !item) {
        return
      }

      this.selectedId = item.id
      this.selectedLabel = item[this.labelAttribute]
      this.$emit('select', item)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~scss/mixins';

@include tiny-vertical-scroll;

.comp-list-view {
  ::v-deep .el-card__body {
    margin: 0 -10px;

    .el-row {
      font-size: 14px;
      line-height: 30px;
      padding: 0 10px;

      &.item:hover {
        background: rgba(15, 126, 190, 0.2);
      }

      &.item .el-button {
        visibility: hidden;
      }

      &.item:hover .el-button {
        visibility: visible;
      }
    }

    .el-radio__label {
      padding-left: 12px;
    }
  }
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 400px);
  padding-right: 2px;
  margin-right: -1px;
}

.inner-header {
  margin-right: 50px!important;
}

.additional-content-column {
    margin-right: -20px;
  }

.sub-header,
.content {
  float: none;

  &:not(.addition-input) {
    width: unset;
  }

  &.addition-input {
    margin-left: -3px;
    margin-right: 30px;
  }

  &.additional-column {
    margin-right: 0px;
    margin-left: -10px;
  }

  ::v-deep input {
    height: auto;
    font-size: 14px;
    line-height: 22px;
  }

  &.sort {
    width: 62px;
    text-align: right;
  }
}

.addition-input,
.content.label {
  flex-grow: 1;
}

.content.label {
  cursor: pointer;
}

.addition-icon {
  color: #95f204;
  min-width: 30px;
}

.spacer {
  height: 5px;
}
</style>
