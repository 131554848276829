<template>
  <div>
    <div class="header-action-row table-widget table-column-filter">
      <div class="search">
        <h4>Filter</h4>
        <el-form class="dates-filter">
          <el-form-item class="date-filter">
            <el-date-picker
              v-model="start_date"
              :picker-options="quickPicks"
              placeholder="Start date"
              size="mini"
              type="date"
            />
          </el-form-item>
          <el-form-item class="date-filter">
            <el-date-picker
              v-model="end_date"
              :picker-options="quickPicks"
              clearable
              placeholder="End date"
              size="mini"
              type="date"
            />
          </el-form-item>
          <el-form-item class="date-filter">
            <el-select
              v-model="selectedProject"
              clearable
              filterable
              placeholder="Project Name"
              size="mini"
            >
              <el-option
                v-for="project in projects"
                :key="project.id"
                :label="project.name"
                :value="project.id"
              >
              </el-option>
            </el-select>
            <!-- <el-input
              type="text"
              v-model="project"
              placeholder="Project name"
              clearable
            /> -->
          </el-form-item>
          <el-form-item class="show-items-without-date-filter">
            <el-checkbox v-model="showItemsWithoutDate" :checked="false">
              Show items without date
            </el-checkbox>
          </el-form-item>
        </el-form>
        <div>
          <el-button
            class="search-button"
            size="medium"
            type="primary"
            @click="getDatesReport"
          >
            Search
          </el-button>
        </div>
      </div>
    </div>

    <div v-loading="loading" class="wrap-table lerfs-reporting-table">
      <template v-if="data">
        <el-table
          v-loading="spinner"
          :data="data"
          :highlight-current-row="true"
          :max-height="maxHeight"
          border
          class="weekly-report"
          size="mini"
          stripe
          @sort-change="changeSort"
          @filter-change="changeFilter"
        >
          <el-table-column
            :show-overflow-tooltip="true"
            label="#"
            prop="id"
            width="60"
          />

          <el-table-column label="Internal">
            <el-table-column label="Reference" prop="reference" width="90"/>
            <el-table-column
              :show-overflow-tooltip="true"
              label="Description"
              prop="description"
              width="200"
            />
            <el-table-column
              :show-overflow-tooltip="true"
              label="Price"
              prop="price"
              width="80"
            />
            <el-table-column
              :show-overflow-tooltip="true"
              label="Unit"
              prop="unit"
              width="60"
            />
          </el-table-column>

          <el-table-column label="Subcontractor">
            <el-table-column
              :show-overflow-tooltip="true"
              label="Reference"
              prop="subcontractorReference"
              width="90"
            />
            <el-table-column
              :show-overflow-tooltip="true"
              label="Description"
              prop="subcontractorDescription"
              width="200"
            />
            <el-table-column
              :show-overflow-tooltip="true"
              label="Price"
              prop="subcontractorPrice"
              width="80"
            />
            <el-table-column
              :show-overflow-tooltip="true"
              label="Unit"
              prop="subcontractorUnit"
              width="60"
            />
          </el-table-column>

          <el-table-column
            :show-overflow-tooltip="true"
            label="Position #"
            prop="positionId"
            width="90"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Project"
            prop="projectName"
            sortable="custom"
            width="100"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Category"
            prop="categoryName"
            width="120"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Subcategory"
            prop="subcategoryName"
            sortable="custom"
            width="120"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Team"
            prop="team"
            sortable="custom"
            width="100"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Executor"
            prop="executor"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="User"
            prop="user"
            sortable="custom"
            width="80"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Creditor number"
            prop="subcontractorCreditorNumber"
            width="120"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Cost obj."
            prop="costCenter"
            sortable="custom"
            width="100"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Cost object code"
            prop="costObjectCode"
            sortable="custom"
            width="160"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Area Code"
            prop="areaCode"
            sortable="custom"
            width="110"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Area Name"
            prop="areaName"
            sortable="custom"
            width="200"
          />

          <el-table-column
            :show-overflow-tooltip="true"
            label="Length"
            prop="length"
            sortable="custom"
            width="100"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Width"
            prop="width"
            sortable="custom"
            width="100"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Height"
            prop="height"
            sortable="custom"
            width="100"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Amount"
            prop="amount"
            sortable="custom"
            width="100"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Factor"
            prop="factor"
            sortable="custom"
            width="100"
          />
          <el-table-column
            :formatter="(r, c, val) => formatDateFromData(val)"
            :show-overflow-tooltip="true"
            label="Start"
            prop="startDate"
            sortable="custom"
            width="90"
          />
          <el-table-column
            :formatter="(r, c, val) => formatDateFromData(val)"
            :show-overflow-tooltip="true"
            label="End"
            prop="endDate"
            sortable="custom"
            width="90"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Comments"
            prop="comments"
            width="200"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Invoice reference"
            prop="lerfExternalReference"
            width="130"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Approval reference"
            prop="approvalReference"
            width="140"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Created at"
            prop="createdAt"
            sortable="custom"
            width="145"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            label="Updated at"
            prop="updatedAt"
            sortable="custom"
            width="145"
          />
        </el-table>

        <el-row class="bottom-row">
          <el-col :span="12">
            <el-button
              :loading="generatingExcelFile"
              type="primary"
              @click="generateExcelFile('xlsx')"
            >Download excel file
            </el-button>
            <el-button
              :loading="generatingExcelFile"
              type="primary"
              @click="generateExcelFile('csv')"
            >Download csv file
            </el-button>
          </el-col>
          <el-col :span="12">
            <el-pagination
              :background="true"
              :current-page.sync="currentPage"
              :hide-on-single-page="true"
              :page-size="perPage"
              :pager-count="5"
              :small="true"
              :total="total"
              class="pagination"
              layout="sizes, total, prev, pager, next"
              @current-change="perPageChange"
              @size-change="changePageSize"
            />
          </el-col>
        </el-row>
      </template>
    </div>
  </div>
</template>

<script>
import camelCaseKeys from 'camelcase-keys'
import moment from 'moment'

import {
  formatDate,
  formatDateForRequest,
  formatErrorMessage,
} from 'common/util'

import weeklyReportApi from 'api/v2/weeklyReport'
import projectsApi from 'api/v2/projects'

export default {
  name: 'WeeklyReportTable',

  data() {
    const lastWeek = moment().subtract('1', 'weeks').format('YYYY-MM-DD')
    const today = moment().format('YYYY-MM-DD')
    return {
      data: [],
      projects: [],
      id: null,
      currentPage: 1,
      total: null,
      perPage: 50,
      loading: false,
      generatingExcelFile: false,

      spinner: true,

      start_date: lastWeek,
      end_date: today,
      selectedProject: '',

      showItemsWithoutDate: 0,

      quickPicks: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: 'Today',
            onClick(picker) {
              picker.$emit('pick', new Date())
            },
          },
          {
            text: 'Yesterday',
            onClick(picker) {
              const date = new Date(Date.now() - 3600 * 24 * 1000)
              picker.$emit('pick', date)
            },
          },
        ],
      },
    }
  },

  computed: {
    maxHeight() {
      return window.innerHeight - 250
    },
  },

  created() {
    this.fetchProjects()
  },

  mounted() {
    this.loadPage(1)
  },

  methods: {
    fetchProjects() {
      projectsApi.index(
        (response) => {
          this.projects = response
        },
        (error) => {
          this.$message.error({
            message: formatErrorMessage(error),
          })
        }
      )
    },
    getDatesReport() {
      this.loading = true

      const startDate = formatDateForRequest(this.start_date)
      const endDate = formatDateForRequest(this.end_date)

      const params = {
        page: 1,
        startDate,
        endDate,
        showItemsWithoutDate: this.showItemsWithoutDate ? 1 : 0,
      }

      if (this.selectedProject) {
        params.projectId = this.selectedProject
      }

      this.updateData(params, startDate, endDate)
    },
    loadPage(page) {
      this.loading = true

      this.data = []

      const params = {}
      let startDate, endDate

      if (this.start_date) {
        startDate = formatDateForRequest(this.start_date)
      }

      if (this.end_date) {
        endDate = formatDateForRequest(this.end_date)
      }

      params['showItemsWithoutDate'] = this.showItemsWithoutDate ? 1 : 0

      if (this.selectedProject) {
        params.projectId = this.selectedProject
      }

      this.updateData({page, ...params}, startDate, endDate)
    },

    generateExcelFile(extension) {
      this.generatingExcelFile = true

      const startDate = formatDateForRequest(this.start_date)
      const endDate = formatDateForRequest(this.end_date)

      const params = {}
      params['startDate'] = startDate
      params['endDate'] = endDate
      params['showItemsWithoutDate'] = this.showItemsWithoutDate ? 1 : 0
      params['extension'] = extension

      if (this.selectedProject) {
        params.projectId = this.selectedProject
      }

      weeklyReportApi.export(
        (response) => {
          this.generatingExcelFile = false
          window.location = response
        },
        (error) => {
          this.generatingExcelFile = false
          if (error) {
            this.$message.error({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 0,
            })
          }
        },
        'export',
        params
      )
    },

    pageChange(val, sortDirection, prop) {
      this.loading = true

      const start = (val - 1) * this.perPage
      const end = start + this.perPage
      const params = {}

      if (prop === 'startDate') {
        prop = 'start_date'
      }

      if (prop === 'endDate') {
        prop = 'end_date'
      }

      if (prop === 'costCenter') {
        prop = 'cost_center'
      }

      params['page'] = val
      params[sortDirection] = prop

      if (this.start_date && this.end_date) {
        const startDate = formatDateForRequest(this.start_date)
        const endDate = formatDateForRequest(this.end_date)

        params['startDate'] = startDate
        params['endDate'] = endDate
      }

      params['showItemsWithoutDate'] = this.showItemsWithoutDate ? 1 : 0

      if (this.selectedProject) {
        params.projectId = this.selectedProject
      }

      this.updateData(params, start, end)
    },

    updateData(params, start, end) {
      // console.log(
      //   'update data, params: %o, start: %o, end: %o',
      //   params,
      //   start,
      //   end
      // )
      params.perPage = this.perPage
      params.startDate = start
      params.endDate = end

      weeklyReportApi.index(
        (response) => {
          this.data = response.data.map((item) => camelCaseKeys(item))

          this.total = response.meta.total
          this.loading = false
          this.spinner = false
        },
        (error) => {
          this.loading = false
          if (error) {
            this.$message.error({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 0,
            })
          }
        },
        params
      )
    },

    perPageChange(val) {
      this.loading = true
      this.currentPage = val
      this.loadPage(val)
    },

    getOriginLabel(value) {
      if (value) {
        return this.origin[value].label
      }

      return null
    },

    changeSort({prop, order}) {
      let sortDirection
      if (order === 'ascending') {
        sortDirection = 'sortBy'
      } else if (order === 'descending') {
        sortDirection = 'sortByDesc'
      } else {
        sortDirection = null
        return
      }

      if (prop === 'projectName') {
        prop = 'project_name'
      }

      if (prop === 'categoryName') {
        prop = 'category_name'
      }

      if (prop === 'costCenter') {
        prop = 'cost_center'
      }

      if (prop === 'areaCode') {
        prop = 'area_code'
      }

      if (prop === 'areaName') {
        prop = 'area_name'
      }

      this.pageChange(1, sortDirection, prop)
    },

    changeFilter(filters) {
      this.filters = filters

      this.pageChange(1)
    },

    changePageSize(newPageSize) {
      this.perPage = newPageSize

      this.perPageChange(1)
    },

    formatDateFromData(date) {
      return formatDate(date)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .lerfs-reporting-table {
  .bottom-row {
    margin-top: 1em;
  }

  th {
    vertical-align: middle;
  }

  .day-9 {
    background: rgba(#ffdc00, 0.4) !important;
  }

  .day-8 {
    background: rgba(#ff4136, 0.4) !important;
  }

  .day-7 {
    background: rgba(#2ecc40, 0.4) !important;
  }

  .day-6 {
    background: rgba(#b10dc9, 0.4) !important;
  }

  .day-5 {
    background: rgba(#001f3f, 0.4) !important;
    color: #fff;
  }

  .day-4 {
    background: rgba(#ff851b, 0.4) !important;
  }

  .day-3 {
    background: rgba(#0074d9, 0.4) !important;
  }

  .day-2 {
    background: rgba(#7fdbff, 0.4) !important;
  }

  .day-1 {
    background: rgba(#01ff70, 0.4) !important;
  }
}
</style>
