import ApiResource from '../ApiResource'

class ClaimResource extends ApiResource {
  constructor() {
    super({
      name: 'claims',
    })
  }

  export(resolve, reject, project_id = null) {
    this.get(`${this.getUrl()}/export`, { params: { project_id } })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const claimsApi = new ClaimResource()

export default claimsApi
