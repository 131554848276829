<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card>
          <FormGeneral :purchase-invoice="purchaseInvoice" @update="update" />
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card>
          <FormSchedule :purchase-invoice="purchaseInvoice" @update="update" />
        </el-card>
        <el-card
          v-if="purchaseInvoice.project.invoice_template === 'default'"
          class="discount-card"
        >
          <FormDiscount :purchase-invoice="purchaseInvoice" @update="update" />
        </el-card>
        <InvoicingFactor ref="InvoicingFactor" :purchase-invoice="purchaseInvoice"/>
      </el-col>
      <el-col :span="12">
        <el-card>
          <FormStatus :purchase-invoice="purchaseInvoice" @update="update" />
        </el-card>
      </el-col>
    </el-row>
    <el-row
      v-if="projectInvoiceFields"
      :gutter="20"
    >
      <el-col
        v-for="(items, index) of categories"
        :key="`col_${index}`"
        :span="8"
      >
        <div
          v-for="category of items"
          :key="`metaField_${category}`"
          class="meta-card"
        >
          <OverviewDepartment
            v-if="can('view', 'area_meta', category)"
            :project="purchaseInvoiceModified.project"
            :invoice="purchaseInvoiceModified"
            :project-fields="projectInvoiceFields"
            :on-success="setProjectInvoiceFields"
            :category="category"
          />
        </div>
      </el-col>
    </el-row>
    <div v-if="allRevisions && allRevisions.length">
      <revision-table
        :revisions="allRevisions"
        card-title="Invoice meta revisions"
        type="projectMeta"
      />
    </div>
  </div>
</template>

<script>
import FormGeneral from '@/js/components/PurchaseInvoices/FormGeneral'
import FormSchedule from '@/js/components/PurchaseInvoices/FormSchedule'
import FormStatus from  '@/js/components/PurchaseInvoices/FormStatus'
import FormDiscount from '@/js/components/PurchaseInvoices/FormDiscount'
import InvoicingFactor from '@/js/components/Common/Invoices/InvoicingFactor.vue';
import projectPurchaseInvoiceMetaFieldsApi from '@/js/api/v2/projectPurchaseInvoiceMetaFields';
import {AuthorizationError} from '@/js/api/errors';
import {can, formatErrorMessage} from '@/js/common/util';
import OverviewDepartment from '@/js/components/Views/Projects/Single/Overview/Department.vue';
import RevisionTable from '@/js/components/Common/RevisionTable.vue';
import {orderBy} from 'lodash';

export default {
  name: 'PurchaseInvoiceOverview',
  components: {
    RevisionTable,
    OverviewDepartment,
    FormGeneral,
    FormSchedule,
    FormStatus,
    FormDiscount,
    InvoicingFactor
  },
  props: {
    purchaseInvoice: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      projectInvoiceFields: null,
      categories: [[], [], []]
    }
  },
  computed: {
    purchaseInvoiceModified() {
      return {...this.purchaseInvoice, ...{invoiceType:'purchase'}}
    },
    allRevisions() {
      if (!this.projectInvoiceFields) {
        return null
      }
      return orderBy(
        this.projectInvoiceFields.reduce((acc, current) => {
          //eslint-disable-next-line
          console.log(current)
          if (current.revisionHistory) {
            const revisionHistory = current.revisionHistory.map((h) => ({
              name: current.name,
              category: current.category,
              ...h,
            }))
            return acc.concat(revisionHistory)
          }

          return acc
        }, []),
        ['updated_at'],
        ['desc']
      )
    },
  },
  mounted() {
    this.fetchProjectInvoiceFields()
  },
  methods: {
    can(action, category, subcategory) {
      return can(action, category, subcategory)
    },
    update(data) {
      this.$emit('update', data)
      this.$refs.InvoicingFactor.refresh(data)
    },
    setProjectInvoiceFields() {
      this.fetchProjectInvoiceFields()
    },
    fetchProjectInvoiceFields() {
      this.isLoading = true
      projectPurchaseInvoiceMetaFieldsApi.index(
        (response) => {
          this.isLoading = false
          this.$set(this, 'projectInvoiceFields', response)

          // extract the categories
          let colsIndex = 0;
          let categoriesIncluded = [];
          this.categories = [[], [], []]

          for (const item of response) {
            if(!categoriesIncluded.includes(item.category)) {
              categoriesIncluded.push(item.category)
              this.categories[colsIndex].push(item.category)
              colsIndex++;
              if(colsIndex >= 3) {
                colsIndex = 0
              }
            }
          }
        },
        (error) => {
          this.isLoading = false
          if (error instanceof AuthorizationError) {
            this.$router.push('/')
          }

          this.$message.error({
            message: formatErrorMessage(error),
          })
        },
        this.purchaseInvoice.project.id,
        this.purchaseInvoice.id
      )
    },
  },
}
</script>
<style scoped>
::v-deep .discount-card,
.meta-card {
  margin-top: 1.5em;
}
</style>
