<template>
  <div class="sales-invoice-edit-form">
    <el-form label-width="120px" label-position="top">
    <el-form-item label="External reference (Invoice number)">
      <el-input v-model="lerfForm.externalReference" />
    </el-form-item>
    <el-form-item label="External reference 2 (Beleg/Auftrag number)">
      <el-input v-model="lerfForm.externalReferenceSecond" />
    </el-form-item>
    <el-form-item label="External reference 3 (Abschlagsnummer)">
      <el-select
        v-model="lerfForm.externalReferenceThird"
        placeholder="Please select an installment number"
        filterable
      >
        <el-option
          v-for="item in installmentNumbers"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="Origin">
      <el-select
        v-model="lerfForm.origin"
        placeholder="Please select an origin"
        filterable
      >
        <el-option
          v-for="item in origin"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form-item>
    <el-form-item
      v-if="lerf.origin === 'nachunternehmer'"
      label="Subcontractor"
    >
      <el-select
        v-model="lerfSubcontractorId"
        placeholder="Please select a subcontractor"
        :loading="loadingSubcontractors"
        filterable
      >
        <el-option
          v-for="subcontractor in subcontractors"
          :key="subcontractor.id"
          :label="subcontractor.name"
          :value="subcontractor.id"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="Area">
      <el-select
        v-model="lerfForm.area"
        placeholder="Please select an area"
        multiple
        filterable
      >
        <el-option
          v-for="(area) in areas"
          :key="area.id"
          :label="area.name"
          :value="area.name"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="Description">
      <el-input v-model="lerfForm.description" type="textarea" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit"> Update </el-button>
    </el-form-item>
  </el-form>
  </div>
</template>

<script>
import lerfsApi from 'api/v2/lerfs'
import subcontractorsApi from 'api/v2/subcontractors'
import projectAreasApi from 'api/v2/projectAreas'
import { formatErrorMessage, logErrorMessage } from '../../common/util'

export default {
  name: 'LerfFormGeneral',
  props: {
    lerf: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      lerfForm: {
        externalReference: null,
        externalReferenceSecond: null,
        externalReferenceThird: null,
        origin: null,
        area: null,
        description: null,
        subcontractor: null,
      },
      subcontractors: [],
      installmentNumbers: [ // Abschlagsnummer(s)
        {value:'', 'label': '(none)'},
        {value: 'Abschlagsrechnung-Nr.1'},
        {value: 'Abschlagsrechnung-Nr.2'},
        {value: 'Abschlagsrechnung-Nr.3'},
        {value: 'Abschlagsrechnung-Nr.4'},
        {value: 'Abschlagsrechnung-Nr.5'},
        {value: 'Abschlagsrechnung-Nr.6'},
        {value: 'Abschlagsrechnung-Nr.8'},
        {value: 'Abschlagsrechnung-Nr.9'},
        {value: 'Abschlagsrechnung-Nr.10'},
        {value: 'Abschlagsrechnung-Nr.11'},
        {value: 'Abschlagsrechnung-Nr.12'},
        {value: 'Abschlagsrechnung-Nr.13'},
        {value: 'Abschlagsrechnung-Nr.14'},
        {value: 'Abschlagsrechnung-Nr.15'},
        {value: 'Abschlagsrechnung-Nr.16'},
        {value: 'Abschlagsrechnung-Nr.17'},
        {value: 'Abschlagsrechnung-Nr.18'},
        {value: 'Abschlagsrechnung-Nr.19'},
        {value: 'Abschlagsrechnung-Nr.20'},
        {value: 'Abschlagsrechnung-Nr.21'},
        {value: 'Abschlagsrechnung-Nr.22'},
        {value: 'Abschlagsrechnung-Nr.23'},
        {value: 'Abschlagsrechnung-Nr.24'},
        {value: 'Abschlagsrechnung-Nr.25'},
        {value: 'Abschlagsrechnung-Nr.26'},
        {value: 'Abschlagsrechnung-Nr.27'},
        {value: 'Abschlagsrechnung-Nr.28'},
        {value: 'Abschlagsrechnung-Nr.29'},
        {value: 'Abschlagsrechnung-Nr.30'}
      ],
      loadingSubcontractors: true,
      // categories: [],
      areas: [],
    }
  },


  computed: {
    origin() {
      return this.$store.getters['global/getEnumById']('lerf_origin')
    },
    lerfSubcontractorId: {
      get() {
        return this.lerfForm.subcontractor ? this.lerf.subcontractor.id : null
      },
      set(value) {
        if (!value) {
          this.lerfForm.subcontractor = null
        } else {
          this.lerfForm.subcontractor = this.subcontractors.find(
            (subcontractor) => subcontractor.id === value
          )
        }
      },
    },
  },

  watch: {
    'lerf.origin'(value) {
      if (value !== 'nachunternehmer') {
        this.lerfSubcontractorId = null
      }
    },
    'lerf.externalReferenceThird'(value) {
      this.externalReferenceThird = value;
    }
  },

  created() {
    this.lerfForm = this.lerf
    // this.loadCategories()
    subcontractorsApi.index(
      (response) => {
        this.loadingSubcontractors = false
        this.subcontractors = response
      },
      (error) => {
        this.loadingSubcontractors = false
        throw error
      }
    )

    projectAreasApi.index(
      (response) => {
        this.areas = response
      },
      (error) => {
        this.loading = false
        logErrorMessage(error)
      },
      this.lerf.projectId
    )
  },

  methods: {
    onSubmit() {
      lerfsApi.update(
        (response) => {
          this.$emit('update', response)

          this.$message({
            type: 'success',
            message: 'Updated',
            duration: 3000,
          })
        },
        (error) => {
          this.loading = false
          this.$message({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.lerf.id,
        {
          external_reference: this.lerfForm.externalReference,
          external_reference_second: this.lerfForm.externalReferenceSecond,
          external_reference_third: this.lerfForm.externalReferenceThird,
          origin: this.lerfForm.origin,
          category_id: this.lerf.categoryId,
          description: this.lerfForm.description,
          subcontractor_id: this.lerfForm.subcontractor
            ? this.lerfForm.subcontractor.id
            : null,
          area: this.lerfForm.area,
        }
      )
    },
  },
}
</script>

<style lang="scss">
.sales-invoice-edit-form {
  .el-select {
    width: 100%;
  }
}
</style>
