import ApiResource from '../ApiResource'

class ProjectClaimItemResource extends ApiResource {
  constructor() {
    super({
      name: 'projects.claims.items',
    })
  }
}

const projectClaimItemsApi = new ProjectClaimItemResource()

export default projectClaimItemsApi
