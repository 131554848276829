import ApiResource from '../ApiResource'

class ProjectApprovalResource extends ApiResource {
  constructor() {
    super({
      name: 'projects.approvals',
    })
  }

  approveAll(resolve, reject, projectId, approvalId) {
    this.ids = [projectId, approvalId]
    this.post(`${this.getUrl()}/approve`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error))
  }

  addItems(resolve, reject, projectId, approvalId, params) {
    this.ids = [projectId, approvalId]
    this.post(`${this.getUrl()}/add-items`, params)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error))
  }

  resetItems(resolve, reject, projectId, approvalId) {
    this.ids = [projectId, approvalId]
    this.post(`${this.getUrl()}/reset-items`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error))
  }

  email(resolve, reject, projectId, approvalId, payload) {
    this.ids = [projectId, approvalId]
    this.post(`${this.getUrl()}/email`, payload)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error))
  }

  positions(resolve, reject, projectId, approvalId, params) {
    this.ids = [projectId, approvalId]
    this.get(`${this.getUrl()}/positions`, { params })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error))
  }
}

const projectApprovalsApi = new ProjectApprovalResource()

export default projectApprovalsApi
