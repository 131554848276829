<template>
  <div v-loading.body="loadingData" class="project-single-view">
    <el-tabs :value="tab" @tab-click="clickedTab">
      <el-tab-pane label="Overview" name="overview">
        <project-single-overview
          v-if="tab === 'overview' && !loadingData"
          :project="currentProject"
        />
      </el-tab-pane>

      <el-tab-pane
        v-if="canViewAreas && !isNewProject"
        label="Areas"
        name="areas"
      >
        <project-areas
          v-if="tab === 'areas' && !loadingData"
          :project="currentProject"
          :area-id="areaId"
        />
      </el-tab-pane>

      <el-tab-pane
        v-if="canViewAreas && !isNewProject"
        label="Area meta"
        name="area-meta"
      >
        <ProjectAreaMeta
          v-if="tab === 'area-meta' && !loadingData"
          :project="currentProject"
          :area-id="areaId"
        />
      </el-tab-pane>

      <el-tab-pane v-if="!isNewProject" label="Access" name="access">
        <project-access
          v-if="tab === 'access' && !loadingData"
          :project="currentProject"
        />
      </el-tab-pane>

      <el-tab-pane
        v-if="canViewApprovals && !isNewProject"
        label="Approvals"
        name="approvals"
      >
        <template
          v-if="canViewApprovals && tab === 'approvals' && !loadingData"
        >
          <project-single-approval v-if="$route.params.approvalId" />
          <project-approvals v-else :project="currentProject" />
        </template>
      </el-tab-pane>

      <el-tab-pane
        v-if="canViewLerf && !isNewProject"
        label="Sales Invoices"
        name="lerfs"
      >
        <template v-if="tab === 'lerfs' && !loadingData">
          <project-single-lerf v-if="$route.params.lerfId" />
          <project-lerfs v-else :project="currentProject" />
        </template>
      </el-tab-pane>

      <el-tab-pane
        v-if="canViewLerf && !isNewProject"
        label="Purchase Invoices"
        name="purchase-invoices"
      >
        <project-single-purchase-invoice v-if="$route.params.purchaseInvoiceId"  />
        <project-purchase-invoices v-else :project="currentProject" />
      </el-tab-pane>


      <el-tab-pane
        v-if="canViewPosition && !isNewProject"
        label="Positions"
        name="positions"
      >
        <template v-if="tab === 'positions' && !loadingData">
          <project-single-position v-if="$route.params.positionId" />
          <project-positions v-else :project="currentProject" />
        </template>
      </el-tab-pane>

      <el-tab-pane
        v-if="canViewClaim && !isNewProject"
        label="Claims"
        name="claims"
      >
        <template v-if="tab === 'claims' && !loadingData">
          <ProjectSingleClaim v-if="$route.params.claimId" />
          <ProjectClaims v-else :project="currentProject" />
        </template>
      </el-tab-pane>

      <el-tab-pane
        v-if="canViewPricing && !isNewProject"
        label="Pricing"
        name="pricing"
      >
        <project-pricing
          v-if="tab === 'pricing' && !loadingData"
          :project="currentProject"
        />
      </el-tab-pane>

      <el-tab-pane v-if="!isNewProject" label="Notes" name="notes">
        <ProjectNotes
          v-if="tab === 'notes' && !loadingData"
          :project="currentProject"
        />
      </el-tab-pane>

      <el-tab-pane v-if="!isNewProject" label="Documents" name="documents">
        <ProjectDocuments
          v-if="tab === 'documents' && !loadingData"
          :project="currentProject"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AuthorizationError } from 'api/errors'
import { can } from 'common/util'
import ProjectSingleOverview from './Overview'
import ProjectAreas from './Areas'
import ProjectAreaMeta from './AreaMeta'
import ProjectAccess from './Access'
import ProjectApprovals from '@/js/components/Views/Projects/Single/Approvals/ProjectApprovals';
import ProjectSingleApproval from './Approvals/Single'
import ProjectLerfs from './Lerfs'
import ProjectPurchaseInvoices from './PurchaseInvoices'
import ProjectSingleLerf from './Lerfs/Single'
import ProjectSinglePurchaseInvoice from './PurchaseInvoices/Single'
import ProjectPositions from './Positions'
import ProjectSinglePosition from './Positions/Single'
import ProjectClaims from './Claims'
import ProjectSingleClaim from './Claims/Single'
import ProjectPricing from './Pricing'
import ProjectNotes from './Notes'
import ProjectDocuments from './ProjectDocuments'

import projectsApi from 'api/v2/projects'
import {logErrorMessage} from '@/js/common/util'

export default {
  name: 'ProjectSingleView',

  components: {
    ProjectAreas,
    ProjectAccess,
    ProjectApprovals,
    ProjectSingleApproval,
    ProjectDocuments,
    ProjectLerfs,
    ProjectPurchaseInvoices,
    ProjectSingleLerf,
    ProjectSinglePurchaseInvoice,
    ProjectPositions,
    ProjectSinglePosition,
    ProjectClaims,
    ProjectSingleClaim,
    ProjectNotes,
    ProjectSingleOverview,
    ProjectPricing,
    ProjectAreaMeta,
  },

  props: {
    tab: {
      type: String,
      required: true,
    },

    areaId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      loadingData: true,
    }
  },

  computed: {
    ...mapGetters('projects', ['currentProject']),

    canViewAreas() {
      return can('update', 'project') || can('view', 'project')
    },

    canViewLerf() {
      return can('view', 'lerf')
    },

    canViewPosition() {
      //todo
      return can('view', 'position')
    },

    canViewClaim() {
      //todo
      return can('view', 'lerf')
    },

    canViewApprovals() {
      return can('view', 'approval')
    },

    canViewPricing() {
      return can('view', 'pricing_template')
    },

    isNewProject() {
      return !this.currentProject || !this.currentProject.id
    },

    statuses() {
      return this.$store.getters['global/getEnumById']('project_status')
    },
  },

  watch: {
    $route() {
      if (
        !this.currentProject ||
        parseInt(this.$route.params.projectId) !==
          parseInt(this.currentProject.id)
      ) {
        this.fetchProject()
      }
    },

    currentProject: {
      deep: false,
      immediate: false,
      handler(project, oldProject) {
        this.$store.commit('global/titleTag', this.status(project.status))
        this.$store.commit('global/titleColor', this.color(project.status))

        if (
          oldProject &&
          oldProject.id &&
          oldProject.id !== project.id &&
          project.id
        ) {
          this.fetchProject()
        }
      },
    },
  },

  created() {
    if (this.$route.name === 'newProject') {
      this.$store.commit('projects/currentProject', {})
      this.loadingData = false
    } else {
      this.fetchProject()
    }
  },

  beforeDestroy() {
    this.$store.commit('global/titleTag', null)
    this.$store.commit('global/titleColor', null)
  },

  methods: {
    clickedTab(tabComponent) {
      this.$router.replace(
        `/projects/${this.$route.params.projectId}/${tabComponent.name}`
      )
    },
    fetchProject() {
      this.loadingData = true

      projectsApi.getSingleItem(
        (response) => {
          this.$store.commit('projects/currentProject', response)
          this.loadingData = false
        },
        (error) => {
          logErrorMessage(error)
          this.loadingData = false

          let errorMessage = error.message
          if (error instanceof AuthorizationError) {
            errorMessage = 'You are not allowed to perform this action'
            this.$router.push('/projects')
          }

          this.$nextTick(() => {
            this.$message.error({
              message: errorMessage,
            })
          })
        },
        this.$route.params.projectId,
        {
          include: [
            'projectManagerContact',
            'projectLeadContact',
            'customer',
            'notes',
            'lerfs',
          ],
        }
      )
    },

    color(status) {
      if (this.statuses[status] && this.statuses[status].meta) {
        return this.statuses[status].meta.color
      }

      return undefined
    },

    status(status) {
      if (this.statuses[status] && this.statuses[status].label) {
        return this.statuses[status].label
      }

      return status
    },
  },
}
</script>

<style lang="scss"></style>
