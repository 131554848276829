<template>
  <div>
    <div>
      <el-row style="margin-bottom: 20px;">
        <el-col :span="24">
          <el-date-picker
            v-model="startDate"
            clearable
            format="yyyy-MM-dd"
            placeholder="Pick a start date..."
            style="max-width: 140px"
            type="date"
          />

          <el-date-picker
            v-model="endDate"
            format="yyyy-MM-dd"
            placeholder="Pick an end date..."
            style="max-width: 140px"
            type="date"

          />
          <el-button :loading="loading" type="primary" @click="fetchReport">
            Search
          </el-button>
        </el-col>
      </el-row>
    </div>

    <div v-loading="loading">
      <div v-if="!loading && !contactsWithPositions.length">
        <p>No results</p>
      </div>
      <div v-if="contactsWithPositions.length">
        <div v-for="contact in contactsProjects" :key="contact.id">
          <h2 class="subcontractor-title">
            {{ contact.subcontractor ? contact.subcontractor.name : 'Unknown or deleted subcontractor' }}</h2>
          <h3 class="subcontractor-contact-title">{{ contact.fullname }}</h3>
          <table
            class="subcontractor-report table el-table"
          >

            <thead>
            <tr>
              <th width="400">Project</th>
              <th class="text-right" width="90"># Positions</th>
              <th class="text-right" width="80"># Items</th>
              <th class="text-right" width="160">Item statuses</th>
              <th width="640">Approvals</th>
              <th width="100"></th>
            </tr>
            </thead>
            <tbody>
            <template v-for="project in contact.projects">
              <tr
                v-if="project.positions && project.positions.length"
                :key="`${contact.id}-${project.id}`"
              >
                <td>{{ project.name }}</td>
                <td class="text-right">{{ project.positions.length }}</td>
                <td class="text-right">
                  {{ project.positionItemCount }}
                </td>
                <td class="text-right">
                  <el-tag title="Available items" type="gray">{{ project.approvalAvailableItemCount }}</el-tag>
                  <el-tag v-if="project.approvalTotalItemCount" title="Selected items" type="primary">
                    {{ project.approvalTotalItemCount }}
                  </el-tag>
                  <el-tag v-if="project.approvalApprovedItemCount" title="Approved items" type="success">
                    {{ project.approvalApprovedItemCount }}
                  </el-tag>
                  <el-tag v-if="project.approvalUndecidedItemCount" title="Undecided items" type="warning">
                    {{ project.approvalUndecidedItemCount }}
                  </el-tag>
                  <el-tag v-if="project.approvalDeniedItemCount" title="Disapproved items" type="danger">
                    {{ project.approvalDeniedItemCount }}
                  </el-tag>
                </td>
                <td>
                  <template v-if="project.approvals.length">
                    <div v-for="approval in project.approvals" :key="approval.id" class="existing-approval">
                      <div>{{ approval.reference }}</div>
                      <div v-if="approval.created_by_contact">
                        {{ approval.created_by_contact.fullname }}
                      </div>
                      <div class="aproval-item-statuses">
                        <el-tag v-if="approval.total_items_count" title="Selected items" type="primary">
                          {{ approval.total_items_count }}
                        </el-tag>
                        <el-tag v-if="approval.approved_items_count" title="Approved items" type="success">
                          {{ approval.approved_items_count }}
                        </el-tag>
                        <el-tag v-if="approval.undecided_items_count" title="Undecided items" type="warning">
                          {{ approval.undecided_items_count }}
                        </el-tag>
                        <el-tag v-if="approval.denied_items_count" title="Disapproved items" type="danger">
                          {{ approval.denied_items_count }}
                        </el-tag>
                      </div>
                      <div class="approval-status">
                        <el-tag v-if="approval.status === 'Pending'" title="Approval status" type="primary">Pending
                        </el-tag>
                        <el-tag v-if="approval.status === 'Approved'" title="Approval status" type="success">Approved
                        </el-tag>
                      </div>
                      <div>
                        <el-button
                          size="small"
                          type="success"
                          @click="viewApproval(approval)"
                        >
                          View
                        </el-button>
                      </div>
                    </div>
                  </template>
                </td>
                <td>
                  <el-button
                    :disabled="!project.approvalAvailableItemCount"
                    size="small"
                    type="primary"
                    @click="createApproval(contact, project)"
                  >
                    Add Approval
                  </el-button>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import approvalsApi from 'api/v2/approvals'
import projectApprovalsApi from 'api/v2/projectApprovals'
import {logErrorMessage} from '../../../common/util'

export default {
  name: 'AddApproval',

  data() {
    return {
      contactsWithPositions: [],
      startDate: moment()
        .startOf('week')
        .subtract('2', 'weeks')
        .format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      loading: true,
    }
  },

  computed: {
    contactsProjects() {
      if (!this.contactsWithPositions.length) return []
      return this.contactsWithPositions.map(contact => {
        contact.projects.forEach(project => {
          const approvals = contact.approvals.filter(approval => approval.project_id === project.id)
          project.approvals = approvals
          project.positionItemCount = 0
          project.approvalAvailableItemCount = 0
          project.approvalTotalItemCount = 0
          project.approvalApprovedItemCount = 0
          project.approvalUndecidedItemCount = 0
          project.approvalDeniedItemCount = 0

          project.positions.forEach(position => {
            project.positionItemCount += position.items.length
          })

          project.approvalAvailableItemCount = project.positionItemCount

          approvals.forEach(approval => {
            project.approvalTotalItemCount += approval.total_items_count
            project.approvalApprovedItemCount += approval.approved_items_count
            project.approvalDeniedItemCount += approval.denied_items_count


            // undecided items
            project.approvalUndecidedItemCount += approval.total_items_count
            project.approvalUndecidedItemCount -= approval.approved_items_count
            project.approvalUndecidedItemCount -= approval.denied_items_count

            // unselected items
            project.approvalAvailableItemCount -= approval.total_items_count

          })

          return project
        })
        return contact
      })
    }
  },

  mounted() {
    this.fetchReport()
  },

  methods: {
    fetchReport() {
      this.loading = true
      approvalsApi.report(
        (response) => {
          this.loading = false
          if (response.startDate && response.endDate) {
            this.startDate = moment(this.startDate).format('YYYY-MM-DD')
            this.endDate = moment(this.endDate).format('YYYY-MM-DD')
          }
          if (!response.contacts || !response.contacts.length) {
            this.contactsWithPositions = []
            return
          }
          this.contactsWithPositions = response.contacts
        },
        (error) => {
          this.loading = false
          logErrorMessage(error)
        },
        {
          startDate: moment(this.startDate).format('YYYY-MM-DD'),
          endDate: moment(this.endDate).format('YYYY-MM-DD'),
        }
      )
    },
    createApproval(contact, project) {
      if (!contact.subcontractor) {
        this.$message.error(`Cannot create approval, subcontractor has not been set on contact on ${contact.fullname}`);
        return;
      }
      projectApprovalsApi.store(
        (response) => {
          if (response && response.id) {
            this.$router.push({
              name: 'projectApproval',
              params: {
                projectId: project.id,
                approvalId: response.id,
                tab: 'approvals',
              },
            })
          }
        },
        (error) => {
          this.$message.error(error)
        },
        project.id,
        {
          subcontractor_id: contact.subcontractor.id,
          subcontractor_contact_id: contact.id,
          start_date: moment(this.startDate).format('YYYY-MM-DD'),
          end_date: moment(this.endDate).format('YYYY-MM-DD'),
        }
      )
    },
    viewApproval(approval) {
      this.$router.push({
        name: 'projectApproval',
        params: {
          approvalId: approval.id,
          projectId: approval.project.id,
          tab: 'approvals',
        },
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.text-right {
  text-align: right;
}

.subcontractor-title, .subcontractor-contact-title {
  margin: 0;
}

.subcontractor-title {
  font-size: 20px;
  margin-bottom: 5px;
}

.subcontractor-contact-title {
  font-size: 16px;
  margin-bottom: 5px;
}

table.subcontractor-report {
  margin-bottom: 30px;
  table-layout: fixed;
  width: 1600px;

  > tbody tr:hover td {
    background: lighten($primary, 38);
  }

  tbody tr td {
    height: 33px;
    vertical-align: top;
    line-height: 27px;
  }

  thead tr th, tbody tr td {
    padding: 1px 4px 2px 4px;
  }

  .existing-approval {
    display: inline-block;
    margin: 1px 0;

    > div {
      display: inline-block;
      font-size: 0.9em;

      &:first-child {
        width: 110px;
      }

      &:nth-child(2) {
        width: 220px;
      }

      &:nth-child(3) {
        width: 157px;

      }

      &:nth-child(4) {
        width: 90px;
      }

      &:nth-child(5) {
        width: 52px;
        text-align: right;
      }
    }
  }
}

.subcontractor {
  font-weight: normal;
}

table thead tr.pending-approvals th {
  height: 30px;
  font-size: 0.9em;
}
</style>
