<template>
  <TableWithFilters :project="project"/>
</template>

<script>
import TableWithFilters from '@/js/components/PurchaseInvoices/TableWithFilters.vue'

export default {
  name: 'ProjectSinglePurchaseInvoices',
  components: {
    TableWithFilters,
  },

  props: {
    project: {
      type: Object,
      default: null,
    },
  },
}
</script>
