<template>
  <el-form label-width="120px" label-position="top">
    <el-form-item label="Type">
      <el-select v-model="claimForm.claimType">
        <el-option
          v-for="claimType in claimTypes"
          :key="claimType.value"
          :label="claimType.label"
          :value="claimType.value"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="Category">
      <el-input v-model="claimForm.category" />
    </el-form-item>

    <el-form-item label="Area">
      <el-select
        v-model="claimForm.area"
        placeholder="Please select an area"
        filterable
      >
        <el-option
          v-for="area in areas"
          :key="area.id"
          :label="area.name"
          :value="area.name"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="Description">
      <el-input v-model="claimForm.description" type="textarea" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit"> Update </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import claimsApi from 'api/v2/claims'
import projectAreasApi from 'api/v2/projectAreas'
import { formatErrorMessage } from 'common/util'
import {logErrorMessage} from '../../common/util';

export default {
  name: 'ClaimFormGeneral',
  props: {
    claim: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      claimForm: {
        category: null,
        claimType: null,
        description: null,
        area: null,
      },
      isLoading: true,
      areas: [],
      claimTypes: [
        {
          label: 'D-04',
          value: 'd04',
        },
        {
          label: 'Default',
          value: 'default',
        },
      ],
    }
  },

  created() {

    this.claimForm = this.claim

    projectAreasApi.index(
      (response) => {
        this.isLoading = false
        this.areas = response
      },
      (error) => {
        logErrorMessage(error)

        this.isLoading = false
      },
      this.claim.projectId
    )
  },

  methods: {
    onSubmit() {
      this.isLoading = true

      claimsApi.update(
        (response) => {
          this.$emit('update', response)

          this.$message({
            type: 'success',
            message: 'Updated',
            duration: 3000,
          })
        },
        (error) => {
          this.isLoading = false
          this.$message({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.claim.id,
        {
          claim_type: this.claimForm.claimType,
          category: this.claimForm.category,
          description: this.claimForm.description,
          area: this.claimForm.area,
        }
      )
    },
  },
}
</script>
