import ApiResource from '../ApiResource'
import contactUserApi from './contactUser'

class ContactResource extends ApiResource {
  constructor() {
    super({
      name: 'contacts',
    })
  }

  createUser(resolve, reject, ...params) {
    return contactUserApi.store(resolve, reject, ...params)
  }

  updateUser(resolve, reject, ...params) {
    return contactUserApi.update(resolve, reject, ...params)
  }

  deleteUser(resolve, reject, ...params) {
    return contactUserApi.delete(resolve, reject, ...params)
  }
}

const contactsApi = new ContactResource()

export default contactsApi
