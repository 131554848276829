import { render, staticRenderFns } from "./ItemsRevisionsTable.vue?vue&type=template&id=2eabfd6a&"
import script from "./ItemsRevisionsTable.vue?vue&type=script&lang=js&"
export * from "./ItemsRevisionsTable.vue?vue&type=script&lang=js&"
import style0 from "./ItemsRevisionsTable.vue?vue&type=style&index=0&id=2eabfd6a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports