<template>
  <div class="bid-add-container">
    <el-dialog
      :visible.sync="visible"
      :before-close="close"
      title="Add Bid"
    >
      <el-form
        ref="form"
        label-width="120px"
        label-position="top"
        :model="bid"
        :rules="rules"
      >
        <el-form-item
          v-for="(field, index) in loadedConfiguration.fillable"
          :key="index"
          :ref="index"
          :label="field.name"
          :prop="index"
        >
          <el-input
            v-if="['text', 'string'].includes(field.type)"
            v-model="bid[index]"
            size="medium"
          />
          <el-input
            v-if="['number', 'decimal'].includes(field.type)"
            v-model="bid[index]"
            type="number"
            :step="editNumberInputStep(field)"
            size="medium"
          />
          <el-date-picker
            v-if="['date'].includes(field.type)"
            v-model="bid[index]"
            type="date"
            placeholder="'Pick a date"
            value-format="yyyy-MM-dd"
            format="dd.MM.yyyy"
            size="medium"
          />
          <el-select
            v-if="['dropdown'].includes(field.type)"
            v-model="bid[index]"
            filterable
            allow-create
            size="medium"
          >
            <el-option
              v-for="item in field.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit"> Create </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import BidsApi from 'api/v2/bids'
import { formatErrorMessage } from '../../common/util'
import moment from 'moment'

export default {
  name: 'BidAddDialog',
  data() {
    return {
      visible: true,
      bid: {},
      loadedConfiguration: {
        fillable: {},
      },
      formRules: {},
    }
  },
  computed: {
    rules() {
      return this.formRules
    },
    dateFormat() {
      return 'dd.MM.yyyy'
    },
  },
  created() {
    this.loadFields()
  },
  methods: {
    editNumberInputStep(field) {
      if (field.type === 'decimal') {
        return '0.01'
      } else if (field.type === 'number') {
        return '1'
      }
    },
    itemAdded() {
      this.$emit('itemAdded')
    },
    close(done) {
      this.$emit('cancel')
      if (done && typeof done === 'function') {
        done()
      }
    },
    loadFields() {
      BidsApi.configuration(
        (response) => {
          this.loadedConfiguration = response
          for (const index in this.loadedConfiguration.fillable) {
            let field = this.loadedConfiguration.fillable[index]
            this.formRules[index] = {
              required: true,
              message: field.name + ' is required',
            }
          }
        },
        (error) => {
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        {
          groups: ['fillable'],
        }
      )
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let payload = Object.assign({}, this.bid)
          for (const fieldName in payload) {
            if (['id', 'areaId'].includes(fieldName)) {
              continue
            }
            if (payload[fieldName]) {
              if (
                ['date'].includes(
                  this.loadedConfiguration.fillable[fieldName].type
                )
              ) {
                payload[fieldName] = moment(payload[fieldName]).format(
                  'YYYY-MM-DD 00:00:00'
                )
              }
            }
          }

          BidsApi.store(
            () => {
              this.$message({
                type: 'success',
                message: 'Bid created',
                duration: 4000,
              })
              this.close(this.itemAdded)
            },
            (error) => {
              this.loading = false
              this.$message.error({
                showClose: true,
                type: 'error',
                message: formatErrorMessage(error),
                duration: 0,
              })
            },
            payload
          )
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.bid-add-container {
  .el-input,
  .el-select,
  .el-date-picker {
    width: 100%;
  }
}
</style>
