<template>
  <div>
    <el-row>
      <el-col :span="12">
        <el-card>
          <el-form
            ref="update"
            :rules="updateRules"
            label-position="top"
            label-width="120px"
          >
            <el-form-item label="Name" prop="name" required>
              <el-input v-model="data.name"/>
            </el-form-item>
            <el-button :loading="loading" type="primary" @click="onUpdate">
              Update
            </el-button>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-col :span="12">
        <el-card>
          <el-form
            ref="updatePassword"
            :rules="passwordRules"
            label-position="top"
            label-width="120px"
          >
            <el-form-item label="Password" prop="password" required>
              <el-input v-model="data.password" type="password"/>
            </el-form-item>
            <el-form-item
              label="Password (confirm)"
              prop="password_confirmation"
              required
            >
              <el-input v-model="data.password_confirmation" type="password"/>
            </el-form-item>
            <el-form-item>
              <el-button
                :loading="loading"
                type="primary"
                @click="onUpdatePassword"
              >
                Update password
              </el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import profileApi from 'api/v2/profile'

export default {
  name: 'ProfileView',
  data() {
    return {
      loading: false,
      updateRules: {
        name: [
          {required: true, message: 'Name is required', trigger: 'blur'},
        ],
      },
      passwordRules: {
        password: [
          {required: true, message: 'Enter a new password', trigger: 'blur'},
          {min: 6, message: 'Length should be at least 6', trigger: 'blur'},
        ],
        password_confirmation: [
          {required: true, message: 'Confirm password', trigger: 'change'},
        ],
      },
      data: {
        name: '',
        password: '',
        password_confirmation: '',
      },
    }
  },
  computed: {
    ...mapGetters('global', ['user']),
  },
  mounted() {
    this.data.name = this.user.name
  },
  methods: {
    onUpdate() {
      this.$refs.update.validate(() => {

      })

      this.loading = true

      profileApi.update(
        (response) => {
          this.loading = false
          this.$store.commit('global/user', response.data)
          this.$refs.update.resetFields()
          this.$message({
            type: 'success',
            message: 'Profile updated',
            duration: 1000,
          })
        },
        (error) => {
          this.loading = false
          this.$message({
            showClose: true,
            type: 'error',
            message: 'Could not update profile: ' + error['name'][0],
            duration: 1000,
          })
        },
        {
          name: this.data.name,
        }
      )
    },
    onUpdatePassword() {
      if (
        this.data.password.length !== this.data.password_confirmation.length
      ) {
        window.alert('Passwords don\'t match')

        return
      }

      let object = {
        name: this.data.name,
        password: this.data.password,
        password_confirmation: this.data.password_confirmation,
      }

      this.loading = true

      profileApi.update(
        () => {
          this.loading = false
          this.$refs.updatePassword.resetFields()
          this.$message({
            type: 'success',
            message: 'Password updated',
            duration: 1000,
          })
        },
        () => {
          this.loading = false
          this.$message({
            showClose: true,
            type: 'error',
            message: 'Could not update password',
            duration: 1000,
          })
        },
        object
      )
    },
  },
}
</script>
