<template>
  <div class="bid-edit-container">
    <div v-if="bid && !bid.areaId" ref="main" :class="containerCssClass">
      <el-row :gutter="20" class="breadcrumbs-row">
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: lastRoute }"
              >Bids</el-breadcrumb-item
            >
            <el-breadcrumb-item>Editing: #{{ bid.id }}</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col>
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-arrow-left"
            @click="goBack"
          >Back</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>Required Fields</span>
            </div>
            <div
              v-for="(field, index) in loadedConfiguration.fillable"
              :key="index"
              class="bid-field"
            >
              <div :class="getFieldClass[index]">
                <el-row :gutter="40">
                  <el-col :span="12" class="field-label">
                    <label>{{ field.name }}</label>
                    <small>{{ field.description }}</small>
                  </el-col>
                  <el-col :span="12">
                    <template v-if="!editInputShow">
                      <template
                        v-if="
                          ['date'].includes(field.type) &&
                          bid['meta' + field.id]
                        "
                      >
                        {{ formatDisplayDate(bid['meta' + field.id]) }}
                      </template>
                      <template v-else>
                        {{ bid['meta' + field.id] }}
                      </template>
                    </template>
                    <template v-if="cellIsUpdateable('meta_' + field.id)">
                      <el-input
                        v-if="['text', 'string'].includes(field.type)"
                        v-model="bid['meta' + field.id]"
                        :name="'meta_' + field.id"
                        type="text"
                        class="cell-input"
                        size="small"
                      />
                      <el-input
                        v-if="['number', 'decimal'].includes(field.type)"
                        v-model="bid['meta' + field.id]"
                        :name="'meta_' + field.id"
                        type="number"
                        :step="editNumberInputStep(field)"
                        class="cell-input"
                        size="small"
                      />
                      <el-date-picker
                        v-if="['date'].includes(field.type)"
                        v-model="bid['meta' + field.id]"
                        type="date"
                        placeholder="Pick a date"
                        style="width: 100%"
                        value-format="yyyy-MM-dd"
                        format="dd.MM.yyyy"
                        class="cell-input"
                        size="small"
                      />
                      <el-select
                        v-if="['dropdown'].includes(field.type)"
                        v-model="bid['meta' + field.id]"
                        :name="'meta_' + field.id"
                        class="cell-input"
                      >
                        <el-option
                          v-for="item in loadedConfiguration.metaFields[
                            'meta_' + field.id
                          ].options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                          :selected="bid['meta' + field.id] == item.value"
                        />
                      </el-select>
                    </template>
                  </el-col>
                </el-row>
              </div>
            </div>
            <el-row>
              <el-col :span="12">
                <el-button
                  v-if="!editInputShow && !isUpdating"
                  title="Finalize Bid"
                  type="primary"
                  @click="finalizeDialogVisible = true"
                  >Finalize
                </el-button>
                <el-button
                  v-if="editInputShow && !isUpdating"
                  title="Save"
                  type="success"
                  @click="updateBid"
                  >Save
                </el-button>
              </el-col>
              <el-col :span="12" class="text-right">
                <el-button
                  v-if="!editInputShow && !isUpdating"
                  title="Edit Bid"
                  @click="toggleEdit(true)"
                  >Edit
                </el-button>
                <el-button
                  v-if="editInputShow && !isUpdating"
                  title="Cancel"
                  type="danger"
                  @click="toggleEdit(false)"
                  >Cancel
                </el-button>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>Other Fields</span>
            </div>
            <div
              v-for="(field, index) in nonFillableFields"
              :key="index"
              class="bid-field"
            >
              <div :class="getFieldClass[index]">
                <el-row :gutter="40">
                  <el-col :span="12" class="field-label">
                    <label>{{ field.name }}</label>
                    <small>{{ field.description }}</small>
                  </el-col>
                  <el-col :span="12">
                    <template v-if="!editInputShow">
                      <template
                        v-if="
                          ['date'].includes(field.type) &&
                          bid['meta' + field.id]
                        "
                      >
                        {{ formatDisplayDate(bid['meta' + field.id]) }}
                      </template>
                      <template v-else>
                        {{ bid['meta' + field.id] }}
                      </template>
                    </template>
                    <template v-if="cellIsUpdateable('meta_' + field.id)">
                      <el-input
                        v-if="['text', 'string'].includes(field.type)"
                        v-model="bid['meta' + field.id]"
                        :name="'meta_' + field.id"
                        type="text"
                        class="cell-input"
                        size="small"
                      />
                      <el-input
                        v-if="['number', 'decimal'].includes(field.type)"
                        v-model="bid['meta' + field.id]"
                        :name="'meta_' + field.id"
                        type="number"
                        :step="editNumberInputStep(field)"
                        class="cell-input"
                        size="small"
                      />
                      <el-date-picker
                        v-if="['date'].includes(field.type)"
                        v-model="bid['meta' + field.id]"
                        type="date"
                        placeholder="Pick a date"
                        value-format="yyyy-MM-dd"
                        format="dd.MM.yyyy"
                        style="width: 100%"
                        class="cell-input"
                        size="small"
                      />
                      <el-select
                        v-if="['dropdown'].includes(field.type)"
                        v-model="bid['meta' + field.id]"
                        :name="'meta_' + field.id"
                        class="cell-input"
                      >
                        <el-option
                          v-for="item in loadedConfiguration.metaFields[
                            'meta_' + field.id
                          ].options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                          :selected="bid['meta' + field.id] == item.value"
                        />
                      </el-select>
                    </template>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <el-row
        v-if="allRevisions && allRevisions.length"
        class="revision-card-wrapper"
      >
        <el-row class="toggle">
          Bid value revisions

          <el-button
            type="primary"
            class="toggle-button"
            @click="revisionsVisible = !revisionsVisible"
          >
            <template v-if="revisionsVisible === true">
              Hide Revisions
            </template>
            <template v-if="revisionsVisible === false">
              Show Revisions
            </template>
          </el-button>
        </el-row>
        <div v-if="allRevisions && allRevisions.length">
          <revision-table
            card-title=""
            :revisions="allRevisions"
            type="bidValue"
          >
          </revision-table>
        </div>
      </el-row>
      <BidFinalizeDialog
        v-if="finalizeDialogVisible"
        :bid="bid"
        @cancel="closeDialog"
        @itemAdded="refreshPage"
      />
    </div>

    <el-alert
      v-else-if="bid.id"
      title="This bid has already been finalized."
      type="error"
      show-icon
    />
    <el-alert
      v-else-if="!loading"
      title="You are not able to edit/finalize this bid."
      type="error"
      show-icon
    />
  </div>
</template>

<script>
import BidsApi from 'api/v2/bids'
import BidValuesApi from 'api/v2/bidValues'
import { formatErrorMessage } from '../../../common/util'
import RevisionTable from 'components/Common/RevisionTable'
import BidFinalizeDialog from 'components/Bids/FinalizeDialog'
import moment from 'moment'

export default {
  name: 'BidEdit',
  components: {
    RevisionTable,
    BidFinalizeDialog,
  },
  props: {},
  data() {
    return {
      editInputShow: false,
      isUpdating: false,
      bid: false,
      loading: true,
      loadedConfiguration: {
        fillable: {},
      },
      formRules: {},
      loadedRevisions: [],
      revisionsVisible: false,
      finalizeDialogVisible: false,
    }
  },
  computed: {
    lastRoute() {
      return '/bids/open-bids'
    },
    getFieldClass() {
      let classes = {}
      for (const index in this.loadedConfiguration.metaFields) {
        let field = this.loadedConfiguration.metaFields[index]
        classes[index] = 'bid-field-hide field_' + field.id
        if (this.bid['meta' + field.id]) {
          classes[index] += ' has-value'
        }
      }
      return classes
    },
    dateFormat() {
      return 'dd.MM.yyyy'
    },
    nonFillableFields() {
      let fields = {}
      for (const index in this.loadedConfiguration.metaFields) {
        if (!this.loadedConfiguration.fillable[index]) {
          fields[index] = this.loadedConfiguration.metaFields[index]
        }
      }
      return fields
    },
    allRevisions() {
      let result = this.loadedRevisions.map(function (currentObject) {
        currentObject.username =
          currentObject.user.firstname + ' ' + currentObject.user.lastname
        currentObject.updated_at = currentObject.updatedAt
        return currentObject
      })
      return result
    },
    containerCssClass() {
      let classes = []
      classes.push(this.revisionsVisible ? 'show-revisions' : 'hide-revisions')
      classes.push(this.editInputShow ? 'is-editing' : '')
      return classes.join(' ')
    },
  },
  mounted() {
    this.loadFields()
    this.loadBid()
    this.loadRevisions()
  },
  methods: {
    goBack() {
      this.$router.push(this.lastRoute);
    },
    formatDisplayDate(value) {
      if (value.length <= 0) {
        return
      }
      let newValue = value
      return moment(newValue).format('DD.MM.YYYY')
    },
    closeDialog() {
      this.finalizeDialogVisible = false
    },
    toggleEdit(status) {
      this.editInputShow = status
    },
    editNumberInputStep(field) {
      if (field.type === 'decimal') {
        return '0.01'
      } else if (field.type === 'number') {
        return '1'
      }
    },
    cellIsUpdateable(column) {
      if (this.loadedConfiguration.metaFields[column] === undefined) {
        return false
      }
      return this.loadedConfiguration.metaFields[column].updateable
    },
    loadBid() {
      if (this.$route.params.bidId === undefined) {
        return
      }
      BidsApi.show(
        (response) => {
          this.bid = response
          this.loading = false
        },
        (error) => {
          this.loading = false
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.$route.params.bidId,
        {
          include: ['bid_values'],
        }
      )
    },
    updateBid() {
      if (this.$route.params.bidId === undefined) {
        return
      }
      this.isUpdating = true
      this.toggleEdit(false)
      let bidValues = []

      for (const fieldName in this.bid) {
        if (['id', 'areaId', 'createdBy'].includes(fieldName)) {
          continue
        }

        let metaField = fieldName.replace('meta', 'meta_')
        if (this.bid[fieldName]) {
          if (
            this.bid[fieldName] &&
            ['date'].includes(
              this.loadedConfiguration.metaFields[metaField].type
            )
          ) {
            this.bid[fieldName] = moment(this.bid[fieldName]).format(
              'YYYY-MM-DD'
            )
          }
        }

        bidValues.push({
          project_field_id: fieldName.replace('meta', ''),
          value: this.bid[fieldName],
        })
      }
      BidValuesApi.update(
        (response) => {
          this.bid = response
          this.isUpdating = false
          this.loadRevisions()
          if (!response.validationErrors) {
            this.$message({
              type: 'success',
              message: 'Bid updated',
              duration: 4000,
            })
          } else {
            let error_message = [
              '<strong>Bid saved, but with errors, see error(s):</strong><br />'
            ]

            for (const field_key in response.validationErrors) {
              error_message.push(
                response.validationErrors[field_key].name + ': ' + response.validationErrors[field_key].error
              )
            }
            this.$message.error({
              showClose: true,
              type: 'error',
              dangerouslyUseHTMLString: true,
              message: error_message.join('<br />'),
              duration: 0,
            })
          }

          this.loadFields()
          this.loadBid()
          this.loadRevisions()
        },
        (error) => {
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.$route.params.bidId,
        {
          bid_values: bidValues,
          include: ['bid_values'],
        }
      )
    },
    loadFields() {
      BidsApi.configuration(
        (response) => {
          this.loadedConfiguration = response
          for (const index in this.loadedConfiguration.fillable) {
            let field = this.loadedConfiguration.fillable[index]
            this.formRules[index] = {
              required: true,
              message: field.name + ' is required',
            }
          }
        },
        (error) => {
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        {
          groups: ['fillable', 'meta'],
        }
      )
    },
    loadRevisions() {
      BidsApi.revisions(
        (response) => {
          this.loadedRevisions = response
        },
        (error) => {
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        {
          bidId: this.$route.params.bidId,
        }
      )
    },
    refreshPage() {
      this.$nextTick(() => {})
    },
  },
}
</script>
<style lang="scss" scoped>
.bid-edit-container {
  .el-breadcrumb {
    margin-bottom: 15px;
  }
  .bid-field {
    .el-row {
      padding: 15px;
      border-bottom: 1px solid #eff2f7;
    }

    .field-label {
      label {
        font-weight: bold;
        display: block;
      }

      small {
        display: block;
      }
    }

    .bid-field-hide,
    .cell-input {
      display: none;
    }

    .bid-field-hide.has-value {
      display: block;
    }
  }

  .is-editing {
    .bid-field {
      .bid-field-hide,
      .cell-input {
        display: block;
      }
    }
  }

  .bid-field + .el-row {
    margin-top: 30px;
  }

  .text-right {
    text-align: right;
  }

  .revision-card-wrapper {
    .toggle-button {
      float: right;
    }

    margin-top: 1em;

    .toggle {
      padding: 0.5em 0.5em 0.5em 1em;
      line-height: 35px;
      font-size: 15px;
      border: 1px solid #d1dbe5;
      border-radius: 4px;
      background: #fff;
      overflow: hidden;
    }
  }

  .revision-card .el-card__header {
    display: none;
  }

  .revision-card .el-card__body .el-table__body-wrapper {
    max-height: 49vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .hide-revisions {
    .revision-card {
      display: none;
    }
  }

  .show-revisions .native-table-outer-row {
    max-height: 25vh;
    overflow: auto;
  }

  .breadcrumbs-row {
    margin-bottom: 10px;

    .el-col {
      width: auto;
    }
    .el-breadcrumb {
      margin-bottom: 0;
      line-height:22px;
    }
  }

}
</style>
