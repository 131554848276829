import ApiResource from '../ApiResource'

class ProjectNoteResource extends ApiResource {
  constructor() {
    super({
      name: 'projects.notes',
    })
  }
}

const projectNotesApi = new ProjectNoteResource()

export default projectNotesApi
