<template>
  <div v-loading="loading" class="positions-view">
    <template v-if="data">
      <el-row  v-if="showAddButton" :gutter="24" class="header-action-row">
        <el-col :span="3">
          <el-button
            v-if="canAddPositions"
            type="primary"
            @click="addDialogVisible = true"
          >
            Add Position
          </el-button>
          <div class="grid-content bg-purple" />
        </el-col>
      </el-row>

      <div class="header-action-row table-widget">
        <div class="search">
          <h4>Search columns</h4>
          <div>
            <el-input
              v-model="searchValue"
              placeholder="Position Id"
              suffix-icon="el-icon-search"
              :on-icon-click="search"
              size="mini"
            />
            <el-input
              v-model="searchCostObjectCodeValue"
              placeholder="Kst2"
              suffix-icon="el-icon-search"
              :on-icon-click="search"
              size="mini"
            />
            <el-input
              v-model="searchProjectValue"
              placeholder="Projektname"
              suffix-icon="el-icon-search"
              :on-icon-click="search"
              size="mini"
            />
            <el-input
              v-model="searchAreaValue"
              placeholder="Polygon"
              suffix-icon="search"
              :on-icon-click="search"
              size="mini"
            />
            <el-input
              v-model="searchCreatedAtValue"
              placeholder="Created at"
              suffix-icon="search"
              :on-icon-click="search"
              size="mini"
            />
            <el-input
              v-model="searchCreatedByValue"
              placeholder="Created by"
              suffix-icon="search"
              :on-icon-click="search"
              size="mini"
            />
            <el-input
              v-model="searchTeamValue"
              placeholder="Team"
              suffix-icon="search"
              :on-icon-click="search"
              size="mini"
            />
            <el-input
              v-model="searchExecutorValue"
              placeholder="Executor"
              suffix-icon="search"
              :on-icon-click="search"
              size="mini"
            />
            <el-date-picker
              v-model="searchStartDateValue"
              format="dd.MM.yyyy"
              type="date"
              placeholder="Pick a date"
              style="width: 100%"
              size="mini"
            />
            <el-date-picker
              v-model="searchEndDateValue"
              format="dd.MM.yyyy"
              type="date"
              placeholder="Pick a date"
              style="width: 100%"
              size="mini"
            />
          </div>
        </div>
      </div>

      <el-table
        v-loading="spinner"
        :data="data"
        sum-text="Sum"
        size="mini"
        :highlight-current-row="true"
        stripe
        @cell-dblclick="openItem"
        @sort-change="changeSort"
        @filter-change="changeFilter"
      >
        <el-table-column
          width="120"
          prop="id"
          label="Position Id"
          sortable="custom"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          width="80"
          prop="area.cost_object_code"
          label="Kst2"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          width="180"
          prop="project.name"
          label="Projektname"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          width="200"
          prop="area.name"
          label="Polygon"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          width="130"
          prop="team"
          label="Team"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          width="130"
          prop="executor_name"
          label="Executor"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          width="120"
          prop="start_date"
          label="Start Date"
          sortable="custom"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ formatDateFromData(scope.row.start_date) }}
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          prop="end_date"
          label="End Date"
          sortable="custom"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ formatDateFromData(scope.row.end_date) }}
          </template>
        </el-table-column>
        <el-table-column
          width="140"
          prop="created_at"
          label="Created At"
          sortable="custom"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.created_at }}
          </template>
        </el-table-column>
        <el-table-column
          width="170"
          prop="user"
          label="Created by"
          sortable="custom"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.user ? scope.row.user.name : '' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="comments"
          label="Comments"
          sortable="custom"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          fixeds="right"
          label="Operations"
          width="110"
          class-name="actions"
          :show-overflow-tooltip="true"
          align="right"
        >
          <template slot-scope="scope">
            <el-button
              v-if="canEditPositions"
              size="mini"
              icon="el-icon el-icon-edit"
              @click.native.prevent="editItem(scope.$index)"
            >
            </el-button>
            <el-button
              v-if="canRemovePositions"
              type="danger"
              size="mini"
              icon="el-icon el-icon-delete"
              @click.native.stop.prevent="removeItem(scope.$index)"
            >
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        :per-page="perPage"
        :total="total"
        :current-page="currentPage"
        @pageChange="pageChange"
        @changePageSize="changePageSize"
      />


      <position-add-dialog
        v-if="addDialogVisible"
        :project="project"
        @cancel="closeDialog"
      />
    </template>
  </div>
</template>

<script>
import { formatDate, can, formatErrorMessage } from 'common/util'
import { getNet, getGross } from 'common/positionUtil'
import positionsApi from 'api/v2/positions'
import projectPositionsApi from 'api/v2/projectPositions'
import PositionAddDialog from './AddDialog'
import numeral from 'numeral'
import Pagination from '@/js/components/Common/DefaultPagination.vue';

export default {
  name: 'PositionTable',
  components: {
    PositionAddDialog,
    Pagination
  },

  props: {
    project: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      data: [],
      id: null,
      currentPage: 1,
      total: null,
      perPage: 20,
      loading: false,
      addDialogVisible: false,
      searchValue: null,
      searchProjectValue: null,
      searchCostObjectCodeValue: null,
      searchAreaValue: null,
      searchCreatedAtValue: null,
      searchCreatedByValue: null,
      searchTeamValue: null,
      searchExecutorValue: null,
      searchStartDateValue: null,
      searchEndDateValue: null,
      orderProp: null,
      orderDirection: null,
      filters: null,
      spinner: true,
      isDownloading: false,
      showAddButton: true, // Adding a position doesn't work correctly, disabling for now
    }
  },

  computed: {
    canAddPositions() {
      return can('create', 'position')
    },

    canRemovePositions() {
      return can('delete', 'position')
    },

    canEditPositions() {
      return can('update', 'position')
    },
  },

  watch: {
    searchValue() {
      this.search()
    },
    searchProjectValue() {
      this.pageChange(1)
    },
    searchCostObjectCodeValue() {
      this.pageChange(1)
    },
    searchAreaValue() {
      this.pageChange(1)
    },
    searchCreatedAtValue() {
      this.pageChange(1)
    },
    searchCreatedByValue() {
      this.pageChange(1)
    },
    searchTeamValue() {
      this.pageChange(1)
    },
    searchExecutorValue() {
      this.pageChange(1)
    },
    searchStartDateValue() {
      this.pageChange(1)
    },
    searchEndDateValue() {
      this.pageChange(1)
    },
    project() {
      this.pageChange(1)
    },
  },

  mounted() {
    this.loadPage(1)
  },

  methods: {
    getNet(a, b) {
      return getNet(a, b)
    },

    getGross(a, b) {
      return getGross(a, b)
    },

    search() {
      this.pageChange(1)
    },

    openItem(item) {
      this.$router.push(
        '/projects/' + item.project_id + '/positions/' + item.id + '/overview'
      )
    },

    closeDialog() {
      this.addDialogVisible = false
    },

    editItem(index) {
      this.$router.push(
        '/projects/' +
          this.data[index].project_id +
          '/positions/' +
          this.data[index].id
      )
    },

    removeItem(index) {
      let confirm = window.confirm('Are you sure?')

      if (confirm) {
        positionsApi.delete(
          () => {
            this.$message({
              type: 'success',
              message: 'Deleted',
              duration: 3000,
            })

            this.data.splice(index, 1)
          },
          (error) => {
            this.$message({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 0,
            })
          },
          this.data[index].id
        )
      }
    },
    changePageSize(newPageSize) {
      this.perPage = newPageSize
      this.pageChange(1)
    },
    pageChange(val) {
      this.loading = true
      this.spinner = true
      this.currentPage = val
      this.loadPage(val)
    },

    formatDateFromData(date) {
      return formatDate(date)
    },

    getOriginLabel(value) {
      if (value) {
        return this.origin[value].label
      }

      return null
    },
    loadPage(page) {
      this.data = []
      if (this.project) {
        projectPositionsApi.index(
          (response) => {
            this.loading = false
            this.spinner = false
            this.data = response.data
            this.total = response.meta.total
          },
          (error) => {
            this.loading = false
            this.spinner = false
            this.$message.error({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 0,
            })
          },
          this.project.id,
          {
            page,
            perPage: this.perPage,
            include: ['project', 'location', 'user'],
            search: JSON.stringify({
              search: this.searchValue,
              search_project: this.searchProjectValue,
              search_cost_object_code: this.searchCostObjectCodeValue,
              search_area: this.searchAreaValue,
              search_created_by: this.searchCreatedByValue,
              search_created_at: this.searchCreatedAtValue,
              search_team: this.searchTeamValue,
              search_executor: this.searchExecutorValue,
              search_start_date: this.searchStartDateValue,
              search_end_date: this.searchEndDateValue,
            }),
            orderBy: this.orderProp,
            orderDirection: this.orderDirection,
            filters: this.filters,
          }
        )
      } else {
        positionsApi.index(
          (response) => {
            this.loading = false
            this.spinner = false
            this.data = response.data
            this.total = response.meta.total
          },
          (error) => {
            this.loading = false
            this.spinner = false
            this.$message.error({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 0,
            })
          },
          {
            page,
            perPage: this.perPage,
            search: JSON.stringify({
              search: this.searchValue,
              search_project: this.searchProjectValue,
              search_cost_object_code: this.searchCostObjectCodeValue,
              search_area: this.searchAreaValue,
              search_created_by: this.searchCreatedByValue,
              search_created_at: this.searchCreatedAtValue,
              search_team: this.searchTeamValue,
              search_executor: this.searchExecutorValue,
              search_start_date: this.searchStartDateValue,
              search_end_date: this.searchEndDateValue,
            }),
            orderBy: this.orderProp,
            orderDirection: this.orderDirection,
            filters: this.filters,
            include: ['project', 'location', 'user'],
          }
        )
      }
    },

    changeSort({ prop, order }) {
      this.orderProp = prop
      if (order === 'ascending') {
        this.orderDirection = 'asc'
      } else if (order === 'descending') {
        this.orderDirection = 'desc'
      } else {
        this.orderDirection = null
      }

      this.pageChange(1)
    },

    changeFilter(filters) {
      this.filters = filters

      this.pageChange(1)
    },

    formatNumber(number) {
      return numeral(number).format()
    },

    exportPositions() {
      this.isDownloading = true

      const filename = this.project
        ? `project-${this.project.name}-positions-export`
        : 'positions-export'

      positionsApi.export(
        (response) => {
          let blob = new Blob([response])
          let link = document.createElement('a')
          const objURL = window.URL.createObjectURL(blob)
          link.href = objURL
          link.download = filename + '.csv'
          link.click()

          window.URL.revokeObjectURL(objURL)

          this.isDownloading = false
        },
        (error) => {
          this.isDownloading = false
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.project ? this.project.id : null
      )
    },
  },
}
</script>
