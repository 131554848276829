<template>
  <div>
    <el-form :model="lerf" label-width="120px" label-position="top">
      <el-form-item label="Date from">
        <el-date-picker
          v-model="lerfForm.startAt"
          :picker-options="pickerOptions"
          :format="dateFormat"
          type="date"
          placeholder="Pick a date"
          style="width: 100%"
          @change="fromChanged"
        />
      </el-form-item>

      <el-form-item label="Date to">
        <el-date-picker
          v-model="lerfForm.endAt"
          :picker-options="pickerOptions"
          :format="dateFormat"
          type="date"
          placeholder="Pick a date"
          style="width: 100%"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit"> Update </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import * as util from 'common/util'
import moment from 'moment'
import lerfsApi from 'api/v2/lerfs'

export default {
  name: 'LerfFormSchedule',
  props: {
    lerf: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      lerfForm: {
        startAt: null,
        endAt: null,
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
    }
  },
  computed: {
    dateFormat() {
      return util.getDateFormat('element')
    },
  },
  created() {
    // set default properties
    this.lerfForm = this.lerf;
  },
  methods: {
    fromChanged() {
      let fromMoment = moment(this.lerfForm.startAt)
      this.lerfForm.endAt = fromMoment
        .locale('de-DE')
        .endOf('week')
        .subtract(1, 'd')
    },
    onSubmit() {
      lerfsApi.update(
        (response) => {
          this.$message({
            type: 'success',
            message: 'Updated',
            duration: 4000,
          })
          this.$emit('update', response)
        },
        (error) => {
          this.loading = false
          this.$message.error({
            showClose: true,
            message: util.formatErrorMessage(error),
            duration: 0,
          })
        },
        this.lerf.id,
        {
          start_at: util.formatDateForRequest(this.lerfForm.startAt),
          end_at: util.formatDateForRequest(this.lerfForm.endAt),
        }
      )
    },
  },
}
</script>
