/**
 * @todo refactor
 */
import { get } from '../'

const ROUTE = 'api/v2/item-report'

export default {
  category: 'lerf',

  index(cb, error, params) {
    get(ROUTE, { params: params })
      .then((response) => {
        cb(response.data)
      })
      .catch((response) => {
        error(response.data)
      })
  },

  export(cb, error, format, params) {
    get(`${ROUTE}/${format}`, { params: params })
      .then((response) => {
        cb(response.data)
      })
      .catch((response) => {
        error(response.data)
      })
  },
}
