import ApiResource from '../ApiResource'

class ProjectMetaFieldResource extends ApiResource {
  constructor() {
    super({
      name: 'projects.meta-fields',
    })
  }

  indexForArea(resolve, reject, projectId, areaId) {
    this.ids = [projectId]
    this.get(`${this.getUrl()}?area_id=${areaId}`)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  // storeForArea(resolve, reject, projectId, areaId, payload) {
  //   this.ids = [projectId]
  //   this.post(`${this.getUrl()}?area_id=${areaId}`, payload)
  //     .then((response) => {
  //       resolve(response.data)
  //     })
  //     .catch((error) => {
  //       reject(error)
  //     })
  // }

  exportRevisionHistory(resolve, reject, projectId) {
    this.ids = [projectId]

    this.get(`${this.getUrl()}/export-revision-history`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const projectMetaFieldsApi = new ProjectMetaFieldResource()

export default projectMetaFieldsApi
