/* eslint-disable */
import Vue from 'vue'

import { generateSetters } from '../../helpers.js'

export default {
  ...generateSetters([
    'items',
    'streets',
    'mfgs',
    'muffen',
    'projectId',
    'selectedArea',
    'errored',
    'loading',
  ]),
}
