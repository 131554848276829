import ApiResource from '../ApiResource'

class ProjectPositionResource extends ApiResource {
  constructor() {
    super({
      name: 'projects.positions',
    })
  }
}

const projectPositionsApi = new ProjectPositionResource()

export default projectPositionsApi
