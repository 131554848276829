<template>
  <div v-if="projects.length">
    <el-select
      v-model="projectId"
      clearable
      placeholder="Go to project"
      filterable
      class="project-selector"
      @input="changeSelectedProject"
    >
      <el-option
        v-for="project in projects"
        :key="project.id"
        :label="project.name"
        :value="project.id"
      />
    </el-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {can} from '@/js/common/util';

export default {
  name: 'ProjectSelector',

  data() {
    return {
      projectId: null,
      projects: [],
    }
  },

  computed: {
    ...mapGetters('projects', ['items', 'currentProject']),

    storeProjects() {
      return this.$store.getters['projects/items'] ?? []
    }
  },

  watch: {
    storeProjects(projects) {
      this.projects = projects
    },
    currentProject(value) {
      if (value && value.id && value.id !== this.projectId) {
        this.projectId = value.id
      }
    },
    $route(value) {
      if (!value.params.projectId) {
        this.$nextTick(() => {
          this.projectId = null
        })
      }
    },
  },
  mounted() {
    if (can('view', 'project')) {
      this.$store.dispatch('projects/fetchItems');
    }
  },
  methods: {
    changeSelectedProject(projectId) {
      if (!projectId) {
        return
      }

      const currentProjectId = this.currentProject
        ? this.currentProject.id
        : null

      let name, newRouteparams
      if (this.$route.params.projectId) {
        name = this.$route.name
        newRouteparams = { ...this.$route.params, projectId }
        newRouteparams.tab = newRouteparams.tab || 'overview'
      } else {
        name = 'projectTab'
        newRouteparams = { tab: 'overview', projectId }
      }

      this.$store.dispatch('projects/setCurrentProjectById', projectId)

      this.$nextTick(() => {
        if (currentProjectId !== projectId) {
          this.$router.push({
            name: name,
            params: newRouteparams,
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
.project-selector {
  width: calc(100% - 1rem) !important;
  margin: 0 0.5rem;

  input {
    border-radius: 0;
  }
}
</style>
