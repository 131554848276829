<template>
  <div>
    <el-row :gutter="20" class="header-action-row">
      <el-col :span="18">
        <el-button type="primary" @click="addDialogVisible = true">
          Add Item
        </el-button>
        <div class="grid-content bg-purple" />
      </el-col>
      <el-col :span="6" />
    </el-row>
    <div class="wrap-table">
      <ClaimItemsTable
        :items="data"
        :items-summary="dataSummary"
        :loading="loading"
        @editItem="editItem"
        @removeItem="removeItem"
        @sort-change="changeSort"
        @filter-change="changeFilter"
      />
    </div>
    <Pagination
      :per-page="perPage"
      :total="total"
      :current-page="currentPage"
      @pageChange="pageChange"
      @changePageSize="changePageSize"
    />
    <ClaimEditItemDialog
      v-if="editDialogVisible"
      :claim-item="editClaimItem"
      :claim="item"
      @updateItem="updateItem"
      @cancel="closeDialog"
    />

    <ClaimAddItemDialog
      v-if="addDialogVisible"
      :claim="item"
      @addItem="addItem"
      @cancel="closeDialog"
    />
  </div>
</template>

<script>
import claimItemsApi from 'api/v2/claimItems'
import projectClaimItemsApi from 'api/v2/projectClaimItems'
import ClaimItemsTable from 'components/Claims/ItemsTable'
import ClaimEditItemDialog from 'components/Claims/EditItemDialog'
import ClaimAddItemDialog from 'components/Claims/AddItemDialog'
import {formatErrorMessage, logErrorMessage} from '../../../../common/util';
import Pagination from '@/js/components/Common/DefaultPagination.vue';


export default {
  name: 'ClaimItems',
  components: {
    ClaimItemsTable,
    ClaimAddItemDialog,
    ClaimEditItemDialog,
    Pagination
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      data: [],
      dataSummary: [],
      addDialogVisible: false,
      editClaimItemIndex: null,
      editDialogVisible: false,
      currentPage: 1,
      total: null,
      perPage: 20,
      loading: false,
      orderProp: null,
      orderDirection: null,
      filters: null,
    }
  },

  computed: {
    editClaimItem() {
      return this.data[this.editClaimItemIndex]
    },
  },

  mounted() {
    this.loadPage(1)
  },

  methods: {
    removeItem(index) {
      if (window.confirm('Are you sure you want to delete this claim item?')) {
        claimItemsApi.delete(
          () => {
            this.$message({
              type: 'success',
              message: 'Deleted',
              duration: 3000,
            })

            this.data.splice(index, 1)
            this.loadSummary()
          },
          (error) => {
            this.$message({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 0,
            })
          },
          this.item.id,
          this.data[index].id
        )
      }
    },
    editItem(index) {
      this.editClaimItemIndex = index
      this.editDialogVisible = true
    },
    updateItem() {
      this.loadPage(this.currentPage)
      this.loadSummary()

      this.$message({
        type: 'success',
        message: 'Item Updated',
        duration: 3000,
      })
    },

    addItem(newItem) {
      this.data.unshift(newItem)
      this.loadSummary()

      this.$message({
        type: 'success',
        message: 'Item Added',
        duration: 3000,
      })
    },

    closeDialog() {
      this.addDialogVisible = false
      this.editDialogVisible = false
      this.editClaimItemIndex = null
    },

    changePageSize(newPageSize) {
      this.perPage = newPageSize
      this.pageChange(1)
    },

    pageChange(val) {
      this.loading = true
      this.currentPage = val
      this.loadPage(val)
    },

    loadSummary() {
      projectClaimItemsApi.index(
        (response) => {
          this.dataSummary = response
        },
        (error) => {
          logErrorMessage('Failed to get summary')
          logErrorMessage(error)
        },
        this.item.projectId,
        this.item.id,
        null,
        {
          filters: this.filters,
          summary: true,
        }
      )
    },

    loadPage(page) {
      if (this.item.projectId) {
        projectClaimItemsApi.index(
          (response) => {
            this.loading = false
            this.data = Object.values(response.data)
            this.total = response.meta.total
            this.perPage = response.meta.per_page
          },
          (error) => {
            this.loading = false
            this.$message.error({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 5000,
            })
          },
          this.item.projectId,
          this.item.id,
          {
            page,
            perPage: this.perPage,
            include: ['pricingItem'],
            orderBy: this.orderProp,
            orderDirection: this.orderDirection,
            filters: this.filters,
          }
        )
      } else {
        claimItemsApi.index(
          (response) => {
            this.loading = false
            this.data = Object.values(response.data)
            this.total = response.meta.total
            this.perPage = response.meta.per_page
          },
          (error) => {
            this.loading = false
            this.$message.error({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 5000,
            })
          },
          this.item.projectId,
          this.item.id,
          {
            page,
            perPage: this.perPage,
            include: ['pricingItem'],
            orderBy: this.orderProp,
            orderDirection: this.orderDirection,
            filters: this.filters,
          }
        )
      }

      this.loadSummary()
    },

    changeSort({ prop, order }) {
      this.orderProp = prop
      if (order === 'ascending') {
        this.orderDirection = 'asc'
      } else if (order === 'descending') {
        this.orderDirection = 'desc'
      } else {
        this.orderDirection = null
      }

      this.pageChange(1)
    },

    changeFilter(filters) {
      this.filters = filters

      this.pageChange(1)
    },
  },
}
</script>

<style lang="scss">
.claims-view {
  .pagination {
    float: right;
    margin-top: 1rem;

    &:after {
      content: '';
      clear: both;
      display: block;
    }
  }
}
</style>
