<template>
  <pricing-template
    :project="null"
    :subcontractor="null"
    :pricing-template-id="pricingTemplateId"
  />
</template>

<script>
import PricingTemplate from 'components/PricingTemplate'
export default {
  name: 'PricingTemplateEdit',

  components: {
    PricingTemplate,
  },

  computed: {
    pricingTemplateId() {
      return parseInt(this.$route.params.pricingTemplateId, 10)
    },
  },
}
</script>

<style></style>
