<template>
  <div>
    <div v-if="activeComponent === 'index'" class="sync-tabs">
      <el-tabs :value="tab" @tab-click="clickedTab">
        <el-tab-pane label="PricingItem (Base)" name="pricing-template">
          <DataTableAlternate
              v-if="tableVisible && tab === 'pricing-template'"
              :api="pricingAdvancedSyncApi()"
              :data-table-options="dataTableOptions"
              :request-data="indexRequestData"
              :row-actions="rowActions"
              table-name="advancedSyncPricingTemplate"
              @row-action="rowAction"
          />
        </el-tab-pane>
        <el-tab-pane label="PricingItem (Child)" name="pricing-child-template">
          <DataTableAlternate
            v-if="tableVisible && tab === 'pricing-child-template'"
            :api="pricingAdvancedSyncApi()"
            :data-table-options="dataTableOptions"
            :request-data="indexRequestData"
            :row-actions="rowActions"
            table-name="advancedSyncPricingChildTemplate"
            @row-action="rowAction"
          />
        </el-tab-pane>
        <el-tab-pane label="Position" name="position">
          <DataTableAlternate
              v-if="tableVisible && tab === 'position'"
              :api="pricingAdvancedSyncApi()"
              :data-table-options="dataTableOptions"
              :request-data="indexRequestData"
              :row-actions="rowActions"
              table-name="advancedSyncPosition"
              @row-action="rowAction"
          />
        </el-tab-pane>
        <el-tab-pane label="Approval" name="approval">
          <DataTableAlternate
              v-if="tableVisible && tab === 'approval'"
              :api="pricingAdvancedSyncApi()"
              :data-table-options="dataTableOptions"
              :request-data="indexRequestData"
              :row-actions="rowActions"
              table-name="advancedSyncApproval"
              @row-action="rowAction"
          />
        </el-tab-pane>
        <el-tab-pane label="Sales Invoice" name="invoice">
          <DataTableAlternate
              v-if="tableVisible && tab === 'invoice'"
              :api="pricingAdvancedSyncApi()"
              :data-table-options="dataTableOptions"
              :request-data="indexRequestData"
              :row-actions="rowActions"
              table-name="advancedSyncInvoice"
              @row-action="rowAction"
          />
        </el-tab-pane>
        <el-tab-pane label="Purchase Invoice" name="purchase-invoice">
          <DataTableAlternate
            v-if="tableVisible && tab === 'purchase-invoice'"
            :api="pricingAdvancedSyncApi()"
            :data-table-options="dataTableOptions"
            :request-data="indexRequestData"
            :row-actions="rowActions"
            table-name="advancedSyncPurchaseInvoice"
            @row-action="rowAction"
          />
        </el-tab-pane>
      </el-tabs>
    </div>

    <template v-if="activeComponent === 'view'">
      <PricingTemplateForm
        v-if="originalPricingItem && (tab === 'pricing-template' || tab === 'pricing-child-template')"
        :item="originalPricingItem"
        :load-preview="loadPreview"
        @row-action="rowAction"
        @preview="preview"
        @change="formChanged"
        @update-and-sync="updateAndSync"
      />
      <PositionForm
        v-if="originalPricingItem && tab === 'position'"
        :item="originalPricingItem"
        :load-preview="loadPreview"
        @row-action="rowAction"
        @preview="preview"
        @change="formChanged"
        @update-and-sync="updateAndSync"
      />
      <InvoiceForm
        v-if="originalPricingItem && tab === 'invoice'"
        :item="originalPricingItem"
        :load-preview="loadPreview"
        @row-action="rowAction"
        @preview="preview"
        @change="formChanged"
        @update-and-sync="updateAndSync"
      />
      <InvoiceForm
        v-if="originalPricingItem && tab === 'purchase-invoice'"
        :item="originalPricingItem"
        :load-preview="loadPreview"
        @row-action="rowAction"
        @preview="preview"
        @change="formChanged"
        @update-and-sync="updateAndSync"
      />
      <ApprovalForm
        v-if="originalPricingItem && tab === 'approval'"
        :item="originalPricingItem"
        :load-preview="loadPreview"
        @row-action="rowAction"
        @preview="preview"
        @change="formChanged"
        @update-and-sync="updateAndSync"
      />




      <el-checkbox
        v-if="loadPreview"
        v-model="matchingOnly"
      >
        Only highlight matching changes
      </el-checkbox>

      <DataTableAlternate
        v-if="loadPreview"
        ref="previewTable"
        :api="pricingAdvancedSyncApi()"
        :data-table-options="previewDataTableOptions"
        :request-data="previewDataTableRequestData"
        :row-actions="rowActions"
        index-method="preview"
        table-name="pricing-template"
        @selection-change="handleSelectionChange"
        @row-action="rowAction"
    >
      <template #selection="{ row }">
        <div v-if="!hasChanges(row)"></div>
        <el-checkbox
            v-if="hasChanges(row)"
            :value="$refs.previewTable.isChecked(row)"
            @change="$refs.previewTable.toggleRowSelection(row)"
        />
      </template>
      <template #default="{ row, column }">
        <div v-if="hasChanges(row, column)" class="has-change">
          <i class="el-icon-warning" size="small"></i>
          <div class="row-value">
            {{ row[column] }}
          </div>
          <div class="new-value">
            {{ getNewValue(column) }}
          </div>
        </div>
        <div v-else>
          {{ row[column] }}
        </div>
      </template>
    </DataTableAlternate>
    </template>
  </div>
</template>

<script>
import DataTableAlternate from '@/js/components/Common/DataTableAlternate.vue'
import pricingAdvancedSyncApi from '@/js/api/v2/pricingAdvancedSync'
import {formatErrorMessage} from '@/js/common/util'
import snakeCaseKeys from 'snakecase-keys'
import PricingTemplateForm from './Forms/PricingTemplate.vue'
import PositionForm from './Forms/Position.vue'
import InvoiceForm from './Forms/Invoice.vue'
import ApprovalForm from './Forms/Approval.vue'

export default {
  name: 'PricingAdvancedSyncView',
  components: {
    DataTableAlternate,
    PricingTemplateForm,
    PositionForm,
    InvoiceForm,
    ApprovalForm,
  },
  props: {},
  data() {
    return {
      activeComponent: 'index',
      viewData: {},
      editFields: {
        reference: {},
        internal_pricing_item_reference: {
          label: 'Soli reference',
        },
        description: {},
        unit: {},
        price: {type: 'number'},
        plan_index: {},
        bonus_index: {},
        prognose_mtb_art_index: {},
      },
      pricingItem: null,
      originalPricingItem: null,
      loadPreview: false,
      previewRowsSelected: [],
      showSelf: true,
      matchingOnly: true,
      showAll: false,
      pricingItemRules: {
        description: [
          {
            required: true,
            message: 'Please enter a description',
            trigger: 'blur',
          },
        ],
      },
      rowActions: {
        view: {
          label: 'View',
        },
      },
      dataTableOptions: {
        showRefresh: false,
        tableHeightOffset: 200,
        columnWidth: {
          id: 150,
          _rowActions: 60,
          mapping_status: 300,
          pricing_template: 200,
        },
      },
      typeLabels: {
        lerf: 'Invoice Item',
        approval: 'Approval Item',
        position: 'Position Item',
        pricing: 'Pricing Item',
      },
      tab: 'pricing-template',
      tableVisible: false,
      typeTabs: {
        PurchaseInvoice: 'purchase-invoice',
        Invoice: 'invoice',
        Approval: 'approval',
        Position: 'position',
        PricingTemplate: 'pricing-template',
      },
    }
  },
  computed: {
    indexRequestData() {
      let data = {
        loadType: this.tab,
        include: []
      }
      if (this.tab === 'pricing-template' || this.tab === 'pricing-child-template') {
        data.include = [...data.include, ...[
          'pricingCategory',
          'pricingTemplate',
          'pricingTemplate.project',
          'pricingTemplate.parentPricingTemplate',
          'internalPricingItem',
          'parentPricingItem',
          'pricingItems',
          'positionItems',
          'approvalItems',
          'lerfItems',
        ]]
      }
      return data
    },
    previewDataTableRequestData() {
      let data = {
        loadType: this.tab,
        stagedPricingItem: this.pricingItem,
        showSelf: this.showSelf,
      }
      return data
    },
    previewDataTableOptions() {
      const self = this
      return {
        ...this.dataTableOptions,
        ...{
          rowClassName: function (tableRow) {
            let classes = []
            if (tableRow.row.is_editing) {
              classes.push('is-editing')
            }
            if (self.hasChanges(tableRow.row)) {
              classes.push('has-changes')
            }
            return classes.join(' ')
          },
          tableHeightOffset: 450,
          allowSelection: true,
          columnWidth: {
            type: 150,
            description: 350,
            mapping_status: 300,
            pricing_template: 200,
            _rowActions: 60,
          },
        },
      }
    },
    changedColumns() {
      const changedColumns = {}
      for (const key in this.pricingItem) {
        if (this.pricingItem[key] !== this.originalPricingItem[key]) {
          changedColumns[key] = {
            original: this.originalPricingItem[key],
            current: this.pricingItem[key],
          }
        }
      }
      return changedColumns
    },
  },
  watch: {
    showSelf() {
      this.$nextTick(() => {
        this.preview()
      })
    },
  },
  created() {
    if (this.$route.params.tab) {
      this.tab = this.$route.params.tab
      this.tableVisible = true
    }
    if (this.$route.params.pricingItemId) {
      this.activeComponent = 'view'
      this.viewData = {
        id: this.$route.params.pricingItemId,
      }
      this.loadPricingItem()
    }
  },
  mounted() {
  },
  methods: {
    pricingAdvancedSyncApi() {
      return pricingAdvancedSyncApi
    },
    rowAction({action, data}) {

      this.viewData = data
      this.$nextTick(() => {
        this.activeComponent = null
        this.loadPreview = false
        this.$nextTick(() => {
          this.activeComponent = action

          if (action === 'index') {
            this.$router.push(`/pricing/advanced-sync/${this.tab}`)
          }

          if (action === 'cancel') {
            // First hide the table
            this.tableVisible = false

            this.pricingItem = null
            this.originalPricingItem = null
            this.loadPreview = false

            this.activeComponent = 'index'
            this.$nextTick(() => {
              this.$router.replace(`/pricing/advanced-sync/${this.tab}`)
              this.tableVisible = true;
            })
          }

          if (this.activeComponent === 'view' && this.viewData) {
            if(this.viewData.type) {
              this.tab = this.typeTabs[this.viewData.type]
            }
            this.$router.push(`/pricing/advanced-sync/${this.tab}/${this.viewData.id}`)
            this.loadPricingItem()
          }
        })
      })
    },
    updateAndSync() {
      let postData = {
        loadType: this.tab,
        include: [],
        syncTo: this.previewRowsSelected,
        ...this.changedColumns,
      }
      if (this.tab === 'pricing-template' || this.tab === 'pricing-child-template') {
        postData.include = [...postData.include, ...[
          'pricingTemplate',
          'pricingCategory'
        ]]
      }
      if (this.tab === 'position') {
        postData.include = [...postData.include, ...[
          'pricingItem',
          'pricingItem.pricingTemplate',
          'pricingItem.pricingCategory'
        ]]
      }
      if (this.tab === 'approval') {
        postData.include = [...postData.include, ...[
          postData.include = [...postData.include, ...[
            'positionItem.pricingItem',
            'positionItem.pricingItem.pricingTemplate',
            'positionItem.pricingItem.pricingCategory'
          ]]
        ]]
      }
      if (this.tab === 'invoice') {
        postData.include = [...postData.include, ...[
          postData.include = [...postData.include, ...[
            'pricingItem',
            'pricingItem.pricingTemplate',
            'pricingItem.pricingCategory',
            'positionItem.pricingItem',
            'positionItem.pricingItem.pricingTemplate',
            'positionItem.pricingItem.pricingCategory'
          ]]
        ]]
      }
      if (this.tab === 'purchase-invoice') {
        postData.include = [...postData.include, ...[
          postData.include = [...postData.include, ...[
            'pricingItem',
            'pricingItem.pricingTemplate',
            'pricingItem.pricingCategory',
            'positionItem.pricingItem',
            'positionItem.pricingItem.pricingTemplate',
            'positionItem.pricingItem.pricingCategory'
          ]]
        ]]
      }
      pricingAdvancedSyncApi.update(
        () => {
          this.pricingItem = null
          this.originalPricingItem = null
          this.loadPreview = false
          this.previewRowsSelected = []
          this.matchingOnly = true

          this.$nextTick(() => {
            this.loadPricingItem()
          })
        },
        (error) => {
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.viewData.id,
        postData
      )
    },
    loadPricingItem() {
      this.pricingItem = null
      this.originalPricingItem = null
      this.loadPreview = false
      this.previewRowsSelected = []
      this.matchingOnly = true

      let postData = {
        loadType: this.tab,
        include: []
      }
      if (this.tab === 'pricing-template' || this.tab === 'pricing-child-template') {
        postData.include = [...postData.include, ...[
          'pricingTemplate',
          'pricingCategory'
        ]]
      }
      if (this.tab === 'position') {
        postData.include = [...postData.include, ...[
          'pricingItem',
          'pricingItem.pricingTemplate',
          'pricingItem.pricingCategory'
        ]]
      }
      if (this.tab === 'approval') {
        postData.include = [...postData.include, ...[
          'positionItem.pricingItem',
          'positionItem.pricingItem.pricingTemplate',
          'positionItem.pricingItem.pricingCategory'
        ]]
      }
      if (this.tab === 'invoice') {
        postData.include = [...postData.include, ...[
          'pricingItem',
          'pricingItem.pricingTemplate',
          'pricingItem.pricingCategory',
          'positionItem.pricingItem',
          'positionItem.pricingItem.pricingTemplate',
          'positionItem.pricingItem.pricingCategory'
        ]]
      }
      if (this.tab === 'purchase-invoice') {
        postData.include = [...postData.include, ...[
          'pricingItem',
          'pricingItem.pricingTemplate',
          'pricingItem.pricingCategory',
          'positionItem.pricingItem',
          'positionItem.pricingItem.pricingTemplate',
          'positionItem.pricingItem.pricingCategory'
        ]]
      }

      this.loadPreview = false
      pricingAdvancedSyncApi.show(
        (response) => {
          this.pricingItem = snakeCaseKeys(response)
          this.originalPricingItem = JSON.parse(
            JSON.stringify(this.pricingItem)
          )
          this.loadPreview = true
        },
        (error) => {
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.viewData.id,
        postData
      )
    },
    preview() {
      this.loadPreview = true
      this.$refs.previewTable.refresh(true)
    },
    hasChanges(row, column = null) {
      if (column && !this.changedColumns[column]) {
        return false
      } else if (!column) {
        // So basically we want to see if any column on the row has possible chnages
        for (const column in row) {
          if (this.hasChanges(row, column)) {
            return true
          }
        }
        return false
      }
      if (!this.matchingOnly) {
        return true
      }
      // if the current row value is the same as the original value then mark as changed
      if (this.changedColumns[column].original === row[column]) {
        return true
      }
      return false
    },
    getNewValue(column) {
      return this.changedColumns[column].current
    },
    handleSelectionChange(rows) {
      // Internally el-tale selects all rows
      // Filter with the ones that should actually be selectable
      // Reset Local
      this.previewRowsSelected = []
      for (const row of rows) {
        if (this.hasChanges(row)) {
          this.previewRowsSelected.push(row)
        }
      }
    },
    isInputDisabled(field) {
      if (field === 'internal_pricing_item_reference') {
        if (!this.pricingItem.pricing_template) {
          return true
        }
        if (!this.pricingItem.pricing_template.is_subcontractor_template) {
          return true
        }
        if (this.pricingItem.pricing_template.is_project_template) {
          return true
        }
        return false
      }
      return false
    },
    refreshPage() {
      this.tableVisible = false
      this.pricingItem = null
      this.originalPricingItem = null
      this.loadPreview = false
      this.$nextTick(() => {
        this.tableVisible = true
      })
    },
    clickedTab(tabComponent) {
      if (this.tab === tabComponent.name) {
        return;
      }
      // First hide the table
      this.tableVisible = false

      this.pricingItem = null
      this.originalPricingItem = null
      this.loadPreview = false

      this.$nextTick(() => {
        // Set the tab to the newly selected one
        this.tab = tabComponent.name
        // Change the URL
        this.$router.replace(`/pricing/advanced-sync/${tabComponent.name}`)

        // and then load the table again
        this.tableVisible = true;
      })

    },
    formChanged(pricingItem) {
      this.pricingItem = pricingItem
    }
  },
}
</script>

<style scoped>
.box-card {
  margin-bottom: 2em;
}

.text {
  font-size: 14px;
}
::v-deep .el-table__row.is-editing td:first-child {
  border-left: 10px solid  rgb(14, 192, 174);
}
::v-deep .el-table__row.has-changes {
  background: rgba(219, 205, 23, 1);
}
::v-deep .el-table__row.has-changes.hover-row > td {
  background: rgba(219, 205, 23, 1);
}
::v-deep .el-table__row.el-table__row--striped.has-changes > td.el-table__cell {
  background: rgba(219, 205, 23, 1);
}
::v-deep .el-table__row.el-table__row--striped.has-changes.hover-row > td {
  background: rgba(219, 205, 23, 1);
}
::v-deep .has-change {
  position: relative;
  padding-left: 1.5em;

  > i {
    position: absolute;
    left: 0;
  }

  > div {
    font-size: 0.7rem;
    line-height: 1.2;
    min-height: 0.7rem;

    &.row-value:before {
      display: inline-block;
      width: 1.25em;
      content: 'O: ';
      font-weight: bold;
    }

    &.new-value:before {
      display: inline-block;
      width: 1.25em;
      content: 'N: ';
      font-weight: bold;
    }
  }
}
</style>
