import ApiResource from '../ApiResource'

class ProjectMediaResource extends ApiResource {
  constructor() {
    super({
      name: 'projects.media',
    })
  }

  upload(resolve, reject, projectId, document) {
    const formData = new FormData()

    formData.append('pdf', document)
    this.ids = [projectId]

    this.post(this.getUrl(), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const projectMediaApi = new ProjectMediaResource()

export default projectMediaApi
