<template>
  <div
    v-loading="areasLoading"
    class="project-areas"
    element-loading-text="Loading..."
  >
    <!-- <el-select
      v-model="selectedArea"
      placeholder="Select area..."
      :disabled="!areas"
      style="margin-bottom: 16px"
      @input="fetchProjectMetaForArea"
    >
      <el-option
        v-for="area in areas"
        :key="area.id"
        :label="area.name"
        :value="area"
      />
    </el-select> -->
    <el-row
      v-if="areas.length"
      v-loading="isLoading"
      :gutter="20"
      element-loading-text="Loading..."
    >
      <el-col :span="8">
        <list-view
          :items="areas"
          :secondary-label-column-span="3"
          filterable
          label-attribute="name"
          secondary-label-attribute="costObjectCode"
          sortable
          title="Areas"
          @select="selectArea"
        />
      </el-col>
      <template v-if="projectFields">
        <el-col :span="8">
          <OverviewDepartment
            v-if="can('view', 'area_meta', 'general')"
            :area-id="selectedArea.id"
            :on-success="setProjectFields"
            :project="project"
            :project-fields="projectFields"
            category="general"
          />

          <OverviewDepartment
            v-if="can('view', 'area_meta', 'customer')"
            :area-id="selectedArea.id"
            :on-success="setProjectFields"
            :project="project"
            :project-fields="projectFields"
            category="customer"
          />
          <OverviewDepartment
            v-if="can('view', 'area_meta', 'bid_management')"
            :area-id="selectedArea.id"
            :on-success="setProjectFields"
            :project="project"
            :project-fields="projectFields"
            category="bid_management"
          />
          <OverviewDepartment
            v-if="can('view', 'area_meta', 'controlling')"
            :area-id="selectedArea.id"
            :on-success="setProjectFields"
            :project="project"
            :project-fields="projectFields"
            category="controlling"
          />
        </el-col>
        <el-col :span="8">
          <OverviewDepartment
            v-if="can('view', 'area_meta', 'bauleiters')"
            :area-id="selectedArea.id"
            :on-success="setProjectFields"
            :project="project"
            :project-fields="projectFields"
            category="bauleiters"
          />
          <OverviewDepartment
            v-if="can('view', 'area_meta', 'engineering')"
            :area-id="selectedArea.id"
            :on-success="setProjectFields"
            :project="project"
            :project-fields="projectFields"
            category="engineering"
          />
          <OverviewDepartment
            v-if="can('view', 'area_meta', 'invoicing')"
            :area-id="selectedArea.id"
            :on-success="setProjectFields"
            :project="project"
            :project-fields="projectFields"
            category="invoicing"
          />
          <OverviewDepartment
            v-if="can('view', 'area_meta', 'finance')"
            :area-id="selectedArea.id"
            :on-success="setProjectFields"
            :project="project"
            :project-fields="projectFields"
            category="finance"
          />
          <OverviewDepartment
            v-if="can('view', 'area_meta', 'closing')"
            :area-id="selectedArea.id"
            :on-success="setProjectFields"
            :project="project"
            :project-fields="projectFields"
            category="closing"
          />
          <OverviewDepartment
            v-if="can('view', 'area_meta', 'other')"
            :area-id="selectedArea.id"
            :on-success="setProjectFields"
            :project="project"
            :project-fields="projectFields"
            category="other"
          />
        </el-col>
      </template>
    </el-row>

    <div v-if="allRevisions && allRevisions.length">
      <revision-table
        :revisions="allRevisions"
        card-title="Area meta Fields revisions"
        type="projectMeta"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import projectMetaFieldsApi from 'api/v2/projectMetaFields'
import {AuthorizationError} from 'api/errors'
import {formatDateTime, formatErrorMessage} from 'common/util'
import {orderBy} from 'lodash'
import { can } from 'common/util'

import ListView from './ListView.vue'
import OverviewDepartment from '../Overview/Department.vue'
import RevisionTable from '../../../../Common/RevisionTable'

export default {
  name: 'ProjectAreaMeta',

  components: {
    ListView,
    OverviewDepartment,
    RevisionTable,
  },

  props: {
    project: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedArea: null,

      isLoading: false,
      projectFields: null,
      isDownloading: false,
    }
  },

  computed: {
    ...mapGetters('projectAreas', {
      areas: 'items',
      areasLoading: 'loading',
      areasErrored: 'errored',
    }),
    allRevisions() {
      if (!this.projectFields) {
        return null
      }
      return orderBy(
        this.projectFields.reduce((acc, current) => {
          if (current.revisionHistory) {
            const revisionHistory = current.revisionHistory.map((h) => ({
              name: current.name,
              category: current.category,
              ...h,
            }))
            return acc.concat(revisionHistory)
          }

          return acc
        }, []),
        ['updated_at'],
        ['desc']
      )
    },
  },

  async created() {
    await this.$store.dispatch('projectAreas/reset')
    await this.$store.dispatch('projectAreas/setProjectId', this.project.id)
    this.fetchAreas()
  },
  methods: {
    can(action, category, subcategory) {
      return can(action, category, subcategory)
    },
    selectArea(area) {
      this.selectedArea = area
      this.fetchProjectMetaForArea(area)
    },
    formatDateTime(value) {
      return formatDateTime(value)
    },

    fetchAreas() {
      this.$store.dispatch('projectAreas/fetchAll', {
        projectId: this.project.id,
      })
    },

    fetchProjectMetaForArea(area) {
      this.isLoading = true

      projectMetaFieldsApi.indexForArea(
        (response) => {
          this.isLoading = false
          this.$set(this, 'projectFields', response)
        },
        (error) => {
          this.isLoading = false
          if (error instanceof AuthorizationError) {
            this.$router.push('/')
          }

          this.$message.error({
            message: formatErrorMessage(error),
          })
        },
        this.project.id,
        area.id
      )
    },

    setProjectFields(projectFields) {
      this.$set(this, 'projectFields', projectFields)
    },

    // downloadRevisionHistory() {
    //   this.isDownloading = true

    //   projectMetaFieldsApi.exportRevisionHistory(
    //     (response) => {
    //       let blob = new Blob([response])
    //       let link = document.createElement('a')
    //       const objURL = window.URL.createObjectURL(blob)
    //       link.href = objURL
    //       link.download = 'project-meta-revision-history-export.csv'
    //       link.click()

    //       window.URL.revokeObjectURL(objURL)

    //       this.isDownloading = false
    //     },
    //     (error) => {
    //       this.isDownloading = false
    //       this.$message.error({
    //         showClose: true,
    //         type: 'error',
    //         message: formatErrorMessage(error),
    //         duration: 0,
    //       })
    //     },
    //     this.project.id
    //   )
    // },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-card {
  + .el-card {
    margin-top: 1.5rem;
  }
}

::v-deep .table-card {
  margin-top: 3rem;

  .el-card__body {
    padding: 0;
  }
}
</style>
