import ApiResource from '../ApiResource'

class ProjectLerfItemResource extends ApiResource {
  constructor() {
    super({
      name: 'projects.lerfs.items',
    })

    this.getSingleItem = this.show
    this.deleteItem = this.delete
  }
}

const projectLerfItemsApi = new ProjectLerfItemResource()

export default projectLerfItemsApi
