import ApiResource from '../ApiResource'

class PurchaseInvoiceItemResource extends ApiResource {
  constructor() {
    super({
      name: 'purchase-invoices.items',
    })
  }

  import(resolve, reject, purchaseInvoiceId, positionItems) {
    this.ids = [purchaseInvoiceId]

    this.post(`${this.getUrl()}/import`, positionItems)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const purchaseInvoiceItemsApi = new PurchaseInvoiceItemResource()

export default purchaseInvoiceItemsApi
