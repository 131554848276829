<template>
  <div>
    <pair>
      <key>API</key>
      <value>{{ apiBaseUrl }}</value>
    </pair>
    <br />
    <pair>
      <key>Commit SHA</key>
      <value>{{ commitSha }}</value>
    </pair>
    <br />
    <pair>
      <key>Commit Tag</key>
      <value>{{ commitTag }}</value>
    </pair>
  </div>
</template>

<script>
import appConfig from '../../../../config/app'

export default {
  name: 'DebugView',
  data() {
    return {
      commitSha: appConfig.commitSha,
      commitTag: appConfig.commitTag,
      apiBaseUrl: appConfig.api.baseUrl,
    }
  },
}
</script>
