<template>
  <div class="component-positions-fixMissing-subcontractors-wrapper">
    <DataTableAlternate
      ref="table"
      :api="api()"
      :data-table-options="dataTableOptions"
      :request-data="{visibleColumns: ['id', 'name', 'subcontractor', 'project', 'pricing_category', 'missing_info_count', 'updated_date']}"
      :filter-preset="filterPreset"
      table-name="positionsFixMissingSubcontractors"
    >
      <template slot-scope="scope">
        <template v-if="scope.column === 'id'">
          <a
            v-if="!scope.row.project.deleted && !scope.row.project.archived"
            :href="getPositionLink(scope)"
            target="_blank"
            title="link to Project pricing"
          >
            {{ scope.row.id }}
          </a>
          <span v-else-if="scope.row.project.archived" title="Project is archived">
            {{ scope.row.id }} *
          </span>
          <span v-else title="Project is deleted">
            {{ scope.row.id }} *
          </span>
        </template>
        <template v-else-if="scope.column === 'subcontractor'">
          <a
            v-if="!scope.row.subcontractor.is_deleted && !scope.row.subcontractor.is_archived"
            :href="getSubcontractorLink(scope)"
            target="_blank"
            title="link to Subcontractor pricing"
          >
            {{ scope.row.subcontractor.name }}
          </a>
          <span v-else-if="scope.row.subcontractor.is_archived">
            {{ scope.row.subcontractor.name }} - archived
          </span>
          <span v-else>
            {{ scope.row.subcontractor.name }} - deleted
          </span>
        </template>
        <template v-else-if="scope.column === 'project'">
          <a
            v-if="!scope.row.project.deleted && !scope.row.project.archived"
            :href="getProjectLink(scope)"
            target="_blank"
            title="link to Project pricing"
          >
            {{ scope.row.project.name }}
          </a>
          <span v-else-if="scope.row.project.archived">
            {{ scope.row.project.name }} - archived
          </span>
          <span v-else>
            {{ scope.row.project.name }} - deleted
          </span>
        </template>
        <template v-else-if="scope.column === 'pricing_category'">
          <a
            v-if="!scope.row.subcontractor.is_deleted && !scope.row.subcontractor.is_archived"
            :href="getSubcontractorLink(scope)"
            target="_blank"
            title="link to Subcontractor pricing"
          >
            {{ scope.row.pricing_category.name }}
          </a>
          <span v-else-if="scope.row.subcontractor.is_archived" title="Subcontractor is Archived">
            {{ scope.row.pricing_category.name }} *
          </span>
          <span v-else title="Subcontractor is Deleted">
            {{ scope.row.pricing_category.name }} *
          </span>
        </template>
        <template v-else>
          {{$refs.table.getColumnValue(scope.column, scope.row)}}
        </template>
      </template>
    </DataTableAlternate>
  </div>
</template>
<script>

import DataTableAlternate from '@/js/components/Common/DataTableAlternate.vue';
import positionsFixMissingSubcontractorsApi from '@/js/api/v2/positionsFixMissingSubcontractors';

export default {
  name: 'FixPricing',
  components: {DataTableAlternate},
  data() {
    return {
      filterPresetData: {},
      dataTableOptions: {
        showRefresh: true,
        columnWidth: {
          _rowActions: 80,
          id: 120,
          subcontractor: 200,
          project: 200,
        }
      }
    }
  },
  computed: {
    filterPreset() {
      return this.filterPresetData
    }
  },
  mounted() {
    if (this.$route.params.positionId) {
      this.filterPresetData.id = this.$route.params.positionId
    }
  },
  methods: {
    api() {
      return positionsFixMissingSubcontractorsApi
    },
    getPositionLink(scope) {
      return `#/projects/${scope.row.project.id}/positions/${scope.row.id}/overview`
    },
    getProjectLink(scope) {
      return `#/projects/${scope.row.project.id}/pricing`
    },
    getSubcontractorLink(scope) {
      return `#/subcontractors/${scope.row.subcontractor.id}/pricing-templates`
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
