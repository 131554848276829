import ApiResource from '../ApiResource'

class PricingSyncResource extends ApiResource {
  constructor() {
    super({
      name: 'pricing-sync',
    })
  }

  toggleSync(resolve, reject, params) {
    this.post(`${this.getUrl()}/toggle-sync`, params)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  setParentPricingTemplate(resolve, reject, params) {
    this.post(`${this.getUrl()}/set-parent-pricing-template`, params)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  checkInternalReferences(resolve, reject, pricingTemplateId) {
    this.ids = [pricingTemplateId]
    this.get(`${this.getUrl()}/check-internal-references`)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
    this.ids = []
  }

  syncInternalReferences(resolve, reject, pricingTemplateId) {
    this.ids = [pricingTemplateId]
    this.post(`${this.getUrl()}/sync-internal-references`)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
    this.ids = []
  }

  bulkSync(resolve, reject, params) {
    this.post(`${this.getUrl()}/bulk`, params)

      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const pricingSyncApi = new PricingSyncResource()

export default pricingSyncApi
