import { post, get, put, remove } from './index'

/**
 * This is a base class for api resources, written to help to bring
 * more structure to the api modules, while staying compatible with the
 * current implementation, so we do not have to rewrite all modules
 * that depend on these api modules.
 *
 * @param {object} options
 * @param {string} options.name The name of the resource, can be nested IE projects.subcontractors
 * @param {string} [options.apiBase=/api/v2]
 * @export
 * @class ApiResource
 */
export default class ApiResource {
  constructor(options) {
    this.apiBase = options.apiBase || 'api/v2'
    this.resources = []
    this.resourceIds = []
    this.setResource(options.name)
    this.ids = []

    // also expose api methods from index.js
    this.post = post
    this.get = get
    this.put = put
    this.remove = remove
  }

  /**
   * Performs a GET request to the resource, retrieving multiple items
   * of that resource.
   *
   * @param {Function} resolve
   * @param {Function} reject
   * @param  {...any} params
   * @param {Number} [params.page] In case you want paginated results
   */
  index(resolve, reject, ...params) {
    // console.log('params %o', params)
    // console.error('trace')

    const normalizeKeys =
      params[0] && params[0].normalizeKeys === false ? false : true

    const { requestParams, additionalParams } = this._parseMethodParams(
      'GET',
      params
    )

    // console.log(
    //   'requestParams %o, additionalParams %o, ids %o',
    //   requestParams,
    //   additionalParams,
    //   this.ids
    // )

    get(
      this.getUrl(),
      { params: requestParams },
      additionalParams,
      normalizeKeys
    )
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  /**
   * Performs a GET request to the resource, retrieving multiple items
   * of that resource.
   * This is an alias to the `index` function, however in this case
   * you should probide the page number as the third argumens, instead
   * of addding it to the params object ({ page })
   *
   * @param {Function} resolve
   * @param {Function} reject
   * @param {Number} page
   * @param {Object} additionalParams
   */
  listItems(resolve, reject, page, additionalParams) {
    return this.index(resolve, reject, { page }, additionalParams)
  }

  /**
   * Performs a GET request to a single resource
   *
   * @param {Function} resolve
   * @param {Function} reject
   * @param  {...any} params
   */
  show(resolve, reject, ...params) {
    const { requestParams, additionalParams } = this._parseMethodParams(
      'GET',
      params
    )

    get(this.getUrl(), { params: requestParams }, additionalParams)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  /**
   * Create a new resource item
   *
   * @param {Function} resolve
   * @param {Function} reject
   * @param  {...any} params
   */
  store(resolve, reject, ...params) {
    const { payload } = this._parseMethodParams('POST', params)

    post(this.getUrl(), payload)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  /**
   * Update an existing resource item
   *
   * @param {Function} resolve
   * @param {Function} reject
   * @param  {...any} params
   */
  update(resolve, reject, ...params) {
    const { payload } = this._parseMethodParams('PUT', params)

    put(this.getUrl(), payload)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        // console.error(error)
        reject(error)
      })
  }

  /**
   * Delete a resource item
   *
   * @param {Function} resolve
   * @param {Function} reject
   * @param  {...any} params
   */
  delete(resolve, reject, ...params) {
    const { payload } = this._parseMethodParams('DELETE', params)

    remove(this.getUrl(), payload)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  /**
   * Builds the URL based on the resource definition, and
   * the `ids` that are set
   */
  getUrl() {
    if (!this.resources.length) {
      throw new Error('Name of resource has not been set')
    }
    let url = `${this.apiBase}/${this.resources[0]}`
    if (this.resources.length > 1) {
      url += `/${this.ids[0]}/${this.resources[1]}`
    }
    if (this.resources.length > 2) {
      url += `/${this.ids[1]}/${this.resources[2]}`
    }
    if (this.resources.length > 3) {
      url += `/${this.ids[2]}/${this.resources[3]}`
    }
    if (this.resources.length > 4) {
      url += `/${this.ids[3]}/${this.resources[4]}`
    }

    // add the specific resource id when fetching, updating
    // or deleting a single resource
    if (this.ids.length === this.resources.length) {
      url += `/${this.ids[this.ids.length - 1]}`
    }
    // console.log('this.ids: ', this.ids)
    // console.log('constructed url: %s', url)
    return url
  }

  /**
   * Name of the resource, can be nested, like:
   * `projects.subcontractors'
   *
   * @memberof ApiResource
   */
  setResource(resourceName) {
    // console.log(
    //   `setting resource to '%s'`,
    //   resourceName,
    //   resourceName.split('.')
    // )
    this.resources = []
    const resourceIds = [
      'resourceId',
      'subResourceId',
      'subSubResourceId',
      'subSubSubResourceId',
    ]
    resourceName.split('.').forEach((resource) => {
      this.resources.push(resource)
    })
    this.resourceIds = resourceIds.slice(0, this.resources.length)
    // console.log(this.resources, this.resourceIds)
  }

  /**
   * Parses the method parameters, because we usually have these api methods:
   *
   * index(resolve, reject, projectId, params, additionalParams)
   * show(resolve, reject, projectId, subcontractorId, params, additionalParams)
   * update(resolve, reject, projectId, params, additionalParams, payload)
   *
   * Sets this.ids to the correct ids from the params and returns an object containing:
   * { requestParams, additionalParams, payload }
   *
   * @param {string} requestType Should be GET, POST, PUT, PATCH or DELETE
   * @param {array} params The parameters passed to the method
   * @returns
   * @private
   */
  _parseMethodParams(requestType, params) {
    let requestParams, additionalParams, payload

    // we only have a payload in params when creating, or updating
    // resources (and for some reason also when deleting, like force deleting)
    if (['POST', 'PUT', 'PUT', 'DELETE'].indexOf(requestType) !== -1) {
      if (params.length && typeof params[params.length - 1] === 'object') {
        // params, additionalParams, payload
        // resourceId, params, additionalParams, payload
        // resourceId, subResourceId, params, additionalParams, payload
        payload = params.pop()

        // when we want to pass a payload with a DELETE method, it should
        // be wrapped in a 'data' attribute
        if (requestType === 'DELETE' && !payload.data) {
          payload = { data: payload }
        }
      }
    }

    // requestParams, additionalParams
    // resourceId, requestParams, additionalParams
    // resourceId, subResourceId, requestParams, additionalParams

    // if last param is object, it is params or aditional params
    if (params.length && typeof params[params.length - 1] === 'object') {
      requestParams = params.pop()
    }

    // we have both requestParams and additionalParams
    if (params.length && typeof params[params.length - 1] === 'object') {
      additionalParams = requestParams
      additionalParams = params.pop()
    }

    // the remaining params should all be ids, so set the ids, so we
    // will be able to build the correct URL (see getUrl)
    if (params.length) {
      this.ids = params.slice(0)
    } else {
      this.ids = []
    }

    return {
      requestParams,
      additionalParams,
      payload,
    }
  }
}
