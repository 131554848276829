import ApiResource from '../ApiResource'

class ProjectContactResource extends ApiResource {
  constructor() {
    super({
      name: 'projects.contacts',
    })
  }

  linkProjectContact(resolve, reject, projectId, contactId) {
    this.ids = [projectId, contactId]
    this.post(`${this.getUrl()}/attach`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }

  unlinkProjectContact(resolve, reject, projectId, contactId) {
    this.ids = [projectId, contactId]
    this.post(`${this.getUrl()}/detach`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const projectContactsApi = new ProjectContactResource()

export default projectContactsApi
