var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bid-edit-container"},[(_vm.bid && !_vm.bid.areaId)?_c('div',{ref:"main",class:_vm.containerCssClass},[_c('el-row',{staticClass:"breadcrumbs-row",attrs:{"gutter":20}},[_c('el-col',[_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: _vm.lastRoute }}},[_vm._v("Bids")]),_vm._v(" "),_c('el-breadcrumb-item',[_vm._v("Editing: #"+_vm._s(_vm.bid.id))])],1)],1),_vm._v(" "),_c('el-col',[_c('el-button',{attrs:{"type":"primary","size":"mini","icon":"el-icon-arrow-left"},on:{"click":_vm.goBack}},[_vm._v("Back")])],1)],1),_vm._v(" "),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8}},[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Required Fields")])]),_vm._v(" "),_vm._l((_vm.loadedConfiguration.fillable),function(field,index){return _c('div',{key:index,staticClass:"bid-field"},[_c('div',{class:_vm.getFieldClass[index]},[_c('el-row',{attrs:{"gutter":40}},[_c('el-col',{staticClass:"field-label",attrs:{"span":12}},[_c('label',[_vm._v(_vm._s(field.name))]),_vm._v(" "),_c('small',[_vm._v(_vm._s(field.description))])]),_vm._v(" "),_c('el-col',{attrs:{"span":12}},[(!_vm.editInputShow)?[(
                        ['date'].includes(field.type) &&
                        _vm.bid['meta' + field.id]
                      )?[_vm._v("\n                      "+_vm._s(_vm.formatDisplayDate(_vm.bid['meta' + field.id]))+"\n                    ")]:[_vm._v("\n                      "+_vm._s(_vm.bid['meta' + field.id])+"\n                    ")]]:_vm._e(),_vm._v(" "),(_vm.cellIsUpdateable('meta_' + field.id))?[(['text', 'string'].includes(field.type))?_c('el-input',{staticClass:"cell-input",attrs:{"name":'meta_' + field.id,"type":"text","size":"small"},model:{value:(_vm.bid['meta' + field.id]),callback:function ($$v) {_vm.$set(_vm.bid, 'meta' + field.id, $$v)},expression:"bid['meta' + field.id]"}}):_vm._e(),_vm._v(" "),(['number', 'decimal'].includes(field.type))?_c('el-input',{staticClass:"cell-input",attrs:{"name":'meta_' + field.id,"type":"number","step":_vm.editNumberInputStep(field),"size":"small"},model:{value:(_vm.bid['meta' + field.id]),callback:function ($$v) {_vm.$set(_vm.bid, 'meta' + field.id, $$v)},expression:"bid['meta' + field.id]"}}):_vm._e(),_vm._v(" "),(['date'].includes(field.type))?_c('el-date-picker',{staticClass:"cell-input",staticStyle:{"width":"100%"},attrs:{"type":"date","placeholder":"Pick a date","value-format":"yyyy-MM-dd","format":"dd.MM.yyyy","size":"small"},model:{value:(_vm.bid['meta' + field.id]),callback:function ($$v) {_vm.$set(_vm.bid, 'meta' + field.id, $$v)},expression:"bid['meta' + field.id]"}}):_vm._e(),_vm._v(" "),(['dropdown'].includes(field.type))?_c('el-select',{staticClass:"cell-input",attrs:{"name":'meta_' + field.id},model:{value:(_vm.bid['meta' + field.id]),callback:function ($$v) {_vm.$set(_vm.bid, 'meta' + field.id, $$v)},expression:"bid['meta' + field.id]"}},_vm._l((_vm.loadedConfiguration.metaFields[
                          'meta_' + field.id
                        ].options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value,"selected":_vm.bid['meta' + field.id] == item.value}})}),1):_vm._e()]:_vm._e()],2)],1)],1)])}),_vm._v(" "),_c('el-row',[_c('el-col',{attrs:{"span":12}},[(!_vm.editInputShow && !_vm.isUpdating)?_c('el-button',{attrs:{"title":"Finalize Bid","type":"primary"},on:{"click":function($event){_vm.finalizeDialogVisible = true}}},[_vm._v("Finalize\n              ")]):_vm._e(),_vm._v(" "),(_vm.editInputShow && !_vm.isUpdating)?_c('el-button',{attrs:{"title":"Save","type":"success"},on:{"click":_vm.updateBid}},[_vm._v("Save\n              ")]):_vm._e()],1),_vm._v(" "),_c('el-col',{staticClass:"text-right",attrs:{"span":12}},[(!_vm.editInputShow && !_vm.isUpdating)?_c('el-button',{attrs:{"title":"Edit Bid"},on:{"click":function($event){return _vm.toggleEdit(true)}}},[_vm._v("Edit\n              ")]):_vm._e(),_vm._v(" "),(_vm.editInputShow && !_vm.isUpdating)?_c('el-button',{attrs:{"title":"Cancel","type":"danger"},on:{"click":function($event){return _vm.toggleEdit(false)}}},[_vm._v("Cancel\n              ")]):_vm._e()],1)],1)],2)],1),_vm._v(" "),_c('el-col',{attrs:{"span":8}},[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Other Fields")])]),_vm._v(" "),_vm._l((_vm.nonFillableFields),function(field,index){return _c('div',{key:index,staticClass:"bid-field"},[_c('div',{class:_vm.getFieldClass[index]},[_c('el-row',{attrs:{"gutter":40}},[_c('el-col',{staticClass:"field-label",attrs:{"span":12}},[_c('label',[_vm._v(_vm._s(field.name))]),_vm._v(" "),_c('small',[_vm._v(_vm._s(field.description))])]),_vm._v(" "),_c('el-col',{attrs:{"span":12}},[(!_vm.editInputShow)?[(
                        ['date'].includes(field.type) &&
                        _vm.bid['meta' + field.id]
                      )?[_vm._v("\n                      "+_vm._s(_vm.formatDisplayDate(_vm.bid['meta' + field.id]))+"\n                    ")]:[_vm._v("\n                      "+_vm._s(_vm.bid['meta' + field.id])+"\n                    ")]]:_vm._e(),_vm._v(" "),(_vm.cellIsUpdateable('meta_' + field.id))?[(['text', 'string'].includes(field.type))?_c('el-input',{staticClass:"cell-input",attrs:{"name":'meta_' + field.id,"type":"text","size":"small"},model:{value:(_vm.bid['meta' + field.id]),callback:function ($$v) {_vm.$set(_vm.bid, 'meta' + field.id, $$v)},expression:"bid['meta' + field.id]"}}):_vm._e(),_vm._v(" "),(['number', 'decimal'].includes(field.type))?_c('el-input',{staticClass:"cell-input",attrs:{"name":'meta_' + field.id,"type":"number","step":_vm.editNumberInputStep(field),"size":"small"},model:{value:(_vm.bid['meta' + field.id]),callback:function ($$v) {_vm.$set(_vm.bid, 'meta' + field.id, $$v)},expression:"bid['meta' + field.id]"}}):_vm._e(),_vm._v(" "),(['date'].includes(field.type))?_c('el-date-picker',{staticClass:"cell-input",staticStyle:{"width":"100%"},attrs:{"type":"date","placeholder":"Pick a date","value-format":"yyyy-MM-dd","format":"dd.MM.yyyy","size":"small"},model:{value:(_vm.bid['meta' + field.id]),callback:function ($$v) {_vm.$set(_vm.bid, 'meta' + field.id, $$v)},expression:"bid['meta' + field.id]"}}):_vm._e(),_vm._v(" "),(['dropdown'].includes(field.type))?_c('el-select',{staticClass:"cell-input",attrs:{"name":'meta_' + field.id},model:{value:(_vm.bid['meta' + field.id]),callback:function ($$v) {_vm.$set(_vm.bid, 'meta' + field.id, $$v)},expression:"bid['meta' + field.id]"}},_vm._l((_vm.loadedConfiguration.metaFields[
                          'meta_' + field.id
                        ].options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value,"selected":_vm.bid['meta' + field.id] == item.value}})}),1):_vm._e()]:_vm._e()],2)],1)],1)])})],2)],1)],1),_vm._v(" "),(_vm.allRevisions && _vm.allRevisions.length)?_c('el-row',{staticClass:"revision-card-wrapper"},[_c('el-row',{staticClass:"toggle"},[_vm._v("\n        Bid value revisions\n\n        "),_c('el-button',{staticClass:"toggle-button",attrs:{"type":"primary"},on:{"click":function($event){_vm.revisionsVisible = !_vm.revisionsVisible}}},[(_vm.revisionsVisible === true)?[_vm._v("\n            Hide Revisions\n          ")]:_vm._e(),_vm._v(" "),(_vm.revisionsVisible === false)?[_vm._v("\n            Show Revisions\n          ")]:_vm._e()],2)],1),_vm._v(" "),(_vm.allRevisions && _vm.allRevisions.length)?_c('div',[_c('revision-table',{attrs:{"card-title":"","revisions":_vm.allRevisions,"type":"bidValue"}})],1):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.finalizeDialogVisible)?_c('BidFinalizeDialog',{attrs:{"bid":_vm.bid},on:{"cancel":_vm.closeDialog,"itemAdded":_vm.refreshPage}}):_vm._e()],1):(_vm.bid.id)?_c('el-alert',{attrs:{"title":"This bid has already been finalized.","type":"error","show-icon":""}}):(!_vm.loading)?_c('el-alert',{attrs:{"title":"You are not able to edit/finalize this bid.","type":"error","show-icon":""}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }