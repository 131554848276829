<template>
  <lerf-report-table />
</template>

<script>
import LerfReportTable from './LerfReportTable'

export default {
  name: 'LerfReport',
  components: {
    LerfReportTable,
  },
}
</script>
