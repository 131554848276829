<template>
  <div>
    <pricing-sync-table
        ref="table"
        @select-template="handleOnSelectTemplate"
    />

    <select-template-modal
      :edit-item="editItem"
      :template-options="templateOptions"
      :visible.sync="selectTemplateModalVisible"
      @updated="handleUpdate"
    />
  </div>
</template>

<script>
import pricingTemplatesApi from 'api/v2/pricingTemplates'
import PricingSyncTable from './Table'
import pricingSyncApi from 'api/v2/pricingSync'
import SelectTemplateModal from './SelectTemplateModal'
import {cloneDeep} from 'lodash-es'
import {logErrorMessage} from '../../../../common/util';

export default {
  name: 'PricingSyncView',
  components: {
    PricingSyncTable,
    SelectTemplateModal,
  },
  data() {
    return {
      basePricingTemplates: [],
      selectTemplateModalVisible: false,
      editItem: {},
    }
  },
  computed: {
    // so we can later add this for subcontractors
    templateOptions() {
      return this.basePricingTemplates
    },
    api() {
      return pricingSyncApi
    }
  },
  mounted() {
    this.fetchBaseTemplates()
  },
  methods: {
    fetchBaseTemplates() {
      pricingTemplatesApi.index(
        (response) => {
          this.basePricingTemplates = response
        },
        (error) => {
          logErrorMessage(error)
        },
        {
          ofBaseType: true,
          ofNoSubcontractor: true,
        }
      )
    },
    handleOnSelectTemplate(item) {
      this.editItem = cloneDeep(item)
      this.selectTemplateModalVisible = true
    },
    handleUpdate() {
      this.$refs.table.$refs.pricingSyncIndex.refresh()
    },
  },
}
</script>

<style></style>
