<template>
  <div v-if="subcontractor && subcontractor.id">
    <el-row :gutter="20">
      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header" class="clearfix">Contact details</div>
          <default-form
            v-if="Object.keys(contact).length"
            ref="contactForm"
            :errors="contactFormErrors"
            :form="contactForm"
            @submit="saveContact"
          />
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header" class="clearfix">User account</div>
          <div v-if="!contact || !contact.id">
            <p>Please add the contact details first</p>
          </div>
          <default-form
            v-else-if="showUserForm"
            ref="userForm"
            :errors="userFormErrors"
            :form="userForm"
            @delete="deleteUser"
            @submit="saveUser"
            @reset-pin="resetPin"
            @reset-password="resetPassword"
          />
          <template v-else>
            <p style="margin-top: 0">This contact does not yet have access</p>
            <el-button type="primary" @click="showUserForm = true">
              Create user account
            </el-button>
          </template>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import subcontractorContactsApi from 'api/v2/subcontractorContacts'
import contactsApi from 'api/v2/contacts'
import DefaultForm from 'components/Common/Form/DefaultForm'

export default {
  name: 'EditContactForm',
  components: {
    DefaultForm,
  },
  props: {
    subcontractor: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
    editContact: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      contact: {},
      user: {},
      isNewUser: false,
      isNewContact: false,
      showUserForm: false,
      contactFormErrors: {},
      userFormErrors: {},
    }
  },
  computed: {
    ...mapGetters('global', ['roles']),
    subcontractorId() {
      return this.$route.params.subcontractorId
    },
    newContact() {
      return {
        firstname: null,
        lastname: null,
        function: null,
        phone1: null,
        phone2: null,
        email: null,
      }
    },
    newUser() {
      return {
        name: [this.contact.firstname, this.contact.lastname].join(' ').trim(),
        username: null,
        email: this.contact.email,
        pin: null,
        role: 'subcontractor_supervisor',
      }
    },
    contactForm() {
      return {
        initialModel: this.contact,
        items: [
          {
            label: 'First name',
            prop: 'firstname',
            required: true,
            rules: [],
          },
          {
            label: 'Last name',
            prop: 'lastname',
            required: true,
            rules: [],
          },
          {
            label: 'Function',
            prop: 'function',
            required: false,
            rules: [],
          },
          {
            label: 'Phone 1',
            prop: 'phone1',
            required: false,
            rules: [],
          },
          {
            label: 'Phone 2',
            prop: 'phone2',
            required: false,
            rules: [],
          },
          {
            label: 'Email',
            prop: 'email',
            required: false,
            rules: [],
          },
        ],
        buttons: [
          {
            event: 'submit',
            label: 'Save',
            type: 'primary',
          },
        ],
        ref: 'contactForm',
      }
    },
    userForm() {
      return {
        initialModel: this.user,
        items: [
          {
            label: 'Name',
            prop: 'name',
            required: true,
            rules: [],
          },
          {
            label: 'Username',
            prop: 'username',
            required: true,
            rules: [],
          },
          {
            label: 'Email',
            prop: 'email',
            required: true,
            rules: [],
          },
          {
            label: 'Password',
            prop: 'password',
            required: true,
            rules: [],
            render: this.isNewUser === true,
          },
          {
            label: 'Pin',
            prop: 'pin',
            required: true,
            rules: [],
            render: this.isNewUser === true,
          },
          {
            label: 'Role',
            prop: 'role',
            required: true,
            rules: [],
            type: 'select',
            options: this.roles,
            placeholder: 'Select a role for this user',
            disabled: this.subcontractor && this.subcontractor.id !== undefined,
          },
        ],
        buttons: [
          {
            event: 'reset-pin',
            label: 'Reset pin',
            type: 'secondary',
            render: this.isNewUser !== true,
          },
          {
            event: 'reset-password',
            label: 'Reset password',
            type: 'secondary',
            render: this.isNewUser !== true,
          },
          {
            event: 'delete',
            label: 'Delete',
            type: 'danger',
            render: this.isNewUser !== true,
          },
          {
            event: 'submit',
            label: 'Save',
            type: 'primary',
          },
        ],
        ref: 'contactForm',
      }
    },
  },
  mounted() {
    if (!this.editContact) {
      this.contact = Object.assign({}, this.newContact)
      this.user = Object.assign({}, this.newUser)
      this.isNewContact = true
      this.isNewUser = true
      return
    }
    if (this.editContact && this.editContact.id) {
      this.contact = Object.assign({}, this.editContact)
      delete this.contact.user
    }
    this.updateUserFields()
  },
  methods: {
    updateUserFields() {
      if (this.editContact.user) {
        this.user = Object.assign({}, this.editContact.user)
        this.showUserForm = true
      } else if (!(this.user && this.user.id)) {
        this.user = Object.assign({}, this.newUser)
        this.isNewUser = true
      }
    },
    saveContact(contact) {
      if (this.isNewContact) {
        subcontractorContactsApi.store(
          (response) => {
            this.contact = response
            this.isNewContact = false
            this.$nextTick(() => {
              this.updateUserFields()
            })
            this.$message.success({
              message: 'Contact created',
            })
            this.$emit('update')
            this.$emit('contact-created', contact)
          },
          (error) => {
            if (error.errors) {
              this.$set(this, 'contactFormErrors', error.errors)
              this.$refs.contactForm.validate()
            } else {
              this.showApiError(error)
            }
          },
          this.subcontractorId,
          contact
        )
      }
      if (!this.isNewContact) {
        subcontractorContactsApi.update(
          (response) => {
            this.$message.success({
              message: 'Contact updated',
            })
            this.contact = response
            this.$nextTick(() => {
              this.updateUserFields()
            })

            // close and reopen user form to display
            // updated name and email fields
            if (this.showUserForm) {
              this.showUserForm = false
              this.$nextTick(() => {
                this.showUserForm = true
              })
            }

            // let parent component know we have updated
            this.$emit('update')
          },
          (error) => {
            if (error.errors) {
              this.$set(this, 'contactFormErrors', error.errors)
              this.$refs.contactForm.validate()
            } else {
              this.showApiError(error)
            }
          },
          this.subcontractorId,
          this.contact.id,
          contact
        )
      }
    },
    saveUser(user) {
      if (this.isNewUser) {
        contactsApi.createUser(
          (response) => {
            this.$message.success({
              message: 'User created',
            })
            this.user = response
            this.isNewUser = false
            this.$emit('update')
          },
          (error) => {
            if (error.errors) {
              this.$set(this, 'userFormErrors', error.errors)
              this.$refs.userForm.validate()
            } else {
              this.showApiError(error)
            }
          },
          this.contact.id,
          user
        )
      }
      if (!this.isNewUser) {
        contactsApi.updateUser(
          (response) => {
            this.$message.success({
              message: 'User updated',
            })
            this.user = response

            // let parent component know we have updated
            this.$emit('update')
          },
          (error) => {
            if (error.errors) {
              this.$set(this, 'userFormErrors', error.errors)
              this.$refs.userForm.validate()
            } else {
              this.showApiError(error)
            }
          },
          this.contact.id,
          this.user.id,
          user
        )
      }
    },
    async deleteUser() {
      try {
        await this.$confirm(
          'This will permanently delete this user. Continue?',
          'Warning',
          {
            confirmButtonText: 'Yes, delete',
            cancelButtonText: 'No, I want my mommy',
            type: 'warning',
          }
        )
        contactsApi.deleteUser(
          () => {
            this.$message({
              type: 'success',
              message: 'User deleted',
            })
            this.user = Object.assign({}, this.newUser)
            this.isNewUser = true
            this.showUserForm = false

            // let parent component know we have updated
            this.$emit('update')
          },
          (error) => this.showApiError(error),
          this.contact.id,
          this.user.id
        )
      } catch (e) {
        if (e === 'cancel') {
          this.$message({
            type: 'info',
            message: 'Delete canceled',
          })
        } else {
          this.$message({
            type: 'error',
            message: e.message,
          })
        }
      }
    },
    async resetPassword() {
      try {
        const prompt = await this.$prompt(
          'Please enter a new password',
          'Question',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
          }
        )
        const password = prompt.value.toString()
        contactsApi.updateUser(
          () => {
            this.$message.success({
              message: 'Password updated',
            })
          },
          (error) => {
            this.showApiError(error)
          },
          this.contact.id,
          this.user.id,
          {password}
        )
      } catch (e) {
        if (e === 'cancel') {
          // noop
        } else {
          this.$message({
            type: 'error',
            message: e.message,
          })
        }
      }
    },
    async resetPin() {
      try {
        const prompt = await this.$prompt(
          'Please enter a new pin',
          'Question',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
          }
        )
        const pin = prompt.value.toString()
        contactsApi.updateUser(
          () => {
            this.$message.success({
              message: 'Pin updated',
            })
          },
          (error) => {
            this.showApiError(error)
          },
          this.contact.id,
          this.user.id,
          {pin}
        )
      } catch (e) {
        if (e === 'cancel') {
          // noop
        } else {
          this.$message({
            type: 'error',
            message: e.message,
          })
        }
      }
    },
    showApiError(error) {
      this.$message.error({
        showClose: true,
        message: error.message || error,
        duration: 5000,
      })
    },
  },
}
</script>

<style></style>
