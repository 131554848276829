import ApiResource from '../ApiResource'

class ProjectResource extends ApiResource {
  constructor() {
    super({
      name: 'projects',
    })

    this.getSingleItem = this.show
    this.deleteItem = this.delete
    this.archive = this.archiveProject
    this.restore = this.restoreProject
  }

  updateContact(resolve, reject, projectId, payload) {
    this.ids = [projectId]
    this.post(`${this.getUrl()}/update-contact`, payload)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  getAvailableContacts(resolve, reject, projectId, params) {
    this.ids = [projectId]
    this.get(`${this.getUrl()}/available-contacts`, { params })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  detachAllContacts(resolve, reject, projectId) {
    this.ids = [projectId]
    this.post(`${this.getUrl()}/detach-all-contacts`)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  getArchivedProjects(resolve, reject, params) {
    this.ids = []
    this.get(`${this.getUrl()}/archived`, {
      params: {
        ...params,
        include: ['projectManagerContact', 'projectLeadContact', 'customer'],
      },
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  archiveProject(resolve, reject, projectId) {
    this.ids = [projectId]
    this.post(`${this.getUrl()}/archive`)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  restoreProject(resolve, reject, projectId) {
    this.ids = [projectId]
    this.post(`${this.getUrl()}/restore`)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  /**
   * Retrieve positionItems between to dates. Generate a report from them
   * or use it to import positionItems to a lerf
   *
   * @param {Function} resolve
   * @param {Function} reject
   * @param {Number} projectId
   * @param {Object} params
   * @param {String} params.startDate
   * @param {String} params.endDate
   * @param {String} [params.area]
   */
  getPositionItems(resolve, reject, projectId, params) {
    this.ids = [projectId]
    this.get(`${this.getUrl()}/item-import`, { params })
  }

  exportToZip(resolve, reject, projectId) {
    this.ids = [projectId]
    this.get(
      `${this.getUrl()}/export-to-zip`,
    )
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  }

}

const projectsApi = new ProjectResource()

export default projectsApi
