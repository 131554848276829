import ApiResource from '../ApiResource'

class ProjectMetaReportResource extends ApiResource {
  constructor() {
    super({
      name: 'project-meta-report',
    })
  }

  export(resolve, reject, format, params) {
    this.get(`${this.getUrl()}/${format}`, { params: params })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  indexAreas(resolve, reject, params) {
    this.index(resolve, reject, { is_areas: true, ...params })
  }

  exportAreas(resolve, reject, format, params) {
    this.get(`${this.getUrl()}/${format}`, {
      params: { is_areas: true, ...params },
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const ProjectMetaReportApi = new ProjectMetaReportResource()

export default ProjectMetaReportApi
