module.exports = {
  api: {
    baseUrl: 'https://api.soli-infra.de/',
    clientSecret: 'uL3P7j3hAGtIhTxFGWdZ0v5e8kgz6MaqA1lgBcJl',
    clientId: 2
  },
  evergabe: {
    url: 'https://api.soli-infra.de',
    username: 'evergabe',
    password: 'secret'
  }
}
