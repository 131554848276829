import Vue from 'vue'

// Sentry
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing';

// Localization
import numeral from 'numeral'
import moment from 'moment'
import localization from 'moment/locale/en-gb';
moment.locale('en', localization);

import './plugins/element-ui';

import config from 'config/app'
import router from './router'
import store from './store'
import App from './App'

const debug = process.env.NODE_ENV !== 'production'

numeral.register('locale', 'nl', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: function (number) {
    return number === 1 ? 'er' : 'ème'
  },
  currency: {
    symbol: '€',
  },
})
numeral.locale('nl')

if (debug === false) {
  Sentry.init({
    Vue,
    dsn: 'https://44f477362bde4c69a439d7d06a81650d@o74657.ingest.sentry.io/187855',
    enabled: !debug,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', 'api.soli-infra.de', 'api-staging.soli-infra.de', /^\//],
      }),
    ],
    allowUrls: [/https?:\/\/(?:[^/]+\.)?soli-infra\.de/],
    ignoreErrors: [
      'Unauthenticated.',
      'Network Error',
      'This action is unauthorized.',
      /Navigation(Duplicated|Aborted|Cancelled)/
    ],
    // Rate at which errors are sampled and sent (0 to 1)
    sampleRate: 1.0,
    // Rate at which performance metrics are sent (0 to 1)
    tracesSampleRate: 0.05,
    environment: process.env.NODE_ENV,
    release: config.version,
  });
  Sentry.setTag('git_commit', config.commitSha)
  Sentry.setTag('git_tag', config.commitTag)

  store.subscribe(function (mutation) {
    if (mutation.type === 'global/user') {
      Sentry.setUser({
        email: mutation.payload.email,
      })
    } else if (mutation.type === 'auth/logout') {
      Sentry.setUser(null)
    }
  })
}

/* eslint-disable no-new */
new Vue({
  el: '#elem-app',
  router,
  render: (h) => h(App),
  store,
})
