import ApiResource from '../ApiResource'

class ProjectAreaMetaResource extends ApiResource {
  constructor() {
    super({
      name: 'project-area-meta',
    })
  }

  configuration(resolve, reject, ...params) {
    this.get(`${this.getUrl()}/configuration`, { params: params })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
  updateConfiguration(resolve, reject, visibleColumns) {
    this.put(`${this.getUrl()}/configuration`, {
      visibleColumns: visibleColumns,
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
  revisions(resolve, reject, ...params) {
    this.get(`${this.getUrl()}/revisions`, { params: params })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const ProjectAreaMetaApi = new ProjectAreaMetaResource()

export default ProjectAreaMetaApi
