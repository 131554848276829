<template>
  <el-dialog
    class="lerf-edit-item-dialog"
    :close-on-click-modal="false"
    :visible.sync="visible"
    title="Add Invoice item"
    :before-close="close"
  >
    <el-form label-width="120px" label-position="top">
      <el-form-item ref="external_reference" label="Comment">
        <el-input v-model="newItem.comment" :required="true" />
      </el-form-item>
      <el-form-item ref="section" label="Section">
        <el-input v-model="newItem.section" />
      </el-form-item>
      <el-form-item ref="position" label="Pricing item">
        <el-select
          v-model="newItem.pricingItem"
          placeholder="Please select..."
          filterable
          return-object
          value-key="id"
          class="position-select"
          @change="itemChanged"
        >
          <el-option-group
            v-for="optionGroup in pricingItemOptions"
            :key="optionGroup.id"
            :label="optionGroup.name"
            :visible="true"
          >
            <el-option
              v-for="option in optionGroup.options"
              :key="option.id"
              :label="`${option.reference} / ${option.description}`"
              :value="option"
            />
          </el-option-group>
        </el-select>
      </el-form-item>

      <el-row :gutter="35">
        <el-col :span="8">
          <el-form-item
            v-if="muffeOptions.length"
            ref="muffe_id"
            label="Muffe"
            prop="muffe_id"
          >
            <el-select
              v-if="muffeOptions.length"
              v-model="newItem.location.id"
              placeholder="Please select a Muffe"
              filterable
            >
              <el-option
                v-for="(muffe, index) in muffeOptions"
                :key="index"
                :label="`${muffe.name}`"
                :value="muffe.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item
            v-if="mfgOptions.length"
            ref="mfg_id"
            label="Mfg"
            prop="mfg_id"
          >
            <el-select
              v-if="mfgOptions.length"
              v-model="newItem.location.id"
              placeholder="Please select a mfg"
              filterable
            >
              <el-option
                v-for="(mfg, index) in mfgOptions"
                :key="index"
                :label="`${mfg.name}`"
                :value="mfg.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="5">
        <el-col :span="12">
          <el-form-item
            v-if="streetOptions.length"
            ref="street_id"
            label="Street"
            prop="street_id"
          >
            <el-select
              v-if="streetOptions.length"
              v-model="newItem.location.id"
              placeholder="Please select a street"
              filterable
            >
              <el-option
                v-for="(street, index) in streetOptions"
                :key="index"
                :label="street.name"
                :value="street.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="Measurements" class="measurements">
        <el-row :gutter="20">
          <el-col :span="5">
            <el-input
              v-if="
                !newItem.pricingItem ||
                ['M', 'M2', 'M3'].indexOf(newItem.pricingItem.unit) !== -1
              "
              v-model="newItem.length"
              :required="true"
            >
              <template slot="prepend">Length</template>
              <template slot="append">M</template>
            </el-input>
          </el-col>
          <el-col :span="5">
            <el-input
              v-if="
                !newItem.pricingItem ||
                ['M2', 'M3'].indexOf(newItem.pricingItem.unit) !== -1
              "
              v-model="newItem.width"
              :required="true"
              prop="newItem.width"
            >
              <template slot="prepend">Width</template>
              <template slot="append">M</template>
            </el-input>
          </el-col>
          <el-col :span="5">
            <el-input
              v-if="!newItem.pricingItem || newItem.pricingItem.unit === 'M3'"
              v-model="newItem.height"
              :required="true"
            >
              <template slot="prepend">Height</template>
              <template slot="append">M</template>
            </el-input>
          </el-col>
          <el-col :span="5">
            <el-input
              v-if="
                !newItem.pricingItem ||
                newItem.pricingItem.unit === 'ST' ||
                newItem.pricingItem.unit === 'STD'
              "
              v-model="newItem.amount"
              :required="true"
            >
              <template slot="prepend">{{
                newItem.pricingItem && newItem.pricingItem.unit === 'STD'
                  ? 'Hours'
                  : 'Amount'
              }}</template>
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-input v-model="newItem.factor" :required="true">
              <template slot="prepend">Factor</template>
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item label="Calculated" class="calculated">
        <el-row :gutter="20">
          <el-col :span="5">
            <el-input
              v-model="getMass"
              :required="true"
              :readonly="true"
              disabled
            >
              <template slot="prepend">Mass</template>
              <template v-if="newItem.pricingItem" slot="append">{{
                newItem.pricingItem.unit
              }}</template>
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-input v-model="newItem.price" :required="true">
              <template slot="prepend">Price</template>
              <template slot="append">EUR</template>
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-input
              v-model="getGross"
              :required="true"
              :readonly="true"
              disabled
            >
              <template slot="prepend">Gross</template>
              <template slot="append">EUR</template>
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-input
              v-model="getNet"
              :required="true"
              :readonly="true"
              disabled
            >
              <template slot="prepend">Net</template>
              <template slot="append">EUR</template>
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item>
        <el-button :loading="loading" type="primary" @click="onSubmit">
          Create
        </el-button>
        <el-button type="warning" @click="close"> Cancel </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import lerfItemsApi from 'api/v2/lerfItems'
import projectAreasApi from 'api/v2/projectAreas'
import projectPricingCategoriesApi from 'api/v2/projectPricingCategories'
import pricingTemplateApi from '../../api/v2/pricingTemplates'
import * as lerfUtil from '../../common/lerfUtil'

import {formatErrorMessage, logErrorMessage} from '../../common/util'
import { cloneDeep } from 'lodash-es'
import snakecaseKeys from 'snakecase-keys';

export default {
  name: 'LerfItemAddDialog',
  props: {
    lerf: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      visible: true,

      areas: [],
      categories: [],
      sub_categories: [],

      pricingCategories: [],
      pricingItems: [],
      pricingTemplate: {},
      selectedPricingItem: null,
      selectedPricingItemArea: null,
      newItem: {
        location: {
          id: null,
          type: null
        },
        comment: null,
        width: 0,
        height: 0,
        length: 0,
        amount: 0,
        price: null,
        factor: 1,
        unit: 'M',
      },
    }
  },
  computed: {
    getMass() {
      return lerfUtil.getMass(this.newItem)
    },
    getGross() {
      return lerfUtil.getGross(this.newItem, true)
    },
    getNet() {
      return lerfUtil.getNet(this.newItem, true)
    },
    pricingItemOptions() {
      if (!this.pricingCategories || !this.pricingItems) {
        return []
      }
      const options = []
      this.pricingCategories.forEach((pricingCategory) => {
        const optionGroup = {
          id: `cat-${pricingCategory.id}`,
          name: pricingCategory.name,
          options: [],
        }
        optionGroup.options = this.pricingItems.filter(
          (pricingItem) =>
            pricingItem.pricing_category_id === pricingCategory.id
        )
        options.push(optionGroup)
      })
      return options
    },
    streetOptions() {
      if (this.selectedPricingItemArea !== 'street') return []

      let options = []
      this.areas.forEach((area) => {
        if (area.streets && area.streets.length) {
          options = options.concat(area.streets)
        }
      })
      return options
    },
    muffeOptions() {
      if (this.selectedPricingItemArea !== 'muffe') return []

      let options = []

      this.areas.forEach((area) => {
        if (area.muffen && area.muffen.length) {
          options = options.concat(area.muffen)
        }
      })
      return options
    },
    mfgOptions() {
      if (this.selectedPricingItemArea !== 'mfg') return []

      let options = []
      this.areas.forEach((area) => {
        if (area.mfgs && area.mfgs.length) {
          options = options.concat(area.mfgs)
        }
      })
      return options
    },
    subcategoryOptions() {
      if (!this.newItem.pricing_category_id) return []
      if (!this.sub_categories) return []
      let selectedCategory = this.categories.find(
        (o) => o.id === this.newItem.pricing_category_id
      )
      if (!selectedCategory) return []
      let options = this.sub_categories.filter(
        (subcategory) => subcategory.categoryName === selectedCategory.name
      )
      return options
    },
  },
  watch: {
    'newItem.pricing_category_id': function () {
      this.newItem.pricing_subcategory_id = this.newItem.pricing_category_id
    },
    errors: function (error) {
      if (error !== null) {
        let errorMessage

        if (error && error.message) {
          errorMessage = error.message
        } else {
          errorMessage = 'A validation error occured'

          // Let's set the errors
          Object.entries(error).forEach(([key, value]) => {
            this.$refs[key].error = value[0]
          })
        }

        this.$message.error({
          showClose: true,
          message: errorMessage,
          duration: 10000,
        })
      }
    },
  },
  mounted() {
    this.getCategories();
    this.loadAreas();
  },
  methods: {
    close(done) {
      this.$emit('cancel')
      if (done && typeof done === 'function') {
        this.$nextTick(() => {
          done()
        })
      }
    },
    onSubmit() {
      const item = cloneDeep(this.newItem)
      delete item.pricingItem
      this.loading = true
      lerfItemsApi.store(
        (response) => {
          this.loading = false
          this.$emit('addItem', snakecaseKeys(response))
          this.close()
        },
        (error) => {
          this.loading = false
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.lerf.id,
        item
      )
    },
    getCategories() {
      pricingTemplateApi.index(
        (response) => {
          const pricingTemplate = response[0]
          this.$set(this, 'pricingTemplate', pricingTemplate)
          this.$set(this, 'pricingCategories', pricingTemplate.categories)
          this.$set(this, 'pricingItems', pricingTemplate.items)
        },
        (error) => {
          logErrorMessage(error)
        },
        {
          project_id: this.lerf.projectId,
          subcontractor_id: this.lerf.subcontractor
            ? this.lerf.subcontractor.id
            : null,
          include: ['categories', 'items'],
        }
      )

      projectPricingCategoriesApi.index(
        (response) => {
          this.categories = response
        },
        (error) => {
          this.loading = false
          this.categories = []

          logErrorMessage(error)
        },
        this.lerf.projectId,
        {}
      )
    },
    loadAreas() {
      if (!this.lerf.projectId) {
        return
      }
      projectAreasApi.index(
        (response) => {
          this.areas = response
          if (!this.areas.length) {
            const ok = confirm('Please add areas before creating a position.')

            if (ok) {
              this.$router.push({
                path: 'projects/edit/:projectId',
                name: 'edit',
                params: { projectId: this.lerf.projectId },
              })
            }
          }
        },
        (error) => {
          this.loading = false
          logErrorMessage(error)
        },
        this.lerf.projectId,
        {
          include: ['streets', 'muffen', 'mfgs'],
        }
      )
    },
    itemChanged(selectedItem) {
      this.updateItemAmounts(selectedItem)
    },
    updateItemAmounts(selectedPricingItem) {
      if (!selectedPricingItem) return
      if (!this.newItem) return

      const pricingItem = this.pricingItems.find((item) => {
        return item.id === selectedPricingItem.id
      })

      this.updatePricingItemArea(selectedPricingItem)
      this.newItem.pricing_item_id = pricingItem.id
      this.newItem.price = pricingItem.price
      this.newItem.factor = pricingItem.factor
      this.newItem.unit = pricingItem.unit
    },
    updatePricingItemArea(selectedPricingItem) {
      let category = this.categories.find(
        (o) => o.id === selectedPricingItem.pricing_category_id
      )

      this.selectedPricingItemArea = category.areaSelection;
      this.updateLocationData(category.areaSelection);
    },
    updateLocationData(locationType) {
      const location = this.newItem.location
      // check - location type
      if (locationType === 'street') {
        this.newItem.location = {
          id: location.street_id,
          type: locationType,
        }
      }
      else if (locationType === 'mfg') {
        this.newItem.location = {
          id: location.mfg_id,
          type: locationType,
        }
      }
      else if (locationType === 'muffe') {
        this.newItem.location = {
          id: location.muffe_id,
          type: locationType,
        }
      }
    }
  },
}

</script>

<style lang="scss">
.lerf-edit-item-dialog {
  .line {
    padding-left: 2rem;

    &:first-child {
      padding-left: 0;
    }
  }
}

.position-filter {
  max-width: 50vw;
}
</style>
