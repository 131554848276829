<template>
  <div>
    <el-row :gutter="24" class="header-action-row">
      <el-col :span="12">

        <el-button
          v-if="canAddLerf"
          size="medium"
          type="primary"
          @click="addDialogVisible = true"
        >
          Add Invoice
        </el-button>
        <div class="grid-content bg-purple"/>
      </el-col>
      <el-col :span="12" style="text-align: right">
        <el-button
          :loading="isDownloading"
          size="medium"
          type="primary"
          @click="exportLerfs"
        >
          Export
        </el-button>
      </el-col>
    </el-row>

    <DataTableAlternate
      ref="dataTable"
      :api="lerfsApi()"
      :data-table-options="dataTableOptionsFiltered"
      :filter-types="{id: 'text', external_reference: 'text', project: 'text'}"
      :request-data="additionalRequestData"
      :row-actions="rowActions"
      table-name="LerfsTable"
      @cell-dblclick="openItem"
      @row-action="rowAction"
    >
      <template #default="{ row, column }">
        <el-tag
          v-if="column === 'status'"
          :color="status[row.status].meta.color"
          :effect="tagBackground(row.status)"
          close-transition
        >
          {{ status[row.status].label }}
        </el-tag>
        <span v-else-if="column === 'project'">
          {{ row[column].name }}
        </span>
        <span v-else>
          {{$refs.dataTable.getColumnValue(column, row)}}
        </span>
      </template>
    </DataTableAlternate>
    <lerf-add-dialog
      v-if="addDialogVisible"
      :project="project"
      @cancel="closeDialog"
    />
  </div>
</template>
<script>
import DataTableAlternate from '@/js/components/Common/DataTableAlternate.vue';
import lerfsApi from '@/js/api/v2/lerfs';
import LerfAddDialog from '@/js/components/Lerfs/AddDialog.vue';
import {can, formatErrorMessage} from '@/js/common/util';

export default {
  name: 'LerfViewWithFilters',
  components: {
    LerfAddDialog,
    DataTableAlternate
  },
  props: {
    project: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      addDialogVisible: false,
      isDownloading: false,
      dataTableOptions: {
        showRefresh: false,
        tableHeightOffset: 180,
        columnWidth: {
          id: 80,
          status: 300,
          project_id: 125,
          project: 200,
          _rowActions: 80,
          area: 200,
          created_at: 130,
          updated_at: 130,
          start_at: 100,
          end_at: 100,
          origin: 150,
          user_name: 180,
          invoicing_factor: 100,
          net: 130,
          gross: 130,
          pricing_category_id:120,
          external_reference:200,
          external_reference_second:220,
          external_reference_third:200,
          description:200,
        }
      }
    }
  },
  computed: {
    dataTableOptionsFiltered() {
      const data = this.dataTableOptions
      if(this.project) {
        data.tableHeightOffset = 220
      }
      return data
    },
    additionalRequestData() {
      const data = {include:['project', 'subcontractor']}
      if(this.project) {
        data.project_id = this.project.id
      }
      return data
    },
    canAddLerf() {
      return can('create', 'lerf')
    },
    status() {
      return this.$store.getters['global/getEnumById']('lerf_status')
    },
    rowActions() {
      let rowActions = {}
      if (this.canEditLerf) {
        rowActions.openItem = {
          label: false,
          icon: 'el-icon el-icon-edit'
        }
      }
      if (this.canRemoveLerf) {
        rowActions.removeItem = {
          label: false,
          icon: 'el-icon el-icon-delete',
          type: 'danger'
        }
      }
      return rowActions;
    },
    canRemoveLerf() {
      return can('delete', 'lerf')
    },
    canEditLerf() {
      return can('update', 'lerf')
    },
  },
  methods: {
    lerfsApi() {
      return lerfsApi
    },
    closeDialog() {
      this.addDialogVisible = false
    },
    openItem(item) {
      this.$router.push(
        '/projects/' + item.project_id + '/lerfs/' + item.id + '/overview'
      )
    },
    rowAction(action) {
      if (this[action.action]) {
        this[action.action](action.data);
      }
    },
    removeItem(item) {
      let confirm = window.confirm('Are you sure?')
      if (confirm) {
        lerfsApi.delete(
          () => {
            this.$message({
              type: 'success',
              message: 'Deleted',
              duration: 3000,
            })
            this.$refs.dataTable.refresh();
          },
          (error) => {
            this.$message({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 0,
            })
          },
          item.id
        )
      }
    },
    tagBackground(status) {
      if (
        [
          'one',
          'two',
          'three',
          'four',
          'five',
          'six',
          'seven', // Green Background
          'eight',
          'nine' + '',
        ].includes(status)
      ) {
        return 'dark'
      }
      return 'light'
    },
    exportLerfs() {
      this.isDownloading = true
      const filename = this.project
        ? `project-${this.project.name}-lerfs-export`
        : 'lerfs-export'
      lerfsApi.export(
        (response) => {
          let blob = new Blob([response])
          let link = document.createElement('a')
          const objURL = window.URL.createObjectURL(blob)
          link.href = objURL
          link.download = filename + '.csv'
          link.click()
          window.URL.revokeObjectURL(objURL)
          this.isDownloading = false
        },
        (error) => {
          this.isDownloading = false
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.project ? this.project.id : null
      )
    },
  }
}

</script>
