<template>
  <ClaimsTable :project="project" />
</template>

<script>
import ClaimsTable from '../../../../Claims/ClaimsTable'

export default {
  name: 'ProjectClaims',
  components: {
    ClaimsTable,
  },

  props: {
    project: {
      type: Object,
      default: null,
    },
  },
}
</script>
