<template>
  <view-add-edit-delete-view
    :headers="headers"
    :api="customersApi"
    :data="customers"
    identifying-prop="name"
    :vocabulary="{ single: 'customer', plural: 'customers' }"
    :buttons="buttons"
    category="customer"
    :max-table-width="900"
    @data="processData"
  >
    <template slot="addItemDialog">
      <dialog-add-customer
        @cancel="$emit('cancel')"
        @changed="$emit('changed')"
      />
    </template>

    <template slot="editItemDialog" slot-scope="{ item }">
      <dialog-edit-customer
        :customer="item"
        @cancel="$emit('cancel')"
        @changed="$emit('changed')"
      />
    </template>
  </view-add-edit-delete-view>
</template>

<script>
import ViewAddEditDeleteView from 'components/Views/Common/ViewAddEditDeleteView'
import DialogAddCustomer from 'components/Customers/DialogAddCustomer'
import DialogEditCustomer from 'components/Customers/DialogEditCustomer'

import customersApi from 'api/v2/customers'

export default {
  name: 'CustomersView',

  components: {
    ViewAddEditDeleteView,
    DialogAddCustomer,
    DialogEditCustomer,
  },

  data() {
    return {
      customers: [],

      headers: [
        {
          prop: 'name',
          label: 'Name',
          sortable: true,
          width: 300,
        },
        {
          prop: 'short_name',
          label: 'Short name',
          sortable: true,
          width: 200,
        },
        {
          prop: 'address',
          label: 'Address',
          sortable: true,
        },
      ],

      buttons: [
        {
          label: 'edit',
          event: 'change',
          faIcon: 'el-icon el-icon-edit',
          title: 'Edit customer',
          prop: 'id',
          capability: 'update',
        },
        {
          label: 'delete',
          faIcon: 'el-icon el-icon-delete',
          title: 'Delete customer',
          event: 'delete',
          type: 'danger',
          prop: 'id',
          capability: 'delete',
        },
      ],
    }
  },

  computed: {
    customersApi() {
      return customersApi
    },
  },

  methods: {
    processData(data) {
      this.customers = data
    },
  },
}
</script>

<style lang="scss">
.customers-view {
  .pagination {
    float: right;
    margin-top: 1rem;

    &:after {
      content: '';
      clear: both;
      display: block;
    }
  }
}
</style>
