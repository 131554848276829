import ApiResource from '../ApiResource'

class ProjectFieldResource extends ApiResource {
  constructor() {
    super({
      name: 'project-fields',
    })
  }
}

const projectFieldsApi = new ProjectFieldResource()

export default projectFieldsApi
