<template>
  <div class="core-topbar">
    <header v-if="loggedIn" class="core-header">
      <h1>{{ title }}</h1>
      <el-tag v-if="titleTag" class="title-tag" :color="titleColor">
        {{ titleTag }}
      </el-tag>
    </header>
    <span class="buttons-right">
      <el-button v-if="false" type="text" @click="logErrorWithSentry">
        <i class="el-icon el-icon-warning-outline"></i>
      </el-button>

      <el-button type="text" @click="profile">
        <i class="el-icon el-icon-user-solid"></i>
      </el-button>

      <el-button type="text" @click="logout">
        <i class="el-icon el-icon-switch-button"></i>
      </el-button>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import { assert } from 'common/util'

export default {
  name: 'CoreTopbar',

  components: {},

  props: {
    loggedIn: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('global', ['titleColor', 'titleTag', 'customTitle']),

    title() {
      if (this.customTitle) {
        return this.customTitle
      }

      if (this.$route.meta.title instanceof Function) {
        return this.$route.meta.title(this.$store, this.$route.params)
      }

      if (this.$route && this.$route.meta && this.$route.meta.title) {
        return this.$route.meta.title
      }

      return ''
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('auth/logout')

      // Due to how z-index is determined by the framework, we display the message on the next tick
      // so it is displayed above the modal of /login
      Vue.nextTick(() => {
        this.$message.info({
          showClose: true,
          message: 'You have been logged out',
          duration: 5000,
        })
      })
    },

    profile() {
      this.$router.push('/profile')
    },

    logErrorWithSentry() {
      assert(false, 'This error shows that sentry is configured correctly', {})
      this.$message.success({
        message: 'Success! We should see an error logged in Sentry now',
      })
    },
  },
}
</script>

<style lang="scss">
.core-topbar {
  background: rgb(50, 65, 87);
  z-index: 4;
  .el-button {
    margin: 0;
    padding: 0;
  }
  .el-button + .el-button {
    margin-left: 10px;
  }
  .el-icon {
    color: #eee;
    font-size: 24px;
    font-weight: normal;
  }

  .core-header {
    padding: 0 10px 0 260px;
    color: #eee;
    line-height: 49px;
    vertical-align: middle;
    h1 {
      font-size: 1rem;
      line-height: 1;
      display: inline-block;
      margin: 0px;
      text-transform: uppercase;
      vertical-align: middle;
    }

    .title-tag {
      margin-left: 1rem;
      display: inline-block;
      background: #8391a5;
      color: #ffffff;
      vertical-align: middle;
      line-height: 32px;
      height: 32px;
    }

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .buttons-right {
    top: 50%;
    transform: translateY(-50%);
    right: 2rem;
    position: absolute;
  }

  .fa-exclamation-triangle {
    color: darken(red, 15%);
  }
}
</style>
