<template>
  <view-add-edit-delete-view
    :api="teamsApi"
    :buttons="buttons"
    :data="teams"
    :headers="headers"
    :max-table-width="600"
    :vocabulary="{ single: 'team', plural: 'teams' }"
    category="team"
    identifying-prop="name"
    show-table-search
    @data="processData"
  >
    <template slot="extraButtons">
      <el-button :loading="exporting" @click="exportTeamsAsCSV"
      >Export (csv)
      </el-button
      >
    </template>

    <template slot="addItemDialog">
      <dialog-add-team @cancel="$emit('cancel')" @changed="$emit('changed')"/>
    </template>

    <template slot="editItemDialog" slot-scope="{ item }">
      <dialog-edit-team
        :team="item"
        size="medium"
        @cancel="$emit('cancel')"
        @changed="$emit('changed')"
      />
    </template>
  </view-add-edit-delete-view>
</template>

<script>
import ViewAddEditDeleteView from 'components/Views/Common/ViewAddEditDeleteView'
import DialogAddTeam from 'components/Teams/DialogAddTeam'
import DialogEditTeam from 'components/Teams/DialogEditTeam'

import teamsApi from 'api/v2/teams'

export default {
  name: 'TeamsView',

  components: {
    ViewAddEditDeleteView,
    DialogAddTeam,
    DialogEditTeam,
  },

  data() {
    return {
      teams: [],

      exporting: false,

      headers: [
        {
          prop: 'name',
          label: 'Name',
          sortable: true,
        },
      ],

      buttons: [
        {
          label: 'edit',
          faIcon: 'el-icon el-icon-edit',
          event: 'change',
          prop: 'id',
          capability: 'update',
        },
        {
          label: 'delete',
          event: 'delete',
          faIcon: 'el-icon el-icon-delete',
          type: 'danger',
          prop: 'id',
          capability: 'delete',
        },
      ],
    }
  },

  computed: {
    teamsApi() {
      return teamsApi
    },
  },

  methods: {
    processData(data) {
      this.teams = data
    },

    exportTeamsAsCSV() {
      this.exporting = true
      teamsApi.downloadCSV(
        (response) => {
          if (response.data.url) {
            const aElement = document.createElement('a')
            aElement.href = response.data.url
            aElement.download = response.data.filename
            aElement.click()
          }

          this.exporting = false
        },
        () => {
          this.exporting = false
        }
      )
    },
  },
}
</script>

<style lang="scss">
.teams-view {
  .pagination {
    float: right;
    margin-top: 1rem;

    &:after {
      content: '';
      clear: both;
      display: block;
    }
  }
}
</style>
