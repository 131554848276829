import ApiResource from '../ApiResource'

class NotificationResource extends ApiResource {
  constructor() {
    super({
      name: 'notifications',
    })
  }
  restore(resolve, reject, notificationId, params) {
    this.put(`api/v2/notifications/${notificationId}/restore`, params)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  getStats(resolve, reject, notificationId, params) {
    this.get('api/v2/notifications/get-stats', params)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  showNotification(resolve, reject, notificationId) {
    this.get(`api/v2/notifications/${notificationId}`)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  getEvents(resolve, reject) {
    this.get('event-stream/global')
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const notificationsApi = new NotificationResource()

export default notificationsApi
