<template>
  <div class="purchase-invoice-edit-form">
    <el-form label-width="120px" label-position="top">
    <el-form-item label="External reference (Invoice number)">
      <el-input v-model="purchaseInvoiceForm.externalReference" />
    </el-form-item>
    <el-form-item label="External reference 2 (Beleg/Auftrag number)">
      <el-input v-model="purchaseInvoiceForm.externalReferenceSecond" />
    </el-form-item>
    <el-form-item label="External reference 3 (Abschlagsnummer)">
      <el-select
        v-model="purchaseInvoiceForm.externalReferenceThird"
        placeholder="Please select an installment number"
        filterable
      >
        <el-option
          v-for="item in installmentNumbers"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="Origin">
      <el-select
        v-model="purchaseInvoiceForm.origin"
        placeholder="Please select an origin"
        filterable
      >
        <el-option
          v-for="item in origin"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form-item>
    <el-form-item
      v-if="purchaseInvoice.origin === 'nachunternehmer'"
      label="Subcontractor"
    >
      <el-select
        v-model="purchaseInvoiceSubcontractorId"
        placeholder="Please select a subcontractor"
        :loading="loadingSubcontractors"
        :disabled="purchaseInvoice.items?.length ? true : false"
        filterable
      >
        <el-option
          v-for="subcontractor in subcontractors"
          :key="subcontractor.id"
          :label="subcontractor.name"
          :value="subcontractor.id"
        />
      </el-select>
    </el-form-item>
    <el-alert
      v-if="purchaseInvoice.items?.length"
      type="info"
      style="margin-top:-15px;margin-bottom:15px;"
      clearable="false"
    >
      There are one or more items added to this invoice, the Subcontractor cannot be changed.
    </el-alert>
    <el-form-item label="Area">
      <el-select
        v-model="purchaseInvoiceForm.area"
        placeholder="Please select an area"
        multiple
        filterable
      >
        <el-option
          v-for="(area) in areas"
          :key="area.id"
          :label="area.name"
          :value="area.name"
        />
      </el-select>
    </el-form-item>
    <el-form-item
      ref="plan_index"
      label="Plan index"
      prop="planIndex"
    >
      <el-input v-model="purchaseInvoiceForm.planIndex" />
    </el-form-item>
    <el-form-item label="Description">
      <el-input v-model="purchaseInvoiceForm.description" type="textarea" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit"> Update </el-button>
    </el-form-item>
  </el-form>
  </div>
</template>

<script>
import purchaseInvoicesApi from '@/js/api/v2/purchaseInvoices'
import subcontractorsApi from 'api/v2/subcontractors'
import projectAreasApi from 'api/v2/projectAreas'
import { formatErrorMessage, logErrorMessage } from '../../common/util'

export default {
  name: 'PurchaseInvoiceFormGeneral',
  props: {
    purchaseInvoice: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      purchaseInvoiceForm: {
        externalReference: null,
        externalReferenceSecond: null,
        externalReferenceThird: null,
        planIndex: null,
        origin: null,
        area: null,
        description: null,
        subcontractor: null,
      },
      subcontractors: [],
      loadingSubcontractors: true,
      installmentNumbers: [ // Abschlagsnummer(s)
        {value:'', 'label': '(none)'},
        {value: 'Abschlagsrechnung-Nr.1'},
        {value: 'Abschlagsrechnung-Nr.2'},
        {value: 'Abschlagsrechnung-Nr.3'},
        {value: 'Abschlagsrechnung-Nr.4'},
        {value: 'Abschlagsrechnung-Nr.5'},
        {value: 'Abschlagsrechnung-Nr.6'},
        {value: 'Abschlagsrechnung-Nr.8'},
        {value: 'Abschlagsrechnung-Nr.9'},
        {value: 'Abschlagsrechnung-Nr.10'},
        {value: 'Abschlagsrechnung-Nr.11'},
        {value: 'Abschlagsrechnung-Nr.12'},
        {value: 'Abschlagsrechnung-Nr.13'},
        {value: 'Abschlagsrechnung-Nr.14'},
        {value: 'Abschlagsrechnung-Nr.15'},
        {value: 'Abschlagsrechnung-Nr.16'},
        {value: 'Abschlagsrechnung-Nr.17'},
        {value: 'Abschlagsrechnung-Nr.18'},
        {value: 'Abschlagsrechnung-Nr.19'},
        {value: 'Abschlagsrechnung-Nr.20'},
        {value: 'Abschlagsrechnung-Nr.21'},
        {value: 'Abschlagsrechnung-Nr.22'},
        {value: 'Abschlagsrechnung-Nr.23'},
        {value: 'Abschlagsrechnung-Nr.24'},
        {value: 'Abschlagsrechnung-Nr.25'},
        {value: 'Abschlagsrechnung-Nr.26'},
        {value: 'Abschlagsrechnung-Nr.27'},
        {value: 'Abschlagsrechnung-Nr.28'},
        {value: 'Abschlagsrechnung-Nr.29'},
        {value: 'Abschlagsrechnung-Nr.30'}
      ],
      // categories: [],
      areas: [],
    }
  },

  computed: {
    origin() {
      return this.$store.getters['global/getEnumById']('purchase_invoice_origin')
    },
    purchaseInvoiceSubcontractorId: {
      get() {
        return this.purchaseInvoiceForm.subcontractor ? this.purchaseInvoice.subcontractor.id : null
      },
      set(value) {
        if (!value) {
          this.purchaseInvoiceForm.subcontractor = null
        } else {
          this.purchaseInvoiceForm.subcontractor = this.subcontractors.find(
            (subcontractor) => subcontractor.id === value
          )
        }
      },
    },
  },

  watch: {
    'purchaseInvoice.origin'(value) {
      if (value !== 'nachunternehmer') {
        this.purchaseInvoiceSubcontractorId = null
      }
    },
    'purchaseInvoice.externalReferenceThird'(value) {
      this.externalReferenceThird = value;
    }
  },

  created() {
    this.purchaseInvoiceForm = this.purchaseInvoice
    // this.loadCategories()
    subcontractorsApi.index(
      (response) => {
        this.loadingSubcontractors = false
        this.subcontractors = response
      },
      (error) => {
        this.loadingSubcontractors = false
        throw error
      }
    )

    projectAreasApi.index(
      (response) => {
        this.areas = response
      },
      (error) => {
        this.loading = false
        logErrorMessage(error)
      },
      this.purchaseInvoice.projectId
    )
  },

  methods: {
    onSubmit() {
      purchaseInvoicesApi.update(
        (response) => {
          this.$emit('update', response)

          this.$message({
            type: 'success',
            message: 'Updated',
            duration: 3000,
          })
        },
        (error) => {
          this.loading = false
          this.$message({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.purchaseInvoice.id,
        {
          external_reference: this.purchaseInvoiceForm.externalReference,
          external_reference_second: this.purchaseInvoiceForm.externalReferenceSecond,
          external_reference_third: this.purchaseInvoiceForm.externalReferenceThird,
          plan_index: this.purchaseInvoiceForm.planIndex,
          origin: this.purchaseInvoiceForm.origin,
          category_id: this.purchaseInvoice.categoryId,
          description: this.purchaseInvoiceForm.description,
          subcontractor_id: this.purchaseInvoiceForm.subcontractor
            ? this.purchaseInvoiceForm.subcontractor.id
            : null,
          area: this.purchaseInvoiceForm.area,
        }
      )
    },
  },
}
</script>

<style lang="scss">
.purchase-invoice-edit-form {
  .el-select {
    width: 100%;
  }
}
</style>
