import ApiResource from '../ApiResource'

class BidResource extends ApiResource {
  constructor() {
    super({
      name: 'bids',
    })
  }
  configuration(resolve, reject, params) {
    this.get('api/v2/bids/configuration', { params })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
  updateConfiguration(resolve, reject, visibleColumns) {
    this.put(`${this.getUrl()}/configuration`, {
      visibleColumns: visibleColumns,
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
  revisions(resolve, reject, params) {
    this.get('api/v2/bids/revisions', { params: params })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
  finalize(resolve, reject, bidId, params) {
    this.put(`api/v2/bids/${bidId}/finalize`, params)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
  checkAreaOverwrite(resolve, reject, bidId, areaId, params) {
    this.get(`api/v2/bids/${bidId}/check-area-overwrite/${areaId}`, params)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
  reopen(resolve, reject, bidId, params) {
    this.put(`api/v2/bids/${bidId}/reopen`, params)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
  restore(resolve, reject, bidId, params) {
    this.put(`api/v2/bids/${bidId}/restore`, params)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
  clone(resolve, reject, bidId, params) {
    this.put(`api/v2/bids/${bidId}/clone`, params)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
  close(resolve, reject, bidId, params) {
    this.put(`api/v2/bids/${bidId}/close`, params)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
  closeBulk(resolve, reject, params) {
    this.put('api/v2/bids/close-bulk', params)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
  searchProjects(resolve, reject, params) {
    this.get('api/v2/bids/search-projects', { params })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }

  getProjectList(resolve, reject, params) {
    this.get('api/v2/bids/get-projects-list', { params })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
  exportExcel(resolve, reject, format, params) {
    this.get('api/v2/bids/export', {
      params: { ...params },
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
  showForArea(resolve, reject, areaId, params) {
    this.get(`api/v2/bids/show-for-area/${areaId}`, params)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const BidsApi = new BidResource()

export default BidsApi
