<template>
  <div>
    <div v-if="canCreate" class="header-action-row">
      <el-button
        v-if="canCreate"
        type="primary"
        size="medium"
        @click="addItem"
      >
        Add Mapping
      </el-button>
    </div>

    <DataTableAlternate
      ref="pricingMappingTable"
      :api="pricingMappingApi"
      :row-actions="rowActions"
      :data-table-options="{
        tableHeightOffset:200,
        showRevisions:true
      }"
      table-name="pricingMapping"
      @row-action="rowAction"
    >
    </DataTableAlternate>

    <DialogTemplate
      v-if="isAdding"
      type="add"
      :visible="true"
      :on-close="onAdded"
      :on-success="onAdded"
    />

    <DialogTemplate
      v-if="isEditing"
      type="edit"
      :visible="true"
      :translation="editingItem"
      :on-close="onEdited"
      :on-success="onEdited"
    />

  </div>
</template>

<script>
import DialogTemplate from './DialogTemplate'
import DataTableAlternate from '@/js/components/Common/DataTableAlternate.vue';
import pricingMappingApi from 'api/v2/pricingMapping'
import {can, logErrorMessage} from '@/js/common/util';

export default {
  name: 'PricingMappingView',

  components: {
    DialogTemplate,
    DataTableAlternate,
  },

  data() {
    return {
      isAdding: false,
      isEditing: false,
      editingItem: {},
      pricingMapping: [],
    }
  },

  computed: {
    rowActions() {
      let rowActions = {}
      if (this.canCreate) {
        rowActions.editItem = {
          label: false,
          icon: 'el-icon el-icon-edit'
        }
      }
      if (this.canCreate) {
        rowActions.deleteItem = {
          label: false,
          icon: 'el-icon el-icon-delete',
          type: 'danger'
        }
      }
      return rowActions;
    },
    pricingMappingApi() {
      return pricingMappingApi
    },
    canCreate() {
      return can('create', 'user')
    },
  },

  methods: {
    processData(data) {
      this.pricingMapping = data
    },
    addItem() {
      this.isAdding = true
    },
    onAdded() {
      this.isAdding = false
      this.$refs.pricingMappingTable.refresh()
    },
    onEdited() {
      this.isEditing = false
      this.editingItem = {}
      this.$refs.pricingMappingTable.refresh()
    },
    editItem(data) {
      this.isEditing = true
      this.editingItem = data
    },
    deleteItem(data) {
      let confirm = window.confirm('Are you sure?')
      if (confirm) {
        this.pricingMappingApi.delete(
          () => {
            this.$refs.pricingMappingTable.refresh()
          },
          (error) => {
            logErrorMessage(error)
          },
          data.id,
        )
      }
    },
    rowAction(action) {
      if (this[action.action]) {
        this[action.action](action.data);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-table td {
  height: auto;
  padding-top: 2px;
  padding-bottom: 2px;
}
.header-action-row {
  margin-bottom:15px;
}
</style>
