<template>
  <div class="purchaseInvoice-email-preview">
    <h2>Preview</h2>

    <el-card>
      <div slot="header">
        <h3>Email</h3>
      </div>
      <div ref="previewEmail" contenteditable="true" @keyup="emailChanged" />
    </el-card>
    <el-card>
      <div slot="header">
        <h3>
          <el-row>
            <el-col :span="17">
              Pdf
            </el-col>
            <el-col :span="7">
              <el-button
                size="small"
                class="pull-right"
                :loading="isDownloading && isDownloadingRevision === 0"
                :disabled="actionsDisabled"
                @click="downloadPdf()"
              >Download
              </el-button>
              <el-button
                size="small"
                :loading="isSaving"
                :disabled="actionsDisabled"
                @click="savePDF"
              >Save
              </el-button>
            </el-col>
          </el-row>
        </h3>
      </div>

      <el-col class="email-preview" :span="18">
        <div v-if="!isDownloadDisabled" class="a4paper landscape">
          <div ref="previewPdf" contenteditable="true" @keyup="pdfChanged" />
        </div>
      </el-col>
      <el-col  v-if="!isDownloadDisabled" class="saved-version" :span="6">
        <div v-if="savedPdfs.length">
          <table class="saved-version-download-table">
            <caption>Saved versions</caption>
            <tr>
              <th class="version">Version</th>
              <th class="date">Date</th>
              <th class="time">Time</th>
              <th class="download"></th>
            </tr>
            <tr
              v-for="(pdf, index) in savedPdfs"
              :key="pdf.id"
            >
              <td class="version">{{savedPdfs.length - index}}</td>
              <td class="date">{{formatDate(pdf.created_at)}}</td>
              <td class="time">{{formatTime(pdf.created_at)}}</td>
              <td class="download">
                <el-button
                  size="mini"
                  class="pull-right"
                  :loading="isDownloading && isDownloadingRevision === pdf.id"
                  :disabled="actionsDisabled"
                  @click.prevent="downloadPdfVersion(pdf.id)"
                >
                  Download
                </el-button>
              </td>
            </tr>
          </table>
        </div>
      </el-col >

      <div v-if="isDownloadDisabled" class="a4paper landscape">
        <div>Please add items to the invoice.</div>
      </div>

    </el-card>
  </div>
</template>

<script>
import miscApi from 'api/v2/miscellaneous'
import {logErrorMessage} from '../../common/util';
import moment from 'moment/moment';
import projectPurchaseInvoiceEmailApi from '@/js/api/v2/projectPurchaseInvoiceEmail';

export default {
  name: 'PurchaseInvoiceEmailPreview',

  props: {
    email: {
      type: Object,
      default: null,
    },
    purchaseInvoice: {
      type: Object,
      default: null,
    },
    images: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      previewEmail: '',
      previewPdf: '',
      isDownloading: false,
      isDownloadingRevision: 0,
      isSaving: false,
      isDownloadDisabled: false,
      savedPdfs: [],
    }
  },
  computed: {
    actionsDisabled() {
      return this.isDownloadDisabled || this.isDownloading
    },
  },
  watch: {
    email() {
      this.renderPreviews()
    },
  },

  mounted() {
    this.setDownloadDisabledStatus()
    this.renderPreviews()
    this.savedPdfs = this.email.versions
  },

  methods: {
    emailChanged(event) {
      this.previewEmail = event.target.innerHTML
      this.$emit('changedEmail', this.previewEmail)
    },

    pdfChanged(event) {
      this.previewPdf = event.target.innerHTML
      this.$emit('changedPdf', this.previewPdf)
    },

    renderPreviews() {
      this.previewEmail = this.email.mail
      this.previewPdf = this.email.pdf
      this.$refs.previewEmail.innerHTML = this.previewEmail
      this.$refs.previewPdf.innerHTML = this.previewPdf

      this.$emit('changedEmail', this.previewEmail)
      this.$emit('changedPdf', this.previewPdf)
    },
    downloadPdfVersion(pdfVersion) {
      this.isDownloading = true;
      this.isDownloadingRevision = pdfVersion;

      projectPurchaseInvoiceEmailApi.versionShow(
        (response) => {
          this.downloadPdf(response.data.htmlContents, response.data.images);
        },
        (error) => {
          logErrorMessage(error)

          this.isDownloading = false
          this.isDownloadingRevision = 0;
        },
        pdfVersion,
        this.purchaseInvoice.projectId,
        this.purchaseInvoice.id
      )
    },
    downloadPdf(htmlContents, images) {
      this.isDownloading = true

      const theHtml = htmlContents ?? this.previewPdf
      const theImages = images ?? this.images

      const filename = 'purchaseInvoice-' + this.purchaseInvoice.id + '-email-preview'
      miscApi.html2pdf(
        (data) => {
          let blob = new Blob([data], { type: 'application/pdf' })
          const objURL = window.URL.createObjectURL(blob)

          let link = document.createElement('a')
          link.href = objURL
          link.download = filename + '.pdf'
          link.click()

          window.URL.revokeObjectURL(objURL)

          // window.open(objURL)

          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(objURL)
          }, 100)

          this.isDownloading = false
        },
        (error) => {
          logErrorMessage(error)
        },
        theHtml,
        theImages,
        'landscape',
        {
          fontHeightRatio: 0.75
        }
      )
    },
    getAllSavedPDFs() {
      projectPurchaseInvoiceEmailApi.versionIndex(
        (response) => {
          this.savedPdfs = response.data.versions;
        },
        (error) => {
          logErrorMessage(error)
        },
        this.purchaseInvoice.projectId,
        this.purchaseInvoice.id
      )
    },
    savePDF() {
      this.isSaving = true
      projectPurchaseInvoiceEmailApi.versionStore(
        () => {
          this.isSaving = false;
          this.getAllSavedPDFs() // refresh the list of saved pdfs
        },
        (error) => {
          logErrorMessage(error)
          this.isSaving = false
        },
        this.previewPdf,
        this.images,
        this.purchaseInvoice.projectId,
        this.purchaseInvoice.id
      );
    },
    setDownloadDisabledStatus() {
      if (typeof this.email.itemsCount !== 'number') {
        return
      }
      if (this.email.itemsCount === 'null') {
        return
      }
      this.isDownloadDisabled = this.email.itemsCount === 0
    },
    formatDate(value) {
      if(!value) {
        return ''
      }
      return moment(String(value)).format('DD.MM.YYYY')
    },
    formatTime(value) {
      if(!value) {
        return ''
      }
      return moment(String(value)).format(' HH:mm')
    },
  },
}
</script>

<style lang="scss">
.purchaseInvoice-email-preview {
  h3 {
    font-size: 1.4rem;
    margin: 0;
  }

  .email-preview {
    .a4paper {
      min-width: 1125px;
    }
    overflow-x: auto;
  }

  @media screen and (max-width:1650px) {
    .saved-version,
    .email-preview {
      width:100%;
    }
    .saved-version {
      margin-top: 25px;
    }
  }

  .saved-version-download-table {
    width:100%;

    caption {
      text-align: left;
      padding:5px;
    }

    tr {
      &:nth-child(even) {
        background: #eff2f7;
      }
      th, td {
        text-align: left;
        &.version {
          width: 40px;
        }
        &.download {
          width: 40px;
          text-align: right;
        }
        &.date {
          width: 80px;
        }
      }
    }
  }

  .el-card {
    margin-bottom: 0.5rem;
  }

  .a4paper {
    // a4wrapper is defined in the template. We render it differently on
    // smaller screens, but have to keep it at a certain width when rendering
    // to pdf
    &,
    .a4wrapper {
      &.landscape {
        width: auto !important;
        max-width: 29.7cm;
      }

      &.portrait {
        width: auto !important;
        max-width: 21cm;
      }
    }

    padding-left: 1.5cm;
    padding-right: 1.5cm;
    border-left: 1px dotted lighten(black, 50%);
    border-right: 1px dotted lighten(black, 50%);
  }
}
</style>
