<template>
  <div>
    <el-tabs v-if="purchaseInvoice" ref="tabs" v-model="activeName" @tab-click="tabChange">
      <el-tab-pane v-loading="loadingOverview" label="Overview" name="overview">
        <PurchaseInvoiceOverview
          v-if="purchaseInvoice && activeName === 'overview'"
          :purchase-invoice="purchaseInvoice"
          @update="update"
        />
      </el-tab-pane>
      <el-tab-pane label="Items" name="items">
        <PurchaseInvoiceItems
          v-if="purchaseInvoice && activeName === 'items'"
          ref="items"
          :item="purchaseInvoice"
        />
      </el-tab-pane>
      <el-tab-pane label="Import" name="import">
        <PurchaseInvoiceImport
          v-if="purchaseInvoice && activeName === 'import'"
          :purchase-invoice="purchaseInvoice"
          @reload="reloadPurchaseInvoice"
        />
      </el-tab-pane>
      <el-tab-pane label="Email" name="email">
        <PurchaseInvoiceEmail
          v-if="purchaseInvoice && activeName === 'email'"
          :purchase-invoice="purchaseInvoice"
          @reload="loadPurchaseInvoice"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import purchaseInvoiceApi from '@/js/api/v2/purchaseInvoices'
import PurchaseInvoiceOverview from './Overview'
import PurchaseInvoiceItems from './Items'
import PurchaseInvoiceImport from './Import'
import PurchaseInvoiceEmail from './Email'
import { formatErrorMessage } from '@/js/common/util'

export default {
  name: 'PurchaseInvoiceSingle',

  components: {
    PurchaseInvoiceOverview,
    PurchaseInvoiceItems,
    PurchaseInvoiceImport,
    PurchaseInvoiceEmail,
  },

  data() {
    return {
      activeName: 'overview',
      purchaseInvoice: null,
      loadingOverview: true,
    }
  },

  watch: {
    $route(val) {
      this.activeName = val.params.tab
    },
  },

  mounted() {
    this.loadPurchaseInvoice()

    if (this.$route.params.tab) {
      this.activeName = this.$route.params.tab
    }
  },

  methods: {
    reloadPurchaseInvoice() {
      if (!this.$refs.items) {
        return
      }
      this.$refs.items.loadPage()
    },
    tabChange(e) {
      this.$router.replace(
        '/projects/' +
        this.purchaseInvoice.projectId +
        '/purchase-invoices/' +
        this.purchaseInvoice.id +
        '/' +
        e.name,
        () => {
          this.$route.params.tab = e.name
        },
        () => {
          this.$refs.tabs.setCurrentName(this.$route.params.tab)
          this.activeName = this.$route.params.tab
        }
      )

      if (e.label === 'Items') {
        if (!this.$refs.items) {
          return
        }
        this.$refs.items.loadPage(1)
      }
    },
    update() {
      // this.purchaseInvoice = item
      this.loadPurchaseInvoice()
    },
    loadPurchaseInvoice() {
      this.loadingOverview = true
      purchaseInvoiceApi.show(
        (response) => {
          this.purchaseInvoice = response
          this.$store.commit('projects/currentPurchaseInvoice', response)
          this.loadingOverview = false
        },
        (error) => {
          this.loadingOverview = false
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.$route.params.purchaseInvoiceId,
        {include: ['statuses', 'statuses.user', 'project', 'subcontractor', 'items']}
      )
    },
  },
}
</script>
