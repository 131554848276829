import ApiResource from '../ApiResource'

class LerfItemResource extends ApiResource {
  constructor() {
    super({
      name: 'lerfs.items',
    })
  }

  import(resolve, reject, lerfId, positionItems) {
    this.ids = [lerfId]

    this.post(`${this.getUrl()}/import`, positionItems)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }
}

const lerfItemsApi = new LerfItemResource()

export default lerfItemsApi
