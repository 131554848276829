<template>
  <div>
    <div v-loading="loading" class="reporting-table">
      <template v-if="data && data.length && projectFields">
        <el-table
          v-loading="isLoading"
          :data="data"
          :highlight-current-row="true"
          :max-height="maxHeight"
          border
          class="weekly-report"
          size="mini"
          stripe
        >
          <el-table-column
            v-for="column in columns"
            :key="column.prop"
            :label="column.label"
            :prop="column.prop"
            :show-overflow-tooltip="true"
            :width="column.width"
          >
            <template slot-scope="scope">
              {{ scope.row[column.prop] }}
            </template>
          </el-table-column>
        </el-table>

        <el-row class="bottom-row">
          <el-col :span="12">
            <el-button
              :loading="generatingExcelFile"
              type="primary"
              @click="generateExcelFile('xlsx')"
            >Download excel file
            </el-button
            >
            <el-button
              :loading="generatingExcelFile"
              type="primary"
              @click="generateExcelFile('csv')"
            >Download csv file
            </el-button
            >
          </el-col>
          <el-col :span="12">
            <el-pagination
              :background="true"
              :current-page.sync="currentPage"
              :page-size="perPage"
              :pager-count="5"
              :small="true"
              :total="total"
              class="pagination"
              layout="sizes, total, prev, pager, next"
              @current-change="pageChange"
              @size-change="changePageSize"
            />
          </el-col>
        </el-row>
      </template>
    </div>
  </div>
</template>

<script>
import {formatErrorMessage} from 'common/util'
import {upperFirst} from 'lodash'

import projectMetaReportApi from 'api/v2/projectMetaReport'
import projectFieldsApi from 'api/v2/projectFields'
import {AuthorizationError} from '@/js/api/errors';

export default {
  name: 'SubcontractorsReportTable',

  data() {
    return {
      projectFields: null,
      data: null,
      loading: 0,
      generatingExcelFile: false,
      currentPage: 1,
      total: null,
      perPage: 20,
    }
  },

  computed: {
    isLoading() {
      return this.loading > 0
    },
    maxHeight() {
      return window.innerHeight - 150
    },
    columns() {
      if (this.data && this.data[0]) {
        return Object.keys(this.data[0]).map((prop) => {
          return {
            prop: prop,
            label: upperFirst(prop.replaceAll('_', ' ')),
            width: this.getWidthForProp(prop),
          }
        })
      }

      return []
    },
  },

  mounted() {
    this.fetchProjectFields()
    this.fetchProjectMetaReport(1)
  },

  methods: {
    getWidthForProp(prop) {
      if (
        [
          '#',
          'archived',
          'costCenter',
          'status',
          'region',
          'volume',
          'strecke',
          'lng',
          'lat',
        ].includes(prop)
      ) {
        return 100
      } else if (
        [
          'internal_customer_contact_name',
          'internal_customer_contact_email',
          'external_customer_contact_name',
          'external_customer_contact_email',
          'Vertragswert (Basisinfrastruktur)',
          'Mehraufwandspauschale-Drilling',
          'Kommentar-Projektvorbereitung',
          'Kommentar-Genehmigungsplanung',
        ].includes(prop)
      ) {
        return 240
      }

      return 200
    },

    fetchProjectFields() {
      this.loading++
      projectFieldsApi.index(
        (response) => {
          this.$set(this, 'projectFields', response)
          this.loading--
        },
        (error) => {
          this.loading--

          if (error instanceof AuthorizationError) {
            this.$router.push('/')
          }

          this.$message.error({
            message: formatErrorMessage(error),
          })
        }
      )
    },
    pageChange(val) {
      this.loading = 0
      this.spinner = true
      this.currentPage = val
      this.fetchProjectMetaReport(val)
    },
    changePageSize(newPageSize) {
      this.perPage = newPageSize
      this.loading = 0
      this.spinner = true
      this.currentPage = 1
      this.fetchProjectMetaReport(1)
    },
    fetchProjectMetaReport(page) {
      this.loading++

      projectMetaReportApi.index(
        (response) => {
          this.data = response.data
          this.total = response.meta.total
          this.perPage = response.meta.per_page
          this.loading--
        },
        (error) => {
          this.loading--
          if (error) {
            this.$message.error({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 0,
            })
          }
        },
        {
          normalizeKeys: false,
          page: page,
          perPage: this.perPage,
        }
      )
    },

    generateExcelFile(extension) {
      this.generatingExcelFile = true

      const params = {}
      params['extension'] = extension

      projectMetaReportApi.export(
        (response) => {
          this.generatingExcelFile = false
          window.location = response
        },
        (error) => {
          this.generatingExcelFile = false
          if (error) {
            this.$message.error({
              showClose: true,
              type: 'error',
              message: formatErrorMessage(error),
              duration: 0,
            })
          }
        },
        'export',
        params
      )
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .reporting-table {
  .bottom-row {
    margin-top: 1em;
  }

  th {
    vertical-align: middle;
  }

  th .cell {
    vertical-align: middle;
  }

  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background: none;
  }

  .el-table--enable-row-hover .el-table__body tr:hover {
    background: mix(#000, #fff, 20%);
  }
}
</style>
