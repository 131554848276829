<template>
  <div>
    <LerfTableWithFilters />
  </div>
</template>

<script>
import LerfTableWithFilters from '../../Lerfs/LerfTableWithFilters'

export default {
  name: 'LerfsView',
  components: {
    LerfTableWithFilters,
  },
}
</script>
