<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>General</span>
    </div>

    <div class="text project-details">
      <template v-if="editMode">
        <el-form
          ref="project-general-form"
          :model="formData"
          label-position="right"
          label-width="50%"
          :rules="rules"
        >
          <el-row
            v-for="{ key, label } of projectDetails"
            :key="key"
            :gutter="20"
          >
            <el-form-item :ref="key" :label="label" :prop="key">
              <template v-if="['name', 'cost_center'].includes(key)">
                <el-input v-model="formData[key]" />
              </template>
              <template v-else-if="['customer_id'].includes(key)">
                <el-select v-model="formData[key]" filterable clearable>
                  <el-option
                    v-for="customer in customers"
                    :key="customer.id"
                    :value="customer.id"
                    :label="customer.name"
                  />
                </el-select>
              </template>
              <template v-else-if="['region'].includes(key)">
                <el-select v-model="formData[key]" filterable clearable>
                  <el-option
                    v-for="region in regions"
                    :key="region"
                    :value="region"
                    :label="region"
                  />
                </el-select>
              </template>
              <template v-else-if="['managing_area'].includes(key)">
                <el-select v-model="formData[key]" filterable clearable>
                  <el-option
                    v-for="managing_area in managing_areas"
                    :key="managing_area"
                    :value="managing_area"
                    :label="managing_area"
                  />
                </el-select>
              </template>
              <template
                v-else-if="
                  [
                    'project_manager_contact_id',
                    'project_lead_contact_id',
                  ].includes(key)
                "
              >
                <el-select
                  v-model="formData[key]"
                  value-key="id"
                  :loading="usersLoading"
                  :disabled="usersErrored"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="contact in loadContacts"
                    :key="contact.id"
                    :value="contact.id"
                    :label="contact.fullname"
                  />
                </el-select>
              </template>

              <el-select
                v-else-if="key === 'status'"
                v-model="formData[key]"
                placeholder="Project status *"
                filterable
              >
                <el-option
                  v-for="status in projectStatuses"
                  :key="status.value"
                  :value="status.value"
                  :label="status.label"
                />
              </el-select>

              <el-select
                v-else-if="key === 'invoice_template'"
                v-model="formData[key]"
                placeholder="Invoice template *"
                filterable
              >
                <el-option
                  v-for="invoice_template in [{value: 'default', label: 'Default'}, {value: 'lerf', label: 'LERF'},]"
                  :key="invoice_template.value"
                  :value="invoice_template.value"
                  :label="invoice_template.label"
                />
              </el-select>

              <template v-else> No type selected </template>
            </el-form-item>
          </el-row>

          <el-row>
            <el-col :span="24" class="align-right">
              <el-button @click="cancel">Cancel</el-button>
              <el-button type="primary" @click="submit">Save</el-button>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template v-else>
        <el-row
          v-for="{ key, label, readableValue } of projectDetails"
          :key="key"
          :gutter="20"
        >
          <el-col :span="12" class="key">
            {{ label }}
          </el-col>
          <el-col :span="12" class="value">
            {{ readableValue }}
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24" class="align-right">
            <el-button v-if="canUpdate" size="small" @click="editMode = true">
              Edit
            </el-button>
          </el-col>
        </el-row>
      </template>

      <!-- META FIELDS -->
      <template v-if="showMetaFields">
        <hr style="margin: 30px 0" />
        <projectMetaFields
          :project="project"
          :project-fields="projectFields"
          :on-success="setProjectFields"
          :can-sdit="canUpdate"
        />
      </template>
    </div>
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex'
import contactsApi from 'api/v2/contacts'
import customersApi from 'api/v2/customers'

import ProjectMetaFields from './ProjectMetaFields'
import {can, logErrorMessage} from '../../../../../common/util';

export default {
  name: 'ProjectSingleOverviewGeneral',

  components: { ProjectMetaFields },

  props: {
    project: {
      type: Object,
      required: true,
    },
    projectFields: {
      type: Array,
      default: null,
    },
    setProjectFields: {
      type: Function,
      default: null,
    },
    onCancel: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      contacts: [],
      editMode: !this.project.id,

      regions: [
        'Nord',
        'Süd',
        'West',
        'Ost'
      ],
      managing_areas: [
        '(HAH) Hannover - Höxter',
        '(HNF) Heilbronn - Franken',
        '(KAN) Karlsruhe - Neubrunn',
        '(KLH) Kleve - Heinsberg',
        '(NBY) Nordbayern',
        '(OLS) Olpe - Siegen',
        '(OSN) Ost - Nord',
        '(OSS) Ost - Süd',
        '(SAW) Salzgitter - Wabern',
        '(SBY) Südbayern',
        '(SÜW) Südliche Weinstraße',
        '(WES) West',
      ],
      customers: [],

      rules: {
        name: [
          {
            required: true,
            message: 'Please enter a project name',
            trigger: 'blur',
          },
        ],
        customer_id: [
          {
            required: true,
            message: 'Please select a customer',
            trigger: 'blur',
            type: 'integer',
          },
        ],
      },

      formData: {},
    }
  },

  computed: {
    ...mapGetters('global', ['projectStatuses']),
    ...mapGetters('projects', ['users', 'usersLoading', 'usersErrored']),

    canUpdate() {
      return can('update', 'project')
    },

    projectDetails() {
      if (!this.project) {
        return []
      }

      return [
        {
          key: 'name',
          label: 'Project title',
          readableValue: this.project.name,
          value: this.project.name,
        },
        {
          key: 'customer_id',
          label: 'Customer',
          readableValue: this.project.customer
            ? this.project.customer.name
            : '',
          value: this.project.customer ? this.project.customer.id : '',
        },
        {
          key: 'cost_center',
          label: 'Cost center',
          readableValue: this.project.costCenter,
          value: this.project.costCenter,
        },
        {
          key: 'project_manager_contact_id',
          label: 'Project Supervisor',
          readableValue: this.project.projectManagerContact
            ? this.project.projectManagerContact.fullname
            : '',
          value: this.project.projectManagerContact
            ? this.project.projectManagerContact.id
            : null,
        },
        {
          key: 'project_lead_contact_id',
          label: 'Project Leader',
          readableValue: this.project.projectLeadContact
            ? this.project.projectLeadContact.fullname
            : '',
          value: this.project.projectLeadContact
            ? this.project.projectLeadContact.id
            : null,
        },
        {
          key: 'region',
          label: 'Region',
          readableValue: this.project.region,
          value: this.project.region,
        },
        {
          key: 'managing_area',
          label: 'Managing Area',
          readableValue: this.project.managingArea,
          value: this.project.managingArea,
        },
        {
          key: 'status',
          label: 'Status',
          readableValue: this.project.status,
          value: this.project.status,
        },
        {
          key: 'invoice_template',
          label: 'Invoice Template',
          readableValue: this.project.invoiceTemplate,
          value: this.project.invoiceTemplate,
        },
      ]
    },

    loadContacts() {
      if (!this.contacts) return []
      return this.contacts.filter(function (contact) {
        if (!contact.user) return false
        return (
          ['project_leader', 'project_supervisor'].indexOf(
            contact.user.role
          ) !== -1
        )
      })
    },

    showMetaFields() {
      return (
        this.projectFields &&
        this.projectFields.length &&
        this.projectFields.filter((pf) => pf.canEdit || pf.value).length > 0
      )
    },
  },

  watch: {
    project: {
      deep: true,
      handler() {
        this.editMode = false
      },
    },
  },

  created() {
    this.fillFormData()
    this.fetchContacts()
    this.fetchCustomers()

    if (!this.project || !this.project.id) {
      this.editMode = true
    }
  },

  methods: {
    fetchContacts() {
      contactsApi.index(
        (response) => {
          this.contacts = response
        },
        (error) => {
          logErrorMessage(error)
        },
        { include: ['user'] }
      )
    },
    fetchCustomers() {
      customersApi.index(
        (response) => {
          this.customers = response
        },
        (error) => {
          logErrorMessage(error)
        },
        {}
      )
    },
    fillFormData() {
      const currentData = this.projectDetails.reduce((acc, { key, value }) => {
        acc[key] = value || null

        return acc
      }, {})

      if (this.project && this.project.id) {
        currentData.id = this.project.id
      }

      this.$set(this, 'formData', currentData)
    },

    cancel() {
      this.fillFormData()
      this.editMode = false

      if (this.onCancel) {
        this.onCancel()
      }
    },

    submit() {
      this.$refs['project-general-form'].validate((valid) => {
        if (!valid) {
          return
        }

        this.$store.dispatch('projects/saveProject', this.formData)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.key,
.value {
  margin: 4px 0;
}
</style>
