<template>
  <el-dialog
    class="claim-edit-item-dialog"
    :visible.sync="visible"
    title="Edit Claim item"
    :before-close="close"
    :close-on-click-modal="false"
  >
    <ClaimItemForm
      :claim="claim"
      :claim-item="editItem"
      :loading="loading"
      :on-submit="onSubmit"
      @cancel="close"
    />
  </el-dialog>
</template>

<script>
import snakeCaseKeys from 'snakecase-keys'
import claimItemsApi from 'api/v2/claimItems'
import { formatErrorMessage } from 'common/util'
import ClaimItemForm from './ItemForm'

export default {
  name: 'LerfItemEditDialog',
  components: { ClaimItemForm },
  props: {
    claim: {
      type: Object,
      required: true,
    },
    claimItem: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      visible: true,
      pricingCategories: [],
      pricingItems: [],
      editItem: {
        id: this.claimItem.id,
        description: this.claimItem.description,
        comment: this.claimItem.comment,
        width: this.claimItem.width,
        height: this.claimItem.height,
        length: this.claimItem.length,
        amount: this.claimItem.amount,
        pricing_item_id: this.claimItem.pricing_item_id,
        price: this.claimItem.price,
        factor: this.claimItem.factor,
        unit: this.claimItem.unit,
        section: this.claimItem.section,
        custom_category: this.claimItem.custom_category,
      },
      items: [],
    }
  },

  methods: {
    close(done) {
      this.$emit('cancel')
      if (done && typeof done === 'function') {
        this.$nextTick(() => {
          done()
        })
      }
    },
    onSubmit(editedItem) {
      this.loading = true
      claimItemsApi.update(
        (response) => {
          this.loading = false
          this.$emit('updateItem', response)
          this.close()
        },
        (error) => {
          this.loading = false
          this.$message.error({
            showClose: true,
            type: 'error',
            message: formatErrorMessage(error),
            duration: 0,
          })
        },
        this.claim.id,
        editedItem.id,
        snakeCaseKeys(editedItem)
      )
    },
  },
}
</script>
