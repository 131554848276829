<template>
  <div class="note-history">
    <el-collapse :value="[0, 1, 2]">
      <el-collapse-item
        v-for="(note, index) in notes"
        :key="note.id"
        :title="getTitle(note)"
        :name="index"
      >
        <div>{{processHtml(note.note)}}</div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import projectNotesApi from 'api/v2/projectNotes'
import { formatErrorMessage } from 'common/util'

export default {
  name: 'NoteHistory',

  props: {
    project: {
      type: Object,
      default: null,
    },
    extraNotes: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      apiNotes: [],
    }
  },

  computed: {
    notes() {
      return [...this.extraNotes, ...this.apiNotes]
    },
  },

  created() {
    projectNotesApi.index(
      (data) => {
        this.apiNotes = data
      },
      (error) => {
        this.$message({
          message: formatErrorMessage(error),
          type: 'error',
        })
      },
      this.project.id,
      { include: ['user', 'user.contact'] }
    )
  },

  methods: {
    getTitle(note) {
      return `${note.user.name} (${note.updatedAt})`
    },

    processHtml(text) {
      const textWithNewTabLinks = text.replace(
        /<a([^>]+)>(.*?)<\/a>/g,
        '<a$1 target="_blank">$2</a>'
      )

      return textWithNewTabLinks
    },
  },
}
</script>

<style lang="scss" scoped>
.note-history {
  ::v-deep .el-collapse-item__arrow {
    margin: 0 5px 0 0;
    font-weight: bold;
    font-size: 16px;
  }
  ::v-deep .el-collapse-item > div > div {
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>
